import * as CNT from "./action";
import {RESET} from "./action";

const initialState = {
    //isFetching: false, // 默认是false的话，那么刚进入页面的时候各种展示型组件就会成功挂载，但是此时数据又还没有加载过来
    isFetching: true,
    isFetchingQuestions: false,
    isSend: false,
    info: {
        // categoryId: 0, // @小刚 说这个分类暂时没用
        id: "",
        content: "",
        questions: [],
        questionIds:[]
    }
};

export function materialQuestionInfo(state = initialState, action) {
    switch (action.type) {
        case CNT.REQUEST_INFO:
            return {...state, isFetching: true};
        case CNT.RECEIVE_INFO:
            let getInfo = action.info;
            getInfo.selectedQuestions = action.info.questions || [];
            return {...state, isFetching: false, info: getInfo};
        case CNT.FILL_QARR:
        case CNT.FILL_QUESTIONS:
        case CNT.FILL_CONTENT:            
            return Object.assign({...state, info: {...state.info, [action.key]: action.value}});
        case CNT.REQUEST_PUBLISH:
            return {...state, isFetching: true, isSend: true};
        case CNT.RECEIVE_PUBLISH_SUC:
            return Object.assign({}, state, {isFetching: false, isSend: false});
        case CNT.RECEIVE_PUBLISH_FAIL:
            return Object.assign({}, state, {isFetching: false, isSend: false});
        default:
            return state;
    }
}