import apiRequest from '../../common/request';
import { message } from 'antd';
export const REQUEST_APP_ID = 'REQUEST_APP_ID';


export function publishPushedInfo(type) {
    let config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: '',
        credentials: 'include'
    };
    
    let fetchUrl = `/gk/ops/positions/pushMsg/${type}`;
    
    apiRequest(fetchUrl, config)
        .then((res) => {            
            if(res.status==1) {message.success('推送成功');}
            else {message.error('推送失败');}
        });
}