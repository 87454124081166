import apiRequest from '../../common/request';

async function fetchCommonSense (id, successCb) {
    apiRequest('/gk/ops/commonSense/getById?id=' + id, { credentials: 'include' })
        .then(function(json) {
            successCb && successCb(json);
        });
}

async function updateCommonSense(data, successCb) {
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    apiRequest("/gk/ops/commonSense/saveOrUpdate", config)
        .then(() => {
            successCb && successCb();
        });
}

export {
    fetchCommonSense,
    updateCommonSense
};