import React, { Component } from 'react'
import { Modal, Divider, Button } from 'antd';
import apiRequest from "../../common/request";
import './index.scss'

export default class Preview extends Component {

    constructor(props) {
        super(props);
        this.state = { isFetchingMaterial: false, materials: "" }
    }

    componentDidMount() {
        this.props.question && this.props.question.materialsId && this.fetchMaterialQuestion(this.props.question.materialsId);
    }

    fetchMaterialQuestion = (id) => {
        this.setState({ isFetchingMaterial: true });
        // 默认行测
        let url = `/gk/ops/materials/details/${id}`;
        let { fromType } = this.props;
        if (fromType == 'gj' || fromType == 'zc') {
            // 公基  职测 详情
            url = `/gk/ops/${fromType}/materials/details/${id}`;
        }
        return apiRequest(url)
            .then(json => {
                this.setState({ isFetchingMaterial: json.obj == undefined || false, materials: json.obj && json.obj.content || '' });
            })
    };

    /** 获取没有提供材料 的材料题 的材料, key :pre||next 是父组件传过来的方法*/
    getMaterials = async (key) => {
        await this.props[key]();
        const { question = {} } = this.props;
        question.materialsId && this.fetchMaterialQuestion(question.materialsId);
    };

    goToPath = (id) => {
        let { fromType } = this.props;
        if (fromType == 'gj' || fromType == 'zc') {
            // 公基  职测 详情
            return `/institutionInfo/${fromType}/${id}`;
        } else if (fromType == 0) {
            // 行测 详情
            return `/question/${id}`;
        } else {
            // 突发情况回到首页
            return `/`;
        }
    }

    render() {
        let { previewShow, hidePreviewCb, question, number } = { ...this.props };
        const options = question && question.options || [];
        let key = 1;
        return (
            <div className="preBox">
                <Modal key={key++} footer={null} visible={previewShow} onCancel={hidePreviewCb}>
                    {previewShow && question && <div className="previewModal">
                        <div>
                            <span className="type">{question.quesTypeName}{(number + 1) && '--第' + (number + 1) + '题' || ''}</span>
                            <a href={this.goToPath(question.id)} rel="noreferrer" target="_blank">编辑</a>
                        </div>
                        <hr></hr>
                        {
                            question.materialsId && !this.state.isFetchingMaterial &&
                            <div className="explain-item">
                                <Divider orientation="left" plain>资料内容</Divider>

                                <div className="explain-content"
                                    dangerouslySetInnerHTML={{ __html: `${question.materialsContent || this.state.materials}` }}>
                                </div>
                            </div>
                        }
                        <div className="content">
                            <div dangerouslySetInnerHTML={{ __html: question.content }}></div>
                            <div className="choice-items">
                                {options.map((citem, cindex) => {
                                    return (
                                        <div key={cindex} className="choice-item">
                                            <span className={`index ${question.answer.includes(citem.key) ? 'right' : ''}`}>{citem.key}</span>
                                            <span className="item-content" dangerouslySetInnerHTML={{ __html: `${citem.value}` }}></span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {
                            question.knowledge &&
                            <div className="explain-item">
                                <Divider orientation="left" plain>考点</Divider>

                                <div className="explain-content" dangerouslySetInnerHTML={{ __html: `${question.knowledge}` }}></div>
                            </div>
                        }
                        {question.source &&
                            <div className="explain-item">
                                <Divider orientation="left" plain>来源</Divider>

                                <div className="explain-content"
                                    dangerouslySetInnerHTML={{ __html: `${question.source}` }}></div>
                            </div>}
                        {question.quesExplain &&
                            <div className="explain-item">
                                <Divider orientation="left" plain>题目解析</Divider>

                                <div className="explain-content"
                                    dangerouslySetInnerHTML={{ __html: `${question.quesExplain}` }}></div>
                            </div>}
                        {
                            question.issue &&
                            <div className="explain-item">
                                <Divider orientation="left" plain>疑难解答</Divider>

                                <div className="explain-content" dangerouslySetInnerHTML={{ __html: `${question.issue}` }}></div>
                            </div>
                        }
                        <div style={{ width: '100%', height: '40px' }}></div>
                        <div className="footerPre">
                            <Button className="pre" size="small" type="primary" onClick={() => this.getMaterials('pre')}>上一题</Button>
                            <Button size="small" type="primary" onClick={() => this.getMaterials('next')}>下一题</Button>
                        </div>
                    </div>}
                </Modal>
            </div>
        )
    }
}