import React from 'react';
import { List, Input, Modal, Upload, Button, message, InputNumber, Image, Popconfirm, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { checkPermission, uploadOSS2 } from '../../utils/utils';

export default class Teacher extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            addStatus: false,
            sort: 1,
            fromIdx: 1,
            show: false,
            editObj: {},
            list: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        let that = this;
        // let url = `https://resource.sinture.com/test/1.js?v=${Date.now()}`;
        let url = `https://gk-cdn.oss-cn-beijing.aliyuncs.com/config/teacher.js?v=${Date.now()}`;
        let request = new XMLHttpRequest();
        request.open("get", url);
        request.send(null);
        request.onload = function () {
            if (request.status == 200) {
                let str = (request.responseText);
                let res = str.replace('var teacherListJs =', '');
                let data = {
                    "obj": JSON.parse(res)
                };
                that.setState({ list: data.obj, loading: false });
            } else {
                that.setState({ loading: false });
                message.error('获取老师资源失败');
            }
        }
    }

    update = () => {
        let { list } = this.state;
        // 先过滤空标签
        // 文件路径 https://gk-cdn.oss-cn-beijing.aliyuncs.com/config/teacher.js
        list.map((i) => { i.tags = i.tags.filter((t) => t.tag != ''); });
        let data = `var teacherListJs = ${JSON.stringify(list)}`;
        let blob = new Blob([data], { type: "application/json" });
        const cb = () => { this.setState({ loading: false }); }
        this.setState({ loading: true });
        uploadOSS2('/config/teacher.js', blob, 'gk-cdn', false, cb);
    }

    /** 编辑老师 */
    edit = (editObj, index) => {
        this.setState({ fromIdx: index, sort: index+1, editObj, show: true });
    }

    /** 删除老师 */
    remove = (index) => {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list });
        message.success('删除成功');
    }

    /** 新增老师 */
    pushList = () => {
        this.setState({ editObj: { tags: [{ tag: '' }] }, show: true, addStatus: true });
    }

    /** 新增或者编辑确认 */
    handleOk = () => {
        let { addStatus, list, editObj, sort, fromIdx } = this.state;        
        if (!editObj.src || !editObj.name || !editObj.title) return message.warn('请录完所有信息');
        if (editObj.tags.find(i => i.tag == '')) return message.warn('标签不能为空或删除多余空标签');
        if (addStatus) {            
            list.splice(sort - 1, 0, editObj);
        } else {
            list.splice(fromIdx, 1);
            list.splice(sort-1, 0, editObj);
        }
        this.setState({ list, show: false, addStatus: false });
    }

    /** 新增或者编辑修改 */
    onChange = (e, key, idx) => {
        let { list, editObj, sort, addStatus } = this.state;
        let v = e.target.value;
        if (idx) {      // 编辑tags情况
            editObj.tags[idx - 1].tag = v;
            !addStatus && (list[sort-1] = editObj);
        } else {        // 编辑非tags情况
            editObj[key] = v;
            !addStatus && (list[sort-1] = editObj);
        }
        this.setState({ list, editObj });
    }

    /** 新增老师序号 */
    onChangeNumber = (v) => {        
        this.setState({ sort: v });
    }

    /** 新增标签 */
    add = () => {
        let { list, editObj, sort, addStatus } = this.state;
        editObj.tags.push({ tag: '' });
        !addStatus && (list[sort-1] = editObj);
        this.setState({ editObj, list });
    }

    /** 删除标签 */
    del = (idx) => {
        let { list, editObj, sort, addStatus } = { ...this.state };
        if (editObj.tags.length == 1) return message.warn('至少保留一个标签');
        editObj.tags.splice(idx, 1);
        !addStatus && (list[sort-1] = editObj);
        this.setState({ editObj, list });
    }

    /** 生成upload组件的配置参数 : Fake Single File Upload 
     * 链接格式 https://resource.sinture.com/老师资料/tearcherPhoto/齐老师.png
    */
    generateUploadSettings = () => {
        return {
            beforeUpload: file => {
                let { editObj } = this.state;
                if (!editObj.name) return message.warn('请先在下方输入名称');
                let name = '/老师资料/tearcherPhoto/' + editObj.name + '.png';
                const cb = (url) => {
                    editObj.src = url;
                    this.setState(editObj);
                }
                uploadOSS2(name, file, 'gk-resource', true, cb);
                return false;
            },
            onPreview: () => { },
            disabled: !this.state.editObj.name,
            listType: "picture-card",
            accept: 'image/png',
        };
    }

    render() {
        let { list, loading, editObj, show, sort, addStatus } = this.state;
        return (
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <List
                        itemLayout="horizontal"
                        loading={loading}
                        style={{ background: '#fff', padding: '0 10px', borderRadius: '15px' }}
                        dataSource={list || []}
                        renderItem={(item, index) => (
                            <List.Item actions={[<Button type="link" onClick={() => this.edit(item, index)}>编辑</Button>, <Popconfirm
                                title="确认删除吗"
                                onConfirm={() => this.remove(index)}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button type="link">删除</Button>
                            </Popconfirm>]}>
                                <List.Item.Meta
                                    avatar={<Image width={80} src={item.src} />}
                                    title={index + 1 + '.' + item.name + ' -- ' + item.title}
                                    description={item.tags && item.tags.map((i, idx) => i.tag && <span key={idx} style={{ display: 'inline-block', padding: '3px 5px', color: '#fff', background: idx % 2 ? '#108ee9' : '#2db7f5', margin: '0 10px 10px 0', borderRadius: '5px' }}>{i.tag}</span>)}
                                />
                            </List.Item>
                        )}
                    />

                </div>
                <div className="commonsubmit">
                    {checkPermission('gk:tea:update') && <Card className="cardR" size="small" title="操作">
                        <Button type="primary" onClick={() => this.pushList()} disabled={loading} style={{ marginRight: '20px' }}>新增</Button>
                        <Button type="primary" onClick={() => this.update()} disabled={loading}>更新</Button>
                    </Card>}
                </div>

                <Modal title={addStatus && '新增' || '编辑'} visible={show} onOk={this.handleOk} onCancel={() => this.setState({ show: false, editObj: {} })}>
                    {checkPermission('gk:tea:pic') && <div style={{ display: 'flex' }}>
                        <div><Upload {...this.generateUploadSettings()}>点击上传</Upload></div>
                        <div style={{ fontSize: '12px' }}>
                            <p>上传前图片先压缩一下:<a target="_blank" rel="noreferrer" href="https://tinypng.com/">压缩网站</a></p>
                            <p style={{ color: 'red' }}>上传前请先在下方输入老师名称</p>
                            <p>上传照片格式为.png格式</p>
                            <p>上传成功链接会自动替换</p>
                            <p>已有照片链接则无需上传</p>
                        </div>
                    </div>}
                    <label>照片链接</label><Input style={{ width: '80%' }} placeholder="例：https://resource.sinture.com/老师资料/tearcherPhoto/杨过.png" value={editObj.src} onChange={(e) => this.onChange(e, 'src')} />
                    {!addStatus && <div><label>排序</label><InputNumber placeholder="请输入序号" min="1" max={list.length} style={{ width: '80%' }} value={sort} onChange={this.onChangeNumber} /></div>}
                    {addStatus && <div><label>排序</label><InputNumber placeholder="请输入序号" min="1" max={list.length + 1} value={sort} style={{ width: '80%' }} onChange={this.onChangeNumber} /></div>}
                    <label>名称</label><Input style={{ width: '80%' }} placeholder="例：杨过" value={editObj.name} onChange={(e) => this.onChange(e, 'name')} />
                    <label>称号</label><Input style={{ width: '80%' }} placeholder="例：行测资深讲师" value={editObj.title} onChange={(e) => this.onChange(e, 'title')} />
                    {editObj.tags && editObj.tags.map((i, idx) => <div key={idx}><label>标签{idx + 1}</label><Input placeholder="例：授课耐心细致" style={{ width: '75%', marginRight: '5px' }} value={i.tag} onChange={(e) => this.onChange(e, 'tag', idx + 1)} /><DeleteOutlined style={{ color: 'red' }} onClick={() => this.del(idx)} /> </div>)}
                    <Button type="primary" size="small" onClick={() => this.add()} style={{ marginTop: '10px' }}>新增标签</Button>
                </Modal>
            </div>
        )
    }
}
