import {
  SET_KEY_VALUE,
  RESET_SEARCH_STATUS
} from './actions';

/** 可能有某些参数会和api的VO字段名字不同，由于历史原因，而且被大量依赖，暂时不改成一致 */
const initFilter = {
  page: 1,
  pageSize: 10,
  id: [''],                   // 这个即代表api中VO的categoryId
  searchName: '',           // 这个代表VO中的query
  options: '',
  questionTag: '',
  year: [],
  province: '',
  area: '',
  query: '',               //申论搜索
  historyList: [],         // 历年真题列表
  selLoading: false,       // 套卷搜索加载
  qdesc: 'asc',
};

let initState = {
  ...initFilter
};

export const questionSelectCard = (prevState=initState, action) => {
  switch(action.type) {
    case SET_KEY_VALUE:
      return { ...prevState, [action.key]: action.value };
    case RESET_SEARCH_STATUS:
      return { ...prevState, ...initFilter };
    default:
      return prevState;
  }
}