import React from "react";
import { connect } from "react-redux";
import {
    fetchMaterialQuestion,
    fill_content,
    fill_qArr,
    fill_questions,
    update
} from "./action";
import { Breadcrumb, Card, Button, Spin } from "antd";
import Editor from "../../components/Editor/index";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import { checkPermission, validateUtil } from '../../utils/utils';

class MaterialQuestionInfo extends React.Component {

    componentDidMount() {
        let { type, materialQuestionId } = this.props.match.params;
        this.props.dispatch(fetchMaterialQuestion(type, materialQuestionId));
    }

    submit = async () => {
        let { type } = this.props.match.params;
        let blackList = ['content', 'questionIds'];
        let key2Cn = { content: '资料内容', questionIds: '问题' };
        if (validateUtil(this.props.info, blackList, key2Cn)) return;
        await this.props.dispatch(update(type, this.props.info));
    };

    updateQuesArr = (arr) => {
        this.props.dispatch(fill_qArr(arr))
    };

    updateSelQues = (arr) => {
        this.props.dispatch(fill_questions(arr));
    };

    getName = () => {
        let { type } = this.props.match.params;
        if (type == 'xc') return '行测';
        if (type == 'gj') return '公基';
        if (type == 'zc') return '职测';
        return '';
    }

    render() {
        let {
            isFetching,
            isSend,
            info,
        } = { ...this.props };
        let { type } = this.props.match.params;
        let quesArr = [...info.questionIds || []] ;
        let selQuesList = [...info.questions || []] ;

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>资料题</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.getName()}资料题详情</Breadcrumb.Item>
                </Breadcrumb>
                <Spin tip="加载中……" spinning={isFetching}>
                    <div>
                        <label>资料内容</label>
                        <Editor
                            content={info.content}
                            onChange={value => this.props.dispatch(fill_content(value))}
                        />
                    </div>
                    <br/>
                    <QuestionSelectCard
                        quesArr={quesArr}
                        selQuesList={selQuesList || []}
                        updateQuesArr={this.updateQuesArr}
                        updateSelQues={this.updateSelQues}
                        exerciseType={type=='xc' ? 0 : type}
                    />
                    {checkPermission('sys:material:edit') &&
                        <Card className="cardR" title="发布" size="small" >
                            <Button type="primary" onClick={this.submit} disabled={isSend}>更新</Button>
                        </Card>
                    }
                </Spin>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        isSend,
        info,
    } = state.materialQuestionInfo;

    return {
        isFetching,
        isSend,
        info,
    }
}

export default connect(mapStatetoProps)(MaterialQuestionInfo);