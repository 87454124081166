import React from 'react';
import { connect } from 'react-redux';
import { fetchGoodsList, setGoodsKeyValue, resetFilterValue, publishGoods, updateItemKeyValue } from './action';
import { checkPermission, generateBaseUrl } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { Typography, Table, Button, Tooltip, Select, message, Input } from 'antd';

const { Paragraph } = Typography;
const { Option } = Select;

class Goods extends React.Component {

  filterState2Chinese = { needAddress: '发货情况', publish: '是否上架', name: '商品名' };    // ui2ApiProperty

  selectConditions = ['needAddress', 'publish'];                              // 筛选类的样式

  inputConditions = ['name'];

  needAddressList = [{ value: null, label: '全部' }, { value: 1, label: '需要' }, { value: 0, label: '不需要' }];

  publishList = [{ value: null, label: '全部' }, { value: 1, label: '已上架' }, { value: 0, label: '已下架' }];

  componentDidMount() {
    this.baseUrl = generateBaseUrl();
    this.fetchListHandler();
  }

  getParams = () => {
    let keys = ['needAddress', 'page', 'pageSize', 'publish', 'name'];
    let data = {};
    const checkIsValid = key => { this.filterNullAndEmpty(this.props[key]) && (data[key] = this.props[key]) };
    keys.map(key => checkIsValid(key));
    return data;
  }

  filterNullAndEmpty = val => val != null && val !== ''

  fetchListHandler = () => {
    let data = this.getParams();
    this.props.dispatch(fetchGoodsList(data));
  }

  resetChain = async () => {
    await this.props.dispatch(resetFilterValue());
    this.fetchListHandler();
  }

  /** 点击查询按钮，除开需要携带查询input输入的查询参数外，还需要初始化页码 */
  searchHandler = async () => {
    await this.props.dispatch(setGoodsKeyValue('page', 1));
    this.fetchListHandler();
  }

  /** 渲染条件栏 */
  renderFilterUI = () => {
    return (
      <div className="commonSearchBox">
        { this.renderSelect()}
        { this.renderInputUI()}
        <Button disabled={this.props.loading} onClick={this.searchHandler} type="primary" style={{marginRight: '30px'}}>查询</Button>
        <Button disabled={this.props.loading} onClick={this.resetChain} type="danger">重置</Button>
      </div>
    );
  }

  /** 修改筛选状态的连锁反应 */
  setValueChain = async (key, value) => {
    await this.props.dispatch(setGoodsKeyValue(key, value));
    await this.props.dispatch(setGoodsKeyValue('page', 1));
    this.fetchListHandler();
  }

  /** 渲染select样式的UI结构 */
  renderSelect = () => {
    return this.selectConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.filterState2Chinese[key]}</label>
        <Select onChange={value => this.setValueChain(key, value)} value={this.props[key]} className="commonW150">
          {
            this[`${key}List`].map((obj, idx) => (
              <Option key={idx} value={obj.value}>{obj.label}</Option>
            ))
          }
        </Select>
      </div>
    ))
  }

  /** 渲染输入栏样式的UI结构 */
  renderInputUI = () => {
    return this.inputConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.filterState2Chinese[key]}</label>
        <Input onPressEnter={() => this.searchHandler()} value={this.props[key]} onChange={event => this.props.dispatch(setGoodsKeyValue(key, event.target.value))} className="commonW150" />
      </div>
    ))
  }

  /** 监听上下架点击 */
  publishHandler = async (obj, idx) => {
    let id = obj.id;
    let resultPublich = 1 - obj.publish;
    let status = await publishGoods(id);
    await this.props.dispatch(updateItemKeyValue(idx, 'publish', resultPublich));
    if (status === 1) message.success('操作成功');
    else message.success('操作失败');
  }

  generateTableColumns = () => {
    const arr = [
      {
        title: '商品名', width: '30%',
        render: (text, record) => checkPermission('sys:product:info') && <Link to={`/goodsDetail/${record.id}`}>{record.name}</Link> || <span>{record.name}</span>
      },
      {
        title: '商品描述图', width: '25%',
        render: (text, record) => this.renderRichImg(record.productDesc)
      },
      { title: '价格/分', dataIndex: 'price', key: 'price', width: '10%', },
      {
        title: '是否需要发货',
        width: '12%',
        render: (text, record) => record.needAddress ? '是' : '否'
      },
      {
        title: '操作',
        width: '10%',
        render: (text, record) => checkPermission('sys:product:gkfz') && <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/goodsDetail.html?goodsId=${record.id}` }}>h5链接</Paragraph>
      }
    ];
    if (true) {
      let obj = {
        title: '上下架状态',
        width: '13%',
        render: (text, record, idx) => (
          checkPermission('sys:product:publish') && <Button size='small' onClick={() => this.publishHandler(record, idx)} type={record.publish ? 'danger' : 'primary'}>{record.publish ? '下架' : '上架'}</Button>
        )
      };
      arr.splice(2, 0, obj);
    }
    return arr;
  }

  /** 渲染富文本图片 */
  renderRichImg = src => (
    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: src }}></div>}>
      <div className='richImg' dangerouslySetInnerHTML={{ __html: src }}></div>
    </Tooltip>
  );

  gotoNewGoods = () => {
    this.props.history.push('/createGoods');
  }

  /** 渲染antd table的表头 */
  renderHeader = () => (
    <div className="commonTableTitle">
      <span>商品列表</span>
      { checkPermission('sys:product:edit') && <Button onClick={this.gotoNewGoods} type="primary">添加商品</Button>}
    </div>
  );

  /** 改变页码 */
  changePage = async page => {
    await this.props.dispatch(setGoodsKeyValue('page', page));
    this.fetchListHandler();
  }

  /** 改变每页大小 */
  showSizeChange = async (current, size) => {
    await this.props.dispatch(setGoodsKeyValue('pageSize', size));
    await this.props.dispatch(setGoodsKeyValue('page', 1));
    this.fetchListHandler();
  }

  render() {
    return (
      <div className="goodsPageContainer">
        {
          checkPermission('sys:product:page') && this.renderFilterUI()
        }
        <Table
          columns={this.generateTableColumns()}
          dataSource={this.props.productsVO.content}
          rowKey={record => record.id}
          title={this.renderHeader}
          loading={this.props.loading}
          pagination={{
            current: this.props.page,
            pageSize: this.props.pageSize,
            total: this.props.productsVO.totalElements,
            showTotal: total => `共有${total}条数据`,
            onChange: this.changePage,
            onShowSizeChange: this.showSizeChange,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100']
          }}
        />
      </div>
    );
  }

}

const mapStateToProps = storeState => ({ ...storeState.goodsList });

export default connect(mapStateToProps)(Goods);