import React from 'react';
import $ from "jquery";
import { Input, Button, message } from 'antd';
import { getAvailableVideoUrl } from '../../components/VideoPicker/action';
import VideoPicker from '../../components/VideoPicker';
import { OSS_PUB_GK_COURSE } from '../../common/consts';
import apiRequest from '../../common/request';
import './index.scss';
import { checkPermission } from '../../utils/utils';

class VerifyVideo extends React.Component {
  state = { isUpdate: false, isSelect: false, size: 0, showVideoModal: false, loading: false, length: null, videoSrc: '', videoError: false, urlInput: '' }

  urlChange = event => {
    this.setState({ urlInput: event.target.value, isSelect: false, isUpdate: false, size: 0 });
  }

  startVerify = async () => {
    let {urlInput, isSelect} = this.state;
    if (!urlInput) return message.warn('请输入视频链接');
    this.setState({ loading: true, length: null, videoSrc: '', videoError: false });
    if (this.isAbsolute(urlInput)) {//以前绝对路径直接播放该地址，可能会播放失败，现在需要判断一下是公用视频还是私有视频
      if (this.isPublicURL(urlInput)) {//私有视频需要进一步处理发请求
        let resultUrl = await getAvailableVideoUrl(this.isPublicURL(urlInput));
        await this.setState({ videoSrc: resultUrl });
      } else {//公用视频直接播放
        await this.setState({ videoSrc: urlInput });
      }
    } else {//相对路径直接发请求获得有效地址
      let resultUrl = await getAvailableVideoUrl(urlInput);
      await this.setState({ videoSrc: resultUrl });
    }
    let vEle = this.refs.video;
    vEle.onloadedmetadata = () => {
      this.setState({ length: Math.floor(vEle.duration), isUpdate: true });
    }
    vEle.onerror = () => {
      this.setState({ videoError: true });
    }
    this.setState({ loading: false });
    !isSelect && this.getVideoSize(this.state.videoSrc);  // 从视频库选择视频有size传过来，不需要这样获取size
  }

  // 判断是公用视频还是私有视频,公用视频返回false，私有视频返回true
  isPublicURL = url => {
    if (url.includes('gk-course')) {//私有视频类型1
      return url.replace('https://gk-course.oss-cn-beijing.aliyuncs.com/', '')
    } else if (url.includes('gk-test-course')) {//私有视频类型2
      return url.replace('https://gk-test-course.oss-cn-beijing.aliyuncs.com/', '')
    } else {//公用视频类型
      return false;
    }
  }

  /** 校验是相对地址还是绝对地址 */
  isAbsolute = str => {
    return str && (str.includes('http') || str.includes('oss') || str.includes('aliyuncs.com'));
  }

  /** 检验视频链接是否包含?auth_key或者.com,返回有效半路径 */
  isKey=(str)=>{
    if(str.includes('?')) {
      return str.match(/com\/(\S*)\?/)[1];
    } else if(str.includes('com/')){
      return str.split('com/')[1];
    } else {
      return str;
    }
  }
  
  /** 刷新课程视频信息 */
  refreshClass = async () => {
    let url = `/gk/ops/course/class/refreshClass`;
    let data = {
      length: this.state.length || 0,
      size: this.state.size || 0,
      url: decodeURIComponent(this.isKey(this.state.videoSrc))
    }
    let config = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };
    await apiRequest(url, config)
      .then(json => {
        if(json.status==1){
          message.success('视频链接更新成功');
        } else {
          message.error('视频链接更新失败');
        }
      })
      .catch(error => console.log(error));
  }

  /** 获取视频size */
  getVideoSize = (src) => {
    let that = this;
    $.ajax({
      type: 'HEAD', // 获取头信息，type=HEAD即可
      url: src,
      complete: function (xhr) {
        // 获取相关Http Response header
        let size = xhr.getResponseHeader('content-length');
        that.setState({size: parseInt(size/1024)});
      }
    });
  }

  render() {
    return (
      <div className='vvPageContainer'>
        <div className='videoContainer'>
          {
            this.state.videoSrc && <video ref='video' autoPlay controls src={this.state.videoSrc} className='video' />
          }
          {
            this.state.length != null && <h4 className='length'>时长为：{this.state.length}s</h4>
          }
          {
            this.state.videoError && <h4 className='error'>该视频存在问题</h4>
          }
        </div>
        <div className='inputContainer'>
          <Button type="primary" onClick={() => this.setState({ showVideoModal: true })}>选择</Button>
          <Input value={this.state.urlInput} className='w500' placeholder='输入视频URL(相对或绝对都行)' onChange={this.urlChange} />
          <Button disabled={this.state.loading} type='primary' onClick={this.startVerify}>校验</Button>
          {checkPermission('sys:class:refresh') && <Button style={{marginLeft: '10px'}} disabled={!this.state.size || !this.state.isUpdate} type="primary" onClick={() => this.refreshClass()}>更新</Button>}
        </div>
        <br/>
        <span>注意：点击检验按钮完成后，再点击更新按钮</span>
        <p>若更新按钮不可点击，说明没有获取到视频size</p>
        {
          this.state.showVideoModal ?
            <VideoPicker
              pubName={OSS_PUB_GK_COURSE}
              videoKey={"key"}
              showModal={this.state.showVideoModal}
              closeModal={() => this.setState({ showVideoModal: false })}
              feedBack={(url) => this.setState({ urlInput: url, isSelect: true, isUpdate: false })}
              getSize={(s) => this.setState({ size: s })}
            /> : ''
        }
      </div>
    )
  }
}

export default VerifyVideo;