import apiRequest from '../../common/request';

/** 加载课程列表数据 */
export const fetchCourseLists = async (title) => {
  let url = `/gk/ops/course/page`;
  let data = { page: 1, pageSize: 10 };            // 获取所有课程数据
  (title!='') && (data.title = title);
  let config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    credentials: 'include'
  };
  let response = await apiRequest(url, config);
  return response;
}

/** 负责上传文件, fileObj => 文件对象 ； courseId => 课程ID */
/** 由于Ant的upload组件不好实现只上传一个文件并且还带回显，而在UI表现上所实现的是一个fake single file upload，而在这里要做的就是取出第一个文件 */
export const uploadFile = async (fileList, courseId) => {
  let fileObj = fileList[0];
  let url = `/gk/ops/usercourse/configureStudentCourse`;
  const formData = new FormData();
  formData.append('file', fileObj);
  for (let id of courseId) {
    formData.append('courseIds[]', id);
  }
  let config = {
    method: 'POST',
    body: formData,
    credentials: 'include'
  };
  let response = await apiRequest(url, config, true);
  return response;
}