import React, { Component } from 'react';
import { fetchSystemInfo, setPageSize, setPageActive } from './action';
import { connect } from "react-redux";
import { Table, Select, Button, Tooltip } from "antd";
import './style.scss';
import { checkPermission } from '../../utils/utils';

const { Option } = Select;

class InfoSystemPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeValue: 9,
    };
    this.dataType = 0;
    this.deviceType = 0;
    this.selectUI = [{
      key: 'dataType',
      lable: '公告类型',
      option: [{
        opt: '全部',
        val: ''
      }, {
        opt: '系统升级',
        val: 9
      }, {
        opt: '直播公告',
        val: 10
      }, {
        opt: '开课公告',
        val: 11
      }]
    }, {
      key: 'deviceType',
      lable: '设备机型',
      option: [{
        opt: '全部',
        val: ''
      }, {
        opt: '安卓',
        val: 1
      }, {
        opt: 'IOS',
        val: 2
      }]
    }]
  }

  componentDidMount() {
    this.props.dispatch(fetchSystemInfo({ page: 1 }));
    this.props.dispatch(setPageActive(1));
  }
  addInfo = () => {
    this.props.history.push(`/infoSystemAdd`);
  }
  /** 渲染antd table的表头 */
  renderHeader = () => (
    <div className="commonTableTitle"><span>系统公告</span>{checkPermission('sys:notification:save') && <Button type="primary" onClick={this.addInfo}>新增系统公告</Button>}</div>
  );
  handleChange = (key, val) => {
    this[key] = val
    this.props.dispatch(setPageActive(1));
    this.props.dispatch(fetchSystemInfo({
      dataType: this.dataType,
      pageSize: this.props.pageSize,
      deviceType: this.deviceType
    }));
  }
  onShowSizeChange = (current, size) => {
    this.props.dispatch(setPageSize(size));
    this.props.dispatch(fetchSystemInfo({
      pageSize: size,
      dataType: this.dataType,
      page: current,
      deviceType: this.deviceType
    }));
  }
  paginationChange = (current) => {
    this.props.dispatch(setPageActive(current));
    this.props.dispatch(fetchSystemInfo({
      page: current,
      dataType: this.dataType,
      pageSize: this.props.pageSize,
      deviceType: this.deviceType
    }));
  }
  getTooltip = (record) => {
    return <div dangerouslySetInnerHTML={{ __html: record ? record : '' }}></div>
  };
  /** 渲染select类型相关的筛选数据 */
  renderSelectStatus = () => {
    return this.selectUI.map((sel, idx) => (
      <div className="itemSearchBox" key={idx}>
        <label>{sel.lable}</label>
        <Select defaultValue='全部' style={{ width: 150 }} onChange={value => { this.handleChange(sel.key, value) }}>
          {
            sel.option.map((opts, idx) => (
              <Option key={idx} value={opts.val}>{opts.opt}</Option>
            ))
          }
        </Select>
      </div>
    ));
  }
  getDeviceType = (val) => {
    return val == '1' ? '安卓' : val == '2' ? 'ios' : 'ios+安卓'
  }

  render() {
    const columns = [
      {
        title: '推送标题',
        width: '20%',
        render: (record) => (
          <Tooltip placement="topLeft" title={this.getTooltip(record.title)}>
            <span className="title" dangerouslySetInnerHTML={{ __html: record.title }}></span>
          </Tooltip>
        ),
      },
      {
        title: '推送内容',
        width: '20%',
        render: (record) => (
          <Tooltip placement="topLeft" title={this.getTooltip(record.content)}>
            <span className="title" dangerouslySetInnerHTML={{ __html: record.content }}></span>
          </Tooltip>
        ),
      },
      {
        title: '机型',
        width: '10%',
        render: (record) => (
          <span>{this.getDeviceType(record.deviceType)}</span>
        ),
      },
      {
        title: '富文本',
        width: '15%',
        render: (record) => (
          <Tooltip placement="topLeft" title={this.getTooltip(record.text)}>
            <span className="title" dangerouslySetInnerHTML={{ __html: record.text }}></span>
          </Tooltip>
        ),
      },
      {
        title: '截止时间',
        width: '15%',
        dataIndex: 'deadline',
        key: 'deadline',
      },
      {
        title: '是否弹窗',
        width: '10%',
        render: (record) => (
          <span className="noMore3Line">
            {record.isPopUps ? '是' : '否'}
          </span>
        ),
      },
      {
        title: '公告类型',
        width: '10%',
        render: (record) => (
          <span className="noMore3Line">
            {record.type == 9 && '系统升级'}
            {record.type == 10 && '直播公告'}
            {record.type == 11 && '开课公告'}
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="commonSearchBox">
          {checkPermission('sys:notification:page') && this.renderSelectStatus()}
        </div>
        <Table
          columns={columns}
          dataSource={this.props.systemList}
          rowKey={record => record.id}
          title={this.renderHeader}
          loading={this.props.loading}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            onChange: this.paginationChange,
            total: this.props.totalElements,
            showTotal: total => `共有${total}条数据`,
            pageSize: this.props.pageSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            current: this.props.activePage,
          }}
        />
      </div>
    )
  }
}
function mapStatetoProps(state) {
  const {
    systemList,
    totalElements,
    pageSize,
    activePage,
    loading,
  } = state.infoSystemPush;

  return {
    systemList,
    totalElements,
    pageSize,
    activePage,
    loading,
  };
}

export default connect(mapStatetoProps)(InfoSystemPush);