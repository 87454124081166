import apiRequest from '../../common/request';

const PREFIX = "_GOODS_";

export const SET_GOODS_KEY_VALUE = PREFIX + "SET_GOODS_KEY_VALUE";
export const RESET_FILTER_VALUE = PREFIX + "RESET_FILTER_VALUE";
export const UPDATE_ITEM_KEY_VALUE = PREFIX + "UPDATE_ITEM_KEY_VALUE";

export const updateItemKeyValue = (idx, key, value) => ({
  type: UPDATE_ITEM_KEY_VALUE,
  idx, key, value
});

export const resetFilterValue = () => ({
  type: RESET_FILTER_VALUE
});

export const setGoodsKeyValue = (key, value) => ({
  type: SET_GOODS_KEY_VALUE,
  key,
  value
});

/** 加载商品列表数据 */
export const fetchGoodsList = data => {
  let url = `/gk/ops/product/page`;
  let config = { 
    method: 'POST', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify(data)
  };
  return async dispatch => {
    dispatch(setGoodsKeyValue('loading', true ));
    let json = await apiRequest(url, config);
    dispatch(setGoodsKeyValue('productsVO', json.obj));
    dispatch(setGoodsKeyValue('loading', false));
  }
}

/** 修改上下架状态 */
export const publishGoods = async id => {
  let url = `/gk/ops/product/publish/${id}`;
  let config = { 
    method: 'GET', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
  };
  let json = await apiRequest(url, config);
  return json.status;
}