import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Select, Divider, Breadcrumb, Table, Input, Button, message, BackTop, Modal, Radio, Tooltip, Popconfirm } from 'antd';
import {
  changeActivePage,
  changePageSize,
  changeStatus,
  updateStatus,
  updateFeedBack,
  fetchQuestionCorrection
} from './actions';
import './style.scss';
import { checkPermission } from '../../utils/utils';

const { Option } = Select;

const CORRECTION_TYPE = {
  1: '题干',
  2: '选项',
  3: '解析',
  4: '其他'
}

class QuesCorrection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,           // 预览信息
      feedBack: null,           // 反馈信息
      visible: false,
      info: null
    };
  }

  componentDidMount() {
    this.fetchQuestionCorrection();
  };

  /** 根据已选的条件获取纠错试题列表数据 */
  fetchQuestionCorrection = () => {
    let params = {};
    this.props.status && (params.status = this.props.status);
    this.props.activePage && (params.page = this.props.activePage);
    this.props.pageSize && (params.pageSize = this.props.pageSize);
    params.breed = this.props.breed;
    this.props.dispatch(fetchQuestionCorrection(params));
  }

  showModalHandler = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  errMessageHandler = errorMes => {
    message.error(errorMes);
  }

  getTooltip = (content) => {
    return <div dangerouslySetInnerHTML={{ __html: content || '' }}></div>
  };

  changeStatusHandler = (record, idx) => {
    this.props.dispatch(updateStatus(record, idx));
  }

  showFeedBackHandler = (record, idx) => {
    this.setState({
      feedBack: record.correctionMethod || "",
      record, idx
    })
  }

  showModal = (record) => {
    this.setState({
      visible: !this.state.visible,
      info: (record && record.correctionMethod) || "",
    })
  }

  /** 不同的题型跳转不同的详情页 */
  getUrl=(id)=>{
    let { breed } = this.props;
    if(breed==0) return `/question/${id}`;
    if(breed==3) return `/institutionInfo/gj/${id}`;
    if(breed==4) return `/institutionInfo/zc/${id}`;
  }

  /** antd table 的数据格式约定配置 */
  generateColumns() {
    const columns = [
      {
        title: '试题内容',
        width: '20%',
        render: (text, record) => {
          return record.questionOpsVO && <Tooltip title={this.getTooltip(record.questionOpsVO.content)}>
            {checkPermission('question:correct:gkinfo') && <Link to={this.getUrl(record.questionOpsVO.id)} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.questionOpsVO.content }}></Link>
              || <div className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.questionOpsVO.content }}></div>}
          </Tooltip>
        }
      },
      {
        title: '问题描述',
        dataIndex: 'description',
        width: '10%',
        render: (text, record) => {
          return <Tooltip title={this.getTooltip(record.description)}>
            <div className="ellipsis2" style={{ width: '60px' }}>{record.description}</div>
          </Tooltip>
        }
      },
      {
        title: '纠错类型',
        width: '10%',
        render: (text, record) => {
          return <div className="tagList">
            {record.type.includes(1) && <span>{CORRECTION_TYPE[1]}</span>}
            {record.type.includes(2) && <span>{CORRECTION_TYPE[2]}</span>}
            {record.type.includes(3) && <span>{CORRECTION_TYPE[3]}</span>}
            {record.type.includes(4) && <span>{CORRECTION_TYPE[4]}</span>}
          </div>;
        }
      },
      {
        title: '提交时间',
        dataIndex: 'gmtCreate',
        width: '20%',
      },
      {
        title: '用户id',
        dataIndex: 'userId',
        key: 'userId',
        width: '10%',
      },
      {
        title: '完成状态',
        width: '10%',
        render: (text, record) => {
          return record.status ? '已处理' : <span style={{ color: "red" }}>未处理</span>
        }
      },
      {
        title: '操作',
        width: '10%',
        render: (text, record, idx) => {
          return (
            <div className="commonTableBtn">
              {checkPermission('question:correct:update') && 
                <Popconfirm
                  title="确定改变完成状态？"
                  onConfirm={() => { this.changeStatusHandler(record, idx) }}
                  okText="确定"
                  cancelText="否"
                >
                  <Button size="small" type={record.status === 1 ? 'danger' : 'primary'} className="mr5 mb5">改变完成状态</Button>
                </Popconfirm>
              }
              {checkPermission('question:correct:gkpre') && <Button size="small" type="primary" className="mr5 mb5" onClick={() => { this.previewHandler(record, idx) }}>预览</Button>}
            </div>
          );
        }
      },
      {
        title: '反馈',
        width: '10%',
        render: (text, record, idx) => {
          return (
            <div className="commonTableBtn">
              {checkPermission('question:correct:method') && <Button size="small" type="primary" className="mr5 mb5" onClick={() => { this.showFeedBackHandler(record, idx) }}>点击反馈</Button>}
              {
                checkPermission('question:correct:method') && record && record.correctionMethod && (
                  <Button size="small" type="primary" className="mr5 mb5" onClick={() => { this.showModal(record) }}>查看反馈</Button>
                )
              }
            </div>
          );
        }
      }
    ];
    return columns;
  }


  commChange = async (key, val) => {
    await this.props.dispatch(changeStatus(key, val))
    await this.resetActivePage1();
    this.fetchQuestionCorrection();
  }

  /** 分页组件改变页码 */
  paginationChange = async (page, pageSize) => {
    await this.props.dispatch(changeActivePage(page));
    await this.props.dispatch(changePageSize(pageSize));
    this.fetchQuestionCorrection();
  }

  updateFeedBack = () => {
    if (this.state.feedBack && this.state.feedBack.trim()) {
      this.props.dispatch(updateFeedBack(this.state.record, this.state.idx, this.state.feedBack)).then(() => {
        this.setState({
          feedBack: null,
          record: null,
          idx: null,
        })
        message.success("更新成功");
      });
    } else {
      message.error("反馈不能为空")
    }
  }

  modalCancelHandler = () => {
    this.setState({
      feedBack: null,
      record: null,
      idx: null,
    })
  }

  /** 重置为第一页 */
  resetActivePage1 = () => {
    this.props.dispatch(changeActivePage(1));
  }

  previewHandler = (record) => {
    if(record.questionOpsVO) {
      this.setState({
        preview: record,
      });
    } else {
      message.warn('未检测到题目');
    }
  }

  previewCancelHandler = () => {
    this.setState({
      preview: null,
    })
  }

  render() {
    let columns = this.generateColumns();
    const { breed, responseObj, onLoading, activePage, pageSize, status } = this.props;
    const { preview } = this.state;
    const question = preview ? preview.questionOpsVO : {};
    const options = question ? question.options || [] : [];

    return (
      <div className="wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>试题纠错</Breadcrumb.Item>
          <Breadcrumb.Item>纠错列表</Breadcrumb.Item>
        </Breadcrumb>
        {/** 筛选区域开始 */}
        <div className="commonSearchBox">
          <div>
            <label>题型分类</label>
            <Select value={breed} className="commonW150" onChange={value => { this.commChange('breed', value) }}>
              <Option value={0}>行测</Option>
              <Option value={3}>公基</Option>
              <Option value={4}>职测</Option>
            </Select>
          </div>
          <div>
            <label>完成状态</label>
            <Select value={status} className="commonW150" onChange={value => { this.commChange('status', value) }}>
              <Option value={''}>全部</Option>
              <Option value={0}>未处理</Option>
              <Option value={1}>已处理</Option>
            </Select>
          </div>
        </div>

        <div className="tableWrapper">
          <Table
            columns={columns}
            dataSource={responseObj.content}
            rowKey={record => record.id}
            loading={onLoading}
            pagination={{
              loading: this.props.onLoading,
              current: activePage,
              pageSize: pageSize,
              total: responseObj.totalElements,
              onChange: this.paginationChange,
              // onShowSizeChange: this.showSizeChange,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: total => `共有${total}条数据`,
            }}
          />
        </div>

        {/** 添加反馈信息 */}
        <Modal
          // title="反馈信息"
          visible={this.state.feedBack !== null}
          onOk={this.updateFeedBack}
          onCancel={this.modalCancelHandler}
        >
          <label>反馈信息</label>
          <Input.TextArea
            style={{ height: 100 }}
            value={this.state.feedBack}
            autoSize={{ minRows: 5, maxRows: 5 }}
            onChange={event => { this.setState({ feedBack: event.target.value }) }}
          />
        </Modal>

        {/** 查看反馈信息 */}
        <Modal
          visible={this.state.visible}
          onOk={this.showModal}
          onCancel={this.showModal}
        >
          <label>反馈内容</label>
          {this.state.info && <div className="info">{this.state.info}</div> || <div className="info">---暂无反馈内容---</div>}
        </Modal>

        {/** 试题预览 */}
        <Modal
          className="corPreModel"
          visible={this.state.preview !== null}
          width='800'
          onCancel={this.previewCancelHandler}
          footer={[
            <Button key="back" type="primary" onClick={this.previewCancelHandler}>
              取消
            </Button>,
          ]}
        >
          <Divider orientation="left" plain>试题内容</Divider>
          <div className="content" style={{ marginBottom: 10 }}>
            <div style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: question.content }}></div>
            <div className="choice-items">
              {options.map((citem, idx) => {
                return (
                  <div className="choice-item" key={idx}>
                    <span className={`index ${question.answer == citem.key ? 'right' : ''}`}>
                      {citem.key}
                    </span>
                    <span className="item-content"
                      dangerouslySetInnerHTML={{ __html: `${citem.value}` }}>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="explain-item" style={{ marginBottom: 10 }}>
            <Divider orientation="left" plain>题目解析</Divider>
            <div className="explain-content" style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: `${question.quesExplain}` }}></div>
            <Divider orientation="left" plain>来源</Divider>
            <div>{question.source}</div>
          </div>

          {preview && <div>
            <Divider orientation="left" plain>纠错类型</Divider>
            <div className="tagList">
              {preview.type.includes(1) && <span>{CORRECTION_TYPE[1]}</span>}
              {preview.type.includes(2) && <span>{CORRECTION_TYPE[2]}</span>}
              {preview.type.includes(3) && <span>{CORRECTION_TYPE[3]}</span>}
              {preview.type.includes(4) && <span>{CORRECTION_TYPE[4]}</span>}
            </div>
          </div>}

          {preview && <div>
            <Divider orientation="left" plain>问题描述</Divider>
            <div>
              {preview.description}
            </div>
          </div>}
        </Modal>
        <BackTop />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.quesCorrection,
});

export default connect(mapStateToProps)(QuesCorrection);
