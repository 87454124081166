import React from 'react';
import { connect } from 'react-redux';
import {
    bulkOperation,
    changeTableList,
    modifyTableList,
    setCurrentDate,
    setCurrentStatus,
    addModifyRemarks,
    batchUpdataBase,
    modifyBatchCurrentRedux,
    clearBatchSearch,
    modifySelectValue,
} from './action';
import { fetchContractUserInform } from '../AddOrModify/action';
import { fetchApplicationArea, fetchDepartmentList } from '../ContractOrder/action';
import { openModleAction, fetchSearchClass } from '../Inquiry/action';
import { Table, Checkbox, DatePicker, Select, Button, message, Input } from 'antd';
import moment from 'moment';
import './index.scss';
import UserInfo from '../../components/UserInfo/index';
import ClassInfo from '../../components/ClassInfo/index';

const { Option } = Select;
const sell = '销售部';
const classTeacher = '全程部,师资堂';
const operation = '运营部';
const teacherFame = '师资堂,全程部';

const getStatus = [
    { label: '全部', value: null },
    { label: '正常', value: 0 },
    { label: '异常', value: 1 },
];

const getHuifangStatus = [
    { label: '全部', value: null },
    { label: '正常', value: 0 },
    { label: '异常', value: 1 },
    { label: '未回复', value: 2 },
];

class Batch extends React.Component {

    state = {
        isAllActive: false,
    };

    componentDidMount() {
        this.getUserListInfo();
        this.props.dispatch(fetchApplicationArea());
    };

    /** 获取所有学员信息 */
    getUserListInfo = () => {
        let type = this.props.match.params.current;
        const { isStatus, page, pageSize, userInfoIds, examArea, salesName, classId, startDate, endDate, headTeacherId } = this.props;
        let data = {
            page,
            pageSize,
            isGroup: 1,
        };
        isStatus != null && (data.status = isStatus);
        userInfoIds && userInfoIds.length > 0 && (data.userInfoIds = userInfoIds);
        examArea != '全部' && (data.examArea = examArea);
        salesName != '全部' && (data.salesName = salesName);
        classId != '全部' && (data.classId = classId);
        headTeacherId != '全部' && (data.headTeacherId = headTeacherId);
        startDate && (data.startDate = startDate);
        endDate && (data.endDate = endDate)
        this.props.dispatch(bulkOperation(data, type))
    };

    /** 全选 */
    clickPageAll = event => {
        let checked = event.target.checked;
        this.setState({ isAllActive: checked });
        const { content } = this.props;
        let that = this
        content.map(user => {
            setTimeout(function () {
                that.selectUser(user, checked);
            }, 0);
        })
    };

    /** 全选逻辑 */
    selectUser = (user, check) => {
        const { selectArr, userArr } = this.props;
        let currentSelectArr = [...selectArr];
        let currentUserArr = [...userArr];
        if (check) {                                    // 全选逻辑
            if (!userArr.includes(user.contractId)) {
                currentSelectArr.push(user);
                currentUserArr.push(user.contractId);
            }
        } else {
            currentUserArr = [];
            currentSelectArr = [];
        }
        this.props.dispatch(changeTableList(currentUserArr));
        this.props.dispatch(modifyTableList(currentSelectArr));
    };

    /** 单个选择情况 */
    selectCurrentUser = user => {
        const { selectArr, userArr } = this.props;
        let currentSelectArr = [...selectArr];
        let currentUserArr = [...userArr];
        if (!userArr.includes(user.contractId)) {                        // 没有push进去
            currentSelectArr.push(user);
            currentUserArr.push(user.contractId)
        } else {
            currentUserArr = userArr.filter(v => v != user.contractId);
            currentSelectArr = selectArr.filter(v => v.contractId != user.contractId)
        }
        this.props.dispatch(changeTableList(currentUserArr));
        this.props.dispatch(modifyTableList(currentSelectArr));
    };

    /** 获取当前批量操作类型 */
    getCurrentBatchType = () => {
        const { current } = this.props.match.params;
        if (current == 'supervise') return "盯学";
        if (current == 'userCare') return "关怀";
        if (current == 'returnVisit') return "回访";
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    /** 设置日期输入的值 */
    calendarValueChangeHandler = dateString => {
        let currentArr = [...this.props.selectArr];
        if (currentArr && currentArr.length) {
            currentArr.map(item => {
                if (dateString) {
                    item.date = dateString;
                }
            })
        }
        this.props.dispatch(addModifyRemarks(currentArr));
        this.props.dispatch(setCurrentDate(dateString));
    };

    /** 设置日期输入的值 */
    calendarValueChange = async (dateString, key) => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key, value: dateString }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 搜索合同用户 */
    searchContractInform = value => {
        if (!value) return;
        this.props.dispatch(fetchContractUserInform(value));
    };

    /** 改变分页大小 */
    onShowSizeChange = async (current, size) => {
        await this.resetActivePage1();
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'pageSize', value: size }));
        this.getUserListInfo();
    };

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(modifyBatchCurrentRedux({ key: 'page', value: 1 }));
    };

    /** 改变当前页数 */
    handlerPage = async page => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'page', value: page }));
        this.getUserListInfo();
    };

    /** 报考地区筛选 */
    modifyExamArea = async value => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'examArea', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 用户筛选 */
    handleChange = async value => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'userInfoIds', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 销售筛选 */
    modifySales = async value => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'salesName', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 退款筛选 */
    modifyStatus = async value => {
        await this.props.dispatch(modifyBatchCurrentRedux({ key: 'isStatus', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 清空搜索记录 */
    clearRecord = async () => {
        await this.props.dispatch(clearBatchSearch());
        this.getUserListInfo();
    };

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        if (!value) return;
        let data = { title: value }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 处理选择班级  只能选择一个 */
    handleSelectClass = async index => {
        const { classes } = this.props;
        let currentClasses = [{ className: '全部', id: '全部' }].concat(classes);
        let currentClass = currentClasses[index];
        if (currentClass) {
            const { className, id } = currentClass;
            this.props.dispatch(modifyBatchCurrentRedux({ key: 'currentClassName', value: className }));
            this.props.dispatch(modifyBatchCurrentRedux({ key: 'classId', value: id }));
        }
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    handlerTeacher = index => {
        const { teacherDepartment } = this.props;
        let teacherDetails = [{ name: '全部', id: '全部' }].concat(teacherDepartment);
        let selectTeacher = teacherDetails[index];
        this.props.dispatch(modifySelectValue({ key: 'teacherId', value: selectTeacher.id }));
        this.props.dispatch(modifySelectValue({ key: 'teacherName', value: selectTeacher.name }));
    };

    /** 处理回访老师选择框 */
    handlerHuifangTeacher = index => {
        const { operational } = this.props;
        let teacherDetails = [{ name: '全部', id: '全部' }].concat(operational);
        let selectTeacher = teacherDetails[index];
        this.props.dispatch(modifySelectValue({ key: 'teacherId', value: selectTeacher.id }));
        this.props.dispatch(modifySelectValue({ key: 'teacherName', value: selectTeacher.name }));
    };

    /** 处理班主任筛选框 */
    modifyHeadTeachers = async index => {
        const { headTeachers } = this.props;
        let currentHeadTeachers = [{ name: '全部', id: '全部' }].concat(headTeachers);
        let selectHeadTeacher = currentHeadTeachers[index];
        await this.props.dispatch(modifySelectValue({ key: 'headTeacherId', value: selectHeadTeacher.id }));
        await this.props.dispatch(modifySelectValue({ key: 'headTeacherName', value: selectHeadTeacher.name }));
        await this.resetActivePage1();
        this.getUserListInfo();
    }

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    /** 批量更新 */
    bulkUpdate = async () => {
        const { date, status, selectArr, teacherId } = this.props;
        const { current } = this.props.match.params;
        let currentType = this.getCurrentBatchType();
        if (!date) {
            message.warn('请选择日期');
            return;
        };
        if (current == 'supervise' || current == 'returnVisit') {
            if (status == null) {
                message.warn(`请选择${currentType}情况`);
                return;
            };
        };
        if (current == 'returnVisit' || current == 'userCare') {
            if (teacherId == '全部') {
                message.warn(`请选择${currentType}老师`);
                return;
            }
        }
        if (!selectArr || !selectArr.length) {
            message.warn('请选择学员');
            return;
        };
        let currentArr = [...this.props.selectArr];
        if (currentArr && currentArr.length) {
            currentArr.map(item => {
                if (current == 'supervise' || current == 'returnVisit') item.status = status;
                if (current == 'returnVisit' || current == 'userCare') item.teacherId = teacherId;
                item.date = date;
            })
        };
        await this.props.dispatch(addModifyRemarks(currentArr));
        const cb = () => this.props.history.push('/inquiry');
        this.props.dispatch(batchUpdataBase(selectArr, current, cb));
    };

    changeCurrentRemarks = (value, id) => {
        let currentArr = [...this.props.selectArr];
        if (currentArr && currentArr.length) {
            currentArr.map(item => {
                if (item.contractId == id) {
                    item.remarks = value;
                }
            })
        }
        this.props.dispatch(addModifyRemarks(currentArr));
    };

    /** 打开学生信息详情 */
    open = (data) => {
        this.props.dispatch(openModleAction(data))
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = (type, currentType) => (
        [
            {
                title: '客户',
                width: '15%',
                key: 'userName',
                render: (text, record) => (
                    <span className="active" onClick={() => this.open({ key: 'userModle', value: { open: true, id: record.userInfoId } })}>{record.userName || ''}</span>
                ),
            }, {
                title: '班级',
                width: '10%',
                key: 'className',
                render: (text, record) => (
                    <span className="active" onClick={() => this.open({ key: 'classModle', value: { open: true, id: record.classId } })}>{record.className || ''}</span>
                ),
            }, {
                title: '开班日期',
                width: '10%',
                dataIndex: 'startDate',
                key: 'startDate',
            }, {
                title: '是否结课',
                width: '5%',
                key: 'isEnd',
                render: (text, record) => (
                    <span>{record.isEnd ? '是' : '否'}</span>
                ),
            }, {
                title: '班主任',
                width: '5%',
                dataIndex: 'headTeacher',
                key: 'headTeacher',
            }, {
                title: '销售微信',
                width: '8%',
                dataIndex: 'salesWechat',
                key: 'salesWechat',
            }, {
                title: '销售',
                width: '8%',
                dataIndex: 'salesName',
                key: 'salesName',
            }, {
                title: '报考地区',
                width: '8%',
                dataIndex: 'examArea',
                key: 'examArea',
            }, {
                title: '最近一次',
                width: '10%',
                dataIndex: 'latestDate',
                key: 'latestDate',
            }, {
                title: `${(type == 1) ? currentType : '合同'}备注`,
                key: 'userInfoId',
                width: '16%',
                render: (text, record) => (
                    type == 1 ? <Input
                        type="text"
                        placeholder="请输入学员备注"
                        onChange={(event) => { this.changeCurrentRemarks(event.target.value, record.contractId) }}
                    /> : <span>{record.remarks}</span>
                ),
            }, {
                title: '选择',
                width: '5%',
                key: 'contractId',
                render: (text, record) => (
                    <input type="checkbox"
                        data-target={record.contractId}
                        onChange={() => { }}
                        onClick={e => this.selectCurrentUser(record)}
                        checked={this.props.userArr.includes(record.contractId)}
                    />
                ),
            },
        ]
    );

    render() {
        const {
            classModle, userModle, isStatus, content, userArr, selectArr, appLicationArea, salles, userNames, salesName, examArea, userInfoIds, totalElements, teacherDepartment, teacherName, operational, classes, currentClassName, startDate, endDate, headTeachers, headTeacherName
        } = this.props;
        const { current } = this.props.match.params;
        let currentType = this.getCurrentBatchType();
        return (
            <div className="batch-container">
                {userModle.open && <UserInfo
                    updeteCb={this.getUserListInfo}
                    notUpdate={true}
                />}
                {classModle.open && <ClassInfo
                    updeteCb={this.getUserListInfo}
                    notUpdate={true}
                />}

                <Button onClick={this.bulkUpdate} className="position" type="primary">更新</Button>

                <div className="commonSearchBox">
                    <div>
                        <label>日期</label>
                        <DatePicker
                            className="commonW150"
                            format="YYYY-MM-DD"
                            disabledDate={current => (current && current > moment().endOf('day'))}
                            value={this.calendarValuePipe(this.props.date)}
                            onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString) }}
                        />
                    </div>

                    {
                        current === 'supervise' &&
                        <div>
                            <label>{`${currentType}情况`}</label>
                            <Select
                                className="commonW150" value={this.props.status}
                                onChange={value => this.props.dispatch(setCurrentStatus(value))}
                            >
                                {
                                    getStatus.map((item, index) => (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {
                        current === 'userCare' &&
                        <div>
                            <label>关怀老师</label>
                            <Select
                                className="commonW150"
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(teacherFame, value) }}
                                value={teacherName}
                                onChange={value => this.handlerTeacher(value)}
                            >
                                {
                                    [{ name: '全部', id: '全部' }].concat(teacherDepartment).map((item, index) => (
                                        <Option key={index} value={index}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {
                        current === 'returnVisit' &&
                        <div>
                            <label>回访老师</label>
                            <Select
                                className="commonW150"
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(operation, value) }}
                                value={teacherName}
                                onChange={value => { this.handlerHuifangTeacher(value) }}
                            >
                                {
                                    [{ name: '全部', id: '全部' }].concat(operational).map((item, index) => (
                                        <Option key={index} value={index}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {
                        current === 'returnVisit' &&
                        <div>
                            <label>{`${currentType}情况`}</label>
                            <Select
                                className="commonW150" value={this.props.status}
                                onChange={value => this.props.dispatch(setCurrentStatus(value))}
                            >
                                {
                                    getHuifangStatus.map((item, index) => (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                </div>
                <Table
                    title={() => <div className="commonTableTitle">
                        <span>已选学员</span>
                        <span>已选择<span style={{ color: 'red' }}>{selectArr.length}</span>位学员</span>
                    </div>}
                    dataSource={selectArr}
                    rowKey={record => record.contractId}
                    columns={this.generateTableColumns(1, currentType)}
                    pagination={false}
                />
                <div className="commonSearchBox">
                    <div>
                        <label>客户</label>
                        <Select
                            style={{ width: '400px' }}
                            mode="multiple"
                            showSearch
                            placeholder="请输入客户姓名或手机号"
                            filterOption={false}
                            onSearch={value => { this.searchContractInform(value) }}
                            onChange={value => { this.handleChange(value) }}
                        >
                            {
                                userNames && userNames.map((item, index) => (
                                    <Option key={index} value={item.id}>{`${item.name}(${item.mobile})`}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班级</label>
                        <Select
                            showSearch
                            style={{ width: 400 }}
                            filterOption={false}
                            placeholder="请输入班级关键词进行查询"
                            value={currentClassName}
                            onSearch={value => { this.searchClass(value) }}
                            onChange={value => { this.handleSelectClass(value) }}
                        >
                            {
                                [{ className: '全部', id: '全部' }].concat(classes).map((item, index) => (
                                    <Option key={index} value={index}>{item.className}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>起始时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(startDate)}
                            onChange={(date, dateString) => { this.calendarValueChange(dateString, 'startDate'); }}
                        />
                    </div>
                    <div>
                        <label>截止时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(endDate)}
                            onChange={(date, dateString) => { this.calendarValueChange(dateString, 'endDate'); }}
                        />
                    </div>
                    <div>
                        <label>是否退款</label>
                        <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={isStatus}
                            onChange={event => { this.modifyStatus(event) }}
                        >
                            <Option value={null}>全部</Option>
                            <Option value={1}>是</Option>
                            <Option value={0}>否</Option>
                        </Select>
                    </div>
                    <div>
                        <label>销售</label>
                        <Select
                            style={{ width: 150 }}
                            showSearch
                            filterOption={false}
                            value={salesName}
                            onSearch={value => { this.employeeSearch(sell, value) }}
                            onChange={event => { this.modifySales(event) }}
                        >
                            {
                                salles && [{ name: '全部' }].concat(salles).map((item, index) => (
                                    <Option key={index} value={item.name}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班主任</label>
                        <Select
                            showSearch
                            filterOption={false}
                            style={{ width: 150 }}
                            value={headTeacherName}
                            onSearch={value => this.employeeSearch(classTeacher, value)}
                            onChange={event => { this.modifyHeadTeachers(event) }}
                        >
                            {
                                [{ name: '全部', id: '全部' }].concat(headTeachers).map((item, index) => (
                                    <Option key={index} value={index}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>报考地区</label>
                        <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={examArea}
                            onChange={event => { this.modifyExamArea(event) }}
                        >
                            {
                                appLicationArea && ['全部'].concat(appLicationArea).map((item, index) => (
                                    <Option key={index} value={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <Button type="danger" onClick={this.clearRecord}>清空搜索记录</Button>
                    </div>
                </div>

                <Table
                    title={() => <div className="commonTableTitle">
                        <span>已分班学员</span>
                        <Checkbox checked={this.state.isAllActive} onChange={this.clickPageAll}>全选</Checkbox>
                    </div>}
                    dataSource={content}
                    rowKey={record => record.contractId}
                    columns={this.generateTableColumns(0)}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onShowSizeChange: this.onShowSizeChange,
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: totalElements,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.handlerPage,
                        showQuickJumper: true,
                    }}
                />;
            </div>
        )
    }
}

function mapStatetoProps(state) {

    const {
        classModle,
        userModle
    } = state.inquiryReducer;

    const {
        ...initialState
    } = state.batchReducer;

    const {
        appLicationArea,
        salles
    } = state.contractOrder;

    return {
        classModle,
        userModle,
        appLicationArea,
        salles,
        ...initialState
    };
}

export default connect(mapStatetoProps)(Batch);