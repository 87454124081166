import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Tooltip, Select, Input, Button, message, Modal, DatePicker } from 'antd';
import {
    fetchClassList,
    changeActivePage,
    changePageSize,
    fetchClassLabel,
    changeLabel,
    fetchHeadTeacher,
    changeHeadTeacher,
    changeSubject,
    changeTitle,
    fetchCourseList,
    saveOrUpdateClass,
    changeTeacher,
    resetSearchStatus,
    changeHeadTeacherId
} from './actions';
import { fetchCourseTeachers } from '../CourseList/action';
import { set_current_page, set_current_day, set_filter_status } from "../ClassDetail/actions";
import { fetchDepartmentList } from "../ContractOrder/action";
import { checkPermission } from '../../utils/utils';
import moment from 'moment';
import Time from './time';
const Option = Select.Option;

const classTeachers = '全程部,师资堂';

const isEndStatus = [{ label: '全部', value: '全部' }, { label: '是', value: 1 }, { label: '否', value: 0 }];

class ClassList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeObj: {},                // 关怀信息
            isModalVisible: false,      // 关怀模态框
            isTrue: false,              // 模态框科目与课程类型是否一致
            showModal: false,           // 是否显示班级编辑弹框
            className: '',              // 模态班级名
            classNumber: '',            // 班级号
            classTeacher: '',           // 班级老师
            classLabel: '',             // 班级标签
            classCourse: '',            // 班级课程ID数组
            classId: '',                // 班级ID
            type: '',                   // 班级类型
            isEnd: '全部',               // 是否结课
            startDate: '',              // 开班日期
            teacherId: '全部',
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchClassLabel(this.errMessageHandler));      // 申论批改人员无需加载班级标签
        this.props.dispatch(fetchHeadTeacher(this.errMessageHandler));
        this.getClassListFun();
        this.props.dispatch(fetchCourseList(this.errMessageHandler));      // 申论批改人员无需加载课程列表
        this.props.dispatch(set_current_page());                                          // 将班级详情列表页面的第几页初始化为第一页
        this.props.dispatch(set_current_day());                                                   // 将班级详情页面的第几天初始化为第一题
        this.props.dispatch(fetchCourseTeachers());
        this.props.dispatch(set_filter_status());                                                // 将班级详情页面的作业筛选状态初始化为全部
    }

    errMessageHandler = errorMes => {
        message.error(errorMes);
    }

    getTooltip = (content) => {
        return <div>{content}</div>
    };

    /** antd table 的数据格式约定配置 */
    generateColumns() {
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'className',
                width: '15%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.className)}>
                        <span className="title">{record.className}</span>
                    </Tooltip>
                ),
            },
            {
                title: '关联课程',
                dataIndex: 'courseName',
                width: '15%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.courseName)}>
                        <span className="title">{record.courseName}</span>
                    </Tooltip>
                ),
            },
            {
                title: '开班日期',
                dataIndex: 'startDate',
                width: '10%'
            },
            {
                title: '科目',
                width: '5%',
                render: (record) => {
                    return (
                        <span>
                            {record.type == 0 && '行测'}
                            {record.type == 1 && '申论'}
                            {record.type == 2 && '面试'}
                            {record.type == 3 && '公基'}
                            {record.type == 4 && '职测'}
                            {record.type == 5 && '综应'}
                        </span>
                    )
                }
            },
            {
                title: '结课',
                width: '5%',
                render: (record) => {
                    return (
                        <span>
                            {record.isEnd == 0 && '否'}
                            {record.isEnd == 1 && '是'}
                        </span>
                    )
                }
            },
            {
                title: '班主任',
                dataIndex: 'teacher',
                width: '5%'
            },
            {
                title: '老师',
                dataIndex: 'teachers',
                width: '5%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.teachers)}>
                        <span className="ellipsis1" style={{width: '4em'}}>{record.teachers}</span>
                    </Tooltip>
                ),
            },
            {
                title: '学员数',
                dataIndex: 'studentNumber',
                key: 'studentNumber',
                width: '5%'
            },
            {
                title: '操作',
                width: '15%',
                render: (text, record) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('clazz:exercise:page') &&
                                <Link to={`/classExercise?classId=${record.id}&classType=${record.type}&classNo=${record.classNo}&isShuaTi=${record.label === '刷题班' ? '1' : '0'}&className=${record.className}`}>
                                    <Button size="small" type="primary">查看练习</Button>
                                </Link>
                            }
                            {checkPermission('sys:clazz:info') && <Link to={`/classDetail?classId=${record.id}`}>
                                <Button size="small" type="primary">查看学员</Button>
                            </Link>}
                            {checkPermission('sys:clazz:edit') &&
                                <Button size="small" type="primary" onClick={() => { this.updateClass(record) }}>修改班级</Button>
                            }
                            {checkPermission('care:date:list') &&
                                <Button size="small" type="primary" onClick={() => {this.openTime(record)}}>分配关怀</Button>
                            }
                        </div>
                    );
                }
            }
        ];
        return columns;
    }

    openTime = (record) => {
        this.setState({timeObj: record, isModalVisible: true})
    }

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>班级列表</span>
            {checkPermission('sys:clazz:edit') && <Button type="primary" onClick={this.showModalHandler}>添加班级</Button>}
        </div>
    );

    /** 分页组件改变页码 */
    paginationChange = async (page, size) => {
        await this.props.dispatch(changeActivePage(page));
        await this.props.dispatch(changePageSize(size));
        this.getClassListFun();
    }

    /** 分页组件改变每页条数 */
    // showSizeChange = async (current, size) => {
    //     await this.props.dispatch(changePageSize(size));
    //     await this.resetActivePage1();
    //     this.getClassListFun();
    // }

    /** 根据已选的条件获取班级列表数据 */
    getClassListFun = () => {
        let params = {};
        this.props.label && this.props.label != '全部' && (params.label = this.props.label);
        this.props.activePage && (params.page = this.props.activePage);
        this.props.pageSize && (params.pageSize = this.props.pageSize);
        this.props.teacher && this.props.teacher != '全部' && (params.teacher = this.props.teacher);
        this.props.teacherId != '全部' && (params.teacherId = this.props.teacherId);
        this.props.title && (params.title = this.props.title);
        this.props.subject !== '' &&  (params.type = this.props.subject);
        this.props.dispatch(fetchClassList(params, this.errMessageHandler));
    }

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(changeActivePage(1));
    }

    /** 选择班级标签 */
    changeLabelHandler = async value => {
        await this.props.dispatch(changeLabel(value));
        await this.resetActivePage1();
        this.getClassListFun();
    }

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    /** 选择班主任 */
    changeHeadTeacherHandler = async index => {
        const { headTeachers } = this.props;
        let currentTeachers = [{ name: '全部', id: '全部' }].concat(headTeachers);
        let currentTeacher = currentTeachers[index];
        await this.props.dispatch(changeHeadTeacher(currentTeacher.name));
        await this.props.dispatch(changeHeadTeacherId(currentTeacher.id));
        await this.resetActivePage1();
        this.getClassListFun();
    }

    /** 新增或修改选择班主任 */
    changeHeadHandler = index => {
        const { headTeachers } = this.props;
        let currentTeachers = [{name: '全部', id: '全部'}].concat(headTeachers);
        let currentTeacher = currentTeachers[index];
        this.setState({ classTeacher: currentTeacher.name, teacherId: currentTeacher.id });
    };

    /** 选择科目 */
    changeSubjectHandler = async value => {
        await this.props.dispatch(changeSubject(value));
        await this.resetActivePage1();
        this.getClassListFun();
    }

    /** 选择老师 */
    changeTeacherHandler = async value => {
        await this.props.dispatch(changeTeacher(value));
        await this.resetActivePage1();
        this.getClassListFun();
    }

    /** 清空搜索条件 */
    resetSearch = async () => {
        await this.props.dispatch(resetSearchStatus());
        this.getClassListFun();
    }

    /** 输入班级名关键词 */
    classNameHandler = async event => {
        await this.props.dispatch(changeTitle(event.target.value));
    };

    useClassNameForSearch = async () => {
        await this.resetActivePage1();
        this.getClassListFun();
    }

    getType = (option) => {
        if(option=='XINGCE') return 0;
        if(option=='SHENLUN') return 1;
        if(option=='INTREVIEW') return 2;
        if(option=='GONG_JI') return 3;
        if(option=='ZHI_CE') return 4;
        if(option=='ZONG_YING') return 5;
    }

    classInputHandler = (value, key, option) => {
        let {type} = this.state;
        this.setState({ [key]: value });
        if(key=='classCourse') {
            if(type!=this.getType(option) && type!='') {
                this.setState({ isTrue: true });
                message.error('科目和班级课程类型不一致，请重新选择');
            } else {
                this.setState({ isTrue: false });
            }
        }
    }

    showModalHandler = () => {
        this.setState({ showModal: true });
    }

    modalCancelHandler = () => {
        this.setState({
            isTrue: false,
            showModal: false,
            className: '',
            classNumber: '',
            classCourse: '',
            classLabel: '',
            classTeacher: '',
            classId: '',
            type: '',
            isEnd: '全部',
            startDate: '',
            id: '',
            teacherId: '全部',
        });
    }

    handleOk = async () => {
        if (!this.verifyInput()) return;
        let params = {
            className: this.state.className,
            classNo: this.state.classNumber,
            courseId: this.state.classCourse,
            label: this.state.classLabel,
            teacher: this.state.classTeacher,
            type: this.state.type,
            isEnd: this.state.isEnd,
            startDate: this.state.startDate,
            id: this.state.id,
            teacherId: this.state.teacherId,
        };
        this.state.classId && (params.id = +this.state.classId);
        const successCb = () => {
            this.setState({
                showModal: false,
                className: '',
                classNumber: '',
                classCourse: '',
                classLabel: '',
                classTeacher: '',
                classId: '',
                type: '',
                isEnd: '全部',
                startDate: '',
                id: '',
                teacherId: '全部',
            });
            message.success('操作成功');
        };
        await this.props.dispatch(saveOrUpdateClass(params, successCb, this.errMessageHandler));
        this.getClassListFun();
    }

    updateClass = (classObj = {}) => {
        this.setState({
            type: classObj.type,
            className: classObj.className,
            classNumber: classObj.classNo,
            classTeacher: classObj.teacher,
            classLabel: classObj.label,
            classCourse: classObj.courseId,
            classId: classObj.id,
            startDate: classObj.startDate,
            isEnd: classObj.isEnd,
            id: classObj.id,
            teacherId: classObj.teacherId,
            showModal: true
        });
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';


    verifyInput = () => {
        let result = true;
        if (!this.state.className) {
            result = false;
            message.error('请填写班级名')
            return result;
        }
        if (!this.state.classNumber) {
            result = false;
            message.error('请填写班级号')
            return result;
        }
        if (!this.state.classTeacher) {
            result = false;
            message.error('请填写班级老师')
            return result;
        }
        if (!this.state.classLabel) {
            result = false;
            message.error('请填写班级标签')
            return result;
        }
        if (this.state.type === '') {
            result = false;
            message.error('请填写科目')
            return result;
        }
        if (!this.state.classCourse) {
            result = false;
            message.error('请填写班级课程')
            return result;
        }
        if (!this.state.startDate) {
            result = false;
            message.error('请填写开班日期')
            return result;
        }
        if (this.state.isEnd == '全部') {
            result = false;
            message.error('请选择是否结课')
            return result;
        }
        if(this.state.isTrue) {
            message.error('科目和班级课程类型不一致，请重新选择');
            result = false;
            return result;
        }
        return result;
    }

    /** 关闭关怀 */
    handleCancel = () => {
        this.setState({isModalVisible: false});
    }

    render() {
        let columns = this.generateColumns();
        let {isModalVisible, timeObj} = this.state;
        const {
            responseObj, onLoading, activePage, pageSize, labels, subject,
            onLoadingLabel, label, headTeachers, headTeacher, title, teacher, teachers, subjects
        } = this.props;

        return (
            <div>
                {checkPermission('sys:clazz:page') && <div className="commonSearchBox">
                    <div>
                        <label>班级标签</label>
                        <Select
                            onChange={this.changeLabelHandler}
                            value={label}
                            disabled={onLoadingLabel}
                            className="commonW150">
                            {
                                labels.map(item => (
                                    <Option value={item} key={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班主任</label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={false}
                            onSearch={value => { this.employeeSearch(classTeachers, value) }}
                            onChange={this.changeHeadTeacherHandler}
                            value={headTeacher}
                            className="commonW150"
                        >
                            {
                                [{ name: '全部', id: '全部' }].concat(headTeachers).map((item, index) => (
                                    <Option value={index} key={index}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>老师</label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={this.changeTeacherHandler}
                            value={teacher}
                            disabled={teachers.length < 1}
                            className="commonW150"
                        >
                            {
                                ['全部'].concat(teachers).map(item => (
                                    <Option value={item} key={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>科目</label>
                        <Select
                            className="commonW150"
                            value={subject}
                            onChange={this.changeSubjectHandler}
                        >
                            {
                                subjects.map(item => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班级查询</label>
                        <Input onPressEnter={() => this.useClassNameForSearch()} value={title} placeholder="请输入班级名关键词" onChange={this.classNameHandler} className="commonW400" />
                    </div>
                    <div>
                        <Button disabled={onLoading} type="primary" onClick={this.useClassNameForSearch}>搜索</Button>
                    </div>
                    <div>
                        <Button disabled={onLoading} type="danger" onClick={this.resetSearch}>重置</Button>
                    </div>
                </div>}

                <Table
                    columns={columns}
                    dataSource={responseObj.content}
                    rowKey={record => record.id}
                    title={this.renderHeader}
                    loading={onLoading}
                    pagination={{
                        current: activePage,
                        pageSize: pageSize,
                        total: responseObj.totalElements,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.paginationChange,
                        // onShowSizeChange: this.showSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                />

                <Modal destroyOnClose={true} width={800} title="分配老师关怀任务" visible={isModalVisible} footer={null} onCancel={()=>this.handleCancel()}>
                    <Time classId={timeObj.id} className={timeObj.className} />
                </Modal>

                <Modal
                    title="新增（或修改）班级"
                    visible={this.state.showModal}
                    onOk={this.handleOk}
                    onCancel={this.modalCancelHandler}
                >
                    <div className="commonSearchBox" style={{padding: 0, margin: 0}}>
                        <div>
                            <label>班级名称</label>
                            <Input
                                placeholder="请输入班级名"
                                className="commonW150"
                                value={this.state.className}
                                onChange={event => { this.classInputHandler(event.target.value, 'className') }}
                            />
                        </div>

                        <div>
                            <label>班级号</label>
                            <Input
                                placeholder="请输入班级号"
                                className="commonW150"
                                value={this.state.classNumber}
                                onChange={event => { this.classInputHandler(event.target.value, 'classNumber') }}
                            />
                        </div>

                        <div>
                            <label>班主任</label>
                            <Select
                                className="commonW150"
                                showSearch
                                optionFilterProp="children"
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(classTeachers, value) }}
                                onChange={value => { this.changeHeadHandler(value) }}
                                value={this.state.classTeacher}
                            >
                                {
                                    [{ name: '全部', id: '全部' }].concat(headTeachers).map((item, index) => (
                                        <Option value={index} key={index} disabled={item.id == '全部'}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>

                        <div>
                            <label>标签名</label>
                            <Select
                                className="commonW150"
                                onChange={value => { this.classInputHandler(value, 'classLabel') }}
                                disabled={labels.length <= 0}
                                value={this.state.classLabel}
                            >
                                {
                                    labels.filter(n => n != '全部').map((item, index) => (
                                        <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>

                        <div>
                            <label>科目</label>
                            <Select
                                className="commonW150"
                                onChange={value => { this.classInputHandler(value, 'type') }}
                                value={this.state.type}
                            >
                                {
                                    subjects.filter(n => n.value !== '').map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>

                        <div>
                            <label>是否结课</label>
                            <Select
                                className="commonW150"
                                onChange={value => { this.classInputHandler(value, 'isEnd') }}
                                value={this.state.isEnd}
                            >
                                {
                                    isEndStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>

                        <div>
                            <label>开班日期</label>
                            <DatePicker
                                className="commonW150"
                                format="YYYY-MM-DD"
                                placeholder="请选择日期"
                                value={this.calendarValuePipe(this.state.startDate) || ''}
                                onChange={(date, dateString) => { this.classInputHandler(dateString, 'startDate') }}
                            />
                        </div>

                        <div>
                            <label>班级课程</label>
                            <Select
                                showSearch={true}
                                dropdownMatchSelectWidth={false}
                                className="commonW400"
                                placeholder="请选择班级课程"
                                onChange={(value, option) => { this.classInputHandler(value, 'classCourse', option.type) }}
                                disabled={this.props.courseList.length <= 0}
                                value={this.state.classCourse}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    this.props.courseList.map((item, index) => (
                                        <Option key={index} value={item.id} type={item.type}>{item.title}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                </Modal>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.classList,
    teachers: state.courseList.teachers
});


export default connect(mapStateToProps)(ClassList);
