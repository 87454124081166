import apiRequest from '../../common/request';

const PREFIX = "ROLE";

export const ROLE_TREE = PREFIX + "ROLE_TREE";
export const ROLE_USER = PREFIX + "ROLE_USER";
export const TABLE_LOADING = PREFIX + "TABLE_LOADING";

/** 加载开始 */
function tableLoading() {
  return {
    type: TABLE_LOADING
  }
}

function role_tree(data) {
  return {
    type: ROLE_TREE,
    data
  }
};

function role_user(data) {
  return {
    type: ROLE_USER,
    data
  }
};

/** 添加用户 */
export const addRoleUser = async (params = {}) => {
  let data = {
    parentId: params.parentId,
    name: params.name,
    mobile: params.mobile,
    department: params.department,
  };
  params.id && (data.id = params.id);

  let config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let url = `/gk/ops/employee/saveOrUpdate`;
  let res = await apiRequest(url, config);
  return res;
};

/** 获取用户列表 */
export const getRoleUser = (params = {}) => {
  let data = {
    page: params.page || 1,
    pageSize: params.pageSize || 10
  };
  params.id && (data.id = params.id);
  params.name && (data.name = params.name);
  params.department && (data.department = params.department);

  let config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let url = `/gk/ops/employee/page`;

  return dispatch => {
    dispatch(tableLoading());
    apiRequest(url, config)
      .then(json => {
        dispatch(role_user(json.obj||[]));
      })
      .catch(error => console.log(error));
  }
};

/** 获取树结构 */
export const getRoleTree = () => {
  let url = `/gk/ops/employee/treeSelect`;
  return dispatch => {
    apiRequest(url)
      .then(json => {
        dispatch(role_tree(json.obj));
      })
      .catch(error => console.log(error));
  }
};

