import * as CNT from './action';

export function courseClassList(state = {
    isFetching: false,
    items: [],
    title: '',
}, action) {
    switch (action.type) {
        case CNT.COMMON_CHANGE:
            return {...state, [action.key]: action.val};
        case CNT.REQUEST_LIST:
            return {...state, isFetching: true};
        case CNT.RECEIVE_LIST:
            return {...state, isFetching: false, items: action.list};
        case CNT.RECEIVE_DELETE:
            return {...state, items: [...state.items.filter(item => item.id != action.id)]};
        case CNT.UPDATE_DAY:
            let items = state.items;
            let item = items[action.index];
            item['day'] = action.value;
            return {...state, items: items};
        default:
            return state;
    }
}