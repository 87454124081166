import apiRequest from '../../common/request';
import {message} from 'antd';
import Cookies from 'js-cookie';

const PREFIX = 'INQUIRY_ACTION';

export const SET_STUDENT_CLASS = PREFIX + 'SET_STUDENT_CLASS';
export const CHANGE_CURRENT_TAB = PREFIX + 'CHANGE_CURRENT_TAB';
export const CHANGE_CURRENT_TAB_VALUE = PREFIX + 'CHANGE_CURRENT_TAB_VALUE';
export const GET_CLASS_DETAILS = PREFIX + 'GET_CLASS_DETAILS';
export const DEAL_SELECT_REDUX = PREFIX + 'DEAL_SELECT_REDUX';
export const HANDLE_CURRENT_CLASS = PREFIX + 'HANDLE_CURRENT_CLASS';
export const CLEAR_SEARCH_CRITERIA = PREFIX + 'CLEAR_SEARCH_CRITERIA';
export const OPEN_MODLE = PREFIX + 'OPEN_MODLE';

const set_student_class = data => ({ type: SET_STUDENT_CLASS, data });

const get_class_details = data => ({ type: GET_CLASS_DETAILS, data });

export const openModleAction = data => ({ type: OPEN_MODLE, key: data.key, value: data.value });

export const clearSearchCriteria = () => ({ type: CLEAR_SEARCH_CRITERIA });

export const handleCurrentClass = data => ({ type: HANDLE_CURRENT_CLASS, data });

export const dealSelectRedux = data => ({ type: DEAL_SELECT_REDUX, key: data.key, value: data.value });

export const changeCurrentTab = value => ({ type: CHANGE_CURRENT_TAB, value });

export const changeCurrentTabValue = data => ({ type: CHANGE_CURRENT_TAB_VALUE, value: data.value });

/** 查询老师需要关怀的班级 */
export const fetchCareClass = (teacherId, date) => {
    let url = `/gk/ops/userCare/class/data/${teacherId}/${date}`;
    let config = {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        let res = json.obj || [];
        dispatch(dealSelectRedux({ key: 'careClassList', value: res }));
    }
};

export const fetchSearchClass = data => {
    let url = `/gk/ops/student/class/condition`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        let Obj = json.obj || {};
        dispatch(set_student_class(Obj));
    }
};

/** 获取不同类型列表数据 */
export const getInquiryList = (data, type) => {
    let url = `/gk/ops/supervise/class/details`;
    if (type === 'ask') url = `gk/ops/ask/class/details`;
    if (type === 'userCare') url = `gk/ops/userCare/class/details`;
    if (type === 'returnVisit') url = `gk/ops/visit/class/details`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        let Obj = json.obj || {};
        dispatch(get_class_details(Obj));
    }
};

/** 新增或修改 */
export const fetchAddStatus = (data, type, successCb, successPull, errCb) => {
    let url = `/gk/ops/supervise/saveOrUpdate`;
    if (type === 'ask') url = `/gk/ops/ask/saveOrUpdate`;
    if (type === 'userCare') url = `/gk/ops/userCare/saveOrUpdate`;
    if (type === 'returnVisit') url = `/gk/ops/visit/saveOrUpdate`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        apiRequest(url, config).then(json => {
            successCb && successCb();
            successPull && successPull();
        }).catch(e => {
            console.log('打印错误',e.errorMes);
        });
    }
};

/** 导出用户数据 */
export const exportUsersData = (data, current, type) => {
    let url = '/gk/ops/contract/excel/exportSuperviseInfo';
    if (current === 'userCare') url = `/gk/ops/contract/excel/exportUserCareInfo`;
    if (current === 'ask') url = `/gk/ops/contract/excel/exportUserAskInfo`;
    if (current === 'returnVisit') url = `/gk/ops/contract/excel/exportReturnVisitInfo`;
    let config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Sin-Access-Token': Cookies.get('accessToken'),
        'Sin-UserAgent-Sign': 'SintureGK-WEB',
        'Sin-Client-Type': 3,
        Accept: '*/*',
      },
      body: JSON.stringify(data),
      credentials: 'include'
    };
    return dispatch => {
        fetch(url, config).then(response => {
            response.blob().then(blob => {
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                let fileName = `${type}数据.xls`;
                a.download = fileName;
                document.body.appendChild(a).click();
                window.URL.revokeObjectURL(url);
            })
        }).catch(err => {
            message.error('下载出错');
        })
    }
}
