import apiRequest from '../../common/request';

const PREFIX = "POSITION_LIST";

export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const RECEIVE_QUESTIONS_YEARS = PREFIX + "RECEIVE_QUESTIONS_YEARS";
export const RECEIVE_QUESTIONS_AREAS = PREFIX + "RECEIVE_QUESTIONS_AREAS";
export const RECEIVE_QUESTIONS_TYPES = PREFIX + "RECEIVE_QUESTIONS_TYPES";
export const RECEIVE_QUESTIONS_SOURCES = PREFIX + "RECEIVE_QUESTIONS_SOURCES";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const CHANGE_COMMON = PREFIX + "CHANGE_COMMON";
export const RESET_FILTER_STATUS = PREFIX + 'RESET_FILTER_STATUS';              // 重置筛选状态

export const resetFilterStatus = () => ({
    type: RESET_FILTER_STATUS
});

export const changeCommon = data => ({
    type: CHANGE_COMMON,
    key: data.key,
    val: data.val
});

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        positionList: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        numberOfElements: data.numberOfElements,
        receiveAt: Date.now()
    }
}

function receive_question_years(years) {
    return {
        type: RECEIVE_QUESTIONS_YEARS,
        years
    }
}

function receive_question_areas(areas) {
    return {
        type: RECEIVE_QUESTIONS_AREAS,
        areas
    }
}

function receive_question_types(types) {
    return {
        type: RECEIVE_QUESTIONS_TYPES,
        types
    }
}

export function fetchQuestionAreas() {
    return dispatch => {
        apiRequest('/gk/ops/position-condition/queryCondition/1', { credentials: 'include' })
            .then(json => {
                let _arr = json.obj[0]?.condition || []
                let _area = []
                for (let index = 0; index < _arr.length; index++) {
                    _area.push(_arr[index].name);
                }
                dispatch(receive_question_areas(_area));
            })
            .catch(error => console.log(error));
    }
}

export function fetchQuestionTypes() {
    return dispatch => {
        apiRequest('/gk/ops/position-condition/queryCondition/2', { credentials: 'include' })
            .then(json => {
                let _arr = json.obj || []
                let _area = []
                for (let index = 0; index < _arr.length; index++) {
                    _area.push(_arr[index].name);
                }
                dispatch(receive_question_types(_area));
            })
            .catch(error => console.log(error));
    }
}

export function fetchQuestions(params, cb) {
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10,
    }
    params.age ? data.age = params.age : ''; 
    params.deptCode ? data.deptCode = params.deptCode : '';
    params.deptName ? data.deptName = params.deptName : '';
    params.education ? data.education = params.education : '';
    params.id !== '-1' ? data.categoryId = params.id : '';
    params.major ? data.major = params.major : '';
    data.page = params.page || 1;
    data.pageSize = params.pageSize || 10;
    params.positionCode ? data.positionCode = params.positionCode : '';
    params.positionName ? data.positionName = params.positionName : '';
    params.qdesc ? data.qdesc = params.qdesc : '';
    params.workedYear ? data.workedYear = params.workedYear : '';
    params.year ? data.year = params.year : 0;
    if (params.province && params.province.length > 0) {
        data.areas = params.province;
    }
    if (params.educations && params.educations.length > 0) {
        data.educations = params.educations;
    }
    if (params.classify && params.classify.length > 0) {
        data.types = params.classify;
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_questions());
        apiRequest('/gk/ops/positions/page', config)
            .then(json => {
                dispatch(receive_questions(json.obj));
                cb && cb();
            })
            .catch(error => console.log(error));
    }
}
