import { message } from 'antd';
import apiRequest from '../../common/request';

// 查询  已分配  用户角色列表 [sys:role:list]
export const getSysRoleList = async (params) => {
	let data = {
		page: params.page || 1,
		pageSize: params.pageSize || 10,
        roleId:  params.roleId
	}
    params.mobile && (data.mobile = params.mobile) || '';
    params.nickName && (data.nickName = params.nickName) || '';

	let url = `gk/ops/system/role/authUser/allocatedList`;
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);    
    return obj.obj;
}

// 查询  未分配  用户角色列表 [sys:role:list]
export const getUnSysRoleList = async (params) => {
	let data = {
		page: params.page || 1,
		pageSize: params.pageSize || 10,
        roleId:  params.roleId
	}
    params.mobile && (data.mobile = params.mobile) || '';
    params.nickName && (data.nickName = params.nickName) || '';

	let url = `gk/ops/system/role/authUser/unallocatedList`;
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);    
    return obj.obj;
}

/** 批量授权用户 [sys:role:update] */
export const sysRoleUser = async (params) => {
    let data = {
        roleId: params.roleId,
        userIds:  params.userIds
	}
	let url = `/gk/ops/system/role/authUsers`;
    let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);
    if(obj.status==1){
		message.success('授权成功');
	}   
    return obj.obj;
}

/** 取消授权用户 [sys:role:update] */
export const systemDeleteUser = async (params,roleId) => {
    let data = {
        roleId:  roleId*1,
        userId: params.id

	}
	let url = `/gk/ops/system/role/authUser/cancel`;
    let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);
    if(obj.status==1){
		message.success('取消授权成功');
	}   
    return obj.obj;
}
