import apiRequest from '../../common/request'

const PREFIX = "JIAODIAN_INTERVIEW";

export const RECEIVE_INTERVIEW_AREAS = PREFIX + "RECEIVE_INTERVIEW_AREAS";
export const RECEIVE_INTERVIEW_CONDITION = PREFIX + "RECEIVE_INTERVIEW_CONDITION";
export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const RESET_SEARCH_STATUS = PREFIX + "RESET_SEARCH_STATUS";
export const CHANGE_COMMON = PREFIX + "CHANGE_COMMON";

export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

export const resetSearchStatus = () => ({ type: RESET_SEARCH_STATUS });

export const changeCommon = data => ({
    type: CHANGE_COMMON,
    key: data.key,
    val: data.val
});

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        receiveAt: Date.now()
    }
}

export function fetchQuestions(params, cb) {
    let data = { ...params };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_questions());
        apiRequest('/gk/ops/interview/page', config)
            .then(json => {
              dispatch(receive_questions(json.obj));
              cb && cb();
            })
            .catch(error => console.log(error));
    }
}

function receive_interview_areas(areas){
    return {
        type: RECEIVE_INTERVIEW_AREAS,
        areas
    }
}

function receive_interview_condition(condition){
    return {
        type: RECEIVE_INTERVIEW_CONDITION,
        condition
    }
}

/** 面试地区列表 */
export function fetchInterviewAreas(){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
      apiRequest('/gk/ops/interview/area', config)
        .then(json => {
            dispatch(receive_interview_areas(json.obj));
        })
        .catch(error => console.log(error));
    }
}

/** 面试分类列表 */
export function fetchInterviewCondition(){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
      apiRequest('/gk/ops/interview/condition', config)
        .then(json => {
            dispatch(receive_interview_condition(json.obj));
        })
        .catch(error => console.log(error));
    }
}
