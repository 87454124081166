import React from "react";
import { connect } from "react-redux";
import { Spin, Breadcrumb, Card, InputNumber, message, Select, Switch, Input, Button, Radio } from 'antd';
import Editor from "../../components/Editor/index";
import { fetchCourseCategories } from "../ModuleSetting/actions";
import VideoPicker from '../../components/VideoPicker';
import ImagePicker from '../../components/ImagePicker';
import { checkPermission, validateUtil } from '../../utils/utils';
import { fetchCourseTeachers, fetchCourseLabels } from "../CourseList/action";
import CategorySelectCard from "../../components/CategorySelectCard/index";
import CoursenumCalendar from "../../components/CoursenumCalendar/index";
import { OSS_PUB_GK_NEWS } from '../../common/consts';

import './index.scss';

import {
    fetchCourse,
    updateCourse,
    fill_banner,
    reset_info,
    input_property,
    setIsFetchingFalse,
    setCourseNums,
    setUpdateStatus,
    notSetUpdateStatus
} from "./action";

const Option = Select.Option;

const subjectType = [
    { label: '行测', value: 0 },
    { label: '申论', value: 1 },
    { label: '公基', value: 3 },
    { label: '职测', value: 4 },
    { label: '综应', value: 5 },
];

class CourseInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showImageModal: false,
            showVideoModal: false,
            size: null,
        };
        this.selfInit();
    }

    /** 自身普通状态初始化 */
    selfInit() {
        this.k2v = {
            bannerType: '课程轮播图类型', bookDesc: '配套教材', categoryIds: '分类目录',
            classDesc: '课程介绍', courseBanner: 'banner图', courseNums: '发布第几天到第几天的课程',
            jifenUse: '是否可用积分购买', label: '标签', limits: '限制人数', packageSend: '是否需要发货',
            period: '开始时间-结束时间', price: '价格', publish: '是否上架', recommend: '是否推荐课程', target: '收益和适合对象', teacherDesc: '授课老师',
            teachers: '任课老师(最多七个)', title: '课程标题', remark: '备注', classCount: '课时', type: '课程类型'
        };//area: '地区',

        this.uiStringInputs = ['title', 'period', 'bookDesc', 'remark', 'target'];

        this.uiSelectOne = ['label']; //'area',
        this.uiSelectMulti = ['teachers'];
        this.uiSelectType = ['type'];

        this.uiSwitchInput = ['publish', 'recommend', 'jifenUse', 'packageSend'];
        this.uiNumberInput = ['price', 'classCount', 'limits'];
    }

    componentDidMount() {
        if (this.props.match.params.courseId) {
            this.props.dispatch(fetchCourse(this.props.match.params.courseId));
        } else {
            this.props.dispatch(reset_info());
            this.props.dispatch(setIsFetchingFalse());
        }
        this.props.dispatch(fetchCourseCategories());
        this.props.dispatch(fetchCourseTeachers());
        this.props.dispatch(fetchCourseLabels());
    }

    submit = () => {
        const cb = tip => message.warning(tip);
        let blackList = Object.keys(this.k2v);
        let whiteList = ['limits', 'remark', 'bookDesc'];                   // 无需参与一起校验的字段
        whiteList.map(key => {
            let index = blackList.indexOf(key);
            blackList = blackList.splice(index, 1);
        });
        const hasEmpty = validateUtil(this.props.info, blackList, this.k2v);
        if (hasEmpty) return;
        if (this.props.info['limits'] == 0) {
            cb('限制人数必须大于0');
            return;
        }
        let { classDesc, target, teacherDesc, courseBanner, title, period, label, type, courseNums, teachers } = this.props.info;
        if (title == '') return message.warn('课程标题不能为空');
        if (period == '') return message.warn('开始时间-结束时间不能为空');
        if (label == '') return message.warn('请选择标签');
        if (type == null) return message.warn('请选择课程类型');
        if (!courseNums.length) return message.warn('请设置课程日期');
        if (!teachers.length) return message.warn('请选择任课老师');
        if (teacherDesc == '') return message.warn('授课老师不能为空');
        if (courseBanner == '') return message.warn('课程banner不能为空');
        if (classDesc == '') return message.warn('课程介绍不能为空');
        if (target == '') return message.warn('收益对象不能为空');
        let submitData = this.submitDataHandler();                       // 删除空数据
        const cb2 = () => this.props.history.push('/courses');
        this.props.dispatch(updateCourse(submitData, cb2));
    }

    videoPickerSize = value => {
        this.setState({ size: value })
    };

    /** 对提交的的数据做一个处理 */
    submitDataHandler = () => {
        let submitData = this.props.info;
        submitData.text = submitData.teachers.join(',');
        submitData.reward = 0;
        submitData.discount = 0;
        return submitData;
    }

    open = (v) => {
        if (this.props.info.bannerType == 1) {
            this.setState({ showVideoModal: true })
        } else {
            this.setState({ showImageModal: true })
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    /** Input组件的onChange handler */
    inputHandler = (key, event) => {
        this.props.dispatch(input_property({ key, value: event.target.value }));
    }

    /** 直接可以拿到输入值的handler */
    valueHandler = (key, value) => {
        this.props.dispatch(input_property({ key, value }));
    }

    selectTeacher = (key, value) => {
        if (value.length > 7) {
            value = value.slice(0, 7);
        }
        this.props.dispatch(input_property({ key, value }));
    }

    updateCourseNumsHandler = (courseNums, isFromCalendar) => {
        this.props.dispatch(setCourseNums(courseNums, isFromCalendar));
        // this.props.dispatch(input_property({ key: 'courseNums', value: courseNums }));
    }

    confirmUpdateCourseNumsHandler = value => {
        const { confirmCourseNums, originalCourseNums } = this.props;
        if (value) this.props.dispatch(setUpdateStatus(originalCourseNums));
        else this.props.dispatch(notSetUpdateStatus(confirmCourseNums));
    }

    render() {
        const { info } = this.props;
        return (
            <div className="courseInfo">
                <Spin tip="加载中..." spinning={this.props.isFetching}>
                    <div className="commonFlexLR">
                        <div className="commonLeft">
                            <Breadcrumb>
                                <Breadcrumb.Item>课程列表</Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.match.params.courseId ? '在线课程详情' : '添加在线课程'}</Breadcrumb.Item>
                            </Breadcrumb>

                            {
                                this.uiStringInputs.map((key, idx) => (
                                    <div key={idx} className='flexcolumn inputRow'>
                                        <label>{this.k2v[key]}{key === 'title' ? '-不能超过20字' : ''}</label>
                                        <Input maxLength={20} className='input400' type='text' value={info[key]} onChange={event => this.inputHandler(key, event)} placeholder={`请输入${this.k2v[key]}`} />
                                    </div>
                                ))
                            }

                            {/** 课程编号输入 */}
                            <CoursenumCalendar
                                courseNums={info.courseNums}
                                updateCourseNums={this.updateCourseNumsHandler}
                                confirmUpdateCourseNums={this.confirmUpdateCourseNumsHandler}
                                originalCourseNums={this.props.originalCourseNums}
                            ></CoursenumCalendar>

                            {/** 暂时关闭地区选项 */}
                            {
                                this.uiSelectOne.map(key => (
                                    <div className='flexrow inputRow' key={key}>
                                        <label>{this.k2v[key]}</label>
                                        <Select
                                            showSearch
                                            className="select320"
                                            placeholder={`请选择${this.k2v[key]}`}
                                            onChange={value => this.valueHandler(key, value)}
                                            value={info[key]}
                                        >
                                            {
                                                this.props[`${key}List`] && this.props[`${key}List`].map((label, idx) => (
                                                    <Option key={idx} value={label}>{label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                ))
                            }

                            {/* 课程类型 */}
                            {
                                this.uiSelectType.map(key => (
                                    <div className="flexrow inputRow" key={key}>
                                        <label>{this.k2v[key]}</label>
                                        <Select
                                            className="select320"
                                            placeholder={`请选择${this.k2v[key]}`}
                                            value={info[key]}
                                            onChange={value => this.valueHandler(key, value)}
                                        >
                                            {
                                                subjectType.map((obj, idx) => (
                                                    <Option key={idx} value={obj.value}>{obj.label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                ))
                            }

                            {
                                this.uiSelectMulti.map(key => (
                                    <div className='flexrow inputRow' key={key}>
                                        <label>{this.k2v[key]}</label>
                                        <Select
                                            className="select320"
                                            mode="multiple"
                                            placeholder={`请选择${this.k2v[key]}`}
                                            onChange={value => this.selectTeacher(key, value)}
                                            value={info[key]}
                                        >
                                            {
                                                this.props[`${key}List`] && this.props[`${key}List`].map(label => (
                                                    <Option value={label} key={label}>{label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                ))
                            }

                            {
                                this.uiSwitchInput.map(key => (
                                    <div className='flexrow inputRow' key={key}>
                                        <label>{this.k2v[key]}</label>
                                        <Switch checked={info[key] == 1} onChange={value => this.valueHandler(key, value)} />
                                    </div>
                                ))
                            }

                            {
                                this.uiNumberInput.map(key => (
                                    <div className='flexrow inputRow' key={key}>
                                        <label>{this.k2v[key]}</label>
                                        <InputNumber min={0} value={info[key]} onChange={value => this.valueHandler(key, value)} className="input400" />
                                    </div>
                                ))
                            }

                            <div className="flexcolumn inputRow">
                                <label>授课老师</label>
                                <Editor
                                    content={info.teacherDesc}
                                    onChange={value => this.valueHandler('teacherDesc', value)}
                                    imgPath='background/course/detail'
                                />
                            </div>

                            <div className="flexcolumn inputRow">
                                <label>课程介绍</label>
                                <Editor
                                    content={info.classDesc}
                                    onChange={value => this.valueHandler('classDesc', value)}
                                    imgPath='background/course/detail'
                                />
                            </div>
                        </div>

                        <div className="commonsubmit">
                            {checkPermission('sys:course:edit') && <div className="cardR" style={{ height: '90%', overflow: 'auto' }}>
                                <Card title="更新" size="small">
                                    <Button disabled={this.props.isUpdating} onClick={this.submit} type='primary'>
                                        更新
                                    </Button>
                                </Card>
                                {
                                    this.props.categories &&
                                    <CategorySelectCard
                                        defaultChecked={info.categoryIds}
                                        categories={this.props.categories}
                                        onChange={value => this.valueHandler('categoryIds', value)}
                                    />
                                }
                                <Card title="banner" size="small">
                                    <div className="inputRow">
                                        <div>课程轮播图类型</div>
                                        <Radio.Group onChange={e => { this.valueHandler('bannerType', e.target.value);; this.props.dispatch(fill_banner(info.courseBanner || '')) }} value={info.bannerType}>
                                            <Radio value={1}>视频</Radio>
                                            <Radio value={0}>图片</Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className="cover">
                                        {
                                            info.bannerType == 1 ?
                                                <video loop muted controls style={{ width: 200, height: 120 }} src={info.courseBanner} />
                                                :
                                                <img style={{ width: 200, height: 120 }} alt="img" src={info.courseBanner} />
                                        }
                                    </div>
                                    <div className="upload">
                                        <Button size="small" onClick={this.open}>选择</Button>
                                        <Button size="small" onClick={() => this.props.dispatch(fill_banner(""))}>删除</Button>
                                    </div>
                                    {
                                        info.bannerType == 1 ?
                                            <VideoPicker
                                                pubName={OSS_PUB_GK_NEWS}
                                                showModal={this.state.showVideoModal}
                                                closeModal={() => this.setState({ showVideoModal: false })}
                                                feedBack={value => this.props.dispatch(fill_banner(value.trim()))}
                                                getSize={this.videoPickerSize}
                                            /> :
                                            <ImagePicker
                                                showModal={this.state.showImageModal}
                                                bucket='gk-resource'
                                                imgPath='background/banner/course'
                                                closeModal={() => this.setState({ showImageModal: false })}
                                                feedBack={value => this.props.dispatch(fill_banner(value.trim()))}
                                            />
                                    }
                                </Card>
                            </div>}
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        isFetching,
        isUpdated,
        isUpdating,
        isUploading,
        info,
        originalCourseNums,
        confirmCourseNums
    } = state.courseInfo;

    const {
        areas
    } = state.questions;

    const {
        items: categories
    } = state.courseCategories;

    const {
        teachers,
        labels
    } = state.courseList

    return {
        isFetching,
        isUpdated,
        isUpdating,
        isUploading,
        info,
        areaList: areas,
        categories,
        teachersList: teachers,
        labelList: labels,
        originalCourseNums,
        confirmCourseNums
    };
}

export default connect(mapStatetoProps)(CourseInfo);
