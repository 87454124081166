/**
 * Created by pwq on 2017/11/07.
 */
import {
    REQUEST_BANNERS,
    RECEIVE_BANNERS,
    SELECT_BANNER,
    ORDER_BANNER,
    REQUEST_UPDATE_BANNER,
    RECEIVE_UPDATE_BANNER_RESULT_SUC,
    SET_PAGE_SIZE,
    SET_ACTIVE_PAGE,
    RECEIVE_BANNER_LABELS
} from "./actions";

export function moduleBannerList(state = {
    isFetchingExamine: false,
    isFetchingBanners: false,
    isUpdating: false,
    isUpdated: false,
    items: [],
    labels: [],
    info: {
        title: "",
        bannersArr: [],
        selBannersList: []
    },
    categoryId: '-1',
    totalElements: 1,
    activePage: 1,                          // 分页组件当前页
    pageSize: 10,                           // 分页组件每页数量
}, action) {
    switch (action.type) {
        case REQUEST_BANNERS:
            return Object.assign({}, state, {isFetchingBanners: true});
        case RECEIVE_BANNER_LABELS:
            return Object.assign({}, state, {
                labels: action.bannerList,
            });
        case RECEIVE_BANNERS:
            let onlineBanner = [];
            let onlineBannerId = [];
            action.bannerList.forEach(function(banner) {
                if (banner.publish === 1) {
                    onlineBanner.push(banner);
                    onlineBannerId.push(banner.id);
                }
            }, this);
            return Object.assign({}, state, {
                isFetchingBanners: false,
                items: action.bannerList,
                totalElements: action.totalElements,
                info: {
                    selBannersList: onlineBanner,
                    bannersArr: onlineBannerId
                }
            });
        case SELECT_BANNER:
            let bannersArr = state.info.bannersArr;
            let selBannersList = state.info.selBannersList;
            action.id = parseInt(action.id);  // 手动转换回 int 类型
            if (bannersArr.includes(action.id))
                return Object.assign({}, state, {
                    info: Object.assign({}, state.info, {
                        bannersArr: bannersArr.filter(v => v != action.id),
                        selBannersList: selBannersList.filter(q => q.id != action.id)
                    })
                });
            else {
                bannersArr.push(action.id);
                selBannersList.push(action.banner);
                return Object.assign({}, state, {
                    info: Object.assign({}, state.info, {
                        bannersArr: bannersArr,
                        selBannersList: selBannersList
                    })
                });
            }
        case ORDER_BANNER:
            let swapItems = (arr, index1, index2) => {
                arr[index1] = arr.splice(index2, 1, arr[index1])[0];
                return arr;
            };
            let bannersOrderList = state.info.bannersArr;
            let selBannersOrderList = state.info.selBannersList;
            action.id = parseInt(action.id);  // 手动转换回 int 类型
            let qIndex = bannersOrderList.indexOf(action.id);
            if (qIndex > 0) {
                swapItems(bannersOrderList, qIndex, qIndex - 1);
                swapItems(selBannersOrderList, qIndex, qIndex - 1);
            }
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    bannersArr: bannersOrderList,
                    selBannersList: selBannersOrderList
                })
            });

        case REQUEST_UPDATE_BANNER:
            return Object.assign({}, state, {isUpdating: true});
        case RECEIVE_UPDATE_BANNER_RESULT_SUC:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, { activePage: action.data });
        default:
            return state;
    }
}
