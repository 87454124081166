
import { message } from 'antd';
import {
    GUFEN_TEXT,
    YATI_TEXT,
    TRUEEXAM_TEXT,
} from '../ExamineList/action';
import apiRequest from '../../common/request';


const PREFIX = 'EXAMINE_DETAIL';
export const START_FETCHING_DETAIL = PREFIX + 'START_FETCHING_DETAIL';
export const FINISH_LOADING_DATA = PREFIX + 'FINISH_LOADING_DATA';
export const SET_TYPE = PREFIX + 'SET_TYPE';
export const SET_VALUE = PREFIX + 'SET_VALUE';
export const RESET_EXAMINE_OBJ = PREFIX + 'RESET_EXAMINE_OBJ';
export const SET_SEL_QUESIDS = PREFIX + 'SET_SEL_QUESIDS';
export const RESET_ALL_DATA = PREFIX + 'RESET_ALL_DATA';

export const resetAllData = () => ({ type: RESET_ALL_DATA });

export const resetExamineObj = () => ({ type: RESET_EXAMINE_OBJ });

export const setSelQuesIds = value => ({
    type: SET_SEL_QUESIDS,
    value
});

export const setValue = (key, value) => ({
    type: SET_VALUE,
    key,
    value
});

export const startFetching = () => ({ type: START_FETCHING_DETAIL });

export const loadDataSucced = data => ({
    type: FINISH_LOADING_DATA,
    data
});

export const setType = value => ({ type: SET_TYPE, value });

/**
 * 生成获取试卷详情对应的API接口
 * @param {String} type : 试卷类型
 */
function generateUrlAndConfig(type, id) {
    let config = {
        method: 'GET',
    };
    let url = `/gk/ops/${type}/historyExamine/getById/${id}`;// 历年真题试卷类型
    return { url, config };
}


/**
 * 生成创建试卷对应的API接口
 * @param {String} type : 试卷类型
 * @param {Object} data : 需要提交API接口的数据
 */
function postGenerateUrlAndConfig(type, data) {
    let config = {
        credentials: 'include', method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
        body: JSON.stringify(data)
    };
    let url = `/gk/ops/${type}/historyExamine/saveOrUpdate`;
    return { url, config };
}

/**
 * 从已经选择的问题数组里面筛选出问题ID
 * @param {Array} selQuesArr : 问题数组
 */
export function findQuesId(selQuesArr, containPoint = false) {
    if (Object.prototype.toString.call(selQuesArr) !== '[object Array]') return [];
    let result = [];
    selQuesArr.map(obj => {
        if (containPoint) {
            result.push({ questionId: obj.id, point: obj.point });
        } else {
            result.push(obj.id);
        }
    });
    return result;
}

/**
 * 获取试卷的详情数据
 * @param {Number} id : 试卷ID
 * @param {String} type : 试卷类型
 */
export function getExamineDetail(id, type) {
    const { url, config } = generateUrlAndConfig(type, id);
    return dispatch => {
        dispatch(startFetching());
        apiRequest(url, config)
            .then(json => {
                let selQuesIds = findQuesId(json.obj && json.obj.questions);
                dispatch(loadDataSucced(json.obj));
                dispatch(setSelQuesIds(selQuesIds));
            })
    }
}

/**
 * 创建或者修改试卷
 * @param {Object} params : 提交接口的参数
 * @param {*} type : 试卷类型
 */
export async function uploadExamineDetail(params, type, cb) {
    const { url, config } = postGenerateUrlAndConfig(type, params);
    apiRequest(url, config)
        .then(json => {
            if (json.status == 1) {
                resetExamineObj();
                message.success('操作成功');
                cb && cb();
            } else {
                message.error('操作失败');
            }
        })
}