import React from 'react';
import { connect } from 'react-redux';
import { Select, Input, DatePicker, Button, Table, Modal, message, Tooltip, Popover } from 'antd';
import {
    getAskNum,
    getCurrentDetails,
    dealCurrentSearch,
    emptyCurrentStatus,
    changeDataStatus
} from './action';
import { openModleAction, fetchSearchClass, fetchAddStatus, exportUsersData } from '../Inquiry/action';
import { fetchDepartmentList, searchUserInfo } from '../ContractOrder/action';
import './index.scss';
import moment from 'moment';
import UserInfo from '../../components/UserInfo/index';
import ClassInfo from '../../components/ClassInfo/index';
import { getSearchQuery, checkPermission } from '../../utils/utils';

const { TextArea } = Input;

const { Option } = Select;

const classTeacher = '全程部,师资堂';

const operation = '运营部';

const teacherFame = '师资堂,全程部';

const superviseStatus = [{ label: '全部', value: '全部' }, { label: '正常', value: 0 }, { label: '异常', value: 1 }];

const questionType = ['申论', '言语理解与表达', '数量关系', '判断推理', '资料分析', '科学推理'];

const returnVisitStatus = [{ label: '全部', value: '全部' }, { label: '正常', value: 0 }, { label: '异常', value: 1 }, { label: '未回复', value: 2 }];

class InquiryDetail extends React.Component {

    state = {
        id: null,
        showModal: false,                       // 是否显示模态框
        classId: null,                          // 班级id
        userInfoId: null,                       // 用户id
        date: null,                             // 日期
        headTeacherId: '全部',                   // 班主任id
        teacherId: '全部',                       // 老师id
        type: '全部',                            // 答疑类型
        remarks: null,                          // 备注
        status: '全部',                          // 回访状态  督学状态
        askNum: 0,                              // 答疑次数
        topShow: true,                          // 切换学员
        teacherName: null,                      // 老师名字
        headTeacherName: null,                  // 班主任名字
        className: null,                       // 班级名字
        userName: null,                         // 用户信息                      
    };

    componentWillMount() {
        this.studentId = getSearchQuery('userInfoId');
        this.studentIds = JSON.parse(localStorage.getItem('inquiryIds'));
        this.studentList = JSON.parse(localStorage.getItem('inquiryList'));
        let global = getSearchQuery('global');
        let userInfoId = getSearchQuery('userInfoId');
        let name = getSearchQuery('name');
        let mobile = getSearchQuery('mobile');
        global && this.setState({ topShow: !global });      //global表示是全局搜索过来的页面，不需要显示学员切换
        if (userInfoId) {
            let params = [{ id: Number(userInfoId), mobile, name }];
            this.props.dispatch(changeDataStatus(params));
        }
    };

    async componentDidMount() {
        let type = getSearchQuery('type');
        let userInfoId = getSearchQuery('userInfoId');
        if (type === 'returnVisit') this.setState({ currentDepartment: operation });
        if (type === 'userCare' || type === 'ask') this.setState({ currentDepartment: teacherFame });

        if (userInfoId) {
            await this.props.dispatch(dealCurrentSearch({ key: 'userInfoIds', value: [Number(userInfoId)] }));
            this.handleAllFilterSearch();
        } else {
            this.handleAllFilterSearch();
        }
    };

    componentWillUnmount() {
        this.props.dispatch(emptyCurrentStatus());
    };

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        if (!value) return;
        let data = { title: value }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 综合处理所有筛选条件搜索 */
    handleAllFilterSearch = () => {
        const {
            classId, headTeacherId, startDate, endDate, page, pageSize, teacherId, userInfoIds,
        } = this.props;
        let params = { page, pageSize };
        if (classId != '全部') params.classId = classId;
        if (headTeacherId !== '全部') params.headTeacherId = headTeacherId;
        if (userInfoIds && userInfoIds.length > 0) params.userInfoIds = userInfoIds;
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        if (teacherId !== '全部') params.teacherId = teacherId;
        let currentType = getSearchQuery('type');
        this.props.dispatch(getCurrentDetails(params, currentType));
        currentType == 'ask' && this.props.dispatch(getAskNum(params));//答疑才显示次数    
    };

    /** 处理选择班级  只能选择一个 */
    handleSelectClass = async index => {
        await this.props.dispatch(dealCurrentSearch({ key: 'classIdx', value: index }));
        const { classIdx, classes } = this.props;
        if (classIdx !== null) {
            let currentClasses = [{ className: '全部', id: '全部' }].concat(classes);
            let currentClass = currentClasses[classIdx];
            await this.props.dispatch(dealCurrentSearch({ key: 'classDetail', value: currentClass }));
            const { classDetail } = this.props;
            if (classDetail) {
                this.props.dispatch(dealCurrentSearch({ key: 'currentClassName', value: classDetail.className }));
                this.props.dispatch(dealCurrentSearch({ key: 'classId', value: classDetail.id }));
            }
        };
        await this.resetActivePage1();
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };


    /** 班主任筛选搜索 */
    headTeacherSearch = async event => {
        let currentType = getSearchQuery('type');
        if (currentType === 'supervise') await this.props.dispatch(dealCurrentSearch({ key: 'teacherId', value: event }));
        else await this.props.dispatch(dealCurrentSearch({ key: 'headTeacherId', value: event }));
        await this.resetActivePage1();
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };

    /** 老师筛选框 */
    handleTeacherSearch = async event => {
        let currentType = getSearchQuery('type');
        let currentTeacher = [];
        const { operational, teacherDepartment } = this.props;
        if (currentType === 'returnVisit') currentTeacher = [...operational];
        if (currentType === 'userCare' || currentType === 'ask') currentTeacher = [...teacherDepartment];
        let currentArr = [{ name: '全部', id: '全部' }].concat(currentTeacher);
        let id = currentArr[event].id;
        let name = currentArr[event].name;
        await this.props.dispatch(dealCurrentSearch({ key: 'teacherId', value: id }));
        await this.props.dispatch(dealCurrentSearch({ key: 'currentTeacherName', value: name }));
        await this.resetActivePage1();
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(dealCurrentSearch({ key: 'page', value: 1 }));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    /** 设置日期输入的值 */
    calendarValueChangeHandler = async (dateString, key) => {
        await this.props.dispatch(dealCurrentSearch({ key, value: dateString }));
        await this.resetActivePage1();
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };

    /** 重置搜索 */
    resetConditionQuery = async () => {
        await this.props.dispatch(emptyCurrentStatus());
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };

    /** 处理客户搜索框 */
    handleChange = async value => {
        await this.props.dispatch(dealCurrentSearch({ key: 'userInfoIds', value }));
        await this.resetActivePage1();
        this.handleAllFilterSearch();
        this.setState({ topShow: false });
    };

    /** 分页组件改变页码 */
    paginationChange = async page => {
        await this.props.dispatch(dealCurrentSearch({ key: 'page', value: page }));
        this.handleAllFilterSearch();
    };

    /** 分页组件改变每页条数 */
    showSizeChange = async (current, size) => {
        await this.resetActivePage1();
        await this.props.dispatch(dealCurrentSearch({ key: 'pageSize', value: size }));
        this.handleAllFilterSearch();
    };

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    getLatest = record => {
        let type = getSearchQuery('type');
        if (type == 'returnVisit') this.props.dispatch(fetchDepartmentList(operation, record.teacherName));
        if (type == 'userCare' || type == 'ask') this.props.dispatch(fetchDepartmentList(teacherFame, record.teacherName));
    }

    /** 修改 */
    modifyBounce = async record => {
        await this.getLatest(record);
        this.setState({
            id: record.id,
            classId: record.classId,
            userInfoId: record.userInfoId,
            className: record.className,
            userName: record.userName,
            headTeacherName: record.headTeacherName,
            teacherName: record.teacherName,
            headTeacherId: record.headTeacherId,
            teacherId: record.teacherId,
            date: record.date,
            status: record.status,
            remarks: record.remarks,
            type: record.type,
            askNum: record.askNum,
            showModal: true,
        });
    };

    /** 修改state里面的老师详情 */
    modifyCurrentTeacher = index => {
        const { operational, teacherDepartment } = this.props;
        let currentType = getSearchQuery('type');
        let currentTeacher = [];
        if (currentType === 'returnVisit') currentTeacher = [...operational];
        if (currentType === 'userCare' || currentType === 'ask') currentTeacher = [...teacherDepartment];
        this.setState({
            teacherId: currentTeacher[index].id,
            teacherName: currentTeacher[index].name
        })
    };

    /** 修改日期 */
    modifyDate = value => {
        this.setState({
            date: value
        });
    };

    verifyInput = () => {
        let result = true;
        let current = getSearchQuery('type');
        if (!this.state.date) {
            result = false;
            message.error('请选择日期');
            return result;
        };
        if (current === 'supervise') {
            if (this.state.status === '全部') {
                result = false;
                message.error('请选择督学情况');
                return result;
            };
        };
        if (current === 'ask' || current === 'userCare' || current === 'returnVisit') {
            if (this.state.teacherId === '全部') {
                result = false;
                message.error('请选择老师');
                return result;
            };
        };
        if (current === 'ask') {
            if (this.state.type === '全部') {
                result = false;
                message.error('请选择答疑类型');
                return result;
            };
            if (this.state.askNum <= 0) {
                result = false;
                message.error('请输入正确的答题次数');
                return result;
            }
        };
        if (current === 'returnVisit') {
            if (this.state.status === '全部') {
                result = false;
                message.error('请选择回访情况');
                return result;
            };
        }
        return result;
    };

    /** 修改提交 */
    handleOk = () => {
        if (!this.verifyInput()) return;
        let current = getSearchQuery('type');
        let params = {
            classId: this.state.classId,
            userInfoId: this.state.userInfoId,
            date: this.state.date,
            id: this.state.id,
        };
        this.state.remarks && (params.remarks = this.state.remarks);
        const successCb = () => {
            this.setState({
                showModal: false,
                id: null,
                classId: null,
                userInfoId: null,
                date: null,
                headTeacherId: '全部',
                teacherId: '全部',
                type: '全部',
                remarks: null,
                status: '全部',
                teacherName: null,
                headTeacherName: null,
                className: null,
                userName: null,
                askNum: 0,
            });
            message.success('操作成功');
        };
        if (current === 'supervise') {
            params.teacherId = this.state.teacherId;
            params.status = this.state.status;
            this.props.dispatch(fetchAddStatus(params, 'supervise', successCb, this.handleAllFilterSearch, this.errMessageHandler));
        };
        if (current === 'ask') {
            params.type = this.state.type;
            params.askNum = this.state.askNum;
            params.teacherId = this.state.teacherId;
            params.headTeacherId = this.state.headTeacherId;
            this.props.dispatch(fetchAddStatus(params, 'ask', successCb, this.handleAllFilterSearch, this.errMessageHandler));
        };
        if (current === 'userCare') {
            params.teacherId = this.state.teacherId;
            params.headTeacherId = this.state.headTeacherId;
            this.props.dispatch(fetchAddStatus(params, 'userCare', successCb, this.handleAllFilterSearch, this.errMessageHandler));
        };
        if (current === 'returnVisit') {
            params.teacherId = this.state.teacherId;
            params.headTeacherId = this.state.headTeacherId;
            params.status = this.state.status;
            this.props.dispatch(fetchAddStatus(params, 'returnVisit', successCb, this.handleAllFilterSearch, this.errMessageHandler));
        }
    };

    errMessageHandler = errorMes => {
        message.error(errorMes);
    };

    modalCancelHandler = () => {
        this.setState({
            showModal: false,
        })
    };

    /** 用户实时查询 */
    searchCustormer = value => {
        if (!value) return;
        let userArr = [];
        const { custormers, userInfoIds } = this.props;
        for (let user of custormers) {
            for (let id of userInfoIds) {
                if (id == user.id) {
                    userArr.push(user);
                }
            }
        }
        this.props.dispatch(searchUserInfo(value, userArr));
    };


    /** 生成表头方法 */
    renderHeader = () => {
        let currentValue = '盯学';
        let type = getSearchQuery('type');
        type === 'ask' && (currentValue = '答疑');
        type === 'userCare' && (currentValue = '关怀');
        type === 'returnVisit' && (currentValue = '回访');
        return (
            <div className="commonTableTitle">
                <span>{`${currentValue}列表`}</span>
                <div>
                    {type == 'ask' && <span>总答疑次数：<span style={{ color: 'red', marginRight: '20px' }}>{this.props.askNum}</span></span>}
                    {checkPermission(this.getType('export')) &&
                        <Button type="primary" onClick={this.downExcel}>{`导出${currentValue}数据`}</Button>
                    }
                </div>
            </div>
        )
    };

    /** 导出用户数据 */
    downExcel = () => {
        let currentValue = '盯学';
        let type = getSearchQuery('type');
        type === 'ask' && (currentValue = '答疑');
        type === 'userCare' && (currentValue = '关怀');
        type === 'returnVisit' && (currentValue = '回访');
        const {
            classId, headTeacherId, startDate, endDate, page, pageSize, teacherId, userInfoIds,
        } = this.props;
        let params = { page, pageSize };
        if (classId != '全部') params.classId = classId;
        if (headTeacherId != '全部') params.headTeacherId = headTeacherId;
        if (userInfoIds && userInfoIds.length > 0) params.userInfoIds = userInfoIds;
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        if (teacherId !== '全部') params.teacherId = teacherId;
        this.props.dispatch(exportUsersData(params, type, currentValue));
    };

    /** 打开学生信息详情 */
    open = (data) => {
        this.props.dispatch(openModleAction(data))
    }

    /** 动态生成表格 */
    dynamicGenerateColumns = () => {
        let type = getSearchQuery('type');
        let superviseTeacher = 'teacherName';
        if (type !== 'supervise') superviseTeacher = 'headTeacherName';
        let columns = [
            {
                title: '客户',
                render: (text, record) => (
                    <span className="active" onClick={() => this.open({ key: 'userModle', value: { open: true, id: record.userInfoId } })}>{record.userName}</span>
                ),
            },
            {
                title: '班级',
                render: (text, record) => (
                    <span className="active" onClick={() => this.open({ key: 'classModle', value: { open: true, id: record.classId } })}>{record.className}</span>
                ),
            },
            {
                title: '班主任',
                dataIndex: superviseTeacher,
            },
            {
                title: '日期',
                dataIndex: 'date'
            },
        ];
        if (type === 'supervise') {
            columns.push(
                { title: '督学情况', render: record => (<span>{record.status ? '异常' : '正常'}</span>) },
            )
        } else if (type === 'ask') {
            columns.push(
                { title: '答疑类型', dataIndex: 'type' },
                { title: '答疑老师', dataIndex: 'teacherName' },
                { title: '答疑次数', dataIndex: 'askNum' },
            )
        } else if (type === 'userCare') {
            columns.push(
                { title: '关怀老师', dataIndex: 'teacherName' },
            )
        } else if (type === 'returnVisit') {
            columns.push(
                {
                    title: '回访情况', render: record => (
                        <span>
                            {record.status == 0 && '正常'}
                            {record.status == 1 && '异常'}
                            {record.status == 2 && '未回复'}
                        </span>
                    )
                },
                { title: '回访人员', dataIndex: 'teacherName' },
            )
        };

        columns.push(
            { title: '备注', dataIndex: 'remarks' },
            {
                title: '操作',
                render: record => (
                    <div>
                        {checkPermission(this.getType('edit')) &&
                            <Button type="primary" size="small" onClick={() => this.modifyBounce(record)}>修改</Button>
                        }
                    </div>
                )
            }
        )
        return columns;
    };

    /** 点击上一位学员 */
    prevStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex <= 0) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex, true);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 点击下一位学员 */
    nextStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex >= this.studentIds.length - 1) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 返回当前学员的ID */
    findCurrentStudentIdx = () => {
        if (Object.prototype.toString.call(this.studentIds) != '[object Array]') return;
        this.studentId = getSearchQuery('userInfoId');
        return this.studentIds.indexOf(+this.studentId);
    }

    /** 返回上下位学员的ID */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.studentIds[currentIndex - 1];
        else return this.studentIds[currentIndex + 1];
    }

    /** 页面跳转到上下位学员 */
    jump2NextPrevStudent = async studentId => {
        let userName = ''
        this.studentList.map(item => {
            if (item.id == studentId) return userName = item.name;
        })
        let index1 = userName.indexOf('(')
        let index2 = userName.indexOf(')')
        let name = userName.slice(0, index1);
        let mobile = userName.slice(index1 + 1, index2);
        let type = getSearchQuery('type');
        let params = [{ id: Number(studentId), mobile, name }];
        let url = `/inquiryDetail?type=${type}&userInfoId=${studentId}&name=${name}&mobile=${mobile}`;
        this.props.history.replace(url);
        await this.props.dispatch(dealCurrentSearch({ key: 'page', value: 1 }));
        await this.props.dispatch(dealCurrentSearch({ key: 'userInfoIds', value: [Number(studentId)] }));
        await this.props.dispatch(changeDataStatus(params));
        this.handleAllFilterSearch();
    }

    getType = (str) => {
        let type = getSearchQuery('type');
        if (str == 'export') {
            let res = 'user:supervise:export';
            type === 'ask' && (res = 'user:ask:export');
            type === 'userCare' && (res = 'user:care:export');
            type === 'returnVisit' && (res = 'user:visit:export');
            return res;
        }
        if (str == 'edit') {
            if (type == 'supervise') return 'user:supervise:edit';
            if (type == 'ask') return 'user:ask:edit';
            if (type == 'userCare') return 'user:care:edit';
            if (type == 'returnVisit') return 'user:visit:edit';
        }
    }

    render() {
        const {
            classModle, userModle, queryInfo, classes, headTeacherId, headTeachers, teacherId, custormers, startDate, endDate, operational, teacherDepartment, currentClassName, page, pageSize, userInfoIds, loading, currentTeacherName
        } = this.props;
        let columns = this.dynamicGenerateColumns();
        let currentType = getSearchQuery('type');
        let currentTeacher = [];
        if (currentType === 'returnVisit') currentTeacher = [...operational];
        if (currentType === 'userCare' || currentType === 'ask') currentTeacher = [...teacherDepartment];
        let currentIndex = this.findCurrentStudentIdx();                                  // 当前学员的索引位置
        let prevNums = currentIndex;                                                      // 当前学员前面还有多少位学员
        let nextNums = this.studentIds ? this.studentIds.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
        let stuList = this.studentList && (
            <div className="popoverStu">
                {this.studentList.map((item, idx) => <p key={idx} className={['classStuList', item.id == this.studentId ? 'activeStu' : ''].join(' ')} onClick={() => this.jump2NextPrevStudent(item.id)}>{idx + 1 + '-' + item.name}</p>)}
            </div>
        ) || (<div>暂无列表</div>)
        return (
            <div className="queryDetailContainer">
                {userModle.open && <UserInfo
                    updeteCb={this.handleAllFilterSearch}
                />}
                {classModle.open && <ClassInfo
                    updeteCb={this.handleAllFilterSearch}
                />}
                {this.state.topShow && <div className="changeStu">
                    <Tooltip title={`前面还有${prevNums}位学员`}>
                        <Button disabled={this.studentIds && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一位学员</Button>
                    </Tooltip>
                    <Tooltip title={`后面还有${nextNums}位学员`}>
                        <Button disabled={this.studentIds && currentIndex >= this.studentIds.length - 1} onClick={this.nextStudent} type="primary" className="mr30">下一位学员</Button>
                    </Tooltip>
                    <Popover content={stuList} title="学员列表" trigger="click">
                        <Button type="primary">学员列表</Button>
                    </Popover>
                </div>}
                {/* 上面筛选模块 */}
                <div className="commonSearchBox">
                    <div>
                        <label>客户</label>
                        <Select
                            mode="multiple"
                            style={{ width: '400px' }}
                            filterOption={false}
                            value={userInfoIds}
                            placeholder="请输入客户手机号或姓名"
                            onSearch={value => { this.searchCustormer(value) }}
                            onChange={this.handleChange}
                        >
                            {
                                custormers && custormers.map((item, idx) => (
                                    <Option key={idx} value={item.id}>{`${item.name}(${item.mobile})`}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label >班级</label>
                        <Select
                            showSearch
                            style={{ width: 400 }}
                            filterOption={false}
                            value={currentClassName}
                            placeholder="请输入班级关键词进行查询"
                            onSearch={value => { this.searchClass(value) }}
                            onChange={value => { this.handleSelectClass(value) }}
                        >
                            {
                                [{ className: '全部', id: '全部' }].concat(classes).map((item, index) => (
                                    <Option key={index} value={index}>{item.className}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班主任</label>
                        <Select
                            style={{ width: 150 }}
                            value={currentType === 'supervise' ? teacherId : headTeacherId}
                            showSearch
                            filterOption={false}
                            onSearch={value => { this.employeeSearch(classTeacher, value) }}
                            onChange={event => { this.headTeacherSearch(event) }}
                        >
                            {
                                headTeachers && [{ name: '全部', id: '全部' }].concat(headTeachers).map((item, idx) => (
                                    <Option key={idx} value={item.id}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    {
                        currentType !== 'supervise' &&
                        <div>
                            <label>老师</label>
                            <Select
                                style={{ width: 150 }}
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(this.state.currentDepartment, value) }}
                                value={currentTeacherName}
                                onChange={event => { this.handleTeacherSearch(event) }}
                            >
                                {
                                    currentTeacher && [{ name: '全部', id: '全部' }].concat(currentTeacher).map((item, index) => (
                                        <Option key={index} value={index}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    <div>
                        <label>起始时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(startDate)}
                            onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'startDate'); }}
                        />
                    </div>
                    <div>
                        <label className="mr10 ml10">截止时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(endDate)}
                            onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'endDate'); }}
                        />
                    </div>
                    <div>
                        <Button type="danger" onClick={this.resetConditionQuery}>清空搜索条件</Button>
                    </div>
                </div>
                <div className="mt30">
                    <Table
                        columns={columns}
                        title={this.renderHeader}
                        dataSource={queryInfo.content}
                        rowKey={record => record.id}
                        loading={loading}
                        pagination={{
                            current: page,
                            pageSize: pageSize,
                            total: queryInfo.totalElements,
                            showTotal: total => `共有${total}条数据`,
                            onChange: this.paginationChange,
                            onShowSizeChange: this.showSizeChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '20', '50', '100']
                        }}
                    />
                </div>

                <Modal
                    title="修改"
                    className="inModal"
                    visible={this.state.showModal}
                    onOk={this.handleOk}
                    onCancel={this.modalCancelHandler}
                >
                    <div className="flexRow">
                        <label style={{ width: 70 }}>客户:</label>
                        <label>{this.state.userName}</label>
                    </div>
                    <div className="mt10 flexRow">
                        <label style={{ width: 70 }}>班级:</label>
                        <label>{this.state.className}</label>
                    </div>
                    {
                        currentType === 'supervise' ?
                            <div className="mt10 flexRow">
                                <label style={{ width: 70 }}>班主任:</label>
                                <label>{this.state.teacherName}</label>
                            </div>
                            :
                            <div className="mt10 flexRow">
                                <label style={{ width: 70 }}>班主任:</label>
                                <label>{this.state.headTeacherName}</label>
                            </div>
                    }
                    <div className="mt10 flexRow">
                        <label style={{ width: 70 }}>日期:</label>
                        <DatePicker
                            style={{ width: 200 }}
                            format="YYYY-MM-DD"
                            placeholder="请选择日期"
                            value={this.calendarValuePipe(this.state.date) || ''}
                            onChange={(date, dateString) => { this.modifyDate(dateString) }}
                            disabledDate={current => (current && current > moment().endOf('day'))}
                        />
                    </div>
                    {
                        currentType === 'supervise' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>督学情况:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.status}
                                onChange={event => this.setState({ status: event })}
                            >
                                {
                                    superviseStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        currentType === 'returnVisit' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>回访情况:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.status}
                                onChange={event => this.setState({ status: event })}
                            >
                                {
                                    returnVisitStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        (currentType === 'ask' || currentType === 'userCare') &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>老师:</label>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(teacherFame, value) }}
                                value={this.state.teacherName}
                                onChange={event => this.modifyCurrentTeacher(event)}
                            >
                                {
                                    currentTeacher.map((item, index) => (
                                        <Option key={index} value={index}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        currentType === 'ask' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>答疑类型:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.type}
                                onChange={event => this.setState({ type: event })}
                            >
                                {
                                    questionType.map(item => (
                                        <Option value={item}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        currentType === 'ask' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>答疑次数:</label>
                            <Input type="number" style={{ width: 200 }} value={this.state.askNum} onChange={e => this.setState({ askNum: e.target.value })} />
                        </div>
                    }
                    {
                        currentType === 'returnVisit' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>老师:</label>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(operation, value) }}
                                value={this.state.teacherId}
                                onChange={event => this.setState({ teacherId: event })}
                            >
                                {
                                    currentTeacher.map((item, idx) => (
                                        <Option key={idx} value={item.id}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    <div className="mt10">
                        <label>备注:</label>
                        <br />
                        <TextArea
                            value={this.state.remarks}
                            onChange={e => this.setState({ remarks: e.target.value })}
                            style={{ width: '60%' }}
                            autosize={{ minRows: 5 }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
};

function mapStatetoProps(state) {

    const {
        classModle, userModle
    } = state.inquiryReducer;

    const {
        ...initialState
    } = state.inquiryDetail;

    const {
        headTeachers
    } = state.contractOrder

    return {
        classModle, userModle,
        headTeachers,
        ...initialState
    };
}

export default connect(mapStatetoProps)(InquiryDetail);