import React from 'react';
import { Input, Select, Modal, DatePicker, message } from 'antd';
import { connect } from 'react-redux';
import { openModleAction } from '../../containers/Inquiry/action';
import { fetchHeadTeacher, fetchClassLabel, fetchCourseList, saveOrUpdateClass } from '../../containers/ClassList/actions';
import apiRequest from '../../common/request'
import moment from 'moment';

const Option = Select.Option;

const isEndStatus = [{ label: '是', value: 1 }, { label: '否', value: 0 }];

let classObj = {};//储存班级对象

class ClassInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: '',              // 模态班级名
            classNumber: '',            // 班级号
            classTeacher: '',           // 班级老师
            classLabel: '',             // 班级标签
            classCourse: '',            // 班级课程ID数组
            classId: '',                // 班级ID
            type: '',                   // 班级类型
            isEnd: '全部',               // 是否结课
            startDate: '',              // 开班日期
            teacherId: null,           // 班主任id
            id: null,                   // 主键id
        };
    }

    async componentDidMount() {
        let { classModle } = this.props;//蒙版开关
        let classId = classModle.id;
        await this.props.dispatch(fetchCourseList());
        await this.props.dispatch(fetchClassLabel());
        await this.props.dispatch(fetchHeadTeacher());
        let res = await this.fetchClassList(classId);
        classObj = res.obj;
        classObj && this.setState({
            className: classObj.className,
            classNumber: classObj.classNo,
            classTeacher: classObj.teacher,
            classLabel: classObj.label,
            classCourse: classObj.courseId,
            classId: classObj.id,
            startDate: classObj.startDate,
            isEnd: classObj.isEnd,
            id: classObj.id,
            teacherId: classObj.teacherId,
            type: classObj.type
        });
    }

    /** 获取类型值 */
    getTypeVal = (type) => {
        if(type==0) return '行测';
        if(type==1) return '申论';
        if(type==3) return '公基';
        if(type==4) return '职测';
        if(type==5) return '综应';
        return '未知';
    }

    /** 获取班级详情数据 */
    fetchClassList = (classId) => {
        const url = `/gk/ops/student/classDetail/${classId}`;
        let config = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            }
        };
        return apiRequest(url, config)
    }

    /** 错误回调 */
    errMessageHandler = errorMes => {
        message.error(errorMes);
    }

    /** 更新文本值 */
    classInputHandler = (value, key) => {
        this.setState({ [key]: value });
    }

    /** 验证有效值 */
    verifyInput = () => {
        let result = true;
        if (!this.state.className.trim().length || !this.state.className) {
            result = false;
            message.error('请填写班级名')
            return result;
        }
        if (!this.state.classNumber.trim().length || !this.state.classNumber) {
            result = false;
            message.error('请填写班级号')
            return result;
        }
        if (!this.state.classTeacher) {
            result = false;
            message.error('请填写班级老师')
            return result;
        }
        if (!this.state.classLabel) {
            result = false;
            message.error('请填写班级标签')
            return result;
        }
        if (this.state.type==='') {
            result = false;
            message.error('请填写科目')
            return result;
        }
        if (!this.state.classCourse) {
            result = false;
            message.error('请填写班级课程')
            return result;
        }
        if (!this.state.startDate) {
            result = false;
            message.error('请填写开班日期')
            return result;
        }
        if (this.state.isEnd == '全部') {
            result = false;
            message.error('请选择是否结课')
            return result;
        }
        return result;
    }

    /** 点击确认更新 */
    handleOk = async () => {
        if (this.props.notUpdate) return this.props.dispatch(openModleAction({ key: 'classModle', value: { open: false } }));
        if (!this.verifyInput()) return;
        let params = {
            className: this.state.className,
            classNo: this.state.classNumber,
            courseId: this.state.classCourse,
            label: this.state.classLabel,
            teacher: this.state.classTeacher,
            type: this.state.type,
            isEnd: this.state.isEnd,
            startDate: this.state.startDate,
            id: this.state.id,
            teacherId: this.state.teacherId,
        };
        const successCb = () => {
            this.props.updeteCb();
            message.success('操作成功');
        };
        await this.props.dispatch(saveOrUpdateClass(params, successCb, this.errMessageHandler));
        await this.props.dispatch(openModleAction({ key: 'classModle', value: { open: false } }));
    };

    /** 关闭蒙版 */
    handleCancel = () => {
        this.props.dispatch(openModleAction({ key: 'classModle', value: { open: false } }));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';

    render() {
        const { notUpdate, classModle, labels, headTeacherArr, subjects, courseList } = this.props;
        return (
            <div className="classInfo">
                <Modal
                    title="班级详情"
                    visible={classModle.open}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {classObj.className &&
                        (notUpdate &&
                            <div className="modelBox">
                                <div><b style={{display: 'inline-block', width: '110px'}}>班级名</b><span>{this.state.className}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>班级号</b><span>{this.state.classNumber}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>班主任</b><span>{this.state.classTeacher}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>标签名</b><span>{this.state.classLabel}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>科目</b><span>{this.getTypeVal(this.state.type)}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>是否结课</b><span>{this.state.isEnd && '是' || '否'}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>开班日期</b><span>{this.state.startDate}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>班级课程</b><span>{
                                    courseList.map((item, index) => (
                                        this.state.classCourse == item.id && item.title || ''
                                    ))
                                }</span></div>
                            </div>
                            ||
                            <div className="commonSearchBox" style={{ padding: 0, margin: 0 }}>
                                <div>
                                    <label>班级名</label>
                                    <Input
                                        placeholder="请输入班级名"
                                        className="commonW150"
                                        type="text"
                                        value={this.state.className}
                                        onChange={event => { this.classInputHandler(event.target.value, 'className') }}
                                    />
                                </div>
                                <div>
                                    <label>班级号</label>
                                    <Input
                                        placeholder="请输入班级号"
                                        className="commonW150"
                                        type="text"
                                        value={this.state.classNumber}
                                        onChange={event => { this.classInputHandler(event.target.value, 'classNumber') }}
                                    />
                                </div>
                                <div>
                                    <label>班主任</label>
                                    <Select
                                        showSearch
                                        className="commonW150"
                                        onChange={value => { this.classInputHandler(value, 'classTeacher') }}
                                        value={this.state.classTeacher}
                                    >
                                        {
                                            headTeacherArr.map((item, idx) => (
                                                <Option key={idx} value={item}>{item}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <label>标签名</label>
                                    <Select
                                        showSearch
                                        className="commonW150"
                                        onChange={value => { this.classInputHandler(value, 'classLabel') }}
                                        disabled={labels.length <= 0}
                                        value={this.state.classLabel}
                                    >
                                        {
                                            labels.filter(n => n != '全部').map((item, index) => (
                                                <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <label>科目</label>
                                    <Select
                                        showSearch
                                        className="commonW150"
                                        onChange={value => { this.classInputHandler(value, 'type') }}
                                        value={this.state.type}
                                    >
                                        {
                                            subjects.filter(n => n != '全部').map((item, index) => (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <label>是否结课</label>
                                    <Select
                                        showSearch
                                        className="commonW150"
                                        onChange={value => { this.classInputHandler(value, 'isEnd') }}
                                        value={this.state.isEnd}
                                    >
                                        {
                                            isEndStatus.map((item, idx) => (
                                                <Option key={idx} value={item.value}>{item.label}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <label>开班日期</label>
                                    <DatePicker
                                        style={{ width: 150 }}
                                        format="YYYY-MM-DD"
                                        placeholder="请选择日期"
                                        value={this.calendarValuePipe(this.state.startDate) || ''}
                                        onChange={(date, dateString) => { this.classInputHandler(dateString, 'startDate') }}
                                    />
                                </div>
                                <div>
                                    <label>班级课程</label>
                                    <Select
                                        showSearch
                                        placeholder="请选择班级课程"
                                        className="commonW400"
                                        onChange={value => { this.classInputHandler(value, 'classCourse') }}
                                        disabled={courseList.length <= 0}
                                        value={this.state.classCourse}
                                    >
                                        {
                                            courseList.map((item, index) => (
                                                <Option key={index} value={item.id}>{item.title}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>)
                        || <p>加载中...</p>}
                </Modal>
            </div>
        );
    }
}

function mapStatetoProps(state) {
    const {
        classModle
    } = state.inquiryReducer;

    const {
        courseList,
        headTeacherArr,
        subjects,
        labels,
    } = state.classList;

    return {
        courseList,
        headTeacherArr,
        subjects,
        labels,
        classModle,
    };
}

export default connect(mapStatetoProps)(ClassInfo);