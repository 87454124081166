import React from 'react';
import { Tooltip, Popconfirm, Popover, TreeSelect, Radio, Table, Input, Button, Modal, Select, Tag } from 'antd';
import { getRouters, systemDeleteMenu, systemAddOrUpdateMenu, getSystemMenuList } from './action';
import IconSelect from '../../components/IconSelect';
import './style.scss'
import { checkPermission } from '../../utils/utils';

const { Option } = Select;

export default class SystemMenu extends React.Component {

    constructor(props) {
        super(props);
        this.init = {
            id: 0,                  // 模态框更新时的id
            parentId: 0,            // 模态框上级菜单
            menuType: 'M',          // 模态框单选按钮菜单类型
            statusModal: 0,         // 模态框单选按钮菜单状态
            isFrame: 1,             // 模态框单选按钮是否外链
            visibleModal: 0,        // 模态框单选按钮是否显示
            icon: '',               // 模态框图标
            path: '',               // 模态框路由地址
            orderNum: null,         // 模态框显示排序
            menuNameModal: '',      // 模态框框菜单名称
            isCache: 0,             // 模态框是否缓存
            component: '',          // 模态框组件路径
            perms: '',              // 模态框权限标识
            remark: '',             // 模态框备注
        };
        this.state = {
            loading: false,
            menuName: '',           // 搜索框菜单名称
            status: '',             // 搜索框状态
            addOrUpdate: false,     // 更新还是新增
            isModalVisible: false,  // 是否显示模态框
            selectMenuList: [],     // 下拉框选择数据
            systemMenuList: [],     // 表格展示数据
            ...this.init,
        };
    }

    componentDidMount() {
        this.refrshData();
    }

    /** 获取表格全部数据 */
    refrshData = async () => {
        this.setState({ loading: true });
        let systemMenuList = await getSystemMenuList({});
        this.setState({ systemMenuList, loading: false });
    }

    /** 查询菜单下拉树结构 */
    getTreeselect = async () => {
        let systemMenuList = await getSystemMenuList({});
        let selectMenuList = [];
        const menu = { id: 0, menuName: '主类目', children: [], remark:'' };
        menu.children = systemMenuList;
        selectMenuList.push(menu)
        await this.setState({ selectMenuList });
    }

    /** 条件搜索列表数据 */
    getSearchData = async () => {
        let { menuName, status } = this.state;
        let data = {
            menuName: menuName,
            status: status,
        }
        let systemMenuList = await getSystemMenuList(data);
        this.setState({ systemMenuList });
    }

    /** 重置表格 */
    reset = async () => {
        await this.setState({ menuName: '', status: '' });
        this.refrshData();
    }

    /** 下拉框状态 */
    onChangeSelect = async (value) => {
        await this.setState({ status: value });
        await this.getSearchData();
    }

    /** 新增列表 */
    handleAdd = (record) => {
        if (record) {
            this.setState({ parentId: record.id })
        }
        this.setState({ addOrUpdate: false, isModalVisible: true });
        this.getTreeselect();
    }

    /** 提交数据 */
    senPost = async (addOrUpdate) => {
        let { remark, perms, component, isCache, id, path, menuNameModal, orderNum, icon, visibleModal, statusModal, isFrame, parentId, menuType } = this.state;
        let data = {
            remark,
            perms,
            component,
            isCache,
            id,
            icon,
            parentId,
            isFrame,
            menuType,
            orderNum,
            path,
            menuName: menuNameModal,
            status: statusModal,
            visible: visibleModal,
        }
        await systemAddOrUpdateMenu(data, addOrUpdate);
        this.setState({ isModalVisible: false, ...this.init });
        this.refrshData();
    }

    refrshMenu = async () => {
        await getRouters();
        window.location.reload();
    }

    /** 渲染表格头部 */
    tableTitle = () => (
        <div className="tableHeader">
            {checkPermission('sys:menu:add') && <Button type="primary" onClick={() => this.handleAdd()}>新增</Button>}
            <Button type="primary" onClick={() => this.refrshMenu()}>更新菜单</Button>
        </div>
    )

    /** 模态框上级菜单选择 */
    onChangeTree = (value) => {
        this.setState({ parentId: value });
    }

    /** 公共状态修改方法 */
    onChangeCommon = (value, key) => {
        this.setState({ [key]: value });
    }

    /** 修改数据时，回显数据 */
    setStatus = (record) => {
        this.setState({
            perms: record.perms,
            component: record.component,
            isCache: record.isCache,
            id: record.id,
            parentId: record.parentId,
            path: record.path,
            menuNameModal: record.menuName,
            orderNum: record.orderNum,
            icon: record.icon,
            visibleModal: record.visible,
            statusModal: record.status,
            isFrame: record.isFrame,
            menuType: record.menuType,
            remark: record.remark,
        });
    }

    /** 修改列表项 */
    updateList = (record) => {
        this.setState({ addOrUpdate: true, isModalVisible: true });
        this.setStatus(record);
        this.getTreeselect();
    }

    /** 删除列表项 */
    deleteList = async (record) => {
        await systemDeleteMenu(record.id);
        this.refrshData();
    }

    /** 关闭模态框，重置状态 */
    onCancelModal = () => {
        this.setState({ ...this.init, isModalVisible: false })
    }

    getType = (type) => {
        if (type == 'M') { return <Tag color="red">目录</Tag>; };
        if (type == 'C') { return <Tag color="green">菜单</Tag>; };
        if (type == 'F') { return <Tag color="purple">按钮</Tag>; };
        return '';
    }

    getRemarkToolTip = (record) => {
        return <div className="noMore" dangerouslySetInnerHTML={{__html: record ? record.remark : '11111'}}></div>
    };

    render() {
        let { remark, loading, perms, component, isCache, addOrUpdate, menuNameModal, orderNum, path, icon, visibleModal, statusModal, isFrame, parentId, menuType, selectMenuList, systemMenuList, menuName, status, isModalVisible } = this.state;

        const columns = [
            {
                title: '菜单名称',
                dataIndex: 'menuName',
                key: 'menuName',
                width: '20%',
            },
            {
                title: '类型',
                width: '5%',
                render: (record, text) => {
                    return (
                        <span>{this.getType(record.menuType)}</span>
                    )
                }
            },
            {
                title: '图标',
                width: '5%',
                render: (record, text) => {
                    return (
                        <i className={`fa ${record.icon}`}></i>
                    )
                }
            },
            {
                title: '排序',
                width: '5%',
                render: (record, text, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: '权限标识',
                dataIndex: 'perms',
                width: '10%',
                key: 'perms',
            },
            {
                title: '组件路径',
                dataIndex: 'component',
                width: '10%',
                key: 'component',
            },
            {
                title: '备注',
                width: '10%',
                render: (text, record) => (
                    <Tooltip title={record.remark}>
                        <span className="ellipsis2" dangerouslySetInnerHTML={{__html: record.remark}}></span>
                    </Tooltip>
                ),
            },

            {
                title: '状态',
                width: '5%',
                render: (record, text) => {
                    return (
                        <span>{record.status ? <Tag color="#f50">停用</Tag> : <Tag color="#87d068">正常</Tag>}</span>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: 'gmtModified',
                width: '10%',
                key: 'gmtModified',
            },
            {
                title: '操作',
                width: '20%',
                render: (record, text) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('sys:menu:update') && <Button onClick={() => this.updateList(record)} size="small" type="primary">修改</Button>}
                            {checkPermission('sys:menu:add') && <Button onClick={() => this.handleAdd(record)} size="small" type="primary">新增</Button>}
                            <Popconfirm
                                title="你确定删除该菜单项吗?"
                                onConfirm={() => this.deleteList(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                                {checkPermission('sys:menu:del') && <Button size="small" type="danger">删除</Button>}
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ];
        const options = (data) => {
            return data.map(item => {
                if (item.children && item.children.length) {
                    options(item.children)
                }
                item.value = item.id;
                item.title = item.menuName;
            })
        }
        selectMenuList && options(selectMenuList);     //打造符合树结构的数据结构
        return (
            <div className="systemMenu">
                {checkPermission('sys:menu:query') && <div className="commonSearchBox">
                    <div><label>菜单名称</label><Input value={menuName} onPressEnter={() => this.getSearchData()} onChange={(e) => this.setState({ menuName: e.target.value })} placeholder="请输入菜单名称" style={{ width: 150 }} /></div>
                    <div><label>状态</label>
                        <Select value={status} onChange={value => this.onChangeSelect(value)} placeholder="菜单状态" style={{ width: '150px' }}>
                            <Option value="">全部</Option>
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </div>
                    <div><Button type="primary" onClick={() => this.getSearchData()}>搜索</Button></div>
                    <div><Button onClick={() => this.reset()}>重置</Button></div>
                </div>}
                <Table
                    columns={columns}
                    dataSource={systemMenuList}
                    rowKey={(record) => record.id}
                    title={() => this.tableTitle()}
                    pagination={false}
                    loading={loading}
                />

                <p style={{textAlign: 'center', margin: '20px 0'}}>已经到底了~</p>

                <Modal width={600} title={addOrUpdate ? '修改菜单' : '添加菜单'} visible={isModalVisible} onOk={() => this.senPost(addOrUpdate)} onCancel={() => this.onCancelModal()}>
                    <div className="systemM">
                        <div>
                            <label>上级菜单</label>
                            <TreeSelect
                                value={parentId}
                                style={{ width: 250 }}
                                placeholder="请选择"
                                onChange={this.onChangeTree}
                                treeData={selectMenuList}
                            />
                        </div>
                        <div>
                            <label>菜单类型</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'menuType')} value={menuType}>
                                <Radio value='M'>目录</Radio>
                                <Radio value='C'>菜单</Radio>
                                <Radio value='F'>按钮</Radio>
                            </Radio.Group>
                        </div>
                        {menuType != 'F' && <div>
                            <label>菜单图标</label>
                            <Popover placement="bottom" title='图标库' content={<IconSelect onSelectCb={(item, idx) => this.setState({ icon: item })} />} trigger="click">
                                <div className="selectIcon">{icon && <i className={`fa ${icon}`}></i> || <span style={{ color: '#d4d4d4' }}>点击选择图标</span>}</div>
                            </Popover>
                        </div>}
                        <div className="helf">
                            <label><span style={{ color: 'red' }}>*</span>菜单名称</label><Input onChange={e => this.onChangeCommon(e.target.value, 'menuNameModal')} value={menuNameModal} style={{ width: 150 }} />
                        </div>
                        <div className="helf">
                            <label><span style={{ color: 'red' }}>*</span>显示排序</label><Input type="number" onChange={e => this.onChangeCommon(e.target.value, 'orderNum')} value={orderNum} style={{ width: 150 }} />
                        </div>
                        {menuType != 'F' && <div className="helf">
                            <label>是否外链</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'isFrame')} value={isFrame}>
                                <Radio value={0}>是</Radio>
                                <Radio value={1}>否</Radio>
                            </Radio.Group>
                        </div>}
                        <div className="helf">
                            <label><span style={{ color: 'red' }}>*</span>路由地址</label><Input onChange={e => this.onChangeCommon(e.target.value, 'path')} value={path} style={{ width: 150 }} />
                        </div>
                        {menuType != 'F' && <div className="helf">
                            <label>显示状态</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'visibleModal')} value={visibleModal}>
                                <Radio value={0}>显示</Radio>
                                <Radio value={1}>隐藏</Radio>
                            </Radio.Group>
                        </div>}
                        {menuType != 'F' && <div className="helf">
                            <label>菜单状态</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'statusModal')} value={statusModal}>
                                <Radio value={0}>正常</Radio>
                                <Radio value={1}>停用</Radio>
                            </Radio.Group>
                        </div>}
                        {menuType != 'M' && <div className="helf">
                            <label>组件路径</label><Input onChange={e => this.onChangeCommon(e.target.value, 'component')} value={component} style={{ width: 150 }} />
                        </div>}
                        {menuType != 'M' && <div className="helf">
                            <label>权限标识</label><Input onChange={e => this.onChangeCommon(e.target.value, 'perms')} value={perms} style={{ width: 150 }} />
                        </div>}
                        {menuType == 'C' && <div className="helf">
                            <label>是否缓存</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'isCache')} value={isCache}>
                                <Radio value={0}>是</Radio>
                                <Radio value={1}>否</Radio>
                            </Radio.Group>
                        </div>}
                        <div style={{width: "100%"}}>
                            <label style={{verticalAlign: 'top'}}>备注</label><Input.TextArea style={{width: '75%'}} rows={2}  onChange={e => this.onChangeCommon(e.target.value, 'remark')} value={remark}  />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
