import React, { Component } from 'react';

export default class IconSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icons: [
                'fa-cog',
                'fa-television',
                'fa-recycle',
                'fa-comments',
                'fa-commenting',
                'fa-trophy',
                'fa-th',
                'fa-truck',
                'fa-sliders',
                'fa-users',
                'fa-history',
                'fa-laptop',
                'fa-magnet',
                'fa-share',
                'fa-retweet',
                'fa-umbrella',
                'fa-upload',
                'fa-folder',
                'fa-globe',
                'fa-arrows',
                'fa-adjust',
                'fa-bars',
                'fa-bell',
                'fa-book',
                'fa-calendar-o',
                'fa-building',
                'fa-comments-o',
                'fa-crop',
                'fa-cubes',
                'fa-bank',
                'fa-child',
                'fa-comment',
                'fa-desktop',
                'fa-eraser',
                'fa-bug',
                'fa-edit',
                'fa-gift',
                'fa-eye',
                'fa-flag',
                'fa-fire',
                'fa-fax',
                'fa-phone',
                'fa-rss',
                'fa-plug',
                'fa-photo',
                'fa-paw',
                'fa-tags',
                'fa-tty',
                'fa-tree',
                'fa-tv',
                'fa-tint',
                'fa-square',
                'fa-ban',
                'fa-tasks',
                'fa-star',
                'fa-print',
                'fa-plane',
                'fa-question',
                'fa-server',
                'fa-tty',
                'fa-wrench',
                'fa-wifi',
                'fa-flask',
                'fa-info',
                'fa-glass',
                'fa-eye',
                'fa-check',
                'fa-cab',
            ]
        };
    }

    select = (item, idx) => {        
        this.props.onSelectCb(item, idx);
    }

    render() {
        return (
            <div style={{ width: '200px', maxHeight: '300px', overflow: 'auto', cursor: 'pointer' }}>
                {this.state.icons.map((item, idx) => (
                    <span key={idx} onClick={() => this.select(item, idx)} style={{ margin: '0 20px 20px 0', width: '30px', display: 'inline-block' }}>
                        <i className={`fa ${item}`} aria-hidden="true">{idx + 1}</i>
                    </span>
                ))}
            </div>
        )
    }
}
