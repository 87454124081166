import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchInterviewQuestions, setKeyValue, resetSearchStatus } from "./actions";
import { fetchQuestionYears } from "../../containers/QuestionList/actions";
import { fetchInterviewAreas } from "../../containers/InterviewList/actions";
import { Table, Select, Button, Tooltip, Input } from "antd";

const Option = Select.Option;
const tags = ['其他', '原创'];

class InterviewSelectCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            showCoverModal: false,
            showVideoModal: false,
            selQuesList: [],
            quesArr: [],
            curQuestionIndex: 0,
            previewShow: false,
            isAllActive: false,                         // 表示选择所有题目是否激活
            keys: [],
        }
    }

    componentWillMount() {
        this.fetchQuestionHandler();
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchInterviewAreas());
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            quesArr: nextProps.quesArr || [],
            selQuesList: nextProps.selQuesList || [],
        });
    }

    /** 加载问题的统一方法 */
    fetchQuestionHandler = () => {
        let data = {};
        data.page = this.props.page || 1;
        data.pageSize = this.props.pageSize || 10;
        let keys = ['area', 'questionTag', 'query', 'year'];
        keys.map(key => { this.props[key] && (data[key] = this.props[key]); });
        this.props.dispatch(fetchInterviewQuestions(data));
    }

    handleSelect = async eventKey => {
        await this.props.dispatch(setKeyValue('page', eventKey));
        this.fetchQuestionHandler();
    };

    handleInput = async e => {
        await this.props.dispatch(setKeyValue('query', e.target.value));
    };

    handleSearch = async () => {
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    resetSearch = async () => {
        await this.props.dispatch(resetSearchStatus());
        this.fetchQuestionHandler();
    }

    selectProvince = async value => {
        await this.props.dispatch(setKeyValue('area', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    selectYear = async value => {
        await this.props.dispatch(setKeyValue('year', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    selectFirstCate = async value => {
        await this.props.dispatch(setKeyValue('questionTag', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    selectQuestion = (question) => {
        let selQuesList = this.state.selQuesList;
        let quesArr = this.state.quesArr;
        if (!quesArr.includes(question.id)) {
            if (this.props.updatePoint) question.point = 0;
            selQuesList.push(question)
            quesArr.push(question.id)
            this.props.updateQuesArr(quesArr);
            this.props.updateSelQues(selQuesList);
            this.setState({ selQuesList: selQuesList, quesArr: quesArr });
        } else {
            this.props.updateQuesArr(quesArr.filter(v => v != question.id));
            this.props.updateSelQues(selQuesList.filter(v => v.id != question.id));
            this.setState({
                quesArr: quesArr.filter(v => v != question.id),
                selQuesList: selQuesList.filter(q => q.id != question.id)
            })
        }
    };

    /** 点击+1 up */
    /** TODO 完成顺序改变功能 */
    handleUp = (event) => {
        this.props.handleUpQues(event.target.id);
    };

    searchExamines = () => {
        this.handleSearch();
    }

    updatePoint = (order, value) => {
        let selQuesList = this.state.selQuesList;
        let newArr = [...selQuesList];
        if (order >= 0 && order < selQuesList.length) {
            newArr[order].point = value == "" ? 0 : value;
        }
        this.props.updateSelQues(newArr);
        this.setState({
            selQuesList: newArr
        })
    };

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };

    /** 生成antd table的配置信息 */
    generateTableColumns = (type) => {
        let columns = [
            {
                title: '标题',
                width: '70%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.question)}>
                        <Link className="ellipsis2" to={`/interviewInfo/${record.examineId}`} dangerouslySetInnerHTML={{__html: record.question}}></Link>
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                width: '10%',
                render: (text, record) => (
                    record.questionTag == 1 ? '其他' : '原创'
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
            },
            {
                title: '年份',
                width: '10%',
                dataIndex: 'year',
                key: 'year',
            },

        ];
        return columns;
    }

    /** 生成antd table已选列表的配置信息 */
    generateTableColumnsSel = (type) => {
        let columns = [
            {
                title: '序号',
                width: '10%',
                render: (text, record, idx) => (
                    idx + 1
                ),
            },
            {
                title: '标题',
                width: '60%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.question)}>
                        <Link className="ellipsis2" to={`/interviewInfo/${record.examineId}`} dangerouslySetInnerHTML={{__html: record.question}}></Link>
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                width: '10%',
                render: (text, record) => (
                    record.questionTag == 1 ? '其他' : '原创'
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
            },
            {
                title: '年份',
                width: '10%',
                dataIndex: 'year',
                key: 'year',
            },
        ];
        return columns;
    }

    title = () => (
        <div className="commonTableTitle">
            <div>已选列表</div>
        </div>
    )

    /** 已选列表最简单，只能取消不能勾选，所见即所得 */
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.props.updateQuesArr(selectedRowKeys);
        this.props.updateSelQues(selectedRows);
    };

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let selQuesList = this.props.selQuesList;
        let quesArr = this.props.quesArr;
        if (selected) {
            selQuesList.push(record);
            quesArr.push(record.id);
        } else {
            let idx = quesArr.indexOf(record.id);
            quesArr.splice(idx, 1);
            selQuesList.forEach((item, idx, arr) => {
                if (item.id == record.id) {
                    arr.splice(idx, 1)
                }
            });
        }
        this.props.updateQuesArr([...quesArr]);
        this.props.updateSelQues([...selQuesList]);
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let selQuesList = this.props.selQuesList;
        let quesArr = this.props.quesArr;
        if (selected) {
            changeRows.map(item => {
                quesArr.push(item.id);
            })
            selQuesList.push(...changeRows);
        } else {
            changeRows.map(item => {
                quesArr = quesArr.filter(v => v != item.id);
                selQuesList = selQuesList.filter(v => v.id != item.id);
            })
        }
        this.props.updateQuesArr([...quesArr]);
        this.props.updateSelQues([...selQuesList]);
    };

    sourceHandler = (arr) => {
        if (!arr.length) return [];
        if (arr[0].name == '全部') return arr;
        let defaultItem = { id: '', name: '全部', subset: [] };
        arr.map((item) => {
            if (item.subset && item.subset.length) {
                this.sourceHandler(item.subset);
            }
        });
        arr.unshift(defaultItem);
        return arr;
    }

    render() {
        let { quesArr, selQuesList, questions, isFetching } = this.props;

        return (
            <div>
                <Table
                    rowSelection={{
                        selectedRowKeys: quesArr,
                        onChange: this.onSelectChange,
                    }}
                    columns={this.generateTableColumnsSel()}
                    dataSource={selQuesList}
                    rowKey={record => record.id}
                    title={() => this.title()}
                    pagination={false}
                />

                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>年份</label>
                        <Select
                            value={this.props.year + ""}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => { this.selectYear(value); }}>
                            <Option value="">全部</Option>
                            {this.props.years && this.props.years.map((year, idx) => {
                                return <Option key={idx} value={year + ""}>{year}</Option>
                            })}
                        </Select>
                    </div>

                    <div className="itemSearchBox">
                        <label>地区</label>
                        <Select
                            value={this.props.area}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => { this.selectProvince(value); }}>
                            <Option value="">全部</Option>
                            {this.props.areas && this.props.areas.map((area, idx) => {
                                return <Option key={idx} value={area + ""}>{area}</Option>
                            })}
                        </Select>
                    </div>

                    <div className="itemSearchBox">
                        <label>类型</label>
                        <Select
                            value={this.props.questionTag}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => { this.selectFirstCate(value); }}>
                            <Option value="">全部</Option>
                            {tags && tags.map((item, idx) => {
                                return <Option key={idx} value={item}>{item}</Option>
                            })}
                        </Select>
                    </div>
                    <div><label>试题搜索</label><Input className="commonW400" placeholder="输入试题的关键词查询" value={this.props.query} onChange={this.handleInput} onPressEnter={() => this.searchExamines()}/></div>
                    <Button disabled={this.props.isFetching} type="primary" onClick={this.handleSearch} className="mr15">搜索</Button>
                    <Button disabled={this.props.isFetching} type="danger" onClick={this.resetSearch}>清空搜索条件</Button>
                </div>

                <Table
                    rowSelection={{
                        selectedRowKeys: quesArr,
                        onSelect: this.onSelect,
                        onSelectAll: this.onSelectAll,
                    }}
                    columns={this.generateTableColumns()}
                    dataSource={questions}
                    rowKey={record => record.id}
                    title={() => '试题列表'}
                    loading={isFetching}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        onChange: this.handleSelect,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    }

}

function mapStatetoProps(state) {

    const {
        isFetching,
        items: questionList,
        totalElements,
        numberOfElements,
        years,
        sources
    } = state.questions;

    const { areas } = state.interviewList


    return {
        isFetching,
        questionList,
        totalElements,
        numberOfElements,
        areas,
        years,
        sources,
        ...state.interviewQuestions
    };
}

export default connect(mapStatetoProps)(InterviewSelectCard);
