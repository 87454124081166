import {
    LOAD_DATA,
    GET_QUESTION,
    SET_VALUE
} from './actions'

const initialState = {
    page: 1,
    pageSize: 20,
    isFetching: true,
    question: '',
    responseObj: {},
    filterStatus: 'ALL',                        // 筛选所有，三个可选值，分别是DONE, ALL, NOT
}

export function interviewStudentList (state=initialState, action) {
    switch (action.type) {
        case LOAD_DATA:
            return Object.assign({}, state, { responseObj: Object.assign({}, state.responseObj, action.data), isFetching: false, });
        case GET_QUESTION:
            return Object.assign({}, state, { question: action.data });
        case SET_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        default:
            return state;
    } 
}