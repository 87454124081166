import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    fetchBanners,
    select_banner,
    publishBanners,
    order_banner,
    deleteBanner,
    setPageSize,
    setActivePage
} from "./actions";
import { BackTop, Table, Button, message, Modal, Alert, Card } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons'
import { checkPermission } from '../../utils/utils';

import './index.scss';

const styles = {
    aside: {
        position: 'absolute',
        width: 240,
        height: 100,
        top: 60,
        right: 10
    }
};


class ModuleBannerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showModal: false, deleteId: '', permission: 0 }
    }

    componentDidMount() {
        this.props.dispatch(fetchBanners({
            page: this.props.activePage,
            pageSize: this.props.pageSize
        }));
    }

    modifyBanners = () => {
        if (!this.props.info.bannersArr.length) {
            message.warning('请至少选择一个banner');
            return;
        }

        this.props.dispatch(publishBanners(this.props.info, {
            page: this.props.activePage,
            pageSize: this.props.pageSize
        }));
    };

    /** 这个e参数的作用是用来提供ID */
    selectBanner = (e, banner) => {
        this.props.dispatch(select_banner(e.target.getAttribute('data-target'), banner));
    };

    paginationChange = (page, pageSize) => {
        this.props.dispatch(setActivePage(page));
        this.props.dispatch(fetchBanners({
            page,
            pageSize: this.props.pageSize
        }));
    }

    onShowSizeChange = async (current, size) => {
        await this.props.dispatch(setActivePage(1));
        await this.props.dispatch(setPageSize(size));
        this.props.dispatch(fetchBanners({
            page: this.props.activePage,
            pageSize: this.props.pageSize
        }));
    }

    handleConfirm = () => {
        this.setState({ showModal: false });
        const cb = () => {
            this.props.dispatch(fetchBanners({
                page: this.props.activePage,
                pageSize: this.props.pageSize
            }));
        };
        deleteBanner(this.state.deleteId, cb);
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    getDeleteId = (id) => {
        this.setState({
            deleteId: id,
            showModal: true
        });
    };

    publish = (id) => {
        this.props.dispatch(publishBanners(id, {
            page: this.props.activePage,
            pageSize: this.props.pageSize
        }))
    }

    /** 生成antd table的配置数据 */
    generateTableConfig = isChosed => {
        let config = [
            {
                title: 'id',
                render: (text, record) => {
                    return (checkPermission('sys:module:gkinfo') && <Link to={`/moduleBanner/${record.id}`}>{record.id}、{record.label && record.label.desc}</Link>
                    || <span>{record.id}、{record.label&&record.label.desc}</span>)
                }
            },
            { title: '图片', render: (text, record) => <img alt="img" src={record.imgUrl} className="bannerImg" /> },
            { title: '是否发布', render: (text, record) => <span>{record.publish ? '已发布' : '未发布'}</span> },


        ]
        checkPermission('sys:module:gkchange') && config.push({
            title: '选择',
            render: (text, record) => (
                <input
                    type="checkbox"
                    data-target={record.id}
                    onChange={() => { }}
                    onClick={e => this.selectBanner(e, record)}
                    checked={this.props.info.bannersArr.includes(record.id)}
                />
            )
        })
        isChosed && config.push({
            title: '操作',
            render: (text, record) => {
                if (isChosed) {
                    return <ArrowUpOutlined onClick={() => this.props.dispatch(order_banner(record.id))} />
                }
            }
        })

        return config;
    }

    /** 生成table的标题头数据 */
    generateTableHeader = isChosed => <span>{isChosed ? '已选banner' : '全部banner'}</span>

    render() {
        let { selBannersList } = this.props.info;
        let chosedColumns = this.generateTableConfig(true);
        let allColumns = this.generateTableConfig(false);

        return (
            <div className="bannerList">
                <div className="flex-container">
                    <main>
                        {
                            checkPermission('sys:module:add') &&
                            <Button type="primary" className="mb15">
                                <Link to="/moduleBannerRelease">新增banner</Link>
                            </Button>
                        }
                        <div className="commonSearchBox">
                            <div><span style={{ color: "red" }}>*提示：</span>图片比例必须是：（宽 ：高 = 2.2 ：1）</div>
                            <div><span style={{ color: "red" }}>*注意：</span>推荐图片大小为：402px(宽)*182px(高)</div>
                        </div>
                        <Table
                            key={Date.now()}
                            columns={chosedColumns}
                            dataSource={selBannersList}
                            rowKey={record => record.id}
                            title={() => this.generateTableHeader(true)}
                            pagination={{
                                pageSize: 1000,
                                showTotal: total => `共有${total}条数据`
                            }}
                        />
                        <br />
                        <Table
                            key={Date.now() + 1}
                            columns={allColumns}
                            dataSource={this.props.items}
                            rowKey={record => record.id}
                            title={() => this.generateTableHeader(false)}
                            pagination={{
                                showSizeChanger: true,
                                onShowSizeChange: this.onShowSizeChange,
                                onChange: this.paginationChange,
                                total: this.props.totalElements,
                                showTotal: total => `共有${total}条数据`,
                                pageSize: this.props.pageSize,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                showQuickJumper: true,
                                current: this.props.activePage
                            }}
                        />
                    </main>
                    {
                        checkPermission('sys:module:publish') &&
                        <Card className="cardR" size="small" title='发布'>
                            <Button loading={this.props.isUpdating} type="primary" onClick={this.modifyBanners}>发布</Button>
                        </Card>
                    }
                </div>

                {/** 删除确认弹窗区域 */}
                <Modal
                    title="确定要删除该banner吗？"
                    visible={this.state.showModal}
                    onOk={this.handleConfirm}
                    onCancel={this.closeModal}
                >
                    <Alert message="一旦删除就无法撤销了哦~" type="warning" />
                </Modal>

                <BackTop />
            </div>
        )
    }

}


function mapStatetoProps(state) {
    return { ...state.moduleBannerList };
}

export default connect(mapStatetoProps)(ModuleBannerList);
