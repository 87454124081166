import apiRequest from '../../common/request';
export const UPLOAD_WORD = "UPLOAD_WORD";
export const upload_word = data => ({
  type: UPLOAD_WORD,
  data
});

/** 由于Ant的upload组件不好实现只上传一个文件并且还带回显，而在UI表现上所实现的是一个fake single file upload，而在这里要做的就是取出第一个文件 */
export const uploadFile = (fileList) => {
  let fileObj = fileList[0];
  let url = `/gk/ops/question/docUploadQuestion`;
  const formData = new FormData();
  formData.append('file', fileObj);
  
  let config = {
    method: 'POST',
    body: formData,
    credentials: 'include'
  };

  return async dispatch => {
    await apiRequest(url, config, true)
      .then(json => {        
          dispatch(upload_word(json));
      })
      .catch(error => console.log(error));
  }
}