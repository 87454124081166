import {
    CONTRACT_NO,//合同编号动作
    SEARCH_USER_INFO,
    USER_HANDLER,
    GET_APPLICATION_AREA,
    SET_CONTRACT_INFO,
    CHANGE_CURRENT_AREA,
    RESET_CURRENT_PAGE,
    SET_CURRENT_FILTER_STATUS,
    GET_SALE_INFORM,
    GET_HEAD_TEACHER_INFORM,
    MODIFY_CURRENT_PAGE,
    MODIFY_CURRENT_PAGESIZE,
    MODIFY_CURRENT_SALES,
    MODIFY_CURRENT_HEAD_TEACHER,
    RESET_SEARCH_CRITERIA,
    LOADING_CONTRACT,
    SET_CONTRACT_LOADING
} from './action';

import { SET_STUDENT_CLASS } from '../Inquiry/action';

const initSearch = {
    page: 1,
    pageSize: 10,
    contractNo: '',                             // 合同编号
    custormers: [],                             // 客户集合
    userInfoIds: undefined,                     // 客户搜索id集合 
    currentArea: '全部',                         // 当前报考地区
    status: '全部',                              // 默认退款状态
    isInterview: '全部',                         // 默认进面状态
    isEnd: '全部',                               // 默认结课状态
    isRenew: '全部',                             // 默认续费状态
    headTeacherId: '全部',                         // 默认班主任选择框
    salesName: '全部',                           // 默认销售选择框 
    classes: [],                                // 搜索班级集合 
    className: '全部',                              // 班级名字 
    classIdx: null,                             // 班级索引
    classId: '全部',                                // 班级id
    isType: null,                                 // 类型 0 行测 1 申论
};

const initialState = {
    loading: false,                              // 是否处于加载状态中
    salles: [],                                 // 销售集合
    headTeachers: [],                           // 班主任集合
    contractInfo: {},
    appLicationArea: [],                        // 报考地区
    ...initSearch
};

export const contractOrder = (previousState=initialState, action) => {
    switch(action.type) {
        case LOADING_CONTRACT:
            return { ...previousState, loading: true };
        case SET_CONTRACT_LOADING: 
            return { ...previousState, loading: false };
        case SEARCH_USER_INFO:
            return { ...previousState, custormers: action.data };
        case USER_HANDLER:
            return { ...previousState, userInfoIds: action.data };
        case CONTRACT_NO:
            return { ...previousState, contractNo: action.data };//更新合同编号
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case GET_APPLICATION_AREA:
            return { ...previousState, appLicationArea: action.data };
        case SET_CONTRACT_INFO:
            return { ...previousState, contractInfo: action.data, loading: false };
        case CHANGE_CURRENT_AREA:
            return { ...previousState, currentArea: action.value };
        case RESET_CURRENT_PAGE:
            return { ...previousState, page: action.value }; 
        case SET_CURRENT_FILTER_STATUS:
            return { ...previousState, [ action.key ]: action.value };
        case GET_SALE_INFORM: 
            return { ...previousState, salles: action.data };
        case GET_HEAD_TEACHER_INFORM:
            return { ...previousState, headTeachers: action.data };
        case MODIFY_CURRENT_PAGE:
            return { ...previousState, page: action.value };
        case MODIFY_CURRENT_PAGESIZE:
            return { ...previousState, pageSize: action.value };
        case MODIFY_CURRENT_SALES:
            return { ...previousState, salesName: action.value };
        case MODIFY_CURRENT_HEAD_TEACHER:
            return { ...previousState, headTeacherId: action.value };
        case RESET_SEARCH_CRITERIA: 
            return { ...previousState, ...initSearch }
        default:
            return previousState;
    }
}