/**
 * Created by pwq on 2017/11/7.
 */

import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "MODULE";

export const REQUEST_BANNERS = PREFIX + "REQUEST_BANNERS";
export const RECEIVE_BANNERS = PREFIX + "RECEIVE_BANNERS";
export const RECEIVE_BANNER_LABELS = PREFIX + "RECEIVE_BANNER_LABELS"
export const SELECT_BANNER = PREFIX + "SELECT_BANNER";
export const ORDER_BANNER= PREFIX + "ORDER_BANNER";

export const REQUEST_UPDATE_BANNER = PREFIX + "REQUEST_UPDATE_BANNER";
export const RECEIVE_UPDATE_BANNER_RESULT_SUC = PREFIX + "RECEIVE_UPDATE_BANNER_RESULT_SUC";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});


function request_update_banner() {
    return {
        type: REQUEST_UPDATE_BANNER
    }
}

function receive_update_banner_result_suc() {
    return {
        type: RECEIVE_UPDATE_BANNER_RESULT_SUC
    }
}

function request_banners() {
    return {
        type: REQUEST_BANNERS
    }
}

function receive_banners(data) {
    return {
        type: RECEIVE_BANNERS,
        bannerList: data.content,
        totalElements: data.totalElements
    }
}

function receive_banner_labels(data) {
    return {
        type: RECEIVE_BANNER_LABELS,
        labels: data
    }
}

export function select_banner(id, banner){
    return {
        type:SELECT_BANNER,
        id,
        banner
    }
}

export function order_banner(id){
    return {
        type:ORDER_BANNER,
        id
    }
}

export function fetchBanners(params) {
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_banners());
        apiRequest('/gk/ops/module/page', config)
            .then(json => {
                dispatch(receive_banners(json.obj));
            });
    }
}

export function publishBanners(info, params) {
    return dispatch => {
        dispatch(request_update_banner());
        apiRequest(`/gk/ops/module/publish?ids=${info.bannersArr.join(',')}`)
            .then(() => {
                dispatch(fetchBanners(params))
                dispatch(receive_update_banner_result_suc());
                message.success('操作成功');
            });
    }
}

export function getLabels(info) {
    return dispatch => {
        dispatch(request_update_banner());
        apiRequest(`/gk/ops/module/labels`)
            .then(json => {
                dispatch(receive_banner_labels(json.obj));
            });
    }
}

export function deleteBanner(id, cb) {
    let url = `/gk/ops/module/delete/${id}`;
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    apiRequest(url, config)
        .then(()=> {
            message.success('操作成功');
            cb && cb();
        })
}
