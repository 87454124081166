import apiRequest from '../../common/request';

const PREFIX = "INTERVIEW_CLASS_LIST";

export const RECEIVE_STUDENT_LIST = PREFIX + "RECEIVE_STUDENT_LIST";
export const SET_FILTER_STATUS = PREFIX + "SET_FILTER_STATUS";

export const receive_student_list = data => ({
    type: RECEIVE_STUDENT_LIST,
    data
});

export const set_filter_status = data => ({
    type: SET_FILTER_STATUS,
    data
});

export function fetchInterviewStudent(params) {
    let url = '/gk/ops/interviewClass/getClassInfo';
    let config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
    };
    return dispatch => {
        return apiRequest(url, config)
        .then(json => {
            dispatch(receive_student_list(json.obj))
        })
        .catch(error => console.log(error));
    }
};

