import React from 'react';
import { Popconfirm, Switch, Radio, Table, Input, Button, Modal, Select, Tag } from 'antd';
import { getUserInfo, getRoleList, changeStatus, getSystemUserList, systemResetUser, systemAddOrUpdateUser } from './action';
import './style.scss'
import { checkPermission } from '../../utils/utils';

const { Option } = Select;
export default class SystemUser extends React.Component {

    constructor(props) {
        super(props)
        this.init = {
            id: 0,
            email: '',
            nickName: '',
            mobile: '',
            password: '',
            sex: 1,
            status: 0,
            userType: 0,
            roleIds: '',
        }

        this.state = {
            ...this.init,
            isModalVisible: false,
            addOrUpdate: false,
            mobileSel: '',
            nickNameSel: '',
            statusSel: '',
            userTypeSel: '',
            page: 1,
            pageSize: 10,
            userListObj: {},
            systemUserList: [],
            roleIdsList: [],
        }
    }

    async componentDidMount() {
        this.refrshData();
        let roleIdsList = await getRoleList();
        this.setState({ roleIdsList });
    }

    /** 获取表格全部数据 */
    refrshData = async () => {
        let userListObj = await getSystemUserList({});
        let systemUserList = userListObj.content;
        this.setState({ userListObj, systemUserList });
    }

    /** 渲染表格头部 */
    tableTitle = () => (
        <div className="tableHeader">
            {checkPermission('sys:user:add') && <Button type="primary" onClick={() => this.handleAdd()}>新增</Button>}
        </div>
    )

    /** 页改变 */
    pageChange = async (p) => {
        await this.setState({ page: p });
        await this.getSearchData();
    }

    /** 页大小改变 */
    onShowSizeChange = async (p, s) => {
        await this.setState({ page: 1, pageSize: s });
        await this.getSearchData();
    }

    /** 下拉框状态 */
    onChangeSelect = async (value, key) => {
        await this.setState({ [key]: value, page: 1 });
        await this.getSearchData();
    }

    /** 条件搜索列表数据 */
    getSearchData = async () => {
        let { mobileSel, nickNameSel, statusSel, userTypeSel, page, pageSize } = this.state;
        let data = {
            page,
            pageSize,
            status: statusSel,
            userType: userTypeSel,
            mobile: mobileSel,
            nickName: nickNameSel
        }
        let userListObj = await getSystemUserList(data);
        let systemUserList = userListObj.content;
        this.setState({ userListObj, systemUserList });
    }

    /** 搜索 */
    search = async () => {
        await this.setState({ page: 1 });
        this.getSearchData();
    }

    /** 重置表格 */
    reset = async () => {
        await this.setState({ page: 1, mobileSel: '', nickNameSel: '', statusSel: '', userTypeSel: '' });
        this.refrshData();
    }

    /** 新增列表 */
    handleAdd = (record) => {
        if (record) {
            this.setState({ parentId: record.id })
        }
        this.setState({ addOrUpdate: false, isModalVisible: true });
    }

    /** 公共状态修改方法 */
    onChangeCommon = (value, key) => {
        this.setState({ [key]: value });
    }

    /** 提交数据 */
    senPost = async (addOrUpdate) => {
        let { roleIds, nickName, mobile, email, password, sex, status, userType, id } = this.state;
        let data = {
            roleIds, nickName, mobile, email, password, sex, status, userType, id
        }
        await systemAddOrUpdateUser(data, addOrUpdate);
        this.setState({ isModalVisible: false, ...this.init });
        this.getSearchData();
    }

    /** 修改数据时，回显数据 */
    setStatus = (record) => {
        this.setState({
            id: record.id,
            email: record.email,
            nickName: record.nickName,
            mobile: record.mobile,
            password: '',
            sex: record.sex,
            status: record.status,
            userType: record.userType,
        });
    }

    /** 修改列表项 */
    updateList = async (record) => {
        let obj = await getUserInfo(record.id);
        let roleIds = obj.roleIds[0];
        await this.setState({ roleIds, addOrUpdate: true, isModalVisible: true });
        this.setStatus(record);
    }

    /** 删除列表项 */
    resetPsw = async (record) => {
        await systemResetUser(record.id);
    }

    /** 改变用户状态 */
    changeUserStatus = async (record) => {
        let status = record.status ? 0 : 1;
        await changeStatus(record.id, status);
        this.getSearchData();
    }

    /** 关闭模态框，重置状态 */
    onCancelModal = () => {
        this.setState({ ...this.init, isModalVisible: false })
    }

    render() {
        let { roleIdsList, roleIds, email, nickName, mobile, password, sex, status, userType, isModalVisible, addOrUpdate, mobileSel, nickNameSel, statusSel, userTypeSel, page, pageSize, userListObj, systemUserList } = this.state;
        const columns = [
            {
                title: '用户id',
                dataIndex: 'id',
                key: 'id',
                width: '20%',
            },
            {
                title: '用户名称',
                width: '20%',
                dataIndex: 'nickName',
                key: 'nickName',
            },
            {
                title: '手机号码',
                width: '20%',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '性别',
                width: '10%',
                render: (record, text) => {
                    return (
                        <span>{record.sex ? <Tag color="#2db7f5">男</Tag> : <Tag color="pink">女</Tag>}</span>
                    )
                }
            },
            {
                title: '状态',
                width: '10%',
                render: (record, text) => {
                    return (
                        <Popconfirm
                            title="你确定修改该用户的状态吗?"
                            onConfirm={() => this.changeUserStatus(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <span>
                                {checkPermission('sys:user:update') && <Switch checkedChildren="正常" unCheckedChildren="停用" checked={!record.status} />}
                            </span>
                        </Popconfirm>
                    )
                }
            },
            {
                title: '操作',
                width: '20%',
                render: (record, text) => {
                    return (
                        <div>
                            {checkPermission('sys:user:update') && <Button onClick={() => this.updateList(record)} size="small" style={{ marginRight: '5px' }} type="primary">修改</Button>}
                            <Popconfirm
                                title="确定重置密码?"
                                onConfirm={() => this.resetPsw(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                                {checkPermission('user:password:reset') && <Button size="small" style={{ marginRight: '5px' }} type="danger">重置密码</Button>}
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="systemUser">
                {checkPermission('sys:user:query') && <div className="commonSearchBox">
                    <div><label>手机号码</label><Input value={mobileSel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ mobileSel: e.target.value })} placeholder="请输入手机号" style={{ width: 150 }} /></div>
                    <div><label>用户昵称</label><Input value={nickNameSel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ nickNameSel: e.target.value })} placeholder="请输入用户昵称" style={{ width: 150 }} /></div>
                    <div><label>帐号状态</label>
                        <Select value={statusSel} onChange={value => this.onChangeSelect(value, 'statusSel')} placeholder="菜单状态" style={{ width: '150px' }}>
                            <Option value="">全部</Option>
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </div>
                    <div><label>用户类型</label>
                        <Select value={userTypeSel} onChange={value => this.onChangeSelect(value, 'userTypeSel')} placeholder="菜单状态" style={{ width: '150px' }}>
                            <Option value="">全部</Option>
                            <Option value="0">系统用户</Option>
                            <Option value="1">外部人员</Option>
                        </Select>
                    </div>
                    <div><Button type="primary" onClick={() => this.search()}>搜索</Button></div>
                    <div><Button onClick={() => this.reset()}>重置</Button></div>
                </div>}

                <Table
                    columns={columns}
                    dataSource={systemUserList}
                    rowKey={(record) => record.id}
                    title={() => this.tableTitle()}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: page,
                        pageSize: pageSize,
                        total: userListObj.totalElements,
                        onShowSizeChange: this.onShowSizeChange,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                <Modal title={addOrUpdate ? '修改用户' : '添加用户'} visible={isModalVisible} onOk={() => this.senPost(addOrUpdate)} onCancel={() => this.onCancelModal()}>
                    <div className="systemU">
                        <div className="helf">
                            <label>用户昵称</label>
                            <Input onChange={e => this.onChangeCommon(e.target.value, 'nickName')} value={nickName} style={{ width: 150 }} />
                        </div>
                        <div className="helf">
                            <label>手机号码</label>
                            <Input onChange={e => this.onChangeCommon(e.target.value, 'mobile')} value={mobile} style={{ width: 150 }} />
                        </div>
                        {<div className="helf">
                            <label>邮箱</label>
                            <Input onChange={e => this.onChangeCommon(e.target.value, 'email')} value={email} style={{ width: 150 }} />
                        </div>}
                        <div className="helf">
                            <label>角色</label>
                            <Select value={roleIds} onChange={value => this.onChangeCommon(value, 'roleIds')} placeholder="请选择" style={{ width: 150 }}>
                                {roleIdsList.map((item, idx) => (
                                    <Option key={idx} value={item.id}>{item.roleName}</Option>
                                ))}
                            </Select>
                        </div>
                        {!addOrUpdate && <div className="helf">
                            <label><span style={{ color: 'red' }}>*</span>密码</label>
                            <Input type="password" onChange={e => this.onChangeCommon(e.target.value, 'password')} value={password} style={{ width: 150 }} />
                        </div>}
                        {<div className="helf">
                            <label>性别</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'sex')} value={sex}>
                                <Radio value={1}>男</Radio>
                                <Radio value={0}>女</Radio>
                            </Radio.Group>
                        </div>}

                        {<div className="helf">
                            <label>用户类型</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'userType')} value={userType}>
                                <Radio value={0}>系统</Radio>
                                <Radio value={1}>外包</Radio>
                            </Radio.Group>
                        </div>}
                        {<div className="helf">
                            <label>状态</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'status')} value={status}>
                                <Radio value={0}>正常</Radio>
                                <Radio value={1}>停用</Radio>
                            </Radio.Group>
                        </div>}
                    </div>
                </Modal>

            </div>
        )
    }

}
