import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Select, Button, Table, Spin, Input } from "antd";
import { fetchQuestions, setKeyValue, resetFilterStatus, createExercisePdf, fetchShenlunAreas } from "./actions";
import { fetchQuestionYears } from "../QuestionList/actions";
import { checkPermission } from '../../utils/utils';
import { fetchResource, fetchTag } from "../EssayCategory/actions";
import './index.scss';
const Option = Select.Option;

class EssayList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { posting: false };
    }

    componentDidMount() {        
        this.getQuestionByCondition();
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchShenlunAreas());
        this.props.dispatch(fetchResource());
        this.props.dispatch(fetchTag());
    }


    handleSelect = async (eventKey) => {
        await this.props.dispatch(setKeyValue('page', eventKey));
        this.getQuestionByCondition();
    };

    selectProvince = async (value) => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('province', value));
        this.getQuestionByCondition();
    };

    selectYear = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('year', value));
        this.getQuestionByCondition();
    };

    selectFirstCate = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('categoryId', value));
        this.getQuestionByCondition();
    };

    selectQuestionTag = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('questionTag', value));
        this.getQuestionByCondition();
    }

    handleInput = e => {
        this.props.dispatch(setKeyValue('title', e.target.value));
    };

    handleInputQuery = e => {
        this.props.dispatch(setKeyValue('query', e.target.value));
    }

    handleSearch = async () => {
        await this.props.dispatch(setKeyValue('page', 1));
        this.getQuestionByCondition();
    };

    /** 当尺码改变的时候触发 */
    onShowSizeChange = async (current, size) => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('pageSize', size));
        this.getQuestionByCondition();
    }

    getQuestionByCondition = () => {
        let data = {};
        data.page = this.props.page || 1;
        data.pageSize = this.props.pageSize || 10;
        let keys = ['categoryId', 'province', 'query', 'questionTag', 'title', 'year'];
        keys.map(key => {
            this.props[key] && (data[key] = this.props[key]);
        });
        this.props.dispatch(fetchQuestions(data));
    }
    /** 点击生成或者更新PDF文件 */
    createPdf = async (record) => {
        this.setState({ posting: true });
        await createExercisePdf(record.id)
        this.setState({ posting: false });
        this.getQuestionByCondition();
    }
    /** 点击查看PDF文件 */
    showPdfBtnClickHandler = record => {
        const pdfUrl = record.pdfUrl;
        if (!pdfUrl) return;
        window.open(pdfUrl);
    }

    generateTableColumns = () => {
        let columns = [
            {
                title: '标题',
                width: '50%',
                render: (text, record) => checkPermission('sys:shenlun:gkinfo') && <Link to={`/essayInfo/${record.id}`}>{record.title}</Link>|| <span>{record.title}</span>
            },
            {
                title: '来源',
                width: '10%',
                dataIndex: 'source'
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area'
            },
            { title: '年份', width: '10%', dataIndex: 'year' },
            {
                title: '预览PDF',
                width: '10%',
                render: (text, record) => {
                    return checkPermission('sys:shenlun:gkview') && <Button size="small" onClick={() => this.showPdfBtnClickHandler(record)} disabled={!record.pdfUrl} type="primary" className="mr10">{record.pdfUrl ? '预览' : '暂无'}</Button>;
                }
            },
            {
                title: '操作',
                width: '10%',
                render: (text, record) => {
                    return (
                        checkPermission('sys:shenlun:info') && <Button type={record.pdfUrl ? 'danger' : 'primary'} size="small" className="pdfButton" onClick={() => this.createPdf(record)}>
                            <span className="btnName">{record.pdfUrl ? '更新PDF' : '生成PDF'}</span>
                        </Button>
                    )
                }
            }
        ];
        return columns;
    }

    renderHeader = () => (
        <div className="commonTableTitle">
            申论列表
            {checkPermission('sys:shenlun:edit') &&
                <Link to={`/essayRelease`}>
                    <Button type="primary">添加申论</Button>
                </Link>
            }
        </div>
    )

    resetFilter = async () => {
        await this.props.dispatch(resetFilterStatus());
        this.getQuestionByCondition();
    }

    render() {
        let columns = this.generateTableColumns();
        return (
            <div className="essayListContainer">
                {checkPermission('sys:shenlun:page') && <div className="commonSearchBox">
                <div>
                    <label>年份</label>
                    <Select
                        value={this.props.year + ''}
                        className="commonW150"
                        dropdownMatchSelectWidth={false}
                        onChange={value => { this.selectYear(value) }}
                    >
                        <Option value="">全部</Option>
                        {this.props.years && this.props.years.map((year, idx) => {
                            return <Option key={idx} value={year + ''}>{year}年</Option>
                        })}
                    </Select>
                </div>
                <div>
                    <label>地区</label>
                    <Select
                        value={this.props.province}
                        className="commonW150"
                        dropdownMatchSelectWidth={false}
                        onChange={value => { this.selectProvince(value) }}
                    >
                        <Option value="">全部</Option>
                        {this.props.areas.map((province, idx) => {
                            return <Option key={idx} value={province}>{province}</Option>
                        })}
                    </Select>
                </div>
                <div>
                    <label>题型分类</label>
                    <Select
                        value={this.props.categoryId}
                        className="commonW150"
                        dropdownMatchSelectWidth={false}
                        onChange={value => { this.selectFirstCate(value) }}
                    >
                        <Option value="">全部</Option>
                        {this.props.resource.map((category, idx) => {
                            return <Option key={idx} value={category.id.toString()}>{category.name}</Option>
                        })}
                    </Select>
                </div>

                <div>
                    <label>标签</label>
                    <Select
                        value={this.props.questionTag}
                        className="commonW150"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ margin: '5px 15px 5px 5px' }}
                        onChange={value => { this.selectQuestionTag(value) }}
                    >
                        <Option value="">全部</Option>
                        {this.props.tags.map((tagObj, idx) => {
                            return <Option key={idx} value={tagObj.value}>{tagObj.desc}</Option>
                        })}
                    </Select>
                </div>
                <div>
                    <label>标题查询</label>
                    <Input
                        className="commonW400"
                        placeholder="输入标题查询"
                        value={this.props.title}
                        onChange={this.handleInput}
                        onPressEnter={() => this.handleSearch()}
                    />
                </div>
                <div>
                    <label>问题查询</label>
                    <Input
                        className="commonW400"
                        placeholder="输入问题关键词查询"
                        value={this.props.query}
                        onChange={this.handleInputQuery}
                        onPressEnter={() => this.handleSearch()}
                    />
                </div>
                <div>
                    <Button disabled={this.props.isFetching} type="primary" onClick={this.handleSearch}>搜索</Button>
                </div>
                <div>
                    <Button disabled={this.props.isFetching} type="danger" onClick={this.resetFilter}>重置</Button>
                </div>
                </div>}
                <Table
                    columns={columns}
                    dataSource={this.props.items}
                    rowKey={record => record.id}
                    title={this.renderHeader}
                    loading={this.props.isFetching}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onShowSizeChange: this.onShowSizeChange,
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.handleSelect,
                        showQuickJumper: true
                    }}
                />
                {/** 提交PDF链接的时候显示loading动画 */}
                {
                    this.state.posting &&
                    <div className="loadingWrapper">
                        <div className="progressWrapper">
                            <Spin tip="客官别急，任务进行中，请稍等......" />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        years
    } = state.questions;

    const {
        resource,
        tags
    } = state.essayCategory;

    return {
        years,
        resource,
        tags,
        ...state.essayList
    };
}

export default connect(mapStatetoProps)(EssayList);
