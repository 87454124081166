import React from 'react';
import { connect } from 'react-redux';
import { Popover, Select, Table, Button, Input, Tooltip, message, Progress, Spin, Popconfirm } from 'antd';
import {
  fetchExamineList,
  fetchExamineTypeList,
  setActiveExamType,
  setPage,
  setPageSize,
  GUFEN_TEXT,
  YATI_TEXT,
  TRUEEXAM_TEXT,
  PUBLISH,
  HAS_PUBLISH,
  NO_PUBLISH,
  setSearchValue,
  resetSearchValue,
  publishExam,
  ALL_TEXT,
  SHOULD_SHARE,
  NOT_SHARE,
  uploadPdfFile2Oss,
  uploadPdfUrl2Server,
} from './action';
import { fetchQuestionYears, fetchQuestionAreas, } from '../QuestionList/actions';
import { resetAllData } from '../ExamineDetail/action'
import { checkPermission, downWord } from '../../utils/utils';
import { resetSearchStatus } from '../../components/QuestionSelectCard/actions';
import { createExercisePdf } from '../ClassExercise/actions';
import apiRequest from '../../common/request';
import './index.scss';

const { Option } = Select;

class ExamineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false, percent: 0, posting: false };
  }

  async componentDidMount() {
    await this.props.fetch_examineTypeList();
    await this.props.set_activeExamType(this.props.activeExamType);
    this.fetchDataHandler();
    this.props.fetchYears();
    this.props.fetchAreas();
  }

  /** 获取试卷列表的辅助方法 */
  fetchDataHandler = () => {
    let params = {
      page: this.props.page,
      pageSize: this.props.pageSize,
    };
    const publishTranslate = { [HAS_PUBLISH]: 1, [NO_PUBLISH]: 0 };
    const shareTranslate = { [SHOULD_SHARE]: 1, [NOT_SHARE]: 0 };
    this.props.publish !== PUBLISH && (params.publish = publishTranslate[this.props.publish]);
    this.props.share !== ALL_TEXT && (params.share = shareTranslate[this.props.share]);
    this.props.query && (params.query = this.props.query);
    let stringCheck = ['area', 'year', 'query'];
    stringCheck.map(key => { this.props[key] && (this.props[key] !== ALL_TEXT) && (params[key] = this.props[key]); })
    let type = this.props.activeExamType;
    if (!type) return;
    this.props.fetch_examineList(params, type);
  }

  /** 改变试卷类型的时候需要重置搜索条件 */
  selectHandler = async value => {
    await this.props.setPage(1);
    await this.props.set_activeExamType(value);
    await this.props.resetSearchValue();
    this.fetchDataHandler();
  }

  /** 上下架处理 */
  publishHandler = examObj => {
    const type = this.props.activeExamType;
    const cb = () => { this.fetchDataHandler(); };
    const id = examObj.examineId || examObj.id;
    publishExam.call(this, type, id, cb);
  }

  gotoExamineDetail = async record => {
    await this.props.resetAllExamineData();
    await this.props.resetSearchStatusFun();
    let id = record.id || record.examineId;
    this.props.history.push(`/examineDetail?id=${id}&type=${this.props.activeExamType}`);
  }

  /** 长传成功的callback */
  successUploadCb = () => { this.setState({ uploading: false, percent: 0 }); }

  /** 根据试卷类型生成examine/uploadPdf接口的examineType参数 */
  generateExamineType = () => {
    const examType = this.props.activeExamType;
    let examineType = null;
    switch (examType) {
      case TRUEEXAM_TEXT:
        examineType = 6;
        break;
      case GUFEN_TEXT:
        examineType = 11;
        break;
      case YATI_TEXT:
        examineType = 10;
        break;
      default:
        examineType = null;
    }
    return examineType;
  }

  /** 确定传给/examine/uploadPdf接口的参数 */
  generatePostParams = (url, examineId) => {
    const examineType = this.generateExamineType();
    if (!url) { message.warn('文件的OSS链接不合法'); return false; }
    if (!examineId) { message.warn('未知试卷ID'); return false; }
    if (!examineType) { message.warn('未知试卷类型'); return false; }
    return { examineId, examineType, url };
  }

  /** 确定文件名，第一点：文件名不交给上传者决定，否则容易出现同名文件被覆盖的情况；第二点：为了便于管理，文件名和试卷名尽量保持一致（在长度不会超出浏览器长度限制的情况下），然后加个试卷ID表示唯一 */
  /** 第三点：每次上传操作都应该确保之前的文件还是可用的，所以这里再加个时间戳 */
  generateFileName = record => {
    let examTitle = record.title;
    const id = record.examineId || record.id;
    // const ts = new Date().toLocaleString();            // 把时间戳去掉，不考虑同时读写的问题(这种情况很少发生，manager给的方案)
    examTitle = examTitle.substr(0, 220);                 // 避免过长
    let result = examTitle + '_' + id + '.pdf';
    result = result.replace(/\//g, '_');                  // 避免因为/导致的出现多个文件目录的问题
    return 'pdf_upload_by_background/examine_list/' + result;
  }

  /** 监听文件变化 */
  handleFileValueChange = async (event, record) => {
    const examineId = record.id || record.examineId;
    const files = event && event.target && event.target.files;
    if (!files || !files.length) { message.warn('请先选择文件'); return; }
    this.setState({ uploading: true });
    const file = files[0];
    if (!file || !file.name || !file.name.includes('pdf')) { message.warn('请确保您上传的文件是以.pdf结尾的文件格式'); return; }
    const fileName = this.generateFileName(record);
    const ossUrl = await uploadPdfFile2Oss.call(this, fileName, file, this.successUploadCb);
    const obj = this.generatePostParams(ossUrl, examineId);
    if (!obj) return;
    await uploadPdfUrl2Server.call(this, obj);
    this.fetchDataHandler();
  }

  /** 点击查看PDF文件 */
  showPdfBtnClickHandler = record => {
    const pdfUrl = record.pdfUrl;
    if (!pdfUrl) return;
    window.open(pdfUrl);
  }

  /** 点击生成或者更新PDF文件 */
  createPdf = async (record) => {
    let type = '';
    if (this.props.activeExamType == "历年真题") type = 6;
    if (this.props.activeExamType == "估分卷") type = 11;
    let examineId = record.examineId ? record.examineId : record.id
    this.setState({ posting: true });
    await createExercisePdf(type, examineId)
    this.setState({ posting: false });
    this.fetchDataHandler();
  }

  /** 筛选掉试题缺失的题目 */
  getContentInfo = (res) => {
    return res.filter((item, idx) => {
      return (!item.content.includes('试题暂缺'));
    });;
  }

  /** 下载为word */
  downWordCb = (obj, flag) => {
    let url = '';
    let that = this;
    let config = { method: 'GET', headers: { 'Accept': 'application/json' } };
    let t = this.props.activeExamType;
    if (t == GUFEN_TEXT) url = `/gk/ops/estimate/getById`;        // 估分试卷类型
    if (t == YATI_TEXT) url = `/gk/ops/mockExamine/getById`;        // 押题试卷类型
    if (t == TRUEEXAM_TEXT) url = `/gk/ops/historyExamine/getById`;      // 历年真题试卷类型
    this.props.setSearchValue('loading', true);
    apiRequest(`${url}?id=${obj.examineId || obj.id}`, config)
      .then(res => {
        that.props.setSearchValue('loading', false);
        let arr = res.obj.questions;
        downWord(that.getContentInfo(arr), 1, obj.title, flag);
      }).catch(() => that.props.setSearchValue('loading', false));
  }

  /** antd table 的数据格式约定配置，不同的试卷类型其需要展示的东西不同 */
  generateColumns() {
    let columns = [
      {
        title: '试卷标题',
        width: '50%',
        render: (text, record) => {
          return checkPermission(this.getType('info')) && <span className="aSpan" onClick={() => this.gotoExamineDetail(record)}>{record.title}</span> || <span>{record.title}</span>
        }
      }
    ];
    const last = [
      {
        title: '生成word/pdf（预览）',
        width: '35%',
        render: (text, record) => {
          return (
            <div className="commonTableBtn">
              {checkPermission('sys:exam:word') && <Popover
                title="下载试卷为word"
                content={<div>
                  <Button type="primary" size="small" onClick={() => this.downWordCb(record, 1)} style={{ margin: '10px' }}>有解析</Button>
                  <Button type="primary" size="small" onClick={() => this.downWordCb(record, 2)} style={{ margin: '10px' }}>无解析</Button>
                  <Button type="primary" size="small" onClick={() => this.downWordCb(record, 3)} style={{ margin: '10px' }}>纯解析</Button>
                </div>}
              >
                <Button size='small' type='link'>下载word</Button>
              </Popover>}
              <Popconfirm
                title={`确定${record.publish && '下架' || '上架'}该试卷？`}
                okText="是" cancelText="否"
                onConfirm={() => { this.publishHandler(record) }}
              >
                {checkPermission(this.getType('publish')) && <Button type='link' size="small" className="mr10" style={{ color: record.publish && 'red' || '#1890ff' }}>{record.publish && '下架' || '上架'}</Button>}
              </Popconfirm>
              {checkPermission('exam:pdf:upload') && <Button type='link' size="small" className="pdfButton">
                <span className="btnName">上传PDF</span>
                <input className="opacityInput" type="file" onChange={event => { this.handleFileValueChange(event, record); }} accept=".pdf" />
              </Button>}
              {checkPermission('exam:pdf:create') && <Button type='link' size="small" onClick={() => this.createPdf(record)}>
                <span style={{ color: record.pdfUrl && 'red' || '#1890ff' }}>{record.pdfUrl ? '更新PDF' : '生成PDF'}</span>
              </Button>}
              {checkPermission('sys:exam:gkview') && <Button size='small' onClick={() => this.showPdfBtnClickHandler(record)} disabled={!record.pdfUrl} type="link" className="mr10">{record.pdfUrl && '预览PDF' || '暂无PDF'}</Button>}
            </div>
          )
        }
      }
    ];
    let style = [];
    if (this.props.activeExamType === TRUEEXAM_TEXT) {
      style = [
        { title: '年份', width: '5%', dataIndex: 'year', key: 'year' },
        {
          title: '地区',
          width: '5%',
          dataIndex: 'area',
          render: text => <Tooltip title={text}>
            <div className="ellipsis2">{text}</div>
          </Tooltip>
        },
        { title: '题数', width: '5%', dataIndex: 'questionNum', key: 'questionNum' },
      ];
    } else if (this.props.activeExamType === GUFEN_TEXT || this.props.activeExamType === YATI_TEXT) {
      style = [
        { title: '题数', width: '5%', dataIndex: 'totalQuestion', key: 'totalQuestion' },
        {
          title: '类型',
          width: '5%', 
          render: (text, record) => {
            if (record.examineInfoType == 0) {
              return <span>行测</span>
            } else if (record.examineInfoType == 1) {
              return <span>申论</span>
            }
          }
        },
        { title: '分数', width: '5%', dataIndex: 'totalPoint', key: 'totalPoint' },
      ];
    }
    columns = columns.concat(style);
    columns = columns.concat(last);
    return columns;
  }

  /** antd table pagination配置生成 */
  generateTablePaginationConfig() {
    let config = {
      current: this.props.page,
      pageSize: this.props.pageSize,
      total: this.props.responseObj.totalElements,
      showTotal: total => `共有${total}条数据`,
      onChange: this.pageHandler,
      onShowSizeChange: this.pageSizeHandler,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '20', '50', '100']
    };
    return config;
  }

  /** 改变页码 */
  pageHandler = async page => {
    await this.props.setPage(page);
    this.fetchDataHandler();
  }

  /** 改变每页数量 */
  pageSizeHandler = async (page, size) => {
    await this.props.setPage(1);
    await this.props.setPageSize(size);
    this.fetchDataHandler();
  }

  /** 添加试卷 */
  newExamine = async () => {
    await this.props.resetAllExamineData();
    await this.props.resetSearchStatusFun();
    this.props.history.push(`/examineDetail?type=${this.props.activeExamType}`);
  }

  /** 生成表头方法 */
  renderHeader = () => (
    <div className="commonTableTitle">
      <span>{`${this.props.activeExamType}试卷列表`}</span>
      {checkPermission(this.getType('add')) && <Button type="primary" onClick={this.newExamine}>添加试卷</Button>}
    </div>
  )

  /** 搜索条件的输入函数 */
  searchInput = async (value, key) => {
    await this.props.setSearchValue(key, value);
    this.searchExamine();
  }

  stringInput = (event, key) => {
    let value = event.target.value;
    this.props.setSearchValue(key, value);
  }

  /** 清空搜索条件 */
  resetSearch = async () => {
    await this.props.resetSearchValue();
    await this.props.setPage(1);
    this.fetchDataHandler();
  }

  /** 搜索试卷 */
  searchExamine = async () => {
    await this.props.setPage(1);                // 初始化为第一页
    this.fetchDataHandler();
  }

  /** 生成条件搜索栏，后端暂时只支持历年真题 */
  renderCondition = () => {
    let key2value = { area: '地区', year: '年份', query: '试卷搜索', publish: '是否上架', share: '是否需要分享' };
    let conditionSelectInput = ['area', 'year', 'publish'];
    let conditionStringInput = ['query'];
    if (this.props.activeExamType === GUFEN_TEXT || this.props.activeExamType === YATI_TEXT) {
      conditionSelectInput = ['publish', 'share'];
      conditionStringInput = ['query'];
    }
    return (
      <div className="commonSearchBox">
        <div>
          <label>试卷类型</label>
          <Select value={this.props.activeExamType} onChange={this.selectHandler} className="commonW150">
            {
              this.props.examTypeList.map((type, idx) => (
                <Option key={idx} value={type}>{type}</Option>
              ))
            }
          </Select>
        </div>

        {
          conditionSelectInput.map((key, idx) => (
            <div key={idx}>
              <label>{key2value[key]}</label>
              <Select value={this.props[key]} onChange={value => { this.searchInput(value, key) }} className="commonW150">
                <Option value={'全部'}>全部</Option>
                {
                  this.props[`${key}List`] && this.props[`${key}List`].map((val, idx) => (
                    <Option key={idx} value={val}>{val}</Option>
                  ))
                }
              </Select>
            </div>
          ))
        }

        {
          conditionStringInput.map((key, idx) => (
            <div key={idx}>
              <label>{key2value[key]}</label>
              <Input onPressEnter={() => this.searchExamine()} value={this.props[key]} onChange={event => { this.stringInput(event, key) }} placeholder={`请输入${key2value[key]}`} className="commonW400" />
            </div>
          ))
        }
        <Button disabled={this.props.loading} onClick={this.searchExamine} type="primary" className="mr20">搜索</Button>
        <Button disabled={this.props.loading} onClick={this.resetSearch} danger>重置</Button>
        <div><span style={{ color: 'red' }}>*提示: </span>上传PDF 按钮是手动选择上传本地PDF文件操作，生成PDF/更新PDF 按钮是在线自动生成更新PDF操作</div>
      </div>
    );
  }

  getType = (type) => {
    let { activeExamType } = this.props;
    if (type == 'page') {
      if (activeExamType == '估分卷') return 'sys:estimate:page';
      if (activeExamType == '历年真题') return 'sys:history:page';
    }
    if (type == 'info') {
      if (activeExamType == '估分卷') return 'sys:estimate:gkinfo';
      if (activeExamType == '历年真题') return 'sys:history:gkinfo';
    }
    if (type == 'publish') {
      if (activeExamType == '估分卷') return 'sys:estimate:publish';
      if (activeExamType == '历年真题') return 'sys:history:publish';
    }
    if (type == 'add') {
      if (activeExamType == '估分卷') return 'sys:estimate:edit';
      if (activeExamType == '历年真题') return 'sys:history:edit';
    }
  }

  render() {
    const columns = this.generateColumns();
    const paginationConfig = this.generateTablePaginationConfig();
    return (
      <div className="examineList">

        {/** 条件输入区域 */}
        {checkPermission(this.getType('page')) && this.renderCondition()}

        {/** 试卷列表区域 */}
        <div className="tableWrapper">
          <Table
            columns={columns}
            dataSource={this.props.responseObj.content}
            rowKey={record => record.id || record.examineId}
            title={this.renderHeader}
            loading={this.props.loading}
            pagination={paginationConfig}
          />
        </div>

        {/** 上传PDF文件到OSS的时候显示进度 */}
        {
          this.state.uploading &&
          <div className="loadingWrapper">
            <div className="progressWrapper">
              <Progress type="circle" percent={this.state.percent} />
            </div>
          </div>
        }

        {/** 提交PDF链接的时候显示loading动画 */}
        {
          this.state.posting &&
          <div className="loadingWrapper">
            <div className="progressWrapper">
              <Spin tip="客官别急，任务进行中，请稍等......" />
            </div>
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = storeState => {
  let yearList = storeState.questions.years;
  let areaList = storeState.questions.areas;
  return {
    ...storeState.examineList,
    yearList,
    areaList
  };
}

const mapDispatchToProps = dispatch => ({
  fetch_examineList: (params, type, cb) => dispatch(fetchExamineList(params, type, cb)),
  fetch_examineTypeList: async () => await dispatch(fetchExamineTypeList()),
  set_activeExamType: async value => await dispatch(setActiveExamType(value)),
  setPage: async value => await dispatch(setPage(value)),
  setPageSize: async pageSize => await dispatch(setPageSize(pageSize)),
  fetchYears: () => dispatch(fetchQuestionYears()),
  fetchAreas: () => dispatch(fetchQuestionAreas()),
  setSearchValue: async (key, value) => await dispatch(setSearchValue(key, value)),
  resetSearchValue: async () => await dispatch(resetSearchValue()),
  resetAllExamineData: () => dispatch(resetAllData()),
  resetSearchStatusFun: () => dispatch(resetSearchStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamineList);
