import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Radio, Input, Select, Button, Table } from "antd";
import { Link } from "react-router-dom";
import {
    fetchInstitutionPdf,
    fetchInstitutionPage,
    fetchInstitutionYearOrArea,
    commonSetStatus,
    resetState,
    fetchInstitutionPublish
} from './actions';
import { resetAllData } from '../PaperDetail/actions';
import { checkPermission } from '../../utils/utils';

const { Option } = Select;

class PaperList extends Component {

    /** 没有公基权限，渲染职测 */
    async componentDidMount() {
        if(!checkPermission('gj:history:page')){
            await this.props.dispatch(commonSetStatus('examType', 'zc'));
        } else if(!checkPermission('zc:history:page')){
            await this.props.dispatch(commonSetStatus('examType', 'gj'));
        }
        this.changeType(false, this.props.examType);
    }

    /** 
     * @param type : gj zc
     * 页面初始化不需要重置状态
     * 点击切换需要重置状态
     */
    changeType = async (boolean, type) => {
        boolean && await this.props.dispatch(resetState());
        await this.props.dispatch(commonSetStatus('examType', type));
        await this.props.dispatch(fetchInstitutionYearOrArea(type, 'area', 'jobArea'));
        await this.props.dispatch(fetchInstitutionYearOrArea(type, 'year', 'jobYear'));
        this.fetchPostData();
    }

    /** 获取列表数据 
     * @param examType : gj zc 
    */
    fetchPostData = () => {
        let { examType, page, pageSize, area, year, publish, query } = this.props;
        let obj = { page, pageSize, area, year, publish, query };
        this.props.dispatch(fetchInstitutionPage(examType, obj));
    }

    /** 重置搜索 */
    reset = async () => {
        await this.props.dispatch(resetState());
        this.fetchPostData();
    }

    /** 共用修改方法 */
    commChange = async (key, value) => {
        await this.props.dispatch(commonSetStatus(key, value));
        await this.props.dispatch(commonSetStatus('page', 1));
        key != 'query' && this.fetchPostData();
    }

    /** 上下架 */
    publishChange = (record) => {
        let { examType } = this.props;
        this.props.dispatch(fetchInstitutionPublish(examType, record.examineId, this.fetchPostData));
    }

    /** 更新PDF */
    updatePdf = (id) => {
        let { examType } = this.props;
        this.props.dispatch(fetchInstitutionPdf(examType, id, this.fetchPostData));
    }

    /** 设置表格头部信息列表 */
    generateColumns = () => (
        [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <div>
                        {checkPermission(this.getType('go')) && 
                        <Link to={`/paperDetail/${this.props.examType}/${record.examineId}`} className="ellipsis2">{record.title}</Link>
                        || <div className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.title }}></div>}
                    </div>
                ),
            },
            {
                title: '地区',
                dataIndex: 'area',
                key: 'area',
            },
            {
                title: '年份',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: '限制时间',
                dataIndex: 'limitTime',
                key: 'limitTime',
            },
            {
                title: '题数',
                dataIndex: 'questionNum',
                key: 'questionNum',
            },
            {
                title: '操作',
                render: (record) => (
                    <div className="commonTableBtn">
                        {checkPermission(this.getType('pub')) && <Button onClick={() => this.publishChange(record)} size="small" type={record.publish && 'danger' || 'primary'}>{record.publish && '下架' || '上架'}</Button>}
                        {checkPermission(this.getType('pdf')) && <Button onClick={() => this.updatePdf(record.examineId)} size="small" type={record.pdfUrl && 'danger' || 'primary'}>{record.pdfUrl && '更新pdf' || '生成pdf'}</Button>}
                        {checkPermission(this.getType('see')) && record.pdfUrl && <Button onClick={() => window.open(record.pdfUrl)} size="small" type="primary">查看pdf</Button>}
                    </div>
                ),
            },
        ]
    );

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>{this.props.examType == 'gj' ? '公基' : '职测'}试卷列表</span>
            <Button type="primary" onClick={() => { this.props.dispatch(resetAllData()); this.props.history.push(`/paperAdd/${this.props.examType}`) }}>添加试卷</Button>
        </div>
    )

    /** 表格分页方法 */
    onChangePage = async (current, pageSize) => {
        await this.props.dispatch(commonSetStatus('page', current));
        await this.props.dispatch(commonSetStatus('pageSize', pageSize));
        this.fetchPostData();
    }

    /** 动态权限判断方法 */
    getType = (str) => {
        let { examType } = this.props;
        if (examType == 'gj') {
            if (str == 'pdf') return 'gj:exam:pdf';
            if (str == 'go') return 'gj:history:info';
            if (str == 'pub') return 'gj:history:publish';
            if (str == 'see') return 'gk:gj:pdf';
        }
        if (examType == 'zc') {
            if (str == 'pdf') return 'zc:exam:pdf';
            if (str == 'go') return 'zc:history:info';
            if (str == 'pub') return 'zc:history:publish';
            if (str == 'see') return 'gk:zc:pdf';
        }
        return '';
    }

    render() {
        let { examType, query, publish, year, area, jobYear, jobArea, institutionExamObj, loading } = this.props;
        return (
            <div>
                <Radio.Group value={examType} onChange={(e) => this.changeType(true, e.target.value)} defaultValue="gj" buttonStyle="solid">
                    <Radio.Button value="gj">公基</Radio.Button>
                    <Radio.Button value="zc">职测</Radio.Button>
                </Radio.Group>
                <div className="commonSearchBox">
                    <div>
                        <label>年份</label>
                        <Select value={year} className="commonW150" onChange={value => { this.commChange('year', value) }}>
                            <Option value={''}>全部</Option>
                            {jobYear && jobYear.map((year, idx) => {
                                return <Option key={idx} value={year + ''}>{year}</Option>
                            })}
                        </Select>
                    </div>
                    <div>
                        <label>地区</label>
                        <Select value={area} className="commonW150" onChange={value => { this.commChange('area', value) }}>
                            <Option value={''}>全部</Option>
                            {jobArea && jobArea.map((year, idx) => {
                                return <Option key={idx} value={year + ''}>{year}</Option>
                            })}
                        </Select>
                    </div>
                    <div>
                        <label>是否上架</label>
                        <Select value={publish} className="commonW150" onChange={value => { this.commChange('publish', value) }}>
                            <Option value={''}>全部</Option>
                            <Option value={1}>已上架</Option>
                            <Option value={0}>已下架</Option>
                        </Select>
                    </div>
                    <div>
                        <label>搜索标题</label>
                        <Input value={query} onChange={e => this.commChange('query', e.target.value)} onPressEnter={() => this.fetchPostData()} className="commonW400" placeholder="回车键查询" />
                    </div>
                    <div>
                        <Button type="primary" onClick={() => this.fetchPostData()}>搜索</Button>
                    </div>
                    <div>
                        <Button type="danger" onClick={() => this.reset()}>重置</Button>
                    </div>
                </div>
                <Table
                    columns={this.generateColumns()}
                    dataSource={institutionExamObj.content}
                    rowKey={record => record.examineId}
                    title={() => this.renderHeader()}
                    loading={loading}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: institutionExamObj.totalElements,
                        onChange: this.onChangePage,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    }
}
function mapStatetoProps(state) {
    return {
        ...state.paperList,
    };
}
export default connect(mapStatetoProps)(PaperList)