import { Component } from 'react';
import { connect } from "react-redux";
import { individual_detail } from './action';
import { Spin, Breadcrumb, Radio, Button, Tooltip } from 'antd';
import Shenlun from '../../components/AnswerCardShenlun';
import { getSearchQuery } from '../../utils/utils';
import './index.scss';

class ShenlunStudentDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showComment: true,
    }
  }

  async componentDidMount() {
    await this.init();
    this.getShenlunStudentInfo();
  }

  componentWillReceiveProps(nextProps) {
    let nextStudentId = nextProps.match.params.userExamineId;
    let prevStudentId = this.props.match.params.userExamineId;
    if (prevStudentId != nextStudentId)  this.getShenlunStudentInfo(nextStudentId);
  }

  /** 初始化方法 */
  init = () => {
    let singleInfo = JSON.parse(window.localStorage.getItem('singleInfo'));
    this.singleInfo = singleInfo;
  };

  /** 获取页面学员数据 */
  getShenlunStudentInfo = (id) => {
    let userExamineId = this.props.match.params.userExamineId;
    let type = getSearchQuery('type');
    if (id) userExamineId = id;
    this.props.individualDetail(userExamineId, type);
  };

  /** 提交完毕刷新 */
  updateMe = () => {
    this.getShenlunStudentInfo();
  }

  /** 获取当前索引 */
  findCurrentStudentIdx = () => {
    let id = this.props.match.params.userExamineId;
    let singleInfo = this.singleInfo;
    if (Object.prototype.toString.call(singleInfo) != '[object Array]') return;
    if (singleInfo && singleInfo.length) {
      for (let singleIdx in singleInfo) {
        if (singleInfo[singleIdx].userExamineId == id) {
          return Number(singleIdx);
        };
      }
    }
  };

  /** 获取班级列表路径 */
  getClassPath = () => {
    let pathGather = {};
    let savePath = JSON.parse(window.localStorage.getItem('savePath'));
    if (savePath) {
      Object.assign(pathGather, savePath);
    }
    return pathGather;
  };

  /** 获取当前学员姓名 */
  getStudentName = (index) => {
    let singleInfo = this.singleInfo;
    if (Object.prototype.toString.call(singleInfo) != '[object Array]') return;
    if (singleInfo && singleInfo.length) {
      for (let singleIdx in singleInfo) {
        if (singleIdx == index) {
          return singleInfo[singleIdx].studentName;
        };
      }
    }
  };

  /** 点击上一位学员 */
  prevStudent = () => {
    let currentIndex = this.findCurrentStudentIdx();
    if (currentIndex <= 0) return;
    let nextStudentObj = this.findPrevOrNextId(currentIndex, true);
    const { userExamineId } = nextStudentObj;
    this.jump2NextPrevStudent(userExamineId);
  };

  /** 点击下一位学员 */
  nextStudent = () => {
    let currentIndex = this.findCurrentStudentIdx();
    if (currentIndex >= this.singleInfo.length - 1) return;
    let nextStudentObj = this.findPrevOrNextId(currentIndex);
    const { userExamineId } = nextStudentObj;
    this.jump2NextPrevStudent(userExamineId);
  };

  /** 返回上下位学员的ID  prev：是否是上一个或者下一个 */
  findPrevOrNextId = (currentIndex, prev) => {
    if (prev) return this.singleInfo[currentIndex - 1];
    else return this.singleInfo[currentIndex + 1];
  };

  /** 改变是否显示批注 */
  controlShowComment = () => {
    let v = this.state.showComment;
    v = !v;
    this.setState({ showComment: v });
  }

  /** 页面跳转到上下位学员 */
  jump2NextPrevStudent = studentId => {
    let url =`/shenlunStudentDetail/${this.props.match.params.id}/${this.props.match.params.exerciseId}/${studentId}?type=${getSearchQuery('type')}&className=${getSearchQuery('className')}&title=${getSearchQuery('title')}`;
    this.props.history.replace(url);
  };

  /** 返回班级列表页面 */
  backToClassList = () => {
    const {classId, classType, classNo, isShuaTi} = this.getClassPath();
    let url = `/classExercise?classId=${classId}&classType=${classType}&classNo=${classNo}&isShuaTi=${isShuaTi}`;
    this.props.history.replace(url);
  }

  /** 返回学员列表页面 */
  backToCurrentClassStudent = () => {
    let url = `/shenlunDetail?id=${this.props.match.params.id}&exerciseId=${this.props.match.params.exerciseId}`;
    this.props.history.replace(url);
  }

  render() {
    const {individualObj} = this.props;
    let currentIndex = this.findCurrentStudentIdx();                                  // 当前学员的索引位置
    let prevNums = currentIndex;                                                      // 当前学员前面还有多少位学员
    let nextNums = this.singleInfo ? this.singleInfo.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
    let name = this.getStudentName(currentIndex);
    return (
      <div>
        <Breadcrumb className="breadHead">
          <Breadcrumb.Item>
            <span className="backList" onClick={ this.backToClassList }>班级练习</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="backList" onClick={ this.backToCurrentClassStudent }>学员列表</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>学员详情</Breadcrumb.Item>
        </Breadcrumb>

        <div className="single-container">
          <div className="flexrc childDiv borderBottom">
            <span className="mb5">班级:</span>
            <span className="marginRight25 mb5">{getSearchQuery('className')}</span>
            <span className="mb5">学员:</span>
            <span className="marginRight25 mb5">{name}</span>
            <span className="flexG"></span>
            <Radio checked={this.state.showComment} onChange={this.controlShowComment}>是否显示批注</Radio>
          </div>

          <div className="flexrc childDiv borderBottom">
            <span className="mb5">班级练习:</span>
            <span className="marginRight25 mb5">{getSearchQuery('title')}</span>
          </div>
          
          {/** 学员班级测试数据开始展示 */}
          <div>
            {this.props.loading && <Spin /> || 
            <Shenlun 
              classes={individualObj}
              classType={getSearchQuery('type')}
              showComment={this.state.showComment}
              exercise={true}
              update={this.updateMe}
            />}
          </div>

          <div className="nextAndPrevBtn opacity1">
            <div className="flexRow height100">
              <span className="userName">{name}</span>
              <span className="flexg"></span>
              <Button onClick={this.backToClassList} type="primary" className="mr30">返回班级练习列表页面</Button>
              <Button onClick={this.backToCurrentClassStudent} type="primary" className="mr30">返回学员列表页面</Button>
              <Tooltip title={`前面还有${prevNums}位学员`}>
                <Button disabled={this.singleInfo && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一位学员</Button>
              </Tooltip>
              <Tooltip title={`后面还有${nextNums}位学员`}>
                <Button disabled={this.singleInfo && currentIndex >= this.singleInfo.length-1} onClick={this.nextStudent} type="primary">下一位学员</Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    loading,
    individualObj,
  } = state.shenlunStudentDetail;

  return {
    loading,
    individualObj,
  };
}

const mapDispatchToProps = dispatch => ({
  individualDetail: (id, type) => { dispatch(individual_detail(id, type)); }, 
});

export default connect(mapStateToProps, mapDispatchToProps)(ShenlunStudentDetail);