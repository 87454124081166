import React from 'react';
import { connect } from 'react-redux';
import Comment from '../../components/Comment';
import { Input, Tooltip, Radio, Descriptions, Image, Drawer, DatePicker, Table, Select, message, Tag, Button } from 'antd';
import moment from 'moment';
import { fetchClassList, getRecord, commonChange, getCondition, update, reset } from './actions';
import { checkPermission } from '../../utils/utils';

const { Option } = Select;
class DoList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            info: {},
            setVisible: false,
        }
    }

    /** 没有申论权限，渲染综应 */
    async componentDidMount() {
        if(!checkPermission('zy:exercise:record')){
            await this.props.dispatch(commonChange('examType', 1));
        } else if(!checkPermission('sl:exercise:record')){
            await this.props.dispatch(commonChange('examType', 5));
        }
        this.changeType(false, this.props.examType);
    }

    /** 页面初始化加载数据判断
     * @param boolean: 是否需要重置
     * @param type : 1 5 申论，综应
     * 页面初始化不需要重置状态
     * 点击切换需要重置搜索条件 和 已选学员 状态
     */
    changeType = async (boolean, type) => {
        boolean && await this.props.dispatch(reset());
        boolean && await this.props.dispatch(commonChange('ids', []));
        await this.props.dispatch(commonChange('examType', type));
        this.getData();
    }

    /** 请求数据 */
    getData = () => {
        let { title, classIds, examType, userIds, teacherIds, examineIds, page, pageSize, examineType, isFinish, startDate, endDate } = this.props;
        let data = { title, classIds, userIds, teacherIds, examineIds, page, pageSize, examineType, isFinish, startDate, endDate }
        this.props.dispatch(getRecord(examType, data));
    }

    /** 公用改变redux状态方法 */
    onChangeCommon = async (key, val) => {
        await this.props.dispatch(commonChange('page', 1));
        await this.props.dispatch(commonChange(key, val));
        key!='title' && this.getData();
    }

    /** 回车搜索 */
    onOk = async () => {
        this.getData();
    }

    /** 渲染表格列表标签 */
    getTypeInfo = (type, record) => {
        if (type == 1) {
            if (record.examineType == 5) return <Tag color="green">班级测试</Tag>;
            if (record.examineType == 9) return <Tag color="purple">课后练习</Tag>;
            return <Tag>未知</Tag>;
        } else if (type == 2) {
            return (record.feedbackRemark || record.feedbackUrl) && <Tag color="blue">已批改</Tag> || <Tag color="red">未批改</Tag>
        } else if (type == 3) {
            if (record == 1) return '申论';
            if (record == 5) return '综应';
        }
    }

    /** 打开批改面板 */
    open = (record) => {
        this.setState({ setVisible: true, info: record });
    }

    /** 渲染表格列表信息 */
    generateTableColumns = () => (
        [
            { title: '学员', width: '6em', render: record => checkPermission('gk:record:edit') && <Button type="link" onClick={() => this.open(record)}>{record.userName}</Button> || <div>{record.userName}</div> },
            { title: '作业名称', dataIndex: 'title' },
            { title: '班级名称', dataIndex: 'className'},
            { title: '练习名称', width: '80px', render: record => `练习${record.sort}` },
            { title: '分配老师', width: '80px', dataIndex: 'teacherName' },
            { title: '联系方式', width: '100px', dataIndex: 'mobile' },
            { title: '练习类型', width: '70px', render: record => this.getTypeInfo('1', record) },
            { title: '是否批改', width: '70px', render: record => this.getTypeInfo('2', record) },
            { title: '提交时间', width: '100px', render: record => <div>{record.submitDate}</div>},
            { title: '学员备注', with: '4em', render: record => <Tooltip title={record.remark}><div className="ellipsis1" style={{maxWidth: '4em'}}>{record.remark}</div></Tooltip>},
        ]
    );

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';

    /** 分配批改 */
    update = async () => {
        let { examType, teacherId, ids } = this.props;
        if (teacherId == '') return message.warn('请选择批改老师');
        await this.props.dispatch(update(examType, teacherId, ids));
        await this.props.dispatch(commonChange('ids', []));
        this.getData();
    }

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <div>
                <span style={{ marginRight: '20px' }}>{this.getTypeInfo(3, this.props.examType)}提交记录</span>
                <label style={{ fontWeight: 'normal' }}>选择批改老师</label>
                <Select
                    showSearch
                    value={this.props.teacherId}
                    placeholder="请选择"
                    filterOption={false}
                    style={{ width: '200px' }}
                    onSearch={value => { this.handleChange('employeeList', value) }}
                    onChange={value => { this.props.dispatch(commonChange('teacherId', value)) }}
                >
                    {this.props.employeeList.map((item, idx) => (
                        <Option key={idx} value={item.id}>{`${item.name}(${item.department})`}</Option>
                    ))}
                </Select>
            </div>
            <div>
                <span style={{ marginRight: '20px' }}>已选择<span style={{ color: 'red' }}>{this.props.ids.length}</span>位学员</span>
                {checkPermission('sl:exercise:update') && <Button disabled={!this.props.ids.length} type="primary" onClick={() => this.update()}>确认分配</Button>}
            </div>
        </div>
    );

    /** 表格改变页码 */
    handleSelect = async (page, pageSize) => {
        await this.props.dispatch(commonChange('page', page));
        await this.props.dispatch(commonChange('pageSize', pageSize));
        this.getData();
    }

    /** 实时搜索动态条件 */
    handleChange = (type, value) => {
        if (value == '') return;
        let { examineType, examType } = this.props;
        this.props.dispatch(getCondition(type, value, examineType, examType));
    }

    /** 实时搜索动态班级条件 */
    handleChange2 = (value) => {
        if (value == '') return;
        let { examType } = this.props;
        this.props.dispatch(fetchClassList(value, examType));
    }

    /** 搜索重置 */
    reset = async () => {
        await this.props.dispatch(reset());
        this.getData();
    }

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let sel = this.props.ids;
        if (selected) {
            sel.push(record.id);
        } else {
            let idx = sel.indexOf(record.id);
            sel.splice(idx, 1);
        }
        this.props.dispatch(commonChange('ids', [...sel]));
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let sel = this.props.ids;
        if (selected) {
            changeRows.map(item => {
                sel.push(item.id);
            })
        } else {
            changeRows.map(item => {
                sel = sel.filter(v => v != item.id);
            })
        }
        this.props.dispatch(commonChange('ids', [...sel]));
    };

    render() {
        let { info, setVisible } = this.state;
        let { title, classIds, classList, examType, ids, userIds, teacherIds, examineIds, items, employeeList, studentList, exerciseList, page, pageSize, loading, examineType, isFinish, startDate, endDate } = this.props;
        return (
            <div className="doList">
                <Radio.Group value={examType} onChange={(e) => this.changeType(true, e.target.value)} defaultValue="1" buttonStyle="solid">
                    {checkPermission('gj:question:page') && <Radio.Button value={1}>申论</Radio.Button>}
                    {checkPermission('zc:question:page') && <Radio.Button value={5}>综应</Radio.Button>}
                </Radio.Group>
                <div className="commonSearchBox">
                    <div>
                        <label>是否批改</label>
                        <Select
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={isFinish}
                            onChange={value => { this.onChangeCommon('isFinish', value) }}
                        >
                            <Option value={''}>全部</Option>
                            <Option value={0}>未批改</Option>
                            <Option value={1}>已批改</Option>
                        </Select>
                    </div>
                    <div>
                        <label>类型</label>
                        <Select
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={examineType}
                            onChange={value => { this.onChangeCommon('examineType', value) }}
                        >
                            <Option value={''}>全部</Option>
                            <Option value={5}>班级测试</Option>
                            <Option value={9}>课后练习</Option>
                        </Select>
                    </div>
                    <div>
                        <label>起始时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(startDate)}
                            onChange={(date, dateString) => { this.onChangeCommon('startDate', dateString) }}
                        />
                    </div>
                    <div>
                        <label>截止时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(endDate)}
                            onChange={(date, dateString) => { this.onChangeCommon('endDate', dateString) }}
                        />
                    </div>
                    <div>
                        <label>分配老师</label>
                        <Select
                            showSearch
                            mode="multiple"
                            value={teacherIds}
                            filterOption={false}
                            style={{ width: '400px' }}
                            onSearch={value => { this.handleChange('employeeList', value) }}
                            onChange={value => { this.onChangeCommon('teacherIds', value) }}
                        >
                            {employeeList.map((item, idx) => (
                                <Option key={idx} value={item.id}>{`${item.name}(${item.department})`}</Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label>作业名称</label>
                        <Select
                            showSearch
                            mode="multiple"
                            filterOption={false}
                            value={examineIds}
                            style={{ width: '400px' }}
                            onSearch={value => { this.handleChange('exerciseList', value) }}
                            onChange={value => { this.onChangeCommon('examineIds', value) }}
                        >
                            {exerciseList.map((item, idx) => (
                                <Option key={idx} value={item.id}>{item.title}</Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label>班级搜索</label>
                        <Select
                            showSearch
                            mode="multiple"
                            filterOption={false}
                            style={{ width: '400px' }}
                            value={classIds}
                            onSearch={value => { this.handleChange2(value) }}
                            onChange={(value) => { this.onChangeCommon('classIds', value) }}
                        >
                            {classList.map((item, idx) => (
                                <Option key={idx} value={item.id}>{item.className}</Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label>学员搜索</label>
                        <Select
                            showSearch
                            mode="multiple"
                            filterOption={false}
                            style={{ width: '400px' }}
                            value={userIds}
                            onSearch={value => { this.handleChange('studentList', value) }}
                            onChange={value => { this.onChangeCommon('userIds', value) }}
                        >
                            {studentList.map((item, idx) => (
                                <Option key={idx} value={item.userId}>{`${item.studentName}(${item.mobile})`}</Option>
                            ))}
                        </Select>
                    </div>
                    { examineType == 5 && <div>
                        <label>试卷名称</label>
                        <Input style={{ width: '400px' }} placeholder="回车搜索" defaultValue={title} onChange={e => { this.onChangeCommon('title', e.target.value) }} onPressEnter={()=>this.onOk()}/>
                    </div> }
                    <div><Button danger onClick={() => this.reset()}>重置</Button></div>
                    <div>说明<span style={{color: 'red'}}>*</span>：学员搜索条件：1.班级搜索选择班级后可得到该班级所有学员。2.自己手动搜索</div>
                </div>

                <Table
                    columns={this.generateTableColumns()}
                    dataSource={items.content || []}
                    rowKey={record => record.id}
                    title={() => this.renderHeader()}
                    loading={loading}
                    rowSelection={{
                        selectedRowKeys: ids,
                        onSelect: this.onSelect,
                        onSelectAll: this.onSelectAll,
                    }}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: page,
                        total: items.totalElements,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.handleSelect,
                        showQuickJumper: true,
                    }}
                />

                <Drawer
                    width='80%'
                    title={this.getTypeInfo('3', examType)+'批改作业'}
                    placement="left"
                    onClose={() => this.setState({ setVisible: false })}
                    visible={setVisible}
                >
                    <Descriptions title={`练习名称：${info.title}`}>
                        <Descriptions.Item label="学员姓名">{info.userName}</Descriptions.Item>
                        <Descriptions.Item label="学员电话">{info.mobile}</Descriptions.Item>
                        <Descriptions.Item label="批改老师">{info.teacherName}</Descriptions.Item>
                        <Descriptions.Item label="练习类型">{this.getTypeInfo('1', info)}</Descriptions.Item>
                        <Descriptions.Item label="最近修改">{info.gmtModified}</Descriptions.Item>
                        <Descriptions.Item label="提交时间">{info.createTime}</Descriptions.Item>
                    </Descriptions>
                    <p>学员备注：{info.remark}</p>
                    <Image.PreviewGroup>
                        {(info.imageUrl || []).map((item, idx) => (
                            <Image key={idx} style={{ width: 150, height: 150, marginRight: '10px' }} src={item} />
                        ))}
                    </Image.PreviewGroup>
                    {setVisible && <Comment
                        classType={examType}   // 申论传1，综应传5
                        answerId={info.id}
                        questionId={info.questionId}
                        feedbackRemark={info.feedbackRemark}
                        feedbackUrl={info.feedbackUrl}
                        userImgs={info.imageUrl}
                        update={() => { this.setState({ setVisible: false }); this.getData(); }}
                    />}
                </Drawer>
            </div>
        )
    }
}

function mapStatetoProps(state) {
    return {
        ...state.doList,
    };
}

export default connect(mapStatetoProps)(DoList);