import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "BANNER_RELEASE_";
export const FILL_TITLE = PREFIX + "FILL_TITLE";
export const FILL_DESC = PREFIX + "FILL_DESC";
export const FILL_IMG = PREFIX + "FILL_IMG";
export const FILL_TYPE = PREFIX + "FILL_TYPE";
export const REQUEST_PUBLISH = PREFIX + "REQUEST_PUBLISH";
export const RECEIVE_PUBLISH_SUC = PREFIX + "RECEIVE_PUBLISH_SUC";
export const RESET = PREFIX + "RESET";
export const SET_NEW_OBJ = PREFIX  + "SET_NEW_OBJ";
export const REQUEST_BANNER = PREFIX + "REQUEST_BANNER";
export const RECEIVE_BANNER = PREFIX + "RECEIVE_BANNER";

/** 几个具有子类型的大dataType */
export const MINI_DATA_TYPE = 7;
export const RICH_DATA_TYPE = 1;
export const COURSE_DATA_TYPE = 2;
export const DAILY_DATA_TYPE = 3;

const requestBanner = () => ({
    type: REQUEST_BANNER
});

const receiveBanner = data => ({
    type: RECEIVE_BANNER,
    data
});

export function setNewObj(obj) {
    return {
        type: SET_NEW_OBJ,
        data: obj
    };
}

export function fill_title(value) {
    return {
        type: FILL_TITLE,
        key: 'title',
        value
    }
}

export function fill_banner_desc(value) {
    return {
        type: FILL_DESC,
        key: 'content',
        value
    }
}

export function fill_img_url(value) {
    return {
        type: FILL_IMG,
        key: 'imgUrl',
        value
    }
}

export function fill_type(key, value) {
    return {
        type: FILL_TYPE,
        key,value
    }
}

function request_publish() {
    return {
        type: REQUEST_PUBLISH
    }
}

function receive_publish_suc() {
    return {
        type: RECEIVE_PUBLISH_SUC
    }
}

export function reset() {
    return {
        type: RESET
    }
}

export function publishBanner(info, cb) {
    let data = {...info};
    if (data.dataType == 4 || data.dataType == 5 || data.dataType == 6) {
        delete data.dataId;
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    let fetchUrl = "/gk/ops/banner/save";
    return dispatch => {
        dispatch(request_publish());

        apiRequest(fetchUrl, config)
            .then(() => {
                dispatch(receive_publish_suc());
                message.success('操作成功');
                dispatch(reset());
                cb && cb();
            })
    }
}

export function fetchBannerInfo(id) {
    return dispatch => {
        dispatch(requestBanner());
        apiRequest(`/gk/ops/banner/details/${id}`, {credentials: 'include'})
            .then(json => {
                dispatch(receiveBanner(json.obj));
            });
    }
}