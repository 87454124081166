import apiRequest from '../../common/request';

const PREFIX = "INTERVIEW_CLASS_TEST";

export const FILL_EXAMINE_CLASS_ID = PREFIX + "FILL_EXAMINE_CLASS_ID";
export const FILL_EXAMINE_TITLE = PREFIX + "FILL_EXAMINE_TITLE";
export const SELECT_QUESTION = PREFIX + "SELECT_QUESTION";
export const UPDATE_SEL_QUESTION = PREFIX + "UPDATE_SEL_QUESTION";
export const RESET_ALL_DATA = PREFIX + "RESET_ALL_DATA";
export const RECEIVE_EXAMINE_INFO = PREFIX + "RECEIVE_EXAMINE_INFO";

export const reset_all_data = () => ({ type: RESET_ALL_DATA });
export function fill_examine_class_id(value) {
    return {
        type: FILL_EXAMINE_CLASS_ID,
        key: 'classIdDate',
        value
    }
};

export function fill_examine_title(value) {
    return {
        type: FILL_EXAMINE_TITLE,
        key: 'title',
        value
    }
};

export function receive_examine_info(data) {
    return {
        type: RECEIVE_EXAMINE_INFO,
        data
    }
}

export function select_question(value){
    return {
        type:SELECT_QUESTION,
        value,
    }
};

export function update_sel_question(value){
    return {
        type: UPDATE_SEL_QUESTION,
        value,
    }
}

/** 从questions数组里面剥离出questionId数组 */
function getIdsFromQuestion(questions) {
    let arr = [];
    if (!questions) return arr;
    questions.map(quesObj => {
        arr.push(quesObj.id);
    })
    return arr;
}

// 如果是通过复制新测验来到这个页面的话，尽管会携带测验ID，但是复制新测验本身来说，它是新建过程而非修改过程
export function fetchExamine(id, classType) {
    return async dispatch => {
        apiRequest(`/gk/ops/class/exercise/details/${id}`, { credentials: 'include' }).then(json => {
            let quesArr = getIdsFromQuestion(json.obj.interviewQuestions);
            let questionList = json.obj.interviewQuestions;
            let data = {
                title: json.obj.title,
                quesArr: quesArr,               // before is json.obj.quesArr，but does't return in new api
                selQuesList: questionList,
                classIdDate: json.obj.classIdDate,
                id: json.obj.id
            }
            dispatch(receive_examine_info(data))
        }).catch(e=> console.log(e))

    }
}