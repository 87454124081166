import {
    END_FETCHING_DATA,
    RECEIVE_EXERCISE,
    BEGIN_FETCHING_DATA,
    SET_EXERCISE_LIST_AFTER_SEARCH,
    SET_EXERCISE_LIST,
    START_SEARCH,
    SEARCH_DATA_ARRIVE,
    SET_VALUE
} from './actions';

const initialState = {
    startTime: '',
    endTime: '',
    lastClassId: 0,             // 上次班级id
    lastScrollTop: 0,           // 上次滚动条位置
    selArr: [],                 // 已选试题id
    isFetching: false,
    exerciseList: [],
    originExerciseList: [],      // 原始的练习列表数据
    onSearch: false,            // 是否处于搜索班级测试数据状态
    searchedExerciseList: [],   // 根据标题所匹配到的班级测试列表数据
    title: '',                  // 使用标题进行搜索班级测试
    searchPageSize: 10,         // 搜索的时候所指定的pageSize
    searchTotalElements: 10,    // 搜索出来的结果总共有多少元素
    searchPage: 1,              // 搜索出来的结果列表当前页码
};

export function classExercise (state=initialState, action) {
    switch (action.type) {
        case RECEIVE_EXERCISE:
            let newConst = [...action.exerciseList];
            return Object.assign({}, state, {
                exerciseList: action.exerciseList, 
                isFetching: false,
                originExerciseList: newConst,
            });
        case BEGIN_FETCHING_DATA:
            return Object.assign({}, state, { isFetching: true });
        case END_FETCHING_DATA:
            return Object.assign({}, state, { isFetching: false });
        case SET_EXERCISE_LIST_AFTER_SEARCH:
            return Object.assign({}, state, { exerciseList: action.data });
        case SET_EXERCISE_LIST:
            return Object.assign({}, state, { exerciseList: action.data });
        case START_SEARCH:
            return Object.assign({}, state, { onSearch: true });
        case SEARCH_DATA_ARRIVE:
            let result = action.data;
            let searchedExerciseList = result.content;
            let searchTotalElements = result.totalElements;
            return Object.assign({}, state, { searchedExerciseList, searchTotalElements, onSearch: false });
        case SET_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        default:
            return state;
    }
}