import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Table, Modal, Button } from "antd";
// import { checkPermission } from '../../common/utils/utils';
import {
    fetchExamines,
    deleteExamines,
    setPageSize,
    setActivePage
} from './actions';
class DailyExamineList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            deleteId: ''
        }
    }

    componentDidMount() {
        const permission = 2 || 1;//checkPermission(this.props.route.meta)
        this.setState({
            permission
        })
        this.props.dispatch(fetchExamines(this.props.activePage, this.props.pageSize));
    }

    handleSelect = (eventKey) => {
        this.props.dispatch(setActivePage(eventKey));
        this.props.dispatch(fetchExamines(eventKey, this.props.pageSize));
    };

    /** 页面组件切换的时候触发 */
    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setActivePage(1));
        await this.props.dispatch(setPageSize(pageSize));
        this.props.dispatch(fetchExamines(this.props.activePage, this.props.pageSize));
    }

    handleConfirm = () => {
        this.setState({
            showModal: false
        });
        this.props.dispatch(deleteExamines(this.state.deleteId));
    };

    mdeleteExamine = (e) => {
        e.preventDefault();
        this.setState({
            showModal: true,
            deleteId: e.target.getAttribute('data-target')
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '标题',
                width: '25%',
                render: (text, record) => (
                    <Link to={`/dailyExamine/${record.id}`} className="title">{record.title}</Link>
                ),
            },
            {
                title: '时长(秒)',
                width: '25%',
                dataIndex: 'limitTime',
            },
            {
                title: '上线时间',
                width: '25%',
                dataIndex: 'publishDate',
            }
        ];
        return columns;
    }

    tableTitleRender = () => (
        <div>
            {
                this.state.permission == 2 &&
                <div className="commonTableTitle">
                    <span>每日一练列表</span>
                    <Link to={`/publishDailyExamine`}>
                        <Button type="primary">添加每日一练</Button>
                    </Link>
                </div>
            }
        </div>
    )

    render() {
        return (
            <div>
                <Table
                    columns={this.generateTableColumns()}
                    dataSource={this.props.dailyExamineList}
                    rowKey={record => record.id}
                    title={this.tableTitleRender}
                    loading={this.props.isFetching}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onChange: this.handleSelect,
                        onShowSizeChange: this.onShowSizeChange,
                        current: this.props.activePage,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                <Modal
                    title="确定要删除该每日一练吗？"
                    visible={this.state.showModal}
                    onOk={this.handleConfirm}
                    onCancel={this.closeModal}>
                    <p>一但删除就无法撤销了哦~</p>
                </Modal>
            </div>
        )
    }

}


function mapStatetoProps(state) {
    const {
        isFetching,
        items: dailyExamineList,
        totalElements,
        numberOfElements,
        activePage,
        pageSize
    } = state.dailyExamines;

    return {
        isFetching,
        dailyExamineList,
        totalElements,
        numberOfElements,
        activePage,
        pageSize,
    };
}

export default connect(mapStatetoProps)(DailyExamineList);
