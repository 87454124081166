import React from 'react';
import { connect } from 'react-redux';
import { Typography, Modal, Button, Tabs, message, Spin, Table } from "antd";
import {
    uploadOSSImage,
    receive_content_of_image_directory,
    fetch_content_of_image_directory,
    fetchImages,
    image_picker_last_page,
    image_picker_next_page, image_picker_reset,
    reset_image_markers
} from "./action";
import './style.scss'

const { Paragraph } = Typography;

// LOG : 新增一个bucketType props => 表示库的类型 面试音频库的值对应为1
class ImagePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadFiles: [],
            selectedImage: null,
            urlInput: "",
            urlValid: false,
            tabKey: 1,
            audioName: ''
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        let prefix = this.props.imgPath && this.props.imgPath+'/' || 'img/';
        this.props.dispatch(image_picker_reset());
        this.props.dispatch(receive_content_of_image_directory(prefix));
        this.props.dispatch(fetchImages({ 'bucketName': this.props.bucket, prefix }, this.props.bucketType));
    };

    format = (value) => {
        return (value < 10 ? "0" : "") + value;
    };

    transformTime = (value) => {
        const date = new Date(value / 10000);
        return date.getFullYear() + "-" + this.format(date.getMonth() + 1) + "-" + this.format(date.getDate()) + " " +
            this.format(date.getHours()) + ":" + this.format(date.getMinutes()) + ":" + this.format(date.getSeconds());
    };

    transformSize = (value) => {
        const kb = value / 1024;
        const mb = value / (1024 * 1024);
        const gb = value / (1024 * 1024 * 1024);
        if (gb > 1) {
            return gb.toFixed(2) + "GB";
        } else if (mb > 1) {
            return mb.toFixed(2) + "MB";
        } else {
            return kb.toFixed(2) + "KB";
        }
    };

    selectImage = (url) => {
        this.setState({ selectedImage: this.props.images.filter(image => image.url == url)[0] });
    };

    handleUrlInput = (e) => {
        this.setState({ urlInput: e.target.value, urlValid: true });
    };

    handleImageError = () => {
        this.setState({ urlValid: false });
    };

    handleSelectTab = (key) => {
        this.setState({ tabKey: key });
    };

    confirm = () => {
        if (this.state.tabKey == 1) {
            this.props.feedBack(decodeURIComponent(this.state.selectedImage.url.trim()));
        } else if (this.state.tabKey == 3) {
            this.props.feedBack(decodeURIComponent(this.state.urlInput.trim()));
        } else if (this.state.tabKey == 2) {
            this.props.feedBack(decodeURIComponent(this.props.uploadedUrl.trim()));
        }
        this.setState({ urlInput: "", urlValid: false });
        this.props.closeModal();
    };

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let { prefixes, bucketType, bucket } = this.props;
        let columns = [
            {
                title: '文件名',
                width: '20%',
                render: (text, record) => (
                    <div>
                        {record.menu ?
                            <div className="link" onClick={() => this.props.dispatch(fetch_content_of_image_directory(record.name, bucket))}>{record.name}</div>
                            : <div className="link" onClick={() => this.selectImage(record.url)}>{record.name}</div>
                        }
                    </div>
                ),
            },
            {
                title: '图片预览',
                width: '20%',
                render: (text, record) => (
                    <div>
                        {
                            bucketType == 1 ?
                                <audio src={record.url} className="img-preview img-preview2" controls="controls" />
                                :
                                record.url && <img src={record.url} alt="img" style={{ maxWidth: "120px" }} /> || ''
                        }
                    </div>
                ),
            },
            {
                title: '文件类型',
                width: '15%',
                dataIndex: 'type',
            },
            {
                title: '文件大小',
                width: '15%',
                render: (text, record) => (
                    <div>{record.size && this.transformSize(record.size)}</div>
                ),
            },
            {
                title: '上传时间',
                width: '15%',
                dataIndex: 'time',
            },
            {
                title: '操作',
                width: '15%',
                render: (text, record) => <Paragraph copyable={{ text: record.url }}>复制外链</Paragraph>,
            },
        ];
        return columns;
    }

    /** 让同级的目录与图片数组数据结构一样 */
    setMenuToTable = (arr) => {
        if(!arr.length) return [];
        let res = [];
        arr.map((item)=>{
            res.push({'key': item, 'name': item, 'menu': true})
        })
        return res;
    }

    _renderMediaLibrary = () => {
        let { prefixes, images, isFetching } = this.props;
        let dataSource = images.concat(this.setMenuToTable(prefixes));

        return (
            <div className="media-library">
                <div className="media-list">
                    {
                        <div>
                            <Table
                                columns={this.generateTableColumns()}
                                dataSource={dataSource}
                                rowKey={record => record.key}
                                loading={isFetching}
                                pagination={false}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    };

    _renderUploadPanel = () => {
        return (
            <div className="upload-panel" id="image-picker-modal">
                <div id="container">
                    {
                        this.props.bucketType == 1 ?
                            <div className="audio-wrap">
                                <input type="file" accept=".mp3,.aac,.wav,.m4a,.flac" className="btn btn-default btn-audio-upload" onChange={this.onUploadFile} ref="upload" />
                                {/* <span className="audio-name">{this.state.audioName}</span> */}
                            </div>
                            :
                            <div className="audio-wrap">
                                <input type="file" accept="image/*" className="btn btn-default btn-audio-upload" onChange={this.onUploadFile} ref="upload" />
                                {/* <span className="audio-name">{this.state.audioName}</span> */}
                            </div>
                    }
                </div>
                {
                    this.props.isSending &&
                    <Spin />
                }
                {
                    this.props.uploadedUrl && (this.props.bucketType == 1 ?
                        <audio src={this.props.uploadedUrl} id="uploadPreview" controls="controls" />
                        :
                        <img alt="img" style={{margin: '20px', maxWidth: '200px', height: 'auto', border: '1px solid #999'}} src={this.props.uploadedUrl} id="uploadPreview" />)
                }
                {this.props.uploadedUrl && <Paragraph style={{display: 'inline-block', verticalAlign: 'top', marginTop: '16px'}} copyable={{ text: this.props.uploadedUrl }}>{this.props.bucketType == 1 ? '复制音频链接' : '复制图片链接'}</Paragraph>}
            </div>
        )
    };

    _renderUrlPanel = () => {
        return (
            <div className="url-panel">
                <input placeholder="http://" style={{width: '80%', margin: '20px'}} value={this.state.urlInput} onChange={this.handleUrlInput} />
                {
                    this.props.bucketType == 1 ?
                        <audio src={this.state.urlInput} style={{ display: this.state.urlValid ? "block" : "none" }} onError={this.handleImageError} controls="controls" />
                        :
                        <img alt="img" style={{maxWidth: '200px', height: 'auto', display: this.state.urlValid ? "block" : "none"}} src={this.state.urlInput} onError={this.handleImageError} />
                }
            </div>
        )
    };

    onUploadFile = () => {        
        if (!this.refs.upload.files[0]) {           // 没有文件的话不进行上传操作
            return;
        }
        this.setState({ audioName: this.refs.upload.files[0].name });        
        this.props.dispatch(uploadOSSImage(this.refs.upload.files[0], this.props.bucket, this.props.imgPath));
        // this.props.dispatch(imagePickerUploadImage(this.refs.upload.files[0], this.props.bucketType));
    };

    render() {
        let ModalTitle = this.props.bucketType == 1 ? '插入音频' : '插入图片';
        let tabTitle = this.props.bucketType == 1 ? '音频库' : '图片库';
        let lastIndexOf = this.props.prefix.lastIndexOf("/", this.props.prefix.length - 2);
        let { bucket } = this.props;
        return (
            <Modal
                title={ModalTitle}
                className={'image-picker'}
                width={900}
                visible={this.props.showModal}
                onCancel={this.props.closeModal}
                footer={null}>
                <Tabs defaultActiveKey="1" onChange={this.handleSelectTab} type="card">
                    <Tabs.TabPane key="1" tab={tabTitle}>{this._renderMediaLibrary()}</Tabs.TabPane>
                    <Tabs.TabPane key="2" tab="上传文件">{this._renderUploadPanel()}</Tabs.TabPane>
                    <Tabs.TabPane key="3" tab="从URL插入">{this._renderUrlPanel()}</Tabs.TabPane>
                </Tabs>

                <div className="footer-wrap">
                    <div className="page-btns">
                        <Button
                            style={{
                                'width': '30%',
                                'marginRight': 24,
                                'visibility': (this.props.prefix.length > 0) ? 'visible' : 'hidden'
                            }}
                            onClick={() => this.props.dispatch(fetch_content_of_image_directory(this.props.prefix.substring(0, lastIndexOf + 1), bucket))}
                        >返回上一级</Button>
                        <Button
                            style={{
                                'width': '30%',
                                'marginRight': 24,
                                'visibility': (this.state.tabKey == 1 && this.props.markers.length >= 1) ? 'visible' : 'hidden'
                            }}
                            type="primary"
                            onClick={() => {
                                if (this.props.markers.length < 2) {
                                    this.props.dispatch(fetchImages({ prefix: this.props.prefix,  bucketName: bucket}, this.props.bucketType));
                                    this.props.dispatch(reset_image_markers());
                                } else {
                                    this.props.dispatch(image_picker_last_page(this.props.markers[this.props.markers.length - 2], this.props.prefix, bucket));
                                }
                            }}
                        >上一页</Button>
                        <Button
                            style={{
                                'width': '30%',
                                'visibility': (this.state.tabKey == 1 && this.props.marker && (this.props.images.length !== 0 || this.props.prefixes.length !== 0)) ? 'visible' : 'hidden'
                            }}
                            type="primary"
                            onClick={() => this.props.dispatch(image_picker_next_page(this.props.marker, this.props.prefix, bucket))}
                        >下一页</Button>
                    </div>
                    <div className="confirm-btns">
                        <Button
                            type="primary"
                            onClick={this.confirm}
                            disabled={!((this.state.tabKey == 1 && this.state.selectedImage) ||
                                (this.state.tabKey == 2 && this.props.uploadedUrl) ||
                                (this.state.tabKey == 3 && this.state.urlValid)
                            )}
                        >
                            确定
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        items: images,
        isSending,
        marker,
        markers,
        prefixes,
        prefix,
        uploadedUrl
    } = state.imagePicker;

    return {
        isFetching, images, isSending, marker, markers, prefixes, prefix, uploadedUrl
    }
}

export default connect(mapStatetoProps)(ImagePicker);