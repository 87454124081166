import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    RECEIVE_QUESTIONS_YEARS,
    RECEIVE_QUESTIONS_AREAS,
    RECEIVE_QUESTIONS_TYPES,
    SET_PAGE_SIZE,
    RESET_FILTER_STATUS,
    CHANGE_COMMON
} from './actions';

const initState = {
    page: 1,
    pageSize: 10,
    year: '',               //已选年份
    province: [],           //省份
    classify: [],
    categoryId: '-1',       //已选最终分类id
    positionCode: '',
    positionName: '',
    deptName: '',
}

export function positionList(state = {
    ...initState,
    isFetching: true,
    isDeleting: true,
    items: [],
    areas: [],
    types: [],
    years: [],
    quesType: '-1',
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
}, action) {
    switch (action.type) {
        case CHANGE_COMMON:
            return Object.assign({}, state, { [action.key]: action.val });
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, { isFetching: true });
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.positionList,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
                numberOfElements: action.numberOfElements,
                lastUpdated: action.receivedAt
            });
        case RECEIVE_QUESTIONS_AREAS:
            return Object.assign({}, state, {
                areas: action.areas
            });
        case RECEIVE_QUESTIONS_TYPES:
            return Object.assign({}, state, {
                types: action.types
            });
        case RECEIVE_QUESTIONS_YEARS:
            return Object.assign({}, state, {
                years: action.years.reverse()
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case RESET_FILTER_STATUS:
            return { ...state, ...initState };
        default:
            return state;
    }
}
