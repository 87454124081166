import apiRequest from '../../common/request'
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE_ACTIVE = "SET_PAGE_ACTIVE";
export const SET_LOADING = "SET_LOADING";
export const RECEIVE_SYSTEM_INFO = "RECEIVE_SYSTEM_INFO";

const setLoading = () => ({
    type: SET_LOADING,
});
export const setPageActive = data => ({
    type: SET_PAGE_ACTIVE,
    data
});
export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});
function receive_system_info(data) {
    return {
        type: RECEIVE_SYSTEM_INFO,
        data
    }
}
export function fetchSystemInfo(params){
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10,
    }
    params.dataType && (data.dataType = params.dataType);
    params.deviceType && (data.deviceType = params.deviceType);

    return dispatch => {
        dispatch(setLoading());
        apiRequest('/gk/ops/notification/page',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            })
            .then(json => {              
              dispatch(receive_system_info(json.obj));                
            })
            .catch(error => console.log(error));
    }
}