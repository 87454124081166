import { message } from 'antd';
import apiRequest from '../../common/request'
import OSS from 'ali-oss';

export const GUFEN_TEXT = '估分卷';
export const YATI_TEXT = '押题卷';
export const TRUEEXAM_TEXT = '历年真题';

export const ALL_TEXT = '全部';

export const PUBLISH = ALL_TEXT;
export const HAS_PUBLISH = '已上架';
export const NO_PUBLISH = '已下架';

export const SHOULD_SHARE = '需要分享';
export const NOT_SHARE = '不需要分享';

//const EXAM_LIST_TYPES = [ TRUEEXAM_TEXT, GUFEN_TEXT, YATI_TEXT ]; //关闭押题
const EXAM_LIST_TYPES = [ TRUEEXAM_TEXT, GUFEN_TEXT ];

const PREFIX = "_EXAMINE_LIST_";

export const START_LOAD_EXAMINE_LIST = PREFIX + "START_LOAD_EXAMINE_LIST";
export const FETCH_DATA_SUCCED = PREFIX + "FETCH_DATA_SUCCED";
export const FETCH_EXAMLIST_SUCCED = PREFIX + "FETCH_EXAMLIST_SUCCED";
export const SET_ACTIVE_EXAM_TYPE = PREFIX + "SET_ACTIVE_EXAM_TYPE";
export const SET_PAGE = PREFIX + "SET_PAGE";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_SEARCH_VALUE = PREFIX + "SET_SEARCH_VALUE";
export const RESET_SEARCH_VALUE = PREFIX + "RESET_SEARCH_VALUE";


const startLoading = () => ({ type: START_LOAD_EXAMINE_LIST });

const fetchDataSucced = responseObj => ({ type: FETCH_DATA_SUCCED, data: responseObj });

/**
 * 动态配置请求数据
 * @param {String} type : 向哪种类型请求
 * @param {Object} params : POST参数
 */
function configUrlAndData(type, params={}) {
  let url = '';
  let config = {
    credentials: 'include', method: 'POST', body: JSON.stringify(params),
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
  };
  switch (type) {
    case GUFEN_TEXT:                              // 表示是请求估分试卷
      url = `/gk/ops/estimate/page`;
      break;
    case YATI_TEXT:                               // 表示是请求押题卷
      url = `/gk/ops/mockExamine/page`;
      break;
    case TRUEEXAM_TEXT:                           // 表示是请求历年真题
      url = '/gk/ops/historyExamine/page';
      break;
    default:
      throw new Error('不存在这个type');
  }
  return { url, config };
}

function publishGenerateUrl(type, id) {
  let url = '';
  let config = {
    credentials: 'include', method: 'GET',
    headers: { 'Accept': 'application/json', },
  };
  if (type === GUFEN_TEXT) {
    url = `/gk/ops/estimate/publish/${id}`;
  } else if (type === YATI_TEXT) {
    url = `/gk/ops/mockExamine/publish/${id}`;
  } else if (type === TRUEEXAM_TEXT) {
    url = `/gk/ops/historyExamine/publish/${id}`;
  }
  return { url, config };
}

/** 不同的试卷类型，其接口所返回的数据可能不同，这里做一个处理让他们对UI无感知 */
function dataHandler(responseObj, type) {
  if(type==GUFEN_TEXT || type==YATI_TEXT || type==TRUEEXAM_TEXT) {
      return responseObj
  } else {
    throw new Error('dataHandler方法中，不存在这个type');
  }
}

export const setSearchValue = (key, value) => {
  return {
    type: SET_SEARCH_VALUE,
    key,
    value
  }
};

export const resetSearchValue = () => ({ type: RESET_SEARCH_VALUE });

export const setPage = data => ({ type: SET_PAGE, data });

export const setPageSize = data => ({ type: SET_PAGE_SIZE, data });

/** 模拟获取试卷列表数据的过程 */
export const fetchExamineTypeList = () => ({ type: FETCH_EXAMLIST_SUCCED, data: EXAM_LIST_TYPES });

/** 设置处于焦点状态试卷类型 */
export const setActiveExamType = data => ({ type: SET_ACTIVE_EXAM_TYPE, data });

/** 如果存在地区属性，而且地区属性是数组的话，那么需要把它转换成字符串形式 */
const arr2StrUtils = val => {
  if (Object.prototype.toString.call(val) === '[object Array]') return val.join(',');
  else return val;
}

/**
 * 获取各种类型的试卷列表数据
 * @param {Object} params : POST参数
 * @param {*} type : 发起哪种类型的请求
 * @param {*} cb : 请求完成之后的回调
 */
export function fetchExamineList(params, type, cb) {
  return async dispatch => {
    dispatch(startLoading());
    const configObj = configUrlAndData(type, params);
    apiRequest(configObj.url, configObj.config)
      .then(json => {
        let responseObj = json.obj;
        responseObj = dataHandler(responseObj, type);
        if (Object.prototype.toString.call(responseObj.content) === '[object Array]') {
          responseObj.content = responseObj.content.map(examineObj => {
            examineObj.area = arr2StrUtils(examineObj.area);
            return examineObj;
          })
        }
        dispatch(fetchDataSucced(responseObj));
      }).catch(e => {})
  }
}

/**
 * 上下架试卷
 * @param {String} type : 试卷类型
 * @param {Number} id : 试卷ID
 */
export async function publishExam(type, id, cb) {
  let { url, config } = publishGenerateUrl(type, id);
  apiRequest(url, config)
    .then(json => {
      message.success('操作成功');
      setTimeout(() => {
        cb && cb();
      }, 900);
    }).catch(e => {})
}

/** 返回OSS文件的链接 */
const returnPdfUrl = responseObj => {
  let result = null;
  if (responseObj.url) {
    result = responseObj.url;
  } else {
    result = responseObj && responseObj.res && responseObj.res.requestUrls && responseObj.res.requestUrls[0];
    result = result && result.split('?')[0];
  }
  return result;
}

/** 负责上传PDF操作, 接受参数为文件名以及文件对象，返回数据为所上传PDF的链接 */
export async function uploadPdfFile2Oss(fileName, file, successUploadCb) {
  let that = this;
  let tokenJson = await apiRequest('/gk/ops/sts/getToken?type=oss', { method: 'GET', credentials: 'include' });
  if (tokenJson.status !== 1) return;
  let result = tokenJson.obj;
  let client = new OSS({
    accessKeyId: result.accessKeyId,
    accessKeySecret: result.accessKeySecret,
    stsToken: result.securityToken,
    endpoint: 'https://oss-cn-beijing.aliyuncs.com',
    bucket: 'gk-document'
  });
  let putResponse = await client.multipartUpload(fileName, file, {
    progress: function(p) {
      return function(done) {
        p = parseInt(p * 100);
        that.setState({ percent: p });
        if (p >= 100) { setTimeout(() => { successUploadCb && successUploadCb(); }, 450); }
        done();
      };
    },
  });
  return returnPdfUrl(putResponse);
}

/** 把文件URL上报到公考后端 */
export async function uploadPdfUrl2Server(obj) {
  this.setState({ posting: true });
  const config = { 
    method: 'POST', body: JSON.stringify(obj),
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
  };
  const response = await apiRequest('/gk/ops/examine/uploadPdf', config);
  this.setState({ posting: false });
  if (response.status === 1) message.success('操作成功');
}
