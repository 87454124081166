import apiRequest from '../../common/request'

const PREFIX = "INSTITU";

export const COMMON_SET_STATUS = PREFIX + "COMMON_SET_STATUS";
export const RESET_STATE = PREFIX + "RESET_STATE";

/** 公用修改数据方法 */
export function commonSetStatus(key, value) {
    return {
        type: COMMON_SET_STATUS,
        key, value
    }
}

/** 重置方法 */
export function resetState() {
    return {
        type: RESET_STATE,
    }
}

/** 判断分类id是否使用 */
export function getCategoryId(arr) {
    if(arr[arr.length-1]=='zc'||arr[arr.length-1]=='A'||arr[arr.length-1]=='B'||arr[arr.length-1]=='C'||arr[arr.length-1]=='D'){
        return false;
    }
    return arr[arr.length-1];
}

/** 
 *  打造复用接口方法
 * @param type :gj  zc  公基还是职测
 // if(type=='gj') {url = 'gk/ops/gj/question/page'};
 // if(type=='zc') {url = 'gk/ops/zc/question/page'};
 */
export function fetchInstListPage(type, obj) {
    let url = `/gk/ops/${type}/question/page`;
    let data = {
        page: obj.page || 1,
        pageSize: obj.pageSize || 10,
    }
    obj.questionTag!=='' ? data.questionTag = obj.questionTag : '';
    obj.query ? data.query = obj.query : '';
    obj.options ? data.options = obj.options : '';
    obj.source ? data.source = obj.source : '';
    obj.province ? data.province = obj.province.toString() : '';
    obj.year ? data.years = obj.year.toString() : '';
    (obj.categoryId && getCategoryId(obj.categoryId)) ? data.categoryId = obj.categoryId[obj.categoryId.length-1] : '';
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return dispatch => {
        dispatch(commonSetStatus('loading', true));
        apiRequest(url, config).then(json => {
            if (json.status == 1) {
                dispatch(commonSetStatus('institutionQuestionObj', json.obj));
                dispatch(commonSetStatus('loading', false));
            }
        }).catch(error => console.error(error))
    }
}