import apiRequest from '../../common/request';
import { message } from 'antd';
import Cookies from 'js-cookie';

const PREFIX = "DATA_ORDER";

export const SET_REDUX_KEY_VALUE = PREFIX + "SET_REDUX_KEY_VALUE";
export const RESET_FILTER_VALUES = PREFIX + "RESET_FILTER_VALUES";
export const SET_REDUX_INPUT_WECHAT = PREFIX + "SET_REDUX_INPUT_WECHAT";


export const resetFilterValue = () => ({ type: RESET_FILTER_VALUES });

export const setReduxInputWeChat = data => {
    let wechatArr = [];
    if (data.value) {
        let oldVlaue = data.value.replace(/，/ig,',');
        wechatArr = oldVlaue.split(",");
    }
    return{
        type: SET_REDUX_INPUT_WECHAT,
        key: data.key,
        value: wechatArr,
    }
}

export const setReduxKeyValue = data => ({
    type: SET_REDUX_KEY_VALUE,
    key: data.key,
    value: data.value
});

/** 下载Excel */
export const downloadExcel = data => {
    let url = '/gk/ops/wechat/exportOrder';
    let config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Sin-Access-Token': Cookies.get('accessToken'),
        'Sin-UserAgent-Sign': 'SintureGK-WEB',
        'Sin-Client-Type': 3,
        Accept: '*/*',
      },
      body: JSON.stringify(data),
      credentials: 'include'
    };
    return dispatch => {
        fetch(url, config).then(response => {
            response.blob().then(blob => {
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                let fileName = '微信免费资料.xls';
                a.download = fileName;
                document.body.appendChild(a).click();
                window.URL.revokeObjectURL(url);
            })
        }).catch(err => {
            message.error('下载出错');
        })
    }
}

export const  wecharInformation = data => {
    let url = `/gk/ops/wechat/page`;
    let config = { 
        method: 'POST', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        dispatch(setReduxKeyValue({ key: 'loadingList', value: true }));
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            json.obj.content.length ? '' : message.info('没有数据，请重新查询');
            dispatch(setReduxKeyValue({ key: 'productOrdersVO', value: json.obj }));
            dispatch(setReduxKeyValue({ key: 'loadingList', value: false }));
        }
    }
}