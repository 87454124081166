import React from 'react';
import { connect } from 'react-redux';
import { fetchOrderDetail, postWuliuData } from './actions';
import './index.scss';
import { checkPermission, getSearchQuery } from '../../utils/utils';

import { Modal, Breadcrumb, Alert, Card, Button } from 'antd';

class OrderDetailShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'onOrderDetail': true,//默认处于订单详情状态而非物流状态详情
            'show': false,//控制模态
            'wuliuCompany': '',//填入的物流公司
            'wuliuNumber': ''//填入的物流单号
        };
    }

    componentDidMount() {
        this.props.fetchDetail(getSearchQuery('orderNumber'));
    }

    close = () => (this.setState({ 'show': false, 'wuliuCompany': '', 'wuliuNumber': '' }))
    open = () => (this.setState({ 'show': true }))

    /** todos：用户提交的信息需要得到一个实施反馈 */
    postWuliu = () => {
        let wrapFun = (num) => {
            return () => {
                this.props.fetchDetail(num);
            }
        };
        this.close();
        var obj = {
            //修改需要带id,但是不用订单号
            id: this.props.detail.orderLogistics.id,
            logisticsCompany: this.state.wuliuCompany,
            logisticsNo: this.state.wuliuNumber,
            //outTradeNo: this.props.detail.outTradeNo
        }
        this.props.postData(obj, wrapFun(getSearchQuery('orderNumber')));
        //this.props.fetchDetail(getSearchQuery('orderNumber'));
    }

    handleWuliuCompany = (event) => {
        this.setState({ 'wuliuCompany': event.target.value });
    }

    handleWuliuNumber = (event) => {
        this.setState({ 'wuliuNumber': event.target.value });
    }

    render() {
        var content = '';
        var bssf, bf, bsss;
        if (this.props.detail.status) {
            content = "交易成功";
            bsss = true;
            bf = false;
            bssf = false;
        } else {
            content = '交易失败 未付款'
            bf = true;
            bsss = false;
            bssf = false;
        }
        return (
            <div className="orderBox">
                <Breadcrumb>
                    <Breadcrumb.Item to='/order'>订单管理</Breadcrumb.Item>
                    <Breadcrumb.Item>订单详情</Breadcrumb.Item>
                </Breadcrumb>

                <div>
                    <Alert style={{ fontSize: '2em' }} showIcon message={content} type={bsss && "success" || "error"} />

                    <div className="userinfo">
                        <h2>买家信息：</h2>
                        <div>
                            <div >收货地址：{this.props.detail.userAddress}</div>
                            <div >购买账号：{this.props.detail.userMobile}</div>
                        </div>
                    </div>
                    <div className="contentB">
                        <Card style={{ width: '48%' }} title="订单详情">
                            <div >商品名称：{this.props.detail.goodsName}</div>
                            <div >当前状态：{content}</div>
                            <div >订单编号：{this.props.detail.outTradeNo}</div>
                            <div >付款时间：{this.props.detail.finishTime}</div>
                            <div >
                                付款平台：
                                        {
                                    this.props.detail.platform === 'IAP' ? '苹果支付' :
                                        this.props.detail.platform === 'alipay' ? '支付宝' :
                                            this.props.detail.platform === 'wxpay' ? '微信' : '未知平台'
                                }
                            </div>
                            <div >
                                支付方式：
                                        {
                                    this.props.detail.payMethod === 'NATIVE' ? '扫码支付' :
                                        this.props.detail.payMethod === 'JSAPI' ? 'H5支付' :
                                            this.props.detail.payMethod === 'WAP' ? '手机网页' :
                                                this.props.detail.payMethod === 'APP' ? 'APP支付' :
                                                    this.props.detail.payMethod === 'MWEB' ? 'H5支付' :
                                                        this.props.detail.payMethod
                                }
                            </div>
                            <div >商品金额：¥{this.props.detail.amount && this.props.detail.amount / 100}</div>
                            <div >单价：¥{this.props.detail.amount && this.props.detail.amount / 100}</div>
                            <div >收入：¥{this.props.detail.amount && this.props.detail.amount / 100}</div>
                        </Card>

                        <Card style={{ width: '48%' }} title="物流信息">
                            <div >物流单号：{this.props.detail.orderLogistics && this.props.detail.orderLogistics.logisticsNo}</div>
                            <div >快递公司：{this.props.detail.orderLogistics && this.props.detail.orderLogistics.logisticsCompany}</div>
                            <div >发货时间：{this.props.detail.orderLogistics && this.props.detail.orderLogistics.createTime}</div>
                            {checkPermission('sys:order:logistics') && <Button type="link" onClick={this.open}>修改</Button>}
                        </Card>
                    </div>
                </div>

                <Modal className="centerModal" visible={this.state.show} onCancel={this.close} title="修改物流单号" onOk={this.postWuliu} >
                    <div className="gk-modal-body">
                        <div>核对买家信息</div>
                        <div>姓名：{this.props.detail.userName}</div>
                        <div>联系方式：{this.props.detail.userMobile}</div>
                        <div>收件地址：{this.props.detail.userAddress}</div>
                        <div>单号录入</div>
                        <div>
                            <span>物流公司</span>
                            <input type="text" value={this.state.wuliuCompany} onChange={this.handleWuliuCompany} />
                        </div>
                        <div>
                            <span>物流单号</span>
                            <input type="text" value={this.state.wuliuNumber} onChange={this.handleWuliuNumber} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (storeState, ownProps) => ({
    detail: storeState.orderDetailReducer || {}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchDetail: (orderNumber) => {
        dispatch(fetchOrderDetail(orderNumber));
    },
    postData: (data, cb) => {
        dispatch(postWuliuData(data, cb));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailShow)
