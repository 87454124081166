import {
    MODIFY_BATCH_CLASS_REDUX,
    CLEAR_BATCH_CLASS_STATUS,
    CLEAR_BATCH_CLASS_SEARCH
} from './action';
import { SET_STUDENT_CLASS } from '../Inquiry/action';
import { SET_BATCH_LIST } from '../Batch/action';
import { SEARCH_USER_INFORM } from '../AddOrModify/action';

const initSearch = {
    page: 1,
    pageSize: 10, 
    userInfoIds: [],                        // 当前用户Id
    userNames: [],                          // 当前搜索客户所返回的
    salesName: '全部',                       // 默认销售
    examArea: '全部',                        // 默认地区 
};

const initStatus = {
    selectArr: [],                                      // 上面已选表格的数据
    userArr: [],                                        // id集合数组
    classes: [],                                        // 搜索班级集合
    classIdx: null,                                     // 班级索引
    className: undefined,                               // 班级名字
    classId: '',                                        // 班级id
    teacher: '',                                        // 班主任
    startDate: '',                                      //开班日期
};

const initialState = {
    content: [],
    totalPages: 1,
    totalElements: 1,
    ...initSearch,
    ...initStatus,
};

export const batchClassReducer = (previousState=initialState, action) => {
    switch(action.type) {
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case MODIFY_BATCH_CLASS_REDUX:
            return { ...previousState, [ action.key ]: action.value };
        case SET_BATCH_LIST:
            return Object.assign({}, previousState, {
                content: action.content,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
            });
        case SEARCH_USER_INFORM:
            return { ...previousState, userNames: action.value };
        case CLEAR_BATCH_CLASS_STATUS:
            return { ...previousState, ...initStatus };
        case CLEAR_BATCH_CLASS_SEARCH:
            return { ...previousState, ...initSearch };
        default:
            return previousState;
    }
}