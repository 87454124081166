import {
    COMMON_CHANGE, RESET
} from './actions';

/** 这是筛选状态 */
const searchParams = {
    page: 1,
    pageSize: 10,
    loading: false,
    title: '',              //试卷名称
    examineType: '',        //搜索记录类型
    isFinish: '',           //搜索是否批改
    startDate: '',          //搜索开始时间
    endDate: '',            //搜索截止时间
    exerciseList: [],       //搜索练习列表
    studentList: [],        //搜索学员列表
    employeeList: [],       //搜索分配老师列表
    examineIds: [],         //搜索练习id
    userIds: [],            //搜索学员id
    teacherIds: [],         //搜索分配老师id
    teacherId: '',          //已选老师
    classList: [],          //班级列表
    classIds: [],            //已选班级id
};

const initialState = {
    ...searchParams,
    examType: 1,
    items: {
        content: [],
        totalElements: 1,
    },                                           // 订单列表数据
    loading: false,                          // 是否处于加载状态
    ids: [],   //已选学员
};

export const doList = (prevState = initialState, action) => {
    switch (action.type) {
        case COMMON_CHANGE:
            return { ...prevState , [action.key]: action.val };
        case RESET:
            return { ...prevState, ...searchParams };
        default:
            return prevState;
    }
}