import React, { Component } from 'react'
import { connect } from "react-redux";
import { Popover, Popconfirm, Spin, Modal, Tag, Input, Select, Button, Tooltip, message, Table, Breadcrumb, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { resetFilterStatus, changeCommon, fetchQuestions, fetchQuestionAreas, fetchQuestionTypes } from "./actions";
import { checkPermission } from '../../utils/utils';
import apiRequest from '../../common/request';

import moment from 'moment';
import dayjs from 'dayjs';
const disabledDate = (current) => {
    return current && current >= dayjs().endOf('day');
};

const { Option } = Select;
class PositionList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            curQuestionIndex: 0,
            p: 1,                   // word下载当前页数
            downLoading: false,     // word下载加载状态
            totalPages: 0,          // word下载总页数
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchQuestionTypes());
        this.sendPostPagerequest();
    }

    sendPostPagerequest = async () => {
        let data = {
            age: this.props.age,
            id: this.props.categoryId,
            page: this.props.page,
            pageSize: this.props.pageSize,
            qdesc: this.props.qdesc,
            areas: this.props.areas,
            educations: this.props.educations,
            types: this.props.types,
            year: this.props.year,
            province: this.props.province,
            classify: this.props.classify,
            deptName: this.props.deptName,
            positionCode: this.props.positionCode,
            positionName: this.props.positionName,
        };
        await this.props.dispatch(fetchQuestions(data));
    }

    /** 清空搜索条件 */
    resetInput = async () => {
        await this.props.dispatch(resetFilterStatus());
        this.sendPostPagerequest();
    }

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '编号',
                width: '5%',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '职位名称',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.positionName)}>
                        {checkPermission('sys:question:gksee') && <Link to={`/position/${record.id}`} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.positionName }}></Link>
                        || <div className="ellipsis2" dangerouslySetInnerHTML={{__html: record.positionName}}></div>}
                    </Tooltip>
                ),
            },
            {
                title: '工作单位',
                width: '20%',
                dataIndex: 'deptName',
                key: 'deptName'
            },
            {
                title: '报考地区',
                width: '5%',
                dataIndex: 'area',
                key: 'area'
            },
            {
                title: '报考类型',
                width: '10%',
                dataIndex: 'type',
                key: 'type'
            },
            {
                title: '年份',
                width: '5%',
                dataIndex: 'year',
                key: 'year'
            },
            {
                title: '职位代码',
                width: '5%',
                dataIndex: 'positionCode',
                key: 'positionCode',
            },
            {
                title: '操作',
                width: '5%',
                render: (record, text) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('sys:menu:update') && <Button onClick={() => this.props.history.push(`/position/${record.id}`)} size="small" type="primary">修改</Button>}
                        </div>
                    )
                }
            },
        ];
        return columns;
    }

    commChange = async (val, key) => {
        await this.props.dispatch(changeCommon({ key, val }));
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        console.log(val, key)
        await this.sendPostPagerequest();
    }
    onChangePage = async (current, pageSize) => {
        await this.props.dispatch(changeCommon({ key: 'page', val: current }));
        await this.props.dispatch(changeCommon({ key: 'pageSize', val: pageSize }));
        await this.sendPostPagerequest();
    }

    onPressEnter = async () => {
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        this.sendPostPagerequest();
    }

    tableTitle = () => (
        <div className="commonTableTitle">
            <div>职位列表</div>
            <div>
                {checkPermission('sys:question:edit') &&<Button type="primary" disabled={this.props.isFetching} onClick={() => this.props.history.push('/publishPosition')} style={{marginRight: '10px'}}>添加职位</Button>}
            </div>
        </div>
    )

    render() {
        console.log(this.props)
        const columns = this.generateTableColumns();
        let { deptName, year, province, classify, positionName, positionCode, questionTag, positionList, areas, types, years, categories, isFetching } = { ...this.props };
        let { p, downLoading, totalPages } = this.state;
        const curQuestion = positionList.length > 0 ? positionList[this.state.curQuestionIndex] : {};

        return (
            
            <Spin tip={totalPages && `已完成${p}套, 共${totalPages}套` || '加载中...'} spinning={downLoading}>
                <Breadcrumb>
                    <Breadcrumb.Item>职位管理</Breadcrumb.Item>
                    <Breadcrumb.Item>职位列表</Breadcrumb.Item>
                </Breadcrumb>

                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>年份</label>
                        <DatePicker
                                value={year ? moment(year, 'YYYY') : ''}
                                picker="year"
                                style={{ width: 300 }}
                                onChange={(date, dateString) => { this.commChange(dateString, 'year'); }}
                            />
                    </div>
                    <div className="itemSearchBox">
                        <label>工作单位</label>
                        <Input value={deptName} onChange={e => this.props.dispatch(changeCommon({ key: 'deptName', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 300 }} placeholder="请输入工作单位" />
                    </div>
                    <div className="itemSearchBox">
                        <label>报考地区</label>
                        <Select style={{ width: 300 }} placeholder="请选择报考地区" value={province} mode="multiple" onChange={value => { this.commChange(value, 'province') }}>
                            {areas && areas.map((item, idx) => {
                                return <Option key={idx} value={item + ''}>{item}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>报考类型</label>
                        <Select style={{ width: 300 }} placeholder="请选择报考类型" value={classify} mode="multiple" onChange={value => { this.commChange(value, 'classify') }}>
                            {types && types.map((item, idx) => {
                                return <Option key={idx} value={item + ''}>{item}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>职位名称</label>
                        <Input value={positionName} onChange={e => this.props.dispatch(changeCommon({ key: 'positionName', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 300 }} placeholder="请输入职位名称" />
                    </div>
                    <div className="itemSearchBox">
                        <label>职位代码</label>
                        <Input value={positionCode} onChange={e => this.props.dispatch(changeCommon({ key: 'positionCode', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 300 }} placeholder="请输入职位代码" />
                    </div>
                    <div className="itemSearchBox">
                        <Button type="primary" disabled={isFetching} onClick={() => this.sendPostPagerequest()}>搜索</Button>
                    </div>
                    <div className="itemSearchBox">
                        <Button danger disabled={isFetching} onClick={() => this.resetInput()}>重置</Button>
                    </div>
                </div>

                <Table
                    columns={columns}
                    dataSource={this.props.positionList}
                    rowKey={record => record.id}
                    title={() => this.tableTitle()}
                    loading={this.props.isFetching}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        onChange: this.onChangePage,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </Spin>
        )
    }
}

function mapStatetoProps(state) {
    const {
        deptName,   // 单位名称
        year,   //已选年份
        province,      //省份
        classify,
        positionName,  //职位名称
        positionCode,         //职位代码
        categoryId,
        items: positionList,
        totalPages,
        totalElements,
        numberOfElements,
        areas,
        types,
        years,
        page,
        pageSize,
        isFetching
    } = state.positionList;

    const {
        items: categories
    } = state.questionCategories;

    const { tags } = state.essayCategory;

    return {
        deptName,   // 单位名称
        year,               //已选年份
        province,           //省份
        classify,
        positionName,         //职位名称
        positionCode,            //职位代码
        categoryId,
        categories,
        tags,
        page,
        isFetching,
        positionList,
        areas,
        types,
        years,
        totalPages,
        totalElements,
        numberOfElements,
        pageSize,
    };
}

export default connect(mapStatetoProps)(PositionList);