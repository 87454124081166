import {
    RECEIVE_STUDENT_LIST,
    SET_FILTER_STATUS
} from './action'

const initialState = {
    responseObj: {} ,                           //接口获取的数据
    filterStatus: 'ALL',                        // 筛选所有，三个可选值，分别是DONE, ALL, NOT
};

export function  interviewCandidateList(state=initialState, action) {
    switch (action.type) {
        case RECEIVE_STUDENT_LIST:
            return Object.assign({}, state, { responseObj: Object.assign({}, state.responseObj, action.data) });
        case SET_FILTER_STATUS:
            return Object.assign({}, state, { filterStatus: action.data });
        default:
            return state;
    }
}