import {
    LOADING_STUDENT_DETAIL,
    SET_STUDENT_DETAIL,
    SET_DAY_NUMBER,
    SET_ORIGINAL_STUDENT_DETAIL,
    CHANGE_FILTER_STATUS
} from './action';

const initialState = {
    loadingDetail: false,
    studentDetail: { courseNums: [] },
    dayNumberArr: [],
    filterStatus: 'all',                // 筛选所有，三个可选值，分别是DONE, ALL, NOT
    originalStudentDetail: { courseNums: [] }
};

const studentDetail = (previousState=initialState, action) => {
    switch(action.type) {
        case LOADING_STUDENT_DETAIL:
            return { ...previousState, loadingDetail: true };
        case SET_STUDENT_DETAIL:
            return { ...previousState, loadingDetail: false, studentDetail: action.data }
        case SET_DAY_NUMBER:
            return { ...previousState, dayNumberArr: action.data };
        case SET_ORIGINAL_STUDENT_DETAIL:
            return { ...previousState, originalStudentDetail: action.data };
        case CHANGE_FILTER_STATUS:
            return { ...previousState, filterStatus: action.value }
        default:
            return previousState;
    }
}

export { studentDetail };