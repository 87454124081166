import {
    REQUEST_APP_ID,
} from './actions';

const initialState = {
    appId: ''
};

export function infoPushRelease(state = initialState, action) {
    switch (action.type) {
        case REQUEST_APP_ID:
            return Object.assign({}, state, {appId: ''});
        default:
            return state;
    }
}