import React from 'react';
import { connect } from "react-redux";
import { getRoleTree, getRoleUser, addRoleUser } from "./action";
import './style.scss';
import { Tree, Table, Button, Modal, Input, Select, TreeSelect, message } from 'antd';

import {checkPermission} from '../../utils/utils';

// const TreeNode = Tree.TreeNode;
const { Option } = Select;

class Role extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reset: false,//是否可以重置
            currentPage: 1,//当前页
            pageSize: 10,//当前pageSize
            searchName: '',// 搜索用户名称
            searchDepartment: '',// 搜索部门
            name: '',// 新增或修改的用户名称
            mobile: '',// 新增或修改的用户手机
            department: '',// 新增或修改的部门
            parentId: '',// 新增或修改的上级领导
            id: '',// 修改的用户id
            visible: false//蒙板开关
        };
    }

    /** 部门类别 */
    department = ['综合部', '师资堂', '运营部', '销售部', '全程部', '技术部'];

    componentDidMount() {
        let permission = 2;
        this.setState({ permission });
        this.props.dispatch(getRoleTree());
        this.props.dispatch(getRoleUser());
    }

    /** 新增用户 */
    addUser = () => {
        this.setState({
            visible: true,
            id: '',
            parentId: '',
            name: '',
            mobile: '',
            department: ''
        });
    }

    /** 点击确定，发送新增或者修改请求 */
    handleOk = async () => {
        if (!this.state.name) {
            return message.warning('请输入用户名');
        }
        if (!this.state.parentId) {
            return message.warning('请选择上级领导');
        }
        if (!this.state.mobile) {
            return message.warning('请输入手机号');
        }
        if (!this.state.department) {
            return message.warning('请选择归属部门');
        }
        if (!(/^1[3456789]\d{9}$/.test(this.state.mobile))) {
            return message.warning('手机号格式有误');
        }
        let data = {
            parentId: this.state.parentId,
            name: this.state.name,
            mobile: this.state.mobile,
            department: this.state.department,
            id: this.state.id,
        }
        await addRoleUser(data);
        await this.props.dispatch(getRoleTree());
        await this.props.dispatch(getRoleUser());
        this.setState({
            visible: false,
            currentPage: 1
        });
    };

    /** 取消，关闭蒙版 */
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    /** input事件 */
    classInputHandler = (value, key) => {
        this.setState({ [key]: value, reset: true });
    }

    /** 新增用户选择上级领导 */
    onChangeTree = (value, label, extra) => {
        this.setState({ parentId: value });
        this.getDepartment(value, this.props.treeObj);
    }

    /** 选择领导后，联动绑定部门 */
    getDepartment = (id, treeObj) => {
        treeObj.map(item => {
            if (id === item.id) {
                this.setState({ department: item.department });
                return;
            }
            this.getDepartment(id, item.children);
        })
    }

    /** 新增用户选择上级领导 */
    onChangeSelect = (value) => {
        this.setState({ department: value });
    }

    /** 查询用户选择部门 */
    onChangeSearchSelect = async (value) => {
        await this.setState({ searchDepartment: value });
        await this.searchHandle();
    }

    /** 输入框回车 */
    onPressEnter = () => {
        this.searchHandle();
    }

    /** 点击树结构查看员工个人信息 */
    onSelect = (selectedKeys, e) => {
        this.setState({ reset: true }) //重置功能可以打开
        let pos = e.node.props.pos;
        if (pos === '0-0') return;
        let data = {
            id: selectedKeys[0]
        }
        this.props.dispatch(getRoleUser(data));
    }

    /** 查询员工信息非空才能查询 */
    search = () => {
        if (!this.state.searchName && !this.state.searchDepartment) return;
        this.searchHandle();
    }

    /** 查询员工信息 */
    searchHandle = () => {
        this.setState({ currentPage: 1 });
        let data = {
            name: this.state.searchName,
            department: this.state.searchDepartment
        }
        this.props.dispatch(getRoleUser(data));
    }

    /** 清空查询员工信息，刷新数据 */
    reset = () => {
        if (!this.state.reset && !this.state.searchName && !this.state.searchDepartment) return;
        this.setState({
            currentPage: 1,
            reset: false,
            searchName: '',
            searchDepartment: ''
        })
        this.props.dispatch(getRoleUser());
    }

    /** 修改员工信息，数据回显 */
    update = (record) => {
        this.setState({
            visible: true,
            name: record.name,
            mobile: record.mobile,
            department: record.department,
            parentId: record.parentId,
            id: record.id,
        });
    }

    /** page改变 */
    pageChange = (page, pageSize) => {
        this.setState({ currentPage: page, reset: true });
        let data = {
            page: page,
            pageSize: this.state.pageSize,
            name: this.state.searchName,
            department: this.state.searchDepartment
        }
        this.props.dispatch(getRoleUser(data));
    }

    /** pageSize改变 */
    onShowSizeChange = (current, size) => {
        this.setState({ currentPage: 1, pageSize: size });
        let data = {
            pageSize: size,
            name: this.state.searchName,
            department: this.state.searchDepartment
        }
        this.props.dispatch(getRoleUser(data));
    }

    renderTitle = () => (
        <div className="commonTableTitle">
            <span>员工列表</span>
            {checkPermission('sys:emp:edit') && 
            <Button type="primary" onClick={this.addUser}>
                新增
            </Button>}
        </div>
    )

    render() {
        const { treeObj, roleList, tableLoading } = this.props;
        const reObj = data => {
            return data.map(item => {
                delete item.children
                return item
            })
        }

        // const loop = data => {
        //     return data.map(item => {
        //         if (item.children && item.children.length) {
        //             return <TreeNode key={item.id} title={item.department + '-' + item.name}>{loop(item.children)}</TreeNode>
        //         }
        //         return <TreeNode key={item.id} title={item.department + '-' + item.name} />
        //     })
        // }

        const options = (data) => {
            return data.map((item, idx) => {
                if (item.children && item.children.length) {
                    options(item.children)
                }
                item.key = item.id;
                item.title = item.name;
            })
        }

        treeObj && options(treeObj);//打造符合树结构的数据结构
        roleList.content && reObj(roleList.content);

        const columns = [
            {
                title: '序号',
                render: (text, record, idx) => (
                    <span>{idx + 1}</span>
                ),
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '部门',
                dataIndex: 'department',
                key: 'department',
            },
            {
                title: '手机',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            },
            {
                title: '操作',
                render: (record) => (
                    <div>
                        {
                            checkPermission('sys:emp:edit') && record.parentId !== 0 &&
                            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => this.update(record)}>修改</span>
                        }
                    </div>
                ),
            },
        ]

        return (
            <div className="roleWrap">
                <div className='role'>
                    <div className='listRole'>
                        <h5>教育组织架构</h5>
                        {treeObj && treeObj.length &&
                            <Tree
                                treeData={treeObj}
                                onSelect={this.onSelect}
                                defaultExpandAll
                            />

                            || <h6>暂无结构</h6>
                        }
                    </div>
                    <div className='tableList'>
                        {checkPermission('sys:emp:page') && <div className="commonSearchBox">
                            <div className="itemSearchBox">
                                <label>用户名称</label>
                                <Input
                                    style={{ width: 150 }}
                                    value={this.state.searchName}
                                    onPressEnter={this.onPressEnter}
                                    onChange={event => { this.classInputHandler(event.target.value, 'searchName') }}
                                />
                            </div>
                            <div className="itemSearchBox">
                                <label>归属部门</label>
                                <Select value={this.state.searchDepartment} style={{ width: 150 }} onChange={this.onChangeSearchSelect}>
                                    <Option value=''>全部</Option>
                                    {this.department.map((item, idx) => <Option key={idx} value={item}>{item}</Option>)}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <Button disabled={tableLoading} type="primary" onClick={this.search}>
                                    查询
                                </Button>
                            </div>
                            <div className="itemSearchBox">
                                <Button disabled={tableLoading} danger onClick={this.reset}>
                                    重置
                                </Button>
                            </div>
                            <div className="itemSearchBox">
                                
                                
                            </div>
                        </div>}

                        <Table
                            loading={tableLoading}
                            title={()=>this.renderTitle()}
                            dataSource={roleList.content}
                            rowKey={record => record.id}
                            columns={columns}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onShowSizeChange: this.onShowSizeChange,
                                current: this.state.currentPage,
                                pageSize: this.state.pageSize,
                                total: roleList.totalElements,
                                onChange: this.pageChange,
                                showQuickJumper: true,
                                showTotal: total => `共有${total}条数据`,
                            }}
                        />
                    </div>
                </div>
                <Modal
                    title="新增/修改-员工"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className="commonSearchBox">
                        <div className="itemSearchBox">
                            <label>用户名称</label>
                            <Input
                                style={{ width: 150 }}
                                value={this.state.name}
                                onChange={event => { this.classInputHandler(event.target.value, 'name') }}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>上级领导</label>
                            <TreeSelect
                                value={this.state.parentId}
                                style={{ width: 150 }}
                                placeholder="Please select"
                                onChange={this.onChangeTree}
                                treeData={treeObj}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>手机号码</label>
                            <Input
                                style={{ width: 150 }}
                                value={this.state.mobile}
                                onChange={event => { this.classInputHandler(event.target.value, 'mobile') }}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>归属部门</label>
                            <Select style={{ width: 150 }} value={this.state.department} onChange={this.onChangeSelect}>
                                {this.department.map((item, idx) => <Option key={idx} value={item}>{item}</Option>)}
                            </Select>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        tableLoading,
        treeObj,
        roleList
    } = state.roleTree;

    return {
        tableLoading,
        roleList,
        treeObj
    };
}

export default connect(mapStatetoProps)(Role);