import React from "react";
import { connect } from "react-redux";
import {
    fill_examine_title,
    fetchExamine,
    updateExamine,
    fill_examine_limit_time,
    fill_examine_up_time,
    fill_examine_qArr,
    fill_examine_questions
} from "./actions";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import moment from 'moment';
import { Input, message, Card, Button, DatePicker } from "antd";
import { checkPermission } from '../../utils/utils';

class DailyExamineInfo extends React.Component {

    componentWillMount() {
        this.props.dispatch(fetchExamine(this.props.match.params.dailyExamineId));
    }

    modifyExamine = () => {
        if (this.props.info.title.trim() === "") {
            message.warn("请输入标题");
            return;
        }
        if (this.props.info.limitTime < 1) {
            message.warn("请输入练习时长");
            return;
        }
        if (this.props.info.publishDate == null || typeof (this.props.info.publishDate) == "undefined") {
            message.warn("请输入上线日期");
            return;
        }
        if (!this.props.info.quesArr.length) {
            message.warn("请至少选择一个问题");
            return;
        }
        const cb = () => this.props.history.push('/dailyExamines');
        this.props.dispatch(updateExamine(this.props.info, cb));
    };

    updateQuesArr = (arr) => {
        this.props.dispatch(fill_examine_qArr(arr))
    };

    updateSelQues = (arr) => {
        this.props.dispatch(fill_examine_questions(arr));
    };

    selectUpTime = (val) => {
        this.props.dispatch(fill_examine_up_time(val));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    render() {
        const { info } = this.props;
        let quesArr = info.quesArr;
        let exerciseType = 0;
        let selQuesList = info.questions;
        return (
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <h4 style={{ margin: "0 0 10px" }}>每日一练详情</h4>
                    <div className="commonSearchBox">
                        <div>
                            <label>练习标题</label>
                            <Input className="commonW400" value={info.title} onChange={e => this.props.dispatch(fill_examine_title(e.target.value))} />
                        </div>
                        <div>
                            <label>练习时长(秒)</label>
                            <Input className="commonW150" value={info.limitTime} onChange={e => this.props.dispatch(fill_examine_limit_time(e.target.value))}/>
                        </div>
                        <div>
                            <label>上线日期</label>
                            <DatePicker className="commonW150" value={this.calendarValuePipe(info.publishDate)} format="YYYY-MM-DD" onChange={(date, dateString) => {this.selectUpTime(dateString)}}/>
                        </div>
                    </div>
                    <QuestionSelectCard
                        quesArr={quesArr}
                        selQuesList={selQuesList || []}
                        updateQuesArr={this.updateQuesArr}
                        updateSelQues={this.updateSelQues}
                        exerciseType={exerciseType}
                    />
                </div>
                <div className="commonsubmit">
                    {checkPermission('sys:dailyExercise:edit') && <Card title="更新" size="small" className='cardR'>
                        <Button type="primary" onClick={this.modifyExamine} disabled={this.props.isSend}>更新</Button>
                    </Card>}
                </div>
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        isFetchingExamine,
        isFetchingQuestions,
        isUpdating,
        isUpdated,
        info
    } = state.dailyExamineInfo;

    return {
        isFetchingExamine,
        isFetchingQuestions,
        isUpdating,
        isUpdated,
        info,
    };
}

export default connect(mapStatetoProps)(DailyExamineInfo);
