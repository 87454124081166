import {message} from 'antd';
import apiRequest from '../../common/request'
const PREFIX = "COURSE_LIST";
export const REQUEST_COURSES = PREFIX + "REQUEST_COURSES";
export const RECEIVE_COURSES = PREFIX + "RECEIVE_COURSES";
export const REQUEST_DELETE = PREFIX + "REQUEST_DELETE";
export const RECEIVE_DELETE = PREFIX + "RECEIVE_DELETE";
export const REQUEST_RECOMMEND = PREFIX + "REQUEST_RECOMMEND";
export const RECEIVE_RECOMMEND = PREFIX + "RECEIVE_RECOMMEND";
export const SETTING_CLASSLIST_ARR = PREFIX + "SETTING_CLASSLIST_ARR";
export const SETTING_CURRENT_PAGE = PREFIX + "SETTING_CURRENT_PAGE";
export const SETTING_PAGE_SIZE = PREFIX + "SETTING_PAGE_SIZE";
export const RECEIVE_COURSE_LABELS = PREFIX + "RECEIVE_COURSE_LABELS";
export const RECEIVE_COURSE_CATEGORIES = PREFIX + "RECEIVE_COURSE_CATEGORIES";
export const RECEIVE_COURSE_TEACHERS = PREFIX + "RECEIVE_COURSE_TEACHERS";


function request_courses() {
    return {
        type: REQUEST_COURSES
    }
}

function receive_courses(data) {
    return {
        type: RECEIVE_COURSES,
        list: data.content,
        totalElements: data.totalElements,
    }
}

function request_delete() {
    return {
        type: REQUEST_DELETE
    }
}

function receive_delete(id) {
    return {
        type: RECEIVE_DELETE,
        id
    }
}

function request_recommend() {
    return {
        type: REQUEST_RECOMMEND
    }
}

function receive_recommend(id) {
    return {
        type: RECEIVE_RECOMMEND,
        id
    }
}

export const setCurrentPage = page => ({
    type: SETTING_CURRENT_PAGE,
    data: page
});

export const setPageSize = pageSize => ({
    type: SETTING_PAGE_SIZE,
    data: pageSize
});

/** 更新classList数组 */
export function setClassListArr(newArr) {
    return {
        type: SETTING_CLASSLIST_ARR,
        data: newArr
    };
}

function receive_course_categories(categories){
    return {
        type: RECEIVE_COURSE_CATEGORIES,
        categories
    }
}

function receive_course_teachers(teachers){
    return {
        type: RECEIVE_COURSE_TEACHERS,
        teachers
    }
}

function receive_course_labels(labels){
    return {
        type: RECEIVE_COURSE_LABELS,
        labels
    }
}


export function fetchCourseTeachers(){
    return dispatch => {
      return apiRequest('/gk/ops/course/teacher/list', {method: 'POST',credentials: 'include'})
        .then(json => {
          dispatch(receive_course_teachers(json.obj));
        })
        .catch(error => console.log(error));
    }
}

export function fetchCourseLabels(){
    return dispatch => {
      return apiRequest('/gk/ops/course/label/list', {method: 'POST',credentials: 'include'})
        .then(json => {
          dispatch(receive_course_labels(json.obj));
        })
        .catch(error => console.log(error));
    }
}

// 其实没有必要进行更新，因为对于是否上下架属性来说后台返回的数据顺序是没有影响的
export function publishCourse(id, params) {
    return dispatch => {
      return apiRequest(`/gk/ops/course/publish/${id}`, {credentials: 'include'})
        .then(json => {
          message.success("更新成功")
          dispatch(fetchCourses(params));
        })
        .catch(error => console.log(error));
    }
}

/** 删除成功 */
export function delCourse(id) {
    return dispatch => {
      return apiRequest(`/gk/ops/course/del/${id}`)
        .then(json => {
            if(json.status==1) {
                message.success('删除课程成功');
                dispatch(receive_delete(id));
              }
        })
        .catch(error => console.log(error));
    }
}

export function fetchCourses(params) {
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10,
    }
    params.categoryId ? data.categoryId = params.categoryId : '';
    params.title && params.title.trim().length ? data.title = params.title : '';
    params.label ? data.label = params.label : '';
    params.maxPrice ? data.maxPrice = params.maxPrice : '';
    params.minPrice ? data.minPrice = params.minPrice : '';
    params.publish!=="" ? data.publish = params.publish : '';
    params.recommend!=="" ? data.recommend = params.recommend : '';
    params.teacher ? data.teacher = params.teacher : '';
    params.type!=="" ? data.type = params.type : '';
    params.headTeacher && params.headTeacher !== '全部' && (data.headTeacher = params.headTeacher);

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_courses());
        return apiRequest("/gk/ops/course/page", config)
          .then(json => {
            json.obj.content = json.obj.content.filter(obj => obj.title != '作废');
            dispatch(receive_courses(json.obj))
          })
          .catch(error => console.log(error));
    }
}

export function setRecommend(id, cb) {
    return dispatch => {
        dispatch(request_recommend());
        return apiRequest(`/gk/ops/course/recommend/${id}`, {credentials: 'include', method: 'POST'})
          .then(json => {
            message.success('操作成功');
              // LOG: 2019-0227 成功改变推荐状态不在修改redux对应课程的推荐属性，而是重新获取数据
              cb && cb();
              // dispatch(receive_recommend(id));
          })
          .catch(error => console.log(error));
    }
}
