import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Popconfirm, Table, Input, Button, Tag, message } from 'antd';

import { fetchCommonSenses, deleteCommonSense, delTag, newTag, fetchTagList, setActivePage, setPageSize } from './actions';

import { checkPermission } from '../../utils/utils';


import './index.scss';

class CommonSenseList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tag: '',
            showModal: false,
            tagModal: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchCommonSenses(this.props.activePage, this.props.pageSize));
        this.props.dispatch(fetchTagList());
        let permission = 2 || 1 ;//checkPermission(this.props.route.meta);
        this.setState({ permission });
    }

    handleConfirm = () => {
        this.setState({ showModal: false });
        this.props.dispatch(deleteCommonSense(this.state.deleteId, this.state.activePage));
    };

    handleTagConfirm = () => {
        this.setState({ tagModal: false });
        this.props.dispatch(delTag(this.state.delTagId));
    };


    mdeleteCS = id => {
        this.setState({ showModal: true, deleteId: id });
    };


    handleSelect = eventKey => {
        this.props.dispatch(setActivePage(eventKey));
        this.props.dispatch(fetchCommonSenses(eventKey, this.props.pageSize));
    };

    createTag = () => {
        if (!this.state.tag.trim().length) {
            message.warning('请输入标签')
            return;
        }
        this.props.dispatch(newTag(this.state.tag));
        this.setState({ tag: "" });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    }

    closeTagModal = () => {
        this.setState({ tagModal: false });
    }

    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setActivePage(1));
        await this.props.dispatch(setPageSize(pageSize));
        this.props.dispatch(fetchCommonSenses(this.props.activePage, this.props.pageSize));
    }

    tagInputHandler = event => {
        this.setState({ tag: event.target.value });
    }

    handleSelect = eventKey => {
        this.props.dispatch(setActivePage(eventKey));
        this.props.dispatch(fetchCommonSenses(eventKey, this.props.pageSize));
    };

    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setActivePage(1));
        await this.props.dispatch(setPageSize(pageSize));
        this.props.dispatch(fetchCommonSenses(this.props.activePage, this.props.pageSize));
    }

    handleConfirm = (id) => {
        this.props.dispatch(deleteCommonSense(id, this.state.activePage));
    };

    mdeleteTag = tag => {
        this.props.dispatch(delTag(tag));
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '标题',
                width: '25%',
                render: (text, record) => (
                    checkPermission('sys:sense:gkinfo') && <Link to={`/commonSense/${record.id}`} className="title">{record.title}</Link> || <span className="title">{record.title}</span>
                ),
            },
            {
                title: '第几期',
                width: '25%',
                dataIndex: 'stage',
            },
            {
                title: '上线时间',
                width: '25%',
                dataIndex: 'publishDate',
            },
            {
                title: '操作',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="确定要删除该常识吗?"
                            onConfirm={() => this.handleConfirm(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            {checkPermission('sys:sense:del') && <Button size="small" type="link">删除</Button>}
                        </Popconfirm>
                    )
                }
            }

        ];
        return columns;
    }


    render() {
        return (
            <div>
                {
                    this.state.permission == 2 &&
                    <div className="inputRow flexrc mb20">
                        <label className="mr15">常识标签</label>
                        <Input value={this.state.tag} onChange={this.tagInputHandler} className="i300 mr15" />
                        {checkPermission('sense:label:edit') && <Button type="primary" onClick={this.createTag}>创建标签</Button>}
                        <span className="flexg"></span>
                        {checkPermission('sys:sense:edit') && <Button type="primary">
                            <Link to={`/commonSenseRelease`}>添加常识</Link>
                        </Button>}
                    </div>
                }

                <div className="mb20">
                    {
                        this.props.tags.map((tag, idx) => (
                            <Tag key={idx} closable={this.state.permission == 2} onClose={() => { this.mdeleteTag(tag) }}>{tag}</Tag>
                        ))
                    }
                </div>

                <Table
                    columns={this.generateTableColumns()}
                    dataSource={this.props.commonSenseList}
                    rowKey={record => record.id}
                    title={() => '试题列表'}
                    loading={this.props.isFetching}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onChange: this.handleSelect,
                        onShowSizeChange: this.onShowSizeChange,
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.commonSenses
    }
}

export default connect(mapStatetoProps)(CommonSenseList);
