import React from 'react';
import { connect } from 'react-redux';
import {
    delCourse,
    fetchCourses,
    setRecommend,
    publishCourse,
    setClassListArr,
    setCurrentPage,
    setPageSize,
    fetchCourseTeachers,
    fetchCourseLabels
} from "./action";
import { fetchCourseCategories } from "../ModuleSetting/actions";
import { fetchEmpty } from "../CourseClassInfo/action";
import { fetchHeadTeacher } from "../ClassList/actions";
import { Typography, Tooltip, Menu, Dropdown, Table, Button, message, Select, Input, InputNumber, Popconfirm } from "antd";
import { checkPermission, generateBaseUrl } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { resetSearchStatus } from '../../components/QuestionSelectCard/actions';
import { resetSearchStatus as resetShenlunSearchStatus } from '../../components/ShenlunSelectCard/actions';
import './index.scss';
import apiRequest from '../../common/request';

const { Paragraph } = Typography;
const Option = Select.Option;

const subjectType = [
    { label: '全部', value: "" },
    { label: '行测', value: 0 },
    { label: '申论', value: 1 },
    { label: '公基', value: 3 },
    { label: '职测', value: 4 },
    { label: '综应', value: 5 },
];

class CourseList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchClassName: '',                             // 搜索的课程的名字
            filterDropdownVisible: false,                    // 筛选课程输入组件的可见性
        };
    }

    componentDidMount() {
        this.baseUrl = generateBaseUrl();
        let search = this.getSearch(this.props.location.state);
        search.page = this.props.currentPage;
        search.pageSize = this.props.pageSize;

        this.props.dispatch(fetchCourses(search));
        this.props.dispatch(fetchCourseCategories());
        this.props.dispatch(fetchCourseTeachers());
        this.props.dispatch(fetchCourseLabels());
        this.props.dispatch(fetchHeadTeacher());                            // 获取班主任列表数据
        this.setState({
            ...search,
        });
    }

    componentWillReceiveProps(nextProps) {
        let nextLoc = nextProps.location.state
        let preLoc = this.props.location.state
        if (nextLoc && (nextLoc != preLoc)) {
            let search = nextProps.location.state;
            search.page = nextProps.currentPage;
            search.pageSize = nextProps.pageSize;

            this.props.dispatch(fetchCourses(search));
            this.setState(nextProps.location.state);
        }
    }

    getSearch = (newValue) => {
        return {
            categoryId: '0',
            label: '',
            maxPrice: "",
            minPrice: "",
            publish: "",
            recommend: "",
            teacher: "",
            title: "",
            type: "",
            headTeacher: '全部',
            ...newValue
        }

    }

    // 修改上下架状态成功的话会进行一次重新加载
    publishcourse = (courseObj) => {
        let params = {
            ...this.getSearch(this.props.location.state),
            page: this.props.currentPage,
            pageSize: this.props.pageSize
        };
        this.props.dispatch(publishCourse(
            courseObj.id,
            params
        ));
    };

    /** 渲染antd table的表头 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>在线课程列表</span>
            {checkPermission('sys:course:edit') &&
                <Link className="addButton" to={`/courseRelease`}>
                    <Button type="primary">添加课程</Button>
                </Link>}
        </div>
    );

    /** 输入需要进行搜索的班级名字的时候 */
    onInputClassName = (event) => {
        this.setState({ searchClassName: event.target.value });
    };

    onSearch = () => {
        const { searchClassName } = this.state;
        let newArr = this.props.originItems.map((record) => {
            const match = record.title.indexOf(searchClassName);
            if (match === -1) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        this.props.dispatch(setClassListArr(newArr));

        this.setState({
            filterDropdownVisible: false,
        });
    };

    changeLoc = (value) => {
        this.props.dispatch(setCurrentPage(1));
        this.props.history.push({
            pathname: '/courses',
            state: {
                ...this.props.location.state,
                ...value
            }
        });
    };

    handleSearch = () => {
        this.changeLoc({
            title: this.state.title,
        })
    };

    /** 清空搜索条件 */
    resetSearch = () => {
        this.changeLoc({
            categoryId: '0',
            label: '',
            maxPrice: "",
            minPrice: "",
            publish: "",
            recommend: "",
            teacher: "",
            title: "",
            type: "",
            headTeacher: "全部"
        });
    }

    /** 当前页改变的时候触发 */
    paginationChange = (page, pageSize) => {
        let search = this.getSearch(this.props.location.state);
        search.page = page;
        search.pageSize = pageSize;
        this.props.dispatch(setCurrentPage(page));
        this.props.dispatch(setPageSize(pageSize));
        this.props.dispatch(fetchCourses(search));
    }

    /** 点击推荐或者取消推荐 */
    clickRecommend = courseObj => {
        let id = courseObj.id;
        let cb = () => {
            let search = this.getSearch(this.props.location.state);
            search.page = this.props.currentPage;
            search.pageSize = this.props.pageSize;
            this.props.dispatch(fetchCourses(search));
        };
        this.props.dispatch(setRecommend(id, cb));
    }

    /** 点击复制按钮复制班级 */
    copyClass = record => {
        let id = record.id;
        let url = `/gk/ops/course/copyCourse/${id}`;
        let config = {
            method: 'GET',
            headers: {
                'Acceppt': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        };
        apiRequest(url, config).then(json => {
            message.success('复制成功');
            let search = this.getSearch(this.props.location.state);
            search.page = this.props.currentPage;
            search.pageSize = this.props.pageSize;
            this.props.dispatch(fetchCourses(search));
        });
    }

    /** 添加新课时 */
    addNewCourse = async record => {
        await this.props.dispatch(fetchEmpty(record.id));
        await this.props.dispatch(resetSearchStatus());
        await this.props.dispatch(resetShenlunSearchStatus());
        let url = `/course/${record.id}/classRelease/${record.type}`;
        this.props.history.push(url);
    }

    /** 验证价格格式 */
    limitRule = value => { return (value.replace(/^(0+)|[^\d]+/g, '')) }

    /** 处理分类回显 */
    getName = (arr) => {
        return this.props.categories.length && arr.map(item => (this.props.categories.find(v => v.id == item).name + ' '))
    }

    getType = (type) => {
        let res = '未知';
        if (type == 0) res = '行测';
        if (type == 1) res = '申论';
        if (type == 3) res = '公基';
        if (type == 4) res = '职测';
        if (type == 5) res = '综应';
        return res;
    }

    render() {
        const menu = (record) => (
            <Menu>
                <Menu.Item>
                    {checkPermission('sys:course:publish') && <div onClick={() => { this.publishcourse(record) }}>
                        {record.publish === 0 ? "上架" : "下架"}
                    </div> || ''}
                </Menu.Item>
                <Menu.Item>
                    <Popconfirm
                        title="请确认新开课程是否跟之前的一样？一样的话请复用老的，不要复制!"
                        onConfirm={() => this.copyClass(record)}
                        placement="leftTop"
                        okText="复制"
                        cancelText="取消"
                    >
                        {checkPermission('sys:course:copy') && <div>复制</div>}
                    </Popconfirm>
                </Menu.Item>
                <Menu.Item>
                    {checkPermission('sys:course:recommend') && <div onClick={() => { this.clickRecommend(record) }}>
                        {record.recommend === 0 ? "推荐" : "取消推荐"}
                    </div> || ''}
                </Menu.Item>
                <Menu.Item>
                    <Popconfirm
                        title="确定删除该课程吗？"
                        placement="leftBottom"
                        onConfirm={() => this.props.dispatch(delCourse(record.id))}
                        okText="确定"
                        cancelText="取消"
                        disabled={!checkPermission('sys:course:del')}
                    >
                        <Button type="link" disabled={!checkPermission('sys:course:del')}>删除课程</Button>
                    </Popconfirm>
                </Menu.Item>
                <Menu.Item>
                    {checkPermission('sys:course:gkh5') && <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/courseGoods.html?courseId=${record.id}` }}>复制h5链接</Paragraph>}
                </Menu.Item>
            </Menu>
        );
        /** antd table 配置内容 */
        const columns = [
            {
                title: '课程标题',
                dataIndex: 'title',
                key: 'title',
                with: '20%',
                render: (text, record) => {
                    return (
                        checkPermission('sys:course:gkinfo') && <Link to={{ pathname: `/course/${record.id}`, state: record }}>{record.title}</Link> || <span>{record.title}</span>
                    );
                }
            },
            {
                title: '标签',
                with: '10%',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: '科目',
                with: '5%',
                dataIndex: 'type',
                key: 'type',
                render: (text, record) => this.getType(record.type)
            },
            {
                title: '分类',
                with: '10%',
                dataIndex: 'categoryIds',
                key: 'categoryIds',
                render: (text, record) => <Tooltip title={this.getName(record.categoryIds)}><div className="ellipsis1" style={{width: '4em'}}>{this.getName(record.categoryIds)}</div></Tooltip>
            },
            {
                title: '教师',
                with: '5%',
                dataIndex: 'text',
                key: 'text',
                render: (text, record) => <Tooltip title={record.text}><div className="ellipsis1" style={{width: '4em'}}>{record.text}</div></Tooltip>
            },
            {
                title: '班主任',
                with: '5%',
                dataIndex: 'headTeacher',
                render: (text, record) => <Tooltip title={record.headTeacher}><div className="ellipsis1" style={{width: '4em'}}>{record.headTeacher}</div></Tooltip>
            },
            {
                title: '价格(分)',
                with: '10%',
                dataIndex: 'price',
                key: 'price',
                sorter: (a, b) => a.price - b.price,
                render: (text, record) => {
                    return (
                        <span style={{ color: 'red' }}>{record.price}</span>
                    );
                }
            },
            {
                title: '起止时间',
                with: '10%',
                dataIndex: 'period',
                key: 'period',
                render: (text, record) => <Tooltip title={record.period}><div className="ellipsis1" style={{width: '4em'}}>{record.period}</div></Tooltip>
            },
            {
                title: '操作',
                with: '5%',
                render: (text, record) => {
                    return (
                        <Dropdown overlay={menu(record)} placement="bottomRight">
                            <Button type="link">展开</Button>
                        </Dropdown>
                    );
                }
            },
            {
                title: '课时',
                with: '10%',
                render: (text, record) => 
                    <div>
                        {checkPermission('sys:class:gksee') && <Link to={`/course/${record.id}/classes/${record.type}?title=${record.title}`}><Button size="small" type="link">查看</Button></Link>}
                        {checkPermission('sys:class:edit') && <Button onClick={() => this.addNewCourse(record)} size="small" type="link">添加</Button>}
                    </div>
            }
        ];

        let { isFetching, items, totalElements } = this.props;

        return (
            <div>
                <div className="commonSearchBox">
                    <div>
                        <label>课程类型</label>
                        <Select value={this.state.type} className="commonW150" onChange={value => this.changeLoc({ type: value })}>
                            {subjectType.map((obj, idx) => (
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>分类</label>
                        <Select value={this.state.categoryId}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ categoryId: value })}>
                            {this.props.categories.map((cat, idx) => {
                                return <Option key={idx} value={cat.id.toString()}>{cat.name}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>标签</label>
                        <Select value={this.state.label}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ label: value })}>
                            <Option value="">全部</Option>
                            {this.props.labels.map((label, idx) => {
                                return <Option key={idx} value={label}>{label}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>教师</label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={this.state.teacher}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ teacher: value })}>
                            <Option value="">全部</Option>
                            {this.props.teachers.map((teacher, idx) => {
                                return <Option key={idx} value={teacher}>{teacher}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>班主任</label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={this.state.headTeacher}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ headTeacher: value })}
                        >
                            {this.props.headTeachers.map((teacher, idx) => {
                                return <Option key={idx} value={teacher}>{teacher}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>上架状态</label>
                        <Select value={this.state.publish + ""}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ publish: value })}>
                            <Option value="">全部</Option>
                            <Option value="0">已下架</Option>
                            <Option value="1">已上架</Option>
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>推荐状态</label>
                        <Select value={this.state.recommend + ""}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={value => this.changeLoc({ recommend: value })}>
                            <Option value="">全部</Option>
                            <Option value="0">未推荐</Option>
                            <Option value="1">已推荐</Option>
                        </Select>
                    </div>
                    <div>
                        <label>最低价</label>
                        <InputNumber className="commonW150" value={this.state.minPrice}
                            onChange={value => this.changeLoc({ minPrice: value })} min={0} parser={this.limitRule}
                        />
                    </div>
                    <div>
                        <label>最高价</label>
                        <InputNumber className="commonW150" value={this.state.maxPrice}
                            onChange={value => this.changeLoc({ maxPrice: value })} min={0} parser={this.limitRule} />
                    </div>
                    <div className="itemSearchBox">
                        <label>标题搜索</label>
                        <Input
                            style={{ width: 400 }}
                            placeholder="输入标题的关键词查询"
                            value={this.state.title}
                            onChange={e => this.setState({ title: e.target.value })}
                            onPressEnter={() => this.handleSearch()}
                        />
                    </div>
                    <div className="itemSearchBox">
                        <Button disabled={isFetching} onClick={this.handleSearch} type="primary">搜索</Button>
                    </div>
                    <div className="itemSearchBox">
                        <Button disabled={isFetching} onClick={this.resetSearch} type="danger">重置</Button>
                    </div>
                </div>

                <div>
                    <Table
                        columns={columns}
                        dataSource={items}
                        rowKey={record => record.id}
                        title={this.renderHeader}
                        loading={isFetching}
                        pagination={{
                            current: this.props.currentPage,
                            pageSize: this.props.pageSize,
                            total: totalElements,
                            showTotal: total => `共有${total}条数据`,
                            onChange: this.paginationChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '20', '50', '100']
                        }}
                    />
                </div>

            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        items: categories
    } = state.courseCategories;

    return {
        ...state.courseList,
        categories,
        headTeachers: state.classList.headTeacherArr,                 // 这个是班主任列表数据
    };
}

export default connect(mapStatetoProps)(CourseList);
