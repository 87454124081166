import React from "react";
import { connect } from "react-redux";
import {
    fill_examine_title,
    publishExamine,
    fill_examine_limit_time,
    fill_examine_up_time,
    fill_examine_qArr,
    fill_examine_questions
} from "./actions";

import { checkPermission } from '../../utils/utils';
import { Affix, DatePicker, message, Card, Button, Input } from "antd";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import moment from 'moment';

class DailyExamineRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    submit = () => {
        if (this.props.info.title.trim() === "") {
            message.warn("请输入标题")
            return;
        }
        if (Object.prototype.toString.call(this.props.info.limitTime) === "[object Undefined]" || Object.prototype.toString.call(this.props.info.limitTime) === "[object Null]") {
            message.warn("请输入练习时长")
            return;
        }
        if (this.props.info.publishDate == null || typeof (this.props.info.publishDate) == "undefined") {
            message.warn("请输入上线日期")
            return;
        }
        if (!this.props.info.quesArr.length) {
            message.warn("请至少选择一个问题")
            return;
        }
        const cb = () => this.props.history.push('/dailyExamines');
        this.props.dispatch(publishExamine(this.props.info, cb));
    };

    selectUpTime = (dateString) => {
        this.props.dispatch(fill_examine_up_time(dateString));
    };

    updateQuesArr = (arr) => {
        this.props.dispatch(fill_examine_qArr(arr))
    };

    updateSelQues = (arr) => {
        this.props.dispatch(fill_examine_questions(arr));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    render() {
        const { info } = this.props;
        let quesArr = info.quesArr;
        let selQuesList = info.selQuesList;
        let exerciseType = 0;
        return (
            <div className="commonFlexLR">

                <div className="commonLeft">
                    <h4 style={{ margin: "0 0 10px" }}>新增每日一练</h4>
                    <div className="commonSearchBox">
                        <div>
                            <label>练习标题</label>
                            <Input className="commonW400" value={info.title} onChange={e => this.props.dispatch(fill_examine_title(e.target.value))}/>
                        </div>
                        <div label="">
                            <label>练习时长(秒)</label>
                            <Input className="commonW150" value={info.limitTime} onChange={e => this.props.dispatch(fill_examine_limit_time(e.target.value))}/>
                        </div>
                        <div>
                            <label>上线日期</label>
                            <DatePicker className="commonW150" value={this.calendarValuePipe(info.publishDate)} format="YYYY-MM-DD" onChange={(date, dateString) => {this.selectUpTime(dateString)}}/>
                        </div>
                    </div>

                    <QuestionSelectCard
                        quesArr={quesArr}
                        selQuesList={selQuesList||[]}
                        updateQuesArr={this.updateQuesArr}
                        updateSelQues={this.updateSelQues}
                        exerciseType={exerciseType}
                    />

                </div>
                <div className="commonsubmit">
                    {checkPermission('sys:dailyExercise:edit') &&<Affix offsetTop={0} target={() => document.querySelector('.site-layout-background')}>
                        <div>
                            <Card className="cardR" size="small" title={this.props.isSend ? '发布' : '更新'}>
                                <Button disabled={this.props.isSend} onClick={this.submit} type="primary">{this.props.isSend ? '发布' : '更新'}</Button>
                            </Card>
                        </div>
                    </Affix>}
                </div>
            </div>
        )
    }

}


function mapStatetoProps(state) {
    const {
        isSend,
        info
    } = state.dailyExamineRelease;

    return {
        isSend,
        info,
    };
}

export default connect(mapStatetoProps)(DailyExamineRelease);
