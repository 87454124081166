/**
 *  展示图片组件，用于涂鸦功能，点击图片的话能够对该图片进行涂鸦
 */
import React from 'react';
// import PropTypes from 'prop-types';
import CanvasComponent from '../Canvas';
import { Upload, message, Button } from 'antd';

import './gallery.scss';

class GalleryCanvas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,  // 默认打开第一张图片，下标为0
            imgArr: [ false, false, false, false, false, false, false, false, false, false, false ], // 最多9张图片，这里简单设置一个开关，用于判断图片是否旋转
            imgUrl: '',                                             // 需要进行展示的图片链接
            imgWidth: 500,                                          // 所点击图片的原始宽度
            imgHeight: 900,                                         // 所点击图片的原始高度
        };
    }

    /** 找出imgs中和modifyImgs 中相同图片的个数 */
    compareImgs(imgs, modifyImgs) {
        let result = [ ...imgs ];
        modifyImgs.map(imgUrl => {
            let unionName = imgUrl.split('-feedback')[0];
            unionName = unionName.replace('https://', '');
            unionName = unionName.replace('http://', '');
            let findResult = result.find(n => n.includes(unionName));
            if (findResult) {                                      // 说明当前图片被修改，那么找出删掉并替换
                let findIdx = result.indexOf(findResult);
                result.splice(findIdx, 1, imgUrl);                 // 使用新的进行替换
            } else {                                               // 不存在的话，那就忽略

            }
        });
        return result;
    }

    checkExist(arr, name) {
        let filename = name.replace('-feedback', '');
        filename = filename.replace('https://', '');
        filename = filename.replace('http://', '');
        filename = filename.replace('.jpg', '');
        filename = filename.replace('.png', '');
        let result = false;
        for (let i = 0, len = arr.length; i < len; i++) {
            let url = arr[i];
            if (url.indexOf(filename) !== -1) {
                result = true;
                break;
            }
        }
        return result;
    }

    clickImg = (imgUrl, index, isModified) => {
        let prefix = imgUrl.includes('.com/')?imgUrl.split('.com/')[1]:imgUrl;
        prefix = prefix.split('?')[0]
        prefix = prefix.split('.')[0]
        prefix = prefix.split('-feedback')[0]

        if (isModified) {
          let keys = Object.keys(this.props.holdImgs).filter(i => i===prefix)
          if (keys.length > 0) {
            imgUrl = this.props.holdImgs[keys[0]]
          }
        }else {
          if (imgUrl.indexOf('https') == -1) {
              imgUrl = imgUrl.replace('http', 'https');
          }
        }

        let refName = `img${index}`;
        let naturalWidth = this.refs[refName].naturalWidth;
        let naturalHeight = this.refs[refName].naturalHeight;

        // let MAX_WIDTH = document.documentElement.clientWidth - 160;
        // let MAX_HEIGHT = document.documentElement.clientHeight - 100;
        // if (naturalWidth > MAX_WIDTH && naturalHeight > MAX_HEIGHT) {
        //     let wRadio = naturalWidth / MAX_WIDTH;
        //     let hRadio = naturalHeight / MAX_HEIGHT;
        //     if (wRadio > hRadio) {
        //         naturalWidth = MAX_WIDTH;
        //         naturalHeight = naturalHeight / wRadio;
        //     } else {
        //         naturalHeight = MAX_HEIGHT;
        //         naturalWidth = naturalWidth / hRadio;
        //     }
        // } else if (naturalWidth > MAX_WIDTH) {
        //     let wRadio = naturalWidth / MAX_WIDTH;
        //     naturalWidth = MAX_WIDTH;
        //     naturalHeight = naturalHeight / wRadio;
        // } else if (naturalHeight > MAX_HEIGHT) {
        //     let hRadio = naturalHeight / MAX_HEIGHT;
        //     naturalHeight = MAX_HEIGHT;
        //     naturalWidth = naturalWidth / hRadio;
        // }
        // 给图片设置一个最大高宽度，避免过宽
        // 最大宽度为750
        let MAX_WIDTH = 750;
        if (naturalWidth > MAX_WIDTH) {
            let radio = naturalWidth / MAX_WIDTH;
            naturalWidth = MAX_WIDTH;
            naturalHeight = naturalHeight / radio;
        }

        this.setState({ index, imgUrl, imgKey: prefix, imgWidth: naturalWidth, imgHeight: naturalHeight });
        // 确定index的值，就能找到点评的是哪张图片，然后判断旋转状态，确定宽高是否要对调
        // 如果旋转次数为奇数，说明宽高要对调
        this.state.imgArr[index] &&  this.setState({imgWidth: naturalHeight, imgHeight: naturalWidth});
        // window.scrollTo(0, 0);
    }

    /** 点击canvasComponent的蒙层 隐藏CanvasComponent */
    hideCanvasComponent = () => {
        this.setState({ imgUrl: '' });
    }

    /** 生成随机字符串 */
    randomStr = () => {
        let num1 = Math.random();
        let num2 = Math.random();
        let num3 = Math.random();

        return '' + num1 + num2 + num3;
    }

    /** 上传文件时点击图片 */
    clickNormalImg = imgUrl => {
        this.uploadImgUrl = imgUrl;
    }

    /** 在上传图片展之前做一个验证 */
    /** 对文件做一个验证，避免上传无效文件 */
    beforeUpload = file => {

        let isPic = /(jpg|jpeg|png)$/.test(file.type);
        if (!isPic) {
            message.error('非图片');
            return;
        }

        let that = this;
        let fileName = ``, endIndex;
        let beginIndex = this.uploadImgUrl.indexOf('.com/');
        fileName = this.uploadImgUrl.slice(beginIndex+'.com/'.length);

        fileName = fileName.replace('.png', '');
        if (fileName.indexOf('feedback') !== -1) {
            fileName = fileName.replace('.jpg', '');
        } else {
            endIndex = fileName.indexOf('.');
            fileName = fileName.substr(0, endIndex)+'-feedback';
        }
        if (file.type.match('jp') && fileName.indexOf('.jpg') === -1) {
            fileName += '.jpg';
        } else if (file.type.match('png') && fileName.indexOf('.png') === -1) {
            fileName += '.png';
        } else if (file.type.match('') && fileName.indexOf('.jpg') === -1) {
            fileName += '.jpg';
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            that.props.upload(fileName, file, () => {}, {all: 1, cur: 1});
        };
    }

    /** 长变成高 */
    w2h = (fun) => {
        fun && fun();
        // 这个方法被旋转组件调用，默认为false，奇数为true，说明宽高对调了
        let {imgArr, index} = this.state;
        let arr = imgArr;
        arr[index] = !arr[index];
        this.setState({ imgArr: arr});
    }

    render() {
        let showImg = this.compareImgs(this.props.imgs, this.props.modifyImgs);
        let isModifyObj = {};
        showImg.forEach((img) => {
            let exist = false;
            for (let element in this.props.holdImgs) {
                if (img.indexOf(element) !== -1) {
                    exist = true;
                }
            }
            // this.props.holdImgs && this.props.holdImgs.forEach(element => {
            //     if (img.indexOf(element.fileName) !== -1) {
            //         exist = true;
            //     }
            // });
            isModifyObj[img] = exist;
        });
        return (
            <div>
                {
                    this.props.isUploadFile ?
                    <div className="upload-container">
                        <p className="uploadFileTips">
                            * 请点击选择你想对下面哪张学员图片做出文件上传批注操作
                        </p>
                        <ul ref="imgUl" className="imgListGallery">
                        {
                            showImg.map((imgUrl, index) => (
                                <li key={index}>
                                    <Upload
                                        className="avatar-uploader"
                                        name="avatar"
                                        showUploadList={false}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <img
                                            onClick={() => {this.clickNormalImg(imgUrl)}}
                                            className="normalBorderImg"
                                            alt="upload"
                                            ref={`img${index}`}
                                            src={`${imgUrl}?${this.randomStr()}`}
                                        />
                                    </Upload>
                                </li>
                            ))
                        }
                        </ul>
                    </div>

                    :

                    <ul ref="imgUl" className="imgListGallery">
                    {
                        showImg.map((imgUrl, index) => (
                            <li key={index}>
                                <img
                                    ref={`img${index}`}
                                    alt="img"
                                    onClick={() => {this.clickImg(imgUrl, index)}}
                                    src={`${imgUrl}?${this.randomStr()}`}
                                />
                                {
                                    isModifyObj[imgUrl] &&
                                    <div className="modal-tips">
                                      <span>已暂存</span>
                                      <span>提交点评后生效</span>
                                      <Button onClick={() => {this.clickImg(imgUrl, index, true)}} type="primary">再次修改</Button>
                                    </div>
                                }
                            </li>
                        ))
                    }
                    </ul>
                }

                {
                    this.state.imgUrl && (
                    <CanvasComponent
                        answerId={this.props.answerId}
                        imgUrl={this.state.imgUrl}
                        imgKey={this.state.imgKey}
                        hideCanvasComponent={this.hideCanvasComponent}
                        imgWidth={this.state.imgWidth}
                        imgHeight={this.state.imgHeight}
                        upload={this.props.upload}
                        showHoldImg={this.props.showHoldImg}
                        w2h={this.w2h}
                    />
                    )
                }
            </div>
        );
    }
}

// GalleryCanvas.propTypes = {
//     imgs: PropTypes.array.isRequired,
//     upload: PropTypes.func,
//     showHoldImg: PropTypes.func,                                               // 显示暂存批注的方法
//     modifyImgs: PropTypes.array,                                                // 在对图片进行涂鸦但是还没有提交批注时候的回显
//     holdImgs: PropTypes.array,                                                // 是否被标注
//     isUploadFile: PropTypes.bool,                                               // 表示是否是点击上传文件
// };

GalleryCanvas.defaultProps = {
    imgs: []
};

export default GalleryCanvas;
