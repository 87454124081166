import {
    SET_REDUX_KEY_VALUE,
    RESET_FILTER_VALUES,
    SET_REDUX_INPUT_WECHAT
} from './action';
  
    /** 这是筛选状态 */
    const searchParams = {
        endTime: null,                               // 订单结束时间
        page: 1,                                     // 第几页
        pageSize: 10,                                // 每页多少条
        startTime: null,                             // 订单起始时间
        version: null,                               // 版本号
        wechats: [],                                  // 微信号   
        clientType: null,                            // 领取平台 
        isRepeat: null,                                // 是否重复
        place: null,                                  // 领取位置
    };
  
    const initialState = {
        ...searchParams,
        productOrdersVO: {
            content: [],
            totalElements: 1,
        },                                           // 订单列表数据
        loadingList: false,                          // 是否处于加载状态
    };
  
    export const dataOrder = (prevState=initialState, action) => {
        switch(action.type) {
        case SET_REDUX_KEY_VALUE:
            return { ...prevState, [action.key]: action.value };
        case RESET_FILTER_VALUES:
            return { ...prevState, ...searchParams };
        case SET_REDUX_INPUT_WECHAT:
            return { ...prevState, [action.key]: action.value }
        default:
            return prevState;
    }
}