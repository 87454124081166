import apiRequest from '../../common/request';

const PREFIX = "QUESTION_LIST";

export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const RECEIVE_QUESTIONS_YEARS = PREFIX + "RECEIVE_QUESTIONS_YEARS";
export const RECEIVE_QUESTIONS_AREAS = PREFIX + "RECEIVE_QUESTIONS_AREAS";
export const RECEIVE_SHENLUN_AREAS = PREFIX + "RECEIVE_SHENLUN_AREAS";
export const RECEIVE_QUESTIONS_SOURCES = PREFIX + "RECEIVE_QUESTIONS_SOURCES";
export const REQUEST_DELETE_QUESTIONS = PREFIX + "REQUEST_DELETE_QUESTIONS";
export const RECEIVE_DELETE_QUESTIONS_RESULT = PREFIX + "RECEIVE_DELETE_QUESTIONS_RESULT";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const CHANGE_COMMON = PREFIX + "CHANGE_COMMON";
export const RESET_FILTER_STATUS = PREFIX + 'RESET_FILTER_STATUS';              // 重置筛选状态

export const resetFilterStatus = () => ({
    type: RESET_FILTER_STATUS
});

export const changeCommon = data => ({
    type: CHANGE_COMMON,
    key: data.key,
    val: data.val
});

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        numberOfElements: data.numberOfElements,
        receiveAt: Date.now()
    }
}

function request_delete_questions() {
    return {
        type: REQUEST_DELETE_QUESTIONS
    }
}

function receive_request_delete_questions(id) {
    return {
        type: RECEIVE_DELETE_QUESTIONS_RESULT,
        id
    }
}

function receive_question_years(years) {
    return {
        type: RECEIVE_QUESTIONS_YEARS,
        years
    }
}

function receive_question_areas(areas) {
    return {
        type: RECEIVE_QUESTIONS_AREAS,
        areas
    }
}

function receive_shenlun_areas(areas) {
    return {
        type: RECEIVE_SHENLUN_AREAS,
        areas
    }
}

export async function fetchQuestionCollection(id) {
    let result = await apiRequest(`/gk/ops/user/collection/question/${id}`, { credentials: 'include' })
        .then()
        .catch(error => console.log(error));
    return result;
}

export function fetchQuestionAreas() {
    return dispatch => {
        apiRequest('/gk/ops/question/area/list', { credentials: 'include' })
            .then(json => {
                dispatch(receive_question_areas(json.obj));
            })
            .catch(error => console.log(error));
    }
}

export function fetchShenlunAreas() {
    return dispatch => {
        apiRequest('/gk/ops/shenlun/area', { credentials: 'include' })
            .then(json => {
                dispatch(receive_shenlun_areas(json.obj));
            })
            .catch(error => console.log(error));
    }
}

export function fetchQuestionYears() {
    return dispatch => {
        apiRequest('/gk/ops/question/year/list', { credentials: 'include' })
            .then(json => {
                dispatch(receive_question_years(json.obj));
            })
            .catch(error => console.log(error));
    }
}

export function fetchQuestions(params, cb) {
    let data = {
        page: params.page || 1,
        excludeDeleted: true
    }
    //data.categoryId = params.id == '-1' ? '' : params.id;
    params.id !== '-1' ? data.categoryId = params.id : '';
    params.searchName ? data.query = params.searchName : '';
    params.year ? data.years = params.year.toString() : '';
    params.province ? data.province = params.province.toString() : '';
    params.source ? data.source = params.source : '';
    params.qdesc ? data.qdesc = params.qdesc : '';
    params.qorder ? data.qorder = params.qorder : '';
    params.questionTag ? data.questionTag = params.questionTag : '';
    if (params.options && params.options.length > 0) {
        data.options = params.options;
    }
    data.pageSize = params.pageSize || 10;
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_questions());
        apiRequest('/gk/ops/question/page', config)
            .then(json => {
                dispatch(receive_questions(json.obj));
                cb && cb();
            })
            .catch(error => console.log(error));
    }
}

export function deleteQuestion(id) {

    return dispatch => {
        dispatch(request_delete_questions());
        return apiRequest(`/gk/ops/question/delete/${id}`, { credentials: 'include' })
            .then(json => {

                dispatch(receive_request_delete_questions(id))
            })
            .catch(error => console.log(error));
    }
}
