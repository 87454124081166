import {
  SET_GOODS_KEY_VALUE,
  RESET_FILTER_VALUE,
  UPDATE_ITEM_KEY_VALUE
} from './action';

const filterParam = {
  needAddress: null,                // 是否需要发货地址
  page: 1,                          // 第几页
  pageSize: 10,                     // 每页多少条
  publish: null,                    // 是否上架
  name: null,                       // 商品名称
};

const initialState = {
  ...filterParam,
  productsVO: {
    content: [],
    totalElements: 10
  },                                // 商品列表接口所返回的数据
  loading: false,                   // 是否处于加载过程中            
};

export const goodsList = (prevState=initialState, action) => {
  switch(action.type) {
    case SET_GOODS_KEY_VALUE:
      return { ...prevState, [action.key]: action.value };
    case RESET_FILTER_VALUE:
      return { ...prevState, ...filterParam };
    case UPDATE_ITEM_KEY_VALUE:
      let { idx, key, value } = action;
      let prevContent = prevState.productsVO.content || [];
      prevContent[idx][key] = value;
      return { ...prevState, productsVO: { ...prevState.productsVO, content: prevContent }};
    default:
      return prevState;
  }
}