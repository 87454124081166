import apiRequest from '../../common/request'
import { message } from 'antd';

// 命名冲突会触发多个reducer，降低性能，同时导致未知问题，故变量使用唯一前缀
const PREFIX = "MATERIAL_QUESTION_INFO_";
export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const FILL_CONTENT = PREFIX + "FILL_CONTENT";
export const REQUEST_PUBLISH = PREFIX + "REQUEST_PUBLISH";
export const RECEIVE_PUBLISH_SUC = PREFIX + "RECEIVE_PUBLISH_SUC";
export const RECEIVE_PUBLISH_FAIL = PREFIX + "RECEIVE_PUBLISH_FAIL";
export const REQUEST_INFO = PREFIX + "REQUEST_INFO";
export const RECEIVE_INFO = PREFIX + "RECEIVE_INFO";
export const FILL_QUESTIONS = "FILL_QUESTIONS";
export const FILL_QARR = "FILL_QARR";


function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalElements: data.totalElements,
    }
}

function request_info() {
    return { type: REQUEST_INFO }
}

function receive_info(info) {
    return {
        type: RECEIVE_INFO,
        info
    }
}

function request_publish() {
    return {
        type: REQUEST_PUBLISH
    }
}

function receive_publish_suc() {
    return {
        type: RECEIVE_PUBLISH_SUC
    }
}

function receive_publish_fail() {
    return {
        type: RECEIVE_PUBLISH_FAIL
    }
}

export function fill_qArr(value) {
    return {
        type: FILL_QARR,
        key: 'questionIds',
        value
    }
}

export function fill_questions(value) {
    return {
        type: FILL_QUESTIONS,
        key: 'questions',
        value
    }
}


export function fill_content(value) {
    return {
        type: FILL_CONTENT,
        key: 'content',
        value
    }
}

export function fetchQuestions(page, categoryId, examineTitle, options, tag) {
    let data = {
        page: page || 1,
        excludeDeleted: true,
        categoryId: categoryId == '-1' ? '' : categoryId
    };
    if (examineTitle && examineTitle.length > 0)
        data.query = examineTitle;
    if (options && options.length > 0)
        data.options = options;
    if (tag && tag.length > 0)
        data.questionTag = tag;
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_questions());
        apiRequest('/gk/ops/materials/question/page', config)
            .then(json => {
                dispatch(receive_questions(json.obj))
            })
            .catch(error => console.log(error));
    }
}

export function fetchMaterialQuestion(type, id) {
    let url = `/gk/ops/materials/details/${id}`;
    if (type != 'xc') url = `/gk/ops/${type}/materials/details/${id}`;
    return dispatch => {
        dispatch(request_info());
        return apiRequest(url, { credentials: 'include' })
            .then(json => {
                dispatch(receive_info(json.obj))
            })
            .catch(error => console.log(error));
    }

}

export function update(type, info) {
    let url = `/gk/ops/materials/saveOrUpdate`;
    if (type != 'xc') url = `/gk/ops/${type}/materials/saveOrUpdate`;
    let data = {
        content: info.content,
        id: info.id,
        questionIds: info.questionIds.toString()
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_publish());
        return apiRequest(url, config).then(json => {
            dispatch(receive_publish_suc());
            message.success('操作成功');
            // dispatch(reset());

        }).catch(error => {
            dispatch(receive_publish_fail());
            console.log(error)
        });
    }
}
