import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    getExamineDetail,
    uploadExamineDetail,
    setValue,
    setSelQuesIds
} from './actions';
import { fetchInstitutionYearOrArea } from '../PaperList/actions';
import { Spin, Breadcrumb, Switch, Anchor, Card, Input, Select, Button, message } from "antd";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import { checkPermission } from '../../utils/utils';

const { Option } = Select;


class PaperDetail extends Component {

    componentDidMount() {
        let { type, id } = this.props.match.params;
        id && this.props.dispatch(getExamineDetail(id, type));
        this.props.dispatch(fetchInstitutionYearOrArea(type, 'area', 'jobArea'));
        this.props.dispatch(fetchInstitutionYearOrArea(type, 'year', 'jobYear'));
    }

    /** 共用修改方法 */
    commChange = (key, value) => {
        this.props.dispatch(setValue(key, value));
    }

    /** 发布试卷 */
    submitExam = () => {
        let { type, id } = this.props.match.params;
        let submitData = this.props.examineObj;
        if (submitData.title == '') return message.warning('请输入试卷标题');
        if (submitData.limitTime == '') return message.warning('请输入限制时间');
        if (submitData.year == '') return message.warning('请选择年份');
        if (submitData.area.length == 0) return message.warning('请选择地区');
        if (this.props.selQuesIds.length == 0) return message.warning('请勾选试题');
        submitData.questionIds = this.props.selQuesIds;
        id && (submitData.id = Number(id));
        const cb = () => this.props.history.push('/paperList');
        uploadExamineDetail(submitData, type, cb);
    }

    /** 动态权限判断方法 */
    getType = () => {
        let { type } = this.props.match.params;
        if (type == 'gj') return 'gj:history:edit';
        if (type == 'zc') return 'zc:history:edit';
        return '';
    }

    render() {
        let { type } = this.props.match.params;
        let { examineObj, jobYear, jobArea, loading } = this.props;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>事业单位</Breadcrumb.Item>
                    <Breadcrumb.Item>{type == 'gj' && '公基试卷详情' || '职测试卷详情'}</Breadcrumb.Item>
                </Breadcrumb>
                <Anchor style={{ position: 'fixed', top: 220, right: 30, padding: '4px 10px', background: '#fff', zIndex: 100 }}>
                    <Anchor.Link href="#inputQuestionAnchor" title="到达添加试题位置"></Anchor.Link>
                    <Anchor.Link href="#bottomAnchor" title="到达页面底部"></Anchor.Link>
                </Anchor>

                {checkPermission(this.getType()) &&
                    <Card size="small" title="发布" className="cardR"><Button onClick={this.submitExam} type="primary">发布</Button></Card>
                }
                <Spin spinning={loading} tip="题目量较多，试卷加载中，请稍等...">
                    <div className="commonDivMb20">
                        <div>
                            <label>试卷标题</label>
                            <Input className='commonW400' value={examineObj.title} onChange={e => { this.commChange('title', e.target.value) }} />
                        </div>
                        <div>
                            <label>限制时间</label>
                            <Input className='commonW150' value={examineObj.limitTime} onChange={e => { this.commChange('limitTime', e.target.value) }} />
                        </div>
                        <div>
                            <label>年份</label>
                            <Select value={examineObj.year} className="commonW150" onChange={value => { this.commChange('year', value) }}>
                                {jobYear && jobYear.map((year, idx) => {
                                    return <Option key={idx} value={year + ''}>{year}</Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <label>地区</label>
                            <Select mode="multiple" value={examineObj.area} className="commonW150" onChange={value => { this.commChange('area', value) }}>
                                {jobArea && jobArea.map((year, idx) => {
                                    return <Option key={idx} value={year + ''}>{year}</Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <label>是否上架</label>
                            <Switch checked={examineObj.publish == 1} onChange={value => this.commChange('publish', value)} />
                        </div>
                    </div>

                    <QuestionSelectCard
                        quesArr={this.props.selQuesIds || []}
                        updatePoint={false}
                        selQuesList={this.props.examineObj.questions || []}
                        updateQuesArr={(idArr) => this.props.dispatch(setSelQuesIds(idArr))}
                        updateSelQues={(quesArr) => this.props.dispatch(setValue('questions', quesArr))}
                        exerciseType={type}
                    />
                </Spin>

            </div>
        )
    }
}
function mapStatetoProps(state) {
    return {
        ...state.paperList,
        ...state.paperDetail,
    };
}
export default connect(mapStatetoProps)(PaperDetail)

