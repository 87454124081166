import React from 'react';
import { connect } from 'react-redux';
import { fetchGoodsOrderList, setReduxKeyValue, resetFilterValue, downExcel, updateOrderHandler, updateOrderItemValue } from './action';
import {
  Table,
  Select,
  Button,
  Modal,
  Input,
  DatePicker,
  Tag,
  message,
  InputNumber
} from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { checkPermission } from '../../utils/utils';
import moment from 'moment';
const Option = Select.Option;

const orderItemStyles = {
  inputFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  label4: {
    width: '5.8em',
    flexShrink: 0,
    flexGrow: 0
  },
  redFont: {
    color: 'red'
  }
};

class GoodsOrder extends React.Component {

  state = {
    idx: '',
    orderObj: {},
    showChangeWuliuModal: false,                // 是否显示修改物流弹框
    logisticsCompany: '',                       // 物流公司
    logisticsNo: '',                            // 物流编号
    isSendOutList: [{ label: '全部', value: null }, { label: '已发货', value: 1 }, { label: '未发货', value: 0 }],        // 是否发货的select状态
    statusList: [{ label: '全部', value: null }, { label: '已付款', value: 1 }, { label: '未付款', value: 0 }],           // 是否付款的select状态
  };

  conditionsChinese = {
    endTime: '截止时间', goodsName: '商品名称', isSendOut: '发货状态', logisticsNo: '物流单号',
    outTradeNo: '订单号', startTime: '起始时间', status: '支付状态', userName: '用户名', orderMobile: '手机号码',
    minAmount: '最低价格', maxAmount: '最高价格'
  };

  inputConditions = ['goodsName', 'logisticsNo', 'outTradeNo', 'userName', 'orderMobile'];
  amountConditions = ['minAmount', 'maxAmount']
  selectConditions = ['isSendOut', 'status'];

  dateConditions = ['startTime', 'endTime'];

  componentWillMount() {
    this.fetchOrdersHandler();
  }

  /** 获取分页参数 */
  getFetchParams = () => {
    let params = {};
    let keys = ['minAmount', 'maxAmount', 'endTime', 'goodsName', 'orderMobile', 'isSendOut', 'logisticsNo', 'outTradeNo', 'page', 'pageSize', 'startTime', 'status', 'userName'];
    const checkValidata = (key, params) => {
      if (this.filterNullAndEmptyStr(this.props[key])) { params[key] = this.props[key]; }
    };
    keys.map(key => checkValidata(key, params));
    return params;
  }

  /** 过滤掉类null值以及空字符串 */
  filterNullAndEmptyStr = val => val != null && val !== '';

  /** 加载订单列表数据 */
  fetchOrdersHandler = () => {
    let params = this.getFetchParams();
    this.props.dispatch(fetchGoodsOrderList(params));
  }

  /** 日历日期值转换函数 */
  calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''

  /** 监听点击清空查询条件按钮 */
  resetBtnClickHandler = async () => {
    await this.props.dispatch(resetFilterValue());
    this.fetchOrdersHandler();
  }

  /** 监听点击查询订单按钮 */
  searchHandler = async () => {
    await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
    this.fetchOrdersHandler();
  }

  /** 渲染订单筛选条件输入形式的相关UI结构 */
  renderFilterUI = () => (
    <div>
      <div className="commonSearchBox">
        {this.renderSelectStatus()}
        {this.renderInputsStatus()}
        {this.renderAmountStatus()}
        {this.renderCalendarUI()}
        <div>
          <Button type="primary" onClick={this.searchHandler} className="mr30">查询</Button>
          <Button type="danger" onClick={this.resetBtnClickHandler} className="mr30">重置</Button>
          {checkPermission('sys:productOrder:export') && <Button type="primary" onClick={this.downExcel}>导出Excel订单数据</Button>}
        </div>
      </div>
      <div className="commonSearchBox">
        <div>
          <p><span className="msg">*提示：价格的单位是分</span> 价格区间尽量左闭右开，尽量贴近查询的最低价格，比如实际存在价格880元课程，查询时最小价格输入88000</p>
          <p><span className="msg">*说明：</span>单位换算：1角=10分 1元=100分，  因服务端接收价格单位是分，价格条件框输入都为整数，不能带有小数，整数可以保证精度</p>
        </div>
      </div>
    </div>
  )
  // 验证价格格式
  limitRule = value => { return (value.replace(/^(0+)|[^\d]+/g, '')) }

  /** 商品订单渲染价格input类型相关的筛选数据 */
  renderAmountStatus = () => {
    return this.amountConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.conditionsChinese[key]}</label>
        <InputNumber
          min={0}
          style={{ width: '150px' }}
          value={this.props[key]}
          onChange={value => this.value2Redux({ key, value })}
          parser={this.limitRule}
        />
      </div>
    ));
  }

  /** 渲染select类型相关的筛选数据 */
  renderSelectStatus = () => {
    return this.selectConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.conditionsChinese[key]}</label>
        <Select style={{ width: 150 }} value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
          {
            this.state[`${key}List`].map((obj, idx) => (
              <Option key={idx} value={obj.value}>{obj.label}</Option>
            ))
          }
        </Select>
      </div>
    ));
  }

  /** 设置日期输入的值 */
  calendarValueChangeHandler = async (dateString, key) => {
    await this.props.dispatch(setReduxKeyValue({ key, value: dateString }));
  }

  /** 监听日历组件点击确定按钮 */
  calendarClickOkHandler = async () => {
    await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
    this.fetchOrdersHandler();
  }

  /** 渲染订单筛选条件日期选择形式的相关UI结构 */
  renderCalendarUI = () => {
    return this.dateConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.conditionsChinese[key]}</label>
        <DatePicker
          className="w260"
          format="YYYY-MM-DD HH:mm:ss"
          showTime
          value={this.calendarValuePipe(this.props[key])}
          onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, key); }}
          onOk={this.calendarClickOkHandler}
        />
      </div>
    ));
  }

  /** 渲染input类型相关的筛选数据 */
  renderInputsStatus = () => {
    return this.inputConditions.map((key, idx) => (
      <div key={idx}>
        <label>{this.conditionsChinese[key]}</label>
        <Input style={{ width: 150 }} onPressEnter={() => this.searchHandler()} value={this.props[key]} onChange={event => this.props.dispatch(setReduxKeyValue({ key, value: event.target.value }))} />
      </div>
    ));
  }
  
  /** 把用户输入的值反馈到redux里面 */
  value2Redux = async data => {
    await this.props.dispatch(setReduxKeyValue(data));
    await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
    this.fetchOrdersHandler();
  }

  /** 改变分页器每页显示的数目 */
  onShowSizeChange = async (current, pageSize) => {
    await this.props.dispatch(setReduxKeyValue({ key: 'pageSize', value: pageSize }));
    await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
    this.fetchOrdersHandler();
  }

  paginationChange = async value => {
    await this.props.dispatch(setReduxKeyValue({ key: 'page', value }));
    this.fetchOrdersHandler();
  }

  /** 下载订单数据 */
  downExcel = () => {
    let params = this.getFetchParams();
    this.props.dispatch(downExcel(params));
  }

  /** 返回支付方式 */
  generatePayMethod = (platform) => {
    let result = '';
    if (platform.match('wx')) result = <Tag color="green">微信</Tag>;
    if (platform.match('ali')) result = <Tag color="blue">支付宝</Tag>;
    if (result) return result;
  }

  /** 生成antd table的配置信息 */
  generateTableColumns = () => {
    let columns = [
      {
        title: '订单信息(价格:分)',
        width: '40%',
        render: (text, record) => (
          <div>
            <div><h4>{record.name}</h4></div>
            <span className="info">价格：<Tag color="red">{parseInt(record.amount * 100)}/分</Tag></span>
            <div className="info">订单号：{record.outTradeNo}</div>
            <div className="info">付款时间：{record.gmtCreate}</div>
          </div>
        ),
      },
      {
        title: '收货信息',
        width: '30%',
        render: (text, record) => (
          <div>
            <div className="info">收货人：{record.orderName}</div>
            <div className="info">联系方式：{record.mobile}</div>
            <span className="info">支付方式：{this.generatePayMethod(record.platform)}</span>
          </div>
        ),
      },
      {
        title: '操作',
        width: '20%',
        render: (text, record, index) => {
          return (
            checkPermission('sys:productOrder:logistics') && <Button size="small" type="primary" onClick={()=>this.showChangeWuliuModalHandler(record, index)}>
              填写物流单号
            </Button>

          )
        }
      },
      {
        title: '交易状态',
        width: '10%',
        render: (text, record) => {
          return (
            <div style={{ fontSize: '20px' }}>
              {record.status && <CheckCircleTwoTone twoToneColor="#3dc342" /> ||
                <CloseCircleTwoTone twoToneColor="#f04645" />}
            </div>
          )
        }
      }
    ];
    return columns;
  }

  /** 显示修改物流信息的模态框 */
  showChangeWuliuModalHandler = (record, idx) => {
    this.setState({ showChangeWuliuModal: true, idx, orderObj: record });
  }

  /** 点击模态的确定按钮 */
  okHandler = () => {
    let logisticsCompany = this.state.logisticsCompany || '';
    let logisticsNo = this.state.logisticsNo || '';
    if (!logisticsCompany) { message.warning('请填写物流公司'); return; }
    if (!logisticsNo) { message.warning('请填写物流单号'); return; }
    let successCb = async () => { 
      await this.props.dispatch(updateOrderItemValue(this.state.idx, 'logisticsCompany', logisticsCompany));
      await this.props.dispatch(updateOrderItemValue(this.state.idx, 'logisticsNo', logisticsNo));
      this.setState({ showChangeWuliuModal: false });
    };
    let data = {
      createTime: this.state.orderObj.gmtCreate,
      id: this.state.orderObj.id,
      outTradeNo: this.state.orderObj.outTradeNo
    };
    if (logisticsCompany) { data.logisticsCompany = logisticsCompany; }
    if (logisticsNo) { data.logisticsNo = logisticsNo; }
    updateOrderHandler(data, successCb);
  }

  /** 点击模态的取消按钮 */
  cancelHandler = () => {
    this.setState({ showChangeWuliuModal: false });
  }

  /** 更新state里面的value */
  updateState = data => {
    this.setState({ [data.key]: data.value });
  }

  render() {
    let { content } = this.props.productOrdersVO;
    let loadingList = this.props.loadingList;
    return (
      <div className="goodsOrderPageContainer">
        <div className="filterContainer">
          {this.renderFilterUI()}
        </div>
        <div>
          <div className="listContainer">
            <Table
              columns={this.generateTableColumns()}
              dataSource={content}
              rowKey={record => record.id}
              title={this.tableTitleRender}
              loading={loadingList}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                onChange: this.paginationChange,
                onShowSizeChange: this.onShowSizeChange,
                current: this.props.page,
                pageSize: this.props.pageSize,
                total: this.props.productOrdersVO.totalElements,
                showTotal: total => `共有${total}条数据`,
              }}
            />
          </div>
        </div>
        <Modal
          title="修改物流信息"
          visible={this.state.showChangeWuliuModal}
          onOk={this.okHandler}
          onCancel={this.cancelHandler}
        >
          <div style={orderItemStyles.inputFlexRow}>
            <label style={orderItemStyles.label4}><span style={orderItemStyles.redFont}>*</span>物流公司：</label>
            <Input onChange={event => this.updateState({ key: 'logisticsCompany', value: event.target.value })} placeholder="请填写物流公司" value={this.state.logisticsCompany} />
          </div>
          <div style={orderItemStyles.inputFlexRow}>
            <label style={orderItemStyles.label4}><span style={orderItemStyles.redFont}>*</span>物流单号：</label>
            <Input onChange={event => this.updateState({ key: 'logisticsNo', value: event.target.value })} placeholder="请填写物流单号" value={this.state.logisticsNo} />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapToState = storeState => ({ ...storeState.goodsOrderList });

export default connect(mapToState)(GoodsOrder);