import { 
    REQUEST_COMMONSENSE_LIST, RECEIVE_COMMONSENSE_LIST, RECEIVE_TAG_LIST,
    SET_ACTIVE_PAGE, SET_PAGE_SIZE
} from './actions';

export function commonSenses(state={
    isFetching: true,
    commonSenseList: [],
    totalPages: 1,
    totalElements: 1,
    tags: [],
    activePage: 1,
    pageSize: 10
}, action) {
    switch (action.type) {
        case RECEIVE_TAG_LIST:
            return Object.assign({},state, {tags: action.value||[]})
        case REQUEST_COMMONSENSE_LIST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_COMMONSENSE_LIST:
            return Object.assign({}, state, {
                isFetching: false,
                commonSenseList: action.data.content,
                totalPages: action.data.totalPages,
                totalElements: action.data.totalElements
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, {pageSize: action.data});
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, {activePage: action.data});
        default:
            return state;
    }
}