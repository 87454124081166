import React from 'react';
import { connect } from 'react-redux';
import { get_interview_detail, get_global_assessment } from './actions';
import { fetchQuestionFeedback } from '../InterviewStudentList/actions';
import { Breadcrumb, Button, Input, Form, Modal, Tooltip, Spin, message } from 'antd';
import './index.scss';
import RecordComponent from '../../components/RecordAudio';
import { checkPermission, getSearchQuery, uploadOSS } from '../../utils/utils';

const { TextArea } = Input;
class InterviewStudentDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            videoUrl: '',
            videoModal: false,
            loadingModal: false,
            showUserModal: false,
            userInfo: {},
            showOverallRate: false,
            commentInput: '',
        }
    };

    async componentDidMount() {
        await this.init();
        await this.fetchStudentDetail();
        this.setState({
            commentInput: this.props.responseObj.comment,
        })
    };

    fetchStudentDetail = () => {
        let studentId = getSearchQuery('studentId');
        this.props.dispatch(get_interview_detail(studentId));
    };

    /** 初始化方法 */
    init = () => {
        let singleInfo = JSON.parse(window.localStorage.getItem('interviewStudentList'));
        this.singleInfo = singleInfo;
    };

    /** 返回班级列表页面 */
    backToInterviewClass = () => {
        let url = `/interviewClass`;
        this.props.history.replace(url);
    }

    /** 返回学员列表页面 */
    backToInterviewList = () => {
        const classId = getSearchQuery('classId');
        const num = getSearchQuery('num');
        let url = `/interviewCandidateList?classId=${classId}&num=${num}`;
        this.props.history.replace(url);
    }

    /** 点击编辑弹框 */
    mModify = userInfo => {
        this.setState({
            showUserModal: true,
            userInfo,
        });
    };

    /** 总评弹框 */
    lModify = commentInput => {
        this.setState({
            showOverallRate: true,
            commentInput
        })
    }

    /** 关闭弹窗 */
    handleCancel = () => {
        this.setState({
            showUserModal: false,
            userInfo: {},
        });
    };

    videoHandleCancel = () => {
        this.setState({videoModal: false, videoUrl: ''});
    }

    /** 总评弹窗关闭 */
    commentCancel = () => {
        this.setState({
            showOverallRate: false,
            commentInput: ''
        });
    };

    verifyData = () => {
        if (!this.state.userInfo || !this.state.userInfo.comment || this.state.userInfo.comment == '') {
            message.warn('文字点评不能为空');
            return true;
        }
        return false;
    };

    commentVerifyData = () => {
        if (!this.state.commentInput || this.state.userInfo.comment == '') {
            message.warn('评论不能为空');
            return true;
        }
        return false;
    };

   /** 获取总评 fileName采取唯一且覆盖的形式*/
   fetchGlobalAssessment = async () => {
        let running = this.refs['recordBlob'].state.running;
        if(running) return message.warn('请完成录音');
        if (this.commentVerifyData()) return;
        let userId = getSearchQuery('userId');
        let classId = getSearchQuery('classId');
        const { interviewArchivesCommentId, length, size, voiceUrl } = this.props.responseObj;
        let comment = this.state.commentInput;
        let fileName = `interview/teacher/audio/${classId}/${userId}/${Date.now()}.m4a`;
        let fileAudio = this.refs['recordBlob'].state.blob;
        let params = { classId, userId, comment };
        interviewArchivesCommentId ? params.id = interviewArchivesCommentId : '';
        length ? params.length = length : '';
        size ? params.size = size : '';
        voiceUrl ? params.voiceUrl = voiceUrl.split('aliyuncs.com/')[1] : '';
        this.setState({loadingModal: true});
        if (fileAudio !== null) { //如果有新语音上传，则覆盖老数据，使用新语音
            let newLength = this.refs['recordBlob'].state.duration;
            let newSize = this.refs['recordBlob'].state.fileSize;
            params.length = newLength;
            params.size = newSize;
            params.voiceUrl = fileName;
            await uploadOSS(fileName, fileAudio, 'gk-user');
        }
        let cb = this.fetchStudentDetail;
        await this.props.dispatch(get_global_assessment(params, cb));
        this.setState({loadingModal: false, showOverallRate: false});
    };

    /** 提交点评 */
    modifyUser = async () => {
        let running = this.refs['recordBlob'].state.running;
        if(running) return message.warn('请完成录音');
        if (this.verifyData()) return;
        const { cLength, cSize, commentUrl, comment, questionId, id, remark, userId, exerciseId } = this.state.userInfo
        let classId = getSearchQuery('classId');
        let fileName = `interview/teacher/audio/${questionId}/${userId}/${Date.now()}.m4a`;
        let fileAudio = this.refs['recordBlob'].state.blob;
        let params = {
            classId,
            comment,
            questionId,
            id,
            remark,
            userId,
            exerciseId
        };
        cLength ? params.cLength = cLength : '';
        cSize ? params.cSize = cSize : '';
        commentUrl ? params.commentUrl = commentUrl.split('aliyuncs.com/')[1] : '';

        this.setState({loadingModal: true});
        if (fileAudio !== null) { //如果有新语音上传，则覆盖老数据，使用新语音
            let cLength = this.refs['recordBlob'].state.duration;
            let cSize = this.refs['recordBlob'].state.fileSize;
            params.cLength = cLength;
            params.cSize = cSize;
            params.commentUrl = fileName;
            await uploadOSS(fileName, fileAudio, 'gk-user');
        }
        let cb = this.fetchStudentDetail;
        await this.props.dispatch(fetchQuestionFeedback(params, cb));
        this.setState({loadingModal: false, showUserModal: false});
    };

    /** 获取当前索引 */
    findCurrentStudentIdx = (id) => {
        let singleInfo = this.singleInfo;
        if (Object.prototype.toString.call(singleInfo) != '[object Array]') return;
        if (singleInfo && singleInfo.length) {
            for (let singleIdx in singleInfo) {
                if (singleInfo[singleIdx].userId == id) {
                    return Number(singleIdx);
                };
            }
        }
    };

    /** 点击上一位学员 */
    prevStudent = () => {
        const { userId } = this.props.responseObj;
        let currentIndex = this.findCurrentStudentIdx(userId);
        if (currentIndex <= 0) return;
        let nextStudentObj = this.findPrevOrNextId(currentIndex, true);
        const { studentId } = nextStudentObj;
        this.jump2NextPrevStudent(studentId);
    };

    /** 点击下一位学员 */
    nextStudent = () => {
        const { userId } = this.props.responseObj;
        let currentIndex = this.findCurrentStudentIdx(userId);
        if (currentIndex >= this.singleInfo.length - 1) return;
        let nextStudentObj = this.findPrevOrNextId(currentIndex);
        const { studentId } = nextStudentObj;
        this.jump2NextPrevStudent(studentId);
    };

    /** 页面跳转到上下位学员 */
    jump2NextPrevStudent = studentId => {
        let url =`/interviewStudentDetail?classId=${getSearchQuery('classId')}&num=${getSearchQuery('num')}&studentId=${studentId}&className=${getSearchQuery('className')}`;
        this.props.history.replace(url);
        this.updateMe()
    };

    updateMe = () => {
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    /** 返回上下位学员的ID  prev：是否是上一个或者下一个 */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.singleInfo[currentIndex - 1];
        else return this.singleInfo[currentIndex + 1];
    };

    /** 处理点评输入 */
    handleCommentInput = (event) => {
        let value = event.target.value;
        this.setState({ commentInput: value });
    }

    render() {
        const { classExerciseList, studentName, userId, comment } = this.props.responseObj;
        let currentIndex = this.findCurrentStudentIdx(userId);                                  // 当前学员的索引位置
        let prevNums = currentIndex;                                                      // 当前学员前面还有多少位学员
        let nextNums = this.singleInfo ? this.singleInfo.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
        return(
            <Spin spinning={this.props.loading}>
            <div className="interviewDetailPage">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <span onClick={ this.backToInterviewClass }>班级列表</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span onClick={ this.backToInterviewList }>学员列表</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>学员详情</Breadcrumb.Item>
                </Breadcrumb>
                <div className="bottomModule">
                    <div className="flexrc childDiv borderBottom">
                        <span className="mb5">班级:</span>
                        <span className="marginRight25 mb5">{getSearchQuery('className')}</span>
                        <span className="mb5">学员:</span>
                        <span className="marginRight25 mb5">{studentName}</span>
                    </div>
                    {
                        (classExerciseList && classExerciseList.length) ?
                            classExerciseList.map((item, index) => {
                                return(
                                    <div key={index}>
                                        <div className="flexrc childDiv mt10" key={index}>
                                            <span className="mb5 blue">班级练习:</span>
                                            <span className="marginRight25 mb5 blue">{item.title}</span>
                                        </div>
                                        {
                                            item.interviewQuestions && item.interviewQuestions.length &&
                                            item.interviewQuestions.map((questions, questionIdx) => {
                                                const { interviewArchivesUser, question, detailMaterials } = questions;
                                                return (
                                                    <div key={questionIdx} className="questionName">
                                                        {
                                                            detailMaterials && detailMaterials.length &&
                                                            detailMaterials.map((material, materialIdx) => {
                                                                return (
                                                                    <div key={materialIdx} className="materials">
                                                                        <p className="exerciseTitle">{`材料${materialIdx+1}:`}</p>
                                                                        <span dangerouslySetInnerHTML={{ __html: material}} style={{ 'fontSize': '14px'}}></span>
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                        <p className="exerciseTitle">{`试题${questionIdx+1}:`}</p>
                                                        <span dangerouslySetInnerHTML={{ __html: question}} style={{ 'fontSize': '14px'}}></span>
                                                        {   
                                                            interviewArchivesUser &&
                                                            <div className="por">
                                                                {
                                                                    interviewArchivesUser.remark &&
                                                                    <div className="remarkTotal">
                                                                        <p className="exerciseTitle">学员做答</p>
                                                                        <div className="remarkStyle">{interviewArchivesUser.remark || ''}</div>
                                                                    </div>
                                                                }
                                                                {
                                                                    interviewArchivesUser.voiceUrl && 
                                                                    <div className="remarkTotal">
                                                                        <p className="exerciseTitle">学生语音</p>
                                                                        <audio style={{'height': '35px', 'marginBottom': '10px'}} controls="controls" src={interviewArchivesUser.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                                                                    </div>
                                                                }
                                                                {
                                                                    interviewArchivesUser.videoUrl && 
                                                                    <div className="remarkTotal">
                                                                        <p className="exerciseTitle">学生视频 <Button type="link" onClick={()=>this.setState({videoUrl:interviewArchivesUser.videoUrl, videoModal: true})}>点我播放</Button></p>
                                                                    </div>
                                                                }
                                                                {
                                                                    interviewArchivesUser.gmtCreate &&
                                                                    <div className="fz14">
                                                                        <span>学员提交时间:</span>
                                                                        <span>{interviewArchivesUser.gmtCreate}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    interviewArchivesUser.comment &&
                                                                    <div className="remarkTotal">
                                                                        <p className="exerciseTitle">老师点评</p>
                                                                        <div className="remarkStyle">{interviewArchivesUser.comment || ''}</div>
                                                                    </div>
                                                                }
                                                                {
                                                                    interviewArchivesUser.commentUrl &&
                                                                    <div className="remarkTotal">
                                                                        <p className="exerciseTitle">老师语音</p>
                                                                        <audio style={{'height': '35px', 'marginBottom': '10px'}} controls="controls" src={interviewArchivesUser.commentUrl}>您的浏览器不支持 audio 标签</audio>
                                                                    </div>
                                                                }
                                                                {checkPermission('interview:stu:feedback') &&
                                                                    <div className="comment">
                                                                        <span className="flexG"></span>
                                                                        <Button type="primary" className="modify" onClick={()=>this.mModify(interviewArchivesUser)}>点评</Button>
                                                                    </div> 
                                                                }
                                                            </div>
                                                        } 
                                                        {
                                                            !interviewArchivesUser &&
                                                            <div className="nono">未完成</div>
                                                        }  
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        :
                        <div className="notExercise">
                            没有练习...
                        </div>
                    }
                </div>
                {
                    classExerciseList && classExerciseList.length &&
                    <div className="pageBase">
                        <div className="overallRate">
                            <div className="remarkTotal">
                                <p className="exerciseTitle">老师总评</p>
                                <div className="remarkStyle">{this.props.responseObj.comment || ''}</div>
                                {this.props.responseObj.voiceUrl && 
                                <div style={{marginTop: '10px'}}>
                                    <p className="exerciseTitle">总评语音</p>
                                    <audio controls="controls" src={this.props.responseObj.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                                </div>}
                            </div>
                        </div>
                        
                        <div className="comment">
                            <span className="flexG"></span>
                            {checkPermission('view:comment:edit') &&
                                <Button type="primary" onClick={()=>this.lModify(this.props.responseObj.comment)}>点评</Button>
                            }
                            <Modal
                                title="学员面试总评"
                                visible={this.state.showOverallRate}
                                onOk={this.fetchGlobalAssessment}
                                onCancel={this.commentCancel}
                                width="800"
                                okText='提交'
                                destroyOnClose={true}
                                confirmLoading={this.state.loadingModal}
                            >
                                <Spin tip="点评上传中，请稍等..." spinning={this.state.loadingModal}>
                                <Form>
                                    <Form.Item label="点评(最多输入500字)">
                                        <TextArea
                                            maxLength="500"
                                            autosize={{ minRows: 3}}
                                            value={this.state.commentInput || ''}
                                            onChange={e=>this.setState({commentInput: e.target.value})}
                                            style={{ 'fontSize': '14px'}}
                                        />
                                    </Form.Item>
                                    { this.props.responseObj.voiceUrl &&
                                    <Form.Item label="近一次老师语音总评">
                                        <audio controls="controls" src={this.props.responseObj.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                                    </Form.Item>
                                    }
                                    <Form.Item label="语音点评(可选)">
                                        {this.state.showOverallRate && <RecordComponent key={userId} ref="recordBlob"></RecordComponent>}
                                    </Form.Item>
                                </Form>
                                </Spin>
                            </Modal>
                        </div>  
                    </div>
                }
                <div className="nextAndPrevBtn">
                    <div className="flexRow height100">
                        <span className="userName">{studentName}</span>
                        <span className="flexG"></span>
                        <Button onClick={this.backToInterviewClass} type="primary" className="mr30">返回班级列表页面</Button>
                        <Button onClick={this.backToInterviewList} type="primary" className="mr30">返回学员列表页面</Button>
                        <Tooltip title={`前面还有${prevNums}位学员`}>
                            <Button disabled={this.singleInfo && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一位学员</Button>
                        </Tooltip>
                        <Tooltip title={`后面还有${nextNums}位学员`}>
                            <Button disabled={this.singleInfo && currentIndex >= this.singleInfo.length-1} onClick={this.nextStudent} type="primary">下一位学员</Button>
                        </Tooltip>
                    </div>
                </div>
                <Modal title="学生视频" footer={null} visible={this.state.videoModal} onCancel={this.videoHandleCancel}>
                    <video width="480" height="380" src={this.state.videoUrl} controls autoPlay="autoplay">您的浏览器不支持 HTML5 video 标签</video>
                </Modal>
                <Modal
                    title="学员面试点评"
                    visible={this.state.showUserModal}
                    onOk={this.modifyUser}
                    onCancel={this.handleCancel}
                    width="800"
                    okText='提交'
                    destroyOnClose={true}
                    confirmLoading={this.state.loadingModal}
                >
                    <Spin tip="点评上传中，请稍等..." spinning={this.state.loadingModal}>
                    <Form>
                        <Form.Item label="文字点评(最多输入500字)">
                            <TextArea
                                maxLength="500"
                                autosize={{ minRows: 3}}
                                value={this.state.userInfo.comment || ''}
                                onChange={e=>this.setState({userInfo: {...this.state.userInfo, comment: e.target.value}})}
                                style={{ 'fontSize': '14px'}}
                            />
                        </Form.Item>
                        { this.state.userInfo.commentUrl &&
                        <Form.Item label="近一次老师语音点评">
                            <audio controls="controls" src={this.state.userInfo.commentUrl}>您的浏览器不支持 audio 标签</audio>
                        </Form.Item>
                        }
                        <Form.Item label="语音点评(可选)">
                            <RecordComponent key={this.state.userInfo.questionId} ref="recordBlob"></RecordComponent>
                        </Form.Item>
                    </Form>
                    </Spin>
                </Modal>
            </div>
            </Spin>
        )
    };
}


function mapStatetoProps(state) {

    const {
        responseObj,
        loading
    } = state.interviewStudentDetail;

    return {
        responseObj,
        loading
    };
}

export default connect(mapStatetoProps)(InterviewStudentDetail);