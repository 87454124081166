import apiRequest from '../../common/request'
import Cookies from 'js-cookie';
import fetch from 'isomorphic-fetch';
/**
 * Action Type
*/
import {message} from 'antd';
const PREFIX = '_ORDER_ACTION_';

export const FETCH_ORDERS_SUCCED = PREFIX + 'FETCH_ORDERS_SUCCED';              // 获取订单列表成功
export const FILTER_ALL = PREFIX + 'FILTER_ALL';                                // 筛选条件：所有
export const FILTER_NOPAY = PREFIX + 'FILTER_NOPAY';                            // 筛选条件：未付款
export const FILTER_DONE = PREFIX + 'FILTER_DONE';                              // 筛选条件：已完成
export const POST_NOTE_SUCCED = PREFIX + 'POST_NOTE_SUCCED';                    // 表示成功修改订单数据
export const SET_REDUX_KEY_VALUE = PREFIX + 'SET_REDUX_KEY_VALUE';              // 修改redux中指定键的值
export const RESET_FILTER_STATUS = PREFIX + 'RESET_FILTER_STATUS';              // 重置筛选状态
export const LOADING = PREFIX + 'LOADING';

export const resetFilterStatus = () => ({
    type: RESET_FILTER_STATUS
});

/**
* Action Creator
*/
const set_loading = () => ({
    type: LOADING
});

const post_note_succed_creator = () => ({
    type: POST_NOTE_SUCCED
});

const fetch_orders_succed_creator = (responseData) => ({
    type: FETCH_ORDERS_SUCCED,
    data: responseData
});

/** TODO 增加对订单的筛选  */
export function fetchOrders(data) {
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(set_loading());
        apiRequest('gk/ops/order/page', config)
            .then(json => {  
                json.obj.content.length ? '':message.info('没有数据，请重新查询');
                /** 要根据response的返回的type来dispatch相对应的action */
                dispatch(fetch_orders_succed_creator(json))
            })
            .catch(error => console.error(error))
    };
}

/** 当提交备注时，如果成功提交的话，那么跳转到order列表强制刷新；如果提交失败的话，那么不用跳转 */
export function postNoteMethod(obj, cb) {
    let url = `gk/ops/order/updateRemarks?outTradeNo=${obj.outTradeNo}&remarks=${obj.remarks}`;
    let config = {
        method: "POST",
        headers: {
            'content-type': "application/json",
            'accept': "application/json"
        },
        credentials: "include",
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
          message.success('操作成功');
          dispatch(post_note_succed_creator());
          cb && cb();
        })
        .catch(err => console.error(err))
    }
}

export function alert(content) {
    return dispatch => {
        message.warn(content);
    }
}

/** 下载EXCEL */
export function downloadExcel(params) {
    let url = '/gk/ops/order/exportOrder';
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken'),
            'Sin-UserAgent-Sign': 'SintureGK-WEB',
            'Sin-Client-Type': 3,
            Accept: '*/*',
        },
        body: JSON.stringify(params),
        credentials: 'include'
    };
    return dispatch => {
        fetch(url, config).then(response => {
            response.blob().then(blob => {
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                // let fileName = params.startTime + '~' + params.endTime + '第' + params.page + '页' + '.xls';
                let fileName = '订单数据.xls';
                a.download = fileName;
                document.body.appendChild(a).click();
                window.URL.revokeObjectURL(url);
            })
        }).catch(err => {
            message.error('网络错误');
        })
    }
}

/** 接受key，value，修改redux里面所对应存储的key值，data里面会包含有key,value */
export const setReduxKeyValue = data => ({
    type: SET_REDUX_KEY_VALUE,
    data
});