// Target: 重构行测练习数据展示组件，主要有两点：1.折线图；2.练习结果展示

import React from 'react';
import * as echarts from 'echarts';
import { Card, BackTop, Button, message } from 'antd';
import AnswerCardUnit from '../AnswerCardUnit/index';

import './index.scss';

const styles = {
    card: {
        width: '96%',
        marginBottom: 20,
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)'
    },
}

/** 折线图会使用上，判断某些天是否具有有效的题目数据 */
function isDayValid(dayExerciseArr) {
    let result = false;
    // 根本就不是数组的话那么肯定没有练习题数据
    if (!dayExerciseArr || Object.prototype.toString.call(dayExerciseArr) != "[object Array]") {
        return false;
    }
    // 是数组但是有视频数组但是却没有一个练习数据
    result = dayExerciseArr.some(obj => obj.exercise && obj.courseNum);
    return result;
}

class Xingce extends React.Component {
    constructor(props) {
        super(props);
        this.state={}
    }

    isSinturer = () => {
        return this.props.permission != 3;
    }

    componentDidMount() {
        if (this.isSinturer()) {
            this.chart = echarts.init(document.getElementById('chart'), null, { locale: 'ZH' });
            this.xArr = [];     // 图表的X轴数据
            this.yArr = [];     // 图表的Y轴数据
            let classes = this.props.classes;
            this.dataHandler(classes);
            this.setChart();
        }
    }

    /** 对图表进行初始化设置 */
    setChart = () => {
        this.chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            xAxis: {
                type: 'category',
                name: '第几天',
                data: this.xArr,
                axisPointer: { type: 'shadow' }
            },
            dataZoom: [
                {
                    type: 'slider',   // 使用底部工具左右拖动进行调整放大
                    start: 0, end: 100
                }
            ],
            yAxis: {
                type: 'value',
                name: '该天平均正确率（单位%）'
            },
            series: [{
                data: this.yArr,
                type: 'line'
            }],
            toolbox: {
                feature: {
                    saveAsImage: { pixelRatio: 3 },             // 提高导出的图片的分辨率 
                    dataView: {},                             // 数据视图工具，展现当前图标所使用的数据（非数据可视化形式）
                    magicType: { type: ['line', 'bar'] },
                    restore: { show: true }
                }
            }
        });
    }

    /** classes是一个数组，数组的每一项代表某天的练习数据，同时某天的练习数据也是一个数组，因为一天可以具有多个课时 */
    dataHandler = (classes = []) => {
        let xArr = [];
        let yArr = [];
        if (!classes.map) {
            console.error("行测练习展示组件-练习题目数据不是数组！")
            return;
        }
        /** 关于体验的一点：对于折现图数据来说，希望看到的是有效的反映作业完成情况的数据；而有些天数根本就没有课程或者有课程却没有练习题，对于这些情况都应该剔除掉 */
        classes.map(dayExerciseArr => {             // 需要注意某一天连一个课时都没有的情况，此时就不计算了
            if (dayExerciseArr.classes) {
                if (isDayValid(dayExerciseArr.classes)) {       // 有效数据（该天一定存在着练习数据）
                    let courseNum = dayExerciseArr.classes && dayExerciseArr.classes[0] && dayExerciseArr.classes[0]['courseNum'];
                    let correctExercise = 0, totalExercise = 0;
                    xArr.push(courseNum);
                    // 统计该天的正确率数据
                    dayExerciseArr.classes.map(exerciseObj => {
                        exerciseObj.exercise && exerciseObj.exercise.questions && exerciseObj.exercise.questions.map(questionObj => {
                            totalExercise++;
                            if (questionObj.correct == 1) { correctExercise++; }
                        })
                    });
                    yArr.push((correctExercise / totalExercise * 100).toFixed(1));
                }
            }
        });
        this.xArr = xArr;
        this.yArr = yArr;
    }

    renderTitle = (exerciseObj = {}, index) => {
        return (
            <div className="title-container">
                <span className="spanRight sr2">###{exerciseObj.courseNum}-{index + 1}</span>
                { this.isSinturer() && <span className="spanRight sr2">视频标题：{exerciseObj.title || '无标题'}</span>}
                {
                    exerciseObj.exercise && exerciseObj.exercise.title ?
                        <span className="spanRight sr2">练习标题：{exerciseObj.exercise.title}</span>
                        :
                        <span></span>
                }
                {
                    typeof exerciseObj.length === 'number' && this.isSinturer() &&
                    <span className="spanRight sr2">视频时长：{(exerciseObj.length / 60).toFixed(1)}分钟</span>
                }
            </div>
        );
    };

    /** 计算距离顶部的距离 */
    computeOffset = (ele) => {
        if (!ele) return 0;
        let offsetY = 0;
        while (ele != window.document.body && ele != null) {
            offsetY += ele.offsetTop;
            ele = ele.offsetParent;
        };
        return offsetY;
    };

    jumpLatest = (str) => {
        let o = document.querySelector('.site-layout-background');
        let ele = document.querySelector(str);
        if (!ele) {
            return message.info(str == '.recent' && '最近无观看' || '最近没有做题');
        }
        let distance = this.computeOffset(ele);
        distance = distance - 150;
        o.scrollTop = distance;
    };

    render() {
        const { lastRecordTime, lastExerciseTime } = this.props;
        return (
            <div className="xingce-container">
                <BackTop />
                {/** 折线图展示开始 */}
                { this.isSinturer() && <div className="chart-div" id="chart"></div>}
                {/** 折线图展示结束 */}

                <div className="Positioning">
                    <Button className="mr30" type="danger" onClick={() => this.jumpLatest('.recent')}>最近观看课时</Button>
                    <Button type="danger" onClick={() => this.jumpLatest('.recent2')}>最近做题时间</Button>
                </div>

                {
                    (!this.props.classes || !this.props.classes.length) ?
                        <div className="emptyWorkContainer0346">
                            一片空白~
                    </div>
                        :
                        <div className="exercise-container">
                            {
                                this.props.classes && this.props.classes.map((dayExerciseArr, idx) => (
                                    <div key={idx}>
                                        <div className="singleModule">
                                            <span className="mr30">第{dayExerciseArr.number}天</span>
                                            <span>{dayExerciseArr.date || ''}</span>
                                        </div>
                                        {
                                            dayExerciseArr.classes && dayExerciseArr.classes.map((exerciseObj, index) => (
                                                <Card key={index} style={styles.card} className={[exerciseObj.recordTime && lastRecordTime == exerciseObj.recordTime ? 'outside' : '', exerciseObj.exercise && exerciseObj.exercise.recordTime && lastExerciseTime == exerciseObj.exercise.recordTime ? 'outside' : '']} title={this.renderTitle(exerciseObj, index)}>
                                                    <div className={exerciseObj.recordTime && lastRecordTime == exerciseObj.recordTime ? 'recent' : ''}></div>
                                                    <div className={exerciseObj.exercise && exerciseObj.exercise.recordTime && lastExerciseTime == exerciseObj.exercise.recordTime ? 'recent2' : ''}></div>
                                                    {
                                                        exerciseObj && exerciseObj.exercise && exerciseObj.exercise.questions.length > 0 ?
                                                            exerciseObj.exercise.questions.map((questionObj, answerIndex) => (

                                                                <AnswerCardUnit
                                                                    key={answerIndex}
                                                                    exerciseType = {exerciseObj.exercise.exerciseType}
                                                                    questionId={questionObj.id}
                                                                    questionObj={questionObj}
                                                                    questionIndex={answerIndex + 1}
                                                                />

                                                            ))
                                                            :
                                                            <h5>不存在题目</h5>
                                                    }
                                                    <div className="card-footer">
                                                        {
                                                            typeof exerciseObj.times === 'number' &&
                                                            <div>
                                                                <img alt="img" className="watchImg icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADsElEQVRYR+2Ua2ibZRTH///nTRp7U+zW0u7SJGVeGEwZsyk6xHrpRpm6dRZBURCmCLapU3B+msYPgoKO2kRBvCKiIjrXTSxMnRUGM6lDQZ1KpUuabcz1AiNdXHN5jrxp0r3pkrXZl33J8+19zu33/M85L3GFD69wfZQBygqUFbgsBdb7x1fYoW+E6LUEbaIYSQORioQRPvLs6ulSVntJAO0+sZ2rG28n0lsBPkCiqXgR+U2Eb04j9eE/fdfNLgazKIDHf3wzIO8QXJ1LJiIJAGdInhTBeVDMPDcTvOZCQYkIuDPkde+7FERRgIzMknqDZLeZQCBnCe7Voj4ZmW4+BB91XuLPxfCcjt4G6PsA/RDJVZk4wTEq2Rnsbfm2EEhBgNaByD1k+ovMi0RSGgzEjerdf/Q0zMyrAOHUpsQN5veygxV/E5ScbcPbYleJyBMK+lWANXP38lpwyv38QvB8AJ+o1rrwiyR2E6AIfiLUjmCf85iVfqozvkpTm9JuyN4fVaK2LRuqOmH1Wx8Yc1ZofgDizqwaB9MOR9fRJ1fEc34XAETo8R8/QHLLnLPsCTW6d+FBphdKd6YzdpjkRuu9iBxuGKq9vZDMrf7wU4QEzEdB8EPQ67obnFNsHsATCHdRZC9EYmnikZ+9LfsLJZvYfK4Jhpwyh0JpWWfY7P8lJTUKQFXOorHm+5p/C8Xd4h+7X4GfEqgScnuo1/VVYQDIDIjtxYYmtinWcN5Gs0jaTtv1aZ28SoO/g6DDqK6/+gAnCwG0DUS6BemPSToE6MptR14L2gLhQwDazcETYEeor+Wjgip0xoZB3pFnE/mxfqi2faG/OZBGIvI6Id6sbSjY69pyUQtM4617opXanhwE2JEbGjEqHh/pWRm1Jp68N75StB60DiGV2rr866qTVr+2/rGbROFdkq0CCEVeDnrdL+SK57VgPtAnylMXeYWU5zIQ5v6TuyonXe8P+5iyruFEx+wa2sHl3zhGrWu48b2J2mR85iUIniahzBwiRvdIn/O7hQoV/RG1BcY6RLOfxNosSBSiPqPB/cH65iMXbUdmhcfvUtQPQ6QbZG12/7/Uir0jPe7Thdq5lF/xNkL6ATotrz8L4a/ZAlUgGiloAmmz+EQB9VjI6zLnquhZFMCMXDMw6qgT41GQz+QUKZRRRE4QGNQw9lVPNw9bW1aMYEkA1uB1b0WurdDiNgAntTihmNTgXynhn794m09d6rWX1YJSE5bqX7ICpRZYzL8MUFagrMD/phJ+MCyQll0AAAAASUVORK5CYII="></img>
                                                                {this.isSinturer() && <span className="spanRight">观看时长：{(exerciseObj.times / 60).toFixed(1)}分钟</span>}
                                                            </div>
                                                        }
                                                        {exerciseObj.recordTime ? <span className="spanRight">最近观看时间：{exerciseObj.recordTime}</span> : <span></span>}
                                                        {
                                                            exerciseObj.exercise && exerciseObj.exercise.accuracy && this.isSinturer() ?
                                                                <div>
                                                                    <img alt="img" className="icon correctIcon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAEK0lEQVRYR8WX3U8cVRjGn/fMABaB3SGFakBbujsbG5UmftFiKfWGWk0aP6L1ynihQXYK9xovmmj8A5AdSkwaTfRCE+uFMaAxGspH06ipBmPozi6lkdpa0h2gVeuyM6+ZpYs7y+zuUCSdy3mf8z6/c857vgi3+aPb7A//AP1GVUBCp0Q4xIw2EG8DUxOBl5lwBcBFgEbSLJ/4U2u57LdjvgDqY4kjIO4H0FguMTNsAF+QhDdTr6u/ltOXBLjz/ZltlenMCSJ6qlyiwjgDTMwfphDshdZ4vVj7ogCKnnwasD4g0Nb1mrv0zNOA6Epp4d+88ngCBGJGlyCMENZRI6Upr9gkty30tMwWytYAbBk813SHLX4hQnBDPS9ozIwEWXg41acu5YfWACh6/BSBOv5P89VczCdTWuT5ogDBwcSzgvnkppjfTGoDBxai6mjOwzUCim4kCAhtGID5THZfIHpuTS7GDylNfXQNQPD4zG5hWz9t1JyBsyYH9mO+4YbSmPiMgMOFOW9YVU1/9d77u/N/dQSCevwtAXp7IwCOOSTxhNkdWszmOcay0pgYJaA9Py8z9ZpaeMAFUK8bIwAO3ioAM6Ygi45VcwBKLLmPyP4KQLUbAJ+amnrEBaDEDGfp3e8FwMBHALcSqNUTkHk6U7Xl8aVX70nl4nUD8TZZ0LeF5k6cgQkzqu5zA+jGAgGBQgNmfGzOh1+ua54Lyv/8PQ6iXS4N83Taov3X+9R5lznhGxDVeHaIccHU1B0FAPGrBKp3J8fXqfnwIRwj54BBTb/RUCljAoCa7QkjsWyhPd88MGA8JBGPFjNfGQGeMaOR7GpbLUJFN6YIeMA1V8AiLNFh9oamcv+rh2bvrrLSk2CiNOQ9+Uev8l7yQUj2mNdIumuAx00tkt3s/gOIGcNEeHLNFAAmLNGZDxEcPL9DLGcyqT51Lqe/ae5UvFKukBn4xIyqLxWOwBsEvFukCM2MJLdf626Z9orXDp2/T7Yyk37Ms1NAiJo96mABwEwrwfq5GD0z5i1Q+5IWTuRr6mKJsASeJEJDuZ7n4p4bkROs1414rsC8lxsu20LemztWHXMZPAbCXX7Nwfx9Sos8ltO7zoKgnnhGgD8vmYwxBwt77QpZFnbm9LrMAdg2dy4cjZzyBHB+KrrxHQEHSkEweJaYZBCaffd8Zd2WPo4dTe3Qua0VGXF23cnLkvDFZbt697WjzVfzpZ5XsppYfFcF0YTfqi7nzcwZm8SexWj4x0Jt0Utp7UAyIgtrmEA7yxmUjvMlZulFUwuNe+lKXssD+gVFQnoIwAu3AuFsODYqexaj281i7X09TALHk49Itv0OA13+bsp8iSG9ZkZDX5YD9wWQSxIcmNkuJOsVtnEw72mWYcIfK08znLFJDC+K0Bi6abmcuWsn9CPeDM26RmAzAP4FbyaRMDMP6pAAAAAASUVORK5CYII=" />
                                                                    <span className="spanRight">正确率：{exerciseObj.exercise.accuracy}</span>
                                                                </div>
                                                                :
                                                                <span></span>
                                                        }
                                                        {
                                                            exerciseObj.exercise && (exerciseObj.exercise.times + '') && (exerciseObj.exercise.times + '').match(/[0-9]/) && this.isSinturer() ?
                                                                <div>
                                                                    <img alt="img" className="icon timeIcon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAE00lEQVRYR9WWf2hVZRjHv8977t3W2Nw9t6ZpKPtxzs1A+glLiDDDIqOyiNIkggq67hxnoJlZSWIUKpalu2dZSH8ILaaQIQYihZEEQWUSht5z59ZaKpucszl1V+8954lzttXu7s8NQXr/Opz3eb/P53ne533fh3CdB11n//gfAuwwy8MSLWTi+wmYyYyZAK4AfA5EPQ6Lwxe0hp9BxKVkt+QMVO7smVUhku+B6FkAlYXEmdFPgGFVBjbjxfpkIdviABtZyLWd64n4bQAVnhgzXAJ+ZUIngG6AKwGaTeB7AfIyMjKYz7gk9AFN2Z8PoiBA1Q6zNhjAXgIW+HpAJ5g32AgdgD79YpZoB0vh/s6HGPwqER4Zm3eBDwf6lHXYSOmJa/IDxPqqwhj8BYTISDTYbvUrr+cSyRVdjdG5SMDdTcCckWRwu61HlpcGwExyW+IgAYu9BS7wzoCmbiqlqMbbyLs6a8hx9gG0aDSI1Zaubh9vkzMDoTbzJcHYPRI4b7O1yNqJzmuMxD0SeCszd9h6ZFdeuI4TZeHzZd8DmM+A4zpBdbClrmvMPhvg866K8HD6TwDTGThiNysP5jpSsmFuJmCdVxe2piqFslPd2ntjQAybBMhg7LN09Zm8AHLM1IgQ82jTUmDeULT+ZC5x2YhvJdBaBnfbWqS+2PaM6Xp2KUhzh7SGU953VgZkwzxKwH0ADlma+m8lT3QwWQB4W9FfNgDCDQy8YWvqliyA6l2nbgo4oo98MIpamvJpvsgmDQBANswvCVjKwI+2pnpBZmZgWtvppgA7P/mVT7h7oFk9di0BwrHEGhBvY2DQ1tRQFkCo1VwiBPxb6yrRjIvNSt+1BJDbEsuIud3TtKqqq/DCzZcyakCOxZ8joi98g3JnGl6eO1QMAECSAT9rGYNpn60rreP/hQzzCQF87f1LSsFZl6N1ZzMAQoa5QABHPIO0EJELKxrNfABhI/4uQN77kHsw4pau3jp+Uo7Fo0T0CQNsNyuSd7wzAdq66gSn/UvCEfzw4IrI4Xz6VbH4bWVErQxIE22I+arD0pbBlY3fZgAY5vsErAf4rKVFZuU8hmEjfsZ/0by7X1dX541wChOyEf+NQHcwsN/W1KdyA8TiH4NoFRi9VkBpQJRSU/CVtWQ0Y3/4Ey6WWivVjpwAsnH6doJz3Ddkes3SlQ+uBYAcix8koke9ZsWuDMwZa1RyPkayEW8n0DKAh1JSsCnfdVwqmGyYzxOwZzSoVyxd+WxsbZ7XsKtOuKnfQVQF8N9I03xrldpbqsOMoxdLLCRyDxEoyODjdrN61/jHLW9DIhuJxQB7PQF5aQNJT9taww+TgfA1mL8iQjmDLWJxp6Urf43XKNiShYxECzF/RATh94HEe5JOxZuXW+acKQiy+2S1fEV6C8xriCjgXb1g8ZitNx7NOrLFIvIuJ2LsJUKtX5fgFJi+cQXvcUXwxFAZuqc5gUqRTs0mx5nHhAeIseQ/exyDJJ60o409uXwV74oBeA1FkJIbmFzN28ti0KOg5xli00BfY1uhPrIkgDGHNTu764VILye4jzNR08iznTGSzPwdCAcgSe12tHGwGOykADLEdpjlcrmYQSn3Fpd4OIXguUv9dX3YSG4xpyUX4WSEpmo79QxM1eOEdf8Ags/4MJMaydEAAAAASUVORK5CYII=" />
                                                                    <span className="spanRight">答题时长：{(exerciseObj.exercise.times / 60).toFixed(2) < 1 ? `${exerciseObj.exercise.times}s` : `${(exerciseObj.exercise.times / 60).toFixed(2)}分钟`}</span>
                                                                </div>
                                                                :
                                                                <span></span>
                                                        }
                                                        {
                                                            exerciseObj.exercise && exerciseObj.exercise.recordTime ?
                                                                <span className="spanRight">提交时间：{exerciseObj.exercise.recordTime}</span>
                                                                :
                                                                <span></span>
                                                        }
                                                    </div>
                                                </Card>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        );
    }
}

export default Xingce;