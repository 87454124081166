import { message } from 'antd';
import apiRequest from '../../common/request'
import OSS from 'ali-oss';

const PREFIX = "POSITION_INFO";
export const SET_REDUX_KEY_VALUE = PREFIX + 'SET_REDUX_KEY_VALUE';              // 修改redux中指定键的值

export const REQUEST_UPDATE_QUESTION = "REQUEST_UPDATE_QUESTION";
export const REQUEST_UPDATE_QUESTION_VIDEO = "REQUEST_UPDATE_QUESTION_VIDEO";
export const REQUEST_QUESTION_INFO = "REQUEST_QUESTION_INFO";
export const RECEIVE_QUESTION_INFO = PREFIX + "RECEIVE_QUESTION_INFO";
export const RECEIVE_UPDATE_QUESTION_RESULT = "RECEIVE_UPDATE_QUESTION_RESULT";
export const RECEIVE_UPDATE_QUESTION_VIDEO_RESULT = "RECEIVE_UPDATE_QUESTION_VIDEO_RESULT";
export const UPDATE_QUESTION_REMARKS = "UPDATE_QUESTION_REMARKS";
export const UPDATE_QUESTION_POSITIONDESC = "UPDATE_QUESTION_POSITIONDESC";
export const UPDATE_QUESTION_MAJOR = "UPDATE_QUESTION_MAJOR";
export const UPDATE_QUESTION_MAXYEAR = "UPDATE_QUESTION_MAXYEAR";
export const UPDATE_QUESTION_DEPTCODE = "UPDATE_QUESTION_DEPTCODE";
export const UPDATE_QUESTION_DEPTNAME = "UPDATE_QUESTION_DEPTNAME";
export const UPDATE_QUESTION_NUMBER = "UPDATE_QUESTION_NUMBER";
export const UPDATE_QUESTION_POSITIONCODE = "UPDATE_QUESTION_POSITIONCODE";
export const UPDATE_QUESTION_POSITIONNAME = "UPDATE_QUESTION_POSITIONNAME";
export const UPDATE_POSITION_TAGS = "UPDATE_POSITION_TAGS";
export const UPDATE_QUESTION_DEPTMOBILE = "UPDATE_QUESTION_DEPTMOBILE";
export const UPDATE_QUESTION_SEX = "UPDATE_QUESTION_SEX";
export const UPDATE_QUESTION_NOTICE = "UPDATE_QUESTION_NOTICE";
export const UPDATE_VIDEO_PROGRESS = "UPDATE_VIDEO_PROGRESS";
export const UPDATE_URL = "UPDATE_URL";
export const UPDATE_QUESTION_WORKEDYEAR = "UPDATE_QUESTION_WORKEDYEAR";
export const UPDATE_QUESTION_EDUCATION = "UPDATE_QUESTION_EDUCATION";
export const UPDATE_SIGNED_VIDEO_URL = "QUESTION_INFO_UPDATE_SIGNED_VIDEO_URL";
export const UPDATE_PROVINCE_TYPE="UPDATE_PROVINCE_TYPE";
export const UPDATE_POSITION_TYPE = "UPDATE_POSITION_TYPE";
export const UPDATE_YEARS_TYPE="UPDATE_YEARS_TYPE";
export const ADD_FILE_INPUT = "ADD_FILE_INPUT";

export function update_url(value, idx) {
    return {
        type: UPDATE_URL,
        key: 'name',
        value: value,
        idx: idx
    }
}

export function update_signedVideoUrl(value, idx) {
    return {
        type: UPDATE_SIGNED_VIDEO_URL,
        key: 'path',
        value: value,
        idx: idx
    };
}

export function request_question_info() {
    return {
        type: REQUEST_QUESTION_INFO
    }
}

export function receive_question_info(question) {
    console.log('question', {...question})
    return {
        type: RECEIVE_QUESTION_INFO,
        question
    }
}

function request_update_question() {
    return {
        type: REQUEST_UPDATE_QUESTION
    }
}

function request_update_question_video() {
    return {
        type: REQUEST_UPDATE_QUESTION_VIDEO
    }
}

function receive_update_question_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_RESULT
    }
}

function receive_update_question_video_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_VIDEO_RESULT
    }
}

export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function update_question_major(value) {
    return {
        type: UPDATE_QUESTION_MAJOR,
        key: 'major',
        value
    }
}

export function update_question_maxyear(value) {
    return {
        type: UPDATE_QUESTION_MAXYEAR,
        key: 'maxYear',
        value
    }
}

export function update_question_deptcode(value) {
    return {
        type: UPDATE_QUESTION_DEPTCODE,
        key: 'deptCode',
        value
    }
}

export function update_question_deptname(value) {
    return {
        type: UPDATE_QUESTION_DEPTNAME,
        key: 'deptName',
        value
    }
}

export function update_question_number(value) {
    return {
        type: UPDATE_QUESTION_NUMBER,
        key: 'number',
        value
    }
}

export function update_question_positioncode(value) {
    return {
        type: UPDATE_QUESTION_POSITIONCODE,
        key: 'positionCode',
        value
    }
}

export function update_question_positionname(value) {
    return {
        type: UPDATE_QUESTION_POSITIONNAME,
        key: 'positionName',
        value
    }
}

export function update_position_tags(value) {
    return {
        type: UPDATE_POSITION_TAGS,
        key: 'tags',
        value
    }
}

export function update_question_deptmobile(value) {
    return {
        type: UPDATE_QUESTION_DEPTMOBILE,
        key: 'deptMobile',
        value
    }
}

export function update_question_remarks(value) {
    return {
        type: UPDATE_QUESTION_REMARKS,
        key: 'remarks',
        value
    }
}

export function update_question_positiondesc(value) {
    return {
        type: UPDATE_QUESTION_POSITIONDESC,
        key: 'positionDesc',
        value
    }
}

// LOG: 2019-01-25修改1 之前会对value==2返回另一个action type;但是这个action type所对应的操作又没有任何区别，所以去掉
// LOG: 2019-01-25修改2 解注释；之前这里被注释，导致修改题型报错；
export function update_question_sex(value) {
    return {
        type: UPDATE_QUESTION_SEX,
        key: 'conditionSex',
        value
    }
}

export function update_question_notice(value) {
    return {
        type: UPDATE_QUESTION_NOTICE,
        key: 'notice',
        value
    }
}

export function update_province_type(value) {
    return {
        type: UPDATE_PROVINCE_TYPE,
        key: 'area',
        value
    }
}

export function update_positon_type(value) {
    return {
        type: UPDATE_POSITION_TYPE,
        key: 'type',
        value
    }
}

export function update_years_type(value) {
    return {
        type: UPDATE_YEARS_TYPE,
        key: 'year',
        value
    }
}

export function fetchEmpty(value) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question:{
          quesType: '',
          content: "",
          answer: [],
          quesExplain: "",
          categoryId: "-1",
          options: [{"key": "", "value": ""}, {"key": "", "value": ""}, {"key": "", "value": ""}, {"key": "", "value": ""}],
          // point: "",
          // knowledge: "",
          source: "",
          questionTag: 2, // 默原创
          area: [],
          year: '',
          conditionSex: '',
          files: [{name: '', path: ''}],
        }
    }
}

/** 更新试题的tag类型 */
export function update_question_workedyear (value) {
    return {
        type: UPDATE_QUESTION_WORKEDYEAR,
        key: 'workedYearLevel',
        value
    };
}

export function update_question_education (value) {
    return {
        type: UPDATE_QUESTION_EDUCATION,
        key: 'education',
        value
    };
}

export function addFileInput(value) {
    return {
        type: ADD_FILE_INPUT,
        key: 'files',
        value
    }
}

export function updateQuestionVideo(file, info, idx) {
    let fileType = file.name.slice(file.name.lastIndexOf('.')+1);          // 文件格式
    let fileTitle = file.name.replace(/\//g, '_');  // 文件名称，避免文件名中的'/'符号
    fileTitle = fileTitle.slice(0, fileTitle.lastIndexOf('.')); // 截取文件格式'.'前的文件名称
    fileTitle = fileTitle.substr(0, 200);           // 避免过长
    fileTitle = fileTitle + '-' + Date.now();       // 加时间戳避免文件重名覆盖

    let fileName = `/attachment`;
    if (info.id) {
        fileName += `/${info.id}-${fileTitle}.${fileType}`;
    } else {
        fileName += `/${fileTitle}.${fileType}`;
    }
    return async dispatch => {
        dispatch(request_update_question_video());
        let tokenJson = await apiRequest("/gk/ops/sts/getToken?type=oss",{method: 'GET',credentials: 'include'});
        if (tokenJson.status !== 1) {
            message.warning('获取OSS token时发生错误');
            return;
        }
        let tokenObj = tokenJson.obj;
        let client = new OSS({
            accessKeyId: tokenObj.accessKeyId,
            accessKeySecret: tokenObj.accessKeySecret,
            stsToken: tokenObj.securityToken,
            endpoint: 'https://oss-cn-beijing.aliyuncs.com',
            bucket: 'gk-testresource'
        });
        client.multipartUpload(fileName, file, {
            progress: function (p) {
              return function (done) {
                dispatch(update_video_progress(p * 100 + "%"));
                done();
              };
            },
        }).then(async (result) => {
            dispatch(receive_update_question_video_result());
            // let videoUrl = result.name;
            // if(videoUrl && videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
            // let canPlayUrl = await getAvailableVideoUrl(videoUrl);

            let resultName = result.name;
            resultName = resultName.slice(resultName.lastIndexOf('/')+1);
            let resultPath = null;
            if (result.url) {
                resultPath = result.url;
            } else {
                resultPath = result && result.res && result.res.requestUrls && result.res.requestUrls[0];
                resultPath = resultPath && resultPath.split('?')[0];
            }
            dispatch(update_signedVideoUrl(resultPath, idx));
            dispatch(update_url(resultName, idx));
        }).catch(function (err) {
            dispatch(receive_update_question_video_result());
            message.warning('上传文件到OSS发生错误');
        });
    }
}

export function updateQuestion(questionInfo) {
    let data = {};
    data = Object.assign(questionInfo, data, {})

    let _tags = data?.tags?.toString() || '';
    if (_tags) {
        let _tagArr = _tags.split(/[，,]+/);//(',');
        data.tags = _tagArr;
    }

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_question());
        return apiRequest("/gk/ops/positions/saveOrUpdate", config)
          .then(json => {
            dispatch(receive_update_question_result());
            message.success('操作成功');
            window.history.back();
          })
          .catch(error => {
            dispatch(receive_update_question_result());
            console.log(error)
          });
    }
}

/** 接受key，value，修改redux里面所对应存储的key值，data里面会包含有key,value */
export const setReduxKeyValue = data => ({
    type: SET_REDUX_KEY_VALUE,
    data
});
