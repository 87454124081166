import apiRequest from '../../common/request';
import OSS from 'ali-oss';
import { message } from 'antd';

export const REQUEST_UPDATE_TV = "REQUEST_UPDATE_TV";
export const REQUEST_UPDATE_TV_VIDEO = "REQUEST_UPDATE_TV_VIDEO";
export const REQUEST_TV_INFO = "REQUEST_TV_INFO";
export const RECEIVE_TV_INFO = "RECEIVE_TV_INFO";
export const REQUEST_EMPTY_TV_INFO = "REQUEST_EMPTY_TV_INFO";
export const RECEIVE_UPDATE_TV_RESULT = "RECEIVE_UPDATE_TV_RESULT";
export const RECEIVE_UPDATE_TV_VIDEO_RESULT = "RECEIVE_UPDATE_TV_VIDEO_RESULT";
export const UPDATE_VIDEO_PROGRESS = "UPDATE_VIDEO_PROGRESS";
export const UPDATE_URL = "UPDATE_URL";
export const UPDATE_URL_KEY = "UPDATE_URL_KEY";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_TIMES = "UPDATE_TIMES";
export const UPDATE_RECOMMEND = "UPDATE_RECOMMEND";
export const UPDATE_IMG_URL = "UPDATE_IMG_URL";

export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const RESET_INFO = "RESET_INFO";

export function reset_info() {
    return {
        type: RESET_INFO
    };
}

export function request_tv_info() {
    return {
        type: REQUEST_TV_INFO
    }
}

export function receive_tv_info(info) {
    return {
        type: RECEIVE_TV_INFO,
        info,
        receivedAt: Date.now()
    }
}

function request_update_tv() {
    return {
        type: REQUEST_UPDATE_TV
    }
}

function request_update_tv_video() {
    return {
        type: REQUEST_UPDATE_TV_VIDEO
    }
}

function receive_update_tv_result() {
    return {
        type: RECEIVE_UPDATE_TV_RESULT
    }
}

function receive_update_tv_video_result() {
    return {
        type: RECEIVE_UPDATE_TV_VIDEO_RESULT
    }
}



export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function update_content(value) {
    return {
        type: UPDATE_CONTENT,
        key: 'content',
        value
    }
}

export function update_times(value) {
    return {
        type: UPDATE_TIMES,
        key: 'times',
        value
    }
}

export function update_title(value) {
    return {
        type: UPDATE_TITLE,
        key: 'title',
        value
    }
}

export function update_type(value) {
    return {
        type: UPDATE_TYPE,
        key: 'tag_id',
        value
    }
}

export function update_url_key(value) {
    return {
        type: UPDATE_URL_KEY,
        key: 'key',
        value
    }
}

export function update_url(value) {
    return {
        type: UPDATE_URL,
        key: 'url',
        value
    }
}

export function update_img_url(value) {
    return {
        type: UPDATE_IMG_URL,
        key: 'imgurl',
        value
    }
}

export function update_recommend(value) {
    return {
        type: UPDATE_RECOMMEND,
        key: 'recommend',
        value: value==1?0:1
    }
}

export function fetchEmptyTV(type) {
    return {
        type: REQUEST_EMPTY_TV_INFO,
        value: type || 'v'
    }
}

export function fetchTv(id, type) {
    type = type || 'v'
    return dispatch => {
        dispatch(request_tv_info());
        apiRequest(`/gk/ops/Tv/findById/${type}/${id}`, {credentials: 'include'})
            .then(json => {
                dispatch(receive_tv_info(json.obj))
            });
    }

}

export function updateVideo(file, type){

    return dispatch => {
        dispatch(request_update_tv_video());
        apiRequest("/gk/ops/sts/getToken?type=oss",{credentials: 'include'})
            .then(json => {
                let result = json.obj;
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    endpoint: 'https://oss-cn-beijing.aliyuncs.com',
                    bucket: 'gk-news'
                });
                let date = new Date();
                let url = '/'+date.getFullYear()+date.getMonth()+date.getDate()+date.getHours();

                if (type == 1){
                    url = '/img'+url
                }
                client.multipartUpload(url+'/'+file.name, file, {
                    progress: function (p) {
                      return function (done) {
                        dispatch(update_video_progress(p * 100 + "%"));
                        done();
                      };
                    },
                }).then((result) => {
                    dispatch(receive_update_tv_video_result());
                    let host='https://gk-news.oss-cn-beijing.aliyuncs.com'

                    if(type == 1){
                        dispatch(update_img_url(host + result.name));
                    }else {
                        dispatch(update_url_key(result.name.slice(1)));
                        dispatch(update_url(host + result.name));
                    }
                    
                }).catch(function (err) {
                    dispatch(receive_update_tv_video_result());
                    message.error(err);
                });
            });
    }
}



export function updateTvInfo(data, type) {
    let info = {...data}
    if(info.hasOwnProperty('createTime')) delete info.createTime;
    if(info.hasOwnProperty('tvTag')) delete info.tvTag;
    if(info.hasOwnProperty('subContent')) delete info.subContent;
    if(info.hasOwnProperty('gmtModified')) delete info.gmtModified;
    if(!info.times) delete info.times;
    if(!info.imgurl) delete info.imgurl;

    let config = {
        method: 'POST',
        body: JSON.stringify(info),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_tv());
        apiRequest("/gk/ops/Tv/saveOrUpdate", config)
            .then(() => {
                dispatch(receive_update_tv_result());
                message.success('操作成功');
                window.history.back();
            });
    }
}
