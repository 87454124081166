import apiRequest from '../../common/request'
import { message } from 'antd';
import OSS from 'ali-oss';

export const IMAGE_PICKER_RESET="IMAGE_PICKER_RESET";
export const IMAGE_PICKER_REQUEST_IMAGES = "IMAGE_PICKER_REQUEST_IMAGES";
export const IMAGE_PICKER_RECEIVE_IMAGES = "IMAGE_PICKER_RECEIVE_IMAGES";
export const IMAGE_PICKER_LAST_PAGE = "IMAGE_PICKER_LAST_PAGE";
export const IMAGE_PICKER_NEXT_PAGE = "IMAGE_PICKER_NEXT_PAGE";
export const IMAGE_PICKER_RESET_IMAGE_MARKERS = "IMAGE_PICKER_RESET_IMAGE_MARKERS";
export const IMAGE_PICKER_RECEIVE_CONTENT_OF_IMAGE_DIRECTORY = "IMAGE_PICKER_RECEIVE_CONTENT_OF_IMAGE_DIRECTORY";
export const IMAGE_PICKER_UPLOAD_FINISH = "IMAGE_PICKER_UPLOAD_FINISH";
export const IMAGE_PICKER_START_UPLOAD = "IMAGE_PICKER_START_UPLOAD";

export function image_picker_reset() {
    return {
        type:IMAGE_PICKER_RESET
    }
}

function request_images() {
    return {
        type: IMAGE_PICKER_REQUEST_IMAGES
    }
}

function receive_images(data) {
    return {
        type: IMAGE_PICKER_RECEIVE_IMAGES,
        images: data.content,
        marker: data.marker,
        prefixes: data.prefixes,
        receivedAt: Date.now()
    }
}

function image_picker_last_page_success(marker) {
    return {
        type: IMAGE_PICKER_LAST_PAGE,
        marker
    }
}

function image_picker_next_page_success(marker) {
    return {
        type: IMAGE_PICKER_NEXT_PAGE,
        marker
    }
}

function image_picker_start_upload() {
    return {
        type: IMAGE_PICKER_START_UPLOAD,
    }
}

function image_picker_upload_finish(url) {
    return {
        type: IMAGE_PICKER_UPLOAD_FINISH,
        url
    }
}
export function receive_content_of_image_directory(prefix) {
    return {
        type: IMAGE_PICKER_RECEIVE_CONTENT_OF_IMAGE_DIRECTORY,
        prefix
    }
}

export function fetch_content_of_image_directory(prefix, bucketName) {
    return dispatch => {
        dispatch(fetchImages({prefix, bucketName}));
        dispatch(receive_content_of_image_directory(prefix));
        dispatch(reset_image_markers());
    }
}

export function image_picker_last_page(marker, prefix, bucketName) {
    return dispatch => {
        dispatch(fetchImages({marker, prefix, bucketName}));
        dispatch(image_picker_last_page_success(marker));
    }
}

export function image_picker_next_page(marker, prefix, bucketName) {
    return dispatch => {
        dispatch(fetchImages({marker, prefix, bucketName}));
        dispatch(image_picker_next_page_success(marker));
    }
}

export function reset_image_markers() {
    return {
        type: IMAGE_PICKER_RESET_IMAGE_MARKERS
    }
}

export function fetchImages(data = {}, picOrAudio) {
    if (data.prefix == "" && data.bucketName != "gk-resource") data.prefix = "img/";
    if (picOrAudio == 1) {
        data.prefix = "audio/";
    }
    let config = {
        method: 'POST',
        body: JSON.stringify({ prefix:"img/", ...data, delimiter: "/" }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return dispatch => {
        dispatch(request_images());
        return apiRequest(`/gk/ops/media/page`, config)
          .then(json => {
            dispatch(receive_images(json.obj));
          })
          .catch(error => console.log(error));
    }
}

/** bucket: banner和课程上传有专门的bucket， 只有试题类型的bucket是gk-questions，其他全是gk-resource
 *  name: 区分banner， course， 试题默认/img/时间戳, 非试题放在/background/${imgPath}/${Date.now()}.png下面
 *  https://gk-questions.oss-cn-shanghai.aliyuncs.com/img/2020020715/01c63012-f7ce-44f6-a576-a15846059f40
 */
export function uploadOSSImage(file, bucket, imgPath) {
    let endpoint = 'https://oss-cn-hangzhou.aliyuncs.com';
    let fileName = `/img/${Date.now()}.png`;
    if(imgPath) fileName=`/${imgPath}/${Date.now()}.png`;
    return dispatch => {
        dispatch(image_picker_start_upload());
        apiRequest('/gk/ops/sts/getToken?type=oss').then((tokenJson)=>{
            if(tokenJson.status == 1) {
                let result = tokenJson.obj;
                let client = new OSS({
                    endpoint,
                    stsToken: result.securityToken,
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    cname: false,
                    bucket: 'jd-questions'
                });
                client.multipartUpload(fileName, file).then((result)=>{
                    let res = result.res;
                    if(res.status == 200) {
                        message.success('上传成功');
                        // 这里为什么用split截取一下？因为图片太大上传返回的链接会拼参数，有效的图片访问链接是没有参数的
                        // 大图片返回的requestUrls: https://gk-resource.oss-cn-beijing.aliyuncs.com/background/goods/1641691243994.png?uploadId=7E3B570421404B92BB9A24ED97D906F3
                        // 小图片返回的requestUrls: https://gk-resource.oss-cn-beijing.aliyuncs.com/background/goods/1641691243994.png
                        let url = res.requestUrls[0].split('?')[0];
                        dispatch(image_picker_upload_finish(url));
                    }
                }).catch(error => {
                    message.error('上传失败');
                    console.log(error)
                  });
            }
        });
    }
}

export function imagePickerUploadImage(file, bucketType) {
    let formData = new FormData();
    formData.append('name', 'time');
    formData.append('file', file);
    let config = {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': '*/*',
        },
        credentials: 'include'
    };
    let url = '/gk/ops/upload/image';
    if (bucketType == 1) {
        url = '/gk/ops/upload/audio';
    }
    return dispatch => {
        dispatch(image_picker_start_upload());
        return apiRequest(url, config)
          .then(json => {
            dispatch(image_picker_upload_finish(json.obj));
            dispatch(fetchImages({}, bucketType));
            message.success('上传成功');
          })
          .catch(error => {
            message.success('上传失败');
            dispatch(fetchImages({}, bucketType));
            console.log(error)
          });
    }
}
