import React from 'react';
import { connect } from 'react-redux';
import { setExerciseList, fetchInterviewExercise, searchClassTests, setValue, updateClassTests } from './action';
import { reset_all_data } from "../InterviewClassTest/action";
// import { resetSearchStatus } from '../../components/InterviewSelectCard/actions';
import { Tooltip,Checkbox, Table, List, Button, Input, message } from "antd";
import { getSearchQuery, checkPermission } from '../../utils/utils';
import { Link } from 'react-router-dom';

import './index.scss';

class InterviewClassExercise extends React.Component {

    async componentDidMount() {
        await this.props.dispatch(setValue('searchPage', 1));
        await this.props.dispatch(setValue('searchPageSize', 10));
        this.fetchExerciseListHandler();
        this.getSearchData();
    };

    // 搜索班级练习列表数据
    getSearchData = () => {
        let obj = {};
        this.props.searchPage && (obj.page = this.props.searchPage);
        this.props.searchPageSize && (obj.pageSize = this.props.searchPageSize);
        this.props.title && (obj.title = this.props.title);
        obj.exerciseType = 2;
        obj.classId = getSearchQuery('classId');
        this.props.dispatch(searchClassTests(obj));
    }

    // 获取班级练习列表数据
    fetchExerciseListHandler = () => {
        let params = {
            page: this.props.activePage,
            pageSize: this.props.pageSize,
            id: getSearchQuery('classId'),
            type: 2,                            //面试类型
        };
        this.props.dispatch(fetchInterviewExercise(params));
    }

    // 跳转到试题列表页面
    gotoInterviewClassExercise = async () => {
        await this.props.dispatch(reset_all_data());
        // await this.props.dispatch(resetSearchStatus());
        let classId = getSearchQuery('classId');
        let classType = 2;
        this.props.history.push(`/interviewClassTest?classId=${classId}&type=${classType}`);
    };

    /** 判断某一个班级测试是否被勾选上 */
    checkTestIsChecked = id => {
        let choosedClassTests = this.props.exerciseList;
        return choosedClassTests.find(obj => obj.id === id);
    }

    handleSelect = async page => {
        await this.props.dispatch(setValue('searchPage', page));
        this.getSearchData();
    };

    /** 改变每页显示大小 */
    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setValue('searchPage', 1));
        await this.props.dispatch(setValue('searchPageSize', pageSize));
        this.getSearchData();
    }

    /** 搜索更改状态 */
    searchExamine = async () => {
        await this.props.dispatch(setValue('searchPage', 1));
        this.getSearchData();
    }

    /** 清空搜索 */
    emptySearch = async () => {
        await this.props.dispatch(setValue('title', ''))
        await this.props.dispatch(setValue('searchPage', 1));
        this.getSearchData();
    }

    /** 更新 */
    reorderHandler = () => {
        let exerciseList = this.props.exerciseList;
        let classId = getSearchQuery('classId');
        let type = 2;
        if (Object.prototype.toString.call(exerciseList) !== "[object Array]") return;
        let exerciseDates = exerciseList.map(obj => {
            return {
                "examineId": obj.id,
                "publishDate": obj.publishDate
            }
        })
        this.props.dispatch(updateClassTests({ classId, exerciseDates, type }));
    }

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };

    /** 输入练习的序号, 参数fromIndex表示的意思是原先顺序 */
    inputOrderHandler = async (e, fromIndex) => {
        if (e.keyCode == 13) {
            let toIndex = parseInt(e.target.value) - 1;
            e.target.value = '';
            let items = [...this.props.exerciseList || []];
            items = items.slice(0);
            if (toIndex > (items.length - 1)) {
                message.warning('不能超过当前练习数量');
                return;
            }
            if (toIndex < 0) {
                message.warning('不能小于1');
                return;
            }
            let target = items.splice(fromIndex, 1)[0];         // 当前索引对对应的练习
            items.splice(toIndex, 0, target);                   // 往目标位置插入该练习
            await this.props.dispatch(setExerciseList(items));
        }
    };

    /** 删除数组里面的某一项 */
    deleteArr = (record, key, value) => {
        let result = [];
        for (let i = 0, len = record.length; i < len; i++) {
            let itemValue = record[i][key];
            if (itemValue != value) result.push(record[i]);
        }
        return result;
    };

    /** 勾选或者反选班级测试 */
    addOrDeleteClassTests = record => {
        let choosedClassTests = [...this.props.exerciseList || []];
        let isChoosed = choosedClassTests.find(obj => obj.id === record.id);
        if (isChoosed) {                      // 针对已选的做反选处理
            choosedClassTests = this.deleteArr(choosedClassTests, 'id', record.id);
        } else {                              // 针对未选的做勾选处理
            choosedClassTests.push(record);
        }
        this.props.dispatch(setExerciseList(choosedClassTests));
    };

    /** antd table 的数据格式约定配置 */
    generateColumns(type) {
        const columns = [
            {
                title: '选择', width: '5%', render: (text, record) => <Tooltip title="操作完后记得点击顶部更新按钮">
                    {type && 
                    <Checkbox checked={true} onChange={() => { this.addOrDeleteClassTests(record) }}></Checkbox>
                    || <Checkbox checked={this.checkTestIsChecked(record.id)} onChange={() => { this.addOrDeleteClassTests(record) }}></Checkbox>
                    }
                </Tooltip>
            },
            {
                title: '标题',
                dataIndex: 'title',
                width: '5%',
                render: (text, record) => {
                    return (
                        <div>
                            {checkPermission('clazz:exercise:gkinfo') && <Link to={`/interviewClassTest?id=${record.id}&type=2`}>{record.title}</Link> || <div>{record.title}</div>}
                        </div>
                    )
                }
            },
            {
                title: '试题',
                width: '40%',
                render: (text, record) => {
                    return (
                        <div>
                            <List
                                bordered
                                dataSource={record.interviewQuestions}
                                renderItem={item => (
                                    <List.Item>
                                        <Tooltip title={this.getTooltip(item.question)}>
                                            <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                        </Tooltip>
                                        {type==1 && checkPermission('clazz:exercise:gkstu') && <Link to={`/interviewStudentList?classId=${getSearchQuery('classId')}&exerciseId=${record.id}&title=${record.title}&id=${item.id}`}><Button type="link">全部学员详情</Button></Link>}
                                    </List.Item>
                                )}
                            />
                        </div>
                    )
                }
            },
        ];
        if (type) {
            let uniq = {
                title: '序号', width: '5%',
                render: (text, record, index) => <span>{index + 1}</span>
            };
            columns.splice(1, 0, uniq);
        }
        if (type) {
            let uniq = { title: '移动', width: '5%', render: (text, record, index) => checkPermission('clazz:exercise:gkxh') && <input type="number" min="0" max={this.props.originExerciseList.length} className="num" onKeyUp={event => { this.inputOrderHandler(event, index) }} placeholder="请输入序号" /> };
            columns.splice(2, 0, uniq);
        }
        return columns;
    }


    render() {
        const { searchedExerciseList, exerciseList, searchTotalElements, isFetching } = this.props;

        return (
            <div className="interviewClassPage">
                <main>
                    <div className="mb10 flexRow">
                        {checkPermission('clazz:exercise:gkadd') && <Button className="mb10" type="primary" onClick={this.gotoInterviewClassExercise}>创建班级练习</Button>}
                        {checkPermission('gkview:question:edit') && <Button onClick={this.reorderHandler} className="position" type="primary">更新</Button>}
                    </div>

                    <div style={{background: '#fff', padding: '10px', color: 'red', fontSize: '16px'}}>特别注意：全部学员详情只对应当前班，只能批改已更新已勾选的训练题，未勾选未更新的不要去间接批改</div>

                    <Table
                        columns={this.generateColumns(1)}
                        dataSource={exerciseList}
                        rowKey={record => record.id}
                        title={() => '已选列表'}
                        loading={isFetching}
                        pagination={false}
                    />
                    {checkPermission('clazz:exercise:page') && <div className="mtb20">
                        <Input onPressEnter={() => this.searchExamine()} onChange={event => { this.props.dispatch(setValue('title', event.target.value)); }} value={this.props.title} type="text" placeholder="请输入班级测试的标题" className="input600" />
                        <Button onClick={this.searchExamine} type="primary">搜索</Button>
                        <Button onClick={this.emptySearch} type="danger" className="ml45">清空搜索</Button>
                    </div>}

                    <Table
                        columns={this.generateColumns(0)}
                        dataSource={searchedExerciseList}
                        rowKey={record => record.id}
                        title={() => '练习列表'}
                        loading={isFetching}
                        pagination={{
                            total: searchTotalElements,
                            pageSize: this.props.searchPageSize,
                            current: this.props.searchPage,
                            onChange: this.handleSelect,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onShowSizeChange: this.onShowSizeChange,
                            showTotal: (total) => `共有${total}条数据`
                        }}
                    />
                </main>
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        searchedExerciseList,
        searchTotalElements,
        exerciseList,
        originExerciseList,
        isFetching,
        searchPage,
        searchPageSize,
        title,
    } = state.interviewClassExercise;
    return {
        searchedExerciseList,
        searchTotalElements,
        exerciseList,
        originExerciseList,
        isFetching,
        searchPage,
        searchPageSize,
        title
    };
}

export default connect(mapStatetoProps)(InterviewClassExercise);