import React from "react";
import {connect} from "react-redux";
import {Button, Input, Select, Radio, message, Pagination, Tag} from "antd";
import {
    getInstitutionDistrict,
    fetchInfo,
    getOfficeByProvince,
    getProvinceAndType,
    getPositionDetailById,
    getPositionDetailByIdPro,
    saveOrUpdateStudent,
    getYearsAndProvincesByType,
    getProvinceOffice,
    getOfficeByPerson,
    getCityAndType,
    checkJob
} from "./actions";

import { checkPermission } from '../../utils/utils';
import { fetchClassList } from '../InterviewClass/actions'
import { fetchQuestionAreas, fetchQuestionYears } from '../QuestionList/actions'
import StudentForm from './form.js'
import PositionDetail from './positionDetail.js'
import {WORK_YEAR_LIST, EDUCATION_LIST, EMPTY_INFO, selectStyle, POLITICAL_STATUS_LIST} from  './const.js'

const Option = Select.Option;
class IStudentInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: '2',              // 只是标识tab栏
            finalType: '2',         // 最终职位所代表的类型
            ...EMPTY_INFO           // 职位类型的所有状态：国考，省考，事业单位
        }
    }

    async componentDidMount() {
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchClassList({ page: 1, pageSize: 1000 }));
          // 修改时
        if (this.props.match.params.id ) {
          let info = await fetchInfo(this.props.match.params.id)
          let years = await getYearsAndProvincesByType(parseInt(info.type) + 1) || []
          await this.setState({
            years: years.reverse(),
            info,
            job: info.job,
            type: info.type+'',
            finalType: info.type+'',
            showForm: true
          })
          info.type == 1 && this.resetType1();
          info.type == 2 && this.resetType2();
          info.type == 3 && this.resetType3();

        }else {   //新建时
          this.resetType2()
          this.setState({ showForm: true});
        }
    }

    resetType1 = async () => {
      let years = await getYearsAndProvincesByType(2) || []
      years = years.reverse()
      await this.setState({years})
      if (years[0]) {
        await this.countryYearHandler(years[0].year)
      }
    }

    resetType2 = async () => {
      let years = await getYearsAndProvincesByType(3) || []
      years = years.reverse()
      await this.setState({years})
      if (years[0]) {
        let provinceList = await this.provinceYearHandler(years[0].year);
        if (provinceList[0]) {
          await this.provinceAreaHandler(provinceList[0])
        }
      }
    }

    resetType3 = async () => {
      let years = await getYearsAndProvincesByType(4) || []
      years = years.reverse();
      await this.setState({years});
      if (years[0]) {
        let institutionList = await this.institutionYearHandler(years[0].year);
        institutionList[0] && await this.institutionAreaHandler(institutionList[0]);
      }
    }

    // async componentWillReceiveProps(nextProps, beforeProps) {
    //     if(this.props.match.params.id && !nextProps.params.id){
    //         this.setState({info: null, showForm: true})
    //     }
    // }

    changeType = async (val) => {
      if(val==1) this.resetType1();
      if(val==2) this.resetType2();
      if(val==3) this.resetType3();
      this.setState({
        ...EMPTY_INFO,
        type: val,
        info: this.state.info,
        showForm: true,
        job: this.state.job
      })
    }

    countryHandler = (val, key) => {
      let { country } = this.state
      country[key] = val
      this.setState({
        country
      })
    }

    provinceHandler = (val, key) => {
      let { province } = this.state
      province[key] = val
      this.setState({
        province
      })
    }

    /** 事业单位通用改变回调， 城市改变回调单独做区分*/
    institutionCityHandler = async (val, key, type) => {
      let { institution } = this.state;
      // 城市改变时，报考区县要重置为空，并且请求城市下面定义的区县
      if(type=='city') {
        institution.district = '';
        let districtList = await getInstitutionDistrict(institution.year, institution.province, val);
        this.setState({
          districtList
        })        
      }
      institution[key] = val;
      this.setState({
        institution
      })
    }

    /** 事业单位年份改变回调,返回相对于省份数组 */
    institutionYearHandler = async (val) => {
      let {institution, years} = this.state;
      let institutionList = []
      // 获取相对于年份下面的数组对象，包括省份数组，年份
      let obj = years.filter(i=>i.year == val)[0] || {};
      if (val) {// 拿到年份对应的省份数组
        institutionList = obj.provinces || [];
      }
      institution = {
        ...institution,
        year: obj.year || '',
        province: '',
        city: '',
        district: '',
      }
      this.setState({
        institutionList,
        institution
      })
      return institutionList;
    }

    provinceYearHandler = async (val) => {
      let {province, years} = this.state;
      let provinceList = []
      let obj = years.filter(i=>i.year == val)[0] || {}
      if (val) {
         provinceList = obj.provinces || []
      }

      province = {
        ...province,
        year: obj.year || '',
        type: '',
        province: '',
        city: ''
      }
      this.setState({
        provinceList,
        province
      })
      return provinceList;
    }

    countryYearHandler = async (val) => {
      let provinceList = []
      let {country}=this.state
      if (val){
        provinceList = await getProvinceAndType(val)
      }
      this.setState({
        provinceList,
        positionDetail: null,
        country: {
          ...country,
          year: val,
          type: '',
          province: '',
          office: '',
          position: '',
        }
      })
      return provinceList;
    }

    /** 事业单位省份改变回调 */
    institutionAreaHandler = async (val) => {
      let {institution} = this.state;
      institution = {
        ...institution,
        type: '',
        province: val, // 实时改变省份值
        city: ''
      }
      // 获取省下面的城市cityList
      if (val) {
        let {cities, types} = await getCityAndType(this.state.institution.year, val, 3)
        this.setState({
          positionTypeList: types,
          cityList: cities,
          institution,
        })
      }else {
        this.setState({
          positionTypeList: [],
          cityList: [],
          institution,
        })
      }
    }

    /** 省考省份改变回调 */
    provinceAreaHandler = async (val) => {
      let {province} = this.state;
      province = {
        ...province,
        type: '',
        province: val,
        city: ''
      }
      if (val) {
        let {cities, types} = await getCityAndType(this.state.province.year, val)
        this.setState({
          positionTypeList: types,
          cityList: cities,
          province,
        })
      }else {
        this.setState({
          positionTypeList: [],
          cityList: [],
          province,
        })
      }
    }

    countryAreaHandler = async (val) => {
      let positionTypeList = []
      let {country}=this.state
      let province = ''
      if (val) {
        let obj = this.state.provinceList.filter(i=>i.province==val)[0]
        province = obj.province
        positionTypeList = obj.type
      }
      await this.setState({
        positionTypeList,
        showSearchResult:false,
        positionDetail: null,
        country: {
          ...country,
          year: this.state.country.year,
          province,
          type: '',
          office: '',
          position: '',
        }
      })
      return positionTypeList;
    }

    countryTypeHandler = async val => {
      let country = {...this.state.country}
      let {type} = country
      type = val
      // let officeList = []
      // if (val) {
      //   officeList = await getOfficeByPerson(country) || []
      //   console.log('officeLIst', officeList);
      // }
      this.setState({
        positionList: [],
        // officeList,
        showSearchResult:false,
        country: {
          ...country,
          type,
        }
      })
    }

    countryOfficeHandler = async val => {
      let obj = this.state.officeList.filter(i=>i.office == val)[0]

      let {year, province, type, office} = this.state.country
      office = obj.office || ''
      let positionList = obj.positions || []
      this.setState({
        positionList,
        showSearchResult:false,
        country: {
          year, province, type,office,
          position: '',
        }
      })
    }

    countryPositionHandler = async val => {

      let obj = this.state.positionList.filter(i=>i.id == val)[0] || {}

      let {year, province, type, office, position} = this.state.country
      this.setState({
        showSearchResult:false,
        country: {
          year, province, type,office,
          position: obj.position || '',
          positionId: obj.id,
          positionNumber: obj.number
        }
      })
    }

    searchPosition = async () => {
      // 国考 office：招录机关
      let {country, province, institution, type} = this.state;
      if((type==='1' && country.province=='') || (type==='2' && province.province=='') || (type==='3' && institution.province=='')){
        return message.warn('请选择报考省份!');
      }
      if(type==='1'){ // 国考
        let prevCountry = this.state.country;
        prevCountry['page'] = 1;
        await this.setState({ country: prevCountry });
        let country = {...this.state.country}
        let List = await getOfficeByPerson(country) || []
        let officeList = List.content
        this.setState({
          showSearchResult: true,
          officeList,
          officeListSize: List.totalElements,
          officeTotalPages: List.totalPages,
                  
        })
      } else if(type==='2') { // 省考
        let prevProvince = this.state.province;
        prevProvince['page'] = 1;
        await this.setState({ province: prevProvince });
        let officeInfo = await getProvinceOffice(this.state.province);
        this.setState({
          officeList: officeInfo.content,
          officeListSize: officeInfo.totalElements,
          officeTotalPages: officeInfo.totalPages,
        })
      } else  if(type==='3') { // 事业单位
        let institution = this.state.institution;
        institution['page'] = 1;
        let officeInfo = await getProvinceOffice(institution, 3);
        this.setState({
          officeList: officeInfo.content,
          officeListSize: officeInfo.totalElements,
          officeTotalPages: officeInfo.totalPages,
        })
      }
    }

    selectPosition = async id => {
      let positionDetail = await this.getJobById(id)
      this.setState({ positionDetail })
    }

    saveOrUpdateStudent = form =>{
      if (!this.state.job) {
        message.error('请选择职位')
        return ;
      }
      let jobId = this.state.job.id
      let type = this.state.finalType
      let id = this.props.match.params.id || null
      this.props.dispatch(saveOrUpdateStudent({
        ...form,
        jobId,
        type,
        id
      }))
    }

    getJobById = async id => {
      let type = this.state.type;
      if (type === '1') return await getPositionDetailById(id);
      if (type === '2') return await getPositionDetailByIdPro(id);
      if (type === '3') return await getPositionDetailByIdPro(id, 3);
    }

    positionConfirm = async e => {
      this.setState({ finalType: this.state.type });
      if (this.state.job && this.state.positionDetail && this.state.job.id == this.state.positionDetail.id) {
        message.error('与当前职位一致，不需校验')
        return ;
      }
      let result = await checkJob(this.state.positionDetail.id, this.state.type)

      if (result.status == 1) {
        let job = {...this.state.positionDetail}
        this.setState({ job })
      }
    }

    areaHandler = async (year, area) => {
      this.props.dispatch(fetchClassList({
          page: 1,
          pageSize: 1000,
          year: year || null,
          area: area || null
      }));
    }

    pageOfficeList = async page => {
      this.provinceHandler(page, 'page')
      let officeInfo = await getProvinceOffice({...this.state.province, page})
      this.setState({
        officeList: officeInfo.content,
      })
    }

    /** 事业单位分页查询 */
    pageInstitution = async page => {
      this.institutionCityHandler(page, 'page');
      let officeInfo = await getProvinceOffice({...this.state.institution, page}, 3)
      this.setState({
        officeList: officeInfo.content,
      })
    }

    pageCountry = async page => {
      this.countryHandler(page, 'page')
      let Info = await getOfficeByPerson({...this.state.country, page})
      let officeInfo = Info.content
      this.setState({
        officeList: officeInfo,
      })
    }

    changePage = (page) => {
      let type = this.state.type;
      if (type === '1')  this.pageCountry(page);
      if (type === '2')  this.pageOfficeList(page);
      if (type === '3')  this.pageInstitution(page);
    }

    /** 根据学员的classId去确定地区 */
    findAreaByClassId = () => {
      let id = this.state.info && this.state.info.classId;        // 班级ID
      let classList = this.props.classList || [];
      let area = (classList.find(obj => obj.id === id) || {}).area;
      return area;
    }

    /** 判断省考还是国考，国考返回true */
    isCountry = () => {
      return this.state.type == 1;
    }

    /** 职位详情里面去哪个字段 */
    getPositions = item => {
      let type = this.state.type;
      if (type === '1')  return item['positions'];
      if (type === '2')  return item['gkProvinceJobDeptPositions'];
      if (type === '3')  return item['gkInstitutionJobPositionInfoVOList'];
    }

    /** 判断省考招录机关还是国考招录机关，国考返回true */
    getTitle = item => {
      let type = this.state.type;
      if (type === '1')  return item['office'];
      if (type === '2')  return item['deptName'];
      if (type === '3')  return item['deptName'];
    }

    /** 判断省考职位还是国考职位，国考返回true */
    getName = item => {
      let type = this.state.type;
      if (type === '1')  return item['position'];
      if (type === '2')  return item['positionName'];
      if (type === '3')  return item['positionName'];
    }

    /** 判断省考当前页还是国考当前页，国考返回true */
    getCurrent = () => {
      const {country, province, institution, type} = this.state;
      if (type === '1')  return country;
      if (type === '2')  return province;
      if (type === '3')  return institution;
    }

    render() {
        const {officeListSize, districtList, institution, institutionList, country, province, provinceList, positionTypeList, officeList, positionList, positionDetail, years, cityList, showForm, info} = this.state;
        return (
            <div className="">
                <div style={{ width: '100%'}} className="classType">
                    <div className="roundCorner" style={{backgroundColor: 'lightgray', padding: '5px'}}>
                      <span style={{color: 'rgba(0, 0, 0, .85)', fontWeight: 'bold'}}>考试类型</span>
                      <Radio.Group style={{'margin': '0 10px'}} value={this.state.type} onChange={e=>this.changeType(e.target.value)} buttonStyle="solid">
                        <Radio.Button value="1">国考</Radio.Button>
                        <Radio.Button value="2">省考</Radio.Button>
                        <Radio.Button value="3">事业单位</Radio.Button>
                      </Radio.Group>
                    </div>
                    {
                      this.state.type==1 && <div className='white-panel' style={{'display': 'flex'}}>
                          <div className="margin5">
                              <label>报考年份</label>
                              <Select
                                  value={country.year+''}
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.countryYearHandler(value)}
                              >
                                  {years.map((item, idx) => {
                                      return <Option key={idx} value={item.year+''}>{item.year}年</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考地区</label>
                              <Select
                                  showSearch
                                  style={selectStyle}
                                  value={country.province}
                                  disabled={!country.year}
                                  className="selectClass"
                                  placeholder="请选择"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.countryAreaHandler(value)}
                              >
                                  {provinceList.map((item, idx) => {
                                      return <Option key={idx} value={item.province}>{item.province}</Option>
                                  })}
                              </Select>
                          </div>                        
                          
                          <div className="margin5">
                              <label>工作经验</label>
                              <Select
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.countryHandler(value, 'workedYear') }}
                              >
                                  {
                                    WORK_YEAR_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>政治面貌</label>
                              <Select
                                  // value={province.politicalStatus}
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.countryHandler(value, 'politicalStatus') }}
                              >
                                  {
                                    POLITICAL_STATUS_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>学历</label>
                              <Select
                                  // value={province.education}
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.countryHandler(value, 'education') }}
                              >
                                  {
                                    EDUCATION_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>系统类型</label>
                              <Select
                                  value={country.type+''}
                                  className="selectClass"
                                  style={selectStyle}
                                  placeholder="请选择"
                                  disabled={!country.province}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.countryTypeHandler(value) }}
                              >
                                  {positionTypeList.map((type, idx) => {
                                      return <Option key={idx} value={type+''}>{type}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>单位名称</label>
                              <Input style={selectStyle} value={country.office} type="text" onChange={e=>this.countryHandler(e.target.value, 'office')} />
                          </div>

                          <div className="margin5">
                              <label>用人司局</label>
                              <Input style={selectStyle} value={country.bureau} type="text" onChange={e=>this.countryHandler(e.target.value, 'bureau')} />
                          </div>

                          <div className="margin5">
                              <label>职位名称</label>
                              <Input style={selectStyle} value={country.position} type="text" onChange={e=>this.countryHandler(e.target.value, 'position')} />
                          </div>
                          
                          <div className="margin5">
                              <label>单位代码</label>
                              <Input style={selectStyle} value={country.deptCode} type="text" onChange={e=>this.countryHandler(e.target.value, 'deptCode')} />
                          </div>
                          <div className="margin5">
                              <label>职位代码</label>
                              <Input style={selectStyle} value={country.postCode} type="text" onChange={e=>this.countryHandler(e.target.value, 'postCode')} />
                          </div>                          
                          
                          <div className="margin5">
                              <label>地市</label>
                              <Input style={selectStyle} value={country.city} type="text" onChange={e=>this.countryHandler(e.target.value, 'city')} />
                          </div>
                      </div>
                    }
                    {
                      this.state.type==2 && <div className='white-panel' style={{'display': 'flex'}}>
                          <div className="margin5">
                              <label>报考年份</label>
                              <Select
                                  value={province.year+''}
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.provinceYearHandler(value)}
                              >
                                  {years.map((item, idx) => {
                                      return <Option key={idx} value={item.year}>{item.year}年</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考省份</label>
                              <Select
                                  style={selectStyle}
                                  value={province.province}
                                  disabled={!province.year}
                                  className="selectClass"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.provinceAreaHandler(value)}
                              >
                                  {provinceList.map((item, idx) => {
                                      return <Option key={idx} value={item}>{item}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考地市</label>
                              <Select
                                  style={selectStyle}
                                  value={province.city}
                                  disabled={!province.province}
                                  className="selectClass"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.provinceHandler(value, 'city')}
                              >
                                  <Option value="">全部</Option>
                                  {cityList.map((item, idx) => {
                                      return <Option key={idx} value={item}>{item}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>系统类型</label>
                              <Select
                                  value={province.type+''}
                                  className="selectClass"
                                  style={selectStyle}
                                  disabled={!province.province}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.provinceHandler(value, 'type') }}
                              >
                                  <Option value="">全部</Option>
                                  { positionTypeList.map((type, idx) => {
                                      return <Option key={idx} value={type+''}>{type}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>学历</label>
                              <Select
                                  // value={province.education}
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.provinceHandler(value, 'education') }}
                              >
                                  {
                                    EDUCATION_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>
                          <div className="margin5">
                              <label>工作年限</label>
                              <Select
                                  // value={province.workedYear}
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.provinceHandler(value, 'workedYear') }}
                              >
                                  {
                                    WORK_YEAR_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>政治面貌</label>
                              <Select
                                  // value={province.politicalStatus}
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.provinceHandler(value, 'politicalStatus') }}
                              >
                                  {
                                    POLITICAL_STATUS_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>单位名称</label>
                              <Input style={selectStyle} value={province.deptName} type="text" onChange={e=>this.provinceHandler(e.target.value, 'deptName')} />
                          </div>

                          <div className="margin5">
                              <label>职位名称</label>
                              <Input style={selectStyle} value={province.positionName} type="text" onChange={e=>this.provinceHandler(e.target.value, 'positionName')} />
                          </div>
                          <div className="margin5">
                              <label>专业</label>
                              <Input style={selectStyle} value={province.major} type="text" onChange={e=>this.provinceHandler(e.target.value, 'major')} />
                          </div>
                          <div className="margin5">
                              <label>单位代码</label>
                              <Input style={selectStyle} value={province.deptCode} type="text" onChange={e=>this.provinceHandler(e.target.value, 'deptCode')} />
                          </div>
                          <div className="margin5">
                              <label>职位代码</label>
                              <Input style={selectStyle} value={province.positionCode} type="text" onChange={e=>this.provinceHandler(e.target.value, 'positionCode')} />
                          </div>
                          <div className="margin5">
                              <label>区</label>
                              <Input style={selectStyle} value={province.district} type="text" onChange={e=>this.provinceHandler(e.target.value, 'district')} />
                          </div>
                      </div>
                    }
                    {
                      this.state.type==3 && <div className='white-panel' style={{'display': 'flex'}}>
                          <div className="margin5">
                              <label>报考年份</label>
                              <Select
                                  value={institution.year+''}
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.institutionYearHandler(value)}
                              >
                                  {years.map((item, idx) => {
                                      return <Option key={idx} value={item.year}>{item.year}年</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考省份</label>
                              <Select
                                  style={selectStyle}
                                  value={institution.province}
                                  disabled={!institution.year}
                                  className="selectClass"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.institutionAreaHandler(value)}
                              >
                                  {institutionList.map((item, idx) => {
                                      return <Option key={idx} value={item}>{item}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考地市</label>
                              <Select
                                  style={selectStyle}
                                  value={institution.city}
                                  disabled={!institution.province}
                                  className="selectClass"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.institutionCityHandler(value, 'city', 'city')}
                              >
                                  <Option value="">全部</Option>
                                  {cityList.map((item, idx) => {
                                      return <Option key={idx} value={item}>{item}</Option>
                                  })}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>报考区县</label>
                              <Select
                                  style={selectStyle}
                                  value={institution.district}
                                  disabled={!institution.city}
                                  className="selectClass"
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => this.institutionCityHandler(value, 'district')}
                              >
                                  <Option key='-1' value="">全部</Option>
                                  {districtList.map((item, idx) =>(
                                      <Option key={idx} value={item}>{item}</Option>
                                  ))}
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>学历</label>
                              <Select
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.institutionCityHandler(value, 'education') }}
                              >
                                  {
                                    EDUCATION_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>
                          <div className="margin5">
                              <label>工作年限</label>
                              <Select
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.institutionCityHandler(value, 'workedYear') }}
                              >
                                  {
                                    WORK_YEAR_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>政治面貌</label>
                              <Select
                                  placeholder="请选择"
                                  className="selectClass"
                                  style={selectStyle}
                                  dropdownMatchSelectWidth={false}
                                  onChange={value => { this.institutionCityHandler(value, 'politicalStatus') }}
                              >
                                  {
                                    POLITICAL_STATUS_LIST.map((i, idx) => <Option key={idx} value={i.value}>{i.name}</Option>)
                                  }
                              </Select>
                          </div>

                          <div className="margin5">
                              <label>单位名称</label>
                              <Input style={selectStyle} value={institution.deptName} type="text" onChange={e=>this.institutionCityHandler(e.target.value, 'deptName')} />
                          </div>

                          <div className="margin5">
                              <label>职位名称</label>
                              <Input style={selectStyle} value={institution.positionName} type="text" onChange={e=>this.institutionCityHandler(e.target.value, 'positionName')} />
                          </div>
                          <div className="margin5">
                              <label>单位代码</label>
                              <Input style={selectStyle} value={institution.deptCode} type="text" onChange={e=>this.institutionCityHandler(e.target.value, 'deptCode')} />
                          </div>
                          <div className="margin5">
                              <label>职位代码</label>
                              <Input style={selectStyle} value={institution.positionCode} type="text" onChange={e=>this.institutionCityHandler(e.target.value, 'positionCode')} />
                          </div>
                          <div className="margin5">
                              <label>专业</label>
                              <Input style={selectStyle} value={institution.major} type="text" onChange={e=>this.institutionCityHandler(e.target.value, 'major')} />
                          </div>
                      </div>
                    }
                    {/* 禁用条件  disabled={this.state.type==1?!country.position: !province.province} */}
                    <div style={{backgroundColor: 'white', paddingBottom: '15px', display:'flex'}}>
                        <Button style={{margin: 'auto'}} type="primary" onClick={this.searchPosition}>职位查询</Button>
                    </div>
                </div>
                <h4 style={{marginBottom: 10, marginTop: 15}}>查询结果{officeListSize && <span style={{fontSize: '14px', marginLeft: '20px', color: 'red'}}>共{officeListSize}个职位</span>||''}</h4>
                <div style={{marginBottom: 10, minHeight: '25vh', overflow: 'scroll'}} className='white-panel'>
                    {
                      this.state.officeList.length==0?<div>暂无数据</div>:                   
                      this.state.officeList.map((item, idx) => {
                        return <div key={idx}>
                          <span className='bold-label'>{this.getTitle(item)}</span>
                          <div>
                          {
                            item.office ? 
                            (item.bureaus).map(item=>
                              <div style={{margin:'0 20px 10px'}}>
                                <p>用人司局：{item.bureau}</p>
                                {(item.positions).map((i, idx)=><Tag style={{marginBottom:'10px', cursor: 'pointer'}} color="volcano" key={idx} type="link" onClick={()=>this.selectPosition(i.id)}>{(this.getName(i)||'--')+'(招考人数'+i.number+'人)'}</Tag>)}                 
                              </div>                       
                            )
                            :this.getPositions(item).map((i, idx)=><Tag style={{marginBottom:'10px', cursor: 'pointer'}} color="volcano" key={idx} type="link" onClick={()=>this.selectPosition(i.id)}>{(this.getName(i)||'--')+'(招考人数'+i.number+'人)'}</Tag>)
                          }
                          </div>
                        </div>
                      })
                    }
                    {
                      this.state.officeList.length > 0 && <Pagination style={{ 'marginTop': '10px','textAlign': 'center'}} pageSize={10} current={this.getCurrent().page} total={this.state.officeTotalPages * 10} onChange={this.changePage}/>
                    }
                </div>

                <h4 style={{marginBottom: 10, marginTop: 15}}>职位详情</h4>
                <div className='white-panel' style={{marginBottom: 10, minHeight: '25vh', overflow: 'scroll'}}>
                  {
                    positionDetail ? <PositionDetail detail={positionDetail} job={this.state.job} positionConfirm={this.positionConfirm}/>
                    : <div>暂无数据</div>
                  }
                </div>

                <h4 style={{marginBottom: 10, marginTop: 15}}>学员信息</h4>
                <div className='white-panel' style={{marginBottom: 10, minHeight: '400px', overflow: 'scroll'}}>
                    {
                       showForm && <div>
                          { this.state.job ? <PositionDetail detail={this.state.job}/> : <div>未选择职位</div>}
                          <StudentForm
                            isUpdating={this.props.isUpdating} areas={this.props.areas} years={this.props.years}
                            areaHandler={this.areaHandler} info={info} area={this.findAreaByClassId()} year={this.props.match.params.year}
                            saveOrUpdateStudent={this.saveOrUpdateStudent} classList={this.props.classList||[]}
                            permission={checkPermission('interview:stu:edit')}
                          />
                      </div>
                    }
                </div>
            </div>
        )
    }
}

function mapStatetoProps(state) {

    return {
        classList: state.interviewClass.responseObj.content,
        ...state.iStudentInfo,
        years: state.questions.years,
        areas: state.questions.areas
    };
}

export default connect(mapStatetoProps)(IStudentInfo);
