import apiRequest from '../../common/request';

const PREFIX  = "_SHENLUN_QUESTION_PAGE_";

export const SUCCESS_GET_SHENLUN_QUESTION = PREFIX + "SUCCESS_GET_SHENLUN_QUESTION";
export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const RESET_SEARCH_STATUS = PREFIX + "RESET_SEARCH_STATUS";
export const LOADING = PREFIX + "LOADING";

export const resetSearchStatus = () => ({ type: RESET_SEARCH_STATUS });

export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

const getShenlunQuestion = data => ({
  type: SUCCESS_GET_SHENLUN_QUESTION,
  data
});

const loading = () => ({
  type: LOADING
});

export const fetchShenlunQuestions = data => {
  data.year && (data.year.length!=0) && (data.years = data.year.toString());
  delete data.year;
  let url = `/gk/ops/shenlun/question/page`;
  let config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    credentials: 'include'
  };
  return dispatch => {
    dispatch(loading());
    apiRequest(url, config).then(json => {
      dispatch(getShenlunQuestion(json.obj));
    })
  }
}