import { Component } from 'react';
import { connect } from "react-redux";
import './index.scss';
import { Empty, Drawer, Descriptions, Image, Collapse, Tag, Button, Radio, Table, Tooltip, Popover } from 'antd';
import { fetch_shenlun_detail, set_filter_status } from "./action";
import { getSearchQuery, checkPermission } from '../../utils/utils';
import Comment from '../../components/Comment';

const RadioGroup = Radio.Group;
const { Panel } = Collapse;

class ShenlunDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: {},
      setVisible: false,
    }
  }

  componentDidMount() {
    this.getStudentInfo();
    this.exerciseIdCur = [];
    this.classExerciseArr = JSON.parse(localStorage.getItem('classExerciseArr'));
    this.classExerciseArr && this.classExerciseArr.map(item => this.exerciseIdCur.push(item.id));
  };

  /** 筛选状态发生改变 */
  changeFilterStatus = async event => {
    let value = event.target.value;
    await this.props.setFilterStatus(value);
    await this.getStudentInfo();
  };

  /** 跳转个人详情页面  并存储数据方便详情页面下一个学员跳转 */
  goSingleDetail = userExamineId => {
    this.saveLocal();
    let id = getSearchQuery('id');
    let type = getSearchQuery('type');
    let exerciseId = getSearchQuery('exerciseId');
    let { className, title } = this.props.classObj;
    this.props.history.push(`/shenlunStudentDetail/${id}/${exerciseId}/${userExamineId}?type=${type}&className=${className}&title=${title}`)
  };

  /** 将学员列表数据保存到localStorage */
  saveLocal = () => {
    const { datas } = this.props.classObj;
    let arr = [];
    if (datas && datas.length) {
      datas.map(item => {
        if (item.isFinished) arr.push(item);
      })
    };
    try { window.localStorage.setItem('singleInfo', JSON.stringify(arr)); }
    catch (err) { }
  }

  /** 获取学员数据详情 */
  getStudentInfo = async () => {
    let params = {
      id: getSearchQuery('id'),
      exerciseId: getSearchQuery('exerciseId'),
    }
    if (this.props.filterStatus == 'DONE') {
      params.status = 1;
    }
    if (this.props.filterStatus == 'NOT') {
      params.status = 0;
    }
    await this.props.fetchShenlunDetail(params);
  };

  /** 生成columns的动态显示部分 */
  generateDynamicColumns = () => {
    const columns = [
      {
        title: '练习名称',
        render: (record) => <span>练习{record.sort}</span>
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '提交时间',
        dataIndex: 'submitDate',
      },
      {
        title: '批改时间',
        dataIndex: 'correctDate',
      },
      {
        title: '学员备注',
        render: (record) => <Tooltip title={record.remark}><div className="ellipsis1" style={{ maxWidth: '4em' }}>{record.remark}</div></Tooltip>
      },
      {
        title: '是否批改',
        render: (record) => <span>{this.getTypeInfo(2, record.isCorrect)}</span>
      },
      {
        title: '批改',
        render: (record) => checkPermission('clazz:shenlun:gkpg') && <Button type="link" size="small" onClick={() => { this.open(record) }}>{`练习${record.sort}`}</Button>
      },
    ]
    return columns;
  };

  /** 点击上一个练习 */
  prevExercise = () => {
    let currentIndex = this.findCurrent();
    if (currentIndex <= 0) return;
    let nextStudentId = this.findPrevOrNextId(currentIndex, true);
    this.jump2NextPrev(nextStudentId);
  }

  /** 点击下一个练习 */
  nextExercise = () => {
    let currentIndex = this.findCurrent();
    if (currentIndex >= this.exerciseIdCur.length - 1) return;
    let nextStudentId = this.findPrevOrNextId(currentIndex);
    this.jump2NextPrev(nextStudentId);
  }

  /** 返回当前练习的ID */
  findCurrent = () => {
    if (Object.prototype.toString.call(this.exerciseIdCur) != '[object Array]') return;
    this.exerId = getSearchQuery('exerciseId');
    return this.exerciseIdCur.indexOf(+this.exerId);
  }

  /** 返回上下练习的ID */
  findPrevOrNextId = (currentIndex, prev) => {
    if (prev) return this.exerciseIdCur[currentIndex - 1];
    else return this.exerciseIdCur[currentIndex + 1];
  }

  /** 页面跳转到上下练习 */
  jump2NextPrev = async (exerciseId) => {
    let url = `shenlunDetail?type=${getSearchQuery('type')}&id=${getSearchQuery('id')}&exerciseId=${exerciseId}`;
    await this.props.history.replace(url);
    this.getStudentInfo();
  }

  /** 渲染表格列表标签 */
  getTypeInfo = (type, record) => {
    if (type == 1) {
      if (record == 1) return '申论';
      if (record == 5) return '综应';
      return <Tag>未知</Tag>;
    } else if (type == 2) {
      return (record) && <Tag color="blue">已批改</Tag> || <Tag color="red">未批改</Tag>
    } else if (type == 3) {
      return (record) && <Tag color="blue">已完成</Tag> || <Tag color="red">未完成</Tag>
    }
  }

  /** 打开批改面板 */
  open = (record) => {
    this.setState({ setVisible: true, info: record });
  }

  /** 设置学员头部信息 */
  getStuInfo = (obj) => (
    <div className="common1Line" style={{ padding: 0, background: '#fafafa' }}>
      <span style={{ width: '10em', color: 'blue' }}>{obj.studentName}</span>
      <span style={{ width: '8em' }}>已批改个数：{obj.commentNum}</span>
      <span style={{ width: '8em' }}>练习完成数：{obj.finishedNum}</span>
      <span>是否完成：{this.getTypeInfo(3, obj.isFinished)}</span>
      {obj.gmtCreate && <span>最近提交：<Tag color="red">{obj.gmtCreate}</Tag></span>}
    </div>
  )

  render() {
    let { info, setVisible } = this.state;
    let columns = this.generateDynamicColumns();
    let { classObj } = this.props;
    let currentIndex = this.findCurrent();                                  // 当前学员的索引位置
    let prevNums = currentIndex;                                                      // 当前学员前面还有多少位学员
    let nextNums = this.exerciseIdCur ? this.exerciseIdCur.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
    let stuList = this.classExerciseArr && (
      <div className="popoverStu">
        {this.classExerciseArr.map((item, idx) => <p key={idx} className={['classStuList', item.id == this.exerId ? 'activeStu' : ''].join(' ')} onClick={() => this.jump2NextPrev(item.id)}>{idx + 1 + '-' + item.title}</p>)}
      </div>
    ) || (<div>暂无列表</div>);
    let num = this.classExerciseArr && this.classExerciseArr[currentIndex].num;

    return (
      <div className="shenlunDetail-container">
        <div className="fixed common1Line">
          <Tooltip title={`前面还有${prevNums}个练习`}>
            <Button type="primary" disabled={this.exerciseIdCur && currentIndex <= 0} onClick={this.prevExercise}>上一个练习</Button>
          </Tooltip>
          <Tooltip title={`后面还有${nextNums}个练习`}>
            <Button type="primary" disabled={this.exerciseIdCur && currentIndex >= this.exerciseIdCur.length - 1} onClick={this.nextExercise}>下一个练习</Button>
          </Tooltip>
          <Popover content={stuList} title="练习列表" trigger="click">
            <Button type="primary">练习列表</Button>
          </Popover>
          <span>班主任:{classObj.teacher}</span>
          <span>班级人数:{classObj.studentCount || 0}</span>
          <span>完成人数:{classObj.exerciseCountFinished || 0}</span>
          <RadioGroup onChange={this.changeFilterStatus} value={this.props.filterStatus}>
            <Radio value="ALL">全部</Radio>
            <Radio value="DONE">已完成</Radio>
            <Radio value="NOT">未完成</Radio>
          </RadioGroup>
        </div>

        <div className="common1Line">
          <span>班级：{classObj.className}</span>
          <span>练习总数：{num}</span>
          <span>练习创建时间：{classObj.gmtCreate}</span>
          <span>测试名称：{classObj.title}</span>
        </div>
        {classObj.publishDate && <div className="common1Line" style={{ paddingTop: 0 }}>练习布置时间：<span style={{ textDecoration: "underline"}}>{classObj.publishDate}</span></div>}

        {classObj.datas && classObj.datas.length &&
          <Collapse>
            {classObj.datas.map((item, idx) => (
              <Panel key={idx} header={this.getStuInfo(item)} collapsible={item.exercises && 'true' || 'disabled'}>
                {item.exercises &&
                  <Table
                    pagination={false}
                    columns={columns}
                    dataSource={item.exercises}
                    rowKey={(record) => record.id}
                  />}
              </Panel>
            ))}
          </Collapse> || <Empty />}

        <Drawer
          width='80%'
          title={this.getTypeInfo(1, getSearchQuery('type')) + `-班级测试-批改作业`}
          placement="left"
          onClose={() => this.setState({ setVisible: false })}
          visible={setVisible}
        >
          <Descriptions title=''>
            <Descriptions.Item label="班级名称">{classObj.className}</Descriptions.Item>
            <Descriptions.Item label="测试名称">{classObj.title}</Descriptions.Item>
            <Descriptions.Item label="练习名称">练习{info.sort}</Descriptions.Item>
            <Descriptions.Item label="提交时间">{info.submitDate}</Descriptions.Item>
            <Descriptions.Item label="批改时间">{info.correctDate}</Descriptions.Item>
          </Descriptions>
          <p>学员备注：{info.remark}</p>
          <Image.PreviewGroup>
            {(info.imageUrl || []).map((item, idx) => (
              <Image key={idx} style={{ width: 200, height: 200 }} src={item} />
            ))}
          </Image.PreviewGroup>
          {setVisible && <Comment
            classType={getSearchQuery('type')}   // 申论传1，综应传5
            answerId={info.id}
            questionId={info.questionId}
            feedbackRemark={info.feedbackRemark}
            feedbackUrl={info.feedbackUrl}
            userImgs={info.imageUrl}
            update={() => { this.setState({ setVisible: false }); this.getStudentInfo(); }}
          />}
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    classObj,
    filterStatus
  } = state.shenlunDetail;

  return {
    classObj,
    filterStatus
  };
}

const mapDispatchToProps = dispatch => ({
  setFilterStatus: value => { dispatch(set_filter_status(value)) },      // 设置筛选状态
  fetchShenlunDetail: async (params = {}) => { await dispatch(fetch_shenlun_detail(params)); },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShenlunDetail);