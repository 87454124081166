import {
    FINISH_LOADING_DATA,
    START_FETCHING_DETAIL,
    SET_TYPE,
    SET_VALUE,
    RESET_EXAMINE_OBJ,
    SET_SEL_QUESIDS,
    RESET_ALL_DATA,
    findQuesId
  } from './actions';
  
  const initialState = {
    loading: false,                           // 表示正在加载中，修改的时候会用上
    type: '',                                 // 试卷类型
    selQuesIds: [],                           // 这是已选问题的ID数组信息
    examineObj: {
      questions: [],                          // 问题数组，里面的每一项都是详细数据，而非只有问题ID，这里统一命名为selQuestions后续需要进行转换
      title: '',                              // 试卷标题
      limitTime: '',                          // 限制时间
      publish: false,                         // 是否上架
      year: '',                               // 年份，只有历年真题会用上
      area: [],                               // 地区，只有历年真题会用上
      share: false,                           // 是否需要分享，估分卷和押题卷会用上
    },                                        // 这是试卷详情数据，包括所有类型试卷所必须的输入字段，在saveOrUpdate具体的试卷的时候需要筛选出对应的字段信息
  };
  
  export function paperDetail(prevState=initialState, action) {
    switch(action.type) {
      case START_FETCHING_DETAIL:
        return { ...prevState, loading: true };
      case FINISH_LOADING_DATA:
        let selQuesIds = findQuesId(action.data.questions);
        return { ...prevState, examineObj: action.data, loading: false, selQuesIds };
      case SET_TYPE:
        return { ...prevState, type: action.value };
      case SET_VALUE:
        return { ...prevState, examineObj: { ...prevState.examineObj, [action.key]: action.value } };
      case RESET_EXAMINE_OBJ:
        return { 
          ...prevState, 
          selQuesIds: [],
          examineObj: {
            questions: [],
            title: '',
            limitTime: '',
            publish: false,
            year: '',
            area: [],
            share: false,
          }
        };
      case SET_SEL_QUESIDS:
        return { ...prevState, selQuesIds: action.value };
      case RESET_ALL_DATA:
        return {
          loading: false,
          type: '',
          selQuesIds: [],
          examineObj: {
            questions: [],
            title: '',
            limitTime: '',
            publish: false,
            year: '',
            area: [],
            share: false,
          },
        };
      default:
        return prevState;
    }
  }