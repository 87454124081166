/**
 * LOG: 这是输入课程编号的组件，同时具有和日期进行关联的功能
 */

import React from 'react';
import { InputNumber, Modal, Button } from 'antd';
import Calendar from 'rc-calendar';
import zhLocale from 'rc-calendar/lib/locale/zh_CN';
import 'rc-calendar/assets/index.css';
import 'moment/locale/zh-cn';

import './index.scss';

class CoursenumCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendarModal: false,                       // 是否显示课程编号和日期进行对应关系的模态
      date2Input: {},                                 // 该单元格是否显示日期输入框
      beginCourseNum: 1,                              // 第一个课程编号
      endCourseNum: 1,                                // 最后一个课程编号
      date2NumberObj: {},                             // 使用日历组件输入的课程号跟日期之间的对应关于，键名为日期，值为课程，为什么键名为日期呢？因为日期保持不变呀，输入的时候值一直在变，会误添
    };
    this.init(props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  resetState = () => { this.setState({ date2NumberObj: {} }); }

  init = props => {
    let courseNumsArr = this.getNumbersFromCourseNums(props.courseNums);
    let beginCourseNum = courseNumsArr ? courseNumsArr[0] : 0;
    let endCourseNum = courseNumsArr ? courseNumsArr[courseNumsArr.length - 1] : 0;
    setTimeout(() => {
      this.setState({ beginCourseNum, endCourseNum });
    }, 0);
  }

  showCalendarModalHandler = () => { this.setState({ showCalendarModal: true }); }

  hideCalendarModal = () => { 
    this.setState({ showCalendarModal: false, date2Input: {}, date2NumberObj: {} }); 
    this.props.confirmUpdateCourseNums(false);
  }

  calendarModalSureFun = () => {
    this.calendarGenerateCourseNums();
    this.setState({ showCalendarModal: false });
    this.props.confirmUpdateCourseNums(true);
  }

  /** 日历日期改变方法 */
  calendarOnChange = date => {}

  /** 在日历中的单元格里面进行输入日期的操作 */
  inputNumberHandler = (fullTime, num) => {
    if (!num) {
      this.deleteCourseNumByDate(fullTime);
      return;
    }
    let date2NumberObj = this.state.date2NumberObj;
    date2NumberObj[fullTime] = num;                   // 同一个日期只要有输入就不断更新
    this.setState({ date2NumberObj });
  }

  /** 删除指定日期的courseNum */
  deleteCourseNumByDate = fullTime => {
    let courseNums = this.props.courseNums;
    let result = [];
    courseNums.map(obj => {
      if (obj.date && !obj.date.includes(fullTime)) {
        result.push(obj);
      }
    });
    this.updateCourseNumsHandler(result, true);       // 带个true参数表明是来自日历组件的修改
  }

  /** 把courseNums里面date值相同的项给去掉 */
  deleteSameDateCourseNums = arr => {
    if (Object.prototype.toString.call(arr) !== "[object Array]") return;
    let resultObj = {};
    let resultArr = [];
    arr.map(obj => {
      if (obj.date) {
        resultObj[obj.date] = obj;
      } else {
        resultArr.push(obj);
      }
    });
    Object.keys(resultObj).map(date => {
      resultArr.push(resultObj[date]);
    });
    return resultArr;
  }

  /** 通过日历的形式新增课程编号 */
  calendarGenerateCourseNums = () => {
    let calendarInputsObj = this.translateDate2NumberObj();          // 变量类型形如 { number: courseNumsVO }
    let courseNumsObj = this.courseNums2Obj();
    let finalObj = { ...courseNumsObj, ...calendarInputsObj };       // 日历输入优先级会高
    let courseNums = [];
    Object.keys(finalObj).map(key => {
      courseNums.push(finalObj[key]);
    });
    let params = this.deleteSameDateCourseNums(courseNums);
    this.updateCourseNumsHandler(params, true);                      // 通过日历组件的形式进行修改
    
  }

  /** date2NumberObj把日期作为键值是因为日期不变，但是这不利于后续的比较过程，这里将其转换过来 */
  translateDate2NumberObj = () => {
    let date2NumberObj = this.state.date2NumberObj;
    let keys = Object.keys(date2NumberObj);
    let result = {};
    keys.map(key => {
      let number = date2NumberObj[key];
      result[`_${number}`] = {
        number,
        date: key
      };
    });
    return result;
  }

  /** 把courseNums数组转换为对象，对象的键名为number */
  courseNums2Obj = () => {
    let courseNums = this.props.courseNums;
    let result = {};
    courseNums.map(obj => {
      let number = obj.number;
      result[`_${number}`] = obj;
    });
    return result;
  }

  /** 点击指定单元格的plus icon，那么这个单元格出现输入框 */
  clickInputIcon = fullTime => {
    this.calendarGenerateCourseNums();
    this.setState({ date2Input: { [fullTime]: true } });            // 下面的写法表明可以同时出现多个输入框
    // let param = this.state.date2Input;
    // this.setState({ date2Input: { ...param, [fullTime]: true }});
  }

  findDateExists = (date, arr) => {
    if (Object.prototype.toString.call(arr) !== "[object Array]") return;
    let result = false;
    for (let i = 0, len = arr.length; i < len; i++) {
      let obj = arr[i];
      if (obj['date'] && obj['date'].includes(date)) {
        result = obj.number;                                        // 前提：不存在第0天，直接是从第1天开始的
        break;
      }
    }
    return result;
  }

  /** 点击单元格的其它区域，那么隐藏该单元格的输入框 */
  hideInput = fullTime => {
    let date2Input = this.state.date2Input;
    delete date2Input[fullTime];
    this.setState({ date2Input });
    this.calendarGenerateCourseNums();
  }

  /** rc-calendar组件的dateRender方法 */
  dateRenderFun = (current, value) => {
    let fullTime = current.format('YYYY-MM-DD');
    let date = current.date();
    let month = current.month();
    let choseMonth = value.month();
    let isActiveDate = this.findDateExists(fullTime, this.props.courseNums);
    // if (month === choseMonth) {
    return (
      <div className={["dateClass", isActiveDate ? "activeDate" : ''].join(' ')}>
        <div className="crow crow1" onClick={() => this.hideInput(fullTime)}>
          <span className="flexg"></span>
          <span className="span32">{date}th</span>
        </div>
        {
          !this.state.date2Input[fullTime] ?
          <div className="crow crow2">
            <span>{ isActiveDate || '无' }</span>
            <span className="flexg"></span>
            <img alt="img" className="plusIcon" onClick={() => {this.clickInputIcon(fullTime)}} src={isActiveDate ? 'https://gk-resource.oss-cn-beijing.aliyuncs.com/background/icon_add_default%402x.png' : 'https://gk-resource.oss-cn-beijing.aliyuncs.com/background/icon_add_Stateless%402x.png'} />
          </div>
          :
          <div className="crow crow2">
            <InputNumber defaultValue={isActiveDate} min={1} className="inc" onChange={num => { this.inputNumberHandler(fullTime, num); }} />
          </div>
        }
      </div>
    )
    // }
  }

  /** 从courseNums数组里面，获取number数组 */
  getNumbersFromCourseNums = (courseNums) => {
    if (Object.prototype.toString.call(courseNums) !== "[object Array]") return;
    let result = [];
    courseNums.map(item => { result.push(item.number); });
    result = result.filter(n => n != null);
    return result;
  }

  /** 起始天数发生变化 */
  inputBeginCourseNum = beginCourseNum => {
    this.setState({ beginCourseNum });
    let endCourseNum = this.state.endCourseNum;                       // 最后一天的课程编号
    this.beginOrEndChangeCourseNums(beginCourseNum, endCourseNum);
  }

  /** 结尾天数发生变化 */
  inputEndCourseNum = endCourseNum => {
    this.setState({ endCourseNum });
    let beginCourseNum = this.state.beginCourseNum;
    this.beginOrEndChangeCourseNums(beginCourseNum, endCourseNum);
  }

  /** 采用input形式改变起始天数和截止天数的时候会调用这个方法 */
  beginOrEndChangeCourseNums = (beginCourseNum, endCourseNum) => {
    if (!beginCourseNum || !endCourseNum) return;
    let result = [];
    for (let i = beginCourseNum; i <= endCourseNum; i++) {
      let item = this.coverDate(i);
      result.push(item);
    }
    let params = this.deleteSameDateCourseNums(result);
    this.updateCourseNumsHandler(params);
  }

  /** 调用父组件的方法更新courseNums */
  updateCourseNumsHandler = (params, isFromCalendar=false) => {
    params = this.sortCourseNums(params);
    this.props.updateCourseNums(params, isFromCalendar);
    this.resetState();
  }

  /** 对courseNums数组安装课程号进行排序 */
  sortCourseNums = params => {
    params.sort((obja, objb) => obja.number - objb.number);
    return params;
  }

  /** 覆盖或者新建CourseNumberVO对象，返回一个CourseNumberVO对象 */
  coverDate = (num, dateString) => {
    let courseNums = this.props.originalCourseNums; // 如果想要和实时值courseNums进行比较的话，那么需要注意InputNumber的onChange问题
    if (Object.prototype.toString.call(courseNums) !== "[object Array]") return;
    let result = { number: num };                 // 先填充课程号字段
    for(let i = 0, len = courseNums.length; i < len; i++) {
      let obj = courseNums[i];
      if(obj.number === num) {
        result = { ...result, ...obj };                             // 优先用老的覆盖，副作用，有date的值优先级别会更高
        break;
      }
    }
    if (dateString) { result.date = dateString; }
    return result;
  }


  render() {
    return (
    <div className="CoursenumCalendarComponent">
      <div className='flexcolumn inputRow'>
        <label>发布第几天到第几天的课程</label>
        <div className="flexrow">
          <InputNumber value={this.state.beginCourseNum} onChange={this.inputBeginCourseNum} min={1} placeholder="第几天"/>
          <span className="mlr8">~</span>
          <InputNumber value={this.state.endCourseNum} onChange={this.inputEndCourseNum} min={this.state.beginCourseNum} placeholder="第几天"/>
          <Button className="ml15" type="primary" onClick={this.showCalendarModalHandler}>设置课程日期</Button>
        </div>
      </div>

      {/** 这是输入课程编号和日期对应关系的模态 */}
      <Modal
        wrapClassName="CoursenumCalendarComponent"
        width="635"
        title="课程编号和日期对应关系输入"
        visible={this.state.showCalendarModal}
        onOk={this.calendarModalSureFun}
        onCancel={this.hideCalendarModal}
      >
        <Calendar
          className="calendarWrapper"
          locale={zhLocale}
          onChange={this.calendarOnChange}
          showDateInput={false}
          showToday={false}
          dateRender={this.dateRenderFun}
        />
      </Modal>
    </div>
    );
  }
}

// CoursenumCalendar.propTypes = {
//   courseNums: PropTypes.array,                    // 形如 [ { date: '2019-05-01', number: 1 } ]
//   updateCourseNums: PropTypes.func,               // 调用父组件的方法更新courseNums属性
//   originalCourseNums: PropTypes.array,            // 不变的courseNums，在比较的时候会发生用处
//   confirmUpdateCourseNums: PropTypes.func,
// };

export default CoursenumCalendar;
