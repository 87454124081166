import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchInstitutionYearOrArea } from '../PaperList/actions';
import { fetchGJCategories, fetchZCCategories } from '../ModuleSetting/actions';
import { Typography, Spin, Cascader, Breadcrumb, Radio, message, Select, Tooltip, Button, Card, Space, Input, Checkbox } from 'antd';
import {
    add_option,
    receive_question_info,
    remove_option,
    request_question_info,
    update_category,
    update_multiple_question_answer,
    update_question_answer_desc,
    update_question_analysis,
    update_question_source,
    update_question_selection,
    update_question_title,
    update_question_type,
    updateQuestionVideo,
    update_single_question_answer,
    updateQuestion,
    fetchMaterialQuestion,
    update_question_tag_type,
    fetchEmpty,
    update_url,
    update_signedVideoUrl,
    update_years_type,
    update_province_type
} from "./actions";
import Editor from "../../components/Editor/index";
import VideoPicker from '../../components/VideoPicker';
import { fetchTag } from "../EssayCategory/actions"; // 引入从服务端获取tag的action
import { checkPermission } from '../../utils/utils';
import apiRequest from '../../common/request'
import { OSS_PUB_GK_COURSE } from '../../common/consts';
import { getAvailableVideoUrl } from '../../components/VideoPicker/action';

const { Paragraph } = Typography;
const { Option } = Select;
const MIN_ANSWER = 2;
const MAX_ANSWER = 10;
const ALPHA = ['A', 'B', 'C', 'D', 'E', "F", 'G', "H", 'I', 'J', 'K'];

class InstitutionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionTag: '',
            categoryId: '',
            showVideoModal: false,
            showNextAndPrevBtn: false,
            size: null,
        };
    }

    componentDidMount() {
        //有id说明是查看试题详情，没有则是新增试题
        let { type, id } = this.props.match.params;
        false && this.checkShowNextAndPrev(); //暂时不考虑试题切换
        type == 'gj' && this.props.dispatch(fetchGJCategories());
        type == 'zc' && this.props.dispatch(fetchZCCategories(true));
        this.props.dispatch(fetchInstitutionYearOrArea(type, 'area', 'jobArea'));
        this.props.dispatch(fetchInstitutionYearOrArea(type, 'year', 'jobYear'));
        this.props.dispatch(fetchTag());
        if (id) {
            this.fetchQuestion(type, id);
        } else {
            this.props.dispatch(fetchEmpty());
        }
    }

    fetchQuestion = (type, questionId) => {
        this.props.dispatch(request_question_info());
        apiRequest(`/gk/ops/${type}/question/details/${questionId}`, { credentials: 'include' })
            .then(json => {
                this.props.dispatch(receive_question_info(json.obj));
                // 如果是材料题，就fetch它的材料过来              
                if (!json.obj.options) {
                    json.obj.options = [
                        { key: '', value: '' },
                        { key: '', value: '' },
                        { key: '', value: '' },
                        { key: '', value: '' }
                    ]
                }
                if (json.obj.materialsId) {
                    this.props.dispatch(fetchMaterialQuestion(type, json.obj.materialsId));
                }
                if (json.obj.categoryTree && json.obj.categoryTree.length) {
                    json.obj.categoryTree.reverse();
                }
                if (json.obj.questionTag) {
                    // 每道试题都只对应着一个tag
                    this.props.dispatch(update_question_tag_type(json.obj.questionTag));
                }
                if (json.obj.area) {
                    this.getProvince(json.obj.area);
                }
                if (json.obj.year) {
                    this.getYear(json.obj.year);
                }
            })
            .catch(error => console.log(error));
    };

    // 获取省份列表
    getProvince = value => {
        this.props.dispatch(update_province_type(value));
    }

    // 获取年份列表
    getYear = value => {
        this.props.dispatch(update_years_type(value));
    }

    closeVideoModal = () => {
        this.setState({ showVideoModal: false });
    };

    videoPickerFeedBack = async (v) => {
        let canPlayUrl = await getAvailableVideoUrl(v);
        this.setState({ showVideoModal: false });
        this.props.dispatch(update_url(v));
        this.props.dispatch(update_signedVideoUrl(canPlayUrl));
    };

    videoPickerSize = value => {
        this.setState({ size: value })
    };

    uploadFile = () => {
        let file = this.refs.upload.files[0];
        if (file) {
            this.props.dispatch(updateQuestionVideo(file, this.props.info));
        } else {
            message.warning('请先选择文件');
        }
    };

    /** bad design: 寻找心竺原创 */
    findSinture = () => {
        return this.props.tags.find(obj => obj.desc.includes('心竺'));
    }

    /** 判断是否需要显示省份和年份；只有当questionTag不是原创的时候才有必要显示 */
    showAreaAndYear = () => {
        if (!this.props.tags || !this.props.tags.length) return false;
        let sintureObj = this.findSinture();
        return this.props.info.questionTag != sintureObj.value;
    }

    modifyQuestion = () => {

        if ((!this.props.info.content) || this.props.info.content.trim() === "") {
            message.warning('请输入问题');
            return;
        }

        if (!this.props.info.options.every(v => v.value.trim() !== "")) {
            message.warning('请输入候选项内容');
            return;
        }

        // 答案数组
        if (typeof this.props.info.answer === "string") {
            if (!this.props.info.answer.split(',').filter(i => i.length > 0).length) {
                message.warning('请选择正确答案');
                return;
            }
        } else {
            if (!this.props.info.answer.filter(i => i.length > 0).length) {
                message.warning('请选择正确答案');
                return;
            }
        }

        if (this.props.info.quesExplain.trim() === "") {
            message.warning('请输入试题解析');
            return;
        }

        if (this.props.info.categoryId == -1) {
            message.warning('请选择分类');
            return;
        }

        if (this.props.info.questionTag == "") {
            message.warning('请选择标签');
            return;
        }

        if (!this.props.info.source) {
            message.warning('请输入试题来源');
            return;
        }

        let showAY = this.showAreaAndYear();

        if (showAY) {

            if (!this.props.info.area || !this.props.info.area.length) {
                message.warning('请选择省份');
                return;
            }

            if (!this.props.info.year || !this.props.info.year.length) {
                message.warning('请选择年份');
                return;
            }

        }
        let { type } = this.props.match.params;
        this.props.dispatch(updateQuestion(type, this.props.info, showAY));
    };

    deleteVideo = () => {
        this.props.dispatch(update_signedVideoUrl(null));
        this.props.dispatch(update_url(null));
    };

    /** 检查是否有必要出现底部的功能区域 */
    checkShowNextAndPrev = () => {
        let questionsIds = JSON.parse(localStorage.getItem('questionsIds')) || [];
        this.questionsIds = questionsIds;
        this.questionId = this.props.match.params.id;
        let showNextAndPrevBtn = false;
        if (Object.prototype.toString.call(this.questionsIds) !== '[object Array]') showNextAndPrevBtn = false;
        else showNextAndPrevBtn = this.questionsIds.includes(+this.questionId);
        this.setState({ showNextAndPrevBtn });
    }

    /** 点击上一题 */
    prevStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex <= 0) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex, true);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 点击下一题 */
    nextStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex >= this.questionsIds.length - 1) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 返回当前试题的ID */
    findCurrentStudentIdx = () => {
        if (Object.prototype.toString.call(this.questionsIds) != '[object Array]') return;
        this.questionId = this.props.match.params.id;
        return this.questionsIds.indexOf(+this.questionId);
    }

    /** 返回上下题的ID */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.questionsIds[currentIndex - 1];
        else return this.questionsIds[currentIndex + 1];
    }

    /** 页面跳转到上下题 */
    jump2NextPrevStudent = questionsId => {
        let { type } = this.props.match.params;
        let url = `/institutionInfo/${type}/${questionsId}`;
        this.props.history.replace(url);
        this.fetchQuestion(type, questionsId);
    }

    /** 判断分类id是否使用 */
    getCategoryId = (arr) => {
        if (typeof arr === 'number') return arr;
        if (arr[arr.length - 1] == 'zc' || arr[arr.length - 1] == 'A' || arr[arr.length - 1] == 'B' || arr[arr.length - 1] == 'C' || arr[arr.length - 1] == 'D') {
            return false;
        }
        return arr[arr.length - 1];
    }

    /** 获取所有父id */
    familyTree = (arr1, id) => {
        let temp = []
        let forFn = function (arr, id) {
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i]
                if (item.id === id) {
                    temp.unshift(item.id)
                    forFn(arr1, item.parentId);
                    break;
                } else {
                    if (item.subset) {
                        forFn(item.subset, id);
                    }
                }
            }
        }
        forFn(arr1, id);
        return temp;
    }

    /** 动态权限判断方法 */
    getType = () => {
        let { type } = this.props.match.params;
        if (type == 'gj') return 'gj:question:edit';
        if (type == 'zc') return 'zc:question:edit';
        return '';
    }

    render() {
        let { type } = this.props.match.params;
        let { isFetching, isUpdating, gjCategory, zcCategory, jobYear, jobArea, info, tags } = this.props;
        let answer = info.answer;
        let currentIndex = this.findCurrentStudentIdx();                                  // 当前题目的索引位置
        let prevNums = currentIndex;                                                      // 当前题目前面还有多少位学员
        let nextNums = this.questionsIds ? this.questionsIds.length - currentIndex - 1 : 0;   // 当前题目后面还有多少位学员
        let options = (type == 'gj') && gjCategory || zcCategory;

        return (
            <Spin spinning={isUpdating || isFetching} tip='加载中...'>
                <div className="commonFlexLR">
                    <div className="commonLeft">
                        <Breadcrumb>
                            <Breadcrumb.Item>事业单位</Breadcrumb.Item>
                            <Breadcrumb.Item>{type=='gj'&&'公基'||'职测'}试题详情</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="commonSearchBox">
                            <div className="itemSearchBox">
                                <label>标签</label>
                                <Select style={{ width: 150 }} value={info.questionTag || 2} onChange={value => this.props.dispatch(update_question_tag_type(value))}>
                                    <Option value="">请选择</Option>
                                    {tags.map((tag, idx) => {
                                        return <Option key={idx} value={tag.value}>{tag.desc}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <label>题型</label>
                                <Select style={{ width: 150 }} value={info.quesType} onChange={value => this.props.dispatch(update_question_type(value))}>
                                    <Option value={0}>单选题</Option>
                                    <Option value={1}>多选题</Option>
                                    <Option value={2}>判断题</Option>
                                    <Option value={5}>不定项</Option>
                                    <Option value={6}>填空题</Option>
                                </Select>
                            </div>
                            <div>
                                <label>分类</label>
                                <Cascader className="commonW400" value={this.familyTree(options, info.categoryId)} fieldNames={{ label: 'name', value: 'id', children: 'subset' }} options={(options)} onChange={value => this.props.dispatch(update_category(this.getCategoryId(value)))} changeOnSelect showSearch />
                            </div>
                            {this.showAreaAndYear() && <div className="itemSearchBox">
                                <label>省份</label>
                                <Select
                                    placeholder=" 请选择省份（可多选）"
                                    style={{ width: '400px' }}
                                    mode="multiple"
                                    dropdownMatchSelectWidth={false}
                                    value={this.props.info.area}
                                    onChange={(value) => { this.getProvince(value) }}
                                >
                                    {jobArea.map((province, idx) => {
                                        return <Option key={idx} value={province}>{province}</Option>
                                    })}
                                </Select></div>}
                            {this.showAreaAndYear() && <div className="itemSearchBox"><label>年份</label>
                                <Select
                                    placeholder=" 请选择年份（可多选）"
                                    style={{ width: '400px' }}
                                    mode="multiple"
                                    dropdownMatchSelectWidth={false}
                                    onChange={(value) => { this.getYear(value) }}
                                    value={this.props.info.year}
                                >
                                    {jobYear.map((year, idx) => {
                                        return <Option key={idx} value={year}>{year}</Option>
                                    })}
                                </Select>
                            </div>}
                        </div>
                        <Card size="small" title="来源">
                            <Input value={info.source} onChange={e => this.props.dispatch(update_question_source(e.target.value))} style={{ width: "100%" }} placeholder="来源" />
                        </Card>
                        {
                            info.materialsId && !this.props.isFetchingMaterial &&
                            <Card size="small" title="资料内容">
                                <Link to={`/materialQuestion/${type}/${info.materialsId}`}>[跳转编辑]</Link>
                                <div dangerouslySetInnerHTML={{ __html: this.props.materialsInfo.content || '' }}></div>
                            </Card>
                        }
                        <Card size="small" title="问题">
                            <Editor
                                content={info.content || ''}
                                onChange={value => this.props.dispatch(update_question_title(value))}
                            />
                        </Card>

                        {info.options && info.options.length && info.options.map((item, i) => (
                            <Card size="small" key={i} title={`答案${ALPHA[i]}`}>
                                <Editor
                                    content={info.options[i]['value'] || ''}
                                    onChange={((i) => {
                                        return (value) => {
                                            this.props.dispatch(update_question_selection(i, ALPHA[i], value))
                                        }
                                    })(i)}
                                />
                            </Card>
                        ))}
                        <Button style={{ width: '45%', marginRight: '10%' }} danger
                            onClick={() => {
                                if (this.props.info.options.length - 1 < MIN_ANSWER) {
                                    message.warning(`最少需有${MIN_ANSWER}个候选项`);
                                } else {
                                    this.props.dispatch(remove_option())
                                }
                            }}
                        >删除选项</Button>
                        <Button style={{ width: '45%', marginBottom: '20px' }} type="primary"
                            onClick={() => {
                                if (this.props.info.options.length + 1 > MAX_ANSWER) {
                                    message.warning(`最多只能有${MAX_ANSWER}个候选项`);
                                } else {
                                    this.props.dispatch(add_option())
                                }
                            }}
                        >新增选项</Button>

                        {/* info.quesType == '0'是单选题，1，5是多选和不定项 */}
                        <Card size="small" title="正确答案">
                            {info.quesType == '0' ?
                                <Radio.Group value={info.answer[0]} onChange={e => this.props.dispatch(update_single_question_answer(e.target.value))}>
                                    {(info.options || []).map((v, i) => <Radio key={i} value={ALPHA[i]}>{ALPHA[i]}</Radio>)}
                                </Radio.Group>
                                :
                                <Checkbox.Group value={info.answer} style={{ width: '100%' }} onChange={value => this.props.dispatch(update_multiple_question_answer(value.map(v => v)))}>
                                    {(info.options || []).map((v, i) => <Checkbox key={i} value={ALPHA[i]}>{ALPHA[i]}</Checkbox>)}
                                </Checkbox.Group>
                            }
                        </Card>

                        <Card size="small" title="题目解析">
                            <Editor
                                content={info.quesExplain || ''}
                                onChange={value => this.props.dispatch(update_question_answer_desc(value))}
                            />
                        </Card>
                        <Card size="small" title="疑难解答">
                            <Editor
                                content={info.issue || ''}
                                onChange={value => this.props.dispatch(update_question_analysis(value))}
                            />
                        </Card>
                    </div>

                    <div className="commonsubmit">
                        <div className="cardR" style={{ height: '88vh', overflow: 'auto' }}>
                            {this.state.showNextAndPrevBtn && <div style={{ marginBottom: '10px' }}>
                                <Tooltip title={`前面还有${prevNums}道题`}>
                                    <Button disabled={this.questionsIds && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一题</Button>
                                </Tooltip>
                                <Tooltip title={`后面还有${nextNums}道题`}>
                                    <Button disabled={this.questionsIds && currentIndex >= this.questionsIds.length - 1} onClick={this.nextStudent} type="primary">下一题</Button>
                                </Tooltip>
                            </div>}
                            <Card size="small" title="视频">
                                <Space direction="vertical">
                                    {
                                        this.props.isVideoUpdating && <Spin tip="上传中..."></Spin>
                                    }
                                    {
                                        info.signedVideoUrl ?
                                            <video style={{ width: "100%" }} controls src={info.signedVideoUrl} />
                                            :
                                            info.videoUrl ?
                                                <video style={{ width: "100%" }} controls src={info.videoUrl} />
                                                :
                                                ''
                                    }
                                    {info.videoUrl && <Paragraph style={{fontSize: '12px'}} copyable={{ text: info.videoUrl }}>{info.videoUrl}</Paragraph>}
                                    {checkPermission(this.getType()) && <div>
                                        <input type="file" ref="upload" style={{ margin: '10px 0' }} />
                                        {info.videoUrl && <Button style={{ marginBottom: 10 }} type="danger" onClick={this.deleteVideo}>删除视频</Button>}
                                        <br />
                                        <Button type="primary" onClick={this.uploadFile}>上传本地文件</Button>
                                        <p> 上传完记得点更新</p>
                                        <Button type="primary" onClick={() => this.setState({ showVideoModal: true })}>从视频库选择</Button>
                                        <p> 选择完记得点更新</p>
                                    </div>}
                                </Space>
                            </Card>
                            {checkPermission(this.getType()) && <Card size="small" title="更新">
                                <Button type="primary" onClick={this.modifyQuestion}>更新</Button>
                                <p style={{ marginTop: '10px' }}><span style={{ color: 'red' }}>注意:</span>用户已做试题记录，pdf生成试题相关内容，无法通过修改试题后进行变动，请谨慎使用试题修改！！！</p>
                            </Card>}
                        </div>
                    </div>
                    {this.state.showVideoModal ?
                        <VideoPicker
                            pubName={OSS_PUB_GK_COURSE}
                            videoKey={"key"}
                            showModal={this.state.showVideoModal}
                            closeModal={this.closeVideoModal}
                            feedBack={this.videoPickerFeedBack}
                            getSize={this.videoPickerSize}
                        /> : null}
                </div>
            </Spin>
        )
    }
}

function mapStatetoProps(state) {
    let { jobYear, jobArea } = state.paperList;
    let { gjCategory, zcCategory } = state.questionCategories;
    let { tags } = state.essayCategory;
    return {
        tags, jobYear, jobArea, gjCategory, zcCategory,
        ...state.institutionInfo,
    };
}
export default connect(mapStatetoProps)(InstitutionInfo);