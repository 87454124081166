import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "USER_FEEDBACK_";

export const FETCH_FEEDBACK_SUCCED = PREFIX + "FETCH_FEEDBACK_SUCCED";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";
export const START_LOADING_DATA = PREFIX + "START_LOADING_DATA";
export const CHNAGE_STATUS = PREFIX + "CHANGE_STATUS";

export const changeStatus = data => ({ type: CHNAGE_STATUS, data });

const start_loading_data = () => ({ type: START_LOADING_DATA });

export const setActivePage = data => ({ type: SET_ACTIVE_PAGE, data });

export const setPageSize = data => ({ type: SET_PAGE_SIZE, data });

const fetch_feedback_succed = obj => ({ type: FETCH_FEEDBACK_SUCCED, data: obj });

/** 获取用户反馈列表 */
export const fetch_feedback = (params={}) => {
  let url = '/gk/ops/user/feedback/page';
  let data = { pageSize: 10 };
  params.page && (data.page = params.page);
  params.pageSize && (data.pageSize = params.pageSize);
  (params.status === 1) && (data.status = 1);
  (params.status === 0) && (data.status = 0);
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    credentials: 'include'
  };
  return async dispatch => {
    dispatch(start_loading_data());
    let json = await apiRequest(url, config);
    if (json.status === 1) {
      dispatch(fetch_feedback_succed(json.obj));
    }
  }
}

export async function updateStatus (id) {
  let url = `/gk/ops/user/feedback/updateStatus/${id}`;
  let config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  let json = await apiRequest(url, config);
  if (json.status === 1) {
    message.success('操作成功');
  }
}