import {
    RECEIVE_UPDATE_QUESTION_RESULT,
    RECEIVE_UPDATE_QUESTION_VIDEO_RESULT,
    REQUEST_UPDATE_QUESTION,
    REQUEST_UPDATE_QUESTION_VIDEO,
    REQUEST_QUESTION_INFO,
    UPDATE_QUESTION_REMARKS,
    UPDATE_QUESTION_POSITIONDESC,
    UPDATE_QUESTION_MAJOR,
    UPDATE_QUESTION_MAXYEAR,
    UPDATE_QUESTION_DEPTCODE,
    UPDATE_QUESTION_DEPTNAME,
    UPDATE_QUESTION_NUMBER,
    UPDATE_QUESTION_POSITIONCODE,
    UPDATE_QUESTION_POSITIONNAME,
    UPDATE_POSITION_TAGS,
    UPDATE_QUESTION_DEPTMOBILE,
    UPDATE_QUESTION_SEX,
    UPDATE_QUESTION_NOTICE,
    RECEIVE_QUESTION_INFO, 
    UPDATE_VIDEO_PROGRESS,
    UPDATE_URL,
    UPDATE_QUESTION_WORKEDYEAR,
    UPDATE_QUESTION_EDUCATION,
    UPDATE_SIGNED_VIDEO_URL,
    UPDATE_PROVINCE_TYPE,
    UPDATE_POSITION_TYPE,
    UPDATE_YEARS_TYPE,
    ADD_FILE_INPUT,
    SET_REDUX_KEY_VALUE
} from './actions';

export const NO_ANSWER = [];

export function selectedPosition(state = {
    isFetching: true,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: { area:[], year:'', files: [{name: '', path: ''}] },
    progress: "0",
}, action) {
    switch (action.type) { 
        case SET_REDUX_KEY_VALUE:
            // console.log('--', state)
            let data = action.data;
            let _info = Object.assign({}, state, {
                info: { ...state.info, [data.key]: data.value }
            });
            // console.log('==', _info.info)
            return  _info;   
        case UPDATE_PROVINCE_TYPE:
            return  Object.assign({}, state, {
                info: { ...state.info, area: action.value }
            });
        case UPDATE_YEARS_TYPE:
            return  Object.assign({}, state, {
                info: { ...state.info, year: action.value }
            });
        case ADD_FILE_INPUT:
            let fileData = state.info.files || []
            return Object.assign({}, state, {
                info: { ...state.info, files: [...fileData, action.value] }
            });
        case UPDATE_VIDEO_PROGRESS:
            return Object.assign({}, state, {
                progress: action.value
            });
        case REQUEST_QUESTION_INFO:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_QUESTION_INFO:
            console.log('action', action.question)
            let fileInfo = action.question?.files || [];
            if (!fileInfo || fileInfo.length <= 0) {
                fileInfo = [{name: '', path: ''}];
            }
            return Object.assign({}, state, {
                isFetching: false,
                info: {...action.question, files: fileInfo}
            });
        case REQUEST_UPDATE_QUESTION:
            return Object.assign({}, state, {isUpdating: true});
        case REQUEST_UPDATE_QUESTION_VIDEO:
            return Object.assign({}, state, {isVideoUpdating: true, progress: "0"});
        case RECEIVE_UPDATE_QUESTION_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_QUESTION_VIDEO_RESULT:
            return Object.assign({}, state, {isVideoUpdating: false, isVideoUpdated: true});
        case UPDATE_URL:
            // console.log('UPDATE_URL action.value', action)
            let _files = [];
            if (!action.value) {
                if (action.idx === 1 && state.info.files.length <= 1) {
                    _files = [{name: '', path: ''}];
                } else {
                    _files = state.info.files.filter((item, index) => index !== action.idx-1);
                }
            } else {
                if (action.idx) {
                    _files = state.info.files;
                    _files[action.idx-1] = Object.assign({}, {
                        name: action.value || '',
                        path: state.info.files[action.idx-1].path || ''
                    })
                } else {
                    _files = state.info.files;
                    _files[0] = Object.assign({}, {
                        name: action.value || '',
                        path: state.info.files[0].path || ''
                    })
                }
            }
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {files: _files})
            });
        case UPDATE_SIGNED_VIDEO_URL:
            let _fileList = [];
            if (action.idx) {
                _fileList = state.info.files;
                _fileList[action.idx-1] = Object.assign({}, {
                    name: state.info.files[action.idx-1].name || '',
                    path: action.value || '',
                })
            } else {
                _fileList = state.info.files;
                _fileList[0] = Object.assign({}, {
                    name: state.info.files[0].name || '',
                    path: action.value || '',
                })
            }
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {files: _fileList})
            });
        case UPDATE_POSITION_TYPE:
        case UPDATE_QUESTION_REMARKS:
        case UPDATE_QUESTION_POSITIONDESC:
        case UPDATE_QUESTION_MAJOR:
        case UPDATE_QUESTION_MAXYEAR:
        case UPDATE_QUESTION_DEPTCODE:
        case UPDATE_QUESTION_DEPTNAME:
        case UPDATE_QUESTION_NUMBER:
        case UPDATE_QUESTION_POSITIONCODE:
        case UPDATE_QUESTION_POSITIONNAME:
        case UPDATE_POSITION_TAGS:
        case UPDATE_QUESTION_DEPTMOBILE:
        case UPDATE_QUESTION_NOTICE:
        case UPDATE_QUESTION_WORKEDYEAR:
        case UPDATE_QUESTION_EDUCATION:
        case UPDATE_QUESTION_SEX:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    [action.key]: action.value
                })
            });
        default:
            return state;
    }
}
