import apiRequest from '../../common/request';

export const updateGoods = async data => {
  let url = '/gk/ops/product/saveOrUpdate';
  let config = { 
    method: 'POST', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify(data)
  };
  let json = await apiRequest(url, config);
  return json;
}

export const getGoodsDetail = async id => {
  let url = `/gk/ops/product/details/${id}`;
  let config = {
    method: 'GET', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
  };
  let json = await apiRequest(url, config);
  return json;
}