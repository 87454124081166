import {
  ROLE_TREE,
  ROLE_USER,
  TABLE_LOADING,

} from './action';

const initialState = {
  tableLoading: false,
  treeObj: [],
  roleList: []
};

export const roleTree = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_LOADING:
      return Object.assign({}, state, { tableLoading: true });
    case ROLE_TREE:
      return Object.assign({}, state, { treeObj: action.data });
    case ROLE_USER:
      return Object.assign({}, state, { roleList: action.data, tableLoading: false });
    default:
      return state;
  }
}
