import apiRequest from '../../common/request';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie'
import { message } from 'antd';
const PREFIX = 'CLASS_DETAIL_PREFIX';

export const SET_CURRENT_COURSENAME = PREFIX + 'SET_CURRENT_COURSENAME';        // 设置选中的课程
export const SET_DAY_NUMBER = PREFIX + 'SET_DAY_NUMBER';                        // 设置天数数组
export const SET_CURRENT_DAY = PREFIX + 'SET_CURRENT_DAY';                      // 设置选中的天
export const SET_FILTER_STATUS = PREFIX + 'SET_FILTER_STATUS';                  // 设置筛选状态
export const SET_COURSE_METADATA = PREFIX + 'SET_COURSE_METADATA';              // 设置课程元数据信息
export const LOADING_COURSE_METADATA = PREFIX + 'LOADING_COURSE_METADATA';      // 表示开始获取课程数据元信息
export const LOADING_STUDENT_LEARN_INFO = PREFIX + 'LOADING_STUDENT_LEARN_INFO';// 表示正在加载学员数据
export const SET_STUDENT_LEARN_INFO = PREFIX + 'SET_STUDENT_LEARN_INFO';        // 表示加载完了学员数据
export const SHOW_SIZE_CHANGE = PREFIX + 'SHOW_SIZE_CHANGE';                    // 每页展示多少条数据的修改
export const SET_CURRENT_PAGE = PREFIX + 'SET_CURRENT_PAGE';                    // 设置当前是第几页
export const SET_STUDENT_LIST = PREFIX + 'SET_STUDENT_LIST';                    // 设置学生数组，在进行搜索上会用上
export const FETCH_CLASS_DETAIL_SUCCED = PREFIX + 'FETCH_CLASS_DETAIL_SUCCED';  // 获取班级详情数据
export const UNLOADING_STUDENT_LEARN_INFO = PREFIX + 'UNLOADING_STUDENT_LEARN_INFO';// 表示取消加载学员数据
export const CHECK_LOADING = PREFIX + 'CHECK_LOADING';                          // 添加学员加载loading
export const UNLOADING = PREFIX + 'UNLOADING';                          

export const set_student_list = arr => ({
    type: SET_STUDENT_LIST,
    data: arr
});

const fetch_class_detail_succed = data => ({
    type: FETCH_CLASS_DETAIL_SUCCED,
    data
});

export const set_current_course = (courseName) => ({
    type: SET_CURRENT_COURSENAME,
    data: courseName
});

export const set_day_number = (data) => ({
    type: SET_DAY_NUMBER,
    data
});

export const set_filter_status = data => ({
    type: SET_FILTER_STATUS,
    data
});

export const set_course_metadata = data => ({
    type: SET_COURSE_METADATA,
    data
});

export const loading_course_metadata = () => ({
    type: LOADING_COURSE_METADATA
});

export const set_student_learn_info = data => ({
    type: SET_STUDENT_LEARN_INFO,
    data
});

export const loading_student_learn_info = () => ({
    type: LOADING_STUDENT_LEARN_INFO
});

export const unloading_student_learn_info = () => ({
    type: UNLOADING_STUDENT_LEARN_INFO
});

export const unloading = () => ({
    type: UNLOADING
});

export const set_current_day = data => ({    
    type: SET_CURRENT_DAY,
    data
});

export const show_size_change = data => ({
    type: SHOW_SIZE_CHANGE,
    data
});

export const set_current_page = data => ({
    type: SET_CURRENT_PAGE,
    data
});

export const checkLoading = data => ({
    type: CHECK_LOADING,
    data
});

/** 排序 */
export const setSortList = async (arr, cb) => {
    let url = `/gk/ops/student/edit/sort`;
    let config = {
        method: 'POST',
        body: JSON.stringify(arr),
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json'
        },
    };
    let json = await apiRequest(url, config);        
    if(json.status == 1) {
        cb && cb();
        message.success('操作成功');
    }
}

/** 检验学员是否做过该班级测试 */
export const studentCheckExercise = (mobile, classId) => {
    let url = `/gk/ops/student/checkExercise/${mobile}/${classId}`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        dispatch(checkLoading(true));
        return apiRequest(url, config)
        .then(json => {
            dispatch(checkLoading(false));
            json.obj.length && json.obj.map(item=>{
                item.classExerciseTitle.map(items=>{
                    message.error(`该学员已经做过${items}`);
                })
            })
        })
        .catch(error => console.log(error));
    }
};

/** 获取班级详情数据 */
export const fetch_class_detail = (classId, params) => {
    let url = `/gk/ops/student/classDetail/${classId}`;
    let config = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json'
        }
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        dispatch(fetch_class_detail_succed(json.obj));
    }
}

/** 根据courseId获取某门课程所对应的天数数据 */
export const get_day_num = (courseId) => {
    let url = `/gk/ops/course/class/course/number/${courseId}`;
    let config = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json'
        }
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        dispatch(set_day_number(json.obj));
        // 有些班级没有第一天，第一个数据展示接口提供的天数
        // 这个还是关上，因为每次回到页面天数都重置为1，影响体验
        // dispatch(set_current_day(json.obj[0].number));
    }
}

/** 根据课程的id和课程的天数获取当天课程的详细信息 */
export const get_course_metadata = (courseId, dayNumber) => {
    let url = `/gk/ops/course/class/getStat?courseId=${courseId}&courseNum=${dayNumber}`;
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json'
        }
    };
    return async dispatch => {
        dispatch(loading_course_metadata());
        let json = await apiRequest(url, config);
        dispatch(set_course_metadata(json.obj));
    }
}

/** 获取班级某天的练习完成情况 */
export const get_class_learninfo = (params={}) => {
    let data = {};
    params.classNo && (data.classNo = params.classNo);
    params.courseId && (data.courseId = params.courseId);
    params.courseNum && (data.courseNum = params.courseNum) || (data.courseNum = 1) ;
    params.page && (data.page = params.page);
    if (typeof params.status === 'number') {
        data.status = params.status;
    }
    let url = `/gk/ops/student/getClassLearnInfo`;
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        dispatch(loading_student_learn_info());
        try{
            let json = await apiRequest(url, config);
            if (json.status == 1) {
                dispatch(set_student_learn_info(json.obj));
                dispatch(unloading());
            } else {
                dispatch(unloading_student_learn_info());
            }
        }
        catch(err){
            dispatch(unloading_student_learn_info());
        }
    }
}

export const save_update_student = async (params, successCb, errCb) => {
    let url = '/gk/ops/student/saveOrUpdate';
    let data = {};
    params.sort && (data.sort = params.sort);
    params.classId && (data.classId = params.classId);
    params.id && (data.id = params.id);
    params.mobile && (data.mobile = params.mobile);
    params.studentName && (data.studentName = params.studentName);
    params.remark && (data.remark = params.remark);
    params.retreatDate && (data.retreatDate = params.retreatDate);//退课时间
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    let json = await apiRequest(url, config);
    json.status == 1 && successCb && successCb('操作成功');
    json.status == 2 && errCb && errCb(json.errorMes);
}

/** 导出班级Excel数据 */
export const down_class_excel = params => {
    let url = `/gk/ops/student/exportClassLearnInfo`;
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken'),
            'Sin-UserAgent-Sign': 'SintureGK-WEB',
            'Sin-Client-Type': 3
        },
        body: JSON.stringify(params)
    };
    return dispatch => {
        fetch(url, config).then(response => {
            response.blob().then(blobData => {
                let a = document.createElement('a');
                let href = window.URL.createObjectURL(blobData);
                a.href = href;
                a.download = `${params.classNo}-${params.courseId}-${params.courseNum}.xls`;
                document.body.appendChild(a).click();
                window.URL.revokeObjectURL(href);
            })
        }).catch(err => {
            message.error('网络错误');
        })
    }
}

/** 删除学员 */
export const deleteStudentFun = async id => {
    let url = `/gk/ops/student/delete?id=${id}`;
    let config = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json'
        },
    };
    let json = await apiRequest(url, config);
    if (json.status === 2) {
        message.warning(json.errorMes || '操作失败');
    } else {
        message.success('删除成功');
    }
}
