import React from 'react';
import { connect } from 'react-redux';
import { Spin, Breadcrumb, Select, Button, Radio, Tooltip, Popover } from 'antd';
import { Link } from 'react-router-dom';
import Xingce from '../../components/AnswerCardXinCe';
import Shenlun from '../../components/AnswerCardShenlun';
import {
    get_student_detail,
    get_day_num,
    get_excel_data,
    set_student_detail,
    change_filter_status,
} from './action';
import './index.scss';
import { checkPermission } from '../../utils/utils';

const Option = Select.Option;
const RadioGroup = Radio.Group;

const styles = {
    marginRight: { marginRight: 15 },
    marginBottom: { marginBottom: 15 },
    font15: { fontSize: 15 },
    daySelect: { width: '7em' }
};

class StudentDetail extends React.Component {
    constructor(props) {
        super(props);
        const { begin, end, beginDay, endDay } = this.props.match.params;
        this.state = {
            dayBegin: Number(begin) || Number(beginDay) || 1,
            dayEnd: Number(end) || Number(endDay) || 1,
            showComment: true,
            showNextAndPrevBtn: false,                               // 是否显示上下学员按钮区域
        };
    }

    componentDidMount() {
        this.init();
        this.props.getDayNum(this.courseId);
        this.update = async () => {
            this.courseId = this.props.match.params.courseId;
            this.studentId = this.props.match.params.userId;
            await this.props.getStudentDetail({ courseId: this.courseId, studentId: this.studentId, courseNumStart: this.state.dayBegin, courseNumEnd: this.state.dayEnd });
            this.props.getDayNum(this.courseId);
        };
        this.checkShowNextAndPrev();
    }

    /** 初始化方法，能够进行数据记载 */
    init = async nextProps => {
        let propsObj = nextProps ? nextProps.match : this.props.match;
        this.courseId = propsObj.params.courseId;
        this.studentId = propsObj.params.userId;
        this.beginDay = this.state.dayBegin;
        this.endDay = this.state.dayEnd;
        this.classId = propsObj.params.classId;
        this.setState({ dayBegin: this.beginDay, dayEnd: this.endDay });
        await this.props.getStudentDetail({ courseId: this.courseId, studentId: this.studentId, courseNumStart: this.beginDay, courseNumEnd: this.endDay });
    }

    componentWillReceiveProps(nextProps) {
        if (this.shouldUpdate(nextProps)) {
            this.init(nextProps);
        }
    }

    /** 检查更新是否需要引起组件更新 */
    shouldUpdate = (nextProps) => {
        let nextStudentId = nextProps.match.params.userId;
        let prevStudentId = this.props.match.params.userId;

        let nextBeginDay = nextProps.match.params.beginDay;
        let prevBeginDay = this.props.match.params.beginDay;

        let nextEndDay = nextProps.match.params.endDay;
        let prevEndDay = this.props.match.params.endDay;

        return (nextStudentId != prevStudentId) || (nextBeginDay != prevBeginDay)
            || (nextEndDay != prevEndDay);
    }

    /** 检查是否有必要出现底部的功能区域 */
    checkShowNextAndPrev = () => {
        let studentIds = '[]';
        try { studentIds = window.localStorage.getItem('studentIds'); }
        catch (err) { }
        studentIds = JSON.parse(studentIds);
        this.studentIds = studentIds;
        this.studentList = JSON.parse(localStorage.getItem('studentList'));
        let showNextAndPrevBtn = false;
        if (Object.prototype.toString.call(this.studentIds) !== '[object Array]') showNextAndPrevBtn = false;
        else showNextAndPrevBtn = this.studentIds.includes(+this.studentId);
        this.setState({ showNextAndPrevBtn });
    }

    updateMe = () => {
        this.update();
    }

    /** 开始天数变化时被调用 */
    handleCourseNumStart = value => {
        this.setState({ dayBegin: value });
        const status = 'all';
        this.props.changeFilterStatus(status);
    }

    /** 截止天数变化时被调用 */
    handleCourseNumEnd = value => {
        this.setState({ dayEnd: value });
        const status = 'all';
        this.props.changeFilterStatus(status);
    }

    /** 点击查询 */
    handleSearch = async () => {
        let params = {
            courseId: this.courseId,
            studentId: this.studentId,
            courseNumStart: this.state.dayBegin,
            courseNumEnd: this.state.dayEnd
        }
        if (this.props.filterStatus == 'done') params.status = 1;
        if (this.props.filterStatus == 'not') params.status = 0;
        await this.props.getStudentDetail(params);
        // let url = `/studentDetail/${this.props.match.params.courseId}/${this.studentId}/${this.state.dayBegin}/${this.state.dayEnd}/${this.props.match.params.classId}/${this.props.filterStatus}`;
        // this.props.history.replace(url);
    }

    /** 导出班级Excel数据 */
    handleExportStudentExcel = () => {
        let params = {};
        params.courseId = this.courseId;
        params.courseNumEnd = this.state.dayEnd;
        params.courseNumStart = this.state.dayBegin;
        params.studentId = this.studentId;
        this.props.getExcelData(params);
    }

    /** 筛选状态发生改变 */
    handleFilterStatus = async event => {
        const value = event.target.value;
        let params = {
            courseId: this.courseId,
            studentId: this.studentId,
            courseNumStart: this.state.dayBegin,
            courseNumEnd: this.state.dayEnd
        }
        await this.props.changeFilterStatus(value);
        if (this.props.filterStatus == 'done') params.status = 1;
        if (this.props.filterStatus == 'not') params.status = 0;
        await this.props.getStudentDetail(params);
    };

    /** 改变是否显示批注 */
    controlShowComment = (event) => {
        let v = this.state.showComment;
        v = !v;
        this.setState({ showComment: v });
    };

    /** 点击上一位学员 */
    prevStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex <= 0) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex, true);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 点击下一位学员 */
    nextStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex >= this.studentIds.length - 1) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 返回当前学员的ID */
    findCurrentStudentIdx = () => {
        if (Object.prototype.toString.call(this.studentIds) != '[object Array]') return;
        return this.studentIds.indexOf(+this.studentId);
    }

    /** 返回上下位学员的ID */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.studentIds[currentIndex - 1];
        else return this.studentIds[currentIndex + 1];
    }

    /** 页面跳转到上下位学员 */
    jump2NextPrevStudent = studentId => {
        const { dayBegin, dayEnd } = this.state;
        let url = `/studentDetail/${this.props.match.params.courseId}/${studentId}/${dayBegin}/${dayEnd}/${this.props.match.params.classId}/${this.props.filterStatus}`;
        this.props.history.replace(url);
    }

    /** 返回到当前班级学员列表页面 */
    backToCurrentClassStudent = () => {
        let pageUrl = `/classDetail?classId=${this.classId}`;
        this.props.history.replace(pageUrl);
    }

    /** 返回班级列表页面 */
    backToClassList = () => {
        this.props.history.replace(`/classList`);
    }

    /** 快速到达顶部位置 */
    scrollToTop = () => {
        let o = document.querySelector('.ant-layout-content');
        o.scrollTop = 0
    }

    /** 快速到达中间位置 */
    scrollToMiddle = () => {
        let o = document.querySelector('.ant-layout-content');
        let a = document.querySelector('.studentDetailWrapper');
        o.scrollTop = a.offsetHeight / 2
    }

    /** 快速到达底部位置 */
    scrollToBottom = () => {
        let o = document.querySelector('.ant-layout-content');
        let a = document.querySelector('.studentDetailWrapper');
        o.scrollTop = a.offsetHeight
    }

    /** 找到对应日期 */
    getcurrentDate = (day) => {
        let { dayNumberArr } = this.props;
        let str = ''
        dayNumberArr.map(item => {
            if(day == item.number) str = (item.date || '--');
        })
        return str;
    }

    render() {
        let { dayBegin, dayEnd } = this.state;
        const { courseNums, lastRecordTime, lastExerciseTime } = this.props.studentDetail
        let currentIndex = this.findCurrentStudentIdx();                                  // 当前学员的索引位置
        let prevNums = currentIndex;                                                      // 当前学员前面还有多少位学员
        let nextNums = this.studentIds ? this.studentIds.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
        let stuList = this.studentList && (
            <div className="popoverStu">
                {this.studentList.map((item, idx) => <p key={idx} className={['classStuList', item.id == this.studentId ? 'activeStu' : ''].join(' ')} onClick={() => this.jump2NextPrevStudent(item.id)}>{idx + 1 + '-' + item.studentName}</p>)}
            </div>
        ) || (<div>暂无列表</div>)

        return (
            <div className="studentDetailWrapper">
                {this.props.loadingDetail && <div className="loadingDiv"><Spin size="large" /></div> ||
                    <div>
                        <Breadcrumb style={styles.marginBottom}>
                            <Breadcrumb.Item><Link to={`/classList`} style={styles.font15}>班级列表</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/classDetail?classId=${this.classId}`} style={styles.font15}>学员列表</Link></Breadcrumb.Item>
                            <Breadcrumb.Item style={styles.font15}>学员详情</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="fixedMap">
                            <div className="mapFlex">
                                <span onClick={this.scrollToTop}>到达页面顶部位置</span>
                                <span onClick={this.scrollToMiddle}>到达页面中部位置</span>
                                <span onClick={this.scrollToBottom}>到达页面底部位置</span>
                            </div>
                        </div>

                        {this.props.studentDetail.exerciseType == '0' && <div className="bottom10"><span className="red">*</span>折线图数据以及学员提交答案数据都来自第一次答题记录</div>}
                        <div className="studentDetail-container">
                            {/** 顶部筛选栏开始 */}
                            <div style={{ padding: '15px 20px 0' }}>
                                <span>课程日期: {(this.props.dayNumberArr.length && this.getcurrentDate(dayBegin)) + ' 到 ' + (this.props.dayNumberArr.length && this.getcurrentDate(dayEnd))}</span>
                            </div>
                            <div className="filterDiv flexrc paddinglr">
                                <span style={styles.marginRight}>课程天数</span>
                                <Select defaultValue="第1天" value={`第${this.state.dayBegin}天`} onChange={this.handleCourseNumStart} style={Object.assign({}, styles.marginRight, styles.daySelect)}>
                                    {
                                        this.props.dayNumberArr.map((dayNum, idx) => (
                                            <Option key={idx} value={dayNum.number}>{dayNum.number}</Option>
                                        ))
                                    }
                                </Select>
                                <span style={styles.marginRight}>到</span>
                                <Select defaultValue="第1天" value={`第${this.state.dayEnd}天`} onChange={this.handleCourseNumEnd} style={Object.assign({}, styles.marginRight, styles.daySelect)}>
                                    {
                                        this.props.dayNumberArr.map((dayNum, idx) => (
                                            <Option key={idx} value={dayNum.number}>{dayNum.number}</Option>
                                        ))
                                    }
                                </Select>

                                <Button type="primary" onClick={this.handleSearch}>查询</Button>

                                <div className="flexg"></div>

                                <Radio checked={this.state.showComment} onChange={this.controlShowComment}>是否显示批注</Radio>

                                <RadioGroup onChange={this.handleFilterStatus} value={this.props.filterStatus} style={styles.marginRight}>
                                    <Radio value="all">全部</Radio>
                                    <Radio value="done">已完成</Radio>
                                    <Radio value="not">未完成</Radio>
                                </RadioGroup>
                                {checkPermission('student:learn:export') && <Button type="primary" onClick={this.handleExportStudentExcel}>导出学员练习数据Excel</Button>}
                            </div>
                            {/** 顶部筛选栏结束 */}

                            {/** 课程以及学员元数据信息开始 */}
                            <div className="metadata-container paddinglr">
                                <span className="spanRight">学生名：{this.props.studentDetail.studentName}</span>
                                {this.props.studentDetail.mobile && <span className="spanRight">联系方式：{this.props.studentDetail.mobile}</span>}
                                <span className="spanRight">班级名称：{this.props.studentDetail.className}</span>
                                <span className="spanRight">课程名称：{this.props.studentDetail.courseName}</span>
                            </div>
                            {/** 课程以及学员元数据信息结束 */}

                            <div>
                                {lastRecordTime && <span className="paddinglr recentTime">最近观看时间：{lastRecordTime}</span>}
                                {lastExerciseTime && <span className="paddinglr recentTime">最近做题时间：{lastExerciseTime}</span>}
                            </div>

                            {/** 练习数据开始展示 */}
                            <div className="practice-container">
                                {/** 行测练习开始 */}
                                {
                                    (this.props.studentDetail.exerciseType == 0
                                    || this.props.studentDetail.exerciseType == 3
                                    || this.props.studentDetail.exerciseType == 4) ?
                                        <Xingce
                                            classes={courseNums}
                                            lastRecordTime={lastRecordTime}
                                            lastExerciseTime={lastExerciseTime}
                                        />
                                        :
                                        <div></div>
                                }
                                {/** 行测练习结束 */}

                                {/** 申论练习开始 */}
                                {
                                    (this.props.studentDetail.exerciseType == 1
                                    || this.props.studentDetail.exerciseType == 5) ?
                                        <Shenlun 
                                            classes={courseNums}
                                            showComment={this.state.showComment}
                                            update={this.updateMe}
                                            lastExerciseTime={lastExerciseTime}
                                            lastRecordTime={lastRecordTime}
                                            classType={this.props.studentDetail.exerciseType}
                                        />
                                        :
                                        <div></div>
                                }
                                {/** 申论练习结束 */}
                            </div>
                            {/** 练习数据展示结束 */}
                        </div>
                    </div>}
                <div className={['nextAndPrevBtn', this.state.showNextAndPrevBtn ? 'opacity1' : ""].join(' ')}>
                    <div className="flexRow height100">
                        <span className="userName">{this.props.studentDetail.studentName}</span>
                        <span className="flexg"></span>
                        <Button onClick={this.backToClassList} type="primary" className="mr30">返回班级列表页面</Button>
                        <Button onClick={this.backToCurrentClassStudent} type="primary" className="mr30">返回学员列表页面</Button>
                        <Tooltip title={`前面还有${prevNums}位学员`}>
                            <Button disabled={this.studentIds && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一位学员</Button>
                        </Tooltip>
                        <Tooltip title={`后面还有${nextNums}位学员`}>
                            <Button disabled={this.studentIds && currentIndex >= this.studentIds.length - 1} onClick={this.nextStudent} type="primary" className="mr30">下一位学员</Button>
                        </Tooltip>
                        <Popover content={stuList} title="学员列表" trigger="click">
                            <Button type="primary">学员列表</Button>
                        </Popover>
                    </div>
                </div>
            </div>
        );
    }
}

StudentDetail.defaultProps = {
    loadingDetail: false,                   // 表示正处于加载学员某段时间的详细练习信息中
    studentDetail: {},                      // 学员某段时间的详细练习数据对象
    dayNumberArr: [],                       // 该门课程所对应的天数数组
    originalStudentDetail: {},              // 从服务器获取的原对象
};

const mapStateToProps = (storeState, ownProps) => ({ ...storeState.studentDetail });

const mapDispatchToProps = dispatch => ({
    getStudentDetail: async (params) => { await dispatch(get_student_detail(params)); },    // 获取学员某段时间的详细练习数据对象
    getDayNum: courseId => { dispatch(get_day_num(courseId)); },                // 获取某门课程所对应的天数，为什么不走classDetail的redux？因为在这个页面如果进行刷新的话将会导致状态丢失，影响体验
    getExcelData: params => { dispatch(get_excel_data(params)); },              // 导出学员Excel文档数据
    setStudentDetail: dataObj => { dispatch(set_student_detail(dataObj)); },    // 设置学员某段时间的详细练习数据对象
    changeFilterStatus: value => { dispatch(change_filter_status(value)); },              // 更改筛选默认值
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetail);
