import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "COMMON_SENSE_LIST";

export const RECEIVE_TAG_LIST = PREFIX + "RECEIVE_TAG_LIST";
export const REQUEST_COMMONSENSE_LIST = PREFIX + "REQUEST_COMMONSENSE_LIST";
export const RECEIVE_COMMONSENSE_LIST = PREFIX + "RECEIVE_COMMONSENSE_LIST";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

function request_commonsense_list() {
    return {
        type: REQUEST_COMMONSENSE_LIST
    }
}

function receive_commonsense_list(data) {
    return {
        type: RECEIVE_COMMONSENSE_LIST,
        data,
    }
}

function receive_tag_list(value) {
    return {
        type: RECEIVE_TAG_LIST,
        value
    }
}

export function fetchCommonSenses(page, pageSize=10) {
    let config = {
        method: 'POST',
        body: JSON.stringify({ page:page||1, pageSize }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_commonsense_list());
        apiRequest('/gk/ops/commonSense/page', config)
            .then(json => {
                dispatch(receive_commonsense_list(json.obj));
            });
    }

}


export function deleteCommonSense(id, page) {
    return dispatch => {
        apiRequest('/gk/ops/commonSense/deleteById/?id=' + id, {credentials: 'include', method: 'POST'})
            .then(() => {
                message.success('操作成功');
                dispatch(fetchCommonSenses(page))
            });
    }
}

export function fetchTagList(){
    return dispatch => {
        apiRequest(`/gk/ops/commonSense/label/list`, {credentials: 'include', method: 'POST'})
            .then(json => {
                dispatch(receive_tag_list(json.obj));
            });
    }
}


export function delTag(id, type) {
    return dispatch => {
        apiRequest('/gk/ops/commonSense/label/delete?label=' + id, {credentials: 'include', method: 'POST'})
            .then(() => {
                message.success('操作成功');
                dispatch(fetchTagList())
            });
    }
}

export function newTag(tag) {
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        apiRequest(`/gk/ops/commonSense/label/saveOrUpdate?label=${tag}`, config)
            .then(() => {
                message.success('操作成功');
                dispatch(fetchTagList());
            });
    }
}