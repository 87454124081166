import React from 'react';
import { connect } from 'react-redux';
import { Radio, Popover, Tree, Tooltip, Input, Button, Spin } from "antd";
import { PlusOutlined, EditFilled } from '@ant-design/icons';
import { checkPermission } from '../../utils/utils';
import {
    fetchQuestionCategories,
    addQuestionCategory,
    updateQuestionCategory,
    fetchGJCategories,
    fetchCourseCategories,
    addCourseCategory,
    updateCourseCategory,
    fetchZCCategories,
    fetchZYCategories
} from './actions';
import './index.scss'

class ModuleSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zcChooseType: 'zc_question_type',
            showRenameId: '',
            showAddId: '',
            renameVal: '',
            sort: 0,
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchZYCategories());
        this.props.dispatch(fetchZCCategories());
        this.props.dispatch(fetchGJCategories());
        this.props.dispatch(fetchQuestionCategories());
        this.props.dispatch(fetchCourseCategories());
    }

    renameChange = (e) => {
        this.setState({ renameVal: e.target.value });
    }

    sortChange = (value) => {
        this.setState({ sort: value });
    }

    /** 重命名方法，注意是那种类型 */
    renameClick = (id, pid, type, dataType) => {
        if (type === 'zy') {
            this.props.dispatch(updateQuestionCategory(id, this.state.renameVal, pid, dataType));
        } else if (type === 'zc') {
            this.props.dispatch(updateQuestionCategory(id, this.state.renameVal, pid, dataType));
        } else if (type === 'gj') {
            this.props.dispatch(updateQuestionCategory(id, this.state.renameVal, pid, dataType));
        } else if (type === 'question') {
            this.props.dispatch(updateQuestionCategory(id, this.state.renameVal, pid, dataType));
        } else if(type === 'course') {
            this.props.dispatch(updateCourseCategory(id, this.state.renameVal, this.state.sort));
        }
        this.setState({ showRenameId: '' });
    }

    /** 新增分类方法，注意是那种类型 */
    addOptionClick = (pid, type, zcType) => {
        if (type === 'zy') {
            this.props.dispatch(addQuestionCategory('zongying', this.state.renameVal, pid));
        } else if (type === 'zc') {
            // 职测情况要特殊处理，第一级的id是自定义id，不能使用，需要重置为0
            // 当新增分类选择类型时，zcType的值需要使用选择的值
            let pid2 = pid;
            let zcType2 = zcType;
            if(typeof pid == 'string') pid2 = 0;
            if(this.state.zcChooseType != 'zc_question_type') zcType2 = this.state.zcChooseType;
            this.props.dispatch(addQuestionCategory(zcType2, this.state.renameVal, pid2));
        } else if (type === 'gj') {
            this.props.dispatch(addQuestionCategory('gj_question_type', this.state.renameVal, pid));
        } else if (type === 'question') {
            this.props.dispatch(addQuestionCategory('gk_question_types', this.state.renameVal, pid));
        } else if(type === 'course') {
            this.props.dispatch(addCourseCategory(this.state.renameVal));
        }
        this.setState({ showAddId: '',  zcChooseType: 'zc_question_type'});
    }

    render() {
        const { zyLoading, gjLoading, zcLoading, zyCategory, zcCategory, gjCategory, questionCategory, courseCategory, courseLoding, questionLoding } = this.props
        
        const rename = (dataType, id, pid, type, sort) => (
            <div>
                <Input
                    style={{ width: '120px', marginRight: '10px', borderRadius: '10px' }}
                    size="small"
                    value={this.state.renameVal}
                    onChange={this.renameChange}
                />
                {sort && <span><span>排序 </span><Input
                    style={{ width: '50px', marginRight: '10px' }}
                    type="number"
                    min={0}
                    value={this.state.sort}
                    size="small"
                    onChange={e=>this.sortChange(e.target.value)}
                /></span>}
                <Button type="link" size="small" onClick={() => this.renameClick(id, pid, type, dataType)}>确定</Button>
                <Button type="link" size="small" onClick={() => this.setState({ showRenameId: '', renameVal: "" })}>取消</Button>
            </div>
        )
        const addOption = (pid, type, zcType) => (
            <div>
                {type=='zc' && <div style={{marginBottom: '20px'}}>
                    <Radio.Group size="small" defaultValue={zcType} onChange={e=>this.setState({zcChooseType: e.target.value})}>
                        <Radio value={'zc_question_type'}>非联考</Radio>
                        <Radio value={'lkA_question_type'}>A类</Radio>
                        <Radio value={'lkB_question_type'}>B类</Radio>
                        <Radio value={'lkC_question_type'}>C类</Radio>
                        <Radio value={'lkD_question_type'}>D类</Radio>
                    </Radio.Group>
                </div>}
                <Input
                    style={{ width: '120px', marginRight: '10px', borderRadius: '10px' }}
                    size="small"
                    onChange={this.renameChange}
                />
                <Button type="link" size="small" onClick={() => this.addOptionClick(pid, type, zcType)}>确定</Button>
                <Button type="link" size="small" onClick={() => this.setState({ showAddId: '', renameVal: "" })}>取消</Button>
            </div>
        )

        /** 职测1，2级不需要重命名功能，第三级才是真数据 */
        const loop = (data, type) => {
            return data.map((item, idx) => {
                return {
                    title: <div><span className="sonName">{(idx+1) + '.' + item.name}</span>
                        { (item.doShow != false) && checkPermission('course:category:edit') && <Tooltip title="重命名">
                            <Popover content={rename(item.type, item.id, item.parentId, type, !!(item.sort+1))} title="重命名" trigger="click"
                                visible={item.id === this.state.showRenameId}
                            >
                                <span className="iconModule" onClick={() => this.setState({ sort: item.sort+1, renameVal: item.name, showRenameId: item.id })}><EditFilled /></span>
                            </Popover>
                        </Tooltip>}
                        {checkPermission('course:category:edit') && <Tooltip title="添加子类">
                            <Popover content={addOption(item.id, type, item.type)} title="添加子类" trigger="click"
                                visible={item.id === this.state.showAddId}>
                                <span className="iconModule" onClick={() => this.setState({ showAddId: item.id })}><PlusOutlined /></span>
                            </Popover>
                        </Tooltip>}
                    </div>,
                    key: item.id,
                    children: item.subset && item.subset.length && loop(item.subset, type) || []
                }
            })
        }

        return (
            <div className="module">
                <h3 style={{ margin: "0 0 20px" }}>事业单位</h3>

                {zcCategory.length &&
                    <Tree
                        treeData={[{
                            title: <div><span className="rootName">职测分类</span></div>,
                            key: 'zcTree',
                            children: loop(zcCategory, 'zc')
                        }]}
                    /> || (zcLoading && <Spin /> || '暂无结构')
                }

                {zyCategory.length &&
                    <Tree
                        treeData={[{
                            title: <div><span className="rootName">综应分类</span>
                                {checkPermission('course:category:edit') && <Tooltip title="添加分类">
                                    <Popover content={addOption(0, 'zy')} title="添加分类" trigger="click" visible={'zy' === this.state.showAddId}>
                                        <span className="iconModule" onClick={() => this.setState({ showAddId: 'zy' })}><PlusOutlined /></span>
                                    </Popover>
                                </Tooltip>}
                            </div>,
                            key: 'zy',
                            children: loop(zyCategory, 'zy')
                        }]}
                    /> || (zyLoading && <Spin /> || '暂无结构')
                }
                
                {gjCategory.length &&
                    <Tree
                        treeData={[{
                            title: <div><span className="rootName">公基分类</span>
                                {checkPermission('course:category:edit') && <Tooltip title="添加分类">
                                    <Popover content={addOption(0, 'gj')} title="添加分类" trigger="click" visible={'gj' === this.state.showAddId}>
                                        <span className="iconModule" onClick={() => this.setState({ showAddId: 'gj' })}><PlusOutlined /></span>
                                    </Popover>
                                </Tooltip>}
                            </div>,
                            key: 'gj',
                            children: loop(gjCategory, 'gj')
                        }]}
                    /> || (gjLoading && <Spin /> || '暂无结构')
                }

                <h3 style={{ margin: "0 0 20px" }}>公务员</h3>
                {questionCategory.length &&
                    <Tree
                        treeData={[{
                            title: <div><span className="rootName">行测分类</span>
                                {checkPermission('course:category:edit') && <Tooltip title="添加分类">
                                    <Popover content={addOption(0, 'question')} title="添加分类" trigger="click" visible={'st' === this.state.showAddId}>
                                        <span className="iconModule" onClick={() => this.setState({ showAddId: 'st' })}><PlusOutlined /></span>
                                    </Popover>
                                </Tooltip>}
                            </div>,
                            key: 'root',
                            children: loop(questionCategory, 'question')
                        }]}
                    /> || (questionLoding && <Spin /> || '暂无结构')
                }
                <h3 style={{ margin: "0 0 20px" }}>在线课程</h3>
                {courseCategory.length &&
                    <Tree
                        treeData={[{
                            title: <div><span className="rootName">课程分类</span>
                                {checkPermission('course:category:edit') && <Tooltip title="添加分类">
                                    <Popover content={addOption(0, 'course')} title="添加分类" trigger="click" visible={'kc' === this.state.showAddId}>
                                        <span className="iconModule" onClick={() => this.setState({ showAddId: 'kc' })}><PlusOutlined /></span>
                                    </Popover>
                                </Tooltip>}
                            </div>,
                            key: 'root2',
                            children: loop(courseCategory, 'course')
                        }]}
                    /> || (courseLoding && <Spin /> || '暂无结构')
                }
            </div>
        )
    }
}

function mapStatetoProps(state) {

    const {
        isFetching: questionLoding,
        items: questionCategory
    } = state.questionCategories;

    const {
        isFetching: courseLoding,
        items: courseCategory
    } = state.courseCategories;
    return {
        ...state.questionCategories,
        courseLoding,
        questionLoding,
        questionCategory,
        courseCategory
    };
}

export default connect(mapStatetoProps)(ModuleSetting);