import { combineReducers } from 'redux';
import {interviewQuestions} from "../components/InterviewSelectCard/reducer"
import {videoPicker} from "../components/VideoPicker/reducer";
import {imagePicker} from "../components/ImagePicker/reducer"
import {shenlunQuestions} from "../components/ShenlunSelectCard/reducer";
import {questionSelectCard} from "../components/QuestionSelectCard/reducer";
import { questions } from "../containers/QuestionList/reducer";
import { positionList } from "../containers/PositionList/reducer";
import {questionCategories, courseCategories} from '../containers/ModuleSetting/reducer';
import {areaCategories, typeCategories} from '../containers/PositionConfig/reducer';
import { roleTree } from "../containers/Role/reducer";
import {upload} from "../containers/UploadWord/reducer";
import {quesCorrection} from "../containers/QuesCorrection/reducer";
import {materialQuestionList} from "../containers/MaterialQuestionList/reducer";
import {userFeedback} from "../containers/UserFeedback/reducer";
import {infoSystemPush} from "../containers/InfoSystemPush/reducer";
import {infoPushRelease} from "../containers/InfoPushRelease/reducer";
import {positionPush} from "../containers/PositionPush/reducer";
import {commonSenses} from "../containers/CommonSenseList/reducer";
import {tvList} from "../containers/TvList/reducer";
import {tvInfo} from "../containers/TvInfo/reducer";
import {dailyExamines} from '../containers/DailyExamineList/reducer'
import {dailyExamineRelease} from "../containers/DailyExamineRelease/reducer";
import {userConfigPage} from "../containers/UserConfigPage/reducer";
import {courseList} from "../containers/CourseList/reducer"
import {moduleBannerList} from "../containers/ModuleBannerList/reducer"
import {bannerList} from "../containers/BannerList/reducer";
import {bannerRelease} from "../containers/BannerRelease/reducer";
import {essayCategory} from "../containers/EssayCategory/reducer";
import {essayList} from "../containers/EssayList/reducer";
import {essayInfo} from "../containers/EssayInfo/reducer";
import {goodsOrderList} from "../containers/GoodsOrder/reducer";
import {goodsList} from "../containers/Goods/reducer";
import {orderReducer} from "../containers/Order/reducer";
import {orderDetailReducer} from "../containers/OrderDetail/reducer";
import {interviewCategory} from "../containers/InterviewCategory/reducer";
import {interviewList} from "../containers/InterviewList/reducer";
import {selectedQuestion} from "../containers/QuestionInfo/reducer";
import {selectedPosition} from "../containers/PositionInfo/reducer";
import {dataOrder} from "../containers/DataOrder/reducer";
import {interviewClass} from "../containers/InterviewClass/reducer";
import {classList} from "../containers/ClassList/reducer";
import {studentInfo} from "../containers/StudentInfo/reducer";
import {contractOrder} from "../containers/ContractOrder/reducer";
import {inquiryDetail} from "../containers/InquiryDetail/reducer";
import {inquiryReducer} from "../containers/Inquiry/reducer";
import {batchReducer} from "../containers/Batch/reducer";
import {changeUserInfo} from "../containers/AddOrModify/reducer";
import {positionMenuInfo} from "../containers/PositionMenu/reducer";
import {batchClassReducer} from "../containers/BatchClass/reducer";
import {interviewClassExercise} from "../containers/InterviewClassExercise/reducer";
import {interviewClassTest} from "../containers/InterviewClassTest/reducer";
import {interviewStudentList} from "../containers/InterviewStudentList/reducer";
import {interviewStudent} from "../containers/InterviewStudent/reducer";
import {iStudentInfo} from "../containers/IStudentInfo/reducer";
import {interviewCandidateList} from "../containers/InterviewCandidateList/reducer";
import {interviewStudentDetail} from "../containers/InterviewStudentDetail/reducer";
import {classExercise} from "../containers/ClassExercise/reducer";
import {classTestInfo} from "../containers/ClassTestInfo/reducer";
import {exerciseRank} from "../containers/ExerciseRank/reducer";
import {exerciseRankStudent} from '../containers/ExerciseRankStudent/reducer';
import {examineList} from "../containers/ExamineList/reducer";
import {examineDetail} from "../containers/ExamineDetail/reducer";
import {materialQuestionInfo} from "../containers/MaterialQuestionInfo/reducer";
import {classDetail}  from "../containers/ClassDetail/reducer";
import {studentDetail} from "../containers/StudentDetail/reducer";
import {courseInfo} from "../containers/CourseInfo/reducer";
import {courseClassInfo} from "../containers/CourseClassInfo/reducer";
import {courseClassList} from "../containers/CourseClassList/reducer";
import {dailyExamineInfo} from "../containers/DailyExamineInfo/reducer";
import {shenlunDetail} from "../containers/ShenlunDetail/reducer";
import {shenlunStudentDetail} from "../containers/ShenlunStudentDetail/reducer";
import {materialQuestionRelease} from "../containers/MaterialQuestionRelease/reducer";
import {interviewInfo} from "../containers/InterviewInfo/reducer";
import {paperList} from "../containers/PaperList/reducer";
import {paperDetail} from "../containers/PaperDetail/reducer";
import {institutionList} from "../containers/InstitutionList/reducer";
import {institutionInfo} from "../containers/InstitutionInfo/reducer";
import {zhyList} from "../containers/ZhyList/reducer";
import {zhyInfo} from "../containers/ZhyInfo/reducer";
import {doList} from "../containers/DoList/reducer";

export default combineReducers({
    doList,
    zhyInfo,
    zhyList,
    institutionInfo,
    institutionList,
    paperList,
    paperDetail,
    interviewInfo,
    materialQuestionRelease,
    shenlunStudentDetail,
    shenlunDetail,
    dailyExamineInfo,
    courseClassList,
    courseClassInfo,
    courseInfo,
    studentDetail,
    classDetail,
    materialQuestionInfo,
    examineDetail,
    examineList,
    exerciseRankStudent,
    exerciseRank,
    classTestInfo,
    classExercise,
    interviewStudentDetail,
    interviewCandidateList,
    iStudentInfo,
    interviewStudent,
    interviewStudentList,
    interviewClassTest,
    interviewClassExercise,
    batchClassReducer,
    changeUserInfo,
    positionMenuInfo,
    batchReducer,
    inquiryReducer,
    inquiryDetail,
    contractOrder,
    studentInfo,
    classList,
    interviewClass,
    dataOrder,
    selectedQuestion,
    selectedPosition,
    interviewList,
    interviewCategory,
    orderDetailReducer,
    orderReducer,
    goodsList,
    goodsOrderList,
    essayList,
    essayInfo,
    essayCategory,
    bannerRelease,
    bannerList,
    moduleBannerList,
    courseList,
    userConfigPage,
    dailyExamineRelease,
    dailyExamines,
    tvList,
    tvInfo,
    commonSenses,
    infoPushRelease,
    infoSystemPush,
    positionPush,
    userFeedback,
    materialQuestionList,
    quesCorrection,
    upload,
    roleTree,
    courseCategories,
    questionCategories,
    typeCategories,
    areaCategories,
    questions,
    positionList,
    shenlunQuestions,
    questionSelectCard,
    imagePicker,
    videoPicker,
    interviewQuestions,
});
