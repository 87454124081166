import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Radio, Input, Button, Tooltip, Table } from "antd";
import { fetchMaterials, setValue, resetQueryValue } from "./action";
import { Link } from "react-router-dom";
import { checkPermission } from '../../utils/utils';

export class MaterialQuestionList extends Component {

    /** 没有公基权限，渲染职测 */
    async componentDidMount() {
        if (!checkPermission('sys:material:page') || !checkPermission('gj:material:page')) {
            await this.props.dispatch(setValue({ key: 'type', val: 'zc' }));
        } else if (!checkPermission('sys:material:page') || !checkPermission('zc:material:page')) {
            await this.props.dispatch(setValue({ key: 'type', val: 'gj' }));
        } else if (!checkPermission('gj:material:page') || !checkPermission('zc:material:page')) {
            await this.props.dispatch(setValue({ key: 'type', val: 'xc' }));
        }
        this.changeType(false, this.props.type);
    }

    /** 页面初始化加载数据判断
     * @param boolean: 是否需要重置
     * @param type : xc gj zc
     * 页面初始化不需要重置状态
     * 点击切换需要重置状态
     */
    changeType = async (boolean, type) => {
        boolean && await this.props.dispatch(resetQueryValue());
        await this.props.dispatch(setValue({ key: 'type', val: type }));
        this.senPage();
    }

    /** 获取数据列表 */
    senPage = () => {
        let { options, page, pageSize, query, type } = this.props;
        let data = {
            options, page, pageSize, query
        }
        this.props.dispatch(fetchMaterials(type, data));
    }

    pageChange = async (current, pageSize) => {
        await this.props.dispatch(setValue({ key: 'page', val: current }));
        await this.props.dispatch(setValue({ key: 'pageSize', val: pageSize }));
        this.senPage();
    }

    getTooltip = (question) => {
        return <div dangerouslySetInnerHTML={{ __html: question ? question.content : '' }}></div>
    };

    /** 生成antd table的配置数据 */
    generateTableConfig = () => {
        let columns = [
            {
                title: '内容',
                width: '40%',
                render: (text, record) => {
                    return (
                        <Tooltip title={this.getTooltip(record)}>
                            {checkPermission(this.getType('add')) &&
                                <Link className="ellipsis2" to={`/materialQuestion/${this.props.type}/${record.id}`} dangerouslySetInnerHTML={{ __html: record.content }}></Link>
                                || <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></span>}
                        </Tooltip>
                    );
                }
            },
            { title: '类型', width: '10%', render: (text, record) => this.getName() },
            { title: '创建时间', width: '20%', dataIndex: 'createTime' },
            { title: '题目数量', width: '10%', dataIndex: 'questionNum' },
            {
                title: '视频数',
                width: '20%',
                render: (text, record) => <span>{record.videoNum}/{record.questionNum}</span>
            }
        ];
        return columns;
    }

    onChangeInput = (val, key) => {
        this.props.dispatch(setValue({ key: key, val: val }));
    }

    onPressEnterChange = async () => {
        await this.props.dispatch(setValue({ key: 'page', val: 1 }));
        this.senPage();
    }

    resetQuery = async () => {
        await this.props.dispatch(resetQueryValue());
        this.senPage()
    }

    addNewQues = () => {
        this.props.history.push(`/publishMaterialQuestion/${this.props.type}`);
    }

    getName = () => {
        let { type } = this.props;
        if (type == 'xc') return '行测';
        if (type == 'gj') return '公基';
        if (type == 'zc') return '职测';
        return '';
    }

    tableTitle = () => (
        <div className="commonTableTitle">
            <div>{this.getName()}资料题列表</div>
            {checkPermission(this.getType('add')) && <Button type="primary" onClick={() => this.addNewQues()}>添加{this.getName()}资料题</Button>}
        </div>
    )

    /** 动态权限判断方法 */
    getType = (str) => {
        let { type } = this.props;
        if (str == 'go') {
            if (type == 'xc') return 'sys:material:info';
            if (type == 'gj') return 'gj:material:info';
            if (type == 'zc') return 'zc:material:info';
        }
        if (str == 'add') {
            if (type == 'xc') return 'sys:material:edit';
            if (type == 'gj') return 'gj:material:edit';
            if (type == 'zc') return 'zc:material:edit';
        }
        return '';
    }

    render() {
        let { type, options, page, pageSize, query, items, totalElements, isFetching } = this.props;
        return (
            <div>
                <Radio.Group value={type} onChange={(e) => this.changeType(true, e.target.value)} defaultValue="xc" buttonStyle="solid">
                    {checkPermission('sys:material:page') && <Radio.Button value="xc">行测</Radio.Button>}
                    {checkPermission('gj:material:page') && <Radio.Button value="gj">公基</Radio.Button>}
                    {checkPermission('zc:material:page') && <Radio.Button value="zc">职测</Radio.Button>}
                </Radio.Group>
                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>试题搜索</label>
                        <Input value={query} style={{ width: 400 }} onChange={(e) => this.onChangeInput(e.target.value, 'query')} onPressEnter={() => this.onPressEnterChange()} placeholder="输入后回车键搜索结果" />
                    </div>
                    <div className="itemSearchBox">
                        <label>选项搜索</label>
                        <Input value={options} style={{ width: 400 }} onChange={(e) => this.onChangeInput(e.target.value, 'options')} onPressEnter={() => this.onPressEnterChange()} placeholder="输入后回车键搜索结果" />
                    </div>
                    <div className="itemSearchBox"><Button type="primary" onClick={() => this.onPressEnterChange()}>搜索</Button></div>
                    <div className="itemSearchBox"><Button danger onClick={() => this.resetQuery()}>重置</Button></div>
                </div>

                <Table
                    columns={this.generateTableConfig()}
                    dataSource={items}
                    rowKey={record => record.id}
                    title={() => this.tableTitle()}
                    loading={isFetching}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onChange: this.pageChange,
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.materialQuestionList }
}

export default connect(mapStateToProps)(MaterialQuestionList)
