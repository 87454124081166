import {
    LOADING,
    FETCH_ORDERS_SUCCED,
    POST_NOTE_SUCCED,
    SET_REDUX_KEY_VALUE,
    RESET_FILTER_STATUS
} from './actions.js';

/** 这是筛选状态 */
const searchParams = {
    endTime: null,                               // 订单结束时间
    goodsName: null,                             // 商品名称
    isSendOut: null,                             // 是否发货
    logisticsNo: null,                           // 物流单号
    outTradeNo: null,                            // 订单号
    page: 1,                                     // 第几页
    pageSize: 10,                                // 每页多少条
    startTime: null,                             // 订单起始时间
    status: null,                                // 订单状态
    userName: null,                              // 客户名
    minAmount: null,                             //最低价格
    maxAmount: null,                             //最高价格
    orderMobile: null,                            //手机号码
};

// 默认状态，需要保存筛选状态，避免当查看订单详情的时候点击返回的时候所出现的状态丢失
const initialState = {
    loading: false,
    arr:[],
    totalElements: 0,                           // 所有的元素总数
    ...searchParams
};

export function orderReducer(previousState=initialState, action) {
    switch(action.type) {
        case LOADING:
            return {...previousState, loading: true};
        case FETCH_ORDERS_SUCCED:
            return {...previousState,loading: false, arr: action.data.obj.content, totalPage: action.data.obj.totalPages, totalElements: action.data.obj.totalElements};
        case POST_NOTE_SUCCED:
            return previousState;
        case SET_REDUX_KEY_VALUE:
            let data = action.data;
            return {...previousState, [data.key]: data.value};
        case RESET_FILTER_STATUS:
            return { ...previousState, ...searchParams };
        default:
            return previousState;
    }
}