import React from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie'
import { Modal, message, Tooltip } from 'antd';
import { UserSwitchOutlined, ShareAltOutlined } from '@ant-design/icons';
import apiRequest from '../../common/request';
import './style.scss'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    _handleLogout = async (e) => {        
        e.preventDefault();
        let url = `/gk/ops/logout`;
        let obj = await apiRequest(url);
        if(obj.status == 1) {
            Cookies.remove('accessToken');
            localStorage.removeItem("user");
            localStorage.removeItem("routers");
            this.props.history.replace("/login");
            message.success('退出成功');
        } else {
            message.error('退出失败');
        }  
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };
    getHostName = val => {
        if (val.includes('www.51jdsy.com')) {
            return '线上'
        } else if (val.includes('localhost')) {
            return '本地'
        } else {
            return '未知'
        }
    }

    render() {
        let host = window.location.host
        let userObj = localStorage.getItem('user')!='undefined' && JSON.parse(localStorage.getItem('user'));
        return (
            <div className="x-navbar">
                <Modal
                    title="推荐安装并使用谷歌(Chrome)浏览器打开后台"
                    visible={this.state.visible}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                >
                    <img alt="google" src="https://www.google.cn/chrome/static/images/chrome-logo.svg" />
                    官方下载地址:<a target="_blank" rel="noreferrer" href="https://www.google.cn/intl/zh-CN/chrome/">Chrome浏览器 <i className="fa fa-external-link" /></a>
                    <h6>1、浏览器简洁性能高</h6><span style={{fontSize: '12px'}}>Chrome画面简洁，性能是所有浏览器中最好的</span>
                    <h6>2、全面支持HTML5以及浏览器兼容问题极少</h6><span style={{fontSize: '12px'}}>Chrome兼容起来最简单！HTML5和CSS3可以给用户带来高层次的视觉和体验</span>
                    <h6>3、丰富的插件</h6><span style={{fontSize: '12px'}}>Chrome插件系统很强大，可体验到Chrome的最爽的地方，如各种辅助工具、屏蔽广告、开发工具等等</span>
                    <h6>4、快速搜索体验</h6><span style={{fontSize: '12px'}}>Chrome所具备的智能工具和非凡速度都能让您在网络世界得心应手地操作、创作和探索，可在任何操作系统中和任何设备上运行</span>
                    <h6>5、安全高效处理</h6><span style={{fontSize: '12px'}}>Chrome会自动为您提供安全保护，使您免遭网上诱骗网站和危险网站等安全问题的侵害。在您的地址栏中快速显示答案、提供一键式翻译英文网页</span>
                </Modal>

                <div className="navbar-menu">
                    <span>当前环境:<span className="info">【{this.getHostName(host)}】</span></span>
                    {
                        userObj!=null && <span>用户: <span className="info">{userObj.nickName}</span></span>
                    }
                    <span onClick={this.showModal}>
                        关于浏览器 <ShareAltOutlined />
                    </span>
                    <span onClick={this._handleLogout}>
                        退出登录 <UserSwitchOutlined />
                    </span>
                    <Tooltip title="个人中心">
                        <img onClick={()=>this.props.history.push('/userInfo')} className="avatar" src={userObj!=null && userObj.avatar || 'https://jd-media-resource.oss-cn-hangzhou.aliyuncs.com/background/jiaodian-logo.jpg'} alt="avatar"></img>
                    </Tooltip>
                </div>
            </div>
        )
    }
}
export default  withRouter(Navbar)