import apiRequest from '../../common/request';
import {message} from 'antd';
const PREFIX = "CORRECTION_INDEX_";


export const CHANGE_ACTIVE_PAGE = PREFIX + "CHANGE_ACTIVE_PAGE";
export const CHANGE_PAGE_SIZE = PREFIX + "CHANGE_PAGE_SIZE";

export const CHANGE_STATUS = PREFIX + "CHANGE_STATUS";

export const FETCH_QUES_CORRECTION_SUCCED = PREFIX + "FETCH_QUES_CORRECTION_SUCCED";

export const FETCH_QUES_CORRECTION_START = PREFIX + "FETCH_QUES_CORRECTION_START";

export const FETCH_QUES_CORRECTION_END = PREFIX + "FETCH_QUES_CORRECTION_END";

export const UPDATE_STATUS_SUCCED = PREFIX + "UPDATE_STATUS_SUCCED";

export const UPDATE_FEEDBACK_SUCCED = PREFIX + "UPDATE_FEEDBACK_SUCCED";

const fetch_ques_correction_succed = data => ({ type: FETCH_QUES_CORRECTION_SUCCED, data });

const fetch_ques_correction_start = data => ({ type: FETCH_QUES_CORRECTION_START, data });

const fetch_ques_correction_end = data => ({ type: FETCH_QUES_CORRECTION_END, data });

const update_feedBack_succed = (data, idx) => ({type: UPDATE_FEEDBACK_SUCCED, data, idx})

const update_status_succed = (data, idx) => ({type: UPDATE_STATUS_SUCCED, data, idx})

export const changeStatus = (key, val) => ({
    type: CHANGE_STATUS,
    key, val
});

/** 改变第几页 */
export const changeActivePage = data => ({
    type: CHANGE_ACTIVE_PAGE,
    data
});

/** 改变每页分页数目 */
export const changePageSize = data => ({
    type: CHANGE_PAGE_SIZE,
    data
});

export const fetchQuestionCorrection = data => {
  let url = '/gk/ops/questionCorrection/page';
  let config = {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
  };
  return async dispatch => {
      dispatch(fetch_ques_correction_start());
      apiRequest(url, config).then(json => {
        dispatch(fetch_ques_correction_succed(json.obj));
      }).catch(e=>{
        console.log(e);
        dispatch(fetch_ques_correction_end());
        // message.error(e.errorMes || '获取纠错列表数据出错');
      });
  }
}

export const updateFeedBack = (data, idx, feedBack) => {
  let url = '/gk/ops/questionCorrection/correctionMethod';
  let config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({id: data.id, correctionMethod:feedBack})
  };

  return dispatch => {
      return new Promise((resolve, reject) => {
        apiRequest(url, config).then(json => {
          data.correctionMethod = feedBack
          dispatch(update_feedBack_succed(data, idx));
          resolve();
        }).catch(e=>{
          // message.error(e.errorMes || '添加反馈出错');
          reject()
        });
      })
    }

}

export const updateStatus = (data, idx) => {
  let url = '/gk/ops/questionCorrection/updateStatus/'+data.id;

  return async dispatch => {
      apiRequest(url).then(json => {
        let arr = [1,0];
        data.status = arr[data.status];
        dispatch(update_status_succed(data, idx));
      }).catch(e=>{
        message.error(e.errorMes || '操作出错');
      });
  }
}
