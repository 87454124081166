import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import { Provider } from 'react-redux';
import store from './store/store';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN} componentSize="middle" dropdownMatchSelectWidth={false}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
// <React.StrictMode>
// </React.StrictMode>,
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
