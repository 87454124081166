import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "PREFIX";

export const REQUEST_BANNERS = "REQUEST_BANNERS";
export const RECEIVE_BANNERS = "RECEIVE_BANNERS";
export const RECEIVE_PUBLISH = "RECEIVE_PUBLISH";
export const SELECT_BANNER = "SELECT_BANNER";
export const ORDER_BANNER = "ORDER_BANNER";
export const REQUEST_UPDATE_BANNER = "REQUEST_UPDATE_BANNER";
export const RECEIVE_UPDATE_BANNER_RESULT_SUC = "RECEIVE_UPDATE_BANNER_RESULT_SUC";
export const COMMON_SET_STATUS = PREFIX + "COMMON_SET_STATUS";
export const RESET_STATE = PREFIX + "RESET_STATE";

/** 公用修改数据方法 */
export function commonSetStatus(key, value) {
    return {
        type: COMMON_SET_STATUS,
        key, value
    }
}

/** 重置方法 */
export function resetState() {
    return {
        type: RESET_STATE,
    }
}

function request_update_banner() {
    return {
        type: REQUEST_UPDATE_BANNER
    }
}

function receive_update_banner_result_suc() {
    return {
        type: RECEIVE_UPDATE_BANNER_RESULT_SUC
    }
}

function request_banners() {
    return {
        type: REQUEST_BANNERS
    }
}

function receive_banners(data) {
    return {
        type: RECEIVE_BANNERS,
        bannerList: data.content,
        totalElements: data.totalElements
    }
}

function receive_publish(data) {
    return {
        type: RECEIVE_PUBLISH,
        bannerList: data.content,
        totalElements: data.totalElements
    }
}

export function select_banner(id, banner) {
    return {
        type: SELECT_BANNER,
        id,
        banner
    }
}

export function order_banner(id) {
    return {
        type: ORDER_BANNER,
        id
    }
}

export function fetchPublish(breed) {
    let data = {
        publish: 1,
        breed
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_banners());
        apiRequest('/gk/ops/banner/page', config)
            .then(json => {
                dispatch(receive_publish(json.obj));
            });
    }
}

export function fetchBanners(params) {
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10
    }
    params.breed!=='' ? data.breed = params.breed : '';
    params.addressType!=='' ? data.addressType = params.addressType : '';
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_banners());
        apiRequest('/gk/ops/banner/page', config)
            .then(json => {
                dispatch(receive_banners(json.obj));
            });
    }
}

export function publishBanners(breed, info) {
    let config = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_banner());
        apiRequest(`/gk/ops/banner/publish?breed=${breed}&ids=${info.bannersArr.join(',')}`, config)
            .then(() => {
                dispatch(receive_update_banner_result_suc());
                message.success('操作成功');
            });
    }
}

export function deleteBanner(id, cb) {
    let url = `/gk/ops/banner/delete/${id}`;
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    apiRequest(url, config)
        .then(() => {
            message.success('操作成功');
            cb && cb();
        })
}