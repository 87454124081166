import React from "react";
import { connect } from "react-redux";
import { fetchUserCourses, addCourse, deleteCourse, fetchUserInfo, setActivePage, setPageSize } from "./actions";
import {
  fetchCourses,
  fetchCourseLabels,
  fetchCourseTeachers,
} from "../CourseList/action";
import { checkPermission } from '../../utils/utils';
import { Breadcrumb, Button, Select, InputNumber, Table, Input, message } from 'antd';
import { fetchCourseCategories } from "../ModuleSetting/actions";
import './index.scss';

const Option = Select.Option;

const subjectType = [
  { label: '全部', value: null },
  { label: '行测', value: 0 },
  { label: '申论', value: 1 },
  { label: '公基', value: 3 },
  { label: '职测', value: 4 },
  { label: '综应', value: 5 },
];

/**
 * this.props.selCourseList代表的意思是指已购课程的详细数据
 * this.props.courseArr代表的意思是指已购课程的ID集合
 */
class UserConfigPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchName: "",                     // 这是要进行搜索的学员手机号
      permission: 0,                      // 权限
      /** 下面的是课程搜索条件 */
      categoryId: '0',                    // 这是课程分类
      label: null,                          // 这是课程标签
      maxPrice: null,                       // 这是课程的最高价格
      minPrice: null,                       // 这是课程的最低价格
      publish: null,                        // 这是课程的上架状态
      recommend: null,                      // 这是课程的推荐状态
      teacher: null,                        // 这是课程的任课教师
      title: null,                          // 这是课程名字关键字
      type: null,                           // 课程类型
    };
  }

  componentWillMount() {
    this.getCoursesByCondition();
    this.props.dispatch(fetchCourseCategories());
    this.props.dispatch(fetchCourseLabels());
    this.props.dispatch(fetchCourseTeachers());
  }

  handleInput = event => {
    this.setState({ searchName: event.target.value });
  };

  handleSearch = () => {
    if (!this.state.searchName) {
      message.warning('请先输入用户手机号在点击搜索');
      return;
    }
    this.props.dispatch(setActivePage(1));
    this.props.dispatch(fetchUserInfo(this.state.searchName));
    this.props.dispatch(fetchUserCourses(this.state.searchName));
  };

  selectCourse = (course) => {
    let userId = this.props.userInfo.id;
    if (!userId) {
      message.warning('请先输入用户手机号在点击搜索');
      return;
    }
    this.props.dispatch(addCourse(userId, course.id));
  };

  removeCourse = (course) => {
    this.props.dispatch(deleteCourse(this.props.userInfo.id, course.id));
  }

  onShowSizeChange = async (current, pageSize) => {
    await this.props.dispatch(setActivePage(1));
    await this.props.dispatch(setPageSize(pageSize));
    this.getCoursesByCondition();
  }

  paginationChange = async page => {
    await this.props.dispatch(setActivePage(page));
    this.getCoursesByCondition();
  }

  /** 筛选条件的值输入监听函数 */
  valueChangeHandler = (key, value, realTime = true) => {
    this.setState({ [key]: value }, async () => {
      await this.props.dispatch(setActivePage(1));
      realTime && this.getCoursesByCondition();
    });
  }

  /** 根据搜索条件获取课程列表数据 */
  getCoursesByCondition = () => {
    let params = {};
    params.categoryId = this.state.categoryId;
    let checkKeys = ['type', 'label', 'publish', 'recommend', 'teacher', 'title', 'minPrice', 'maxPrice'];
    checkKeys.map(key => (this.state[key] != null) && (params[key] = this.state[key]));
    params.page = this.props.activePage;
    params.pageSize = this.props.pageSize;
    this.props.dispatch(fetchCourses(params));
  }

  /** 清空搜索条件 */
  resetSearch = () => {
    this.setState({
      categoryId: '0', label: null, maxPrice: null, minPrice: null,
      publish: null, recommend: null, teacher: null, title: null,
    }, () => { this.getCoursesByCondition(); });
  }

  /** 渲染antd table的表头 */
  renderHeader = isAll => (
    <div>{isAll ? '在线课程列表' : '用户已购课程'}</div>
  );

  /** 生成Antd Table的配置数据 */
  generateTableConfig = isAll => {
    let columns = [
      {
        title: '课程标题',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '教师',
        dataIndex: 'text',
        key: 'text',
      },
      {
        title: '价格(分)',
        dataIndex: 'price',
        key: 'price'
      },
      {
        title: '地区',
        dataIndex: 'area',
        key: 'area'
      }
    ];
    let removeBtn = record => checkPermission('user:course:del') && <Button size="small" type="danger" onClick={() => this.removeCourse(record)}>移除</Button>;
    let addBtn = record => checkPermission('user:course:add') && <Button size="small" type="primary" onClick={() => this.selectCourse(record)}>增加</Button>;
    let render = !isAll ?
      (text, record) => removeBtn(record)
      :
      (text, record) => this.props.courseArr && this.props.courseArr.includes(record.id) ? removeBtn(record) : addBtn(record)
    let uni = { title: '操作', render };
    columns.push(uni);

    return columns;
  }

  render() {
    let courseHaveColumns = this.generateTableConfig(false);
    let courseAllColumns = this.generateTableConfig(true);
    return (
      <div className="userConfigPage">
        <Breadcrumb>
          <Breadcrumb.Item>用户课程配置</Breadcrumb.Item>
          <Breadcrumb.Item>手动配置</Breadcrumb.Item>
        </Breadcrumb>
        {checkPermission('user:course:query') && <div className="mb10">
          <Input onPressEnter={() => this.handleSearch()} className="w500" type="number" value={this.state.searchName} onChange={this.handleInput} placeholder="请输入用户手机号" />
          <Button disabled={this.props.loading} type="primary" onClick={this.handleSearch}>搜索</Button>
        </div>}

        <Table
          columns={courseHaveColumns}
          dataSource={this.props.selCourseList}
          rowKey={record => record.id}
          title={() => this.renderHeader(false)}
          pagination={{
            pageSize: 1000
          }}
        />

        {/** 接下来是筛选条件输入区域 */}
        {checkPermission('user:course:gkss') && <div className="commonSearchBox">
          <div>
            <label>课程类型</label>
            <Select 
              value={this.state.type} 
              className="commonW150" 
              onChange={value => this.valueChangeHandler('type', value)}
            >
              {subjectType.map((obj, idx) => (
                  <Option key={idx} value={obj.value}>{obj.label}</Option>
              ))}
            </Select>
            </div>
            <div>
              <label>分类</label>
              <Select
                value={this.state.categoryId}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={value => this.valueChangeHandler('categoryId', value)}
              >
                {this.props.categories && this.props.categories.map((cat, idx) => {
                  return <Option key={idx} value={cat.id.toString()}>{cat.name}</Option>
                })}
              </Select>
            </div>
            <div>
              <label>标签</label>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={this.state.label}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={value => this.valueChangeHandler('label', value)}
              >
                <Option value={null}>全部</Option>
                {this.props.labels && this.props.labels.map((label, idx) => {
                  return <Option key={idx} value={label}>{label}</Option>
                })}
              </Select>
            </div>
            <div>
              <label>教师</label>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={this.state.teacher}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={value => this.valueChangeHandler('teacher', value)}
              >
                <Option value={null}>全部</Option>
                {this.props.teachers && this.props.teachers.map((teacher, idx) => {
                  return <Option key={idx} value={teacher}>{teacher}</Option>
                })}
              </Select>
            </div>
            <div>
              <label>上架状态</label>
              <Select
                value={this.state.publish}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={value => this.valueChangeHandler('publish', value)}
              >
                <Option value={null}>全部</Option>
                <Option value={0}>已下架</Option>
                <Option value={1}>已上架</Option>
              </Select>
            </div>
            <div>
              <label>推荐状态</label>
              <Select
                value={this.state.recommend}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={value => this.valueChangeHandler('recommend', value)}
              >
                <Option value={null}>全部</Option>
                <Option value={0}>未推荐</Option>
                <Option value={1}>已推荐</Option>
              </Select>
            </div>

          <div>
            <label>最低价</label>
            <InputNumber
              className="commonW150"
              value={this.state.minPrice}
              onChange={value => this.valueChangeHandler('minPrice', value)}
            />
          </div>
          <div>
            <label>最高价</label>
            <InputNumber
              className="commonW150"
              value={this.state.maxPrice}
              onChange={value => this.valueChangeHandler('maxPrice', value)}
            />
          </div>
          <div>
            <label>标题搜索</label>
            <Input
              className="commonW400"
              placeholder="输入标题的关键词查询"
              value={this.state.title}
              onChange={event => this.valueChangeHandler('title', event.target.value, false)}
              onPressEnter={() => this.getCoursesByCondition()}
            />
            </div>
          <div>
            <Button disabled={this.props.isFetching} onClick={this.getCoursesByCondition} type="primary">搜索</Button>
          </div>
          <div>
            <Button disabled={this.props.isFetching} onClick={this.resetSearch} type="danger">重置</Button>
          </div>
        </div>}

        {checkPermission('user:course:gkss') && <Table
          columns={courseAllColumns}
          dataSource={this.props.courseList}
          rowKey={record => record.id}
          title={this.renderHeader}
          loading={this.props.isFetching}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            onChange: this.paginationChange,
            total: this.props.totalElements,
            showTotal: total => `共有${total}条数据`,
            pageSize: this.props.pageSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            current: this.props.activePage
          }}
        />}
      </div>
    )
  }

}

function mapStatetoProps(state) {
  const {
    items: courseList,
    totalElements,
    isFetching,
    labels,
    teachers
  } = state.courseList;

  const {
    loading,
    items: selCourseList,
    userInfo,
    courseArr,
    activePage,
    pageSize
  } = state.userConfigPage;

  const {
    items: categories
  } = state.courseCategories;


  return {
    loading,
    courseList,
    selCourseList,
    courseArr,
    userInfo,
    activePage,
    pageSize,
    totalElements,
    categories,
    labels,
    teachers,
    isFetching
  };
}

export default connect(mapStatetoProps)(UserConfigPage);