import React, { Component } from 'react';
import { Input, Popover, Alert, Calendar, DatePicker, Select, Button, Popconfirm, message } from 'antd';
import { fetchDateList, fetchDateEdit, fetchDateDel } from './actions';
import { fetchDepartmentList } from '../ContractOrder/action';
import { connect } from 'react-redux';
import moment from 'moment';
import { checkPermission } from '../../utils/utils';
import './time.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const classTeachers = '全程部,师资堂';

export class Time extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: 0,              // 修改任务的id
               remarks: '',        // 备注
               teacherId: '',      // 当前创建老师id
               dateArr: [],        // 获取的关怀列表
               selDateArr: [],     // 需要设置的时间范围
               firstLastDate: [],  // 日历首尾日期
          };
     }

     componentDidMount() {
          // 定位到今天是哪个月
          this.onDateChange(new Date().getMonth(), new Date().getFullYear());
     }

     /** 调接口渲染日历信息 */
     fetchListInfo = async (firstLastDate) => {
          let res = await fetchDateList({
               "classId": this.props.classId,
               "endDate": firstLastDate[1],
               "startDate": firstLastDate[0],
          });
          this.setState({ dateArr: res });
     }

     /** 选择老师 */
     onSelectChange = (value) => {          
          this.setState({ teacherId: value });
     }

     /** 查看某个老师关怀详情 */
     search = async (arr) => {
          let { firstLastDate, teacherId } = { ...this.state };
          if(teacherId=='') return message.warn('请先选择要查看的老师');
          let res = await fetchDateList({
               "classId": this.props.classId,
               "endDate": arr && arr[1] || firstLastDate[1],
               "startDate": arr && arr[0] || firstLastDate[0],
               "teacherId": teacherId
          });
          this.setState({ dateArr: res });
     }

     /** 新增或修改关怀 */
     setFetch = async () => {
          let { firstLastDate, teacherId, selDateArr, id, remarks } = { ...this.state };
          if(teacherId=='') return message.warn('请先选择老师');
          if(selDateArr.length!==2) return message.warn('请在日历中选择完开始/结束日期');
          let obj = {
               "classId": this.props.classId,
               "endDate": selDateArr[1],
               "startDate": selDateArr[0],
               "teacherId": teacherId
          }
          if (remarks) obj.remarks = remarks;
          if (id) obj.id = id;     // 有id说明是修改
          let res = await fetchDateEdit([obj]);
          if (res.status == 1) {
               this.fetchListInfo(firstLastDate);
               this.setState({selDateArr: [], id: 0, remarks: ''});
               message.success('操作成功');
          }
     }

     /** 删除关怀 */
     confirm = async (id) => {
          let { dateArr } = { ...this.state };
          let res = await fetchDateDel(id);
          if (res.status == 1) {
               let idx = dateArr.findIndex((item) => item.id == id);
               dateArr.splice(idx, 1);
               this.setState({ dateArr });
               message.success('删除成功');
          }
     }

     /** 创建日期 */
     setDateCb = (date) => {
          let { selDateArr } = { ...this.state };
          (selDateArr.length==2) && (selDateArr[1]= date) || selDateArr.push(date);
          this.setState({selDateArr});
     }

     /** 修改日期 */
     updateCb = async (obj) => {
          await this.props.dispatch(fetchDepartmentList(classTeachers, obj.teacherName));
          this.setState({
               remarks: obj.remarks,
               selDateArr: [obj.startDate, obj.endDate],
               teacherId: obj.teacherId,
               id: obj.id,
          });
     }

     /** 日历初始化渲染任务 */
     dateFullCellRender = (value) => {
          let { dateArr, selDateArr, id, remarks } = { ...this.state };
          let d = value.date();
          let f = value.format('YYYY-MM-DD');
          // 新增关怀模板
          const content = () => <div>
               <Button type="link" size='small' disabled={selDateArr.length && f<selDateArr[0]} onClick={()=>this.setDateCb(f)}>设为关怀{selDateArr.length && '结束' || '开始'}日期</Button>
               <Input.TextArea placeholder='备注(可选)' value={remarks} style={{margin: '10px 0'}} onChange={(e)=>this.setState({remarks: e.target.value})} />
          </div>;
          // 修改关怀模板
          const content2 = (item) => (
               <div>
                    <Button size='small' type="link" onClick={()=>this.updateCb(item)}>修改</Button>
                    <Popconfirm
                         title="确定删除该关怀记录吗?"
                         disabled={!checkPermission('care:date:del')}
                         onConfirm={() => this.confirm(item.id)}
                         okText="确定"
                         cancelText="取消"
                    >
                         <Button size='small' type="link" danger>删除</Button>
                    </Popconfirm>
                    <div style={{display: 'flex', marginTop: '10px'}}>
                         <p style={{verticalAlign: 'top'}}>备注：</p>
                         <div style={{width: '10em'}}>{item.remarks || '无'}</div>
                    </div>
               </div>
          );
          let render1 = <div>
               <Popover content={content} title={id && '修改' || '新增'}>
                    <div className={`cell2 ${selDateArr[0] <= f && (selDateArr[1] || selDateArr[0]) >= f && 'sel' || ''}`}>
                         {d}
                    </div>                    
               </Popover>
          </div>;
          let render2 = dateArr.map((item, idx) => {
               if (item.startDate <= f && item.endDate >= f) {
                    return (
                         <div key={idx} className={`cell  ${idx % 2 && 'bg2' || 'bg1'} ${f==item.startDate && 'start' || ''} ${f==item.endDate && 'end' || ''} ${item.startDate==item.endDate && 'one'}`}>
                              <Popover content={content2(item)} title={item.teacherName || ''}>
                                   <p>{d}</p>
                                   <span style={{fontSize: '12px'}}>{f == item.startDate && item.teacherName || ''}</span>                  
                              </Popover>
                         </div>
                    )
               }
          });
          return (dateArr.find(i=>{ return i.startDate <= f && i.endDate >= f })) && render2 || render1;
     }

     /** 日历日期改变回调 */
     onCaChange = (value) => {
          let m = value.month();
          let y = value.year();
          this.onDateChange(m, y);
     }

     /** 日期改变回调更新日期范围 */
     onDateChange = (m, y) => {
          let {tab, teacherId} = this.state;
          let firstLastDate = [];
          if(tab==2 && teacherId=='') return message.warn('请先选择要查看的老师');
          if (m == 0) {               // 1月，往前推上一年的12月，往后推一个月
               firstLastDate = [`${y - 1}-12-01`, `${y}-${m + 2}-28`];
          } else if (m == 11) {       // 12月，往前推一个月，往后推下一年的1月
               firstLastDate = [`${y}-11-01`, `${y + 1}-01-28`];
          } else {                 //其他正常月份
               firstLastDate = [`${y}-${m}-01`, `${y}-${m + 2}-28`];
          }
          this.fetchListInfo(firstLastDate);
          this.setState({ firstLastDate });
     }

     /** 选择时间期间 */
     onRangePickerChange = (dates, dateStrings) => {
          this.setState({ selDateArr: dateStrings });
     }

     /** 老师实时查询 */
     employeeSearch = (value) => {
          if (!value) return;
          this.props.dispatch(fetchDepartmentList(classTeachers, value));
     };

     render() {
          let { teacherList, className } = this.props;
          let {selDateArr, teacherId, id, remarks} = this.state;
          return (
               <div className='calendarBox'>
                    <Calendar
                         onChange={this.onCaChange}
                         style={{ width: '50%', marginRight: '20px' }}
                         fullscreen={true}
                         dateFullCellRender={this.dateFullCellRender}
                    />
                    <div style={{ width: '50%' }}>
                         <h3>班级: {className}</h3>
                         <Alert
                              message={<div>
                                   <p>使用说明:</p>
                                   <p>1.日历中选择无任务的日期可创建关怀任务</p>
                                   <p>2.日历中选择有任务日期可修改/删除关怀任务</p>
                                   <p>3.新增/修改选择的日期不能与已有任务的日期重叠</p>
                              </div>}
                              type="warning"
                         /><br />
                         <div>
                              <label>选择老师/班主任</label>
                              <Select
                                   style={{ width: 150 }}
                                   showSearch
                                   filterOption={false}
                                   onSearch={value => { this.employeeSearch(value) }}
                                   value={teacherId+''}
                                   onChange={(value, option) => this.onSelectChange(value, option)}
                              >
                                   {(teacherList || []).map((item, idx) => (
                                        <Option key={idx} value={item.id + ''}>{item.name}</Option>
                                   ))}
                              </Select>
                         </div><br />
                         {id && <div className="m20">
                              <label>选择日期</label>
                              <RangePicker
                                   value={[moment(selDateArr[0], 'YYYY-MM-DD'), moment(selDateArr[1], 'YYYY-MM-DD')]}
                                   allowClear={false}                                  
                                   onChange={(dates, dateStrings) => this.onRangePickerChange(dates, dateStrings)}
                              />
                              <div style={{height: '20px'}}></div>
                              <label style={{verticalAlign: 'top'}}>备注</label>
                              <Input.TextArea
                                   value={remarks}
                                   style={{width: '80%'}}
                                   onChange={(e) => this.setState({remarks: e.target.value})}
                              />
                         </div> || ''}
                         <Button type='primary' disabled={!checkPermission('care:date:edit') && selDateArr.length!=2} onClick={() => this.setFetch()} className="m20">{id&&'修改'||'创建'}关怀</Button>
                         <Button disabled={id} type='primary' onClick={() => this.setState({selDateArr: []})} className="m20">清空选择</Button>
                         <Button disabled={!id} type='danger' onClick={() => this.setState({id: 0, selDateArr: [], remarks: ''})}>退出修改</Button>
                         <p>开始时间:<span className='bg3'>{selDateArr[0] || '暂未选择'}</span>/结束时间:<span className='bg3'>{selDateArr[1] || '暂未选择'}</span></p>
                    </div>
               </div>
          )
     }
}

function mapStatetoProps(state) {
     const {
          teacherList
     } = state.inquiryReducer;

     return {
          teacherList
     };
}

export default connect(mapStatetoProps)(Time);