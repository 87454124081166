import apiRequest from '../../common/request';
import { message } from "antd";

export const REQUEST_ARTICLE_CATEGORIES = "REQUEST_ARTICLE_CATEGORIES";
export const RECEIVE_ARTICLE_CATEGORIES = "RECEIVE_ARTICLE_CATEGORIES";

export const REQUEST_ADD_ARTICLE_CATEGORY = "REQUEST_ADD_ARTICLE_CATEGORY";
export const RECEIVE_ADD_ARTICLE_CATEGORY_RESULT = "RECEIVE_ADD_ARTICLE_CATEGORY_RESULT";

export const REQUEST_DELETE_ARTICLE_CATEGORY = "REQUEST_DELETE_ARTICLE_CATEGORY";
export const RECEIVE_DELETE_ARTICLE_CATEGORY_RESULT = "RECEIVE_DELETE_ARTICLE_CATEGORY_RESULT";

export const REQUEST_UPDATE_ARTICLE_CATEGORY = "REQUEST_UPDATE_ARTICLE_CATEGORY";
export const RECEIVE_UPDATE_ARTICLE_CATEGORY_RESULT = "RECEIVE_UPDATE_ARTICLE_CATEGORY_RESULT";

export const REQUEST_VIDEO_CATEGORIES = "REQUEST_VIDEO_CATEGORIES";
export const RECEIVE_VIDEO_CATEGORIES = "RECEIVE_VIDEO_CATEGORIES";

export const REQUEST_ADD_VIDEO_CATEGORY = "REQUEST_ADD_VIDEO_CATEGORY";
export const RECEIVE_ADD_VIDEO_CATEGORY_RESULT = "RECEIVE_ADD_VIDEO_CATEGORY_RESULT";

export const REQUEST_DELETE_VIDEO_CATEGORY = "REQUEST_DELETE_VIDEO_CATEGORY";
export const RECEIVE_DELETE_VIDEO_CATEGORY_RESULT = "RECEIVE_DELETE_VIDEO_CATEGORY_RESULT";

export const REQUEST_UPDATE_VIDEO_CATEGORY = "REQUEST_UPDATE_VIDEO_CATEGORY";
export const RECEIVE_UPDATE_VIDEO_CATEGORY_RESULT = "RECEIVE_UPDATE_VIDEO_CATEGORY_RESULT";

export const REQUEST_DEPTH_CATEGORIES = "REQUEST_DEPTH_CATEGORIES";
export const RECEIVE_DEPTH_CATEGORIES = "RECEIVE_DEPTH_CATEGORIES";

export const REQUEST_ADD_DEPTH_CATEGORY = "REQUEST_ADD_DEPTH_CATEGORY";
export const RECEIVE_ADD_DEPTH_CATEGORY_RESULT = "RECEIVE_ADD_DEPTH_CATEGORY_RESULT";

export const REQUEST_DELETE_DEPTH_CATEGORY = "REQUEST_DELETE_DEPTH_CATEGORY";
export const RECEIVE_DELETE_DEPTH_CATEGORY_RESULT = "RECEIVE_DELETE_DEPTH_CATEGORY_RESULT";

export const REQUEST_UPDATE_DEPTH_CATEGORY = "REQUEST_UPDATE_DEPTH_CATEGORY";
export const RECEIVE_UPDATE_DEPTH_CATEGORY_RESULT = "RECEIVE_UPDATE_DEPTH_CATEGORY_RESULT";


export const REQUEST_PDF_CATEGORIES = "REQUEST_PDF_CATEGORIES";
export const RECEIVE_PDF_CATEGORIES = "RECEIVE_PDF_CATEGORIES";

export const REQUEST_ADD_PDF_CATEGORY = "REQUEST_ADD_PDF_CATEGORY";
export const RECEIVE_ADD_PDF_CATEGORY_RESULT = "RECEIVE_ADD_PDF_CATEGORY_RESULT";

export const REQUEST_DELETE_PDF_CATEGORY = "REQUEST_DELETE_PDF_CATEGORY";
export const RECEIVE_DELETE_PDF_CATEGORY_RESULT = "RECEIVE_DELETE_PDF_CATEGORY_RESULT";

export const REQUEST_UPDATE_PDF_CATEGORY = "REQUEST_UPDATE_PDF_CATEGORY";
export const RECEIVE_UPDATE_PDF_CATEGORY_RESULT = "RECEIVE_UPDATE_PDF_CATEGORY_RESULT";


export const REQUEST_LIVE_CATEGORIES = "REQUEST_LIVE_CATEGORIES";
export const RECEIVE_LIVE_CATEGORIES = "RECEIVE_LIVE_CATEGORIES";

export const REQUEST_ADD_LIVE_CATEGORY = "REQUEST_ADD_LIVE_CATEGORY";
export const RECEIVE_ADD_LIVE_CATEGORY_RESULT = "RECEIVE_ADD_LIVE_CATEGORY_RESULT";

export const REQUEST_DELETE_LIVE_CATEGORY = "REQUEST_DELETE_LIVE_CATEGORY";
export const RECEIVE_DELETE_LIVE_CATEGORY_RESULT = "RECEIVE_DELETE_LIVE_CATEGORY_RESULT";

export const REQUEST_UPDATE_LIVE_CATEGORY = "REQUEST_UPDATE_LIVE_CATEGORY";
export const RECEIVE_UPDATE_LIVE_CATEGORY_RESULT = "RECEIVE_UPDATE_LIVE_CATEGORY_RESULT";

export const REQUEST_EXAMINE_CATEGORIES = "REQUEST_EXAMINE_CATEGORIES";
export const RECEIVE_EXAMINE_CATEGORIES = "RECEIVE_EXAMINE_CATEGORIES";

export const REQUEST_ADD_EXAMINE_CATEGORY = "REQUEST_ADD_EXAMINE_CATEGORY";
export const RECEIVE_ADD_EXAMINE_CATEGORY_RESULT = "RECEIVE_ADD_EXAMINE_CATEGORY_RESULT";

export const REQUEST_DELETE_EXAMINE_CATEGORY = "REQUEST_DELETE_EXAMINE_CATEGORY";
export const RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT = "RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT";

export const REQUEST_UPDATE_EXAMINE_CATEGORY = "REQUEST_UPDATE_EXAMINE_CATEGORY";
export const RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT = "RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT";

export const REQUEST_QUESTION_CATEGORIES = "REQUEST_QUESTION_CATEGORIES";
export const RECEIVE_QUESTION_CATEGORIES = "RECEIVE_QUESTION_CATEGORIES";

export const REQUEST_ADD_QUESTION_CATEGORY = "REQUEST_ADD_QUESTION_CATEGORY";

export const REQUEST_DELETE_QUESTION_CATEGORY = "REQUEST_DELETE_QUESTION_CATEGORY";

export const REQUEST_UPDATE_QUESTION_CATEGORY = "REQUEST_UPDATE_QUESTION_CATEGORY";

export const REQUEST_AUDIO_CATEGORIES = "REQUEST_AUDIO_CATEGORIES";
export const RECEIVE_AUDIO_CATEGORIES = "RECEIVE_AUDIO_CATEGORIES";

export const REQUEST_ADD_AUDIO_CATEGORY = "REQUEST_ADD_AUDIO_CATEGORY";
export const RECEIVE_ADD_AUDIO_CATEGORY_RESULT = "RECEIVE_ADD_AUDIO_CATEGORY_RESULT";

export const REQUEST_DELETE_AUDIO_CATEGORY = "REQUEST_DELETE_AUDIO_CATEGORY";
export const RECEIVE_DELETE_AUDIO_CATEGORY_RESULT = "RECEIVE_DELETE_AUDIO_CATEGORY_RESULT";

export const REQUEST_UPDATE_AUDIO_CATEGORY = "REQUEST_UPDATE_AUDIO_CATEGORY";
export const RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT = "RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT";

export const REQUEST_COURSE_CATEGORIES = "REQUEST_COURSE_CATEGORIES";
export const RECEIVE_COURSE_CATEGORIES = "RECEIVE_COURSE_CATEGORIES";
export const REQUEST_ADD_COURSE_CATEGORY = "REQUEST_ADD_COURSE_CATEGORY";
export const REQUEST_DELETE_COURSE_CATEGORY = "REQUEST_DELETE_COURSE_CATEGORY";
export const REQUEST_UPDATE_COURSE_CATEGORY = "REQUEST_UPDATE_COURSE_CATEGORY";
export const COMMON_SET_STATUS = "COMMON_SET_STATUS";

/** 公用修改数据方法 */
function commonSetStatus(key, value) {
    return {
        type: COMMON_SET_STATUS,
        key, value
    }
}

function request_question_categories() {
    return {
        type: REQUEST_QUESTION_CATEGORIES
    }
}

function receive_question_categories(categories) {
    return {
        type: RECEIVE_QUESTION_CATEGORIES,
        categories: categories,
        receivedAt: Date.now()
    }
}

function request_add_question_category() {
    return {
        type: REQUEST_ADD_QUESTION_CATEGORY
    }
}

function request_delete_question_category() {
    return {
        type: REQUEST_DELETE_QUESTION_CATEGORY
    }
}

function request_update_question_category() {
    return {
        type: REQUEST_UPDATE_QUESTION_CATEGORY
    }
}

function request_course_categories() {
    return {
        type: REQUEST_COURSE_CATEGORIES
    }
}

function receive_course_categories(categories) {
    return {
        type: RECEIVE_COURSE_CATEGORIES,
        categories: categories,
        receivedAt: Date.now()
    }
}

function request_add_course_category() {
    return {
        type: REQUEST_ADD_COURSE_CATEGORY
    }
}

function request_delete_course_category() {
    return {
        type: REQUEST_DELETE_COURSE_CATEGORY
    }
}

function request_update_course_category() {
    return {
        type: REQUEST_UPDATE_COURSE_CATEGORY
    }
}

/** 公基分类列表 */
export function fetchGJCategories() {
    return dispatch => {
        return apiRequest(`/gk/ops/resource/gj/findByType/gj_question_type`)
          .then(json => {              
            dispatch(commonSetStatus('gjCategory', json.obj));   //获取数据
            dispatch(commonSetStatus('gjLoading', false));          //关闭loading
          })
          .catch(error => console.log(error));
    }
}

/** 职测分类列表 isChange是否需要改变结构*/
export function fetchZCCategories(isChange) {
    return dispatch => {
        return apiRequest(`/gk/ops/resource/zc/findByType`)
          .then(json => {              
            dispatch(commonSetStatus('zcCategory', isChange && json.obj || getZCCategories(json.obj)));   //获取数据
            dispatch(commonSetStatus('zcLoading', false));          //关闭loading
          })
          .catch(error => console.log(error));
    }
}

/** 综应分类列表 */
export function fetchZYCategories() {
    return dispatch => {
        return apiRequest(`/gk/ops/resource/zy/findByType/zy_question_type`)
          .then(json => {              
            dispatch(commonSetStatus('zyCategory', json.obj));   //获取数据
            dispatch(commonSetStatus('zyLoading', false));       //关闭loading
          })
          .catch(error => console.log(error));
    }
}

/** 将职测数据类型进行分类 */
function getZCCategories (arr) {
    let newArr = [
        {
            name: '非联考',
            subset: [],
            type: 'zc_question_type',
            id: 'zc',
            doShow: false,
            parentId: 0
        },{
            name: '联考A类',
            subset: [],
            type: 'lkA_question_type',
            id: 'A',
            doShow: false,
            parentId: 0
        },{
            name: '联考B类',
            subset: [],
            type: 'lkB_question_type',
            id: 'B',
            doShow: false,
            parentId: 0
        },{
            name: '联考C类',
            subset: [],
            type: 'lkC_question_type',
            id: 'C',
            doShow: false,
            parentId: 0
        },{
            name: '联考D类',
            subset: [],
            type: 'lkD_question_type',
            id: 'D',
            doShow: false,
            parentId: 0
        }
    ];
    arr.length && arr.map(item=>{
        if(item.type=='zc_question_type'){
            newArr[0].subset.push(item);
        }
        if(item.type=='lkA_question_type'){
            newArr[1].subset.push(item);
        }
        if(item.type=='lkB_question_type'){
            newArr[2].subset.push(item);
        }
        if(item.type=='lkC_question_type'){
            newArr[3].subset.push(item);
        }
        if(item.type=='lkD_question_type'){
            newArr[4].subset.push(item);
        }
    });
    return newArr;
}

export function fetchQuestionCategories() {
    return dispatch => {
        dispatch(request_question_categories());
        return apiRequest(`/gk/ops/resource/findByType/group/gk_question_types`, {credentials: 'include'})
          .then(json => {
            dispatch(receive_question_categories(json.obj));
          })
          .catch(error => console.log(error));
    }
}

/** 添加分类类型 */
export function addQuestionCategory(type, name, pid) {
    let data = {
        name: name,
        type: type,
        parentId: pid || 0
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_add_question_category());
        return apiRequest('/gk/ops/resource/saveOrUpdate', config)
          .then(json => {
              if(json.status==1){
                message.success('添加成功');
                if(type=='gj_question_types') {         // 行测更新分类数据
                    dispatch(fetchGJCategories());
                } else if(type=='gk_question_type') {   // 公基更新分类数据
                    dispatch(fetchQuestionCategories());
                } else if(type=='zongying') {           // 综应更新分类数据
                    dispatch(fetchZYCategories());
                } else {                                // 职测更新分类数据
                    dispatch(fetchZCCategories());
                }
              }
          })
          .catch(error => console.log(error));
    }
}

/** 修改分类类型 */
export function updateQuestionCategory(id, name, pid, type) {
    let data = {
        id: id,
        name: name,
        type: type,
        parentId: pid || 0
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_update_question_category());
        return apiRequest('/gk/ops/resource/saveOrUpdate', config)
          .then(json => {
            message.success('修改成功');
            if(type=='gj_question_type') {          // 行测更新分类数据
                dispatch(fetchGJCategories());
            } else if(type=='gk_question_types') {  // 共基更新分类数据
                dispatch(fetchQuestionCategories());
            } else if(type=='zongying') {           // 综应更新分类数据
                dispatch(fetchZYCategories());
            } else {                                // 职测更新分类数据
                dispatch(fetchZCCategories());
            }
          })
          .catch(error => console.log(error));
    }
}

export function deleteQuestionCategory(id) {
    return dispatch => {
        dispatch(request_delete_question_category());
        return apiRequest(`/gk/ops/resource/delete/${id}?recursive=true`, {credentials: 'include'})
          .then(json => {
            dispatch(fetchQuestionCategories());
          })
          .catch(error => console.log(error));
    }
}

export function fetchCourseCategories() {
    return dispatch => {
        dispatch(request_course_categories());
        return apiRequest(`/gk/ops/course/category/list`, {credentials: 'include'})
          .then(json => {
            dispatch(receive_course_categories(json.obj));
          })
          .catch(error => console.log(error));
    }
}

export function addCourseCategory(name) {
    let data = {
        name
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_add_course_category());
        return apiRequest('/gk/ops/course/category', config)
          .then(json => {
            message.success('添加成功');
            dispatch(fetchCourseCategories());
          })
          .catch(error => console.log(error));
    }
}

export function updateCourseCategory(id, name, sort) {
    let data = {
        id: id,
        name: name,
        sort: (sort-1)
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_update_course_category());
        return apiRequest('/gk/ops/course/category', config)
          .then(json => {
            message.success('修改成功');
            dispatch(fetchCourseCategories());
          })
          .catch(error => console.log(error));
    }
}

export function deleteCourseCategory(id) {
    return dispatch => {
        dispatch(request_delete_course_category());
        return apiRequest(`/gk/ops/course/category/delete/${id}`, {credentials: 'include',method:"POST"})
          .then(json => {
            dispatch(fetchCourseCategories());
          })
          .catch(error => console.log(error));
    }
}
