import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "DO_LIST";

export const GET_RECORD = PREFIX + "GET_RECORD";
export const COMMON_CHANGE = PREFIX + "COMMON_CHANGE";
export const RESET = PREFIX + "RESET";

export const reset = () => {
    return { type: RESET }
}

export const commonChange = (key, val) => {
    return { type: COMMON_CHANGE, key, val }
}

/** 实时搜索条件动态请求 */
export function getCondition(key, value, examineType, exerciseType) {
    let url = '';
    let data = {};
    if (key == 'exerciseList') {
        url = `/gk/ops/shenlun/exercise/condition`;
        data = { title: value, exerciseType, type: examineType || 5 }
    }
    if (key == 'studentList') {
        url = `/gk/ops/student/getStudent`;
        data = { key: value }
    }
    if (key == 'employeeList') {
        url = `/gk/ops/employee/selectEmployee`;
        data = { name: value }
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return dispatch => {
        apiRequest(url, config)
            .then(json => {
                if (json.status == 1) {
                    key == 'employeeList' && dispatch(commonChange('employeeList', json.obj || []));
                    key == 'exerciseList' && dispatch(commonChange('exerciseList', json.obj.content || []));
                    key == 'studentList' && dispatch(commonChange('studentList', json.obj.content || []));
                }
            })
            .catch(error => console.log(error));
    }
}

/** 分配学员给老师 */
export function update(examType, teacherId, ids) {
    let url = '';
    if(examType==1) url = `/gk/ops/shenlun/update/student/exercise/${teacherId}?ids=${ids}`;
    if(examType==5) url = `/gk/ops/zongying/update/student/exercise/${teacherId}?ids=${ids}`;
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return async dispatch => {
        dispatch(commonChange('loading', true));
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            message.success('分配成功');
        }
        dispatch(commonChange('loading', false));
    }
}

/** 获取记录列表 */
export function getRecord(examType, data) {
    let url = '';
    if(examType==1) url = `/gk/ops/shenlun/exercise/record`;
    if(examType==5) url = `/gk/ops/zongying/exercise/record`;

    let config = {
        method: 'POST',
        headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        dispatch(commonChange('loading', true));
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            dispatch(commonChange('items', json.obj));
        }
        dispatch(commonChange('loading', false));
    }
}

/** 获取班级列表数据 */
export const fetchClassList = (title, examType) => {
    const url = '/gk/ops/student/class/page';
    let data = { page: 1, pageSize: 20, title, type: examType };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'},
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            let content = json.obj.content || [];
            dispatch(commonChange('classList', content));
        }
    }
}

/** 获取班级所有学员 */
export const getClassStudent = (params={}) => {
    let data = { courseNum: 2 };
    params.classNo && (data.classNo = params.classNo);
    params.courseId && (data.courseId = params.courseId);
    let url = `/gk/ops/student/getClassLearnInfo`;
    let config = {
        method: 'POST',
        headers: { 'content-type': 'application/json'},
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            let content = json.obj.content || [];
            dispatch(commonChange('studentList', content));
            if(content.length) {
                message.success(`该班级搜索到${content.length}位学员`);
                let userIds = []
                content.map(item=>{
                    userIds.push(item.userId);
                });
                dispatch(commonChange('userIds', userIds));// 直接回显学员信息，并请求页面数据
            } else {
                message.warn('该班级搜索没有学员');
            }
        }
    }
}
