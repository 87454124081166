import React from 'react';
import { Tooltip } from 'antd';
import './index.scss';


const Answer = ({ answerObj, questionIndex }) => {

    const generateClassName = () => {
        let correct = answerObj.correct;
        return {
            userAnswer: true,
            correct,
        };
    }


    return (
        <Tooltip title={answerObj.userAnswer && `用户第${questionIndex+1}题的答案为${answerObj.userAnswer}` || `用户未做答`}>
            <div className="answerItemComponent">
                <span className="questionIndex">{questionIndex+1}</span>
                <span className={answerObj.correct ? 'userAnswer correct' : 'userAnswer'}>{answerObj.userAnswer}</span>
            </div>
        </Tooltip>
    );
}

// Answer.propTypes = {
//     answerObj: PropTypes.object,        // 对象里面一定是有userAnswer字段以及correct字段的
//     questionIndex: PropTypes.number,    // 题号
// };

// Answer.defaultProps = {
//     answerObj: {},
// };

export default Answer;