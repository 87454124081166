import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    RECEIVE_DELETE_QUESTIONS_RESULT,
    REQUEST_DELETE_QUESTIONS,
    RECEIVE_QUESTIONS_YEARS,
    RECEIVE_QUESTIONS_AREAS,
    RECEIVE_SHENLUN_AREAS,
    SET_PAGE_SIZE,
    RESET_FILTER_STATUS,
    CHANGE_COMMON
} from './actions';

const initState = {
    page: 1,
    pageSize: 10,
    source: '',             //试题来源
    year: [],               //已选年份
    province: [],           //省份
    searchName: '',         //试卷关键词
    options: '',            //选项关键词
    questionTag: '',        //标签
    categoryId: '-1',       //已选最终分类id
    fstCategoryId: "-1",    //已选一级分类id
    secCategoryId: "-1",    //已选二级分类id
    trdCategoryId: "-1",    //已选三级分类id
    secondCategories: [],   //已选二级分类
    thirdCategories: [],    //已选三级分类
}

export function questions(state = {
    ...initState,
    isFetching: true,
    isDeleting: true,
    items: [],
    areas: [],
    years: [],
    quesType: '-1',
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
}, action) {
    switch (action.type) {
        case CHANGE_COMMON:
            return Object.assign({}, state, { [action.key]: action.val });
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, { isFetching: true });
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.questionList,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
                numberOfElements: action.numberOfElements,
                lastUpdated: action.receivedAt
            });
        case REQUEST_DELETE_QUESTIONS:
            return Object.assign({}, state, { isDeleting: true });
        case RECEIVE_DELETE_QUESTIONS_RESULT:
            return Object.assign({}, state, {
                isDeleting: false,
                items: [...state.items.filter(item => item.id != action.id)]
            });
        case RECEIVE_QUESTIONS_AREAS:
            return Object.assign({}, state, {
                areas: action.areas
            });
        case RECEIVE_SHENLUN_AREAS:
            return Object.assign({}, state, {
                areas: action.areas
            });
        case RECEIVE_QUESTIONS_YEARS:
            return Object.assign({}, state, {
                years: action.years.reverse()
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case RESET_FILTER_STATUS:
            return { ...state, ...initState };
        default:
            return state;
    }
}
