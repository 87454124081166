import React from "react";
import { connect } from "react-redux";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import {
    setValue,
    reset_all_data,
    fill_examine_title,
    select_question,
    update_sel_question,
    fetchExamine,
    updateExamine,
    fill_examine_limit_time,
    fill_examine_class_id,
} from "./actions";
import { fetchSearchClass } from '../Inquiry/action';
import { checkPermission } from '../../utils/utils';
import { Breadcrumb, Select, Input, Button, message, DatePicker, Card } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import './index.scss';
import moment from 'moment';
import { checkExercise } from '../ClassExercise/actions';
const Option = Select.Option;

class ClassTestInfo extends React.Component {

    componentDidMount() {
        let classType = this.props.match.params.classType;
        let from = null;
        if (this.props.location.state) {
            from = this.props.location.state.type;
        }
        this.props.match.params.id && this.props.dispatch(fetchExamine(this.props.match.params.id, from, classType));
        // 页面渲染需要时间，加一个定时器执行准确一点
        setTimeout(()=>this.setScrollTop(), 1000);
    }

    /** 实时监听页面滚动条位置 */
    setScrollTop = () => {
        this.lastClassId = this.props.match.params.id;
        // 判断是否是上次页面，用于回到滚动条位置
        let isCurrent = ( this.lastClassId == this.props.lastClassId);
        let top = document.querySelector('.site-layout-background');        
        top && (top.scrollTop = isCurrent && this.props.lastScrollTop || 0);
        top && top.addEventListener('scroll',() => {                        
            this.lastScrollTop = top.scrollTop;
        });
    }   

    /** 清楚当前页面班级下拉数据缓存, 页面注销需要记录滚动条位置，用于返回页面回到上次滚动条位置 */
    componentWillUnmount() {
        this.props.dispatch(reset_all_data());
        this.props.dispatch(setValue('lastClassId', this.lastClassId));
        this.props.dispatch(setValue('lastScrollTop', this.lastScrollTop));
    }

    modifyExamine = async () => {
        if (!this.dataVerify()) {
            return;
        }
        await this.props.dispatch(updateExamine(this.props.info, this.props.match.params.classType));
    };

    dataVerify = () => {
        if (!this.props.info.selQuesList || this.props.info.selQuesList.length <= 0) {
            message.warn('请选择题目');
            return false;
        }
        if (!this.props.info.title) {
            message.warn('请输入测试标题');
            return false;
        }
        if (this.props.match.params.classType == 0) {
            if (this.props.info.limitTime === ''
                || Object.prototype.toString.call(this.props.info.limitTime) == '[object Null]'
                || Object.prototype.toString.call(this.props.info.limitTime) == '[object Undefined]'
            ) {
                message.warn('请输入限制时间');
                return false;
            }
        }
        return true;
    }

    updateQuesArr = (arr) => {
        this.props.dispatch(select_question(arr));
    }

    updateSelQues = (arr) => {
        this.props.dispatch(update_sel_question(arr));
    }

    updatePoint = (order, point) => {
        // this.props.dispatch(updatePoint(order, point));
    }

    selectClassHandler = (idx, value) => {
        const { classes } = this.props;
        let { classIdDate } = this.props.info;
        if (classIdDate && classIdDate.length) {
            for (let item of classIdDate) {
                if (item.classId == classes[value].id) {
                    message.warn('班级已添加');
                    return;
                }
            }
        };
        classIdDate[idx]['classId'] = classes[value].id;
        classIdDate[idx]['className'] = classes[value].className;
        this.props.dispatch(fill_examine_class_id(classIdDate));
        this.props.match.params.id && this.props.dispatch(checkExercise(this.props.match.params.id, classes[value].id));//检验该班级测试在该班级是否有人做过
    }

    selectTime = (idx, value) => {
        let { classIdDate } = this.props.info;
        classIdDate[idx]['publishDate'] = value == "" ? undefined : value
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        if (!value) return;
        const { classType } = this.props.match.params;
        let data = { title: value, type: classType };
        this.props.dispatch(fetchSearchClass(data));
    };

    addClass = () => {
        let { classIdDate } = this.props.info;
        classIdDate.push({})
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    removeClass = idx => {
        let { classIdDate } = this.props.info;
        classIdDate.splice(idx, 1)
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    /** 一键删除所有班级 */
    removeAll = () => {
        let { classIdDate } = this.props.info;
        classIdDate = [];
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    /** QuestionSelectCard组件部分接收字母 */
    getNumberType = (classType) => {
        if (classType == 0) return 0;
        if (classType == 1) return 1;
        if (classType == 3) return 'gj';
        if (classType == 4) return 'zc';
        if (classType == 5) return 'zy';
    }

    render() {
        let exerciseType = this.props.match.params.classType;
        const { info, classes, isFetching } = { ...this.props };
        return (
            <div className="classTestInfoContainer">
                <Breadcrumb>
                    <Breadcrumb.Item>班级管理</Breadcrumb.Item>
                    <Breadcrumb.Item>班级列表</Breadcrumb.Item>
                    <Breadcrumb.Item>班级练习</Breadcrumb.Item>
                </Breadcrumb>
                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>班级测试标题</label>
                        <Input
                            style={{ width: 300 }}
                            value={info.title}
                            onChange={e => this.props.dispatch(fill_examine_title(e.target.value))}
                        />
                    </div>

                    {
                        exerciseType != 1 && exerciseType != '5' &&
                        <div className="itemSearchBox">
                            <label>限制时间(秒)</label>
                            <Input
                                style={{ width: 200 }}
                                type='number'
                                value={info.limitTime}
                                onChange={e => this.props.dispatch(fill_examine_limit_time(e.target.value))}
                            />
                        </div>
                    }
                </div>

                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>班级</label>
                        <Button size="small" disabled={info.classIdDate.length == 0} className="classInfoBtn" onClick={this.removeAll} danger>
                            一键删除
                        </Button>
                        {
                            info.classIdDate && info.classIdDate.map((cls, idx) => {
                                return <div key={idx}>
                                    <Select
                                        showSearch
                                        placeholder="请选择班级"
                                        style={{ width: 300, margin: '5px 10px 5px 0' }}
                                        onChange={value => this.selectClassHandler(idx, value)}
                                        value={cls.className}
                                        onSearch={value => { this.searchClass(value) }}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {classes.map((item, index) => {
                                            return <Option value={index}>{item.className}</Option>
                                        })}
                                    </Select>
                                    <DatePicker value={cls.publishDate ? moment(cls.publishDate, 'YYYY-MM-DD') : ''} onChange={(mmt, value) => this.selectTime(idx, value)} />
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => this.removeClass(idx)}
                                        icon={<DeleteOutlined />}
                                        shape="circle"
                                        size="small" >
                                    </Button>
                                </div>
                            })
                        }
                        <Button size="small" disabled={isFetching} className="classInfoBtn" onClick={this.addClass} type="primary">
                            添加班级
                        </Button>
                    </div>
                </div>

                {(exerciseType!=1 && exerciseType!=5) && <div className="infoBox">
                    <h3>排序规则</h3>
                    <span>1、按照试题一级类目分别聚合，不同类目试题不能穿插</span><br/>
                    <span>2、同一一级类目之内，同一材料题下面的试题分别聚合，不同材料题试题不能穿插</span><br/>
                    <span>3、调整两个一级类目或者两个材料题顺序时，只需将彼此排头第一题互换顺序即可，不需要把所有题都互换</span>
                </div>}

                <QuestionSelectCard
                    quesArr={info.quesArr}
                    selQuesList={info.selQuesList || []}
                    updateQuesArr={this.updateQuesArr}
                    updateSelQues={this.updateSelQues}
                    updatePoint={true}
                    exerciseType={this.getNumberType(exerciseType)}
                />

                {
                    checkPermission('gkclass:exercise:edit') &&
                    <Card title="更新" className="cardR" size="small">
                        <Button
                            onClick={this.modifyExamine}
                            type="primary"
                            disabled={this.props.isSend}>
                           更新
                        </Button>
                    </Card>
                }
            </div>
        )
    }

}


function mapStatetoProps(state) {
    const {
        lastClassId,             // 上次班级id
        lastScrollTop,           // 上次滚动条位置
        isFetchingExamine,
        isUpdating,
        isUpdated,
        categoryId,
        info,
        classes
    } = state.classTestInfo;

    const {
        isFetching
    } = state.classExercise;

    return {
        isFetching,
        isFetchingExamine,
        isUpdating,
        isUpdated,
        categoryId,
        info,
        classes,
        lastClassId,             // 上次班级id
        lastScrollTop,           // 上次滚动条位置
    };
}

export default connect(mapStatetoProps)(ClassTestInfo);
