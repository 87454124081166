export const PAY_STATUS_LIST = [
  {value: 1, name: '未缴费'},
  {value: 2, name: '部分缴费'},
  {value: 3, name: '全部缴费'},
  {value: 4, name: '已退费'}
]

export const EDUCATION_LIST = [
  {value: 4, name: '不限'},
  {value: 0, name: '专科'},
  {value: 1, name: '本科'},
  {value: 2, name: '硕士'},
  {value: 3, name: '博士'},
]

export const POLITICAL_STATUS_LIST = [
  {value: 3, name: '不限'},
  {value: 0, name: '群众'},
  {value: 1, name: '团员'},
  {value: 2, name: '党员'},
]

export const WORK_YEAR_LIST = [
  {value: 0, name: '无要求'},
  {value: 1, name: '一年'},
  {value: 2, name: '两年'},
  {value: 3, name: '三年'},
  {value: 4, name: '四年'},
  {value: 5, name: '五年及以上'},
  {value: 6, name: '不限'}
]

export const selectStyle = {
    margin: 5,
    width: 120,
}

export const EMPTY_INFO = {
  years: [],
  provinceList: [],
  positionTypeList: [],
  officeList: [],
  officeListSize: 0,  // officeList总条目数（分页用）
  positionList: [],
  cityList: [],
  positionDetail: null, // 选择的职位
  showForm: false,  //用来控制状态，areas拿到数据了，再开始显示
  showSearchResult: false,
  info: null,  //学员信息
  job: null,  //学员职位
  country: {
    year: '',
    type: '',
    province: '',
    office: '',
    position: '',
    //新增查询条件字段
    bureau: '',
    page: 1,
    city: '',
    deptCode: '',
    education: '',
    major: '',
    politicalStatus: '',
    postCode: '',
    workYear: '',
  },
  districtList: [],         // 事业单位实时区县数组
  institutionList: [],      // 事业单位实时省份数组
  institution: {            // 事业单位
    year: '',               // 年份
    province: '',           // 省份
    city: '',               // 市
    district: '',           // 区县
    page: 1,                // 当前页
    positionName: '',       // 职位名称
    deptCode: '',           // 单位代码
    positionCode: '',       // 职位代码
    deptName: '',           // 单位名称
    education: '',          // 学历
    major: '',              // 专业
    politicalStatus: '',    // 政治面貌
    workYear: '',           // 工作经验
  },
  province: {
    page: 1,
    "city": "",
    "deptName": "",
    "education": '',
    "major": '',
    "politicalStatus": '',
    "positionName": '',
    "province": '',
    "type": '',
    "workedYear": '',
    "year": '',
    "deptCode": '',
    "positionCode": '',
    "district": ''
  }
}
