import {
    ASK_NUM,
    GET_INQUIRY_DETAILS,
    DEAL_CURRENT_SEARCH,
    EMPTY_CURRENT_STATUS,
    CHANGE_DATA_STATUS,
    SET_INQUIRY_LOADING,
    SET_INQUIRY_DETAIL_LOADING
} from './action';
import {
    GET_OPERATION_INFORM,
    GET_TEACHER_INFORM,
    SEARCH_USER_INFO
} from '../ContractOrder/action';
import { SET_STUDENT_CLASS } from '../Inquiry/action';

const initSearch = {
    askNum: '',                                 // 用户的答疑次数汇总
    classId: '全部',                                // 班级标题
    headTeacherId: '全部',                       // 默认班主任选择框
    name: '',                                   // 学员姓名
    mobile: '',                                 // 学员手机号
    userInfoIds: [],                            // 实时查询学员集合
    startDate: '',                              // 查询起始时间
    endDate: '',                                // 查询结束时间
    page: 1,                                    // 默认为第一页
    pageSize: 10,                               // 默认一页为10条
    teacherId: '全部',                           // 老师id
    currentTeacherName: '全部',                  // 当前选中老师名字
    classes: [],                                // 搜索班级集合
    currentClassName: '全部',                       // 当前班级名字
    classIdx: null,                             // 班级索引
    classDetail: null,                          // 单个班级详情
    custormers: [],                             // 客户集合
    userNames: [],
    userIndex: [],                             // 当前选中用户索引
};

const initialState = {
    loading: false,                              // 是否处于加载状态中
    queryInfo: {},                              // 接口返回的列表数据
    operational: [],                            // 运营部
    teacherDepartment: [],                      // 师资堂
    ...initSearch
};

export const inquiryDetail = (previouState=initialState, action) => {
    switch(action.type) {
        case ASK_NUM:
            return { ...previouState, askNum: action.value };
        case SET_INQUIRY_LOADING:
            return { ...previouState, loading: true };
        case SET_INQUIRY_DETAIL_LOADING: 
        return { ...previouState, loading: false };
        case GET_INQUIRY_DETAILS:
            return { ...previouState, queryInfo: action.data, loading: false };
        case DEAL_CURRENT_SEARCH:
            return { ...previouState, [ action.key ]: action.value };
        case EMPTY_CURRENT_STATUS:
            return { ...previouState, ...initSearch };
        case GET_OPERATION_INFORM:
            return { ...previouState, operational: [ ...action.data ] };
        case GET_TEACHER_INFORM:
            return { ...previouState, teacherDepartment: [ ...action.data ] };
        case SET_STUDENT_CLASS:
            return { ...previouState, classes: action.data };
        case SEARCH_USER_INFO: 
            return { ...previouState, custormers: action.data };
        case CHANGE_DATA_STATUS:
            return { ...previouState, custormers: action.data };
        default:
            return previouState;
    }
}