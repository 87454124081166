import {
  SHENLUN_STUDENT_DETAIL,
  SET_FILTER_STATUS
} from './action';

const initialState = {
  classObj: {},
  filterStatus: 'ALL',                // 筛选所有，三个可选值，分别是DONE, ALL, NOT
};

export const shenlunDetail = (state = initialState, action) => {
  switch(action.type) {
    case SHENLUN_STUDENT_DETAIL:
      return Object.assign( {}, state, {classObj: action.data} );
    case SET_FILTER_STATUS:
      return Object.assign( {}, state, {filterStatus: action.data} );
    default:
      return state;
  }
}
