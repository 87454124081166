import React from "react";
import { connect } from "react-redux";
import { checkPermission } from '../../utils/utils';
import { Radio, Input, DatePicker, Button, message, Card } from 'antd';
import Editor from "../../components/Editor/index";

import './style.scss';
import apiRequest from '../../common/request'
const { TextArea } = Input;

class InfoSystemPush extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textValue: '',
      titleValue: '',
      contentValue: '',
      dataValue: '',
      isPopUpsValue: 0,
      typeValue: 9,
      deviceType: '1,2'
    };
  }

  /**输入公告标题 */
  titleHandler = event => {
    this.setState({ titleValue: event.target.value });
  }
  contentHandler = event => {
    this.setState({ contentValue: event.target.value });
  }
  dataHandler = (data, value) => {
    this.setState({ dataValue: value });
  }
  isPopUpsChange = e => {
    this.setState({ isPopUpsValue: e.target.value });
  }
  deviceTypeChange = e => {
    this.setState({ deviceType: e.target.value });
  }
  typeChange = e => {
    this.setState({ typeValue: e.target.value });
  }
  textChange = value => {
    this.setState({ textValue: value });
  }
  //字符串转int数组
  toArray = str => {
    return str.split(',').map(item => {
      return parseInt(item)
    })
  }
  submit = async () => {
    if (this.state.titleValue == '') {
      message.warning('请输入公告标题');
      return;
    }
    if (this.state.dataValue == '') {
      message.warning('请选择截止时间');
      return;
    }
    if (this.state.contentValue == '') {
      message.warning('请输入公告内容');
      return;
    }

    let data = {
      content: this.state.contentValue,
      deadline: this.state.dataValue,
      isPopUps: this.state.isPopUpsValue,
      title: this.state.titleValue,
      type: this.state.typeValue,
      deviceType: this.toArray(this.state.deviceType)
    }

    this.state.textValue && (data.text = this.state.textValue)
    await apiRequest('/gk/ops/notification/save',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include'
      })
      .then(() => {
        message.success('操作成功');
        this.props.history.push(`/infoSystemPush`);
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <div className="box">
        <div className="context">
          <label>公告标题</label>
          <Input placeholder="请输入公告标题" onChange={e => this.titleHandler(e)} />
          <br />
          <label>截止时间</label>
          <DatePicker placeholder="请选择截止时间" onChange={(data, value) => { this.dataHandler(data, value) }} />
          <br />
          <label>公告机型</label>
          <Radio.Group value={this.state.deviceType.toString()} onChange={this.deviceTypeChange}>
            <Radio value='1,2'>安卓+IOS</Radio>
            <Radio value='1'>安卓</Radio>
            <Radio value='2'>IOS</Radio>
          </Radio.Group>
          <br />
          <label>是否弹窗</label>
          <Radio.Group value={this.state.isPopUpsValue} onChange={this.isPopUpsChange}>
            <Radio value={0}>否</Radio>
            <Radio value={1}>是</Radio>
          </Radio.Group>
          <br />
          <label>公告类型</label>
          <Radio.Group value={this.state.typeValue} onChange={this.typeChange}>
            <Radio value={9}>系统升级</Radio>
            <Radio value={10}>直播公告</Radio>
            <Radio value={11}>开课公告</Radio>
          </Radio.Group>
          <br />
          <label>公告内容</label>
          <TextArea rows={4} placeholder="请输入公告内容" onChange={e => this.contentHandler(e)} />
          <br />
          <label>公告描述富文本(非必填项)</label>
            <Editor
              content='' 
              onChange={value=>{this.textChange(value)}}
              imgPath='background/gonggao'
            />
          </div>
          <div className="submit">
            {checkPermission('sys:notification:save') && <Card size="small" title="发布">
              <Button type="primary" onClick={this.submit}>发布</Button>
            </Card>}
          </div>
        </div>
    )
  }
}

function mapStatetoProps(state) {
  return {}
}

export default connect(mapStatetoProps)(InfoSystemPush);
