import React, { Component } from 'react'
import Recorder from 'js-audio-recorder';
import { Button, Tag, message, Popconfirm } from 'antd';
import { CaretRightFilled, PauseCircleFilled } from '@ant-design/icons';
import './index.scss'

let recorder = new Recorder({
    sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
    sampleRate: 11025,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
    numChannels: 1,                 // 声道，支持 1 或 2， 默认是1  以上参数越大，声音越清晰，生成音频体积越大
});

/**
 * @Description: 这是一个录音音频封装组件
 * @Author: hujianwen
 * @CreateDate: 2021-03-30 15:30:27
 * @Explain: 引用了第三方插件js-audio-recorder
 * @param: 
 * 
*/
export default class RecordComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            running: false,         // 是否是录音状态
            duration: 0,            // 录音生成音频的时长
            fileSize: 0,            // 录音生成音频的大小
            time: '00:00',          // 录音计时状态
            audioUrl: '',           // 录音生成音频的链接
            startAudio: false,      // 是否是开始录音状态
            resumeAudio: false,     // 是否是暂停录音状态
            blob: null,             // 录音生成音频的blob
        }
    };

    /** 时间转换格式 00:00 */
    lunTime = (time) => {
        if (typeof time !== 'number' || time < 0) {
            return time
        }
        //var hour = parseInt(time / 3600);
        time = time % 3600;
        var minute = parseInt(time / 60);
        time = time % 60;
        var second = time;
        return ([minute, second]).map(function (n) {
            n = n.toString();
            return n[1] ? n : '0' + n
        }).join(':')
    }

    /** 开始录音 */
    recorderStart = () => {
        let that = this;
        recorder.start().then(() => {
            // 开始录音
            message.info('录音已开始');
            recorder.onprogress = function (params) {
                that.setState({ time: that.lunTime(parseInt(params.duration)) });
            }
            that.setState({ running: true, startAudio: true });
        }, () => {
            // 出错了
            message.warn('录音未授权, 请尝试刷新页面获取授权');
        });
    }

    /** 暂停录音 */
    recorderPause = () => {
        message.info('录音已暂停');
        recorder.pause();
        this.setState({ resumeAudio: true })
    }

    /** 继续录音 */
    recorderResume = () => {
        message.info('录音已继续');
        recorder.resume();
        this.setState({ resumeAudio: false })
    }

    /** 完成录音 */
    recorderStop = () => {
        message.success('录音已完成');
        recorder.stop();
        let blob = recorder.getWAVBlob();
        let url = window.URL.createObjectURL(blob);
        let duration = parseInt(recorder.duration);
        let fileSize = recorder.fileSize / 1024;
        this.setState({ running: false, audioUrl: url, blob, duration, fileSize });
    }

    /** 销毁录音 */
    recorderDestroy = () => {        
        recorder.stop();
        recorder.destroy();
        this.setState({ resumeAudio: false, running: false, startAudio: false, audioUrl: '', time: '00:00', blob: null });
    }

    /** 销毁录音组件 */
    componentWillUnmount() {
        this.recorderDestroy();
    }

    render() {
        let { startAudio, resumeAudio, audioUrl, time } = this.state;
        return (
            <div>
                <span style={{ color: 'red' }}>注意：录音请不要超出10分钟，时长较大的录音上传可能比较久，请耐心等待!</span>
                <div className="recordBox">
                    {!startAudio && <Button className="reBtn" icon={<CaretRightFilled />} onClick={() => this.recorderStart()}>开始</Button>}
                    {startAudio && !resumeAudio && <Button className="reBtn" icon={<PauseCircleFilled />} disabled={audioUrl} onClick={() => this.recorderPause()}>暂停</Button>}
                    {resumeAudio && <Button className="reBtn" icon={<CaretRightFilled />} disabled={audioUrl} onClick={() => this.recorderResume()}>继续</Button>}
                    {startAudio && <Popconfirm
                        title="确定重录吗?"
                        onConfirm={() => this.recorderDestroy()}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button className="reBtn">重录</Button>
                    </Popconfirm>}
                    {startAudio && <Button disabled={audioUrl} className="reBtn" onClick={() => this.recorderStop()}>完成</Button>}
                    {!audioUrl && <Tag style={{ fontSize: '16px', margin: '0 10px' }} color="blue">{time}</Tag>}
                    {audioUrl && <audio controls="controls" src={audioUrl}></audio>}
                </div>
            </div>
        )
    }
}
