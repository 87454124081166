import apiRequest from '../../common/request';
import Cookies from 'js-cookie';
import {message} from 'antd';

const PREFIX = 'STUDENT_INFO_ACTION';
export const GET_STUDENT_BIRTHPLACE = PREFIX + 'GET_STUDENT_BIRTHPLACE';
export const CHANGE_ORIGIN_PLACE = PREFIX + 'CHANGE_ORIGIN_PLACE';
export const LOADING_CONTRACT_USER = PREFIX + 'LOADING_CONTRACT_USER';
export const SET_CONTRACT_USER = PREFIX + 'SET_CONTRACT_USER';
export const SET_REDUX_KEY_VALUE = PREFIX + 'SET_REDUX_KEY_VALUE';
export const CLEAR_SEARCH = PREFIX + 'CLEAR_SEARCH';
export const CHANGE_CURRENT_PAGE = PREFIX + 'CHANGE_CURRENT_PAGE';
export const RESET_CURRENT_PAGE = PREFIX + 'RESET_CURRENT_PAGE';
export const CHANGE_PAGE_SIZES = PREFIX + 'CHANGE_PAGE_SIZES';
export const SET_STUDENT_LOADING = PREFIX + 'SET_STUDENT_LOADING';

export const changePageSize = value => ({
    type: CHANGE_PAGE_SIZES,
    value
});

export const resetCurrentPage = value => ({
    type: RESET_CURRENT_PAGE,
    value
});

export const changeCurrentPage = value => ({
    type: CHANGE_CURRENT_PAGE,
    value
});

export const changeOriginPlace = value => ({
    type: CHANGE_ORIGIN_PLACE,
    value
});

const get_student_birthPlace = data => ({
    type: GET_STUDENT_BIRTHPLACE,
    data
});

const loading_contract_user = () => ({
    type: LOADING_CONTRACT_USER,
});

const set_contract_user = data => ({
    type: SET_CONTRACT_USER,
    data
});

const set_student_loading = () => ({ type: SET_STUDENT_LOADING });

export const clearSearch = () => ({ type: CLEAR_SEARCH });

export const setReduxKeyValue = data => ({
    type: SET_REDUX_KEY_VALUE,
    key: data.key,
    value: data.value
});

export const fetchBirthPlace = () => {
    let url = `/gk/ops/userInfo/birthPlace/list`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            dispatch(get_student_birthPlace(json.obj))
        })
    }
};

/** 新增或修改学员信息 */
export const addOrChangeUserInfo = (data, successCb, successPull, errCb) => {
    let url = `/gk/ops/userInfo/saveOrUpdate`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            successCb && successCb();
            successPull && successPull();
        })
        .catch(e => {
            errCb && errCb(e.errorMes || '操作失败');
        });
    }
};

/** 获取用户信息 */
export const fetchContractUserInfo = data => {
    let url = `/gk/ops/userInfo/page`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(loading_contract_user());
        return apiRequest(url, config).then(json => {
            dispatch(set_contract_user(json.obj));
        }).catch(err => {
            dispatch(set_student_loading());
        })
    }
};

/** 导出合同用户信息Excel */
export const fetchInformation = data => {
    let url = `/gk/ops/contract/excel/exportUserInfo`;
    let config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Sin-Access-Token': Cookies.get('accessToken'),
        'Sin-UserAgent-Sign': 'SintureGK-WEB',
        'Sin-Client-Type': 3,
        Accept: '*/*',
      },
      body: JSON.stringify(data),
      credentials: 'include'
    };
    return dispatch => {
        fetch(url, config).then(response => {
            response.blob().then(blob => {
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                let fileName = '合同用户信息列表.xls';
                a.download = fileName;
                document.body.appendChild(a).click();
                window.URL.revokeObjectURL(url);
            })
        }).catch(err => {
            message.error('下载出错');
        })
    }
}
  
