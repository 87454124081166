import {
    RECEIVE_RESOURCE,
    REQUEST_NEW_RESOURCE,
    RECEIVE_TAG
} from './actions';

export function essayCategory(state = {
    isFetching: true,
    isSending: false,
    resource: [],
    tags: [],
}, action) {
    switch (action.type) {
        case REQUEST_NEW_RESOURCE:
            return Object.assign({}, state, {
                isSending: true,
            });
        case RECEIVE_RESOURCE:
            return Object.assign({}, state, {
                isSending: false,
                resource: action.resource
            });
        case RECEIVE_TAG:
            return Object.assign({}, state, {
                isSending: false,
                tags: action.tags
            });
        default:
            return state;
    }
}
