import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Popover, Spin, Tooltip, Radio, Modal, Input, Select, Button, message, Table, Cascader } from "antd";
import { Link } from "react-router-dom";
import {
    getCategoryId,
    fetchInstListPage,
    commonSetStatus,
    resetState,
} from './actions';
import { fetchInstitutionYearOrArea } from '../PaperList/actions';
import { fetchGJCategories, fetchZCCategories } from '../ModuleSetting/actions';
import QRCode from 'qrcode';
import Preview from '../../components/Preview';
import { checkPermission, downWord } from '../../utils/utils';
import apiRequest from '../../common/request';

const { Option } = Select;

class InstitutionList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            qrcodeShow: false,
            showModal: false,
            curQuestionIndex: 0,
            previewShow: false,
            showVideoModal: false,
            p: 1,                   // word下载当前页数
            downLoading: false,     // word下载加载状态
            totalPages: 0,          // word下载总页数
        }
    }

    /** 没有公基权限，渲染职测 */
    async componentDidMount () {
        if(!checkPermission('gj:question:page')){
            await this.props.dispatch(commonSetStatus('examType', 'zc'));
        } else if(!checkPermission('zc:question:page')){
            await this.props.dispatch(commonSetStatus('examType', 'gj'));
        }
        this.changeType(false, this.props.examType);
    }

    /** 页面初始化加载数据判断
     * @param boolean: 是否需要重置
     * @param type : gj zc
     * 页面初始化不需要重置状态
     * 点击切换需要重置状态
     */
    changeType = async (boolean, type) => {
        boolean && await this.props.dispatch(resetState());
        type == 'gj' && this.props.dispatch(fetchGJCategories());
        type == 'zc' && this.props.dispatch(fetchZCCategories());
        await this.props.dispatch(commonSetStatus('examType', type));
        await this.props.dispatch(fetchInstitutionYearOrArea(type, 'area', 'jobArea'));
        await this.props.dispatch(fetchInstitutionYearOrArea(type, 'year', 'jobYear'));
        this.fetchPostData();
    }

    /** 获取列表数据 
     * @param examType : gj zc 
    */
    fetchPostData = () => {
        let { source, categoryId, examType, page, pageSize, province, year, questionTag, options, query } = this.props;
        let obj = { source, categoryId, page, pageSize, province, year, questionTag, options, query };
        this.props.dispatch(fetchInstListPage(examType, obj));
    }

    /** 重置搜索 */
    reset = async () => {
        await this.props.dispatch(resetState());
        this.fetchPostData();
    }

    /** 共用修改方法 */
    commChange = async (key, value) => {
        await this.props.dispatch(commonSetStatus(key, value));
        await this.props.dispatch(commonSetStatus('page', 1));
        if(key!='source' && key!='query' && key!='options') this.fetchPostData();
    }

    /** 设置表格头部信息列表 */
    generateColumns = () => (
        [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.content }}></div>}>
                        {checkPermission(this.getType('go')) && 
                        <Link to={`/institutionInfo/${this.props.examType}/${record.id}`} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></Link>
                        || <div className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></div>}
                    </Tooltip>
                ),
            },
            {
                title: '标签',
                width: '10%',
                dataIndex: 'questionTag',
                key: 'questionTag',
                render: (text, record) => (
                    <div className="ellipsis2">{record.questionTag == 1 && '其他' || '原创'}</div>
                ),
            },
            {
                title: '年份',
                width: '10%',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
                render: (text, record) => (
                    <Tooltip className="ellipsis2" title={record.area}>{record.area}</Tooltip>
                ),
            },
            {
                title: '分类',
                width: '10%',
                dataIndex: 'categoryName',
                key: 'categoryName',
                ellipsis: true
            },
            {
                title: '题型',
                width: '10%',
                dataIndex: 'quesTypeName',
                key: 'quesTypeName',
            },
            {
                title: '来源',
                width: '10%',
                dataIndex: 'source',
                key: 'source',
                render: (text, record) => (
                    <Tooltip title={record.source}>
                        <div className="ellipsis2">{record.source}</div>
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                width: '20%',
                render: (text, record, index) => (
                    <div className="commonTableBtn">
                        {checkPermission(this.getType('pre')) && <Button onClick={() => this.currentPreview(index)} size="small" type='primary'>预览</Button>}
                        {checkPermission(this.getType('ewm')) && <Button size="small" type="primary" onClick={() => this.getQRCode(record)}>二维码</Button>}
                        {checkPermission(this.getType('video')) && record.signedVideoUrl && <Button type="primary" size="small" onClick={() => this.playVideo(record)}>视频</Button>}
                    </div>
                ),
            },
        ]
    );

    /** 点击播放视频按钮 */
    playVideo = record => {
        this.setState({ showVideoModal: true, signedVideoUrl: record.signedVideoUrl });
    }

    /** 生成二维码，然后画带logo的二维码后面优化 */
    getQRCode = (record) => {
        let version = 'xz1';
        this.imgId = record.id;
        this.name = record.categoryName;
        this.imgContent = record.content;
        QRCode.toDataURL(`sinture://topic.service/scan?bn=${version}&id=${record.id}&type=16`)
            .then(url => {
                this.ewmUrl = url; // 本来这个二维码已经有用，但是还要加logo
                this.setState({ qrcodeShow: true });
            })
            .catch(err => {
                console.error(err)
            });
    }

    /** 下载生成带logo的二维码 */
    handleOkQrcode = () => {
        let url = this.ewmUrl;                        // 获取图片地址
        let a = document.createElement('a');          // 创建一个a节点插入的document
        let event = new MouseEvent('click');          // 模拟鼠标click点击事件
        a.download = this.name + this.imgId;          // 设置a节点的download属性值
        a.href = url;                                 // 将图片的src赋值给a节点的href
        a.dispatchEvent(event);                       // 触发鼠标点击事件
    }

    /** 关闭二维码模态框 */
    handleCancelQrcode = () => {
        this.setState({ qrcodeShow: false })
    }

    /** 新增试题 */
    goToAdd = () => {
        let { examType } = this.props;
        this.props.history.push(`/institutionAdd/${examType}`);
    }

    /** 动态权限判断方法 */
    getType = (str) => {
        let { examType } = this.props;
        if (examType == 'gj') {
            if (str == 'add') return 'gj:question:edit';
            if (str == 'go') return 'gj:question:info';
            if (str == 'pre') return 'gk:gj:pre';
            if (str == 'ewm') return 'gk:gj:ewm';
            if (str == 'video') return 'gk:gj:video';
        }
        if (examType == 'zc') {
            if (str == 'add') return 'zc:question:edit';
            if (str == 'go') return 'zc:question:info';
            if (str == 'pre') return 'gk:zc:pre';
            if (str == 'ewm') return 'gk:zc:ewm';
            if (str == 'video') return 'gk:zc:video';
        }
        return '';
    }

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>{this.props.examType == 'gj' ? '公基' : '职测'}试题列表</span>
            <div>
                {(checkPermission('gj:question:download') || checkPermission('zc:question:download')) && 
                    <Popover 
                        title="下载说明" 
                        content={<div>
                            <p>1.每套最多生成500题</p>
                            <p>2.上面的筛选条件就是生成word的内容,请至少选择一个分类</p>
                            <p>3.想要默认自动下载,请找到浏览器的下载设置,关闭下载前询问文件保存位置</p>
                            <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(1)} style={{margin: '10px'}}>有解析</Button>
                            <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(2)} style={{margin: '10px'}}>无解析</Button>
                            <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(3)} style={{margin: '10px'}}>纯解析</Button>
                        </div>}
                    >
                        <Button disabled={this.props.categoryId!=[] && !getCategoryId(this.props.categoryId) && getCategoryId(this.props.categoryId) ==''} type="link">下载word-共{Math.ceil(this.props.institutionQuestionObj.totalElements/500 || 0)}份</Button>
                    </Popover>}
                {checkPermission(this.getType('add')) && <Button type="primary" onClick={() => this.goToAdd()}>添加试题</Button>}
            </div>
        </div>
    )

    /** 表格分页方法 */
    onChangePage = async (current, pageSize) => {
        await this.props.dispatch(commonSetStatus('page', current));
        await this.props.dispatch(commonSetStatus('pageSize', pageSize));
        this.fetchPostData();
    }

    /** 处理2级以上分类数据格式 */
    sourceHandler = (arr) => {
        if (!arr.length) return [];
        if (arr[0].name == '全部') return arr;
        let defaultItem = { id: '', name: '全部', subset: [] };
        arr.map((item) => {
            if (item.subset && item.subset.length) {
                this.sourceHandler(item.subset);
            }
        });
        arr.unshift(defaultItem);
        return arr;
    }

    /** 预览 */
    currentPreview = (index) => {
        if (this.props.institutionQuestionObj.content.length == 0) {
            return;
        }
        this.setState({
            previewShow: true,
            curQuestionIndex: index
        })
    }

    /** 上一题 */
    preQuestion = async () => {
        let { page, pageSize } = this.props;
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex === 0) {
            if (page > 1) { // 如果是第一题，并且不是第一页，请求上一页，索引重置为上一页最后一题
                await this.props.dispatch(commonSetStatus('page', page - 1));
                await this.fetchPostData();
                setTimeout(() => {
                    this.setState({
                        curQuestionIndex: pageSize - 1
                    });
                }, 0)
            } else if (page == 1) { // 如果是第一题，并且是第一页
                message.info('前面已经没有题目了');
            }
        } else {
            this.setState({ // 索引递减
                curQuestionIndex: curQuestionIndex - 1
            });
        }
    }

    /** 下一题 */
    nextQuestion = async () => {
        let { page, pageSize, institutionQuestionObj } = this.props;
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex > pageSize - 2) {
            if (page < institutionQuestionObj.totalPages) { // 如果是最后一题，并且不是最后一页，请求下一页，索引重置为下一页第一题
                await this.props.dispatch(commonSetStatus('page', page + 1));
                this.fetchPostData();
                this.setState({
                    curQuestionIndex: 0
                });
            }
        } else {
            if (curQuestionIndex == institutionQuestionObj.content.length - 1) { // 最后一题
                message.info('已经是最后一题了');
                return;
            }
            this.setState({
                curQuestionIndex: curQuestionIndex + 1
            });
        }
    }

    /** 下载试题为word */
    downloadQuestion = (isShow) => {
        let that = this;
        let flag = isShow;
        let { p } = this.state;
        let {examType} = this.props;
        let url = '';
        if(examType=='gj') url = '/gk/ops/gj/question/downloadQuestion';
        if(examType=='zc') url = '/gk/ops/zc/question/downloadQuestion';
        let data = {
            page : p,
            qdesc: 'asc',
            sortField: 'id',
            years: this.props.year.toString(),
            province: this.props.province.toString(),
            categoryId: getCategoryId(this.props.categoryId) ? getCategoryId(this.props.categoryId) : '',
            options: this.props.options,
            query: this.props.query,
            source: this.props.source,
            questionTag: this.props.questionTag,
            pageSize: 500
        };
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
        };
        that.setState({ downLoading: true});
        apiRequest(url, config)
            .then(async json => {
                let totalPages = json.obj.totalPages;
                let res = json.obj.content || [];
                if(json.obj && !json.obj.content.length) return message.success('试题为空');
                let last = json.obj.last;
                let fileName = this.props.year.toString() + res[0].categoryName + p;
                await downWord(that.getContentInfo(res), p, fileName, flag);
                that.setState({ p: ++p, totalPages});
                if(!last) {        // 不是最后一页，继续下载
                    this.downloadQuestion(flag);
                } else {        // 最后一页，停止下载，恢复第一页
                    message.success('下载成功');
                    that.setState({ p: 1, downLoading: false, totalPages: 0});
                }
            })
            .catch(error => {
                console.log(error);
                message.error('下载失败');
                that.setState({ p: 1, downLoading: false, totalPages: 0});
            });
    }

    /** 筛选掉试题缺失的题目 */
    getContentInfo = (res) => {
        return res.filter((item, idx)=>{
            return (!item.content.includes('试题暂缺'));             
        });;
    }

    render() {
        let { source, questionTag, categoryId, gjCategory, zcCategory, examType, query, options, year, province, jobYear, jobArea, institutionQuestionObj, loading } = this.props;
        let categoryData = (examType == 'gj') && gjCategory || zcCategory;
        let curQuestion = institutionQuestionObj.content.length > 0 ? institutionQuestionObj.content[this.state.curQuestionIndex] : {};
        let { p, downLoading, totalPages } = this.state;
        return (
            <Spin tip={totalPages && `已完成${p}套, 共${totalPages}套` || '加载中...'} spinning={downLoading}>
                <Radio.Group value={examType} onChange={(e) => this.changeType(true, e.target.value)} defaultValue="gj" buttonStyle="solid">
                    {checkPermission('gj:question:page') && <Radio.Button value="gj">公基</Radio.Button>}
                    {checkPermission('zc:question:page') && <Radio.Button value="zc">职测</Radio.Button>}
                </Radio.Group>
                <div className="commonSearchBox">
                    <div>
                        <label>年份</label>
                        <Select value={year} mode="multiple" className="commonW400" onChange={value => { this.commChange('year', value) }}>
                            {jobYear && jobYear.map((year, idx) => {
                                return <Option key={idx} value={year + ''}>{year}</Option>
                            })}
                        </Select>
                    </div>
                    <div>
                        <label>地区</label>
                        <Select value={province} mode="multiple" className="commonW400" onChange={value => { this.commChange('province', value) }}>
                            {jobArea && jobArea.map((year, idx) => {
                                return <Option key={idx} value={year + ''}>{year}</Option>
                            })}
                        </Select>
                    </div>
                    <div>
                        <label>搜索标题</label>
                        <Input value={query} onChange={e => this.commChange('query', e.target.value)} onPressEnter={() => this.fetchPostData()} className="commonW400" placeholder="回车键查询" />
                    </div>
                    <div>
                        <label>搜索选项</label>
                        <Input value={options} onChange={e => this.commChange('options', e.target.value)} onPressEnter={() => this.fetchPostData()} className="commonW400" placeholder="回车键查询" />
                    </div>
                    <div>
                        <label>试题来源</label>
                        <Input value={source} onChange={e => this.commChange('source', e.target.value)} onPressEnter={() => this.fetchPostData()} className="commonW400" placeholder="回车键查询" />
                    </div>
                    <div>
                        <label>分类</label>
                        <Cascader value={categoryId} className="commonW400" fieldNames={{ label: 'name', value: 'id', children: 'subset' }} options={this.sourceHandler(categoryData)} onChange={value => { this.commChange('categoryId', value) }} changeOnSelect showSearch />
                    </div>
                    <div>
                        <label>标签</label>
                        <Select value={questionTag} className="commonW150" onChange={value => { this.commChange('questionTag', value) }}>
                            <Option value={''}>全部</Option>
                            <Option value={1}>其他</Option>
                            <Option value={2}>原创</Option>
                        </Select>
                    </div>
                    <div>
                        <Button type="primary" onClick={() => this.fetchPostData()}>搜索</Button>
                    </div>
                    <div>
                        <Button type="danger" onClick={() => this.reset()}>重置</Button>
                    </div>
                </div>
                <Table
                    columns={this.generateColumns()}
                    dataSource={institutionQuestionObj.content}
                    rowKey={record => record.id}
                    title={() => this.renderHeader()}
                    loading={loading}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: institutionQuestionObj.totalElements,
                        onChange: this.onChangePage,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                <Modal title="试题二维码" okText="下载" visible={this.state.qrcodeShow} onOk={this.handleOkQrcode} onCancel={this.handleCancelQrcode}>
                    <div style={{ display: 'flex' }}>
                        <img width="160px" height="160px" src={this.ewmUrl} alt='ewm' />
                        <div>
                            <p style={{ color: 'red' }}>说明：二维码下载名称默认用试题分类+id命名，可自行更改</p>
                            <h6>题目内容:</h6>
                            <p dangerouslySetInnerHTML={{ __html: this.imgContent }}></p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="解析视频"
                    visible={this.state.showVideoModal}
                    footer={null}
                    closable={false}
                    onCancel={()=>this.setState({ showVideoModal: false, signedVideoUrl: '' })}
                >
                    <video width={550} src={this.state.signedVideoUrl} controls="controls" />
                </Modal>

                {this.state.previewShow ?
                    <Preview
                        fromType={examType}
                        previewShow={this.state.previewShow}
                        hidePreviewCb={() => this.setState({ previewShow: false })}
                        question={curQuestion}
                        pre={this.preQuestion}
                        next={this.nextQuestion}
                    /> : null}
            </Spin>
        )
    }
}
function mapStatetoProps(state) {
    let { jobYear, jobArea } = state.paperList;
    let { gjCategory, zcCategory } = state.questionCategories;
    return {
        jobYear, jobArea, gjCategory, zcCategory,
        ...state.institutionList,
    };
}
export default connect(mapStatetoProps)(InstitutionList)