/**
 *  README: 该组件既包括了新建试卷也包括了修改试卷，行测组卷和申论组卷应该是两块功能，这里不放在一块进行处理
 *  对于押题卷模块和估分卷模块来说，后端关于创建它们的VO和详情VO是不同的，但是对于前端来说，创建页面和详情页面是同一个的，这里统一作为详情接口处理。
 *  由于行测组卷和申论组件是两块功能，该组件只做行测组卷功能，那么当组估分卷以及押题卷的时候，传递的参数examInfoType其值写死XINGCE
 */

import React from 'react';
import { connect } from 'react-redux';
import { Card, Input, Switch, Spin, InputNumber, Button, Select, Anchor, message } from 'antd';
import {
  getExamineDetail,
  findQuesId,
  uploadExamineDetail,
  setType,
  setValue,
  resetExamineObj,
  setSelQuesIds
} from './action';
import {
  GUFEN_TEXT,
  YATI_TEXT,
  TRUEEXAM_TEXT,
  fetchExamineTypeList
} from '../ExamineList/action';
import {
  fetchQuestionYears,
  fetchQuestionAreas
} from '../QuestionList/actions';
import QuestionSelectCard from '../../components/QuestionSelectCard';
import { checkPermission, validateUtil, getSearchQuery } from '../../utils/utils';
import './index.scss';

const { Option } = Select;
const XINGCE_TYPE = 0;

class ExamineDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    await this.selfInit();
    if (getSearchQuery('id') && this.props.examineObj.questions && this.props.examineObj.questions.length === 0) {
      this.props.getExamineDetail(getSearchQuery('id'), this.props.type);
    } else {
      this.props.fetch_examineTypeList();
    }
    this.props.fetch_years();
    this.props.fetch_areas();
  }

  /** 初始化操作 */
  selfInit = async () => {
    let type = getSearchQuery('type') || '';        // 这个是表示试卷类型的中文
    type = decodeURIComponent(type);
    await this.props.setType(type);
    this.id = getSearchQuery('id');                 // 这个是修改试卷时候的试卷ID
    let showPointInput = true;                              // 是否显示分数输入
    if (type === TRUEEXAM_TEXT) showPointInput = false;     // 历年真题试卷不存在分数输入
    this.k2v = { title: '试卷标题', publish: '是否上架', limitTime: '限制时间' };
    this.blackList = ['title', 'publish', 'limitTime'];                  // 需要校验的黑名单数据
    this.stringInput = ['title'];
    this.numberInput = ['limitTime'];
    this.switchInput = ['publish', 'share'];
    this.selectOneInput = [];
    this.multiSelect = [];
    this.noDifference();
    this.setState({ showPointInput });     // 以目前结构来说，在这里进行setState是有必要的，如果没有setState的话，那么就会出现当类型改变的时候还会保留上次试卷输入VO
  }

  /** 不同的试卷类型对应不同的VO，这里需要确定一下该类型试卷需要哪些VO input */
  noDifference = () => {
    switch (this.props.type) {
      case GUFEN_TEXT:                                                     // 估分的输入信息
      case YATI_TEXT:                                                      // 押题卷的输入信息
        let gufenUnique = { share: '是否分享', mockExamineQuestionList: '试题', examInfoType: '试卷类型' };
        this.k2v = Object.assign(this.k2v, gufenUnique);
        this.blackList = this.blackList.concat(['examInfoType', 'mockExamineQuestionList', 'share']);
        break;
      case TRUEEXAM_TEXT:                                                  // 历年真题的输入信息
        let myUnique = { year: '年份', questionsIds: '试题', area: '地区' };
        this.k2v = Object.assign(this.k2v, myUnique);
        this.selectOneInput = ['year'];
        this.multiSelect = ['area'];
        this.switchInput = ['publish'];
        this.blackList = this.blackList.concat(['area', 'questionsIds', 'year']);
        break;
      default:
        message.error('未知试卷类型');
    }
  }

  /** 输入监听函数 */
  valueHandler = (key, value) => {
    this.props.setValue(key, value);
  }

  /** 修改试卷类型 */
  changeExamType = async value => {
    await this.props.setType(value);
    await this.props.resetExamineObj();
    // getSearchQuery('type') = value;
    this.selfInit();
  }

  /** 渲染页面头 */
  renderHeader = () => {
    let str = `${this.props.type}试卷`;
    if (!this.id) {               // 假定不存在ID为0的试卷
      str = '添加' + str;
    } else {
      str = '修改' + str;
    }
    return <h4 className="mb30">{str}</h4>
  }

  /** 渲染试卷类型选择 */
  renderTypeChose = () => {
    if (this.id) return <div></div>
    return (
      <div className="inputRow">
        <label>试卷类型</label>
        <Select value={this.props.type} onChange={this.changeExamType} className="commonW150">
          {
            this.props.examTypeList.map((t, idx) => (
              <Option key={idx} value={t}>{t}</Option>
            ))
          }
        </Select>
      </div>
    );
  }

  /** 更新已选题目的ID数组 */
  updateQuesIdArr = idArr => {
    this.props.setSelQuesIds(idArr);
  }

  /** 更新完整题目数组，由于存在多种类型的试卷，不同类型的试卷其VO层字段都是不同的，但是在redux层还是都是一样的，固定为questions */
  updateSelQues = quesArr => {
    this.props.setValue('questions', quesArr);
  }

  /** 数据加工: 1.对于押题卷和估分卷模块来说，写死字段examInfoType；2.对于是否上下架以及是否分享字段来说，传的不是布尔值而是数字 */
  dataHandler = () => {
    let data = this.props.examineObj;               // 这个是详细数据
    switch (this.props.type) {
      case GUFEN_TEXT:
      case YATI_TEXT:
        data.examInfoType = XINGCE_TYPE;
        data.mockExamineQuestionList = findQuesId(data.questions, true);
        break;
      case TRUEEXAM_TEXT:
        // 对于历年真题来说，新建的逻辑是questionIds字段，所以需要从questions里面筛选出questionId来
        data.questionsIds = findQuesId(data.questions);
        break;
      default:
        throw new Error('合成需要提交API的数据发生错误');
    }
    if ('publish' in data) data.publish = (data.publish ? 1 : 0);
    if ('share' in data) data.share = (data.share ? 1 : 0);
    return data;
  }

  /** 对分数进行校验 */
  pointVerify = (submitData = {}) => {
    let mockExamineQuestionList = submitData.mockExamineQuestionList;
    if (Object.prototype.toString.call(mockExamineQuestionList) !== "[object Array]") return false;
    let hasNoPoint = mockExamineQuestionList.some(obj => obj.point == null);         // 对于有分数的题目来说，只要存在有一道题目没有分数的话，那么就需要报错
    return hasNoPoint;
  }

  /** 发布试卷 */
  submitExam = () => {
    let submitData = this.dataHandler();                            // 不同的试卷类型其提交数据不同
    if (validateUtil(submitData, this.blackList, this.k2v)) return;
    if (this.pointVerify(submitData)) {
      message.warning('每一道试题必须要有分数');
      return;
    }
    const cb = () => this.props.history.push('/examineList');
    uploadExamineDetail(submitData, this.props.type, cb);
  }

  getType = () => {
    let { type } = this.props;
    if (type == '历年真题') return 'sys:history:edit';
    if (type == '估分卷') return 'sys:estimate:edit';
    return '';
  }

  render() {
    let exerciseType = 0;
    let { examineObj, loading } = this.props;
    return (
      <div className="examineDetailPageStyle">

        <Anchor className="gotoAdd">
          <Anchor.Link href="#inputQuestionAnchor" title="到达添加试题位置"></Anchor.Link>
          <Anchor.Link href="#bottomAnchor" title="到达页面底部"></Anchor.Link>
        </Anchor>

        {
          checkPermission(this.getType()) &&
          <Card size="small" title="发布" className="submitBtn"><Button onClick={this.submitExam} type="primary">发布</Button></Card>
        }

        {this.renderHeader()}
        <Spin spinning={loading} tip="题目量较多，试卷加载中，请稍等...">
          {this.renderTypeChose()}

          {
            this.stringInput && this.stringInput.map(key => (
              <div key={key} className="inputRow">
                <label>{this.k2v[key]}</label>
                <Input className='commonW400' value={examineObj[key]} onChange={event => this.valueHandler(key, event.target.value)} placeholder={`请输入${this.k2v[key]}`} />
              </div>
            ))
          }

          {
            this.numberInput && this.numberInput.map(key => (
              <div key={key} className="inputRow">
                <label>{this.k2v[key]}</label>
                <InputNumber min={0} value={examineObj[key]} onChange={value => this.valueHandler(key, value)} className="commonW150" />
              </div>
            ))
          }

          {
            this.selectOneInput && this.selectOneInput.map(key => (
              <div key={key} className="inputRow">
                <label>{this.k2v[key]}</label>
                <Select value={examineObj[key]} className="commonW150" onChange={value => this.valueHandler(key, value)}>
                  {
                    this.props[`${key}List`] && this.props[`${key}List`].map((t, idx) => (
                      <Option key={idx} value={t}>{t}</Option>
                    ))
                  }
                </Select>
              </div>
            ))
          }

          {
            this.multiSelect && this.multiSelect.map(key => (
              <div key={key} className="inputRow">
                <label>{this.k2v[key]}</label>
                <Select mode="multiple" value={examineObj[key]} className="commonW150" onChange={value => this.valueHandler(key, value)}>
                  {
                    this.props[`${key}List`] && this.props[`${key}List`].map((t, idx) => (
                      <Option key={idx} value={t}>{t}</Option>
                    ))
                  }
                </Select>
              </div>
            ))
          }

          {
            this.switchInput && this.switchInput.map(key => (
              <div key={key} className="inputRow">
                <label>{this.k2v[key]}</label>
                <Switch checked={examineObj[key] == 1} onChange={value => this.valueHandler(key, value)} />
              </div>
            ))
          }
        </Spin>
        <div className="infoBox">
            <h3>排序规则</h3>
            <span>1、按照试题一级类目分别聚合，不同类目试题不能穿插</span><br/>
            <span>2、同一一级类目之内，同一材料题下面的试题分别聚合，不同材料题试题不能穿插</span><br/>
            <span>3、调整两个一级类目或者两个材料题顺序时，只需将彼此排头第一题互换顺序即可，不需要把所有题都互换</span>
        </div>
        <QuestionSelectCard
          quesArr={this.props.selQuesIds || []}
          updatePoint={this.state.showPointInput}
          selQuesList={this.props.examineObj.questions}
          updateQuesArr={this.updateQuesIdArr}
          updateSelQues={this.updateSelQues}
          exerciseType={exerciseType}
        />
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  examTypeList: storeState.examineList.examTypeList,                    // 试卷类型
  areaList: storeState.questions.areas,                                 // 地区列表
  yearList: storeState.questions.years,                                 // 年份列表
  ...storeState.examineDetail
});

const mapDispatchToProps = dispatch => ({
  fetch_examineTypeList: async () => await dispatch(fetchExamineTypeList()),
  fetch_years: () => dispatch(fetchQuestionYears()),
  fetch_areas: () => dispatch(fetchQuestionAreas()),
  getExamineDetail: (id, type) => dispatch(getExamineDetail(id, type)),
  setType: value => dispatch(setType(value)),
  setValue: (key, value) => dispatch(setValue(key, value)),
  resetExamineObj: () => dispatch(resetExamineObj()),
  setSelQuesIds: value => dispatch(setSelQuesIds(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamineDetail);