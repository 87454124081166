import React from 'react';
import { connect } from 'react-redux';
import { fetchCommonSense, updateCommonSense } from './fetcher';
import { fetchTagList } from "../CommonSenseList/actions";
import { Input, Select, InputNumber, Button, message, Card,DatePicker } from "antd";
import Editor from "../../components/Editor/index";
import { checkPermission, validateUtil } from '../../utils/utils';

import moment from 'moment';
import "./index.scss";

const Option = Select.Option;
class CommonSenseInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                title: '', label: '', stage: 0, publishDate: '', content: null
            }
        };
        this.selfInit();
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            const successCb = jsonObj => { this.setState({ info: jsonObj.obj }); };
            await fetchCommonSense(this.props.match.params.id, successCb);
        } else {
            await this.setState({ info: { ...this.state.info, content: '' } });
        }
        this.props.dispatch(fetchTagList());
    }

    warningCb(mes) { message.warning(mes); }

    errorCb(mes) { message.error(mes); }

    /** 普通状态初始化 */
    selfInit() {
        this.blackList = ['title', 'label', 'stage', 'publishDate', 'content'];
        this.stringInputs = ['title'];
        this.selectInputOne = ['label'];
        this.numberInputs = ['stage'];
        this.tipsObj = {
            content: '内容', label: '标签', publishDate: '发布日期',
            stage: '第几期', title: '标题'
        };
    }

    update = () => {
        let info = this.state.info;
        let cb = mes => message.warning(mes);
        let validateFailed = validateUtil(info, this.blackList, this.tipsObj, cb, true);
        if (validateFailed) return;
        let submitData = this.dataHandler(this.state.info);
        let successCb = () => {
            message.success('操作成功');
            setTimeout(() => {
                this.props.history.push('/commonSenseList' + window.location.search);
            }, 800);
        }
        updateCommonSense(submitData, successCb, this.warningCb, this.errorCb);
    };

    dataHandler = () => {
        let submitData = this.state.info;
        submitData.publishDate += " 00:00:00";
        return submitData;
    }

    update_upTime = val => {
        this.setState({
            info: {
                ...this.state.info,
                publishDate: val
            }
        })
    };

    update_content = content => {
        this.setState({
            info: {
                ...this.state.info,
                content
            }
        });
    };

    eventHandler = (event, key) => {
        this.setState({
            info: {
                ...this.state.info,
                [key]: event.target.value
            }
        });
    }

    valueHandler = (value, key) => {
        this.setState({
            info: {
                ...this.state.info,
                [key]: value
            }
        });
    }

     /** 日历日期值转换函数 */
     calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    render() {
        const { info } = this.state;
        return (
            <div className="commonSenseInfo">
                <h4 className="mb30">常识详情</h4>

                {
                    this.props.isFetching &&
                    <h5>加载中……</h5>
                }

                {
                    !this.props.isFetching &&
                    <div>

                        {
                            this.stringInputs.map((key, idx) => (
                                <div className="inputRow flexrc" key={idx}>
                                    <label>{this.tipsObj[key]}</label>
                                    <Input defaultValue={`请输入${this.tipsObj[key]}`} value={info[key]} onChange={event => this.eventHandler(event, key)} className="i350" />
                                </div>
                            ))
                        }

                        {
                            this.selectInputOne.map((key, idx) => (
                                <div className="inputRow flexrc" key={idx}>
                                    <label>{this.tipsObj[key]}</label>
                                    <Select value={info[key]} className="i350" onChange={value => this.valueHandler(value, key)}>
                                        {
                                            this.props[`${key}List`] && this.props[`${key}List`].map((val, idx) => (
                                                <Option key={idx} value={val}>{val}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            ))
                        }

                        {
                            this.numberInputs.map((key, idx) => (
                                <div className="inputRow flexrc" key={idx}>
                                    <label>{this.tipsObj[key]}</label>
                                    <InputNumber min={0} value={info[key]} onChange={value => { this.valueHandler(value, key) }} />
                                </div>
                            ))
                        }

                        <div className="inputRow flexrc">
                            <label>发布日期</label>
                            <DatePicker format="YYYY-MM-DD" value={this.calendarValuePipe(info.publishDate)} onChange={(date, dateString) => this.update_upTime(dateString)}/>
                        </div>

                        <div>
                            <div>内容</div>
                            <Editor
                                id={info.id || "new_content"}
                                content={info.content}
                                onChange={this.update_content}
                                imgPath='background/changshi'
                            />
                        </div>

                        {
                            checkPermission('sys:sense:edit') &&
                            <Card className="cardR" size="small" title="发布">
                                <Button type="primary" onClick={()=>this.update()}>发布</Button>
                            </Card>
                        }

                    </div>
                }
            </div>
        )
    }

}

function mapStatetoProps(state) {
    const { tags } = state.commonSenses;

    return {
        labelList: tags,
    };
}

export default connect(mapStatetoProps)(CommonSenseInfo);