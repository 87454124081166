import React, { useEffect } from 'react';
import { Result, Button, message } from 'antd';
import Cookies from 'js-cookie';

function NoPage(props) {
  // 声明一个新的叫做 “count” 的 state 变量
  // const [count, setCount] = useState(0);

  useEffect(() => {
    if (!Cookies.get('accessToken')) {
      message.warn('未登录');
      return props.history.push('/login');
    }
  }, [props]);

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="抱歉，并没有该页面！."
        extra={<Button type="primary" onClick={() => window.history.back(-1)}>回上一页</Button>}
      />,
    </div>
  );
}

export default NoPage