import apiRequest from '../common/request';
import OSS from 'ali-oss';
import { message } from 'antd';
import $ from "jquery";

/** 格式化富文本p标签，防止换行 */
const do1 = (str) => {
    return str.replace(/<p/g, '<span').replace(/<\/p>/g, '</span>');
}

/** 获取富文本字符串内容长度 */
const strToLength = (str) => {
    let p = document.createElement("p");
    p.innerHTML = str;
    return p.innerText.length;
}

/** 判断4个选项是否字数够短 */
const getOptions = (arr) => {
    return arr.find(i=>strToLength(i.value)>6);
}

/** 从试题来源获取年份地区对应 */
const getYearArea = (res) => {
    let str = '未知';
    if(!res.area || !res.year) return str;
    if (res.area.length==1 && res.year.length==1) {             // 年份地区都只有一个
        let y = res.year[0];
        let a = res.area[0];
        str = y + a;
    } if (res.area.length>1 && res.year.length>1) {             // 年份地区都有多个
        let y = res.year.find(i=>res.source.includes(i)) || res.year[0];
        let a = res.area.find(i=>res.source.includes(i)) || res.area[0];
        str = y + a;
    } else if (res.area.length>1 && res.year.length==1) {       // 年份有1个地区有多个
        let y = res.year[0];
        let a = res.area.find(i=>res.source.includes(i)) || res.area[0];
        str = y + a;
    } else if (res.area.length==1 && res.year.length>1) {       // 年份有多个地区有1个
        let y = res.year.find(i=>res.source.includes(i)) || res.year[0];
        let a = res.area[0];
        str = y + a;
    } 
    return str;
}

export const downWord = (res, p, fileName, isShow) => {
    /** 封面和页尾的标签page-break-before:always必须要有内容 */
    // <p>封面</p> 
    // <p style="page-break-before: always">*</p>
    // <p style="page-break-before: always">*</p>
    // <p>尾页</p>
    // jq形式    $('#demo').html()
    // dom形式   document.querySelector(".ee").outerHTML
    
    let content = res.map((item, index) => (
        `<div class="content">
            ${isShow==3 && 
            `<div>${item.quesExplain && `<div style="font-family: 楷体_GB2312;">${(p-1)*500 + index + 1}.解析: ${do1(item.quesExplain)}</div><br/>` || ''}
            </div>` ||
            `<div class="info">
                ${item.materialsContent && `<h4 style="font-family: Times New Roman;">***材料***</h4><div>${item.materialsContent}</div>` || ''}
                <div><span style="font-family: Times New Roman;">${(p-1)*500 + index + 1}.【${getYearArea(item)}】</span>${do1(item.content)}</div>
                ${item.options && item.options.map(i => {                    
                    if(strToLength(i.value)<7 && !getOptions(item.options)) {
                        return `<span style="font-family: Times New Roman;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${i.key + '.' + do1(i.value)}</span>`
                    } else {
                        return `<div style="font-family: Times New Roman;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${i.key + '.' + do1(i.value)}</div>`
                    }
                }) || ''}
                ${isShow==1 && item.quesExplain && `<div style="font-family: 楷体_GB2312;"><br/>解析: ${do1(item.quesExplain)}</div>` || ''}
            </div>
            <br/>`}
        </div>`
    ));

    let box = `<div id='dd'>${content.join().replace(/,/g, '')}</div>`;

    let doc = document.createRange().createContextualFragment(box);

    // Embed all images using Data URLs
    var images = [];
    // .find(DOM) 是jq里面的方法
    // dom 获取所有的img标签
    // var img = document.getElementsByTagName("img");
    var img = $(doc.querySelector('#dd')).find('img');

    let promise = new Promise(function (resolve, reject) {
         if(!img.length) return resolve();
         for (let i = 0; i < img.length; i++) {
            img[i].onload = function async () {
                // Calculate dimensions of output image
                var w = Math.min(img[i].width, 400);
                var h = img[i].height * (w / img[i].width);    
                // Create canvas for converting image to data URL
                var canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                var uri = canvas.toDataURL('image/jpg');
                // Draw image to canvas
                var context = canvas.getContext('2d');
                // context.fillRect(0, 0, w, h);
                context.drawImage(img[i], 0, 0, w, h);
                // Get data URL encoding of image
                // $(img[i]).attr("src", img[i].src);
                if(w!=0) {      // 图片没有宽高不需要处理
                    img[i].width = w;
                    img[i].height = h;
                }
                // Save encoded image to array
                images[i] = {
                    type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
                    encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
                    location: img[i].src,
                    data: uri.substring(uri.indexOf(",") + 1)
                };
                (i==img.length-1) && resolve();
            }
        }
    });
    // Prepare bottom of mhtml file with image data
    promise.then(function() {
        var mhtmlBottom = "\n";
        for (let i = 0; i < images.length; i++) {
            mhtmlBottom += "--NEXT.ITEM-BOUNDARY\n";
            mhtmlBottom += "Content-Location: " + (images[i] && images[i].location || 'null') + "\n";
            mhtmlBottom += "Content-Type: " + (images[i] && images[i].type || 'null')+ "\n";
            mhtmlBottom += "Content-Transfer-Encoding: " + (images[i] && images[i].encoding || 'null') + "\n\n";
            mhtmlBottom += (images[i] && images[i].data|| 'null') + "\n\n";
        }
        mhtmlBottom += "--NEXT.ITEM-BOUNDARY--";

        let html = `
        <html xmlns:v=\"urn:schemas-microsoft-com:vml\" xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:w=\"urn:schemas-microsoft-com:office:word\" xmlns:m=\"http://schemas.microsoft.com/office/2004/12/omml\" xmlns=\"http://www.w3.org/TR/REC-html40\">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
            <!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val=\"Cambria Math\"/><m:brkBin m:val=\"before\"/><m:brkBinSub m:val=\"--\"/><m:smallFrac m:val=\"off\"/><m:dispDef/><m:lMargin m:val=\"0\"/> <m:rMargin m:val=\"0\"/><m:defJc m:val=\"centerGroup\"/><m:wrapIndent m:val=\"1440\"/><m:intLim m:val=\"subSup\"/><m:naryLim m:val=\"undOvr\"/></m:mathPr></w:WordDocument></xml><![endif]-->
            <style type="text/css">
            <!-- /*FontDefinitions*/
            @page WordSection {
                mso-title-page:false; //首页不显示页眉页脚
                mso-page-numbers:1; //起始页码从0开始
                mso-header-margin: 10px;
                mso-footer-margin: 10px;
                mso-header: h1;
                mso-footer: f1;
                margin: 30pt 30pt 30pt 30pt;
            }
            div.WordSection {
                page: WordSection;
            }
            -->
            </style>
        </head>
        <body style="vertical-align: bottom">
            <div class="WordSection">
            ${$(doc.querySelector('#dd')).html()}
            <div style=\"mso-element:footer;text-align: center;\" id=\"f1\">\n
                <p class=\"MsoNum\"><span class=\"num\" style=\"mso-field-code: PAGE \"></span>/<span style=\"mso-field-code:NUMPAGES\"></span>\n</p>\n
            </div>
            </div>
        </body>
        </html>`;

        // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
        let blob = new Blob([html + mhtmlBottom], { type: "application/json" });
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = `${fileName}.docx`;
        a.target = '_blank';
        a.href = url;
        a.click();
    });
    return promise;
}

/** 把s转化为时分秒 */
export const  formatSeconds = (value) => {
    if (value != 0 && !value) return '';
    let theTime = parseInt(value);                  // 秒
    let middle = 0;                                 // 分
    let hour = 0;                                   // 小时
    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    let result = "" + parseInt(theTime) + "秒";
    if (middle > 0) {
        result = "" + parseInt(middle) + "分" + result;
    }
    if (hour > 0) {
        result = "" + parseInt(hour) + "小时" + result;
    }
    return result;
}

/** key(需要检索的键） url（传入的需要分割的url地址，例：?id=2&age=18） */
export const getSearchQuery = (key) => {
    let str = window.location.search;
    str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    var arr = str.split("&");
    var obj = {};
    // 将每一个数组元素以=分隔并赋给obj对象 
    for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return obj[key];
}

/**
  *@Description: 这是一个上传阿里云的方法
  *@Author: hujianwen
  *@CreateDate: 2021-03-30 14:57:05
  *@Explain: 
  *@param: fileName-文件名 file-上传的文件 bucket-仓库标签
  *
 */
export const uploadOSS = async (fileName, file, bucket) => {
    let tokenJson = await apiRequest('/gk/ops/sts/getToken?type=oss', { method: 'GET', credentials: 'include' });
    if (tokenJson.status != 1) return;
    let result = tokenJson.obj;
    let client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.securityToken,
        region: 'oss-cn-hangzhou',
        bucket
    });
    let putResponse = await client.multipartUpload(fileName, file, {
        progress: function (p) {
            p = p * 100;
        }
    })
    return putResponse;
}

/**
  @Description : 这是一个上传阿里云的方法
  @Author : jianwen
  @CreateDate : 2022-01-11 14:57:05
  @param fileName  文件名 
  @param file      上传文件
  @param bucket    仓库
  @param cname     是否使用自定义域名
  @param cb        成功回调
 */
  export const uploadOSS2 = async (fileName, file, bucket, cname, cb) => {
    let tokenJson = await apiRequest('/gk/ops/sts/getToken?type=oss', { method: 'GET', credentials: 'include' });
    if (tokenJson.status != 1) return;
    let result = tokenJson.obj;
    let endpoint = cname ? 'https://resource.sinture.com' : 'https://oss-cn-beijing.aliyuncs.com';
    let client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.securityToken,
        cname: cname,
        endpoint,
        bucket
    });
    client.multipartUpload(fileName, file).then((result)=>{
        let res = result.res;
        if(res.status == 200) {
            message.success('上传成功');
            // 这里为什么用split截取一下？因为图片太大上传返回的链接会拼参数，有效的图片访问链接是没有参数的
            // 大图片返回的requestUrls: https://gk-resource.oss-cn-beijing.aliyuncs.com/background/goods/1641691243994.png?uploadId=7E3B570421404B92BB9A24ED97D906F3
            // 小图片返回的requestUrls: https://gk-resource.oss-cn-beijing.aliyuncs.com/background/goods/1641691243994.png
            let url = res.requestUrls[0].split('?')[0];
            cb && cb(url);
        }
    }).catch(error => {
        message.error('上传失败');
        console.log(error)
    });
}

/** 生成前缀链接 */
export const generateBaseUrl = () => {
    let url = 'https://api-gk.sinture.com';
    let location = window.location.href;
    if (location.match('gk.sinture')) {
        url = 'https://api-gk.sinture.com';
    } else if (location.match('gk-test.sinture')) {
        url = 'https://api-test.sinture.com';
    } else if (location.match('127.0.0.1') || location.match('localhost')) {
        url = 'https://api-test.sinture.com';
    } else if (location.match('gk-pre.sinture')) {
        url = 'https://api-pre.sinture.com';
    } else {
        url = 'https://api-gk.sinture.com';
    }
    return url;
}

export const checkPermission = (meta) => {
    if(localStorage.getItem('user') =='undefined') {
        window.location = '/login';
        return;
    }
    let user = JSON.parse(localStorage.getItem('user'));
    let permissions = user.permissions;
    if(permissions.indexOf(meta)> -1) {
        return true;
    } else {
        return false;
    }
}

/**
 * 校验方法，凡是在黑名单里面的字段，那么就必须是一个”有效值“
 * @param {Object} fish : 对象
 * @param {Array} blackList : 黑名单，只有在黑名单里面的属性才需要进行校验
 * @param {Object} tips : 键名所对应的提示语
 * @param {Boolean} cantZero : 可选参数，如果是校验数字的话，那么需要判断数字可否是0
 */
 export const  validateUtil = (fish, blackList, tips, cantZero=false) => {
    let result = false;
    for (let key of blackList) {
        const cn = tips[key];       // 获取字段对应的名字
        if (fish[key]==='') {
            let tip = cn ? `请输入${cn}` : '输入不能为空';
            if (fish[key]===0 && cantZero) {
                tip = `${cn}不能为0`
            }
            message.warning(tip);
            result = true;
            break;
        }
    }
    return result;
}