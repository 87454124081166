import { message } from 'antd';
import { getAvailableVideoUrl } from '../../components/VideoPicker/action';
import apiRequest from '../../common/request'
import OSS from 'ali-oss';
const PREFIX = "InstitutionInfo";

export const REQUEST_UPDATE_QUESTION = PREFIX + "REQUEST_UPDATE_QUESTION";
export const REQUEST_UPDATE_QUESTION_VIDEO = PREFIX + "REQUEST_UPDATE_QUESTION_VIDEO";
export const REQUEST_QUESTION_INFO = PREFIX + "REQUEST_QUESTION_INFO";
export const RECEIVE_QUESTION_INFO = PREFIX + "RECEIVE_QUESTION_INFO";
export const RECEIVE_UPDATE_QUESTION_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_RESULT";
export const RECEIVE_UPDATE_QUESTION_VIDEO_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_VIDEO_RESULT";
export const UPDATE_QUESTION_TITLE = PREFIX + "UPDATE_QUESTION_TITLE";
export const UPDATE_QUESTION_POINT = PREFIX + "UPDATE_QUESTION_POINT";
export const UPDATE_QUESTION_SOURCE = PREFIX + "UPDATE_QUESTION_SOURCE";
export const UPDATE_QUESTION_KNOWLEDGE = PREFIX + "UPDATE_QUESTION_KNOWLEDGE";
export const UPDATE_QUESTION_TYPE = PREFIX + "UPDATE_QUESTION_TYPE";
export const UPDATE_QUESTION_ANSWER_DESC = PREFIX + "UPDATE_QUESTION_ANSWER_DESC";
export const UPDATE_QUESTION_ANALYSIS = PREFIX + "UPDATE_QUESTION_ANALYSIS";
export const UPDATE_SINGLE_QUESTION_ANSWER = PREFIX + "UPDATE_SINGLE_QUESTION_ANSWER";
export const UPDATE_QUESTION_SELECTION = PREFIX + "UPDATE_QUESTION_SELECTION";
export const ADD_UPDATE_QUESTION_OPTION = PREFIX + "ADD_UPDATE_QUESTION_OPTION";
export const REMOVE_UPDATE_QUESTION_OPTION = PREFIX + "REMOVE_UPDATE_QUESTION_OPTION";
export const UPDATE_QUESTION_CATEGORY = PREFIX + "UPDATE_QUESTION_CATEGORY";
export const UPDATE_MULTIPLE_QUESTION_ANSWER = PREFIX + "UPDATE_MULTIPLE_QUESTION_ANSWER";
export const UPDATE_QUESTION_MATERIALS_TYPE = PREFIX + "UPDATE_QUESTION_MATERIALS_TYPE";
export const QUESTION_RECEIVE_MATERIAL_INFO = PREFIX + "QUESTION_RECEIVE_MATERIAL_INFO";
export const QUESTION_REQUEST_MATERIAL_INFO = PREFIX + "QUESTION_REQUEST_MATERIAL_INFO";
export const UPDATE_VIDEO_PROGRESS = PREFIX + "UPDATE_VIDEO_PROGRESS";
export const UPDATE_URL = PREFIX + "UPDATE_URL";
export const UPDATE_QUESTION_TAG_TYPE = PREFIX + "QUESTION_INFO_UPDATE_QUESTION_TAG_TYPE";
export const UPDATE_SIGNED_VIDEO_URL = PREFIX + "QUESTION_INFO_UPDATE_SIGNED_VIDEO_URL";
export const UPDATE_PROVINCE_TYPE = PREFIX + "UPDATE_PROVINCE_TYPE";
export const UPDATE_YEARS_TYPE = PREFIX + "UPDATE_YEARS_TYPE";

export function update_signedVideoUrl(value) {
    return {
        type: UPDATE_SIGNED_VIDEO_URL,
        key: 'signedVideoUrl',
        value
    };
}

export function request_question_info() {
    return {
        type: REQUEST_QUESTION_INFO
    }
}

export function receive_question_info(question) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question
    }
}

function request_update_question() {
    return {
        type: REQUEST_UPDATE_QUESTION
    }
}

function request_update_question_video() {
    return {
        type: REQUEST_UPDATE_QUESTION_VIDEO
    }
}

function receive_update_question_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_RESULT
    }
}

function receive_update_question_video_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_VIDEO_RESULT
    }
}


export function remove_option() {
    return { type: REMOVE_UPDATE_QUESTION_OPTION }
}

export function add_option() {
    return { type: ADD_UPDATE_QUESTION_OPTION }
}

export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function update_question_selection(index, key, value) {
    return {
        type: UPDATE_QUESTION_SELECTION,
        index,
        key,
        value
    }
}

export function update_question_point(value) {
    return {
        type: UPDATE_QUESTION_POINT,
        key: 'point',
        value
    }
}

export function update_question_source(value) {
    return {
        type: UPDATE_QUESTION_SOURCE,
        key: 'source',
        value
    }
}

export function update_question_knowledge(value) {
    return {
        type: UPDATE_QUESTION_KNOWLEDGE,
        key: 'knowledge',
        value
    }
}

export function update_single_question_answer(value) {
    return {
        type: UPDATE_SINGLE_QUESTION_ANSWER,
        key: 'answer',
        value
    }
}

export function update_multiple_question_answer(value) {
    return {
        type: UPDATE_MULTIPLE_QUESTION_ANSWER,
        key: 'answer',
        value
    }
}

export function update_question_title(value) {
    return {
        type: UPDATE_QUESTION_TITLE,
        key: 'content',
        value
    }
}

export function update_url(value) {
    return {
        type: UPDATE_URL,
        key: 'videoUrl',
        value
    }
}

export function update_question_type(value) {
    return {
        type: UPDATE_QUESTION_TYPE,
        key: 'quesType',
        value
    }
}

export function update_question_answer_desc(value) {
    return {
        type: UPDATE_QUESTION_ANSWER_DESC,
        key: 'quesExplain',
        value
    }
}
export function update_question_analysis(value) {
    return {
        type: UPDATE_QUESTION_ANALYSIS,
        key: 'issue',
        value
    }
}

export function update_category(value) {
    return {
        type: UPDATE_QUESTION_CATEGORY,
        key: 'categoryId',
        value
    }
}

export function update_question_materials_type(value) {
    return {
        type: UPDATE_QUESTION_MATERIALS_TYPE,
        key: 'materialsType',
        value
    }
}

export function update_province_type(value) {
    return {
        type: UPDATE_PROVINCE_TYPE,
        key: 'area',
        value
    }
}

export function update_years_type(value) {
    return {
        type: UPDATE_YEARS_TYPE,
        key: 'year',
        value
    }
}

export function fetchEmpty(value) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question: {
            quesType: 0,
            content: "",
            answer: [],
            quesExplain: "",
            categoryId: "-1",
            options: [{ "key": "", "value": "" }, { "key": "", "value": "" }, { "key": "", "value": "" }, { "key": "", "value": "" }],
            source: "",
            questionTag: 2, // 默认原创
            area: [],
            year: []
        }
    }
}

/** 更新试题的tag类型 */
export function update_question_tag_type(value) {
    return {
        type: UPDATE_QUESTION_TAG_TYPE,
        key: 'questionTag',
        value
    };
}

export function fetchQuestion(type, id) {

    return dispatch => {
        dispatch(request_question_info());
        return apiRequest(`/gk/ops/${type}/question/details/${id}`)
            .then(json => {
                dispatch(receive_question_info(json.obj));
            })
            .catch(error => console.log(error));
    }
}

export function updateQuestionVideo(file, info) {
    let categoryTree = (info && info.categoryTree) || [];
    if (Object.prototype.toString.call(categoryTree) != '[object Array]') {
        message.warning('所选题目不存在分类信息');
        return;
    }
    let fileName = `/question`;
    categoryTree.map(item => {
        fileName += `/${item.name}`;
    });
    if (info.id) {
        fileName += `/${info.id}-${file.name}`;
    } else {
        fileName += `/${file.name}`;
    }
    return async dispatch => {
        dispatch(request_update_question_video());
        let tokenJson = await apiRequest("/gk/ops/sts/getToken?type=oss", { method: 'GET', credentials: 'include' });
        if (tokenJson.status !== 1) {
            message.warning('获取OSS token时发生错误');
            return;
        }
        let tokenObj = tokenJson.obj;
        let client = new OSS({
            accessKeyId: tokenObj.accessKeyId,
            accessKeySecret: tokenObj.accessKeySecret,
            stsToken: tokenObj.securityToken,
            endpoint: 'https://oss-cn-beijing.aliyuncs.com',
            bucket: 'gk-course'
        });
        client.multipartUpload(fileName, file, {
            progress: function (p) {
                return function (done) {
                    dispatch(update_video_progress(p * 100 + "%"));
                    done();
                };
            },
        }).then(async (result) => {
            dispatch(receive_update_question_video_result());
            let videoUrl = result.name;
            if (videoUrl && videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
            let canPlayUrl = await getAvailableVideoUrl(videoUrl);
            dispatch(update_signedVideoUrl(canPlayUrl));
            dispatch(update_url(result.name));
        }).catch(function (err) {
            dispatch(receive_update_question_video_result());
            message.warning('上传文件到OSS发生错误');
        });
    }
}

export function updateQuestion(type, questionInfo, showAY) {    
    let data = {};
    data.answer = questionInfo.answer.toString();
    data.categoryId = questionInfo.categoryId;
    data.content = questionInfo.content;
    data.id = questionInfo.id;
    data.options = questionInfo.options;
    if (questionInfo.videoUrl) {
        let videoUrl = questionInfo.videoUrl;
        if (videoUrl && videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
        data.videoUrl = videoUrl;
    }
    if (questionInfo.source) data.source = questionInfo.source;
    data.quesExplain = questionInfo.quesExplain;
    data.quesType = questionInfo.quesType;
    data.materialsType = questionInfo.materialsType;
    data.questionTag = questionInfo.questionTag;
    questionInfo.issue && (data.issue = questionInfo.issue)
    //地区，年份
    if (showAY) {                                 // 年份以及地区显示了才有必要输入
        if (questionInfo.area && questionInfo.area.length) { data.area = questionInfo.area; }
        if (questionInfo.year && questionInfo.year.length) { data.year = questionInfo.year; }
    }

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_question());
        return apiRequest(`/gk/ops/${type}/question/saveOrUpdate`, config)
            .then(json => {
                dispatch(receive_update_question_result());
                message.success('操作成功');
                window.history.back();
            })
            .catch(error => {
                dispatch(receive_update_question_result());
                console.log(error)
            });
    }
}

function receive_material_info(info) {
    return {
        type: QUESTION_RECEIVE_MATERIAL_INFO,
        info
    }
}

function request_material_info() {
    return {
        type: QUESTION_REQUEST_MATERIAL_INFO
    }
}

export function fetchMaterialQuestion(type, id) {
    if (id)
        return dispatch => {
            dispatch(request_material_info());
            return apiRequest(`/gk/ops/${type}/materials/details/${id}`, { credentials: 'include' })
                .then(json => {
                    dispatch(receive_material_info(json.obj))
                })
                .catch(error => console.log(error));
        }
}
