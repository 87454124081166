import React from 'react';
import { Tree, DatePicker, Popconfirm, Switch, Radio, Table, Input, Button, Modal, Select, Checkbox } from 'antd';
import { getSystemTreeSelect, roleMenuTreeSelect, changeStatus, getSystemRoleList, systemDeleteRole, systemAddOrUpdateRole } from './action';
import moment from 'moment';
import './style.scss';
import { checkPermission } from '../../utils/utils';
import { Link } from 'react-router-dom';

const TreeNode = Tree.TreeNode;
const { RangePicker } = DatePicker;
const { Option } = Select;
export default class SystemRole extends React.Component {

    constructor(props) {
        super(props)
        this.halfIds = [];  // 用于储存半选父id
        this.init = {
            id: 0,
            menuIds: [],
            roleName: '',
            roleKey: '',
            roleSort: 1,
            status: 0,
            halfCheckedKeys: [],
        }

        this.state = {
            ...this.init,
            checked2: false,
            isModalVisible: false,
            addOrUpdate: false,
            startTime: '',
            endTime: '',
            roleKeySel: '',
            roleNameSel: '',
            statusSel: '',
            page: 1,
            pageSize: 10,
            roleListObj: {},
            systemRoleList: [],
            treeList: [],
        }
    }

    componentDidMount() {
        this.refrshData();
    }

    /** true新增还是false修改 */
    getTreeData = async (type, roleId) => {
        let treeList = []
        if (type) {
            treeList = await getSystemTreeSelect({});
        } else {
            let obj = await roleMenuTreeSelect(roleId);
            treeList = obj.treeSelects;
            let menuIds = obj.menuIds.length && obj.menuIds.map(item => item.toString()) || [];
            await this.setState({ menuIds });
        }
        await this.setState({ treeList });
    }

    /** 获取表格全部数据 */
    refrshData = async () => {
        let roleListObj = await getSystemRoleList({});
        let systemRoleList = roleListObj.content;
        this.setState({ roleListObj, systemRoleList });
    }

    /** 渲染表格头部 */
    tableTitle = () => (
        <div className="tableHeader">
            {checkPermission('sys:role:add') && <Button type="primary" onClick={() => this.handleAdd()}>新增</Button>}
        </div>
    )

    /** 页改变 */
    pageChange = async (p) => {
        this.state.page = p
        await this.setState({ page: p });
        await this.getSearchData();
    }

    /** 页大小改变 */
    onShowSizeChange = async (p, s) => {
        this.state.pageSize = s
        await this.setState({ page: 1, pageSize: s });
        await this.getSearchData();
    }

    /** 下拉框状态 */
    onChangeSelect = async (value, key) => {
        await this.setState({ [key]: value, page: 1 });
        await this.getSearchData();
    }

    /** 条件搜索列表数据 */
    getSearchData = async () => {
        let { endTime, roleNameSel, roleKeySel, statusSel, startTime, page, pageSize } = this.state;
        let data = {
            page,
            pageSize,
            status: statusSel,
            startTime: startTime,
            endTime: endTime,
            roleKey: roleKeySel,
            roleName: roleNameSel
        }
        let roleListObj = await getSystemRoleList(data);
        let systemRoleList = roleListObj.content;
        this.setState({ roleListObj, systemRoleList });
    }

    /** 搜索 */
    search = async () => {
        await this.setState({ page: 1 });
        this.getSearchData();
    }

    /** 重置表格 */
    reset = async () => {
        await this.setState({ page: 1, startTime: '', roleNameSel: '', statusSel: '', roleKeySel: '', endTime: '' });
        this.refrshData();
    }

    /** 新增列表 */
    handleAdd = async () => {
        await this.getTreeData(true);
        this.setState({ addOrUpdate: false, isModalVisible: true });

    }

    /** 公共状态修改方法 */
    onChangeCommon = (value, key) => {
        this.setState({ [key]: value });
    }

    /** 树结构点击选择事件 */
    onCheck = (checkedKeys, e) => {
        let halfCheckedKeys = e.halfCheckedKeys;
        this.setState({ menuIds: checkedKeys, halfCheckedKeys });
    }

    /** 提交数据 */
    senPost = async (addOrUpdate) => {
        let { roleName, roleKey, roleSort, menuIds, halfCheckedKeys, status, id } = this.state;

        let data = {
            menuIds: menuIds.concat(halfCheckedKeys),
            roleName, roleKey, roleSort, status, id
        }
        await systemAddOrUpdateRole(data, addOrUpdate);
        this.setState({ isModalVisible: false, ...this.init });
        this.getSearchData();
    }

    /** 修改数据时，回显数据 */
    setStatus = (record) => {
        this.setState({
            id: record.id,
            roleKey: record.roleKey,
            roleName: record.roleName,
            status: record.status,
            roleSort: record.roleSort,
        });
    }

    /** check=true全选，否则反选 */
    onChangeCheck = (check) => {
        let { treeList } = this.state;
        let menuIds = [];
        if (check) {
            menuIds = this.getAllIds(treeList, []);
        }
        this.setState({ checked2: check, menuIds, halfCheckedKeys: [] });
    }

    /** 全选获取所以id */
    getAllIds = (arr, menuIds) => {
        arr.length && arr.map(item => {
            menuIds.push(item.id.toString());
            if (item.children && item.children.length) {
                this.getAllIds(item.children, menuIds);
            }
        })
        return menuIds;
    }

    /** 修改列表项 */
    updateList = async (record) => {
        await this.getTreeData(false, record.id);
        // 每次打开修改都要重置半选父id
        this.halfIds = [];
        // 获取半选父id
        this.getNodePid(this.state.treeList, this.state.menuIds);
        let halfCheckedKeys = this.halfIds;
        this.setState({ addOrUpdate: true, isModalVisible: true, halfCheckedKeys });
        this.setStatus(record);
    }

    /** 获取半选情况的父id */
    getNodePid = (parent, arr) => {
        parent.map(item1 => {
            // 直接遍历树结构的第一层children
            item1.children.length && item1.children.map(item2 => {
                // 如果在第一层的children下面直接包含id,说明这个数据只有一层children。直接把第一层的根id拿到
                if (arr.includes(item2.id.toString())) {
                    // 这里相当于去重，相同的根id，push一次就行了              
                    !this.halfIds.includes(item1.id.toString()) && this.halfIds.push(item1.id.toString());
                } else {
                    // 如果在第一层的children没有找到id,说明这个数据有第二层children
                    item2.children && item2.children.length && item2.children.map(item3 => {
                        if (arr.includes(item3.id.toString())) {
                            // 如果在第二层children找到id, 直接把第二层的id和第一层的根id，全部拿到
                            !this.halfIds.includes(item2.id.toString()) && this.halfIds.push(item2.id.toString());
                            !this.halfIds.includes(item1.id.toString()) && this.halfIds.push(item1.id.toString());
                            // 这里相当于去重，相同的根id，push一次就行了
                        }
                    })
                }
            })
        })
    }

    /** 删除列表项 */
    deleteList = async (record) => {
        await systemDeleteRole(record.id);
        this.getSearchData();
    }

    /** 改变角色状态 */
    changeRoleStatus = async (record) => {
        let status = record.status ? 0 : 1;
        await changeStatus(record.id, status);
        this.getSearchData();
    }

    /** 关闭模态框，重置状态 */
    onCancelModal = () => {
        this.setState({ ...this.init, isModalVisible: false, checked2: false })
    }

    rangePickerChange = (dateStrings) => {
        this.setState({ startTime: dateStrings[0], endTime: dateStrings[1], })
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';

    render() {
        let { checked2, treeList, roleKeySel, startTime, endTime, roleName, menuIds, roleKey, roleSort, status, isModalVisible, addOrUpdate, roleNameSel, statusSel, page, pageSize, roleListObj, systemRoleList } = this.state;
        const columns = [
            {
                title: '角色id',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
            },
            {
                title: '角色名称',
                width: '15%',
                dataIndex: 'roleName',
                key: 'roleName',
            },
            {
                title: '权限字符',
                width: '15%',
                dataIndex: 'roleKey',
                key: 'roleKey',
            },
            {
                title: '显示顺序',
                width: '10%',
                dataIndex: 'roleSort',
                key: 'roleSort',
            },
            {
                title: '状态',
                width: '10%',
                render: (record, text) => {
                    return (
                        <Popconfirm
                            title="你确定删除该菜单项吗?"
                            onConfirm={() => this.changeRoleStatus(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <span>
                                {checkPermission('sys:role:update') && <Switch checkedChildren="正常" unCheckedChildren="停用" checked={!record.status} />}
                            </span>
                        </Popconfirm>
                    )
                }
            },
            {
                title: '创建时间',
                width: '20%',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            },
            {
                title: '操作',
                width: '20%',
                render: (record, text) => {
                    return (
                        <div>
                            {checkPermission('sys:role:list') &&
                                <Link to={`/sysRoleList?roleId=${record.id}`}>
                                    <Button size="small" style={{ marginRight: '5px' }} type="primary">分配用户</Button>
                                </Link>
                            }
                            {checkPermission('sys:role:update') &&
                                <Button onClick={() => this.updateList(record)} size="small" style={{ marginRight: '5px' }} type="primary">修改</Button>
                            }
                            {/* <Popconfirm
                                title="你确定删除该菜单项吗?"
                                onConfirm={() => this.deleteList(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                                {checkPermission('sys:role:del') && <Button size="small" style={{ marginRight: '5px' }} type="danger">删除</Button>}
                            </Popconfirm> */}
                        </div>
                    )
                }
            },
        ];

        const loop = data => { //打造符合树结构的数据结构
            return data.map(item => {
                if (item.children && item.children.length) {
                    return <TreeNode checkable key={item.id} title={item.label}>{loop(item.children)}</TreeNode>
                }
                return <TreeNode checkable key={item.id} title={item.label} />
            })
        }

        return (
            <div className="systemUser">
                {checkPermission('sys:role:query') && <div className="commonSearchBox">
                    <div><label>角色名称</label><Input value={roleNameSel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ roleNameSel: e.target.value })} placeholder="请输入角色名称" style={{ width: 150 }} /></div>
                    <div><label>权限字符</label><Input value={roleKeySel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ roleKeySel: e.target.value })} placeholder="请输入权限字符" style={{ width: 150 }} /></div>
                    <div><label>状态</label>
                        <Select value={statusSel} onChange={value => this.onChangeSelect(value, 'statusSel')} placeholder="菜单状态" style={{ width: '150px' }}>
                            <Option value="">全部</Option>
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </div>
                    <div><label>创建时间</label>
                        <RangePicker style={{ width: 400 }} value={[this.calendarValuePipe(startTime), this.calendarValuePipe(endTime)]} onChange={(dates, dateStrings) => this.rangePickerChange(dateStrings)} />
                    </div>
                    <div><Button type="primary" onClick={() => this.search()}>搜索</Button></div>
                    <div><Button onClick={() => this.reset()}>重置</Button></div>
                </div>}

                <Table
                    columns={columns}
                    dataSource={systemRoleList}
                    rowKey={(record) => record.id}
                    title={() => this.tableTitle()}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: page,
                        pageSize: pageSize,
                        total: roleListObj.totalElements,
                        onShowSizeChange: this.onShowSizeChange,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                <Modal title={addOrUpdate ? '修改角色' : '添加角色'} visible={isModalVisible} onOk={() => this.senPost(addOrUpdate)} onCancel={() => this.onCancelModal()}>
                    <div className="systemM">
                        <div className="helf">
                            <label>角色名称</label>
                            <Input onChange={e => this.onChangeCommon(e.target.value, 'roleName')} value={roleName} style={{ width: 150 }} />
                        </div>
                        <div className="helf">
                            <label>权限字符</label>
                            <Input onChange={e => this.onChangeCommon(e.target.value, 'roleKey')} value={roleKey} style={{ width: 150 }} />
                        </div>
                        {<div className="helf">
                            <label>角色顺序</label>
                            <Input type="number" onChange={e => this.onChangeCommon(e.target.value, 'roleSort')} value={roleSort} style={{ width: 150 }} />
                        </div>}
                        {<div className="helf">
                            <label>状态</label>
                            <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'status')} value={status}>
                                <Radio value={0}>正常</Radio>
                                <Radio value={1}>停用</Radio>
                            </Radio.Group>
                        </div>}
                        <div>
                            <label>菜单权限</label>
                            <Checkbox className="checkbox" checked={checked2} onChange={(e) => this.onChangeCheck(e.target.checked)}>全选反选</Checkbox>
                            <Tree
                                className="tree"
                                checkable
                                checkedKeys={menuIds}
                                onCheck={this.onCheck}
                            >
                                {loop(treeList)}
                            </Tree>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}
