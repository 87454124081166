/**
 * Created by pwq on 2017/07/11.
 */
import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    FILL_EXAMINE_TITLE,
    FILL_EXAMINE_LIMIT_TIME, 
    FILL_EXAMINE_UP_TIME,
    SELECT_QUESTION,
    ORDER_QUESTION,
    REQUEST_UPDATE_EXAMINE,
    RECEIVE_UPDATE_EXAMINE_RESULT_SUC,
    RECEIVE_UPDATE_EXAMINE_RESULT_FAIL, REQUEST_DAILY_EXAMINE_INFO, RECEIVE_DAILY_EXAMINE_INFO,
    RECEIVE_SIMPLE_DAILY_EXAMINE_INFO,
    FILL_EXAMINE_QARR,
    FILL_EXAMINE_QUESTIONS
} from "./actions";

export function dailyExamineInfo(state = {
    isFetchingExamine: false,
    isFetchingQuestions: false,
    isUpdating: false,
    isUpdated: false,
    items: [],
    categoryId: '-1',
    totalPages: 1,
    info: {
        title: "",
        quesArr: [],
        questions: []
    }
}, action) {
    switch (action.type) {
        case REQUEST_DAILY_EXAMINE_INFO:
            return Object.assign({}, state, {isFetchingExamine: true});
        case RECEIVE_DAILY_EXAMINE_INFO:
            return Object.assign({}, state, {
                isFetchingExamine: false,
                info: action.dailyExamine,
                receivedAt: action.receivedAt
            });
        case RECEIVE_SIMPLE_DAILY_EXAMINE_INFO:
            return Object.assign({}, state, {
                info: action.dailyExamine,
                receivedAt: action.receivedAt
            });
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, {isFetchingQuestions: true});
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetchingQuestions: false,
                items: action.questionList,
                totalPages: action.totalPages,
                lastUpdated: action.receivedAt
            });
        case FILL_EXAMINE_TITLE:
        case FILL_EXAMINE_LIMIT_TIME:
        case FILL_EXAMINE_UP_TIME:
        case FILL_EXAMINE_QARR:
        case FILL_EXAMINE_QUESTIONS:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case REQUEST_UPDATE_EXAMINE:
            return Object.assign({}, state, {isUpdating: true});
        case RECEIVE_UPDATE_EXAMINE_RESULT_SUC:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_EXAMINE_RESULT_FAIL:
            return Object.assign({}, state, {isUpdating: false, isUpdated: false});
        default:
            return state;
    }
}
