import {
  START_LOAD_EXAMINE_LIST,
  FETCH_DATA_SUCCED,
  FETCH_EXAMLIST_SUCCED,
  SET_ACTIVE_EXAM_TYPE,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_VALUE,
  RESET_SEARCH_VALUE,
  PUBLISH,
  HAS_PUBLISH,
  NO_PUBLISH,
  ALL_TEXT,
  SHOULD_SHARE,
  NOT_SHARE
} from './action';

// 默认的筛选数据
const defaultSearchValue = {
  area: ALL_TEXT,                 // 根据地区进行筛选
  publish: PUBLISH,               // 默认不限
  query: '',                      // 试卷标题关键词
  year: ALL_TEXT,                 // 年份
  share: ALL_TEXT,                // 根据分享状态进行筛选
};

const initialState = {
  loading: false,                 // 是否处于加载状态中
  responseObj: {
    content: [],                  // 这才是试卷列表数据
  },                              // 接口响应数据
  activeExamType: '',             // 处于焦点状态的试卷类型
  examTypeList: [],               // 试卷类型列表
  page: 1,                        // 第几页
  pageSize: 10,                   // 每页多少条数据
  publishList: [ HAS_PUBLISH, NO_PUBLISH ],
  shareList: [ SHOULD_SHARE, NOT_SHARE ],

  // 下面是筛选数据
  ...defaultSearchValue
};

export const examineList = (prevState=initialState, action) => {
  switch(action.type) {
    case START_LOAD_EXAMINE_LIST:
      return { ...prevState, loading: true };
    case FETCH_DATA_SUCCED:
      return { ...prevState, responseObj: action.data, loading: false };
    case FETCH_EXAMLIST_SUCCED:
      return { ...prevState, examTypeList: action.data };
    case SET_ACTIVE_EXAM_TYPE:
      let aet = action.data || prevState.examTypeList[0];                   // 如果没有传数据的话，那么就表明是使用第一项
      return { ...prevState, activeExamType: aet };
    case SET_PAGE:
      return { ...prevState, page: action.data };
    case SET_PAGE_SIZE:
      return { ...prevState, pageSize: action.data };
    case SET_SEARCH_VALUE:
      return { ...prevState, [action.key]: action.value };
    case RESET_SEARCH_VALUE:
      return { ...prevState, ...defaultSearchValue };
    default:
      return prevState;
  }
}