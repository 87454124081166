import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, message, Spin } from 'antd';
import { 
    remarksHandler,
    fetchMenuInform,
    fetchMenuDetail,
} from './action';
import { getSearchQuery } from '../../utils/utils';
import './index.scss';

const { TextArea } = Input;
class PositionMenu extends React.Component {

    state = {
        loading: false,                                              // 是否处于加载状态中
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.init();
    };

    /** 初始化 */
    init = async () => {
        let info = await fetchMenuDetail();
        this.props.dispatch(remarksHandler(JSON.stringify(info.selfmenu_info) || ''));
        this.setState({ loading: false });
    }

    /** 新增或修改菜单配置 */
    updateMenu = async () => {
        this.setState({ loading: true });
        const { 
            remarks,
        } = this.props;
        if (!remarks) {
            message.warn('请输入菜单代码');
            return;
        };
        this.props.dispatch(fetchMenuInform(remarks));

        await this.init();
    };

    render() {
        const { 
            remarks,
        } = this.props;
        let { loading } = this.state;
        return (
            <div className="add-modify">
                <div>
                    <span className="pageTitle">菜单配置</span>
                </div>
                <Spin spinning={loading}>
                    <div className="mt20">
                        <span className="remark">菜单代码：</span>
                        <br />
                        <TextArea 
                            value={remarks}
                            onChange={e=>this.props.dispatch(remarksHandler(e.target.value))}
                            style={{width: '80%', minHeight: 200}} 
                            autoSize="true"
                        />
                        <br />
                        <Button style={{marginLeft: '29%', marginTop: '10px'}} type="primary" onClick={this.updateMenu}>确定</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}
function mapStatetoProps(state) {

    const {
       ...previousState
    } = state.positionMenuInfo;

    const {
        appLicationArea,
        salles
    } = state.contractOrder;

    return {
        appLicationArea,
        salles,
        ...previousState
    };
}

export default connect(mapStatetoProps)(PositionMenu);