export const EDUCATION_LIST = [
  {value: 0, name: '无限制'},
  {value: 1, name: '大专及以上'},
  {value: 2, name: '本科及以上'},
  {value: 3, name: '硕士研究生及以上'},
  {value: 4, name: '仅限大专'},
  {value: 5, name: '大专或本科'},
  {value: 6, name: '仅限本科'},
  {value: 7, name: '本科或硕士研究生'},
  {value: 8, name: '仅限硕士研究生'},
  {value: 9, name: '仅限博士研究生'},
  {value: 10, name: '研究生'},
  {value: 11, name: '高中（中专）及以上'},
  {value: 12, name: '高中、中专毕业'},
  {value: 13, name: '专科以上'},
  {value: 14, name: '专科'},
]

export const WORK_YEAR_LIST = [
  {value: 0, name: '无要求'},
  {value: 1, name: '一年'},
  {value: 2, name: '两年'},
  {value: 3, name: '三年'},
  {value: 4, name: '四年'},
  {value: 5, name: '五年及以上'},
  {value: 6, name: '不限'}
]

export const EMPTY_INFO = {
  positionList: [],
  info: null,  //学员信息
  province: {
    page: 1,
    "city": "",
    "deptName": "",
    "education": '',
    "major": '',
    "politicalStatus": '',
    "positionName": '',
    "province": '',
    "type": '',
    "workedYear": '',
    "year": '',
    "deptCode": '',
    "positionCode": '',
    "district": ''
  }
}
