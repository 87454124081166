/**
 *  这是一个评论组件，用在申论图片点评上面
 *  只有学员上传了申论作业图片的时候才会调用这个组件进行显示
 */
import React from 'react';
import apiRequest from '../../common/request';
import { Button, Input, message, Progress, Image } from 'antd';
// import Gallery from '../AnswerCardShenlun/gallery';
import CanvasGallery from '../AnswerCardShenlun/galleryCanvas';
import './index.scss';
import { checkPermission } from '../../utils/utils';
import OSS from 'ali-oss';

const { TextArea } = Input;

export class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommentInput: false,                                        // 是否显示点评输入框
            commentInput: '',                                               // 输入的点评
            reviewImgUrls: [],                                              // 点评图片集合
            holdImgs: {},                                                   // 是否有修改标注
            progress: 0,                                                    // 上传图片的进度
            showProgress: false,                                            // 是否显示进度条
            isUploadFile: false,                                            // 表示是上传文件
            isSubmit: false,        // 提交点评状态
        };
    }

    /** 点击点评按钮显示编辑 */
    clickCommentButton = () => {
        this.setState({ showCommentInput: true });
    }

    /** 找出需要进行提交的批注图片，批注图片名字中一定是拥有feedback的 */
    findUploadImgs(imgs, modifyImgs) {
        let result = imgs.filter(n => n.indexOf('-feedback') !== -1);
        modifyImgs.map(imgUrl => {
            // 这里面每一项都是已经修改过的图片
            let unionName = imgUrl.split('-feedback')[0];
            let findResult = result.find(n => n.includes(unionName));
            if (findResult) {                           // 说明这个在之前就已经修改过了，那么删掉之前的，压入这次修改之后的
                let resultIdx = result.indexOf(findResult);
                result.splice(resultIdx, 1);            // 删除老的
                result.push(imgUrl);                    // 加入新的进去
            } else {                                    // 说明当前修改的图片之前不存在修改行为，那么直接加入进去
                result.push(imgUrl);
            }
        });
        return result;
    }

    randomNum = () => {
        let str1 = (Math.random() * 10 + '').replace('.', '');
        let str2 = (Math.random() * 10 + '').replace('.', '');
        return str1 + str2;
    }

    /** 提交点评 */
    /** LOG: 如果已经存在了批注图片，但是老师只修改了文字点评的话，那么批注图片也应该原封不动的传过去 */
    submitFeedbackRemark = async () => {
        let cacheName = `canvas${this.props.answerId}`;
        if ((!window[cacheName] || (window[cacheName] && Object.keys(window[cacheName]).length === 0)) && !this.state.commentInput) {
            message.warning('提交点评之前，至少要有文字点评数据或者图片点评数据中的一个');
            return;
        }
        let imgLength;
        if (window[cacheName]) {
            imgLength = Object.keys(window[cacheName]).length;
        }
        let i = 0;
        for (let key in window[cacheName]) {
            i += 1;
            let uimg = window[cacheName][key];
            let uploadImgInfo = message.info(`正在上传点评图片，共${imgLength}张，当前第${i}张`, 0);
            await this.uploadImg(uimg.fileName, uimg.file, uimg.cb, {all: imgLength, cur: i});
            uploadImgInfo();
        }
        let that = this;
        let data = {
            id: this.props.answerId,
            questionId: this.props.questionId,
            feedbackRemark: this.state.commentInput
        };
        let uploadedImg = this.findUploadImgs(this.concatFeedbackAndUserImg(this.props.userImgs, this.props.feedbackUrl), this.state.reviewImgUrls);
        if (uploadedImg && uploadedImg.length > 0) {
            uploadedImg = uploadedImg.map(imgUrl => {
                if (imgUrl.indexOf('?') === -1) {
                    imgUrl += `?${this.randomNum()}`
                }
                return imgUrl;
            });
            data.feedbackUrl = uploadedImg;
        }
        if (!data.feedbackRemark) delete data.feedbackRemark;
        // classType=1是申论，classType=5是综应
        let url = '/gk/ops/student/shenlunFeedback';
        if(this.props.classType==5) url = '/gk/ops/zongying/zy/Feedback';
        let config = {
            method: 'POST',
            headers: {
                'Acceppt': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            credentials: 'include'
        };
        let cb = ()=> {message.warn('点评失败'); this.setState({isSubmit: false, showProgress: false});};
        this.setState({isSubmit: true});
        apiRequest(url, config, cb).then(json => {
            json.status==1 && message.success('点评成功');
            that.setState({ showProgress: false });
            // 没有必要初始化状态了，因为页面reload了一下，state自然会初始化
            setTimeout(() => {
                that.props.update();
            }, 600);
        });
    }

    /** 处理点评输入 */
    handleCommentInput = (event) => {
        let value = event.target.value;
        if (value.length > 1024) {
            message.warning('点评不能超过1024个字');
            return;
        };
        this.setState({ commentInput: value });
    }

    /** 取消点评 */
    cancelEditRemark = () => {
        // 清空该试题缓存的涂鸦
        let cacheName = `canvas${this.props.answerId}`;
        if (window[cacheName]) {
            window[cacheName] = [];
        }
        this.setState({
            showCommentInput: false,
            commentInput: '',
            isUploadFile: false,
            holdImgs: {}
        });
    }

    /** 渲染右下角的按钮 */
    renderButtonRB = (role) => {
        if (!this.checkHasTheWriteRights()) return <div></div>
        return (
            <div className="no-container">
                <div className="flexgrow"></div>
                {
                    role == 'edit' ?
                    <div>
                        <Button onClick={this.cancelEditRemark} className="cancelButton">取消点评</Button>
                        <Button onClick={this.submitFeedbackRemark} loading={this.state.isSubmit} type="primary" className="mr9">提交点评</Button>
                        <Button onClick={this.setIsUploadFileTrue} type="primary">上传文件</Button>
                    </div>
                    :
                    <Button onClick={this.clickCommentButton} type="primary">点评</Button>
                }
            </div>
        );
    }

    /** 点击修改点评，隐藏评论查看 */
    clickUpdateComment = () => {
        this.setState({
            showCommentInput: true,
            commentInput: this.props.feedbackRemark
        });
    }
    
    showHoldImg = (imgObj) => {
        // let imgArr = [...this.state.reviewImgUrls];
        // let isExist = false;
        // imgArr = imgArr.map((img) => {
        //     if (typeof img === 'object' && img.fileName === imgObj.fileName) {
        //         isExist = true;
        //         return imgObj;
        //     } else if (typeof img === 'object') {
        //         return img;
        //     } else if (typeof img === 'string' && img.indexOf(imgObj.fileName) !== -1) {
        //         isExist = true;
        //         return imgObj;
        //     }
        // });
        // if (!isExist) {
        //     imgArr.push(imgObj);
        // }
        // this.setState({ reviewImgUrls: imgArr });
        let oldHoldImgs = Object.assign({}, this.state.holdImgs);
        oldHoldImgs[imgObj.fileName] = imgObj.compressedPng;
        this.setState({ holdImgs: oldHoldImgs });

    }
    /** 涂鸦组件用来上传图片的方法 */
    uploadImg = async (fileName, file, cb, indexObj) => {
        let that = this;
        let tokenJson = await apiRequest('/gk/ops/sts/getToken?type=oss', { method: 'GET', credentials: 'include' });
        if (tokenJson.status != 1) return;
        let result = tokenJson.obj;
        let client = new OSS({
            accessKeyId: result.accessKeyId,
            accessKeySecret: result.accessKeySecret,
            stsToken: result.securityToken,
            endpoint: 'https://oss-cn-beijing.aliyuncs.com',
            bucket: 'gk-homework'
        });

        this.setState({
            showProgress: true,
            progress: Math.floor((indexObj.cur - 1) / indexObj.all * 100)
        });
        let putResponse = await client.multipartUpload(fileName, file, {
            progress: function(p) {
                return function(done) {
                    p = p * 100;
                    if (p >= 100) {
                        that.setState({ progress: Math.ceil(indexObj.cur / indexObj.all * 100) });
                        setTimeout(() => {
                            that.setState({ showProgress: false });
                        }, 400);
                        cb && cb();
                    }
                    done();
                };
            },
        });


        let requestUrl = (putResponse && putResponse.res && putResponse.res.requestUrls && putResponse.res.requestUrls[0]) || '';
        let imgType = '.png';
        let jpgType = '.jpg';
        if (!requestUrl.endsWith(imgType) && requestUrl.indexOf(imgType) !== -1) {                          // 写死了是png格式
            let typeIndex = requestUrl.indexOf(imgType);
            requestUrl = requestUrl.slice(0, typeIndex + imgType.length);
            requestUrl = requestUrl.replace('http:', 'https:');
        } else if (!requestUrl.endsWith(jpgType) && requestUrl.indexOf(jpgType) !== -1) {
            let typeIndex = requestUrl.indexOf(jpgType);
            requestUrl = requestUrl.slice(0, typeIndex + jpgType.length);
            requestUrl = requestUrl.replace('http:', 'https:');
        }
        // 由于能够传多张图片，所以这里将批注图片作为一个数组进行处理，同时需要避免多传，需要知道的是，同一张多次图片多次编辑上传OSS会被覆盖的
        // 不仅要检查reviewImgUrls，而且还要检查服务器中所返回的feedbackUrl中是否有相关数据
        let imgArr = [...this.state.reviewImgUrls].filter((url) => {return Object.prototype.toString.call(url) !== '[object object]'});
        let feedbackUrlOriginal = [...this.props.feedbackUrl].map((url) => {
            return url.split('?')[0];
        });

        if (imgArr.indexOf(requestUrl) == -1 && feedbackUrlOriginal.indexOf(requestUrl) == -1) { // 不存在的话就加入进去
            imgArr.push(requestUrl);
        }

        this.setState({ reviewImgUrls: imgArr });
    }

    /** CanvasGallery组件所需要进行展示出的图片 */
    concatFeedbackAndUserImg(userImgs, feedBack) {
        let result = [ ...userImgs ];
        if (!feedBack || !feedBack.length) return result;

        // 遍历谁呢，应该遍历作出修改之后的图片feedBack更好，为什么呢？因为批注图片不可能会比学员所提交的答题图片数量更多
        feedBack.map(url => {
            // 找出修改的图片是学员上传的哪一张，找到就替换掉
            let unionName = url.split('-feedback')[0];
            unionName = unionName.split('com/')[1];
            
            // 第一点，一定是根据用户所上传图片为准的
            let findResult = result.find(n => n.includes(unionName));
            if (findResult) {                     // 说明这个批注之后的图片是在用户原来的图片里面的，那么进行删除，并且进行替换
                let findIdx = result.indexOf(findResult);
                result.splice(findIdx, 1, url);
            } else {                              // 批注图片和用户所上传图片已经是没有任何关联了，那么此时什么都不做

            }
        });
        return result;
    }

    /** 点击上传文件 */
    setIsUploadFileTrue = () => {
        this.setState({ isUploadFile: true });
    }

    /** 上传文件完毕后，隐藏文件选择状态 */
    setIsUploadFileFalse = () => {
        this.setState({ isUploadFile: false });
    }

    /** 判断是否具有写的权限, classType=1是申论, classType=5是综应 */
    checkHasTheWriteRights = () => {
        if(this.props.classType==1) return checkPermission('student:shenlun:feedback');
        if(this.props.classType==5) return checkPermission('student:zy:feedback');
        return false;
    }

    // 点评或者修改点评的话应该显示图片列表
    render() {
        let editShowImgs = this.concatFeedbackAndUserImg(this.props.userImgs, this.props.feedbackUrl);

        if (this.state.showCommentInput) {                                  // 点评编辑区域
            return (
                <div className="editCommentContainer">
                    <TextArea defaultValue="请输入文字点评" value={this.state.commentInput} onChange={this.handleCommentInput} className="marginBottomT xzTextarea" rows={5} />

                    <CanvasGallery
                        answerId={this.props.answerId}
                        imgs={editShowImgs}
                        upload={this.uploadImg}
                        showHoldImg={this.showHoldImg}
                        modifyImgs={this.state.reviewImgUrls}
                        holdImgs={this.state.holdImgs}
                        isUploadFile={this.state.isUploadFile}
                    />

                    { this.renderButtonRB('edit') }

                    {
                        this.state.showProgress && (
                        <div className="progressContainer">
                            <Progress strokeWidth="10" showInfo="{true}" type="circle" percent={this.state.progress} />
                        </div>
                        )
                    }
                </div>
            );
        }

        // 点评查看区域
        return (
            <div className="comment-container">
                {
                    // 如果存在文字点评或者 图片点评的话 那么就显示这个点评数据；如果一个都不存在才是显示点评按钮
                    (this.props.feedbackRemark || (this.props.feedbackUrl && this.props.feedbackUrl.length > 0)) ?
                    <div className="feedback-container">
                        <h5 className="marginBottomT weight600 font15">文字点评内容:</h5>
                        <p className="color333 font13 colorRed">{this.props.feedbackRemark || '暂无文字点评'}</p>

                        <div className="divider"></div>

                        <h5 className="marginBottomT weight600 font15">图片点评内容:</h5>
                        {
                            (this.props.feedbackUrl && this.props.feedbackUrl.length > 0) ?
                            <Image.PreviewGroup>
                                {this.props.feedbackUrl.map((item, idx) => (
                                    <Image
                                        key={idx}
                                        style={{width: 150, height:150}}
                                        src={item}
                                    />
                                ))}
                            </Image.PreviewGroup>
                            :
                            <p className="color333 font13">暂无图片点评内容</p>
                        }
                        
                        {
                            this.checkHasTheWriteRights() &&
                            <div className="rightBottomButton">
                                <Button type="primary" onClick={this.clickUpdateComment}>修改点评</Button>
                            </div>
                        }
                    </div>
                    :
                    this.renderButtonRB()
                }

            </div>
        );
    }
}

// Comment.propTypes = {
//     answerId: PropTypes.number,                         // 学员答案的id
//     feedbackRemark: PropTypes.string,                   // 老师对学员的文字点评
//     feedbackUrl: PropTypes.array,                       // 老师对学员的图片点评
//     updateFeedbackRemark: PropTypes.func,               // 更新老师对学员的点评
//     userImgs: PropTypes.array,                          // 学员自己上传的图片集合
//     update: PropTypes.func,                             // 更新StudentDetail组件
// };

Comment.defaultProps = {
    feedbackRemark: '',
    answerId: '',
    feedbackUrl: [],
    userImgs: []
};

export default Comment;
