import React from "react";
import { Button, Input, Select, Radio, InputNumber, Form, message } from "antd";

const Option = Select.Option;
class IStudentForm extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      year: '',
      area: '',
      flag: true
    }
  }

  componentDidMount() {
    if (this.props.info) {
      let { year, area } = this.props;
      let { classId, mobile, mobile2, payStatus, point, rank, remark, sex, studentName, discountAmount } = this.props.info;
      this.formRef.current.setFieldsValue({ year, area, classId, mobile, mobile2, payStatus: payStatus + '', point, rank, remark, sex: sex + '', discountAmount, studentName });
      this.setState({ area: this.props.area, year: this.props.year });
      this.props.areaHandler(null, this.props.area);
    } else {
      this.formRef.current.setFieldsValue({ sex: '1', payStatus: '1' })
    }
  }

  /** 地区改变时的联动变化 */
  areaHandler = async (key, val) => {
    await this.setState({
      [key]: val
    })
    await this.formRef.current.setFieldsValue({ classId: '' })
    await this.props.areaHandler(this.state.year, this.state.area)
  }

  /** 点击修改以及新增的时候调用 */
  handleSubmit = (values) => {
    if (values.remark != '' && values.remark != undefined && !values.remark.trim().length) return message.error('备注不能全部空格');
    this.props.saveOrUpdateStudent(values)
  };

  validateMobile = (rule, value, callback) => {
    if (value && value.length > 0 && value.length != 11) {
      callback('请输入正确的手机号');
    } else {
      callback();
    }
  };

  validateMobile2 = (rule, value, callback) => {
    let mobile = this.formRef.current.getFieldValue('mobile');
    if (value && value.length > 0 && value.length != 11) {
      callback('请输入正确的手机号');
    } else if ((value != undefined) && value === mobile) {      
      callback('不能和手机号相同');
    } else {
      callback();
    }
  };

  handleValue = (value) => {
    if (this.props.info && this.props.info.payStatus != value) {
      this.setState({
        flag: false
      })
    } else {
      this.setState({
        flag: true
      })
    }
  }

  render() {
    let { classList, permission, info, years, areas } = this.props;

    return (
      <Form
        ref={this.formRef}
        layout="inline"
        onFinish={this.handleSubmit}
        className="stuInfoForm"
      >
        <Form.Item
          label="学员姓名"
          name="studentName"
          rules={[{ required: true, message: '请输入学生姓名!' }]}
        >
          <Input style={{ width: 145 }} />
        </Form.Item>
        <Form.Item
          label="学员性别"
          name="sex"
          rules={[{ required: true, message: '请选择性别!' }]}
        >
          <Radio.Group style={{ width: 145 }}>
            <Radio value="1">男</Radio>
            <Radio value="0">女</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="手机号码"
          name="mobile"
          rules={[{ required: true, message: '请输入学员联系方式!' }, { validator: this.validateMobile }]}
        >
          <Input style={{ width: 145 }} />
        </Form.Item>
        <Form.Item
          label="紧急联系"
          name="mobile2"
          rules={[{ required: true, message: '请输入紧急联系方式!' }, { validator: this.validateMobile2 }]}
        >
          <Input style={{ width: 145 }} />
        </Form.Item>
        <Form.Item
          label="缴费状态"
          name="payStatus"
          rules={[{ required: true, message: '请选择缴费状态!' }]}
        >
          <Select style={{ width: 145 }} disabled={!permission} onChange={(value) => { this.handleValue(value) }}>
            <Option value='1' key='1'>未缴费</Option>
            <Option value='2' key='2'>部分缴费</Option>
            <Option value='3' key='3'>全部缴费</Option>
            <Option value='4' key='4'>已退费</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="优惠金额"
          name="discountAmount"
          rules={[{ required: true, message: '请输入优惠金额!' }]}
        >
          <InputNumber placeholder="优惠金额(元)" style={{ width: 145 }} min={0} />
        </Form.Item>
        <Form.Item
          label="* 选择年份"
          name="year"
        >
          <Select
            showSearch
            placeholder="请选择年份"
            style={{ width: 145 }}
            onChange={value => this.areaHandler('year', value)}
          >
            <Option value="">全部</Option>
            {years.map((item, idx) => {
              return <Option key={idx} value={item}>{item}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="* 选择地区"
          name="area"
        >
          <Select
            showSearch
            placeholder="请选择地区"
            style={{ width: 145 }}
            onChange={value => this.areaHandler('area', value)}
          >
            <Option value="">全部</Option>
            {areas.map((item, idx) => {
              return <Option key={idx} value={item}>{item}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="* 笔试得分"
          name="point"
        >
          <InputNumber style={{ width: 145 }} min={0} precision={2} />
        </Form.Item>
        <Form.Item
          label="* 笔试排名"
          name="rank"
        >
          <InputNumber style={{ width: 145 }} min={0} precision={0} />
        </Form.Item>
        <Form.Item
          label="班级选择"
          name="classId"
          rules={[{ required: true, message: '请选择班级信息!' }]}
        >
          <Select
            showSearch
            style={{ width: 400 }}
            placeholder="请选择班级"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {classList.map((item, idx) => {
              return <Option key={idx} value={item.id}>{item.className}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
          style={{ width: '100%' }}
        >
          <Input.TextArea rows="4" />
        </Form.Item>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p style={{ color: 'red' }}>*请再三确认学员 手机号码、缴费状态 是否正确</p>
          {permission &&
            <Button disabled={this.props.isUpdating} type="primary" htmlType="submit">
              {info ? '修改' : '新增'}
            </Button>}
        </div>
      </Form>
    )
  }
}

export default IStudentForm
