import { message } from 'antd';
import { getAvailableVideoUrl } from '../../components/VideoPicker/action';
import apiRequest from '../../common/request'
import OSS from 'ali-oss';

const PREFIX = "QUESTION_INFO";

export const REQUEST_UPDATE_QUESTION = "REQUEST_UPDATE_QUESTION";
export const REQUEST_UPDATE_QUESTION_VIDEO = "REQUEST_UPDATE_QUESTION_VIDEO";
export const REQUEST_QUESTION_INFO = "REQUEST_QUESTION_INFO";
export const RECEIVE_QUESTION_INFO = PREFIX + "RECEIVE_QUESTION_INFO";
export const RECEIVE_UPDATE_QUESTION_RESULT = "RECEIVE_UPDATE_QUESTION_RESULT";
export const RECEIVE_UPDATE_QUESTION_VIDEO_RESULT = "RECEIVE_UPDATE_QUESTION_VIDEO_RESULT";
export const UPDATE_QUESTION_TITLE = "UPDATE_QUESTION_TITLE";
export const UPDATE_QUESTION_POINT = "UPDATE_QUESTION_POINT";
export const UPDATE_QUESTION_SOURCE = "UPDATE_QUESTION_SOURCE";
export const UPDATE_QUESTION_KNOWLEDGE = "UPDATE_QUESTION_KNOWLEDGE";
export const UPDATE_QUESTION_TYPE = "UPDATE_QUESTION_TYPE";
export const UPDATE_QUESTION_ANSWER_DESC = "UPDATE_QUESTION_ANSWER_DESC";
export const UPDATE_QUESTION_ANALYSIS = "UPDATE_QUESTION_ANALYSIS";
export const UPDATE_SINGLE_QUESTION_ANSWER = "UPDATE_SINGLE_QUESTION_ANSWER";
export const UPDATE_QUESTION_SELECTION = "UPDATE_QUESTION_SELECTION";
export const ADD_UPDATE_QUESTION_OPTION = "ADD_UPDATE_QUESTION_OPTION";
export const REMOVE_UPDATE_QUESTION_OPTION = "REMOVE_UPDATE_QUESTION_OPTION";
export const UPDATE_QUESTION_CATEGORY = "UPDATE_QUESTION_CATEGORY";
export const UPDATE_MULTIPLE_QUESTION_ANSWER = "UPDATE_MULTIPLE_QUESTION_ANSWER";
export const UPDATE_QUESTION_MATERIALS_TYPE = "UPDATE_QUESTION_MATERIALS_TYPE";
export const QUESTION_RECEIVE_MATERIAL_INFO = "QUESTION_RECEIVE_MATERIAL_INFO";
export const QUESTION_REQUEST_MATERIAL_INFO = "QUESTION_REQUEST_MATERIAL_INFO";
export const UPDATE_VIDEO_PROGRESS = "UPDATE_VIDEO_PROGRESS";
export const UPDATE_URL = "UPDATE_URL";
export const UPDATE_QUESTION_TAG_TYPE = "QUESTION_INFO_UPDATE_QUESTION_TAG_TYPE";
export const UPDATE_SIGNED_VIDEO_URL = "QUESTION_INFO_UPDATE_SIGNED_VIDEO_URL";
export const UPDATE_PROVINCE_TYPE="UPDATE_PROVINCE_TYPE";
export const UPDATE_YEARS_TYPE="UPDATE_YEARS_TYPE";

export function update_signedVideoUrl(value) {
    return {
        type: UPDATE_SIGNED_VIDEO_URL,
        key: 'signedVideoUrl',
        value
    };
}

export function request_question_info() {
    return {
        type: REQUEST_QUESTION_INFO
    }
}

export function receive_question_info(question) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question
    }
}

function request_update_question() {
    return {
        type: REQUEST_UPDATE_QUESTION
    }
}

function request_update_question_video() {
    return {
        type: REQUEST_UPDATE_QUESTION_VIDEO
    }
}

function receive_update_question_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_RESULT
    }
}

function receive_update_question_video_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_VIDEO_RESULT
    }
}


export function remove_option() {
    return {type: REMOVE_UPDATE_QUESTION_OPTION}
}

export function add_option() {
    return {type: ADD_UPDATE_QUESTION_OPTION}
}

export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function update_question_selection(index, key, value) {
    return {
        type: UPDATE_QUESTION_SELECTION,
        index,
        key,
        value
    }
}

export function update_question_point(value) {
    return {
        type: UPDATE_QUESTION_POINT,
        key: 'point',
        value
    }
}

export function update_question_source(value) {
    return {
        type: UPDATE_QUESTION_SOURCE,
        key: 'source',
        value
    }
}

export function update_question_knowledge(value) {
    return {
        type: UPDATE_QUESTION_KNOWLEDGE,
        key: 'knowledge',
        value
    }
}

export function update_single_question_answer(value) {
    return {
        type: UPDATE_SINGLE_QUESTION_ANSWER,
        key: 'answer',
        value
    }
}

export function update_multiple_question_answer(value) {
    return {
        type: UPDATE_MULTIPLE_QUESTION_ANSWER,
        key: 'answer',
        value
    }
}

export function update_question_title(value) {
    return {
        type: UPDATE_QUESTION_TITLE,
        key: 'content',
        value
    }
}

export function update_url(value) {
    return {
        type: UPDATE_URL,
        key: 'videoUrl',
        value
    }
}

// LOG: 2019-01-25修改1 之前会对value==2返回另一个action type;但是这个action type所对应的操作又没有任何区别，所以去掉
// LOG: 2019-01-25修改2 解注释；之前这里被注释，导致修改题型报错；
export function update_question_type(value) {
    return {
        type: UPDATE_QUESTION_TYPE,
        key: 'quesType',
        value
    }
}

export function update_question_answer_desc(value) {
    return {
        type: UPDATE_QUESTION_ANSWER_DESC,
        key: 'quesExplain',
        value
    }
}
export function update_question_analysis(value) {
    return {
        type: UPDATE_QUESTION_ANALYSIS,
        key: 'issue',
        value
    }
}

export function update_category(value) {
    return {
        type: UPDATE_QUESTION_CATEGORY,
        key: 'categoryId',
        value
    }
}

export function update_question_materials_type(value) {
    return {
        type: UPDATE_QUESTION_MATERIALS_TYPE,
        key: 'materialsType',
        value
    }
}

export function update_province_type(value) {
    return {
        type: UPDATE_PROVINCE_TYPE,
        key: 'area',
        value
    }
}

export function update_years_type(value) {
    return {
        type: UPDATE_YEARS_TYPE,
        key: 'year',
        value
    }
}

export function fetchEmpty(value) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question:{
          quesType: 0,
          content: "",
          answer: [],
          quesExplain: "",
          categoryId: "-1",
          options: [{"key": "", "value": ""}, {"key": "", "value": ""}, {"key": "", "value": ""}, {"key": "", "value": ""}],
          // point: "",
          // knowledge: "",
          source: "",
          questionTag: 2, // 默认原创
          area: [],
          year: []
        }
    }
}

/** 更新试题的tag类型 */
export function update_question_tag_type (value) {
    return {
        type: UPDATE_QUESTION_TAG_TYPE,
        key: 'questionTag',
        value
    };
}

export function fetchQuestion(questionId) {

    return dispatch => {
        dispatch(request_question_info());
        return apiRequest(`/gk/ops/question/details/${questionId}`, {credentials: 'include'})
          .then(json => {              
              dispatch(receive_question_info(json.obj));
          })
          .catch(error => console.log(error));
    }

}

export function updateQuestionVideo(file, info) {
    let categoryTree = (info && info.categoryTree) || [];
    if (Object.prototype.toString.call(categoryTree) != '[object Array]') {
        message.warning('所选题目不存在分类信息');
        return;
    }
    let fileName = `/question`;
    categoryTree.map(item => {
        fileName += `/${item.name}`;
    });
    if (info.id) {
        fileName += `/${info.id}-${file.name}`;
    } else {
        fileName += `/${file.name}`;
    }
    return async dispatch => {
        dispatch(request_update_question_video());
        let tokenJson = await apiRequest("/gk/ops/sts/getToken?type=oss",{method: 'GET',credentials: 'include'});
        if (tokenJson.status !== 1) {
            message.warning('获取OSS token时发生错误');
            return;
        }
        let tokenObj = tokenJson.obj;
        let client = new OSS({
            accessKeyId: tokenObj.accessKeyId,
            accessKeySecret: tokenObj.accessKeySecret,
            stsToken: tokenObj.securityToken,
            endpoint: 'https://oss-cn-beijing.aliyuncs.com',
            bucket: 'gk-course'
        });
        client.multipartUpload(fileName, file, {
            progress: function (p) {
              return function (done) {
                dispatch(update_video_progress(p * 100 + "%"));
                done();
              };
            },
        }).then(async (result) => {
            dispatch(receive_update_question_video_result());
            let videoUrl = result.name;
            if(videoUrl && videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
            let canPlayUrl = await getAvailableVideoUrl(videoUrl);
            dispatch(update_signedVideoUrl(canPlayUrl));
            dispatch(update_url(result.name));
        }).catch(function (err) {
            dispatch(receive_update_question_video_result());
            message.warning('上传文件到OSS发生错误');
        });
    }
}

export function updateQuestion(questionInfo, showAY) {
    let data = {};
    data.answer = questionInfo.answer.join(',');
    data.categoryId = questionInfo.categoryId;
    data.content = questionInfo.content;
    data.id = questionInfo.id;
    data.options = questionInfo.options;
    if (questionInfo.videoUrl) {
        let videoUrl = questionInfo.videoUrl;
        if(videoUrl && videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
        data.videoUrl = videoUrl;
    }
    // data.point = questionInfo.point;
    // if (data.knowledge) data.knowledge = questionInfo.knowledge;
    if(questionInfo.source) data.source = questionInfo.source;
    data.quesExplain = questionInfo.quesExplain;
    data.quesType = questionInfo.quesType;
    data.materialsType = questionInfo.materialsType;
    data.questionTag = questionInfo.questionTag;
    questionInfo.issue&&(data.issue=questionInfo.issue)
    //地区，年份
    if (showAY) {                                 // 年份以及地区显示了才有必要输入
      if (questionInfo.area && questionInfo.area.length) { data.area = questionInfo.area; }
      if (questionInfo.year && questionInfo.year.length) { data.year = questionInfo.year; }
    }

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_question());
        return apiRequest("/gk/ops/question/saveOrUpdate", config)
          .then(json => {
            dispatch(receive_update_question_result());
            message.success('操作成功');
            window.history.back();
          })
          .catch(error => {
            dispatch(receive_update_question_result());
            console.log(error)
          });
    }
}

function receive_material_info(info) {
    return {
        type: QUESTION_RECEIVE_MATERIAL_INFO,
        info
    }
}

function request_material_info() {
    return {
        type:QUESTION_REQUEST_MATERIAL_INFO
    }
}

export function fetchMaterialQuestion(id) {
    if (id)
        return dispatch => {
            dispatch(request_material_info());
            return apiRequest(`/gk/ops/materials/details/${id}`, {credentials: 'include'})
              .then(json => {
                  dispatch(receive_material_info(json.obj))
              })
              .catch(error => console.log(error));
        }

}
