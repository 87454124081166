import React from 'react';
import { connect } from 'react-redux';
import * as echarts from 'echarts/lib/echarts';
import { Spin, Select, Checkbox, Pagination, Table, Button, Collapse } from "antd";
import AnswerCardUnit from '../../components/AnswerCardUnit';
import './index.scss';
import {
    getStudentClass,
    changeCommon,
    fetchExcel,
    fetchRankData,
    fetchDetailExerciseResult,
    resetArrData
} from './actions';
import { getSearchQuery, checkPermission } from '../../utils/utils';

const { Panel } = Collapse;
const { Option } = Select;

const exerciseRankStyle = {
    buttonBottom: {
        marginBottom: 10,
        marginRight: 15
    },
    chartDiv: {
        width: '100%',
        height: 350
    },
    clickSpan: {
        cursor: "pointer",
        color: "rgb(0,0,204)"
    },
    mtb10: {
        marginTop: '3px',
        marginBottom: '10px',
        fontSize: '14px'
    },
    redSpan: {
        color: 'red',
        marginRight: '5px',
        fontWeight: '500'
    }
};

class ExerciseRank extends React.Component {

    componentDidMount() {
        let newId = getSearchQuery('id');
        let classIds = getSearchQuery('classId');
        let data = {
            page: this.props.page,
            pageSize: this.props.pageSize,
            examineId: newId,//班级练习id
        }
        this.props.fetchRankData(data);  // 加载学员排名数据
        this.props.getStudentClass(newId);
        false && this.geteEcharts(newId, classIds); // 暂时不需要展示统计图
    }

    /** 获取统计图 */
    geteEcharts = (newId, classIds) => {
        var correct = echarts.init(document.getElementById('correctData'));
        const wrapFunc = (data) => {
            correct.setOption({
                xAxis: { data: data.xArr },
                series: [
                    { name: '正确率', data: data.correct_Yarr },
                    { name: '平均用时', data: data.time_Yarr, yAxisIndex: 1 }
                ]
            });
        }
        this.props.fetchDetailExerciseResult(newId, classIds, wrapFunc);                  // 加载每道题目的详情数据
        this.echartInit(correct);
    }

    // 参数是echart instance，在还没有加载到数据的时候，echarts所保持的样式
    echartInit = correct => {
        correct.setOption({
            title: {
                text: '班级测试每道试题平均正确率'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            xAxis: {
                type: 'category',
                data: [],
                axisPointer: { type: 'shadow' }
            },
            dataZoom: [
                {
                    type: 'slider',                                                 // 使用底部工具左右拖动进行调整放大
                    start: 0, end: 100
                }
            ],
            // 显示两个y轴，左边一个，右边一个
            yAxis: [
                {
                    type: 'value',
                    name: '正确率',
                    interval: 50,
                    axisLabel: {
                        formatter: '{value} %'
                    }
                },
                {
                    type: 'value',
                    name: '平均用时',
                    axisLabel: {
                        formatter: '{value} s'
                    }
                }
            ],
            series: [
                {
                    name: '正确率',
                    yAxisIndex: 0,
                    type: 'bar',
                    data: [],
                    label: { show: true, position: 'inside' },
                    itemStyle: {
                        /** 自定义柱状图的颜色 */
                        color: function (params) {
                            if (params.data >= 0 && params.data <= 40) {
                                return '#fc5b5d';
                            } else if (params.data > 40 && params.data <= 75) {
                                return '#fcbf03';
                            } else {
                                return '#42abfc';
                            }
                        }
                    }
                },
                {
                    name: '平均用时',
                    yAxisIndex: 1,
                    type: 'line',                                         // 平均用时不显示颜色，在这里的目的就是为了展示label而且是Inside
                    label: { show: true, position: 'top' },
                    data: []
                }
            ],
            /** 顶部工具栏的设置 */
            toolbox: {
                feature: {
                    saveAsImage: { pixelRatio: 3 },                         // 提高导出的图片的分辨率 
                    dataView: {},                                         // 数据视图工具，展现当前图标所使用的数据（非数据可视化形式）
                    magicType: { type: ['line', 'bar'] },
                    restore: { show: true }
                }
            },
            legend: {
                data: ['正确率', '平均用时']
            }
        });
    }

    componentWillUnmount() {
        this.props.resetArrData();
    }

    handleFetchExcel = () => {
        let id = getSearchQuery('id');
        let data = {
            page: this.props.page || 1,
            pageSize: this.props.pageSize || 10,
            examineId: id
        }
        this.props.fetchExcel(data, 0);
    }

    handleFetchDetailExcel = () => {
        let id = getSearchQuery('id');
        let data = {
            id: id,
            classIds: this.props.checkedList
        }
        this.props.fetchExcel(data, 1);
    }

    /** 点击学员名字到达学员详情数据页面，需要传递的参数有班级number和学员id */
    goStudentDetail = record => {
        this.saveLocal();
        let classId = record.classId;
        let userId = record.userId;
        let className = record.className;
        this.props.history.push(`/exerciseStudentDetail/${userId}/${classId}?className=${className}`);
    }
    /** 将学员列表数据保存到localStorage */
    saveLocal = () => {
        let arr = this.props.datas.map(item => item.datas);
        arr = JSON.stringify(arr);
        try { window.localStorage.setItem('studentList', arr); }
        catch (err) { }
    }

    /** antd table 表格配置数据 */
    generateColumns = () => {
        let columns = [
            {
                title: '排名',
                dataIndex: 'rank',
                width: 100,
            },
            {
                title: '用户名',
                dataIndex: 'userName',
                width: 100,
                render: (text, record) => {
                    return (
                        <span onClick={() => { this.goStudentDetail(record) }} style={exerciseRankStyle.clickSpan}>{text}</span>
                    )
                }
            },
            {
                title: '做题时间',
                dataIndex: 'times',
                render: text => {
                    let sec = this.formatSeconds(text);
                    return <span>{sec}</span>
                },
                width: 100,
            },
            {
                title: '分数',
                dataIndex: 'point',
                width: 100,
            },
            {
                title: '正确率',
                dataIndex: 'accuracy',
                width: 100,
            }
        ];
        return columns;
    }

    /** 把s转化为时分秒 */
    formatSeconds = (value) => {
        if (value == null) return '';
        let theTime = parseInt(value);              // 秒
        let middle = 0;                             // 分
        let hour = 0;                               // 小时
        if (theTime > 60) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if (middle > 60) {
                hour = parseInt(middle / 60);
                middle = parseInt(middle % 60);
            }
        }
        let result = "" + parseInt(theTime) + "秒";
        if (middle > 0) {
            result = "" + parseInt(middle) + "分" + result;
        }
        if (hour > 0) {
            result = "" + parseInt(hour) + "小时" + result;
        }
        return result;
    }

    /** 生成表头方法 */
    renderHeader = name => {
        return <div>{name}</div>
    }

    /** 改变页数 */
    onChangePage = (page, pageSize) => {
        let data = {
            classIds: this.props.classIds,
            examineId: getSearchQuery('id'),//班级练习id
            page: page,
            pageSize: pageSize
        }
        this.props.fetchRankData(data);
        this.props.changeCommon('page', page);                                     // 加载学员排名数据
    }

    /** 改变页大小 */
    onShowSizeChange = (current, size) => {
        let data = {
            classIds: this.props.classIdsList,
            examineId: getSearchQuery('id'),//班级练习id
            page: 1,
            pageSize: size
        }
        this.props.fetchRankData(data);
        this.props.changeCommon('pageSize', size);                                    // 加载学员排名数据
    }

    /** 全选与反选 */
    onCheckAllChange = async e => {
        let newId = getSearchQuery('id');
        let classArrList = this.props.classArrList;
        let correct = echarts.init(document.getElementById('correctData'));
        let arr = [];
        classArrList.length && classArrList.map(item => arr.push(item.value));
        e.target.checked ? await this.props.changeCommon('checkedList', arr) : await this.props.changeCommon('checkedList', []);
        this.props.changeCommon('indeterminate', false)
        this.props.changeCommon('checked', e.target.checked)
        e.target.checked && await this.geteEcharts(newId, this.props.checkedList) || this.echartInit(correct);
    };

    /** 勾选班级 */
    handleChange = async val => {
        let newId = getSearchQuery('id');
        let correct = echarts.init(document.getElementById('correctData'));
        await this.props.changeCommon('checkedList', val);
        await this.props.changeCommon('indeterminate', !!val.length && val.length < this.props.classArrList.length);
        await this.props.changeCommon('checked', val.length == this.props.classArrList.length);
        await val.length && this.geteEcharts(newId, val) || this.echartInit(correct);
    }

    reset = () => {
        let data = {
            examineId: getSearchQuery('id'),//班级练习id
            page: 1,
            pageSize: this.props.pageSize
        }
        this.props.fetchRankData(data);
        this.props.changeCommon('page', 1);
        this.props.changeCommon('classIdsList', []);

    }

    onClassChange = (value) => {
        let data = {
            classIds: value,
            examineId: getSearchQuery('id'),//班级练习id
            page: 1,
            pageSize: this.props.pageSize
        }
        this.props.fetchRankData(data);
        this.props.changeCommon('page', 1);
        this.props.changeCommon('classIdsList', value);
    }

    render() {
        const columns = this.generateColumns();
        const { classIdsList, checkedList, checked, indeterminate, classArrList } = this.props;
        classArrList.length && classArrList.map(item => { item.label = item.className; item.value = item.id });

        if (this.props.onFetch) {
            return <Spin />
        } else {
            return (
                <div className="exerciseRankContainer">
                    <div style={{ position: 'sticky', top: '0', background: '#f0f0f0', zIndex: '10', paddingBottom: '10px', borderBottom: '1px solid #999' }}>
                        <span style={{ marginRight: '10px', fontSize: '16px', verticalAlign: 'middle', color: 'blue' }}>{getSearchQuery('title') || ''}</span>
                        {checkPermission('clazz:rank:export') && <Button type="primary" onClick={this.handleFetchExcel} style={exerciseRankStyle.buttonBottom}>导出排名数据</Button>}
                        {checkPermission('clazz:average:export') && <Button disabled={!checkedList.length} type="primary" onClick={this.handleFetchDetailExcel} style={exerciseRankStyle.buttonBottom}>导出班级测试数据</Button>}
                        <span style={exerciseRankStyle.redSpan}>请先在最下方勾选班级才能导出相应班级测试数据</span>
                        <div style={exerciseRankStyle.mtb10}>
                            <label>班级</label>
                            <Select
                                mode="multiple"
                                optionFilterProp="children"
                                value={classIdsList}
                                style={{ width: 580, margin: '0 10px' }}
                                onChange={value => { this.onClassChange(value) }}
                            >
                                {classArrList.length && classArrList.map((item, idx) => {
                                    return <Option key={idx} value={item.id}>{item.className}</Option>
                                })}
                            </Select>
                            <Button onClick={() => this.reset()}>清空班级筛选</Button>
                        </div>
                    </div>
                    {
                        this.props.datas.length && this.props.datas.map((dataObj, idx) => (
                            <Collapse key={idx}>
                                <Panel header={dataObj.className} key={dataObj.className}>
                                    <Table
                                        bordered
                                        style={{ backgroundColor: '#fff', marginBottom: 30 }}
                                        columns={columns}
                                        pagination={false}
                                        size="small"
                                        dataSource={dataObj.datas}
                                        rowKey={record => record.userId}
                                        title={() => this.renderHeader(dataObj.className)}
                                    />
                                    {
                                        dataObj.details && dataObj.details.length &&
                                        <Collapse>
                                            <Panel header={dataObj.className + ' 正确率统计'} key={dataObj.className}>
                                                {
                                                    dataObj.details.map((questionObj, answerIndex) => (
                                                        <AnswerCardUnit
                                                            key={answerIndex}
                                                            exerciseType = {classArrList[0]&&classArrList[0].type}
                                                            questionId={questionObj.id}
                                                            questionObj={questionObj}
                                                            questionIndex={answerIndex + 1}
                                                            isFromUser={false}
                                                        />
                                                    ))
                                                }
                                            </Panel>
                                        </Collapse>
                                    }
                                </Panel>
                            </Collapse>
                        )) || '暂无数据'
                    }

                    <Pagination
                        total={this.props.total}
                        onChange={this.onChangePage}
                        pageSizeOptions={[10, 20, 50, 100]}
                        showSizeChanger
                        showQuickJumper
                        onShowSizeChange={this.onShowSizeChange}
                        showTotal={total => `共 ${total} 个班级`}
                        current={this.props.page}
                        pageSize={this.props.pageSize}
                    />

                    {classArrList.length &&
                        <div className="checkClass">
                            <span><span style={exerciseRankStyle.redSpan}>*使用提示:</span>表格正确率来自用户第一次做题，折线图正确率来自用户最后一次做题</span>
                            <div style={{ color: 'red', marginBottom: '10px' }}>*您可以勾选对应的班级查看对应的统计数据</div>
                            <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checked}>全选</Checkbox>
                            <br/>
                            <Checkbox.Group className="checkOpt" options={classArrList} value={checkedList} onChange={this.handleChange} />
                        </div> || '加载中...'
                    }

                    <div style={exerciseRankStyle.chartDiv} ref="correctData" id="correctData"></div>

                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    classIdsList: state.exerciseRank.classIdsList,
    classArrList: state.exerciseRank.classArrList,
    indeterminate: state.exerciseRank.indeterminate,
    checked: state.exerciseRank.checked,
    checkedList: state.exerciseRank.checkedList,
    total: state.exerciseRank.total,
    page: state.exerciseRank.page,
    pageSize: state.exerciseRank.pageSize,
    datas: state.exerciseRank.data,
    onFetch: state.exerciseRank.onFetch,
    detailData: state.exerciseRank.detailData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getStudentClass: data => { dispatch(getStudentClass(data)) },
    changeCommon: (key, val) => { dispatch(changeCommon(key, val)) },
    fetchRankData: data => { dispatch(fetchRankData(data)) },                                     // 获取班级排名数据
    fetchExcel: (data, type) => { dispatch(fetchExcel(data, type)) },                             // 导出excel
    fetchDetailExerciseResult: (id, classIds, fun) => { dispatch(fetchDetailExerciseResult(id, classIds, fun)) }, // 获取班级测试每道题目的平均用时，平均正确率数据
    resetArrData: () => { dispatch(resetArrData()) },                                         // 初始化数据
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseRank);