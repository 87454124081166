import React from "react";
import { connect } from "react-redux";
import { fetchResource, delResource, newResource } from "./actions";
import { message, Button, Input } from 'antd';
import { checkPermission } from '../../utils/utils';

class InterviewCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            deleteId: '',
            curQuestionIndex: 0,
            previewShow: false,
            tagName: "",
            source: "",
            type: "",
            rootResource: ""
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchResource());
    }

    deleteResource = (id, recursive) => {
        this.props.dispatch(delResource(id, recursive));
    };

    createResource = (pid) => {
        if (!this.state[pid] || this.state[pid].trim() === "") {
            return message.warn('请输入分类标题');
        }

        this.props.dispatch(newResource(this.state[pid], pid));
        this.setState({ pid: "" });
    };

    createRootResource = () => {
        if (this.state.rootResource.trim() === "") {
            return message.warn('请输入分类标题');
        }

        this.props.dispatch(newResource(this.state.rootResource, 0));
        this.setState({ rootResource: "" });
    };

    render() {
        let resource = this.props.resource;
        return (
            <div>
                <h4 style={{ marginBottom: 10, padding: 10 }}>题型分类</h4>
                {
                    checkPermission('sys:resource:edit') &&
                    <div>
                        <Input style={{width: '180px'}} value={this.state.rootResource} onChange={e => this.setState({ rootResource: e.target.value })} />
                        <Button type="primary" style={{ 'margin': '10px' }} onClick={this.createRootResource}
                            disabled={this.props.isSend}>
                            创建一级分类
                        </Button>
                    </div>
                }
                <div>
                    {checkPermission('sys:resource:gkview') &&
                        resource.map((res, idx) => {
                            return (
                                <div key={idx} style={{ 'margin': '20px 0', 'padding': '10px', 'background': 'white' }}>
                                    <h4 style={{ 'display': 'inline-block' }}>{res.name}</h4>

                                    {
                                        checkPermission('sys:resource:edit') &&
                                        <div>
                                            <Input style={{width: '180px'}} value={this.state[res.id]} onChange={e => this.setState({[res.id]: e.target.value})} />
                                            <Button type="primary" style={{ 'margin': '10px' }} onClick={()=>this.createResource(res.id)}
                                                disabled={this.props.isSend}>
                                                创建二级分类
                                            </Button>
                                        </div>
                                    }

                                    {
                                        res.subset && res.subset.map((item, idx) => {
                                            return <span key={idx} style={{ "padding": "10px", "backgroundColor": "white" }}>{item.name}</span>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.interviewCategory
    };
}

export default connect(mapStatetoProps)(InterviewCategory);
