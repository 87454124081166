import apiRequest from '../../common/request';
import {message} from 'antd';

const PREFIX = 'BATCH_CLASS_ACTION';
export const MODIFY_BATCH_CLASS_REDUX = PREFIX+ 'MODIFY_BATCH_CLASS_REDUX';
export const CLEAR_BATCH_CLASS_STATUS = PREFIX+ 'CLEAR_BATCH_CLASS_STATUS';
export const CLEAR_BATCH_CLASS_SEARCH = PREFIX+ 'CLEAR_BATCH_CLASS_SEARCH';

export const modifyBatchClassRedux = data => ({ type: MODIFY_BATCH_CLASS_REDUX, key: data.key, value: data.value });

export const clearBatchClassSearch = () => ({ type: CLEAR_BATCH_CLASS_SEARCH });

export const clearBatchClassStatus = () => ({ type: CLEAR_BATCH_CLASS_STATUS });

export const fetchBatchUpdate = (data, cb) => {
    let url = `/gk/ops/contract/batch/groupClass`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            if (json.status == 1) {
                message.success('操作成功');
                dispatch(clearBatchClassStatus());
                cb && cb();
            } else {
                message.error(json.errorMes||'操作失败');
            }
        })
        .catch(error => console.log(error));
    }
};