import apiRequest from '../../common/request';

const PREFIX = 'EXERCISE_RANK_STUDENT_';

export const FETCHING = PREFIX + 'FETCHING';
export const FETCHING_SUCCED = PREFIX + 'GET_DATA_SUCCED';
export const RESET_STATE = PREFIX + 'RESET_STATE';
export const COMMON = PREFIX + 'COMMON';

const fetching = () => ({ type: FETCHING });
const commonChange = (key, value) => ({ type: COMMON, key, value });


const fetchSucced = (data) => ({
    type: FETCHING_SUCCED,
    value: data
});

export const resetState = () => ({ type: RESET_STATE });


/**
 * 用来获取某个学员的练习详情数据
 * @param {*} classId 班级id
 * @param {*} userId 用户id
 */
export function fetchStudentDetail(classId, userId) {
    let url = `/gk/ops/class/exercise/studentRecords/${classId}/${userId}`;
    let config = { method: 'GET', credentials: 'include' };
    return async dispatch => {
        dispatch(fetching());
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            let dataArr = json.obj || [];
            dispatch(fetchSucced(dataArr));            
            dispatch(commonChange('lastDate', getLastDate(dataArr)));
        }
    }
}

/** 获取最近提交时间 */
const getLastDate = (arr) => {
    let res, m = 0;
    arr.map(item=>{
        let timestamp = item.gmtCreate ? new Date(item.gmtCreate).getTime() : 0;
        if(timestamp > m) {
            m = timestamp;
            res = item.gmtCreate;            
        }
    })
    return res;
}

export function getSort (dataArr) {    
    // 将有提交时间的放在前面
    return dispatch => {
        dataArr.sort((x,y)=>{
            if (x.gmtCreate || 0 < y.gmtCreate || 0) { return -1;} 
            else if (x.gmtCreate || 0 > y.gmtCreate || 0) {return 1;}
            else {return 0;}
        })
        dispatch(fetchSucced([...dataArr]));
    }
}