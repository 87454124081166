import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { publishPushedInfo } from './actions';

import apiRequest from '../../common/request';

import { Card, Radio, Input, Button, message, Tooltip, Spin } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

class PositionPush extends Component {

    constructor() {
        super();
        this.state = {
            appId: 'wxda8eea7653b34063',
            id: "3",
            templateId: 'W_d7Udci2bKuzXhr3k9lrcliOApD3Edtx5nyHx35o04',
            groupType: 0,
            keyword1: '',
            keyword2: '',
            keyword3: '',
            keyword4: '',
            keyword5: '',
            memo: '',
            page: '',
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.fetchPushedInfo();
    }

    // 查询模板
    fetchPushedInfo() {
        let _data = { id: this.state.id }
        let config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_data),
            credentials: 'include'
        };
        
        let fetchUrl = `/gk/ops/wechat-template-msg/selectPosition`;
        
        apiRequest(fetchUrl, config)
            .then((res) => { 
                let resData = res?.obj[0] || {}
                this.setState({ 
                    loading: false,
                    appId: resData?.appId || this.state.appId,
                    id: resData?.id || this.state.id,
                    templateId: resData?.templateId || this.state.templateId,
                    keyword1: resData?.keyword1 || this.state.keyword1,
                    keyword2: resData?.keyword2 || this.state.keyword2,
                    keyword3: resData?.keyword3 || this.state.keyword3,
                    keyword4: resData?.keyword4 || this.state.keyword4,
                    keyword5: resData?.keyword5 || this.state.keyword5,
                    memo: resData?.memo || this.state.memo,
                    page: resData?.page || this.state.page,
                });
            });
    }

    // 输入框改变
    inputChangeHandler(value, key) {
        console.log(key, value)
        this.setState({
            [key]: value,
        })
    }

    // 保存模板
    saveTemplate = () => {
        this.setState({ loading: true });
        let _data = {
            appId: this.state.appId,
            id: this.state.id,
            templateId: this.state.templateId,
            keyword1: this.state.keyword1,
            keyword2: this.state.keyword2,
            keyword3: this.state.keyword3,
            keyword4: this.state.keyword4,
            keyword5: this.state.keyword5,
            memo: this.state.memo,
            page: this.state.page,
        }
        let config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_data),
            credentials: 'include'
        };
        
        let fetchUrl = `/gk/ops/wechat-template-msg/saveOrUpdate`;
        
        apiRequest(fetchUrl, config)
            .then((res) => { 
                this.setState({ loading: false });
                if(res.status==1) {message.success('保存成功');}
                else {message.error('保存失败');}
            });
    }

    /* handleAddInput = () => {
        this.setState({
            noticeData: [...this.state.noticeData, ''],
        })
    }

    handleDeleteInput(idx) {
        let _notice = this.state.noticeData;
        _notice.splice(idx, 1);
        this.setState({
            noticeData: _notice,
        })
    } */

    // 推送人群改变
    onChangeRadio(e) {
        this.setState({ groupType: e.target.value })
    }

    // 发布消息推送, 1：全部人群，2：订阅人群
    publish = () => {
        this.setState({ loading: true });
        this.publishPushedInfo(this.state.groupType);
    }
    
    // 推送消息
    publishPushedInfo(type) {
        let config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: '',
            credentials: 'include'
        };
        
        let fetchUrl = `/gk/ops/positions/pushMsg/${type}`;
        
        apiRequest(fetchUrl, config)
            .then((res) => { 
                this.setState({ loading: false });
                if(res.status==1) {message.success('推送成功');}
                else {message.error('推送失败');}
            });
    }

    render() {
        let { loading, keyword1, keyword2, keyword3, keyword4, keyword5 } = this.state;
        return (
            <div>
                <h4 style={{ margin: "10px 0", fontSize: '14px' }}>添加职位推送</h4>

                <Spin spinning={loading}>
                    <label className="push-type-title">推送人群</label>
                    <Radio.Group className="mb20" onChange={e => this.onChangeRadio(e)} value={this.state.groupType}>
                        <Radio value={0}>全部人群</Radio>
                        <Radio value={2}>订阅人群</Radio>
                    </Radio.Group>

                    <div style={{ marginBottom: "20px" }}>
                        <label className="push-type-title">推送模板</label>
                        {/* <Tooltip title="增加推送内容"><Button shape="circle" icon={<PlusOutlined />} onClick={this.handleAddInput}/></Tooltip> */}
                    </div>

                    {/* {this.state.noticeData.map((item, idx) => {
                        return <div key={idx}>
                            <span>第{idx+1}行：</span>
                            <Input value={item} style={{width: '65%'}} className="mb20" placeholder="通知内容" onChange={event => this.inputChangeHandler(event.target.value, idx)} />

                            {idx > 0 && <Tooltip title={`删除第${idx+1}行`}><Button shape="link" icon=  {<CloseOutlined />} onClick={() => this.handleDeleteInput(idx)}/></Tooltip>
                            }
                        </div>
                    })} */}

                    <div>
                        <span>keyword1：</span>
                        <Input value={keyword1} style={{width: '65%'}} className="mb20" placeholder="keyword1" onChange={event => this.inputChangeHandler(event.target.value, 'keyword1')} />
                    </div>
                    <div>
                        <span>keyword2：</span>
                        <Input value={keyword2} style={{width: '65%'}} className="mb20" placeholder="keyword2" onChange={event => this.inputChangeHandler(event.target.value, 'keyword2')} />
                    </div>
                    <div>
                        <span>keyword3：</span>
                        <Input value={keyword3} style={{width: '65%'}} className="mb20" placeholder="keyword3" onChange={event => this.inputChangeHandler(event.target.value, 'keyword3')} />
                    </div>
                    <div>
                        <span>keyword4：</span>
                        <Input value={keyword4} style={{width: '65%'}} className="mb20" placeholder="keyword4" onChange={event => this.inputChangeHandler(event.target.value, 'keyword4')} />
                    </div>
                    <div>
                        <span>keyword5：</span>
                        <Input value={keyword5} style={{width: '65%'}} className="mb20" placeholder="keyword5" onChange={event => this.inputChangeHandler(event.target.value, 'keyword5')} />
                    </div>
                    <div>
                        <Button type="primary" onClick={() => this.saveTemplate()}>保存模板</Button>
                    </div>

                    <Card title="推送" className="cardR" size="small">
                        <Button type="primary" onClick={() => this.publish()}>推送</Button>
                    </Card>

                </Spin>

            </div>
        )
    }
}

function mapStatetoProps(state) {
    return {};
}

export default connect(mapStatetoProps)(PositionPush);
