import * as CNT from './action';

let initialState = {
    isUpdating: false,
    isFetching: false,
    info: {
        advOptions: 1,              //是否显示问题描述/学习目标/内容要点
        courseId: 0,                //课程编号
        cover: "",                  //视频封面
        length: 0,
        teacher: "",                //教师
        title: "",                  //课时标题
        type: 0,                    //课程类型
        url: "",                    //视频链接
        courseNum: 0,
        exercise: {
            exerciseType: 0,        // 试题类型
            questionIds: null,        // 已选试题的ID集合
            questions: null,          // 已选行测，公基，职测试题的详情数据集合
            slZyQuestions: null,      // 已选申论，综应试题的详情数据集合
        },                          // 这是已选试题的详情数据数组
    }
};

export function courseClassInfo(state = initialState, action) {
    switch (action.type) {
        case CNT.FILL_URL:
        case CNT.FILL_CONTENT:
        case CNT.FILL_TEACHER:
        case CNT.FILL_COURSEID:
            return {...state, info: {...state.info, [action.key]: action.value}};
        case CNT.REQUEST_UPDATE:
            return {...state, isUpdating: true};
        case CNT.RECEIVE_UPDATE:
            return {...state, isUpdating: false};
        case CNT.RECEIVE_INFO:
            return {...state, info: action.info, isFetching: false};
        case CNT.VALUE_INPUT:
            return {...state, info: {...state.info, [action.key]: action.value}};
        case CNT.SET_EXERCISE_DETAIL:
            return {...state, info: {...state.info, exercise: {...state.info.exercise, [action.key]: action.value}}};
        case CNT.FETCH_START:
            return {...state, isFetching: true};
        default:
            return state;
    }
}
