
import { message } from 'antd';
import {
  GUFEN_TEXT,
  YATI_TEXT,
  TRUEEXAM_TEXT,
} from '../ExamineList/action';
import apiRequest from '../../common/request';


const PREFIX = 'EXAMINE_DETAIL';
export const START_FETCHING_DETAIL = PREFIX + 'START_FETCHING_DETAIL';
export const FINISH_LOADING_DATA = PREFIX + 'FINISH_LOADING_DATA';
export const SET_TYPE = PREFIX + 'SET_TYPE';
export const SET_VALUE = PREFIX + 'SET_VALUE';
export const RESET_EXAMINE_OBJ = PREFIX + 'RESET_EXAMINE_OBJ';
export const SET_SEL_QUESIDS = PREFIX + 'SET_SEL_QUESIDS';
export const RESET_ALL_DATA = PREFIX + 'RESET_ALL_DATA';

export const resetAllData = () => ({ type: RESET_ALL_DATA });

export const resetExamineObj = () => ({ type: RESET_EXAMINE_OBJ });

export const setSelQuesIds = value => ({
  type: SET_SEL_QUESIDS,
  value
});

export const setValue = (key, value) => ({
  type: SET_VALUE,
  key,
  value
});

export const startFetching = () => ({ type: START_FETCHING_DETAIL });

export const loadDataSucced = data => ({
  type: FINISH_LOADING_DATA,
  data
});

export const setType = value => ({ type: SET_TYPE, value });

const authCb = () => {
  message.warning('登录失效，请重新登录');
  this.props.history.push("/login");
}

/**
 * 生成获取试卷详情对应的API接口
 * @param {String} type : 试卷类型
 */
function generateUrlAndConfig(type, id) {
  let url = '';
  let config = {
    credentials: 'include', method: 'GET',
    headers: { 'Accept': 'application/json', },
  };
  switch(type) {
    case GUFEN_TEXT:                                // 估分试卷类型
      url = `/gk/ops/estimate/getById?id=${id}`;
      break;
    case YATI_TEXT:                                 // 押题试卷类型
      url = `/gk/ops/mockExamine/getById?id=${id}`;
      break;
    case TRUEEXAM_TEXT:                             // 历年真题试卷类型
      url = `/gk/ops/historyExamine/getById?id=${id}`;
      break;
    default:
      throw new Error('generateUrl Error');
  }
  return { url, config };
}


/**
 * 生成创建试卷对应的API接口
 * @param {String} type : 试卷类型
 * @param {Object} data : 需要提交API接口的数据
 */
function postGenerateUrlAndConfig(type, data) {
  let url = '';
  let config = {
    credentials: 'include', method: 'POST',
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
    body: JSON.stringify(data)
  };
  switch(type) {
    case GUFEN_TEXT:                                // 估分试卷类型
      url = `/gk/ops/estimate/saveOrUpdate`;
      break;
    case YATI_TEXT:                                 // 押题试卷类型
      url = `/gk/ops/mockExamine/saveOrUpdate`;
      break;
    case TRUEEXAM_TEXT:                             // 历年真题试卷类型
      url = `/gk/ops/historyExamine/saveOrUpdate`;
      break;
    default:
      throw new Error('generateUrl Error');
  }
  return { url, config };
}

/**
 * 从已经选择的问题数组里面筛选出问题ID
 * @param {Array} selQuesArr : 问题数组
 */
export function findQuesId(selQuesArr, containPoint=false) {
  if (Object.prototype.toString.call(selQuesArr) !== '[object Array]') return [];
  let result = [];
  selQuesArr.map(obj => {
    if (containPoint) {
      result.push({ questionId: obj.id, point: obj.point });
    } else {
      result.push(obj.id);
    }
  });
  return result;
}

/**
 * 获取试卷的详情数据
 * @param {Number} id : 试卷ID
 * @param {String} type : 试卷类型
 */
export function getExamineDetail(id, type) {
  const { url, config } = generateUrlAndConfig(type, id);
  return dispatch => {
    dispatch(startFetching());
    apiRequest(url, config)
      .then(json => {
        let selQuesIds = findQuesId(json.obj && json.obj.questions);
        dispatch(loadDataSucced(json.obj));
        dispatch(setSelQuesIds(selQuesIds));
      })
  }
}

/**
 * 创建或者修改试卷
 * @param {Object} params : 提交接口的参数
 * @param {*} type : 试卷类型
 */
export async function uploadExamineDetail(params, type, cb) {
  const { url, config } = postGenerateUrlAndConfig(type, params);
  apiRequest(url, config)
    .then(json => {
      message.success('操作成功');
      cb && cb();
    })
}