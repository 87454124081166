import React from "react";
import { connect } from "react-redux";
import ImagePicker from '../../components/ImagePicker';
import  BannerChoseDetail from "../../components/BannerChoseDetail/index";
import {
    fill_title,
    fill_img_url,
    publishBanner,
    fill_type,
    setNewObj,
    MINI_DATA_TYPE,
    RICH_DATA_TYPE,
    COURSE_DATA_TYPE,
    DAILY_DATA_TYPE,
    fetchBannerInfo
} from "./action";
import { checkPermission, validateUtil } from '../../utils/utils';
import { Button, Select, Input, message, Spin, Card } from 'antd';
import './index.scss';

const Option = Select.Option;
const ADDRESS_TYPES = [
    { name: 'android位置', value: 1 },
    { name: 'ios位置', value: 2 },
    { name: 'pc位置', value: 3 },
    { name: '小程序位置', value: 4 }
];

const styles = {
    aside: {
        position: 'absolute',
        width: 240,
        height: 100,
        top: 60,
        right: 10
    }
};

class BannerRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgSelected: false,
            showModal: false,
            addressTypes: [1],
            dataType: "-1",                     //点击banner之后跳转类型，1=资讯，2=课程，3=练习；默认为资讯
            dataId: "",                         //若dataType为2或3,则表示课程或练习id,若为资讯，则无id
        }
    }

    componentDidMount() {
        if (this.props.match.params.bannerId) {
            this.props.dispatch(fetchBannerInfo(this.props.match.params.bannerId));
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    imgPickerFeedBack = (value) => {
        this.setState({ showModal: false, imgSelected: true });
        this.props.dispatch(fill_img_url(value))
    };

    addImg = () => {
        this.setState({ showModal: true });
    };

    selectType = (key, value) => {
        if(key == 'breed') {
            this.props.dispatch(fill_type('addressTypes', [1]));
            this.setState({ addressTypes: [1] });
        }
        this.setState({ [key]: value });
        this.props.dispatch(fill_type(key, value));
    };

    submit = () => {
        let { bannerVO } = this.props;
        let blackList = ['title', 'imgUrl', 'dataType'];
        let keys = { title: 'banner标题', imgUrl: 'banner图', dataType: 'banner类型' };
        if (validateUtil(bannerVO, blackList, keys)) return;
        let dataType = bannerVO.dataType;
        if (dataType === RICH_DATA_TYPE) {                  // 如果banner推送类型是资讯类型的话，那么必须输入资讯富文本content
            if (bannerVO.content=='') {
                message.warning('请输入资讯文本');
                return;
            }
        }
        if (dataType === MINI_DATA_TYPE || dataType === COURSE_DATA_TYPE || dataType === DAILY_DATA_TYPE) {     // 那么必须输入dataId,dataId可以为0
            if (bannerVO.dataId == null) {
                message.warning('请输入关联内容ID');
                return;
            }
        }
        const cb= () => this.props.history.push('/banners');
        this.props.dispatch(publishBanner(bannerVO, cb));
    };

    /** 子组件更新父组件所维护的bannerVO数据 */
    childUpdateBannerVo = newObj => { this.props.dispatch(setNewObj(newObj)); }


    render() {
        let { bannerVO } = this.props;
        if (this.props.isFetching) {
            return <Spin />
        }
        return (
            <div className="bannerRelease">
                <div className="flex-container">
                    <main>
                        <div className="inputRow">
                            <label className="labelWidth">banner标题</label>
                            <Input
                                className="w500"
                                type="text" placeholder="请输入banner标题"
                                value={bannerVO.title}
                                onChange={event => this.props.dispatch(fill_title(event.target.value))}
                            />
                        </div>

                        <div className="inputRow">
                            <label className="labelWidth">banner图</label>
                            {
                                bannerVO.imgUrl ? <Button type="danger" onClick={this.addImg}>修改</Button> : <Button type="primary" onClick={this.addImg}>添加</Button>
                            }
                            {
                                bannerVO.imgUrl && <img alt="img" className="bannerImg" src={bannerVO.imgUrl} />
                            }
                        </div>

                        {/* 事业单位条件下，pc位置不能选择 */}
                        <div className="inputRow">
                            <label className="labelWidth">显示位置</label>
                            <Select className="w360" value={bannerVO.addressTypes} onChange={(value)=>this.selectType('addressTypes', value)} mode="multiple">
                                {
                                    ADDRESS_TYPES.map((positionObj, idx) => <Option key={idx} disabled={bannerVO.breed==1 && positionObj.value==3} value={positionObj.value}>{positionObj.name}</Option>)
                                }
                            </Select>
                        </div>

                        <div className="inputRow">
                            <label className="labelWidth">banner品类</label>
                            <Select className="w360" value={bannerVO.breed} onChange={(value)=>this.selectType('breed', value)}>
                                <Option value={0}>公务员</Option>
                                <Option value={1}>事业单位</Option>
                            </Select>
                        </div>

                        <BannerChoseDetail
                            bannerVO={bannerVO}
                            updateBannerVo={this.childUpdateBannerVo}
                        />

                    </main>

                    {
                        checkPermission('sys:banner:edit') &&
                        <Card className="cardR" size="small" title='发布'>
                            <Button type="primary" onClick={this.submit}>提交</Button>
                        </Card>
                    }
                </div>

                <ImagePicker
                    showModal={this.state.showModal}
                    closeModal={this.closeModal}
                    feedBack={this.imgPickerFeedBack}
                    bucket='gk-resource'
                    imgPath='background/banner'
                />
            </div>
        )
    }
}

function mapStatetoProps(state) {
    return { ...state.bannerRelease };
}

export default connect(mapStatetoProps)(BannerRelease);