/**
 * LOG: 这个组件是用来课后练习中行测题目的宏观数据
 */

import React from 'react';
import { Tooltip } from 'antd';
import './index.scss';

const AnswerCardUnit = props => {

  /** 对接口所返回的数据做一个处理 */
  const dataHandler = () => {
    let questionObj = props.questionObj || {};
    let pointHistogram = questionObj.pointHistogram;
    if (Object.prototype.toString.call(pointHistogram) !== "[object Object]") return [];
    let result = [];
    Object.keys(pointHistogram).map(key => {
      let choiceChosedNum = pointHistogram[key];
      let item = {
        choice: key,                                                // 选项的值
        choiceChosedNum,                                            // 这个选项被多少个人选择了
        answerIsRight: questionObj.answer && questionObj.answer.indexOf(key) !== -1,      // 这个选项是正确答案
      };
      result.push(item);
    });
    return result;
  }

  const generateUserAnswerClass = () => {
    return {
      userAnswer: true,
      userAnswerIsRight: props.questionObj.correct == 1,           // 不考虑多选题，因为把几个选项都放在一起了
      smallUserAnswer: (props.questionObj.userAnswer && props.questionObj.userAnswer.length > 1) || !props.questionObj.userAnswer,                            // 多选，此时字号小一点
    };
  }

  const optionList = dataHandler();
  const optionLength = optionList.length;
  const optionUnitWidth = 55;
  const answerCardUnitWidth = Math.ceil(optionLength / 2) * optionUnitWidth;


  /** 渲染每一个选项都有哪些人选择了 */
  const renderWhoChoose = (answerKey, choiceChosedNum) => {
    let answerNameMap = (props.questionObj && props.questionObj.answerNameMap) || {};
    let students = answerNameMap[answerKey] || [];
    if (props.isFromUser) {
      return `这道题目在该班级有${choiceChosedNum}人选了${answerKey}这个答案`;
    } else {
      return (
        <div className="studentWhoChoose">
          {
            students.map((studentName, idx) => (
              <span  key={idx} style={{ marginRight: 10, marginBottom: 10 }}>
                {studentName}
              </span>
            ))
          }
        </div>
      );
    }
  }

  /** 点击题号跳转题目详情 */
  const goToDetail=(id)=>{  
    if(id==0) return;
    // 行测
    if(props.exerciseType==0) window.open(`/question/${id}`, '_blank');
    // 公基
    if(props.exerciseType==3) window.open(`/institutionInfo/gj/${id}`, '_blank');
    // 职测
    if(props.exerciseType==4) window.open(`/institutionInfo/zc/${id}`, '_blank');
  }
  
  return (
    <div className="answerCardUnit" style={{ width: answerCardUnitWidth }} onClick={()=>goToDetail(props.questionId)}>
      <div className="topRow flexRow">
        <span className="questionIndexC">{props.questionIndex}.</span>
        {
          props.isFromUser && (
            <Tooltip title={`学员所选答案为${props.questionObj.userAnswer || '未选'}`}>
              <span className={(generateUserAnswerClass())}>{props.questionObj.userAnswer || '未选'}</span>
            </Tooltip>
          )
        }
        <Tooltip title={`这道题目在该班做过的人中，平均正确率为${props.questionObj.averageAccuracy}`}>
          <span className="averageAccuracyC">{props.questionObj.averageAccuracy}</span>
        </Tooltip>
      </div>
      <div className="bottomRow flexRow">
        {
          optionList.length && optionList.map((optionObj, idx) => (
            <div key={idx} className="optionUnit" style={{ width: optionUnitWidth }}>
              <Tooltip title={renderWhoChoose(optionObj.choice, optionObj.choiceChosedNum)}>
                <div className="flexRow">
                  <span className={ optionObj.answerIsRight ? 'isCorrect' : 'optionKey' }>{optionObj.choice}</span>
                  <span className="choiceChosedNum">{optionObj.choiceChosedNum}</span>
                </div>
              </Tooltip>
            </div>
          )) || '无人作答'
        }
      </div>
    </div>
  );
}

AnswerCardUnit.defaultProps = {
  questionId: 0,
  questionObj: {},
  isFromUser: true,                             // 默认就是来自学员的情况分析
};

export default AnswerCardUnit;