import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "MATERIAL_QUESTION_LIST";
export const REQUEST_MATERIALS = PREFIX + "REQUEST_MATERIALS";
export const RECEIVE_MATERIALS = PREFIX + "RECEIVE_MATERIALS";
export const REQUEST_DELETE = PREFIX + "REQUEST_DELETE";
export const RECEIVE_DELETE = PREFIX + "RECEIVE_DELETE";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";
export const SET_VALUE = PREFIX + 'SET_VALUE';
export const RESET_QUERY_VALUE = PREFIX + 'RESET_QUERY_VALUE';

export const resetQueryValue = () => ({ type: RESET_QUERY_VALUE });

export const setValue = (data) => ({
    type: SET_VALUE,
    key: data.key,
    val: data.val
});

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

function request_materials() {
    return {
        type: REQUEST_MATERIALS
    }
}

function receive_materials(data) {
    return {
        type: RECEIVE_MATERIALS,
        items: data.content,
        totalElements: data.totalElements,
    }
}

function receive_delete(id) {
    return {
        type: RECEIVE_DELETE,
        id
    }
}

function request_delete() {
    return {type: REQUEST_DELETE}
}

/**
 * 加载材料题列表数据
 * @param {Object} obj: 查询参数
 * @param type:xc gj zc 哪种类型
 * 行测 /gk/ops/materials/page
 * 公基 /gk/gj/ops/materials/page
 * 职测 /gk/zc/ops/materials/page
 */
export function fetchMaterials(type, obj) {
    let url = "/gk/ops/materials/page";
    if(type!='xc') url = `/gk/ops/${type}/materials/page`
    let data = {
        page: obj.page || 1,
        pageSize: obj.pageSize || 10,
    }
    obj.query==='' ? '': data.query = obj.query;
    obj.options==='' ? '': data.options = obj.options;

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }

    return dispatch => {
        dispatch(request_materials());
        apiRequest(url, config )
          .then(json => {
            dispatch(receive_materials(json.obj));
          })
          .catch(error => console.log(error));
    }
}

export function deleteMaterials(id) {
    return dispatch => {
        dispatch(request_delete());
        return apiRequest(`/gk/ops/materials/delete/${id}`, {credentials: 'include'})
          .then(json => {
            message.success('操作成功');
            dispatch(receive_delete(id))
          })
          .catch(error => console.log(error));
    }
}