import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { notification } from 'antd';

/** 当登录失效的时候清除本地token */
const resetCookie = () => {
  Cookies.remove('accessToken');
  localStorage.removeItem('routers');
  localStorage.removeItem('user');
}

/**
 * @param {String} url : 请求链接
 * @param {Object} options : 请求头参数
 * @param {Boolean} cb : 请求失败回调函数
 */
const apiRequest = (url, options, cb) => {
  options = {
    headers: {},
    ...options
  };
  options.headers['Sin-Access-Token'] = Cookies.get('accessToken');
  options.headers['Sin-UserAgent-Sign'] = 'SintureGK-WEB';
  options.headers['Sin-Client-Type'] = 3;
  return new Promise((resolve, reject) => {
    fetch(url, options).then(response => response.json())
      .then(json => {
        if (json.status === 1) {          // 请求成功，返回数据
          resolve(json);
        } else if (json.status === 3 || json.status === 500) {   // 未登录或者登陆失效          
          resetCookie();
          notification.error({
            message: `温馨提示 status： ${json.status}`,
            description: `${json.errorMes || '服务端返回异常'}`,
          });
          setTimeout(() => {
            window.location = '/login';
          }, 500)
        } else {                          // 请求失败情况 status==2或其他异常的情况
          notification.error({
            message: `温馨提示 status： ${json.status}`,
            description: `${json.errorMes || '服务端返回异常'}`,
          });
          cb && cb();
          reject(json);
        }
      }).catch(e => {
        reject(e);
      })
  })
}

export default apiRequest;