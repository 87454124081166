import {
    REQUEST_UPDATE,
    REQUEST_UPDATE_VIDEO,
    REQUEST_INFO,
    RECEIVE_INFO,
    RECEIVE_UPDATE_RESULT,
} from './actions';


export function iStudentInfo(state = {
    isFetching: false,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: {
        "area": "",
        "content": "",
        "date": "",
        "gkLink": "",
        "href": "",
        "source": "",
        "title": "",
        "type": ""
    },
    creatTime: 0
}, action) {
    switch (action.type) {
        case REQUEST_INFO:
            return Object.assign({}, state, {isFetching: true, creatTime: (new Date()).getTime()});
        case RECEIVE_INFO:
            return Object.assign({}, state, {
                isFetching: false,
                info: action.info,
            });
        case REQUEST_UPDATE:
            return Object.assign({}, state, {isUpdating: true});
        case RECEIVE_UPDATE_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        default:
            return state;
    }
}
