import * as CNT from './action';

// 由于增加了一个筛选课程的功能，所以再这里需要保存一下原始的课程列表
export function courseList(state = {
    isFetching: false,
    isDeleting: false,
    items: [],
    originItems: [],                                        // 除开获取到数据的话，那么其它场合下都不应该能够对其作出处理
    totalElements: 1,
    currentPage: 1,                                         // 当前页面
    pageSize: 10,                                           // 默认每页20条
    categories: [],
    teachers: [],
    labels: [],
}, action) {
    switch (action.type) {
        case CNT.REQUEST_COURSES:
            return {...state, isFetching: true};
        case CNT.RECEIVE_COURSE_LABELS:
            return Object.assign({}, state, {
                labels: action.labels
            });
        case CNT.RECEIVE_COURSE_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.categories
            });
        case CNT.RECEIVE_COURSE_TEACHERS:
            return Object.assign({}, state, {
                teachers: action.teachers
            });
        case CNT.RECEIVE_COURSES:
            return {...state, isFetching: false, items: action.list, originItems: action.list, totalElements: action.totalElements};
        case CNT.REQUEST_RECOMMEND:
        case CNT.REQUEST_DELETE:
            return {...state, isDeleting: true};
        case CNT.RECEIVE_RECOMMEND:
            let temp = state.items.find(item => item.id == action.id);
            temp.recommend = (temp.recommend !== 1 ? 1 : 0);
            return {...state, isDeleting: false, items: [...state.items.filter(item => item.id != action.id), temp]};
        case CNT.RECEIVE_DELETE:
            return {...state, isDeleting: false, totalElements: --state.totalElements, items: [...state.items.filter(item => item.id != action.id)]};
        case CNT.SETTING_CLASSLIST_ARR:
            return {...state, items: action.data};
        case CNT.SETTING_CURRENT_PAGE:
            return {...state, currentPage: action.data};
        case CNT.SETTING_PAGE_SIZE:
            return {...state, pageSize: action.data};
        default:
            return state;
    }
}