import {
    FETCH_DATA_START,
    FETCH_DATA_SUCCED,
    FETCH_DATA_FAIL,
    CHANGE_ACTIVE_PAGE,
    CHANGE_PAGE_SIZE,
    FETCH_CLASS_LABEL_START,
    FETCH_CLASS_LABEL_SUCCED,
    CHANGE_LABEL,
    FETCH_CLASS_TEACHER_START,
    FETCH_CLASS_TEACHER_SUCCED,
    CHANGE_HEAD_TEACHER,
    CHANGE_SUBJECT,
    CHANGE_TITLE,
    FETCH_COURSE_LIST_SUCCED,
    CHANGE_TEACHER,
    RESET_SEARCH_STATUS,
    CHANGE_HEADTEACHER_ID,
} from './actions';
import { GET_HEAD_TEACHER_INFORM } from "../ContractOrder/action";

const initSearch = {
    activePage: 1,                  // 当前第几页
    pageSize: 10,                   // 每页多少条
    label: '全部',                   // 班级标签
    teacher: '全部',                 // 任课老师
    headTeacher: '全部',             // 班主任
    subject: '',                 // 科目
    title: '',                      // 班级名
    teacherId: '全部',               // 班主任id
};

const initialState = {
    onLoading: false,               // 是否处于加载过程中
    apiError: false,                // 关键API发生错误
    responseObj: {
        content: [],                    // 班级列表数据
        totalElements: 10,              // 班级总数 
    },                                  // 接口返回数据
    labels: [],                         // 班级标签数组
    onLoadingLabel: false,              // 正处在加载班级标签的过程中
    headTeachers: [],                   // 班级教师数组
    headTeacherArr: [],                 // 其他组件引用
    onLoadingTeacher: false,            // 班级教师正在加载中
    courseList: [],                     // 课程列表数组
    subjects: [
        { label: '全部', value: "", type: '' },
        { label: '行测', value: 0, type: 'XINGCE' },
        { label: '申论', value: 1, type: 'SHENLUN' },
        { label: '公基', value: 3, type: 'GONG_JI' },
        { label: '职测', value: 4, type: 'ZHI_CE' },
        { label: '综应', value: 5, type: 'ZONG_YING' },
     ],                                  //科目列表
    ...initSearch
};
const apiErrorRes = {
    content: [],
    totalElements: 5
};

export const classList = (previousState=initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_START:
            return { ...previousState, onLoading: true };
        case FETCH_DATA_SUCCED:
            return { ...previousState, onLoading: false, apiError: false, responseObj: action.data };
        case FETCH_DATA_FAIL:
            return { ...previousState, apiError: true, onLoading: false, responseObj: apiErrorRes };
        case CHANGE_PAGE_SIZE:
            return { ...previousState, pageSize: action.data };
        case CHANGE_ACTIVE_PAGE:
            return { ...previousState, activePage: action.data };
        case FETCH_CLASS_LABEL_START:
            return { ...previousState, onLoadingLabel: true };
        case FETCH_CLASS_LABEL_SUCCED:
            return { ...previousState, labels: action.data, onLoadingLabel: false };
        case CHANGE_LABEL:
            return { ...previousState, label: action.data };
        case FETCH_CLASS_TEACHER_START:
            return { ...previousState, onLoadingTeacher: true };
        case CHANGE_HEADTEACHER_ID:
            return { ...previousState, teacherId: action.value };
        case FETCH_CLASS_TEACHER_SUCCED:
            return { ...previousState, headTeacherArr: action.data, onLoadingTeacher: false };
        case GET_HEAD_TEACHER_INFORM: 
            return { ...previousState, headTeachers: action.data, onLoadingTeacher: false };
        case CHANGE_HEAD_TEACHER:
            return { ...previousState, headTeacher: action.data };
        case CHANGE_SUBJECT: 
            return {...previousState, subject: action.data };
        case CHANGE_TITLE:
            return { ...previousState, title: action.data };
        case FETCH_COURSE_LIST_SUCCED:
            return { ...previousState, courseList: action.data };
        case CHANGE_TEACHER:
            return { ...previousState, teacher: action.data };
        case RESET_SEARCH_STATUS:
            return { ...previousState, ...initSearch };
        default:
            return previousState;
    }
}