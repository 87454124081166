import {
  FETCH_FEEDBACK_SUCCED,
  SET_PAGE_SIZE,
  SET_ACTIVE_PAGE,
  START_LOADING_DATA,
  CHNAGE_STATUS
} from './actions';

const initialState = {
  responseObj: {
    content: [],
  },
  loading: false,
  pageSize: 10,
  page: 1,
  status: -1,
};

const userFeedback = (previousState=initialState, action) => {
  switch(action.type) {
    case FETCH_FEEDBACK_SUCCED:
      return { ...previousState, responseObj: action.data, loading: false };
    case SET_ACTIVE_PAGE:
      return { ...previousState, page: action.data };
    case START_LOADING_DATA:
      return { ...previousState, loading: true };
    case SET_PAGE_SIZE:
      return { ...previousState, pageSize: action.data };
    case CHNAGE_STATUS:
      return { ...previousState, status: action.data };
    default:
      return previousState;
  }
}

export { userFeedback };