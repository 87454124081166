import React from 'react'
import { Layout, Menu, BackTop } from 'antd';
import { Link, withRouter } from "react-router-dom";
import Navbar from '../components/Navbar';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
class Layouts extends React.Component {
    state = {
        openKeys: [],
        collapsed: false,
    };

    onCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        // 每次页面跳转，滚动条位置不会改变，需要手动给他重置为0
        let top = document.querySelector('.site-layout-background');
        top && (top.scrollTop = 0);

        let navLinks = (localStorage.getItem('routers') != 'undefined') && JSON.parse(localStorage.getItem('routers')) || [];
        const { collapsed, openKeys } = this.state;
        const onOpenChange = keys => {
            const latestOpenKey = keys.find(key => (this.state.openKeys).indexOf(key) === -1);
            this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [''] });
        };

        return (
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo">
                        <Link to='/index'>
                            <img className="logoIcon" alt="logo" src="https://jd-media-resource.oss-cn-hangzhou.aliyuncs.com/background/jiaodian-logo.jpg" />
                            {!collapsed && <span style={{ color: '#fff', marginLeft: '10px' }}>焦点三位一体</span>}
                        </Link>
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} openKeys={openKeys} onOpenChange={onOpenChange}>
                        {navLinks.map((item, idx) => (
                            <SubMenu key={idx} icon={<i className={`fa ${item.meta.icon}`}></i>} title={" " + (!collapsed ? item.meta.title : '')}>
                                {item.children && item.children.map((subs) => (
                                    !subs.hidden && <Menu.Item key={subs.path} icon={<i className={`fa ${subs.meta.icon}`} />}><Link to={`/${subs.path}`}>{subs.meta.title}</Link></Menu.Item>
                                ))}
                            </SubMenu>)
                        )}
                    </Menu>
                    <div style={{ height: '48px' }}></div>
                </Sider>
                <Layout>
                    <Header>
                        <div onClick={this.onCollapse}>{collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}</div>
                        <Navbar />
                    </Header>
                    <Content className="site-layout-background">
                        {this.props.children}
                        <Footer style={{ textAlign: 'center' }}>Copyright © {new Date().getFullYear()} 焦点三位一体 All Rights Reserved</Footer>
                        <BackTop target={() => document.querySelector('.site-layout-background')} />
                    </Content>
                </Layout>
            </Layout>);
    }
}

export default withRouter(Layouts);