import apiRequest from '../../common/request';
import Cookies from 'js-cookie';
import { message } from "antd";
// import fetch from 'isomorphic-fetch';

const PREFIX = "EXERCISE_RANK";

export const FETCH_RANK_DATA_SUCCED = PREFIX + "FETCH_RANK_DATA_SUCCED";
export const FETCH_RANK_DATA_START = PREFIX + "FETCH_RANK_DATA_START";
export const FETCH_DETAIL_RANK_DATA_SUCCED = PREFIX + "FETCH_DETAIL_RANK_DATA_SUCCED";
export const RESET_ARR_DATA = PREFIX + "RESET_ARR_DATA";
export const CHANGE_COMMON = PREFIX + "CHANGE_COMMON";

/** 初始化数据 */
export const resetArrData = () => ({
  type: RESET_ARR_DATA
});

export const changeCommon = (key,val) => ({
    type: CHANGE_COMMON,
    key,val
});

const fetch_detail_rank_succed = (data) => ({
  type: FETCH_DETAIL_RANK_DATA_SUCCED,
  data
});

const fetch_rank_succed = (data) => ({
    type: FETCH_RANK_DATA_SUCCED,
    data
});

const fetch_rank_start = () => ({
    type: FETCH_RANK_DATA_START
});

/** 获取所有班级数据 */
export const getStudentClass = (examineId) => {
    let url = `/gk/ops/class/exercise/getStudentClass/${examineId}`;
    let config = {
        method: 'GET',
        credentials: 'include'
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            dispatch(changeCommon('classArrList',json.obj));
        }
    }
}

/** 获取班级学员列表数据 */
export const fetchRankData = (params) => {
    let url = `/gk/ops/class/exercise/rank`;
    let data = {
        classIds: params.classIds || [],
        examineId: params.examineId,//班级练习id
        page: params.page || 1,
        pageSize: params.pageSize|| 10
    };
    let config = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return async dispatch => {
        fetch_rank_start();
        let json = await apiRequest(url, config);
        if (json.status == 1) {
            dispatch(fetch_rank_succed(json.obj));
        }
    }
}

/** 导出班级练习的学员答题情况数据 type === 0 => 导出排名数据 ； type === 1 => 导出班级平均用时以及平均正确率 */
export function fetchExcel  (data, type) {    
    let url, downName;
    let config = {};
    if (type === 0) {
        config.method =  'POST';
        config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken')
        };
        config.body = JSON.stringify(data);
        url = `/gk/ops/class/exercise/exportRank`;
        downName = `${data.examineId}-班级排名数据.xls`;
    } else {
        config.method =  'GET';
        config.headers = {
            'Accept': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken')
        };
        url = `/gk/ops/class/exercise/exportAverageAccuracy/${data.id}?classIds=${data.classIds}`;
        downName = `${data.id}-班级测试平均用时以及平均正确率数据.xls`;
    }
    return dispatch => {
        fetch(url, config).then(response => {
            if (response.status === 401) {
                message.warn('没有权限');
                this.props.history.push("/login");
            } else {
                response.blob().then(blob => {
                    let a = document.createElement('a');
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = downName;
                    document.body.appendChild(a).click();
                    window.URL.revokeObjectURL(url);
                })
            }
        }).catch(err => {
            message.warn('网络错误')
        })
    }
}

function getData(arr, key) {
    let xArr = [];
    let yArr = [];
    // 貌似echarts不支持y轴为字符串
    arr.map(obj => {
        xArr.push('题目'+obj['number']);
        if (typeof obj[key] === 'string') {
            obj[key] = Number(obj[key].substr(0, obj[key].length-1));
        }
        if (typeof obj[key] === 'number') { yArr.push(obj[key]) };
    });
    return { xArr, yArr };
}


/** 获取这套班级测试试卷的正确率数据，包括有每一道题目的平均正确率，每一道题目的平均用时 */
export const fetchDetailExerciseResult = (id, classIds, fun) => {
    let url = `/gk/ops/class/exercise/averageAccuracy/${id}?classIds=${classIds}`;
    let config = {
        method: 'GET',
        credentials: 'include'
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        if (json.status === 1) {
            !json.obj.length && message.warn('已选班级无统计数据');
            dispatch(fetch_detail_rank_succed(json.obj));
            let correct_data = getData(json.obj, 'accuracy');
            let time_data = getData(json.obj, 'averageTimes');
            fun({ xArr: correct_data.xArr, correct_Yarr: correct_data.yArr, time_Yarr: time_data.yArr });
        }
    }
}