import {
  SUCCESS_GET_INTERVIEW_QUESTION,
  SET_KEY_VALUE,
  RESET_SEARCH_STATUS
} from './actions';

  const searchFilter = {
      area: '',
      questionTag: '',
      page: 1,
      pageSize: 10,
      query: '',                      // 这个是问题的关键词
      year: '',
  };

  let initialState = {
      isFetching: false,
      questions: [],
      totalElements: 10,
      ...searchFilter
  };

export const interviewQuestions = (prevState=initialState, action) => {
  switch(action.type) {
      case SUCCESS_GET_INTERVIEW_QUESTION:
          return { ...prevState, questions: action.data.content, totalElements: action.data.totalElements};
      case SET_KEY_VALUE:
          return { ...prevState, [action.key]: action.value };
      case RESET_SEARCH_STATUS:
          return { ...prevState, ...searchFilter };
      default:
          return prevState;
  }
}