import apiRequest from '../../common/request';
import {message} from 'antd';
const PREFIX = 'BATCH_ACTION';

export const SET_BATCH_LIST = PREFIX + 'SET_BATCH_LIST';
export const CHANGE_TABLE_LIST = PREFIX + 'CHANGE_TABLE_LIST';
export const MODIFY_TABLE_LIST = PREFIX + 'MODIFY_TABLE_LIST';
export const SET_CURRENT_DATE = PREFIX + 'SET_CURRENT_DATE';
export const SET_CURRENT_STATUS = PREFIX + 'SET_CURRENT_STATUS';
export const ADD_MODIFY_REMARKS = PREFIX + 'ADD_MODIFY_REMARKS';
export const MODIFY_BATCH_CURRENT_REDUX = PREFIX + 'MODIFY_BATCH_CURRENT_REDUX';
export const CLEAR_BATCH_SEARCH = PREFIX + 'CLEAR_BATCH_SEARCH';
export const MODIFY_SELECT_VALUE = PREFIX + 'MODIFY_SELECT_VALUE';
export const CLEAR_BATCH_STATUS = PREFIX + 'CLEAR_BATCH_STATUS';

export const modifySelectValue = data => ({ type: MODIFY_SELECT_VALUE, key: data.key, value: data.value });

export const clearBatchSearch = () => ({ type: CLEAR_BATCH_SEARCH });

export const modifyBatchCurrentRedux = data => ({ type: MODIFY_BATCH_CURRENT_REDUX, key: data.key, value: data.value });

export const changeTableList = data => ({ type: CHANGE_TABLE_LIST, data });

export const modifyTableList = data => ({ type: MODIFY_TABLE_LIST, data });

export const setCurrentDate = value => ({ type: SET_CURRENT_DATE, value });

export const setCurrentStatus = value => ({ type: SET_CURRENT_STATUS, value });

export const addModifyRemarks = data => ({ type: ADD_MODIFY_REMARKS, data });

export const clearBatchStatus = () => ({ type: CLEAR_BATCH_STATUS });

const set_batch_list = (data, type) => {
    let content = data.content;
    if (content && content.length) {
        content.map(item => {
            item.contractId = item.id;
            delete item.id;
            if (type == 'supervise') {
                item.teacherId = item.headTeacherId;
                delete item.headTeacherId;
            }
        })
    } 
    return {
        type: SET_BATCH_LIST,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        content, 
    }
};

/** 获取批量操作的所有用户信息 */
export const bulkOperation = (data, type) => {
    let url = `/gk/ops/contract/user/page`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        let Obj = json.obj || {};
        dispatch(set_batch_list(Obj, type));
    };
};

export const batchUpdataBase = (data, type, cb) => {
    let url = `/gk/ops/supervise/saveSuperviseInfo`;
    if (type === "userCare") url = `/gk/ops/userCare/save/batch`;
    if (type === "returnVisit") url = `/gk/ops/visit/save/batch`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            if (json.status == 1) {
                message.success('操作成功');
                dispatch(clearBatchStatus());
                cb && cb();
            } else {
                message.error(json.errorMes||'操作失败');
            }
        })
        .catch(error => console.log(error));
    }
}