/**
 *  LOG: 目前为止，这个视频选择组件用在：1.课时视频选择；2.课程banner视频选择；3.习题解析视频选择；4.TV视频；
 *  差异点1：1和3所使用的仓库应该是gk-course仓库；而2和4所使用的仓库应该是gk-news仓库
 *  差异点2：1，3，4后端用来标识标识视频的都是视频对象的key（4有点特别，URL也要）；但是2就只是用URL来标识
 *  不存在本地上传功能。
 */

import React from 'react';
import { connect } from 'react-redux';
import { Typography, Modal, Button, Tabs, Table, message, Input } from 'antd';
import {
    fetch_content_of_video_directory,
    fetchVideos,
    video_picker_last_page,
    video_picker_next_page, video_picker_reset,
    videoPickerUploadVideo,
    reset_video_markers,
    getAvailableVideoUrl
} from './action';
import { PUB_TO_ENDPOINT } from '../../common/consts';
import './style.scss'

const { Paragraph } = Typography;
class VideoPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadFiles: [],
            selectedVideo: null,
            urlInput: "",
            urlValid: false,
            tabKey: 1,
            seeVideoModal: false,                   // 查看视频的弹窗
            seeVideoUrl: '',                        // 所查看视频的链接
            isVideo: true,                          // 所查看的是否是视频
            queryList: ['']
        }
        this.videoJs = React.createRef();
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(video_picker_reset());
        this.props.dispatch(fetchVideos({}, this.props.pubName));
    };

    format = (value) => {
        return (value < 10 ? "0" : "") + value;
    };

    transformTime = (value) => {
        const date = new Date(value / 10000);
        return date.getFullYear() + "-" + this.format(date.getMonth() + 1) + "-" + this.format(date.getDate()) + " " +
            this.format(date.getHours()) + ":" + this.format(date.getMinutes()) + ":" + this.format(date.getSeconds());
    };

    /** 把kb转换为其它可读的size形式 */
    transformSize = (value) => {
        const kb = value / 1024;
        const mb = value / (1024 * 1024);
        const gb = value / (1024 * 1024 * 1024);
        if (gb > 1) {
            return gb.toFixed(2) + "GB";
        } else if (mb > 1) {
            return mb.toFixed(2) + "MB";
        } else {
            return kb.toFixed(2) + "KB";
        }
    };

    selectVideo = url => {
        var videos = this.props.videos;
        this.setState({ selectedVideo: videos.filter(video => video.url == url)[0] });
    };

    /** 设置全链接 */
    setUrlAll = (url) => {
        if(!url.includes('https://')) {
            let flag = `https://${this.props.pubName}.${PUB_TO_ENDPOINT[this.props.pubName]}/`;
            url = flag + url;
        }
        return url;
    }

    /** 通过链接输入的形式确定所选视频，需要校验所输入视频是否在所指定pubName的仓库中 */
    handleUrlInput = async e => {
        let value = this.setUrlAll(e.target.value);
        let flag = `${this.props.pubName}.${PUB_TO_ENDPOINT[this.props.pubName]}`;
        let flag2 = 'news.sinture.com';             // 这是新的视频的公共仓库，也能添加
        let flag3 = 'course.sinture.com';
        if (!value.includes(flag) && !value.includes(flag2) && !value.includes(flag3)) {                                    // 不在指定仓库中
            this.setState({ urlInput: value, urlValid: false });
            return message.error('公考阿里云OSS公共仓库中没有该复制链接');
        }
        if (this.checkIsPrivate(value)) {                               // 说明在私有仓库中，那么此时需要获取可播放链接
            let key = this.getKeyFromUrl(value);
            let canPlayUrl = await getAvailableVideoUrl(key);
            this.setState({ urlInput: canPlayUrl, urlValid: true });
            return;
        }
        this.setState({ urlInput: value, urlValid: true });
    };

    handleVideoError = () => {
        this.setState({ urlValid: false });
    };

    handleSelectTab = (key) => {
        this.setState({ tabKey: key });
    };

    /** 通过url筛选出key，这个key就是路径 */
    getKeyFromUrl = url => {
        if (!url) {
            message.warning('不存在输入链接');
            return '';
        }
        let mark = 'aliyuncs.com/';
        let sintureMark = 'sinture.com/'
        let bi = url.indexOf(mark);
        let ci = url.indexOf(sintureMark);
        let ei = url.indexOf('?');
        if (bi === -1 && ci === -1) {
            message.error('复制链接不在公考阿里云OSS仓库中');
            return '';
        }
        let key = '';
        if (ei === -1) {
            if (bi != -1) {
                key = url.slice(bi + mark.length);
            } else {
                key = url.slice(ci + sintureMark.length)
            }
        } else {
            if (bi != -1) {
                key = url.slice(bi + mark.length, ei);
            } else {
                key = url.slice(ci + sintureMark.length, ei);
            }
        }
        key = decodeURIComponent(key);
        return key;
    }

    /** 点击确定按钮和父组件进行交互 */
    confirm = () => {
        if (this.state.tabKey == 1) {                   // 文件夹形式选择了所需要的视频
            let theRightVal = this.state.selectedVideo[this.props.videoKey || 'url'].trim();
            let size = this.state.selectedVideo.size;
            size = size / 1024;
            size = Math.ceil(size);
            this.props.getSize(size);
            this.props.feedBack(theRightVal);
        } else if (this.state.tabKey == 3) {            // 复制链接形式
            let val = this.state.urlInput.trim();
            // news.sinture.com公共仓库是直接粘贴的，没有size信息，也要让其通过校验
            let size = this.state.selectedVideo && this.state.selectedVideo.size || 0;
            size = size / 1024;
            size = Math.ceil(size);
            if (this.props.videoKey === 'key') {
                val = this.getKeyFromUrl(val);
            }
            this.props.feedBack(val);
            this.props.getSize(size);
        } else if (this.state.tabKey == 2) {            // 上传视频形式
            this.props.feedBack(this.props.uploadedUrl.trim());
        }
        this.setState({ urlInput: "", urlValid: false });
        this.closeModalHandler();
    };

    /** 调用父组件方法关闭浮层 */
    closeModalHandler = () => {
        if (this.refs.videoJs) {
            if (Object.prototype.toString.call(this.refs.videoJs.pause) === "[object Function]") {
                this.refs.videoJs.pause();
            }
        }
        this.props.closeModal();
    }

    handleSearch = (val) => {
        let { queryList } = this.state
        let query = queryList[queryList.length - 1]
        this.props.dispatch(fetchVideos({ marker: null, prefix: this.props.prefix }, this.props.pubName, val || query));
    }

    setQuery = e => {
        let { queryList } = this.state
        queryList[queryList.length - 1] = e.target.value
        this.setState({ queryList })
    }

    nextPage = () => {
        let { queryList } = this.state
        let query = queryList[queryList.length - 1]
        this.props.dispatch(video_picker_next_page(this.props.marker, this.props.prefix, this.props.pubName, query.trim()))
    }

    /** 判断是否是音频文件 */
    checkIsAudio = name => {
        if (!name) return false;
        return name.includes('.mp4') || name.includes('.MP4') || name.includes('.rmvb') || name.includes('.avi') || name.includes('.mp3') || name.includes('flv');
    }

    /** 判断是否私有库 */
    checkIsPrivate = url => {
        if (!url) return false;
        return url.includes('gk-course') || url.includes('gk-test-course') || url.includes('course.sinture.com');
    }

    /** 预览资源文件 */
    previewSource = source => {
        if (!source || !source.name) return;
        if (source.url && this.checkIsPrivate(source.url)) {                        // 说明是私有库
            if (source.name.includes('.png') || source.name.includes('.jpeg')) {
                return <Button type="primary" onClick={() => this.seeMovie(source, false)}>查看图片内容</Button>
            } else if (this.checkIsAudio(source.name)) {
                return <Button type="primary" onClick={() => this.seeMovie(source)}>查看视频内容</Button>
            }
        } else {
            if (source.name.includes('.png') || source.name.includes('.jpeg')) {                 // 常见图片格式
                return <img alt="img" src={source.url} className="img-preview" />
            } else if (this.checkIsAudio(source.name)) {                                                                            // 默认video，毕竟这是video组件而且音频文件也适合
                return <video width="200px" ref={this.videoJs} src={source.url} controls="controls" className="img-preview" />
            }
        }
    }

    /** 点击查看视频内容按钮，第二个参数表明是否是视频 */
    seeMovie = async (source, isVideo = true) => {
        if (!source || !source.key) return;
        let url = isVideo ? await getAvailableVideoUrl(source.key) : source.url;
        this.setState({ seeVideoModal: true, seeVideoUrl: url, isVideo });
    }

    /** 关闭视频播放 */
    closeVideoModal = () => {
        this.setState({ seeVideoModal: false, seeVideoUrl: '', isVideo: true });
    }

    toLastLevel = () => {
        let { queryList } = this.state;
        queryList.pop()
        this.setState({ queryList })
        // 当前 prefix 的倒数第二个 '/' 的位置
        let lastIndexOf = this.props.prefix.lastIndexOf("/", this.props.prefix.length - 2);
        this.props.dispatch(fetch_content_of_video_directory(this.props.prefix.substring(0, lastIndexOf + 1), this.props.pubName, queryList[queryList.length - 1]))
    }

    intoDir = (p) => {        
        let { queryList } = this.state;
        queryList.push('')
        this.setState({ queryList });        
        this.props.dispatch(fetch_content_of_video_directory(p, this.props.pubName, queryList[queryList.length - 1]))
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let { prefixes, bucketType } = this.props;
        let columns = [
            {
                title: '文件名',
                width: '20%',
                render: (text, record) => (
                    <div>
                        {record.key ?
                        <div className="link" onClick={() => this.selectVideo(record.url)}>{record.name || ''}</div>
                        :<div className="link" onClick={() => this.intoDir(record || '')}>{record || ''}</div>
                        }
                    </div>
                ),
            },
            {
                title: '预览',
                width: '20%',
                render: (text, record) => (
                    <div>
                        {this.previewSource(record)}
                    </div>
                ),
            },
            {
                title: '文件类型',
                width: '15%',
                dataIndex: 'type',
            },
            {
                title: '文件大小',
                width: '15%',
                dataIndex: 'size',
                render: (text, record) => (
                    <div>{record.size && this.transformSize(record.size)}</div>
                ),
            },
            {
                title: '上传时间',
                width: '15%',
                dataIndex: 'time',
            },
            {
                title: '操作',
                width: '15%',
                render: (text, record) => <Paragraph copyable={{ text: record.url }}>复制外链</Paragraph>,
            },
        ];
        return columns;
    }

    /** 文件夹形式渲染出视频列表数据 */
    _renderMediaLibrary = () => {
        let { videos, isFetching, prefixes } = this.props;
        let dataSource = prefixes.concat(...videos);

        return (
            <div className="media-library">
                <Table
                    columns={this.generateTableColumns()}
                    dataSource={dataSource}
                    rowKey={record => record.key ? record.key : record}
                    loading={isFetching}
                    pagination={false}
                />
            </div>
        )
    };

    /** 上传视频这一个tab的UI渲染内容 */
    _renderUploadPanel = () => {
        return (
            <div className="upload-panel" id="video-picker-modal">
                <div className="tips">
                    注意：为了使您上传的课程能够在视频库里面更方便的找到，推荐您的文件的命名方式形如：课程名-视频名称<br />
                    如果您采用上述推荐的格式命名的话，那么您可以在媒体库中{this.props.uploadRootDir}中找到
                </div>
                <div id="container">
                    <input
                        type="file" className="btn btn-default"
                        onChange={this.onUploadFile} ref="upload"
                    />
                </div>
                {
                    this.props.isSending &&
                    '加载中...'
                }
                {
                    this.props.uploadedUrl &&
                    <video src={this.props.uploadedUrl} id="uploadPreview" controls="controls" />
                }
                { this.props.uploadedUrl && <Paragraph style={{display: 'inline-block', verticalAlign: 'top', marginTop: '16px'}} copyable={{ text: this.props.uploadedUrl }}>复制视频链接</Paragraph> }                
            </div>
        )
    };

    _renderUrlPanel = () => {
        return (
            <div className="url-panel">
                <Input placeholder="链接或者目录" className="form-control mb10" onChange={this.handleUrlInput} />
                <p>当前对应仓库: {this.props.pubName || '未知'}</p>
                <p>视频链接仓库说明: gk-course:(1.课时课程 2.试题解析) gk-news:(1.课程banner 2.TV)</p>
                <video
                    width={'400px'}
                    src={this.state.urlInput}
                    style={{ display: this.state.urlValid ? "block" : "none" }}
                    onError={this.handleVideoError}
                    controls="controls"
                />
            </div>
        )
    };

    /** 增加一个判断是上传到公开库还是私有库，以及上传到库里面文件夹的名称，文件名的话需要上传者自己命名好 */
    onUploadFile = () => {
        this.props.dispatch(videoPickerUploadVideo(this.refs.upload.files[0]), this.props.uploadDirName);
    };

    render() {
        let { queryList } = this.state;
        let query = queryList[queryList.length - 1]
        return (
            <div>
                <Modal
                    title="插入视频"
                    className={'video-picker'}
                    width={900}
                    visible={this.props.showModal}
                    onCancel={this.closeModalHandler}
                    footer={null}
                >
                    <Tabs defaultActiveKey="1" onChange={this.handleSelectTab} type="card">
                        <Tabs.TabPane key="1" tab="视频库">
                            <div style={{
                                'margin': '10px 10px 0',
                                'display': 'flex',
                                'justifyContent': 'flex-end'
                            }}>

                                <Input placeholder="搜索当前目录文件" value={query} onChange={this.setQuery} onPressEnter={e => this.handleSearch(e.target.value)} />
                                <Button onClick={e => this.handleSearch()} type="primary">搜索</Button>
                            </div>
                            {this._renderMediaLibrary(false)}
                        </Tabs.TabPane>
                        {
                            this.props.uploadRootDir &&
                            <Tabs.TabPane key="2" tab="上传视频">
                                {this._renderUploadPanel()}
                            </Tabs.TabPane>
                        }
                        <Tabs.TabPane key="3" tab="复制链接">
                            {this._renderUrlPanel()}
                        </Tabs.TabPane>
                    </Tabs>
                    <div className="footer-wrap">
                        <div className="page-btns">
                        <Button
                            style={{
                                'width': '30%',
                                'marginRight': 24,
                                'visibility': (this.props.prefix.length > 0) ? 'visible' : 'hidden'
                            }}
                            onClick={this.toLastLevel}>返回上一级</Button>
                            <Button
                                style={{
                                    'width': '50%',
                                    'marginRight': 24,
                                    'visibility': (this.state.tabKey == 1 && this.props.markers.length >= 1) ? 'visible' : 'hidden'
                                }}
                                type="primary"
                                onClick={() => {
                                    if (this.props.markers.length < 2) {
                                        this.props.dispatch(fetchVideos({ prefix: this.props.prefix }, this.props.pubName, query));
                                        this.props.dispatch(reset_video_markers());
                                    } else {
                                        this.props.dispatch(video_picker_last_page(this.props.markers[this.props.markers.length - 2], this.props.prefix, this.props.pubName, query));
                                    }
                                }}>
                                上一页
                        </Button>

                            <Button
                                style={{
                                    'width': '50%',
                                    'visibility': (this.state.tabKey == 1 && this.props.marker && (this.props.videos.length !== 0 || this.props.prefixes.length !== 0)) ? 'visible' : 'hidden'
                                }}
                                type="primary"
                                onClick={this.nextPage}>
                                下一页
                        </Button>
                        </div>
                        <div className="confirm-btns">
                            <Button
                                type="primary"
                                onClick={this.confirm}
                                disabled={!((this.state.tabKey == 1 && this.state.selectedVideo) ||
                                    (this.state.tabKey == 2 && this.props.uploadedUrl) ||
                                    (this.state.tabKey == 3 && this.state.urlValid) ||
                                    (this.state.tabKey == 4 && this.state.selectedVideo))}>
                                确定
                        </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="预览"
                    visible={this.state.seeVideoModal}
                    footer={null}
                    closable={false}
                    width="540"
                    onCancel={this.closeVideoModal}
                >
                    {
                        this.state.isVideo ?
                            <video ref="video" style={{ width: 500, height: 300 }} src={this.state.seeVideoUrl} controls="controls" />
                            :
                            <img alt="img" src={this.state.seeVideoUrl} style={{ width: 500, height: 300 }} />
                    }
                </Modal>
            </div>
        )
    }


}

function mapStatetoProps(state) {
    const {
        isFetching,
        items: videos,
        isSending,
        marker,
        markers,
        prefixes,
        prefix,
        uploadedUrl,
    } = state.videoPicker;

    return {
        isFetching, videos, isSending, marker, markers, prefixes, prefix, uploadedUrl,
    }
}

export default connect(mapStatetoProps)(VideoPicker);
