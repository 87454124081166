import {
  SET_REDUX_KEY_VALUE,
  RESET_FILTER_VALUES,
  UPDATE_ORDER_ITEM_VALUE
} from './action';

/** 这是筛选状态 */
const searchParams = {
  endTime: null,                               // 订单结束时间
  goodsName: null,                             // 商品名称
  isSendOut: null,                             // 是否发货
  logisticsNo: null,                           // 物流单号
  outTradeNo: null,                            // 订单号
  page: 1,                                     // 第几页
  pageSize: 10,                                // 每页多少条
  startTime: null,                             // 订单起始时间
  status: null,                                // 订单状态
  userName: null,                              // 客户名
  minAmount: null,                             //最低价格
  maxAmount: null,                             //最高价格
  orderMobile: null,                           //手机号码              
};

const initialState = {
  ...searchParams,
  productOrdersVO: {
    content: [],
    totalElements: 1,
  },                                           // 商品订单列表数据
  loadingList: false,                          // 是否处于加载状态
};

export const goodsOrderList = (prevState=initialState, action) => {
  switch(action.type) {
    case SET_REDUX_KEY_VALUE:
      return { ...prevState, [action.key]: action.value };
    case RESET_FILTER_VALUES:
      return { ...prevState, ...searchParams };
    case UPDATE_ORDER_ITEM_VALUE:
      let { idx, key, value } = action;
      let prevOrderList = prevState['productOrdersVO'].content || [];
      prevOrderList[idx][key] = value;
      return { ...prevState, productOrdersVO: { ...prevState.productOrdersVO, content: prevOrderList } };
    default:
      return prevState;
  }
}