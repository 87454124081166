import {
    TO_GET_DATA,
    BEGIN_FETCH
} from './actions';


const initialState = {
    responseObj: {},
    loading: false,
}

export function interviewStudentDetail (state=initialState, action) {
    switch (action.type) {
        case TO_GET_DATA:
            return Object.assign({}, state, { responseObj: action.data, loading: false });
        case BEGIN_FETCH:
            return Object.assign({}, state, { loading: true })
        default:
            return state;
    }
}