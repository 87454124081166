import React from 'react';
import { Tabs } from 'antd';
import EwmList from './ewm';
import Teacher from './teacher';
import { Empty } from 'antd';
import { checkPermission } from '../../utils/utils';

const { TabPane } = Tabs;

export default class Operate extends React.Component {

    render() {
        return (
            <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                <TabPane tab="微信二维码" key="1">
                    {checkPermission('sys:qrcode:query') && <EwmList /> || <Empty description={'无权限'} />}
                </TabPane>
                <TabPane tab="老师配置" key="2">
                    {checkPermission('gk:tea:see') && <Teacher /> || <Empty description={'无权限'} />}
                </TabPane>
            </Tabs>
        )
    }
}
