import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Checkbox, Popover, Menu, Dropdown, Button, Input, Table, message, Tooltip, DatePicker, Progress, Spin, Modal, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons'
import {
    checkExercise,
    fetchExerciseList,
    setExerciseListBySearch,
    setExerciseList,
    updateClassTests,
    searchClassTests,
    setValue,
    createExercisePdf,
    createShenlunPdf,
    receive_exercise_list
} from './actions';
import { uploadPdfFile2Oss, uploadPdfUrl2Server } from '../ExamineList/action';
import { reset_all_data } from '../ClassTestInfo/actions';
import moment from 'moment';
import { checkPermission, getSearchQuery } from '../../utils/utils';
import './style.scss';

const { Paragraph } = Typography;

class ClassExercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indeterminate: false,   // 半选状态
            checkAll: false,        // 全选状态
            selId: [],              // 已选id
            sel: [],                // 已选练习列表
            rankTitle: '',
            searchText: '',// 填入的搜索标题
            filterDropdownVisible: false,
            copyVisible: false,//复制新测验弹窗默认关闭
        };
    }

    /** 判断当前登录角色是否为工作人员 */
    isSinturer = () => {
        return true;
    }

    componentDidMount() {
        this.props.dispatch(setValue('searchPage', 1));
        this.fetchExerciseListHandler();
        this.generateBaseUrl();
        this.getSearchData();
        // 页面渲染需要时间，加一个定时器执行准确一点
        setTimeout(()=>this.setScrollTop(), 500);
    }

    /** 页面注销需要记录滚动条位置，用于返回页面回到上次滚动条位置 */
    componentWillUnmount() {
        localStorage.removeItem('pasteId');
        this.props.dispatch(setValue('lastClassId', this.lastClassId));
        this.props.dispatch(setValue('lastScrollTop', this.lastScrollTop));
    }

    /** 实时监听页面滚动条位置 */
    setScrollTop = () => {
        this.lastClassId = getSearchQuery('classId');
        // 判断是否是上次页面，用于回到滚动条位置
        let isCurrent = ( this.lastClassId == this.props.lastClassId);
        let top = document.querySelector('.site-layout-background');
        top.scrollTop = isCurrent && this.props.lastScrollTop || 0;
        top.addEventListener('scroll',() => {
            // 已选底部控制显示
            if(top.scrollTop>400) {
                document.querySelector('.back') && (document.querySelector('.back').style.display ='block');
            } else {
                document.querySelector('.back') && (document.querySelector('.back').style.display ='none');
            }
            this.lastScrollTop = top.scrollTop;
        });
    }    

    generateBaseUrl = () => {
        let url = 'https://api-gk.sinture.com';
        let location = window.location.href;
        if (location.match('gk.sinture')) {
            url = 'https://api-gk.sinture.com';
        } else if (location.match('127.0.0.1') || location.match('localhost')) {
            url = 'https://api-test.sinture.com';
        } else {
            url = 'https://api-gk.sinture.com';
        }
        this.baseUrl = url;
    }

    // 获取班级练习列表数据
    fetchExerciseListHandler = () => {
        let params = {
            page: this.props.activePage,
            pageSize: this.props.pageSize,
            id: getSearchQuery('classId'),
            type: Number(getSearchQuery('classType')),
        };
        this.props.dispatch(fetchExerciseList(params));
    }

    // 搜索班级练习列表数据
    getSearchData = () => {
        let obj = {};
        this.props.searchPage && (obj.page = this.props.searchPage);
        this.props.searchPageSize && (obj.pageSize = this.props.searchPageSize);
        this.props.title && (obj.title = this.props.title);
        this.props.startTime && (obj.startTime = this.props.startTime);
        this.props.endTime && (obj.endTime = this.props.endTime);
        obj.exerciseType = getSearchQuery('classType');
        this.props.dispatch(searchClassTests(obj));
    }

    searchExamine = async (key, val) => {
        key && await this.props.dispatch(setValue(key, val))
        await this.props.dispatch(setValue('searchPage', 1));
        this.getSearchData();
    }

    handleSelect = async page => {
        await this.props.dispatch(setValue('searchPage', page));
        this.getSearchData();
    };

    handleRankTitle = (event) => {
        let rankTitle = event.target.value.trim();
        rankTitle = window.encodeURI(rankTitle);
        this.setState({ rankTitle });
    }

    copyExercise = (id) => {
        let classType = getSearchQuery('classType');
        this.props.history.push({
            pathname: `/classTestInfo/${id}/${classType}`,
            state: {
                type: 'copy'
            }
        });
    }

    /** 输入的需要进行查找的标题发生变化 */
    onInputChange = event => {
        let value = event.target.value;
        this.setState({ searchText: value });
    }

    /** 根据用户所输入的标题进行筛选 */
    onSearch = () => {
        const { searchText } = this.state;

        let originConst = [...this.props.originExerciseList];
        let newExerciseList = originConst.filter(record => record.title.indexOf(searchText) !== -1);

        this.props.dispatch(setExerciseListBySearch(newExerciseList));

        this.setState({
            filterDropdownVisible: false,
            filtered: !!searchText
        });
    }

    /** 清空搜索 */
    resetInlineSearch = async () => {
        await this.setState({ searchText: '' });
        this.onSearch();
    }

    /** 分页器每页可显示数目变化时调用，每页大小改变的时候自动跳转到第一页 */
    onShowSizeChangeHandler = async (current, size) => {
        await this.props.dispatch(setValue('searchPage', 1));
        await this.props.dispatch(setValue('searchPageSize', size));
        this.getSearchData();
    }

    /** 点击添加班级练习的时候初始化一下班级练习详情页面的数据 */
    gotoClassExerciseRelease = async () => {
        await this.props.dispatch(reset_all_data());
        let classId = getSearchQuery('classId');
        let classType = getSearchQuery('classType');
        this.props.history.push(`/classTestRelease/${classId}/${classType}`)
    }

    /** 输入练习的序号, 参数fromIndex表示的意思是原先顺序 */
    inputOrderHandler = async (e, fromIndex) => {
        if (e.keyCode == 13) {
            let toIndex = parseInt(e.target.value) - 1;
            e.target.value = '';
            let items = this.props.exerciseList || [];
            items = items.slice(0);
            if (toIndex > (items.length - 1)) {
                message.warning('不能超过当前练习数量');
                return;
            }
            if (toIndex < 0) {
                message.warning('不能小于1');
                return;
            }
            let target = items.splice(fromIndex, 1)[0];         // 当前索引对对应的练习
            items.splice(toIndex, 0, target);                   // 往目标位置插入该练习
            let a = []; // id顺序会影响排序
            items.map(i=>a.push(i.id));
            await this.props.dispatch(setValue('selArr', a));
            await this.props.dispatch(setExerciseList(items));
        }
    }

    /** 跳转到班级测试详情页面 */
    gotoClassTestDetail = async id => {
        let classType = getSearchQuery('classType');
        let url = `/classTestInfo/${id}/${classType}`;
        this.props.history.push(url);
    }

    selectTime = (value, id, idx) => {
        let exerciseList = [...this.props.exerciseList];
        exerciseList[idx]['publishDate'] = value == "" ? undefined : value;
        this.props.dispatch(receive_exercise_list({ content: exerciseList }));
    }

    /** 确定上传PDF按钮的类型以及文字描述 */
    makeSureBtnTypeAndNames = (record) => {
        let result = null;
        result = record.pdf ? { type: 'primary', name: '选择', show: true } : { type: 'primary', name: '上传', show: false };
        return result;
    }

    /** 确定传给/classExercise/uploadPdf接口的参数 */
    generatePostParams = (url, examineId) => {
        const examineType = 5;
        if (!url) { message.warn('文件的OSS链接不合法'); return false; }
        if (!examineId) { message.warn('未知班级ID'); return false; }
        return { examineId, examineType, url };
    }



    /** 确定文件名，第一点：文件名不交给上传者决定，否则容易出现同名文件被覆盖的情况；第二点：为了便于管理，文件名和试卷名尽量保持一致（在长度不会超出浏览器长度限制的情况下），然后加个试卷ID表示唯一 */
    /** 第三点：每次上传操作都应该确保之前的文件还是可用的，所以这里再加个时间戳 */
    generateFileName = record => {
        let examTitle = record.title;
        const id = record.examineId || record.id;
        // const ts = new Date().toLocaleString();            // 把时间戳去掉，不考虑同时读写的问题(这种情况很少发生，manager给的方案)
        examTitle = examTitle.substr(0, 220);                 // 避免过长
        let result = examTitle + '_' + id + '.pdf';
        result = result.replace(/\//g, '_');                  // 避免因为/导致的出现多个文件目录的问题
        return 'pdf_upload_by_background/class_exercise/' + result;
    }

    /** 监听文件变化 */
    handleFileValueChange = async (event, record) => {
        const examineId = record.id || record.examineId;
        const files = event && event.target && event.target.files;
        if (!files || !files.length) { message.warn('请先选择文件'); return; }
        this.setState({ uploading: true });
        const file = files[0];
        if (!file || !file.name || !file.name.includes('pdf')) { message.warn('请确保您上传的文件是以.pdf结尾的文件格式'); return; }
        const fileName = this.generateFileName(record);
        const ossUrl = await uploadPdfFile2Oss.call(this, fileName, file, this.successUploadCb);
        const obj = this.generatePostParams(ossUrl, examineId);
        if (!obj) return;
        await uploadPdfUrl2Server.call(this, obj);
        this.fetchExerciseListHandler();
        this.getSearchData();
    }

    /** 点击查看PDF文件 */
    showPdfBtnClickHandler = record => {
        const pdfUrl = record.pdf;
        if (!pdfUrl) return;
        window.open(pdfUrl);
    }

    /** 上传成功的callback */
    successUploadCb = () => {
        this.setState({
            uploading: false,
            percent: 0,
        });
    }

    /** 设置缓存练习列表 */
    setClassID = () => {
        let exerciseList = this.props.exerciseList;
        let classArr = [];
        exerciseList.length && exerciseList.map(item => {
            classArr.push({ 'id': item.id, 'title': item.title, 'num': item.totalQuestion });
        })
        localStorage.setItem('classExerciseArr', JSON.stringify(classArr));
    }

    /** 跳转申论学员列表页面 */
    goShenlunList = (record) => {
        this.setClassID();
        let savePath = {
            classId: getSearchQuery('classId'),
            classType: getSearchQuery('classType'),
            classNo: getSearchQuery('classNo'),
            isShuaTi: getSearchQuery('isShuaTi')
        }
        window.localStorage.setItem('savePath', JSON.stringify(savePath));
        let url = `/shenlunDetail?type=${getSearchQuery('classType')}&id=${getSearchQuery('classId')}&exerciseId=${record.id}`;
        this.props.history.push(url);
    }

    /** 点击生成PDF文件 */
    createPdf = async (record) => {
        let type = 5;
        let examineId = record.id;
        this.setState({ posting: true });
        let exerciseType = getSearchQuery('classType');
        if (exerciseType == 1) {
            await createShenlunPdf(exerciseType, examineId);
        } else if (exerciseType == 0){
            await createExercisePdf(type, examineId);
        } else if (exerciseType == 3){
            await createShenlunPdf(exerciseType, examineId);
        } else if (exerciseType == 4){
            await createShenlunPdf(exerciseType, examineId);
        } else if (exerciseType == 5){
            await createShenlunPdf(exerciseType, examineId);
        } 
        this.setState({ posting: false });
        this.fetchExerciseListHandler();
        this.getSearchData();
    }

    /** 点击复制新测验打开确认弹框 */
    openCopy = (id) => {
        this.copyId = id;
        this.setState({ copyVisible: true });
    }
    /** 确认复制新测验 */
    handleOk = () => {
        this.setState({ copyVisible: false });
        this.copyExercise(this.copyId);
    }
    /** 取消复制新测验 */
    handleCancel = () => {
        this.setState({ copyVisible: false });
    }

    getType = () => {
        let exerciseType = getSearchQuery('classType');
        if(exerciseType == 1) {
            return checkPermission('shenlun:pdf:create');
        } else if(exerciseType == 0) {
            return checkPermission('exam:pdf:create');
        } else if(exerciseType == 3) {
            return checkPermission('gj:exam:pdf');
        } else if(exerciseType == 4) {
            return checkPermission('zc:exam:pdf');
        } else if(exerciseType == 5) {
            return checkPermission('zy:pdf:create');
        }
    }

    /** 生成ant table 的配置信息 */
    generateColumns = () => {
        let exerciseType = getSearchQuery('classType');
        let classNo = getSearchQuery('classNo');
        let classId = getSearchQuery('classId');
        let columns = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                width: '11%',
                fixed: 'left',
                render: (text, record) => {
                    return (<Tooltip title={text}>
                        {checkPermission('clazz:exercise:info') && <span onClick={() => this.gotoClassTestDetail(record.id)} className="clickSpan">{record.title}</span> || <span>{record.title}</span>}
                    </Tooltip>)
                },
                filterDropdown: (
                    <div className="commonSearchBox">
                        <Input
                            ref={ele => this.searchInput = ele}
                            placeholder="请输入需要进行查找的标题"
                            value={this.state.searchText}
                            onChange={this.onInputChange}
                            onPressEnter={this.onSearch}
                            className="itemSearchBox"
                        />
                        <Button type="primary" onClick={this.onSearch} className="mr5">搜索</Button>
                        <Button type="danger" onClick={this.resetInlineSearch}>清空搜索</Button>
                    </div>
                ),
                filterIcon: <SearchOutlined />,
                filterDropdownVisible: this.state.filterDropdownVisible,
                onFilterDropdownVisibleChange: (visible) => {
                    this.setState({
                        filterDropdownVisible: visible,
                    }, () => this.searchInput.focus());
                }
            },
            {
                title: '创建时间',
                width: '6%',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                render: (text, record) => record.gmtCreate && record.gmtCreate.split(' ')[0],
            },
            {
                title: 'h5链接',
                width: '5%',
                render: (text, record) => {
                    return (
                        <Tooltip title={'链接2用户不用登录,答题无任何限制,做题数据不提交服务器,无班级排名;广告是跳转拼多多购买'}>
                            <Dropdown 
                                placement="bottomRight"
                                overlay={
                                    record.isShuati == 1 ?
                                    <Menu>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiIndex.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&examTitle=${this.state.rankTitle}&isShuaTi=true&classNo=${classNo}` }}>刷题班链接1-有广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiLocal.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&examTitle=${this.state.rankTitle}&isShuaTi=true&classNo=${classNo}` }}>刷题班链接2-有广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiIndex.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&examTitle=${this.state.rankTitle}&isShuaTi=true&classNo=${classNo}&pdd=0` }}>刷题班链接1-无广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiLocal.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&examTitle=${this.state.rankTitle}&isShuaTi=true&classNo=${classNo}&pdd=0` }}>刷题班链接2-无广告</Paragraph>
                                        </Menu.Item>
                                    </Menu>
                                    :
                                    <Menu>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiIndex.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}` }}>链接1-有广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiLocal.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}` }}>链接2-有广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiIndex.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&pdd=0` }}>链接1-无广告</Paragraph>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/shuatiLocal.html?classType=${exerciseType}&examId=${record.id}&classId=${classId}&pdd=0` }}>链接2-无广告</Paragraph>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <div>复制链接</div>
                            </Dropdown>
                        </Tooltip>
                    );
                }
            },
            {
                title: '限制时间',
                width: '5%',
                dataIndex: 'limitTime',
                key: 'limitTime'
            },
            {
                title: '总分数',
                width: '4%',
                dataIndex: 'totalPoint',
                key: 'totalPoint',
            },
            {
                title: '总题数',
                width: '4%',
                dataIndex: 'totalQuestion',
                key: 'totalQuestion'
            },
            {
                title: '查看排名',
                width: '5%',
                render: (text, record) => (checkPermission('clazz:exercise:info') && <Link to={`/exerciseRank?id=${record.id}&classId=${getSearchQuery('classId')}&title=${record.title}`}>查看排名</Link>)
            },
            {
                title: '序号',
                width: '3%',
                render: (text, record, index) => <span>{index + 1}</span>
            },
            {
                title: '移动',
                width: '5%',
                render: (text, record, index) => (
                    <div>
                        <Input type="number" min={1} onKeyUp={event => this.inputOrderHandler(event, index)} placeholder="序号" />
                    </div>
                )
            },
            {
                title: '日期',
                width: "10%",
                render: (text, record, index) => (
                    <DatePicker
                        value={record.publishDate ? moment(record.publishDate, 'YYYY-MM-DD') : ''}
                        onChange={(mmt, value) => this.selectTime(value, record.id, index)} />
                )
            },
            {
                title: '复制',
                width: '6%',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('clazz:exercise:gkfz') && 
                                <div>
                                    <span className="ueSpan" onClick={() => this.openCopy(record.id)}>复制新测验</span>
                                </div>
                        }
                    </div>
                )
            },
            {
                title: 'PDF管理',
                width: '8%',
                render: (text, record) => {
                    let pdfObj = this.makeSureBtnTypeAndNames(record);
                    return (
                        <div>
                            <div>
                                {this.getType() && <Button size="small" className="pdfButton" type="primary" onClick={() => this.createPdf(record)}>
                                    <span className="btnName">{pdfObj.show ? '更新' : '生成'}</span>
                                </Button>}
                            </div>
                            {checkPermission('exam:pdf:upload') && <Button  size="small" className="pdfButton" type="primary" >
                            <span className="btnName">上传</span>
                            <input className="opacityInput" type="file" onChange={event => { this.handleFileValueChange(event, record); }} accept=".pdf" />
                            </Button>}
                            { checkPermission('clazz:exercise:gkyl') && pdfObj.show && <Button style={{marginLeft: '5px'}} size="small" type="link" onClick={() => this.showPdfBtnClickHandler(record)}>预览</Button>}
                        </div>
                    )
                }
            },
            {
                title: '批改',
                width: '5%',
                render: (record) => {
                    return (
                        checkPermission('clazz:slzy:info') && <span className="clickSpan" onClick={() => { this.goShenlunList(record) }}>批改</span>
                    )
                }
            }
        ];
        if (exerciseType == 1 || exerciseType == 5) {
            for (let columnIdx in columns) {
                if (columns[columnIdx].title == 'h5链接') {
                    columns.splice(columnIdx, 1);
                };
                if (columns[columnIdx].title == '限制时间') {
                    columns.splice(columnIdx, 1);
                };
                if (columns[columnIdx].title == '总分数') {
                    columns.splice(columnIdx, 1);
                };
                if (columns[columnIdx].title == '查看排名') {
                    columns.splice(columnIdx, 1);
                };
            }
        } else {
            for (let columnIdx in columns) {
                if (columns[columnIdx].title == '批改') {
                    columns.splice(columnIdx, 1);
                };
            }
        }

        return columns;
    }

    /** 生成第二个表格的表格结构 */
    generateColumns2 = () => {
        let exerciseType = getSearchQuery('classType');
        let columns = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (checkPermission('clazz:exercise:info') && <span onClick={() => this.gotoClassTestDetail(record.id)} className="clickSpan">{record.title}</span> || <span>{record.title}</span>),
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                render: (text, record) => record.gmtCreate && record.gmtCreate.split(' ')[0],
            },
            {
                title: '限制时间',
                dataIndex: 'limitTime',
                key: 'limitTime'
            },
            {
                title: '总分数',
                dataIndex: 'totalPoint',
                key: 'totalPoint',
            },
            {
                title: '总题数',
                dataIndex: 'totalQuestion',
                key: 'totalQuestion'
            },
            {
                title: '复制',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('clazz:exercise:gkfz') && 
                                <div>
                                    <span className="ueSpan" onClick={() => this.openCopy(record.id)}>复制新测验</span>
                                </div>
                        }
                    </div>
                )
            },
            {
                title: 'PDF管理',
                render: (text, record) => {
                    let pdfObj = this.makeSureBtnTypeAndNames(record);
                    return (
                        <div>
                            <div>
                                {this.getType() && <Button size="small" className="pdfButton" type="primary" onClick={() => this.createPdf(record)}>
                                    <span className="btnName">{pdfObj.show ? '更新' : '生成'}</span>
                                </Button>}
                            </div>
                            {checkPermission('exam:pdf:upload') && <Button  size="small" className="pdfButton" type="primary">
                            <span className="btnName">上传</span>
                            <input className="opacityInput" type="file" onChange={event => { this.handleFileValueChange(event, record); }} accept=".pdf" />
                            </Button>}
                            {checkPermission('clazz:exercise:gkyl') && pdfObj.show && <Button style={{marginLeft: '5px'}} size="small" type="link" className="showButton" onClick={() => this.showPdfBtnClickHandler(record)}>预览</Button>}
                        </div>
                    )
                }
            }
        ];
        if (exerciseType == 1 || exerciseType == 5) {
            for (let columnIdx in columns) {
                if (columns[columnIdx].title == '限制时间') {
                    columns.splice(columnIdx, 1);
                };
                if (columns[columnIdx].title == '总分数') {
                    columns.splice(columnIdx, 1);
                };
            }
        }
        return columns;
    }

    reorderHandler = () => {
        let exerciseList = this.props.exerciseList;
        let classId = getSearchQuery('classId');
        let type = getSearchQuery('classType');
        if (Object.prototype.toString.call(exerciseList) !== "[object Array]") return;
        let exerciseDates = exerciseList.map(obj => {
            return {
                "examineId": obj.id,
                "publishDate": obj.publishDate
            }
        })
        this.props.dispatch(updateClassTests({ classId, exerciseDates, type }));
    }

    getSelectArrIds = (arr) => (
        arr.length && arr.map(item => (
            item.id
        ))
    )

    /** 已选列表最简单，只能取消不能勾选，所见即所得 */
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.props.dispatch(setValue('selArr', selectedRowKeys));        
        this.props.dispatch(setExerciseList([...selectedRows]));
    };

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {        
        let exerciseList = this.props.exerciseList;
        let quesArr = this.props.selArr;
        let classId = getSearchQuery('classId');
        selected && this.props.dispatch(checkExercise(record.id, classId));//检验该班级测试在该班级是否有人做过
    
        if (selected) {
            exerciseList.push(record);
            quesArr.push(record.id);
        } else {
            let idx = quesArr.indexOf(record.id);
            quesArr.splice(idx, 1);
            exerciseList.forEach((item, idx, arr) => {
                if (item.id == record.id) {
                    arr.splice(idx, 1)
                }
            });
        }
        this.props.dispatch(setValue('selArr', [...quesArr]));
        this.props.dispatch(setExerciseList([...exerciseList]));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';

    /** 定位到已选试题底部 */
    goToBootom = () => {
        let o = document.querySelector('.site-layout-background');
        let a = document.querySelector('#isSelect');        
        o.scrollTop = a.offsetHeight - 80;
    }

    /** 已选练习的全选与反选 */
    onCheckAllChange = (e) => {
        let s = [];
        this.props.exerciseList.map(i=> s.push(i.id));
        this.setState({
            indeterminate: false,
            checkAll: e.target.checked,
            selId: e.target.checked && s || [],
            sel: e.target.checked && [...this.props.exerciseList] || []
        });
    }

    /** 已选练习的单选 */
    selOnchange=(v)=>{
        let sel = this.props.exerciseList.filter((i)=>v.includes(i.id));
        this.setState({sel, selId: v, checkAll: v.length == this.props.exerciseList.length, indeterminate: !!v.length && v.length < this.props.exerciseList.length});
    }

    /** 一键复制 */
    copyList = () => {
        localStorage.setItem('copyExerciseList', JSON.stringify([...this.state.sel]));
        if(!this.state.sel.length) return message.warn('未勾选');
        message.success('一键复制成功');
        // 记录当前班级不可粘贴自己，行测不能粘贴申论等等
        localStorage.setItem('copyId', getSearchQuery('classId'));
        localStorage.setItem('copyType', getSearchQuery('classType'));
    }

    /** 一键粘贴 */
    paste = () => {
        let {exerciseList, selArr} = this.props;
        let copyExerciseList = JSON.parse(localStorage.getItem('copyExerciseList')) || [];
        if(!copyExerciseList.length) return message.warn('未复制练习');
        if(localStorage.getItem('copyType')!= getSearchQuery('classType')) return message.warn('班级类型不一致，无法粘贴');
        if(localStorage.getItem('copyId')==getSearchQuery('classId')) return message.warn('不能粘贴自己');
        if(localStorage.getItem('pasteId')==getSearchQuery('classId')) return message.warn('已粘贴成功，请勿重复粘贴');
        localStorage.setItem('pasteId', getSearchQuery('classId'));

        let s = [];
        copyExerciseList.map(i=> s.push(i.id));
        let newId = [...new Set([...selArr.concat(s)])];       
        let newList = this.getOnly([...exerciseList.concat(copyExerciseList)]);        
        this.props.dispatch(setValue('selArr', [...newId]));
        this.props.dispatch(setValue('exerciseList', [...newList]));
        message.success('一键粘贴成功');
        // 粘贴成功的数据暂时保留不置空，方便粘贴其他班级
    }

    /** 数组对象去重 */
    getOnly =(tempArr)=>{
        let newArr = [];
        let res = [];
        for (let i = 0; i < tempArr.length; i++) {
            if (newArr.indexOf(tempArr[i].id) == -1) {
                newArr.push(tempArr[i].id);
                res.push(tempArr[i]);
            } else {
                tempArr[i] && tempArr[i].id && message.warn(`"${tempArr[i].title}"粘贴重复已自动去重`, 10);               
            };
        };
        return res;
    }

    render() {
        let { exerciseList, selArr, startTime, endTime } = {...this.props};
        let {indeterminate, checkAll, selId} = this.state;
        const columns = this.generateColumns();
        const columns2 = this.generateColumns2();
        return (
            <div className="classExercisePage">
                <div className="back" onClick={this.goToBootom}>已选底部</div>
                <div className="commonTableTitle topTitle">
                    <span style={{fontSize: '16px', color: 'blue'}}>班级：{getSearchQuery('className')}</span>
                    <div>
                        <Button
                            type="link"
                            onClick={()=>this.paste()}
                        >
                            一键粘贴练习
                        </Button>
                        <Popover
                            content={
                                <>
                                <Checkbox indeterminate={indeterminate} onChange={e=>this.onCheckAllChange(e)} checked={checkAll}>
                                    全选
                                </Checkbox>
                                <Button disabled={!selId.length} type="link" onClick={()=>this.copyList()}>一键复制</Button>
                                <br/>
                                <Checkbox.Group value={selId} onChange={v=>this.selOnchange(v)} style={{maxHeight: '65vh', overflow: 'auto'}}>
                                    {(exerciseList).map((v, i) => <div key={i}><Checkbox value={v.id}>{v.title}</Checkbox></div>)}
                                </Checkbox.Group>
                                </>
                            }
                            title="复制已选练习"
                            trigger="click"
                        >
                            <Button type="link">复制已选练习</Button>
                        </Popover>
                        {checkPermission('clazz:exercise:gkadd') && <Button className="btnLeft" type="primary" onClick={this.gotoClassExerciseRelease}>创建班级练习</Button>}
                        {checkPermission('gkclass:question:edit') && <Button type="primary" onClick={this.reorderHandler}>更新</Button>}
                    </div>
                </div>
                {
                    getSearchQuery('isShuaTi') === '1' &&
                    <div className="commonSearchBox">
                        <label className="itemSearchBox">输入刷题班中排行榜中所需要显示的标题</label>
                        <Input className="itemSearchBox" style={{ width: 500 }} placeholder="请输入刷题班排行榜中所需要显示的标题" onChange={this.handleRankTitle} />
                    </div>
                }

                {/** 上传PDF文件到OSS的时候显示进度 */}
                {
                    this.state.uploading &&
                    <div className="loadingWrapper">
                        <div className="progressWrapper">
                            <Progress type="circle" percent={this.state.percent} />
                        </div>
                    </div>
                }

                {/** 提交PDF链接的时候显示loading动画 */}
                {
                    this.state.posting &&
                    <div className="loadingWrapper">
                        <div className="progressWrapper">
                            <Spin tip="客官别急，任务进行中，请稍等......" />
                        </div>
                    </div>
                }
                <Table
                    id="isSelect"
                    title={() => '已选练习'}
                    rowSelection={{
                        columnWidth: '5%',
                        columnTitle: '选择',
                        selectedRowKeys: selArr,
                        onChange: this.onSelectChange,
                    }}
                    scroll={{ x: 1350 }}
                    columns={columns}
                    dataSource={exerciseList}
                    rowKey={record => record.id}
                    pagination={false}
                />
                
                <div>
                    <div className="commonSearchBox">
                        <div>
                            <label>起始时间</label>
                            <DatePicker
                                style={{ width: 150 }}
                                format="YYYY-MM-DD"
                                value={this.calendarValuePipe(startTime)}
                                onChange={(date, dateString) => { this.searchExamine('startTime', dateString) }}
                            />
                        </div>
                        <div>
                            <label>截止时间</label>
                            <DatePicker
                                style={{ width: 150 }}
                                format="YYYY-MM-DD"
                                value={this.calendarValuePipe(endTime)}
                                onChange={(date, dateString) => { this.searchExamine('endTime', dateString) }}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>搜索标题</label>
                            <Input style={{ width: 400 }} onPressEnter={() => this.searchExamine()} onChange={event => { this.props.dispatch(setValue('title', event.target.value)); }} value={this.props.title} placeholder="请输入班级测试的标题" />
                        </div>
                        <div className="itemSearchBox">
                            <Button disabled={this.props.onSearch} onClick={this.searchExamine} type="primary">搜索</Button>
                        </div>
                        <div>
                            <span style={{ color: 'red' }}>*提示: </span>上传 按钮是手动选择上传本地PDF文件操作，生成/更新 按钮是在线自动生成更新PDF操作
                        </div>
                    </div>
                </div>
                
                <Table
                    title={() => '练习列表'}
                    rowSelection={{
                        columnWidth: '5%',
                        columnTitle: '选择',
                        onSelect: this.onSelect,
                        selectedRowKeys: selArr,
                    }}
                    columns={columns2}
                    dataSource={this.props.searchedExerciseList}
                    rowKey={record => record.id}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: this.props.searchPageSize,
                        current: this.props.searchPage,
                        total: this.props.searchTotalElements,
                        onChange: this.handleSelect,
                        onShowSizeChange: this.onShowSizeChangeHandler,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    loading={this.props.isFetching}
                />

                <Modal
                    title="为什么要复制？"
                    visible={this.state.copyVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>1.请确认新开班级测试的题目是否跟之前已上线的一样？一样的话请复用老的，不要复制！</p>
                    <p>2.或者之前关联班级数超过10个，后台打开较慢，这种情况再复制</p>
                </Modal>

            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.classExercise
    };
}

export default connect(mapStatetoProps)(ClassExercise);
