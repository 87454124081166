import apiRequest from '../../common/request'
import { message } from 'antd';

const PREFIX = "PAPERLIST";

export const COMMON_SET_STATUS = PREFIX + "COMMON_SET_STATUS";
export const RESET_STATE = PREFIX + "RESET_STATE";

/** 公用修改数据方法 */
export function commonSetStatus(key, value) {
    return {
        type: COMMON_SET_STATUS,
        key, value
    }
}

/** 重置方法 */
export function resetState() {
    return {
        type: RESET_STATE,
    }
}

/** 
 *  打造复用接口方法
 * @param type :gj  zc  公基还是职测
 // if(type=='gj') {url = 'gk/ops/gj/historyExamine/page'};
 // if(type=='zc') {url = 'gk/ops/zc/historyExamine/page'};
 */
export function fetchInstitutionPage(type, obj) {
    let url = `/gk/ops/${type}/historyExamine/page`;
    let data = {
        page: obj.page || 1,
        pageSize: obj.pageSize || 10,
    }
    obj.publish!=='' ? data.publish = obj.publish : '';
    obj.query ? data.query = obj.query : '';
    obj.area ? data.area = obj.area : '';
    obj.year ? data.year = obj.year : '';
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return dispatch => {
        dispatch(commonSetStatus('loading', true));
        apiRequest(url, config).then(json => {
            if (json.status == 1) {
                dispatch(commonSetStatus('institutionExamObj', json.obj));
                dispatch(commonSetStatus('loading', false));
            }

        }).catch(error => console.error(error))
    }
}
/** 
 * 打造复用接口方法
 * @param from :gj  zc  接口类型
 * @param type :year  area  年份还是地区接口
 * @param state :需要改变的状态
    // gk/ops/gj/question/year/list //公基年份
    // gk/ops/gj/question/area/list //公基地区
    // gk/ops/zc/question/year/list //职测年份
    // gk/ops/zc/question/area/list //职测地区
 */
export function fetchInstitutionYearOrArea(from, type, state) {
    let url = `/gk/ops/${from}/question/${type}/list`;
    return (dispatch) => {
        apiRequest(url)
            .then(json => {
                dispatch(commonSetStatus(state, (type=='year')&&json.obj.reverse()||json.obj));
            })
            .catch(error => { console.error(error) })
    }
}

/** 
 * @param type gj zc
 * @param id 试卷id
 * @param cb 成功回调
 * gk/ops/gj/historyExamine/publish/${id}
 * gk/ops/zc/historyExamine/publish/${id}
 */
export function fetchInstitutionPublish(type, id, cb) {
    let url = `/gk/ops/${type}/historyExamine/publish/${id}`;
    return (dispatch) => {
        apiRequest(url)
            .then(json => {
                if(json.status==1) { message.success('操作成功'); cb && cb();}
                else {message.error('操作失败')};
            })
            .catch(error => { console.error(error) });
    }
}

/** 
 * @param type gj zc
 * @param examineId 试卷id
 * @param cb 成功回调
 * gk/ops/gj/historyExamine/generatePdf/19/${id}
 * gk/ops/zc/historyExamine/generatePdf/20/${id}
 */
 export function fetchInstitutionPdf(type, examineId, cb) {
    let num = (type=='gj') && 19 || 20;
    let url = `/gk/ops/${type}/historyExamine/generatePdf/${num}/${examineId}`;
    return (dispatch) => {
        dispatch(commonSetStatus('loading', true));
        apiRequest(url)
            .then(json => {
                if(json.status==1) {
                    dispatch(commonSetStatus('loading', false));
                    message.success('生成PDF成功');
                    cb && cb();
                };
            })
            .catch(error => { dispatch(commonSetStatus('loading', false));console.error(error) });
    }
}