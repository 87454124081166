import apiRequest from '../../common/request';

const PREFIX = "SHENLUN_EXERCISE_LIST";

export const SHENLUN_STUDENT_DETAIL = PREFIX + "SHENLUN_STUDENT_DETAIL";
export const SET_FILTER_STATUS = PREFIX + "SET_FILTER_STATUS";

function shenlun_student_detail(data) {
  return {
    type: SHENLUN_STUDENT_DETAIL,
    data
  }
};

export const set_filter_status = data => ({
  type: SET_FILTER_STATUS,
  data
});

export const fetch_shenlun_detail = (params = {}) => {
  let data = {};
  params.id && (data.classId = params.id);
  params.exerciseId && (data.exerciseId = params.exerciseId);
  if (typeof params.status === 'number') {
    data.status = params.status;
  }
  let url = `/gk/ops/class/exercise/details`;
  let config = {
    method: 'POST', credentials: 'include', body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
  }
  return dispatch => {
    apiRequest(url, config)
    .then(json => {
      dispatch(shenlun_student_detail(json.obj));
    })
    .catch(error => console.log(error));
  }
};

