import {
    SET_REDUX_KEY_VALUE,
    RESET_SEARCH_FILTER_VALUE,
    CHANGE_CURRENT_CLASS
} from './actions';

const searchParams = {
    year: null,
    area: null,
    classId: '',
    jobId: null,
    mobile: null,
    page: 1,
    pageSize: 10,
    payStatus: null,
    studentName: null,
    type: null,
};

const initialState = {
    responseObj: {
        content: []
    },                    // 获取学员接口所返回的学员列表数据
    loading: false,
    ...searchParams
};

export function interviewStudent (state=initialState, action) {
    switch (action.type) {
        case SET_REDUX_KEY_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        case RESET_SEARCH_FILTER_VALUE:
            return Object.assign({}, state, { ...searchParams });
        case CHANGE_CURRENT_CLASS:
            return Object.assign({}, state, { classId: action.data });
        default:
            return state;
    }
}
