import apiRequest from '../../common/request'
import {message} from 'antd';

const PREFIX = "CLASS_TEST_INFO_OOO";

export const REQUEST_DAILY_EXAMINE_INFO = PREFIX + "REQUEST_DAILY_EXAMINE_INFO";
export const RECEIVE_DAILY_EXAMINE_INFO = PREFIX + "RECEIVE_DAILY_EXAMINE_INFO";

export const FILL_EXAMINE_TITLE = PREFIX + "FILL_EXAMINE_TITLE";
export const FILL_EXAMINE_LIMIT_TIME = PREFIX + "FILL_EXAMINE_LIMIT_TIME";
export const FILL_EXAMINE_CLASS_ID = PREFIX + "FILL_EXAMINE_CLASS_ID"

export const SELECT_QUESTION = PREFIX + "SELECT_QUESTION";
export const UPDATE_SEL_QUESTION = PREFIX + "UPDATE_SEL_QUESTION";

export const REQUEST_UPDATE_EXAMINE = PREFIX + "REQUEST_UPDATE_EXAMINE";
export const RECEIVE_UPDATE_EXAMINE_RESULT_SUC = PREFIX + "RECEIVE_UPDATE_EXAMINE_RESULT_SUC";
export const RECEIVE_UPDATE_EXAMINE_RESULT_FAIL = PREFIX + "RECEIVE_UPDATE_EXAMINE_RESULT_FAIL";

export const RESET_ALL_DATA = PREFIX + "RESET_ALL_DATA";
export const SET_VALUE = PREFIX + 'SET_VALUE';

export const setValue = (key, value) => ({ type: SET_VALUE, key, value });

function request_examine_info() {
    return {
        type: REQUEST_DAILY_EXAMINE_INFO
    }
}

function receive_examine_info(dailyExamine) {
    return {
        type: RECEIVE_DAILY_EXAMINE_INFO,
        dailyExamine
    }
}

function request_update_examine() {
    return {
        type: REQUEST_UPDATE_EXAMINE
    }
}

function receive_update_examine_result_suc() {
    return {
        type: RECEIVE_UPDATE_EXAMINE_RESULT_SUC
    }
}
function receive_update_examine_result_fail() {
    return {
        type: RECEIVE_UPDATE_EXAMINE_RESULT_FAIL
    }
}

export function fill_examine_title(value) {
    return {
        type: FILL_EXAMINE_TITLE,
        key: 'title',
        value
    }
}

export function fill_examine_limit_time(value) {
    return {
        type:FILL_EXAMINE_LIMIT_TIME,
        key:'limitTime',
        value
    }
}

export function fill_examine_class_id(value) {
    return {
        type:FILL_EXAMINE_CLASS_ID,
        key:'classIdDate',
        value
    }
}

export function select_question(value){
    return {
        type:SELECT_QUESTION,
        value,
    }
}

export function update_sel_question(value){
    return {
        type:UPDATE_SEL_QUESTION,
        value,
    }
}

export const reset_all_data = () => ({ type: RESET_ALL_DATA });

/** 从questions数组里面剥离出questionId数组 */
function getIdsFromQuestion(questions) {
    let arr = [];
    if (!questions) return arr;
    questions.map(quesObj => {
        arr.push(quesObj.id);
    })
    return arr;
}

// 如果是通过复制新测验来到这个页面的话，尽管会携带测验ID，但是复制新测验本身来说，它是新建过程而非修改过程
export function fetchExamine(id, from, classType) {
    return async dispatch => {
        dispatch(request_examine_info());
        apiRequest(`/gk/ops/class/exercise/details/${id}`, { credentials: 'include' }).then(json => {
            let quesArr = [];
            if (classType == 1) {
                quesArr = getIdsFromQuestion(json.obj.slZyQuestionList);
            } else if (classType == 0) {
                quesArr = getIdsFromQuestion(json.obj.questions);
            } else if (classType == 3) {
                quesArr = getIdsFromQuestion(json.obj.questions);
            } else if (classType == 4) {
                quesArr = getIdsFromQuestion(json.obj.questions);
            } else if (classType == 5) {
                quesArr = getIdsFromQuestion(json.obj.slZyQuestionList);
            }
            let questionList = [];
            if (classType == 1) {
                questionList = json.obj.slZyQuestionList;
            } else if (classType == 0) {
                questionList = json.obj.questions;
            } else if (classType == 3) {
                questionList = json.obj.questions;
            } else if (classType == 4) {
                questionList = json.obj.questions;
            } else if (classType == 5) {
                questionList = json.obj.slZyQuestionList;
            }
            let data = {
                title: json.obj.title,
                quesArr: quesArr,               // before is json.obj.quesArr，but does't return in new api
                questions: questionList,
                classIdDate: json.obj.classIdDate,
                limitTime: json.obj.limitTime,
                id: json.obj.id
            }
            if (from === 'copy') delete data.id;
            dispatch(receive_examine_info(data))
        }).catch(e=> console.log(e))

    }
}

/** 保存或者更新试题的方法 */
export function updateExamine(examineInfo, examInfoType) {
    let examineQuestionList = [];
    for (let i = 0, len = examineInfo.selQuesList.length; i < len; i++) {
        let quesObj = examineInfo.selQuesList[i] || {};
        if (examInfoType == 2 || examInfoType == 1) {
            examineQuestionList.push({ questionId: quesObj.id });
        } else {
            examineQuestionList.push({ point: +quesObj.point, questionId: quesObj.id });
        }
    }

    let data = {};
    examineInfo.classIdDate && (data.classIdDate = examineInfo.classIdDate);
    examineInfo.id && (data.id = examineInfo.id);
    examInfoType != 1 && (Object.prototype.toString.call(examineInfo.limitTime) != "[object Null]" && examineInfo.limitTime !== '') && (data.limitTime = examineInfo.limitTime);
    examineInfo.title && (data.title = examineInfo.title);
    data.examineQuestionList = examineQuestionList;
    data.examInfoType = examInfoType;

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    };

    return async dispatch => {
        dispatch(request_update_examine());
        apiRequest("/gk/ops/class/exercise/saveOrUpdate", config).then(json => {
          if(json.status == 2){
              return message.warn(json.errorMes)
          } else {
            dispatch(receive_update_examine_result_suc());
            message.success('操作成功');
            dispatch(reset_all_data());
            setTimeout(() => {
                window.history.back();
            }, 600);
        }
            }).catch(e=> {
                dispatch(receive_update_examine_result_fail());
                console.log('打印错误',e);
            })
    }
}
