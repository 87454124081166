import React from "react";
import { connect } from "react-redux";
import apiRequest from '../../common/request';
import { checkPermission } from '../../utils/utils';
import { Button, Select, Card, message, Spin, Switch, Form } from 'antd';
import ImagePicker from '../../components/ImagePicker';

const Option = Select.Option;

class ModuleBannerRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgSelected: false,
            showModal: false,
            permission: 0,
            publish: 0,
            label: '',
            imgUrl: '',
            labels: []
        }
    }

    componentDidMount() {
        if (this.props.match.params.bannerId) {
            let item = this.props.banners.filter(i => i.id == this.props.match.params.bannerId)[0]
            item && this.setState({
                imgUrl: item.imgUrl,
                label: item.label.value,
                publish: item.publish,
                sort: item.sort
            });
        }
        this.getLabels();
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    imgPickerFeedBack = (value) => {
        this.setState({ showModal: false, imgSelected: true, imgUrl: value });
    };

    addImg = () => {
        this.setState({ showModal: true });
    };

    setLabel = label => {
        this.setState({ label });
    }

    setPub = checked => {
        this.setState({ publish: checked ? 1 : 0 });
    }

    reset = () => {
        this.setState({
            publish: 1,
            label: '',
            imgUrl: '',
        })
    }

    submit = () => {
        this.publishBanner({
            imgUrl: this.state.imgUrl,
            label: this.state.label,
            publish: this.state.publish
        });
    };

    getLabels = () => {
        apiRequest(`/gk/ops/module/labels`)
            .then(json => {
                this.setState({ labels: json.obj })
            });
    }

    publishBanner = (data) => {
        if (this.props.match.params.bannerId) {
            data['id'] = this.props.match.params.bannerId
            data['sort'] = this.state.sort
        }
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        };
        return apiRequest("/gk/ops/module/saveOrUpdate", config)
            .then(() => {
                message.success('操作成功');
                this.reset();
                setTimeout(() => {
                    this.props.history.push('/moduleBanners');
                }, 800);
            })
    }

    render() {
        if (this.props.isFetching) {
            return <Spin />
        }
        let imgUrl = this.state.imgUrl;
        return (
            <div className="bannerRelease">
                <div className="flex-container">
                    <Form labelCol={{span: 3}}>
                        <Form.Item label="banner标签">
                            <Select style={{ width: 120 }} value={this.state.label} onChange={this.setLabel}>
                                {
                                    this.state.labels.map((obj, idx) => <Option key={idx} value={obj.value}>{obj.desc}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="banner图">
                            {
                                imgUrl ? <Button type="danger" onClick={this.addImg}>修改</Button> : <Button type="primary" onClick={this.addImg}>添加</Button>
                            }
                            {
                                imgUrl && <div><img alt="banner" style={{marginTop: '10px', maxWidth: '500px', height: 'auto'}} src={imgUrl} /></div>
                            }
                        </Form.Item>
                        <Form.Item label="是否发布">
                            <Switch checkedChildren="发布" unCheckedChildren="不发布" checked={this.state.publish == 1} onChange={this.setPub} />
                        </Form.Item>
                    </Form>
                    {checkPermission('sys:module:edit') &&
                        <Card className="cardR" size="small" title='发布'>
                            <Button type="primary" onClick={this.submit}>提交</Button>
                        </Card>
                    }
                </div>

                <ImagePicker
                    showModal={this.state.showModal}
                    closeModal={this.closeModal}
                    feedBack={this.imgPickerFeedBack}
                    bucket='gk-resource'
                    imgPath='background/banner'
                />
            </div>
        )
    }
}

function mapStatetoProps(state) {
    return { labels: state.moduleBannerList.labels, banners: state.moduleBannerList.items };
}

export default connect(mapStatetoProps)(ModuleBannerRelease);
