import React from 'react';
import apiRequest from '../../common/request';
import { Input, Table, Checkbox, Upload, Button, message, Typography, Image } from 'antd';
import OSS from 'ali-oss';
import { checkPermission, uploadOSS2 } from '../../utils/utils';

const { Paragraph } = Typography;

const OPTIONS = [
    { label: 'PC', value: 'pc' },
    { label: 'IOS', value: 'ios' },
    { label: '安卓', value: 'android' },
    { label: '小程序', value: 'applet' },
]
export default class EwmList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileName: '',
            checkAll: true,
            indeterminate: false,
            sub: ['pc', 'ios', 'android', 'applet'],            // 选择终端列表
            ids: [],            // 已选二维码name
            loading: false,     // 加载状态
            objects: [],        // 阿里云微信二维码列表
            ewmObj: {}          // 已配置二维码
        }
    }

    componentDidMount() {
        this.getQrcode();
        this.getOssImg();
    }

    /** 获取oss上面的微信图片，默认请求最大只返回100条数据 */
    getOssImg = () => {
        let that = this;
        this.setState({ loading: true, fileName: '' });
        apiRequest('/gk/ops/sts/getToken?type=oss').then((tokenJson) => {
            if (tokenJson.status == 1) {
                let result = tokenJson.obj;
                let client = new OSS({
                    // endpoint: 'https://oss-cn-beijing.aliyuncs.com',
                    region: 'oss-cn-beijing',
                    accessKeySecret: result.accessKeySecret,
                    accessKeyId: result.accessKeyId,
                    stsToken: result.securityToken,
                    bucket: 'gk-resource'
                });
                client.list({
                    delimiter: '/',
                    prefix: '微信二维码/'
                }).then(res => {
                    let objects = res.objects;
                    that.setState({ objects, loading: false });
                }).catch(error => {
                    message.error('获取阿里云图片失败');
                    that.setState({ loading: false });
                    console.log(error)
                });
            }
        });
    }

    /** 获取已配置的二维码 */
    getQrcode = async () => {
        let url = `/gk/ops/system/param/qrcode/query`;
        let config = {
            method: 'POST',
            headers: { 'content-type': 'application/json', },
        };
        let res = await apiRequest(url, config);
        let ewmObj = res.obj;
        let sel = { ...res.obj };
        this.setState({ ewmObj, sel });
    }

    /** 配置二维码 */
    editQrcode = async () => {
        let { ids, sub, ewmObj } = this.state;
        let data = { ...ewmObj };
        let that = this;
        let wx = [];
        if (ids.length == 0) return message.warn('请钩选微信二维码');
        if (sub.length == 0) return message.warn('请选择配置终端');
        wx = ids.map(i => i.split('/')[1].split('.')[0]);
        if (sub.includes('pc')) data.pc = wx;
        if (sub.includes('ios')) data.ios = wx;
        if (sub.includes('applet')) data.applet = wx;
        if (sub.includes('android')) data.android = wx;

        let url = `/gk/ops/system/param/qrcode/edit`;
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'content-type': 'application/json', },
        };
        let res = await apiRequest(url, config);
        if (res.status == 1) {
            message.success('配置二维码成功');
            that.setState({ ids: [] });
            that.getQrcode();
        } else {
            message.error('配置二维码失败');
        }
    }

    /** 生成upload组件的配置参数 : Fake Single File Upload */
    generateUploadSettings = () => {
        return {
            beforeUpload: file => {
                let { fileName } = this.state;
                if (fileName == '') return message.warn('请输入图片名称');
                let name = '/微信二维码/' + fileName + '.jpg';
                uploadOSS2(name, file, 'gk-resource', true, this.getOssImg);
                return false;
            },
            onPreview: () => { },
            disabled: this.state.fileName == '',
            listType: "picture-card",
            accept: 'image/jpeg',
        };
    }

    /** 设置图片大小格式 */
    transformSize = (value) => {
        const kb = value / 1024;
        const mb = value / (1024 * 1024);
        const gb = value / (1024 * 1024 * 1024);
        if (gb > 1) {
            return gb.toFixed(2) + "GB";
        } else if (mb > 1) {
            return mb.toFixed(2) + "MB";
        } else {
            return kb.toFixed(2) + "KB";
        }
    };

    /** 渲染表格列表信息 */
    generateTableColumns = () => (
        [
            { title: '序号', render: (text, record, index) => index + 1 },
            { title: '微信二维码', render: record => <span>{record.name.split('/')[1].split('.')[0]}</span> },
            { title: '大小', render: record => this.transformSize(record.size) },
            { title: '链接', render: record => <Paragraph copyable={{ text: record.url }}>复制</Paragraph> },
            { title: '预览', render: record => record.size && <Image width={20} src={record.url+`?v=${new Date()}`} /> || '' },
            { title: '上传时间', dataIndex: 'lastModified' },
        ]
    );

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let sel = this.state.ids;
        if (selected) {
            sel.push(record.name);
        } else {
            let idx = sel.indexOf(record.name);
            sel.splice(idx, 1);
        }
        this.setState({ ids: [...sel] });
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let sel = this.state.ids;
        if (selected) {
            changeRows.map(item => {
                sel.push(item.name);
            })
        } else {
            changeRows.map(item => {
                sel = sel.filter(v => v != item.name);
            })
        }
        this.setState({ ids: [...sel] });
    };

    onCheckAllChange = (e) => {
        if (e.target.checked) {
            this.setState({ sub: ['pc', 'ios', 'android', 'applet'], checkAll: true, indeterminate: false })
        } else {
            this.setState({ sub: [], checkAll: false, indeterminate: false });
        }
    }

    onCheckChange = (e) => {
        this.setState({ sub: e, indeterminate: !!e.length && e.length < OPTIONS.length, checkAll: e.length == OPTIONS.length });
    }

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <div>微信二维码列表</div>
            <div>
                <label style={{ fontWeight: 'normal' }}>选择配置终端</label>
                <Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckAllChange} checked={this.state.checkAll}>全选</Checkbox>
                <Checkbox.Group value={this.state.sub} onChange={this.onCheckChange}>
                    {OPTIONS.map((item, idx) => (
                        <Checkbox key={idx} value={item.value}>{item.label}</Checkbox>
                    ))}
                </Checkbox.Group>
                {checkPermission('sys:user:qrcode') && <Button type="primary" onClick={() => this.editQrcode()}>确认配置</Button>}
            </div>
        </div>
    );

    render() {
        let { ids, objects, loading, ewmObj, fileName } = this.state;
        return (
            <>
                {checkPermission('gk:ewm:up') && <div className="commonSearchBox">
                    <div>
                        <Upload {...this.generateUploadSettings()}>点击上传</Upload>
                        <div><Input style={{ width: 150 }} placeholder='请输入微信号' value={fileName} onChange={e => this.setState({ fileName: e.target.value })} /></div>
                    </div>
                    <div>
                        <p>使用说明: （上传图片格式为.jpg格式）</p>
                        <p>1.配置二维码可以选择下面已上传的，也可以在左边上传新的，再去钩选</p>
                        <p>2.上传前先输入微信号，如：bwys055，否则不能上传</p>
                        <p style={{ color: 'red' }}>3.微信号大小写与格式请输入正确，请特别注意</p>
                        <p>上传前图片先压缩一下:<a target="_blank" rel="noreferrer" href="https://tinypng.com/">压缩网站</a></p>
                    </div>
                </div>}
                <h3 style={{ marginTop: '20px' }}>已配置微信二维码列表</h3>
                {checkPermission('sys:qrcode:query') && <div className="commonSearchBox">
                    <div>
                        <h4>pc微信二维码</h4>
                        {ewmObj.pc && ewmObj.pc.map((i, idx) => <div key={idx}>{i}</div>)}
                    </div>
                    <div>
                        <h4>ios微信二维码</h4>
                        {ewmObj.ios && ewmObj.ios.map((i, idx) => <div key={idx}>{i}</div>)}</div>
                    <div>
                        <h4>安卓微信二维码</h4>
                        {ewmObj.android && ewmObj.android.map((i, idx) => <div key={idx}>{i}</div>)}</div>
                    <div>
                        <h4>小程序微信二维码</h4>
                        {ewmObj.applet && ewmObj.applet.map((i, idx) => <div key={idx}>{i}</div>)}</div>
                </div>}

                <Table
                    columns={this.generateTableColumns()}
                    dataSource={objects}
                    rowKey={record => record.name}
                    title={() => this.renderHeader()}
                    loading={loading}
                    rowSelection={{
                        selectedRowKeys: ids,
                        onSelect: this.onSelect,
                        onSelectAll: this.onSelectAll,
                        getCheckboxProps: (record) => ({
                            disabled: record.size === 0,
                        }),
                    }}
                    pagination={{
                        pageSize: 100,
                        total: objects.length,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </>
        )
    }
}
