import apiRequest from '../../common/request';
import { message } from 'antd';
export const REQUEST_APP_ID = 'REQUEST_APP_ID';


export function publishPushedInfo(info, type) {
    delete info.selectedExamine;
    delete info.selectedCourse;                     //不需要提交selectedCourse属性
    delete info.input;
    let config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(info),
        credentials: 'include'
    };
    
    //测试
    let fetchUrl = '';
    if(type == 1) {
        // ios
        fetchUrl = info.filter ? '/gk/ops/push/pushIOSGroupMessage' : '/gk/ops/push/pushIOSBroadMessage';
    }else if(type == 2){
        // 安卓
        fetchUrl = info.filter ? '/gk/ops/push/pushAndroidGroupMessage' : '/gk/ops/push/pushAndroidBroadMessage';
    }
    // 获取对应的appkey
    apiRequest(fetchUrl, config)
        .then((res) => {            
            if(res.status==1) {message.success('推送成功');}
            else {message.error('推送失败');}
        });
}