import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    fetchOrders,
    postNoteMethod,
    alert,
    downloadExcel,
    setReduxKeyValue,
    resetFilterStatus
} from './actions';
import { fetchOrderDetail, postWuliuData } from '../OrderDetail/actions';
import {
    Select,
    Button,
    Modal,
    BackTop,
    Input,
    DatePicker,
    InputNumber,
    Tag,
    Table
} from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import { checkPermission } from '../../utils/utils';
import './index.scss';
import moment from 'moment';
const Option = Select.Option;

class Order extends React.Component {

    conditionsChinese = {
        endTime: '截止时间', goodsName: '问题内容', isSendOut: '发货状态', logisticsNo: '物流单号',
        outTradeNo: '订单号', startTime: '起始时间', status: '支付状态', userName: '用户名',
        minAmount: '最低价格', maxAmount: '最高价格', orderMobile: '手机号码',
    };
    inputConditions = ['goodsName', 'logisticsNo', 'outTradeNo', 'userName', 'orderMobile'];
    amountConditions = ['minAmount', 'maxAmount']
    selectConditions = ['isSendOut', 'status'];

    dateConditions = ['startTime', 'endTime'];

    state = {
        show: false,                //控制模态
        /** 用户填写的物流单号信息 */
        wuliuNumber: '',
        wuliuCompany: '',

        /** 控制备注模态  */
        noteModal: false,
        orderNote: '',

        /** new version code begins */
        isSendOutList: [{ label: '全部', value: null }, { label: '已发货', value: 1 }, { label: '未发货', value: 0 }],        // 是否发货的select状态
        statusList: [{ label: '全部', value: null }, { label: '已付款', value: 1 }, { label: '未付款', value: 0 }],           // 是否付款的select状态
    }

    componentWillMount() {
        this.fetchOrdersHandler();
    }

    /** 加载订单列表数据 */
    fetchOrdersHandler = () => {
        let params = {};
        let keys = ['maxAmount', 'minAmount', 'endTime', 'goodsName', 'isSendOut', 'logisticsNo', 'orderMobile', 'outTradeNo', 'page', 'pageSize', 'startTime', 'status', 'userName'];
        const checkValidata = (key, params) => { if (this.filterNullAndEmptyStr(this.props[key])) { params[key] = this.props[key] } };
        keys.map(key => checkValidata(key, params));
        this.props.fetchOrdersFun(params);
    }

    /** 过滤掉null以及empty string */
    filterNullAndEmptyStr = val => val != null && val !== ''

    /** 监听点击清空查询订单按钮 */
    resetBtnClickHandler = async () => {
        await this.props.resetOrderFilterHandler();
        this.fetchOrdersHandler();
    }

    /** 渲染订单筛选条件输入形式的相关UI结构 */
    renderFilterUI = () => (
        <div>
            <div className="commonSearchBox">
                {this.renderSelectStatus()}
                {this.renderInputsStatus()}
                {this.renderAmountStatus()}
                {this.renderCalendarUI()}
                <div>
                    <Button disabled={this.props.loading} type="primary" onClick={this.searchHandler} style={{marginRight: '20px'}}>查询</Button>
                    <Button disabled={this.props.loading} type="danger" onClick={this.resetBtnClickHandler} style={{marginRight: '20px'}}>重置</Button>
                    {checkPermission('sys:order:export') && <Button type="primary" onClick={this.handleDownExcel}>导出Excel订单数据</Button>}
                </div>
            </div>
            <div className="commonSearchBox">
                <div>
                    <p><span className="msg">*提示：价格的单位是分</span> 价格区间左闭右开，尽量贴近查询的最低价格，比如实际存在价格880元课程，查询时最小价格输入88000</p>
                    <p><span className="msg">*说明：</span>单位换算：1角=10分 1元=100分， 因服务端接受价格单位是分，价格条件框输入都为整数，不能带有小数，整数可以保证精度</p>
                </div>
            </div>
        </div>
    )

    /** 监听点击查询订单按钮 */
    searchHandler = async () => {
        await this.props.setReduxKeyValueHandler({ key: 'page', value: 1 });
        this.fetchOrdersHandler();
    }

    /** 设置日期输入的值 */
    calendarValueChangeHandler = async (dateString, key) => {
        await this.props.setReduxKeyValueHandler({ key, value: dateString });
    }

    /** 监听日历组件点击确定按钮 */
    calendarClickOkHandler = async () => {
        await this.props.setReduxKeyValueHandler({ key: 'page', value: 1 });
        this.fetchOrdersHandler();
    }

    /** 渲染订单筛选条件日期选择形式的相关UI结构 */
    renderCalendarUI = () => {
        return this.dateConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <DatePicker
                    className="w260"
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                    value={this.calendarValuePipe(this.props[key])}
                    onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, key); }}
                    onOk={this.calendarClickOkHandler}
                />
            </div>
        ))
    }

    /** 渲染select类型相关的筛选数据 */
    renderSelectStatus = () => {
        return this.selectConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <Select style={{ width: 150 }} value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
                    {
                        this.state[`${key}List`].map((obj, idx) => (
                            <Option key={idx} value={obj.value}>{obj.label}</Option>
                        ))
                    }
                </Select>
            </div>
        ));
    }

    /** 渲染input类型相关的筛选数据 */
    renderInputsStatus = () => {
        return this.inputConditions.map((key, idx) => (
            <div key={idx} className="inputWrapper conditionContainer flexRow">
                <label>{this.conditionsChinese[key]}</label>
                <Input
                    style={{ width: '150px' }}
                    value={this.props[key]}
                    onChange={event => this.props.setReduxKeyValueHandler({ key, value: event.target.value })}
                    onPressEnter={() => this.searchHandler()}
                />
            </div>
        ));
    }
    // 验证价格格式
    limitRule = value => { return (value.replace(/^(0+)|[^\d]+/g, '')) }

    /** 渲染价格input类型相关的筛选数据 */
    renderAmountStatus = () => {
        return this.amountConditions.map((key, idx) => (
            <div key={idx} className="inputWrapper conditionContainer flexRow">
                <label>{this.conditionsChinese[key]}</label>
                <InputNumber
                    min={0}
                    style={{ width: '150px' }}
                    value={this.props[key]}
                    onChange={value => this.value2Redux({ key, value })}
                    parser={this.limitRule}
                />
            </div>
        ));
    }

    /** 把用户输入的值反馈到redux里面 */
    value2Redux = async data => {
        await this.props.setReduxKeyValueHandler(data);
        await this.props.setReduxKeyValueHandler({ key: 'page', value: 1 });
        this.fetchOrdersHandler();
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''

    close = () => {
        this.setState({ show: false, wuliuNumber: '', wuliuCompany: '' });
    }

    sureSend = () => {
        // 物流公司以及物流编号必填的
        if (this.state.wuliuCompany === '' || this.state.wuliuNumber === '') {
            this.props.alert('必须填入物流公司以及物流编号');
            return;
        }
        var obj = {
            //不带id传是新增
            //id: this.props.detailObj.orderLogistics.id,
            logisticsCompany: this.state.wuliuCompany,
            logisticsNo: this.state.wuliuNumber,//不允许用户不填写物流单号
            outTradeNo: this.props.detailObj.outTradeNo
        };
        this.props.postwuliuData(obj, this.fetchOrdersHandler);
        this.close();
    }

    /** 当用户填写了备注消息并且提交后，希望能够得到一个反馈：两种做法：1.刷新页面；2.模拟数据 */
    postNote = () => {
        let obj = {
            outTradeNo: this.props.detailObj.outTradeNo,
            remarks: this.state.orderNote
        };
        this.props.postNoteData(obj, this.fetchOrdersHandler);
        this.closeNoteModal();
    }

    closeNoteModal = () => {
        this.setState({ noteModal: false, orderNote: '' });
    }

    open = (id) => {
        this.props.getOrderDetail(id);
        this.setState({ show: true });
    }

    openNoteModal = (id) => {
        this.props.getOrderDetail(id);
        this.setState({ noteModal: true });
    };

    handleWuliuCompany = (event) => {
        this.setState({ wuliuCompany: event.target.value });
    }

    handleWuliuNumber = (event) => {
        this.setState({ wuliuNumber: event.target.value });
    }

    handleOrderChange = (event) => {
        this.setState({ orderNote: event.target.value });
    }

    /** 下载excel */
    handleDownExcel = (event) => {
        let params = {};
        if (this.props.status !== -1) { params.status = this.props.status; }
        if (this.props.isSendOut !== -1) { params.isSendOut = this.props.isSendOut; }
        if (this.props.goodsName !== '') { params.goodsName = this.props.goodsName; }
        if (this.props.endTime !== '') { params.endTime = this.props.endTime; }
        if (this.props.logisticsNo !== '') { params.ogisticsNo = this.props.logisticsNo; }
        if (this.props.outTradeNo !== '') { params.outTradeNo = this.props.outTradeNo; }
        if (this.props.startTime !== '') { params.startTime = this.props.startTime; }
        if (this.props.userName !== '') { params.userName = this.props.userName; }
        if (this.props.orderMobile !== '') { params.orderMobile = this.props.orderMobile; }
        params.page = this.props.page || 1;
        this.props.downloadExcel(params);
    }

    /** 分页数目改变的时候触发，跳转到第一页 */
    onShowSizeChange = async (current, pageSize) => {
        await this.props.setReduxKeyValueHandler({ key: 'pageSize', value: pageSize });
        await this.props.setReduxKeyValueHandler({ key: 'page', value: 1 });
        this.fetchOrdersHandler();
    }

    paginationChange = async value => {
        await this.props.setReduxKeyValueHandler({ key: 'page', value });
        this.fetchOrdersHandler();
    }

    /** 返回支付方式 */
  generatePayMethod = (platform) => {
    let result = '';
    if (platform.match('wx')) result = <Tag color="green">微信</Tag>;
    if (platform.match('ali')) result = <Tag color="blue">支付宝</Tag>;
    if (result) return result;
  }

    generateTableColumns = () => {
        let columns = [
            {
                title: '订单信息(价格:分)',
                width: '40%',
                render: (text, record) => (
                    <div>
                        <div><h4>{record.goodsName}</h4></div>
                        <span className="info">价格：<Tag color="red">{record.amount}/分</Tag></span>
                        <div className="info">订单号：{record.outTradeNo} </div>
                        <div className="info">付款时间：{record.createTime}</div>
                    </div>
                ),
            },
            {
                title: '收货信息',
                width: '30%',
                render: (text, record) => (
                    <div>
                        <div className="info">联系方式：{record.userMobile}</div>
                        <span className="info">支付方式：{this.generatePayMethod(record.platform)}</span>
                        <div className="info">付款状态：{record.status && <CheckCircleTwoTone twoToneColor="#3dc342" /> ||
                                <CloseCircleTwoTone twoToneColor="#f04645" />}</div>
                    </div>
                ),
            },
            {
                title: '操作',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('sys:order:info') && <Link to={`/orderDetail?orderNumber=${record.outTradeNo}`} className="editSpan"><Button size="small" type="primary">订单详情</Button></Link>}
                            {checkPermission('sys:order:logistics') && <Button size="small" type="primary" onClick={()=>this.open(record.outTradeNo)}>填写物流单号</Button>}
                        </div>
                    )
                }
            },
            {
                title: '备注',
                width: '10%',
                render: (text, record) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('sys:order:remark') && <Button size="small" type="primary" onClick={()=>this.openNoteModal(record.outTradeNo)}>添加备注</Button>}
                        </div>
                    )
                }
            }
        ];
        return columns;
    }

    render() {
        return (
            <div className="orderContainer">
                { this.renderFilterUI()}
                
                <Table
                    columns={this.generateTableColumns()}
                    dataSource={this.props.arr}
                    rowKey={record => record.id}
                    title={this.renderHeader}
                    loading={this.props.loading}
                    pagination={{
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements || 5,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.paginationChange,
                        onShowSizeChange: this.onShowSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                />

                <Modal className="centerModal" visible={this.state.show} onCancel={this.close} title="物流单号录入" onOk={this.sureSend}>
                    <div className="gk-modal-body">
                        <div>核对买家信息</div>
                        <div>姓名：{this.props.detailObj.userName}</div>
                        <div>联系方式：{this.props.detailObj.userMobile}</div>
                        <div>收件地址：{this.props.detailObj.userAddress}</div>
                        <div>单号录入</div>
                        <div className="companyMarginBottom">
                            <span>物流公司</span>
                            <input type="text" value={this.state.wuliuCompany} onChange={this.handleWuliuCompany} />
                        </div>
                        <div>
                            <span>物流单号</span>
                            <input type="text" value={this.state.wuliuNumber} onChange={this.handleWuliuNumber} />
                        </div>
                    </div>
                </Modal>

                {/* 一个用于实现备注功能的模态 */}
                <Modal className="noteModal" visible={this.state.noteModal} onCancel={this.closeNoteModal} title="给这个订单添加备注" onOk={this.postNote}>
                    <textarea style={{ "width": "450px", "height": "100px" }} value={this.state.orderNote} onChange={this.handleOrderChange}></textarea>
                </Modal>
                <BackTop />
            </div>
        );
    }
}

function mapStatetoProps(state) {

    return {
        detailObj: state.orderDetailReducer || {},
        ...state.orderReducer,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchOrdersFun: data => { dispatch(fetchOrders(data)) },
    getOrderDetail: (orderNumber) => { dispatch(fetchOrderDetail(orderNumber)) },
    postwuliuData: (obj, cb) => { dispatch(postWuliuData(obj, cb)) },
    postNoteData: (obj, cb) => { dispatch(postNoteMethod(obj, cb)) },
    alert: (content) => { dispatch(alert(content)) },
    downloadExcel: (params) => { dispatch(downloadExcel(params)) },
    setReduxKeyValueHandler: data => { dispatch(setReduxKeyValue(data)) },
    resetOrderFilterHandler: () => { dispatch(resetFilterStatus()) }
})

export default connect(mapStatetoProps, mapDispatchToProps)(Order);
