import apiRequest from '../../common/request'
import { message } from 'antd';
import Cookies from 'js-cookie';

const PREFIX = "INTERVIEW_STUDENT_PAGE";

export const SET_REDUX_KEY_VALUE = PREFIX + "SET_REDUX_KEY_VALUE";
export const RESET_SEARCH_FILTER_VALUE = PREFIX + "RESET_SEARCH_FILTER_VALUE";
export const CHANGE_CURRENT_CLASS = PREFIX + "CHANGE_CURRENT_CLASS"

export const resetFilterValue = () => ({
    type: RESET_SEARCH_FILTER_VALUE
})

export const changeCurrentClass = data => ({
    type: CHANGE_CURRENT_CLASS,
    data
});

export const setReduxKeyValue = (key, value) => ({
    type: SET_REDUX_KEY_VALUE,
    key,
    value
})


export async function updateStatus(id, value){
    let url = '/gk/ops/interviewStudent/updatePayStatus/' + id + '/' + value;
    let json = await apiRequest(url, { method: 'POST',credentials: 'include' });
    if (json.status != 1) {
      message.error(json.errorMes || '操作失败');
      return false;
    } else {
      message.success('操作成功');
      return true;
    }
}

export function exportStudents(data){
    let url = '/gk/ops/interviewStudent/exportInterviewStudent';
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken'),
            'Sin-UserAgent-Sign': 'SintureGK-WEB',
            'Sin-Client-Type': 3
        },
        body: JSON.stringify(data)
    };

    fetch(url, config).then(response => {
        response.blob().then(blobData => {
            let a = document.createElement('a');
            let href = window.URL.createObjectURL(blobData);
            a.href = href;
            let keys = ['classId', 'studentName', 'payStatus'];
            let name = ['学员职位数据表'];
            keys.map(key => { data[key] && name.push(data[key]+'') });
            name = name.join('_') + '.xls';
            a.download = name;
            document.body.appendChild(a).click();
            window.URL.revokeObjectURL(href);
        })
    }).catch(err => {
        message.error('下载失败');
    })
}

export function fetchStudents(data){
    let url = '/gk/ops/interviewStudent/page';
    let config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(setReduxKeyValue('loading', true));
        apiRequest(url, config)
            .then(json => {
                dispatch(setReduxKeyValue('responseObj', json.obj));
                dispatch(setReduxKeyValue('loading', false));
            });
    }
}
