import React from 'react';
import { connect } from 'react-redux';
import { Input, Select, Button, message, DatePicker } from 'antd';
import { 
    fetchContractUserInform,
    modifyApplicationArea,
    setContractDate,
    changeContractNo,
    changeCurrentSale,
    salesWechatHandler,
    salesWechatNameHandler,
    handleSelectClass,
    courseNameHandler,
    modifySelectStatus,
    amountHandler,
    logisticsCompanyHandler,
    logisticsNoHandler,
    handlerClassDetail,
    remarksHandler,
    fetchContractOrderInform,
    fromUserInterface,
    fetchContractDetail,
    modifyCurrentRedux,
    clearCurrentStatus,
    modifyCurrentDetails
} from './action';
import { fetchApplicationArea, fetchDepartmentList } from '../ContractOrder/action';
import { fetchSearchClass } from '../Inquiry/action';
import moment from 'moment';
import { getSearchQuery } from '../../utils/utils';
import './index.scss';

const { TextArea } = Input;

const { Option } = Select;

const sell = '销售部';
class AddOrModify extends React.Component {

    componentWillUnmount() {
        this.props.dispatch(clearCurrentStatus());
    };

    async componentDidMount() {
        let userInfoId = getSearchQuery('userInfoId');
        let contractId = getSearchQuery('contractId');
        let id = userInfoId;
        id = Number(id)
        if (userInfoId) this.props.dispatch(fromUserInterface(id));
        if (contractId) {
            let info = await fetchContractDetail(contractId);
            await this.props.dispatch(modifyCurrentDetails(info));
            this.props.dispatch(modifyCurrentRedux(
                { 
                    key: 'classDetail', 
                    value: { 
                        className: info.className, 
                        id: info.classId, 
                        isEnd: info.isEnd,
                        startDate: info.startDate,
                        teacher: info.headTeacher,  
                    } 
                }
            ));
        };
        await this.init();
        this.props.dispatch(fetchApplicationArea());
    };

    selectList = [ {label: '全部', value: '全部' }, {label: '是', value: 1 }, {label: '否', value: 0 } ];

    statusList = [{label: '是', value: 1 }, {label: '否', value: 0 }]

    value2Redux = data => {
        this.props.dispatch(modifySelectStatus(data));
    };

    /** 搜索合同用户 */
    searchContractInform = value => {
        if (!value) return;
        this.props.dispatch(fetchContractUserInform(value));
    };

    /** 初始化 */
    init = () => {
        let userInfoId = getSearchQuery('userInfoId');
        let name = getSearchQuery('name');
        let phone = getSearchQuery('phone');
        if (name) {
            this.props.dispatch(modifyCurrentRedux({ key: 'userInfoId', value: Number(userInfoId) }));
            this.props.dispatch(modifyCurrentRedux({ key: 'userName', value: `${name}(${phone})` }));
        }
    }

    handleChange = async value => {
        await this.props.dispatch(modifyCurrentRedux({ key: 'userIdx', value }));
        const { userIdx, userNames } = this.props;
        if (userIdx !== null) {
            let currentUser = userNames[userIdx];
            this.props.dispatch(modifyCurrentRedux({ key: 'userDetail', value: currentUser }));
            this.props.dispatch(modifyCurrentRedux({ key: 'userInfoId', value: currentUser.id }));
            this.props.dispatch(modifyCurrentRedux({ key: 'userName', value: `${currentUser.name}(${currentUser.mobile})` }));
        };
    };
    
    /** 修改当前报考地区 */
    changeApplicationArea = event => {
        this.props.dispatch(modifyApplicationArea(event));
    };

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    /** 设置日期输入的值 */
    calendarValueChangeHandler = dateString => {
        this.props.dispatch(setContractDate(dateString));
    };

    contractNoHandler = event => {
        let value = event.target.value;
        this.props.dispatch(changeContractNo(value));
    };

    modifySales = event => {
        this.props.dispatch(changeCurrentSale(event));
    };

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        let data = { title: value }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    }

    /** 处理当前选中的班级 */
    handlerClassSelect = async value => {
        await this.props.dispatch(handleSelectClass(value));
        const { classIdx, classes } = this.props;
        if (classIdx !== null) {
            let currentClass = classes[classIdx];
            this.props.dispatch(modifyCurrentRedux({ key: 'className', value: currentClass.className }));
            this.props.dispatch(handlerClassDetail(currentClass));
        }
    };

    /** 新增或修改合同订单 */
    updateContractOrder = () => {
        const { 
            userInfoId, examArea, contractNo, courseName, signingDate, amount, salesName, salesWechat, salesWechatName,
            classDetail, logisticsCompany, logisticsNo, protocol, isInterview, isRenew, remarks, status, id,
        } = this.props;
        if (!userInfoId) {
            message.warn('请选择客户');
            return;
        };
        if (examArea == '全部') {
            message.warn('请选择报考地区');
            return;
        };
        if (!courseName) {
            message.warn('请输入课程');
            return;
        };
        if (!contractNo) {
            message.warn('请输入合同编号');
            return;
        };
        if (!signingDate) {
            message.warn('请选择合同签订日期');
            return;
        };
        if (!amount) {
            message.warn('请输入合同金额');
            return;
        };
        if (!salesName) {
            message.warn('请选择销售');
            return;
        };
        if (!salesWechat) {
            message.warn('请输入销售微信号');
            return;
        };
        if (!salesWechatName) {
            message.warn('请输入销售微信名');
            return;
        };
        let params = { userInfoId, examArea, contractNo, courseName, signingDate, amount, salesName, salesWechat, salesWechatName };
        id && (params.id = id);
        (classDetail && classDetail.id) && (params.classId = classDetail.id);
        logisticsCompany && (params.logisticsCompany = logisticsCompany);
        logisticsNo && (params.logisticsNo = logisticsNo);
        (protocol != '全部') && (params.protocol = protocol);
        (isInterview != '全部') && (params.isInterview = isInterview);
        (isRenew != '全部') && (params.isRenew = isRenew);
        if (status == 0 || status == '否') {
            params.status = 0;
        } else if (status == 1) {
            params.status = 1;
        }
        remarks && (params.remarks = remarks);
        const cb = () => this.props.history.push('/contractOrder');
        this.props.dispatch(fetchContractOrderInform(params, cb));
    };

    render() {
        const { 
            userNames, examArea, appLicationArea, signingDate, contractNo, salesName, salles, salesWechat, salesWechatName, classes, courseName, amount, logisticsCompany, logisticsNo, classDetail, remarks, status, isRenew, isInterview, protocol, userName, className
        } = this.props;
        return (
            <div className="add-modify">
                <div>
                    <span className="pageTitle">新增/修改合同订单</span>
                </div>

                <div className="flexRow mt20">
                    <label className="mt5 mr10 w70">客户<span style={{color:'red'}}>*</span></label>
                    <Select
                        style={{ width: '300px' }}
                        showSearch
                        placeholder="请输入客户姓名或手机号"
                        filterOption={false}
                        value={userName}
                        onSearch={value => {this.searchContractInform(value)}}
                        onChange={value => {this.handleChange(value)}}
                        className="mr10"
                    >
                        {
                            userNames && userNames.map((item, index) => (
                                <Option value={index}>{`${item.name}(${item.mobile})`}</Option>
                            ))
                        }
                    </Select>
                </div>

                <div className="flexRow mt20">
                    <label className="mt5 mr10 w70">报考地区<span style={{color:'red'}}>*</span></label>
                    <Select
                        style={{ width: 300 }}
                        placeholder="请选择"
                        value={examArea}
                        onChange={event => {this.changeApplicationArea(event)}}
                        className="mr10"
                    >
                        {
                            ['全部'].concat(appLicationArea).map(item => (
                                <Option value={item} key={item}>{item}</Option>
                            ))
                        }
                    </Select>
                </div>

                <div className="flexRow mt20">
                    <label className="mt5 mr10 w70">班级</label>
                    <Select
                        showSearch
                        placeholder="请输入班级关键字"
                        style={{ width: '600px' }}
                        filterOption={false}
                        value={className}
                        onSearch={value => {this.searchClass(value)}}
                        onChange={value => {this.handlerClassSelect(value)}}
                        className="mr10"
                    >
                        {
                            classes && classes.length && classes.map((item, index) => (
                                <Option key={index} value={index}>{item.className}</Option>
                            ))
                        }
                    </Select>
                </div>

                {
                    classDetail && classDetail.id && 
                    <div className="ml80 mt10">
                        <span className="detail">
                            {`(班主任:${classDetail.teacher}--开课日期:${classDetail.startDate}--${classDetail.isEnd ? '结课' : '未结课'})`}
                        </span>
                    </div> 
                }

                <div className={`flexRow ${classDetail ? 'mt10' : 'mt20'}`}>
                    <label className="mt5 mr10 w70">课程<span style={{color:'red'}}>*</span></label>
                    <Input style={{ width: '600px' }} value={courseName}
                        placeholder="请输入课程" 
                        onChange={(e) => this.props.dispatch(courseNameHandler(e.target.value))} />
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">合同编号<span style={{color:'red'}}>*</span></label>
                        <Input style={{ width: '300px' }} value={contractNo} 
                            placeholder="请输入合同编号"
                            onChange={(e) => this.contractNoHandler(e)} />
                    </div>
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">合同签订日期<span style={{color:'red'}}>*</span></label>
                        <DatePicker
                            className="w300"
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(signingDate)}
                            onChange={(date, dateString) => {this.calendarValueChangeHandler(dateString);}}
                        />
                    </div>
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">合同金额<span style={{color:'red'}}>*</span></label>
                        <Input style={{ width: '300px' }} value={amount}
                            placeholder="请输入合同金额" 
                            onChange={(e) => this.props.dispatch(amountHandler(e.target.value))} />
                    </div>
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">销售<span style={{color:'red'}}>*</span></label>
                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="请输入销售姓名"
                            value={salesName}
                            onSearch={value => {this.employeeSearch(sell, value)}}
                            onChange={event => {this.modifySales(event)}}
                            className="mr10"
                        >
                            {
                                salles && salles.map(item => (
                                    <Option value={item.name}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">销售微信号<span style={{color:'red'}}>*</span></label>
                        <Input style={{ width: '300px' }} value={salesWechat} 
                            placeholder="请输入销售微信号"
                            onChange={(e) => this.props.dispatch(salesWechatHandler(e.target.value))} />
                    </div> 
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">销售微信名<span style={{color:'red'}}>*</span></label>
                        <Input style={{ width: '300px' }} value={salesWechatName} 
                            placeholder="请输入销售微信名"
                            onChange={(e) => this.props.dispatch(salesWechatNameHandler(e.target.value))} />
                    </div>      
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">快递公司</label>
                        <Input style={{ width: '300px' }} value={logisticsCompany}
                            placeholder="请输入快递公司" 
                            onChange={(e) => this.props.dispatch(logisticsCompanyHandler(e.target.value))} />
                    </div>
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">快递单号</label>
                        <Input style={{ width: '300px' }} value={logisticsNo} 
                            placeholder="请输入快递单号"
                            onChange={(e) => this.props.dispatch(logisticsNoHandler(e.target.value))} />
                    </div>
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">退款</label>
                        <Select style={{ width: 300 }} value={status} onChange={value => this.value2Redux({ key: 'status', value })}>
                        {
                            this.statusList && this.statusList.map((obj, idx) => (
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))
                        }
                        </Select>
                    </div>
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">续费</label>
                        <Select style={{ width: 300 }} value={isRenew} onChange={value => this.value2Redux({ key: 'isRenew', value })}>
                        {
                            this.selectList && this.selectList.map((obj, idx) => (
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))
                        }
                        </Select>
                    </div>
                </div>

                <div className="flexRow mt20">
                    <div className="flexRow w400">
                        <label className="mt5 mr10 w70">是否进面</label>
                        <Select style={{ width: 300 }} value={isInterview} onChange={value => this.value2Redux({ key: 'isInterview', value })}>
                        {
                            this.selectList && this.selectList.map((obj, idx) => (
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))
                        }
                        </Select>
                    </div>
                    <div className="flexRow ml30">
                        <label className="mt5 mr10 w80">协议是否收回</label>
                        <Select style={{ width: 300 }} value={protocol} onChange={value => this.value2Redux({ key: 'protocol', value })}>
                        {
                            this.selectList && this.selectList.map((obj, idx) => (
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))
                        }
                        </Select>
                    </div>
                </div>

                <div className="mt20">
                    <span className="remark">备注</span>
                    <br />
                    <TextArea 
                        value={remarks}
                        onChange={e=>this.props.dispatch(remarksHandler(e.target.value))}
                        style={{width: '60%'}} 
                        autosize={{ minRows: 5 }}
                    />
                    <br />
                    <Button style={{marginLeft: '29%', marginTop: '10px'}} type="primary" onClick={this.updateContractOrder}>确定</Button>
                </div>
            </div>
        )
    }
}
function mapStatetoProps(state) {

    const {
       ...previousState
    } = state.changeUserInfo;

    const {
        appLicationArea,
        salles
    } = state.contractOrder;

    return {
        appLicationArea,
        salles,
        ...previousState
    };
}

export default connect(mapStatetoProps)(AddOrModify);