import apiRequest from '../../common/request';
import Cookies from 'js-cookie'
import { message } from 'antd';
import fetch from 'isomorphic-fetch';

const PREFIX = 'STUDENT_DETAIL_ACTIONS';
export const LOADING_STUDENT_DETAIL = PREFIX + 'LOADING_STUDENT_DETAIL';
export const SET_STUDENT_DETAIL = PREFIX + 'SET_STUDENT_DETAIL';
export const SET_DAY_NUMBER = PREFIX + 'SET_DAY_NUMBER';
export const SET_ORIGINAL_STUDENT_DETAIL = PREFIX + 'SET_ORIGINAL_STUDENT_DETAIL';
export const CHANGE_FILTER_STATUS = PREFIX + 'CHANGE_FILTER_STATUS';

const loading_student_detail = () => ({
    type: LOADING_STUDENT_DETAIL
});

export const set_student_detail = data => ({
    type: SET_STUDENT_DETAIL,
    data
});

const set_day_number = data => ({
    type: SET_DAY_NUMBER,
    data
});

export const change_filter_status = value => ({
    type:CHANGE_FILTER_STATUS,
    value
})

const set_original_student_detail = data => ({
    type: SET_ORIGINAL_STUDENT_DETAIL,
    data
});

export const get_student_detail = params => {
    if (typeof params.courseNumStart != "number") { params.courseNumStart = +(params.courseNumStart); }
    if (typeof params.courseNumEnd != "number") { params.courseNumEnd = +(params.courseNumEnd); }
    let url = `/gk/ops/student/getStudentLearnDetails`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(params)
    };
    return async dispatch => {
        dispatch(loading_student_detail());
        let json = await apiRequest(url, config);
        let detailObj = json.obj || {};
        dispatch(set_original_student_detail(detailObj));
        dispatch(set_student_detail(detailObj));
    }
}

/** 加载课程所对应的天数 */
export const get_day_num = (courseId) => {
    let url = `/gk/ops/course/class/course/number/${courseId}`;
    let config = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json'
        }
    };
    return dispatch => {
        apiRequest(url, config).then(json => {
          dispatch(set_day_number(json.obj));
        }).catch(err => {

        })
    }
}

/** 导出班级Excel数据 */
export const get_excel_data = (params) => {
    let url = '/gk/ops/student/exportStudentLearnDetails';
    let config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'content-type': 'application/json',
            'Sin-Access-Token': Cookies.get('accessToken'),
            'Sin-UserAgent-Sign': 'SintureGK-WEB',
            Accept: '*/*',
            'Sin-Client-Type': 3
        },
        body: JSON.stringify(params)
    };

    return dispatch => {
        fetch(url, config).then(response => response.blob()).then(blobData => {
            let a = document.createElement('a');
            let href = window.URL.createObjectURL(blobData);
            a.href = href;
            a.download = `班级号${params.courseId}-学员ID${params.studentId}.xls`;
            document.body.appendChild(a).click();
            window.URL.revokeObjectURL(href);
        }).catch(err => {
            console.log(err);
            message.error('网络错误');
        })
    }
}
