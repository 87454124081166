import apiRequest from '../../common/request'
import { message } from 'antd';
import OSS from 'ali-oss';

const PREFIX = "COURSE_INFO_";
export const FILL_START_TIME = PREFIX + "FILL_START_TIME";
export const FILL_END_TIME = PREFIX + "FILL_END_TIME";

export const REQUEST_UPDATE = PREFIX + "REQUEST_UPDATE";
export const RECEIVE_UPDATE_FAIL = PREFIX + "RECEIVE_UPDATE_FAIL";
export const RECEIVE_UPDATE_SUC = PREFIX + "RECEIVE_UPDATE_SUC";
export const RESET = PREFIX + "RESET";
export const REQUEST_INFO = PREFIX + "REQUEST_INFO";
export const RECEIVE_INFO = PREFIX + "RECEIVE_INFO";
export const FETCHING_START = PREFIX + "FETCH_START";
export const RECEIVE_UPLOAD_PIC_SUC = "RECEIVE_UPLOAD_PIC_SUC";
export const RECEIVE_UPLOAD_PIC_FAIL = "RECEIVE_UPLOAD_PIC_FAIL";
export const REQUEST_UPLOAD = "REQUEST_UPLOAD";
export const FILL_BANNER = "FILL_BANNER";
export const SET_ISFETCHING_FALSE = PREFIX + "SET_ISFETCHING_FALSE";

export const RESET_INFO = "RESET_INFO";
export const RECEIVE_TEACHER_INFO = PREFIX + "FETCH_TEACHER_INFO";
export const RECEIVE_LABEL = PREFIX + "FETCH_LABEL";
export const SET_DATE2NUM = PREFIX + "SET_DATE2NUM";
export const SET_ORIGINAL_COURSENUMS = PREFIX + "SET_ORIGINAL_COURSENUMS";
export const SET_COURSENUMS = PREFIX + "SET_COURSENUMS";
export const  SET_UPDATE_STATUS = PREFIX + "SET_UPDATE_STATUS";
export const NOT_SET_UPDATE_STATUS = PREFIX + "NOT_SET_UPDATE_STATUS";

export const setCourseNums = (value, isFromCalendar) => ({
    type: SET_COURSENUMS,
    value,
    isFromCalendar
});

export const setUpdateStatus = data => ({
    type: SET_UPDATE_STATUS,
    data
});

export const notSetUpdateStatus = data => ({
    type: NOT_SET_UPDATE_STATUS,
    data
});

export const setOriginalCourseNums = data => ({
    type: SET_ORIGINAL_COURSENUMS,
    data
});

export const FILL_PROPERTY = "FILL_PROPERTY";

export const setIsFetchingFalse = () => ({ type: SET_ISFETCHING_FALSE });

export const input_property = (obj) => ({
    type: FILL_PROPERTY,
    data: obj
});

const fetch_start = () => ({ type: FETCHING_START });

function receive_teacher_info(data) {
    return {
        type: RECEIVE_TEACHER_INFO,
        data
    };
}

function receive_label(data) {
    return {
        type: RECEIVE_LABEL,
        data
    };
}

export function reset_info() {
    return {
        type: RESET_INFO
    };
}


export function fill_banner(value) {
    return{
        type:FILL_BANNER,
        value,
        key:'courseBanner'
    }
}


function request_update() {
    return {
        type: REQUEST_UPDATE
    }
}

function receive_update_suc() {
    return {
        type: RECEIVE_UPDATE_SUC
    }
}

function receive_update_fail() {
    return {
        type: RECEIVE_UPDATE_FAIL
    }
}

function reset() {
    return {
        type: RESET
    }
}

function request_info() {
    return {
        type:REQUEST_INFO
    }
}

export function receive_info(info) {
    return {
        type:RECEIVE_INFO,
        info
    }
}




function receive_upload_pic_suc() {
    return {
        type: RECEIVE_UPLOAD_PIC_SUC
    }
}

function receive_upload_pic_fail() {
    return {
        type: RECEIVE_UPLOAD_PIC_FAIL
    }
}

function request_upload() {
    return {
        type: REQUEST_UPLOAD
    }
}

export function fetchCourse(id) {
    let fetchUrl = `/gk/ops/course/details/${id}`;
    return dispatch => {
        dispatch(fetch_start());
        return apiRequest(fetchUrl, {method: 'GET', credentials: 'include'})
          .then(json => {
              let jsonObj = dataHandler(json.obj);
              dispatch(receive_info(jsonObj));
          })
          .catch(error => console.log(error));
    }
}

/** 对后端所返回的数据做一个处理 */
function dataHandler(obj) {
    let keys = [ 'teacherDesc', 'classDesc' ];
    keys.map(key => {
        if (!(key in obj)) {
            obj[key] = '';
        }
    });
    return obj;
}


export function updatePicture(file){
    return dispatch => {
        dispatch(request_upload());
        return apiRequest("/gk/ops/sts/getToken?type=oss",{method: 'GET',credentials: 'include'})
          .then(json => {
            let result = json.obj;
            let client = new OSS({
                accessKeyId: result.accessKeyId,
                accessKeySecret: result.accessKeySecret,
                stsToken: result.securityToken,
                endpoint: 'https://oss-cn-beijing.aliyuncs.com',
                bucket: 'gk-course'
            });
            client.multipartUpload('/course/banner/'+(new Date()).getTime()+file.name, file).then((result) => {
                let url = result.name;
                if(url.startsWith("/")) url = url.substring(1)
                let picture = client.signatureUrl(url, {expires: 3600});
                dispatch(receive_upload_pic_suc());
                dispatch(fill_banner(picture));
            }).catch(function (err) {
                dispatch(receive_upload_pic_fail());
                message.error(err);
                console.log(err);
            });
          })
          .catch(error => {
            dispatch(receive_upload_pic_fail());
            console.log(error)
          });
    }
}

function isEmptyString(str){
   if(typeof str=="string"){
       return str.trim().length == 0;
   }else{
       return false;
   }
}


export function updateCourse(data, cb) {
    if(data.remark=='') delete data.remark;
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update());
        return apiRequest("/gk/ops/course", config)
          .then(json => {
            dispatch(receive_update_suc());
            message.success('更新成功');
            cb && cb();
          })
          .catch(error => {
              dispatch(receive_update_fail());
              console.log(error)
          });
    }
}
