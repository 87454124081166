import React from 'react';
import { connect } from 'react-redux';
import {
    fill_teacher,
    fill_url,
    updateClass,
    getCourseData,
    valueInput,
    setExerciseDetail,
    fill_courseId
} from "./action";
import { getAvailableVideoUrl } from '../../components/VideoPicker/action'
import { fetchCourseTeachers } from "../CourseList/action"
import VideoPicker from '../../components/VideoPicker';
import QuestionSelectCard from '../../components/QuestionSelectCard';
import { OSS_PUB_GK_COURSE } from '../../common/consts';
import { Card, Select, Input, InputNumber, Button, Switch, message, Spin } from 'antd';
import { validateUtil, checkPermission } from '../../utils/utils';

import './index.scss';
const Option = Select.Option;

function swapArr(arr, i1, i2) {
    arr[i1] = arr.splice(i2, 1, arr[i1])[0];
    return arr;
}

function arrUp(arr, i, length, cb) {
    if (i !== 0) {
        return swapArr(arr, i, i - 1);
    } else {
        cb();
        return arr;
    }
}

class CourseClassInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showCoverModal: false,
            showVideoModal: false,
            signedUrl: '',
            size: null,
        };
        this.selfInit();
    }

    selfInit = () => {
        this.k2v = {
            title: '标题', type: '课程类型', url: '视频链接', teacher: '教师', length: '视频时长',
            exercise: '试题练习', courseNum: '第几天', courseId: '课程ID', advOptions: '是否显示问题描述/学习目标/内容要点'
        };
        this.stringInputs = ['title'];
        this.numberInputs = ['courseNum'];
        this.switchInputs = ['advOptions'];
    }

    closeVideoModal = () => {
        this.setState({ showVideoModal: false });
    };

    videoPickerFeedBack = async (v) => {
        this.props.dispatch(fill_url(v));
        let signedUrl = await getAvailableVideoUrl(v)
        this.setState({ signedUrl, loading: true });
        let vEle = this.refs.video;
        vEle.onloadedmetadata = async () => {
            this.props.dispatch(valueInput('length', Math.floor(vEle.duration)));
            this.setState({ showVideoModal: false, loading:false });
        }
    };

    videoPickerSize = value => {
        this.setState({ size: value })
    };

    componentDidMount() {
        if (this.determineNeedFetch()) {
            this.props.dispatch(getCourseData(this.props.match.params.classId));
        } else {
            this.props.dispatch(fill_courseId(this.props.match.params.courseId));
        }
        this.props.dispatch(fetchCourseTeachers());
    }

    /** 判断是否需要从服务端获取课时数据 */
    determineNeedFetch = () => {
        let result = false;
        let info = this.props.info || {};
        if (this.props.match.params.classId) result = true;       // 如果只判断classId就进行加载的话，那么后续没有提交到server的新增操作都会丢失，而对于一个课时来说，视频链接是一定存在的
        return result;
    }

    /** 点击提交，校验数字，对courseNum以及length来说，他们都不能为0 */
    submit = () => {
        if (this.props.info.exercise && this.props.info.exercise.questionIds != null && !this.props.info.exercise.questionIds.length) {
            message.warning('请选择试题');
            return;
        }
        const { courseType } = this.props.match.params;
        let { info } = this.props;
        let blackList = ['advOptions', 'courseId', 'courseNum', 'length', 'teacher', 'title', 'type', 'url'];
        if (validateUtil(info, blackList, this.k2v)) return;
        if (info.courseNum == 0) { message.warning('第几天不能为0'); return; }
        if (info.length == 0) { message.warning('视频时长不能为0'); return; }
        if (this.state.size) info.size = this.state.size;
        if (info.size == 0 || info.size == null) { message.warning('视频size未获取，请重试'); return; }
        let handlerObj = { ...info };
        const cb = () => this.props.history.push(`/course/${info.courseId}/classes/${courseType}`);
        this.props.dispatch(updateClass(handlerObj, courseType, cb));
    };

    /** 更新已选试题，但是里面所选的数据是每一道题的ID */
    updateQuesArr = arr => {
        this.props.dispatch(setExerciseDetail('questionIds', arr));
    }

    /** 更新已选试题，里面所存储的是每一道题目的详情数据信息 */
    updateSelQues = arr => {
        let exercise = (this.props.info && this.props.info.exercise) || {};
        if (exercise.exerciseType == 1 || exercise.exerciseType == 5) { // 申论和综应
            this.props.dispatch(setExerciseDetail('slZyQuestions', arr));
        } else { // 行测，职测，公基            
            this.props.dispatch(setExerciseDetail('questions', arr));
        }
    }

    /** 到达首位的提示 */
    cb = () => {
        message.success('已经到达第一个位置');
    }

    /** 申论试题点击up */
    handleShenlunUpQues = id => {
        let exercise = this.props.info.exercise || {};
        let targetIndex, t2;
        if (!exercise || Object.prototype.toString.call(exercise.slZyQuestions) !== '[object Array]') return;
        if (!exercise || Object.prototype.toString.call(exercise.questionIds) !== '[object Array]') return;
        targetIndex = exercise.slZyQuestions.findIndex(obj => obj.id == id);
        var tempQuestions = [...exercise.slZyQuestions];
        var newQuestions = arrUp(tempQuestions, targetIndex, exercise.slZyQuestions.length, this.cb);
        t2 = exercise.questionIds.findIndex(qid => qid == id);
        var tempQuestionIds = [...exercise.questionIds];
        var newQuestionIds = arrUp(tempQuestionIds, t2, exercise.questionIds.length, this.cb);
        this.props.dispatch(setExerciseDetail('questionIds', newQuestionIds));
        this.props.dispatch(setExerciseDetail('slZyQuestions', newQuestions));
    }

    /** 这个valueInput是必要的 */
    handleTeacherSelect = value => {
        let teachers = value.join(',');
        this.props.dispatch(fill_teacher(teachers));
    }

    /** 监听输入条件值 */
    valueInput = (key, value) => {
        this.props.dispatch(valueInput(key, value));
    }

    /** 监听Switch Input */
    bool2NumInput = (key, value) => {
        value = (value === true ? 1 : 0);
        this.props.dispatch(valueInput(key, value));
    }

    /** 点击删除练习题按钮 */
    deleteExercise = () => {
        this.props.dispatch(setExerciseDetail('questions', null));
        this.props.dispatch(setExerciseDetail('slZyQuestions', null));
        this.props.dispatch(setExerciseDetail('questionIds', null));
    }

    getName = () => {
        let { courseType } = this.props.match.params;
        if (courseType == 0) return '行测';
        if (courseType == 1) return '申论';
        if (courseType == 3) return '公基';
        if (courseType == 4) return '职测';
        if (courseType == 5) return '综应';
        return '未知';
    }

    /** QuestionSelectCard组件部分接收字母 */
    getNumberType = (courseType) => {
        if (courseType == 0) return 0;
        if (courseType == 1) return 1;
        if (courseType == 3) return 'gj';
        if (courseType == 4) return 'zc';
        if (courseType == 5) return 'zy';
    }

    /** 添加新的类型试题 
     *{ label: '行测', value: 0 },
     *{ label: '申论', value: 1 },
     *{ label: '公基', value: 3 },
     *{ label: '职测', value: 4 },
     *{ label: '综应', value: 5 },
    */
    addNewExercise = () => {
        let { courseType } = this.props.match.params;
        this.props.dispatch(setExerciseDetail('exerciseType', courseType));
        this.props.dispatch(setExerciseDetail('questionIds', []));
        if(courseType==5 || courseType==1) { // 申论，综应
            this.props.dispatch(setExerciseDetail('slZyQuestions', []));
        } else {  // 行测，职测，公基
            this.props.dispatch(setExerciseDetail('questions', []));
        }
    }

    render() {
        let { info } = this.props;
        let exercise = (info && info.exercise) || {};
        if (this.props.isFetching) {
            return <Spin />
        }
        return (
            <div className="courseClassInfo">
                {
                    this.stringInputs.map((key, idx) => (
                        <div key={idx} className="inputRow">
                            <label>{this.k2v[key]}</label>
                            <Input type="text" value={info[key]} onChange={event => this.valueInput(key, event.target.value)} placeholder={`请输入${this.k2v[key]}`} />
                        </div>
                    ))
                }

                {
                    this.numberInputs.map((key, idx) => (
                        <div key={idx} className="inputRow">
                            <label className="blockInline">{this.k2v[key]}</label>
                            <InputNumber value={info[key]} min={0} onChange={value => this.valueInput(key, value)} className="w100" />
                        </div>
                    ))
                }

                <div className="inputRow">
                    <label>教师信息</label>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="请选择教师"
                        onChange={this.handleTeacherSelect}
                        value={(info && info.teacher && info.teacher.split(',')) || []}
                    >
                        {
                            this.props.teacherArr.map((teacher, idx) => (
                                <Option key={idx} value={teacher}>{teacher}</Option>
                            ))
                        }
                    </Select>
                </div>

                <div className="inputRow">
                    <label>课程类型</label>
                    <Select
                        value={info.type}
                        style={{ width: '100%' }}
                        placeholder="请选择类型信息"
                        onChange={value => { this.valueInput('type', value) }}
                    >
                        <Option value={0}>录播</Option>
                        <Option value={1}>直播</Option>
                    </Select>
                </div>

                {
                    this.switchInputs.map((key, idx) => (
                        <div key={idx} className="inputRow">
                            <label className="mr6">{this.k2v[key]}</label>
                            <Switch checked={info[key] === 1} onChange={value => this.bool2NumInput(key, value)} />
                        </div>
                    ))
                }

                <div className="inputRow">
                    <label>视频 </label>
                    <br />
                    <Button type="primary" onClick={() => this.setState({ showVideoModal: true })}>选择</Button>
                    <Input type="text" disabled={true} value={info.url} className="w80" />
                    <span className="mr10f">{info.url ? '时长:' + info.length + 's' : ''}</span>
                </div>

                {
                    info.url &&
                    <video id="video" ref="video" src={this.state.signedUrl} style={{ 'display': 'none' }}>
                    </video>
                }

                {
                    this.state.showVideoModal &&
                    <VideoPicker
                        pubName={OSS_PUB_GK_COURSE}
                        videoKey={"key"}
                        showModal={this.state.showVideoModal}
                        closeModal={this.closeVideoModal}
                        feedBack={this.videoPickerFeedBack}
                        getSize={this.videoPickerSize}
                    />
                }

                {
                    !exercise.questionIds &&
                    <div>
                        <Button type="primary" style={{margin: '20px 0'}} onClick={()=>this.addNewExercise()}>添加{this.getName()}练习题</Button>
                    </div>
                }

                {
                    exercise.questionIds &&
                    <div>
                        <h3 style={{ margin: "20px 0" }}>{this.getName()}练习题</h3>
                        <Button type="primary" style={{ 'marginBottom': '10px' }} onClick={this.deleteExercise}>删除练习题</Button>
                        <div className="flex-container">
                            <QuestionSelectCard
                                quesArr={(exercise && exercise.questionIds) || []}
                                selQuesList={(exercise && (exercise.questions || exercise.slZyQuestions)) || []}
                                updateQuesArr={this.updateQuesArr}
                                updateSelQues={this.updateSelQues}
                                exerciseType={this.getNumberType(exercise.exerciseType)}
                            />
                        </div>
                    </div>
                }

                {checkPermission('sys:class:edit') &&
                    <Card className="cardR" size="small" title="发布">
                        <Button loading={this.state.loading} type="primary" disabled={this.props.isUpdating} onClick={this.submit}>
                            {this.props.match.params.classId ? "更新" : '保存'}
                        </Button>
                        {this.state.loading && <p>视频时长更新中，请稍等...</p>}
                    </Card>}
            </div>

        )
    }
}

function mapStatetoProps(state) {
    const { teachers } = state.courseList;
    return {
        teacherArr: teachers,
        ...state.courseClassInfo
    };
}

export default connect(mapStatetoProps)(CourseClassInfo);
