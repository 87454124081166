import apiRequest from '../../common/request'
import { message } from 'antd';
// 命名冲突会触发多个reducer，降低性能，同时导致未知问题，故变量使用唯一前缀
const PREFIX = "MATERIAL_QUESTION_RELEASE_";
export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const FILL_CONTENT = PREFIX + "FILL_CONTENT";
export const REQUEST_PUBLISH = PREFIX + "REQUEST_PUBLISH";
export const RECEIVE_PUBLISH_SUC = PREFIX + "RECEIVE_PUBLISH_SUC";
export const RECEIVE_PUBLISH_FAIL = PREFIX + "RECEIVE_PUBLISH_FAIL";
export const FILL_QUESTIONS = PREFIX + "FILL_QUESTIONS";
export const FILL_QARR = PREFIX + "FILL_QARR";

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalElements: data.totalElements,
    }
}

function request_publish() {
    return {
        type: REQUEST_PUBLISH
    }
}

function receive_publish_suc() {
    return {
        type: RECEIVE_PUBLISH_SUC
    }
}

function receive_publish_fail() {
    return {
        type: RECEIVE_PUBLISH_FAIL
    }
}

export function fill_content(value) {
    return {
        type: FILL_CONTENT,
        key: 'content',
        value
    }
}

export function fill_qArr(value) {
    return {
        type: FILL_QARR,
        key: 'questionIds',
        value
    }
}

export function fill_questions(value) {
    return {
        type: FILL_QUESTIONS,
        key: 'questions',
        value
    }
}

export function publish(type, info) {
    let url = `/gk/ops/materials/saveOrUpdate`;
    if (type != 'xc') url = `/gk/ops/${type}/materials/saveOrUpdate`;
    let data = {
        content: info.content,
        questionIds: info.questionIds.toString()
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_publish());
        return apiRequest(url, config).then(json => {
            if (json.status == 1) {
                message.success('操作成功');
            }
            dispatch(receive_publish_suc());
        }).catch(error => {
            dispatch(receive_publish_fail());
            console.log(error)
        });
    }
}
