import React from 'react';
import { connect } from 'react-redux';
import { fetchStudentDetail, resetState, getSort } from './action';
import { Table, Tooltip, Button, Popover, message } from "antd";
import Answer from '../../components/Answer/index';
import './index.scss';
import { getSearchQuery, formatSeconds } from '../../utils/utils';

class StudentDetailRank extends React.Component {
    componentDidMount() {
        this.active = false;        
        this.classId = this.props.match.params.classId;                   // 没有必要设置为state，下面同理
        this.userId = this.props.match.params.userId;
        this.props.fetchStudentDetail(this.classId, this.userId);
        localStorage.getItem('studentList')  && this.checkShowNextAndPrev();
    }

    componentWillUnmount() {
        this.props.resetState();
    }

    /** 生成antd table的配置数据 */
    generateColumns = () => {
        let result = [
            {
                title: '练习名字',
                dataIndex: 'title',
                width: '20%'
            },
            {
                title: '发布时间',
                width: '20%',
                dataIndex: 'publishDate'
            },
            {
                title: '提交时间',
                width: '20%',
                render: record => <span id={record.gmtCreate && (this.props.lastDate == record.gmtCreate) && 'lastDate' || ''}>{record.gmtCreate}</span>,
            },
            {
                title: '耗时',
                dataIndex: 'times',
                width: '10%',
                render: text => {
                    let sec = formatSeconds(text);
                    return <span>{sec}</span>
                },
                sorter: (item1, item2) => item1.times > item2.times
            },             
            {
                title: '正确率',
                dataIndex: 'accuracy',
                width: '10%',
                sorter: (item1, item2) => {
                    if (!item1 || !item2 || !item1.accuracy || !item2.accuracy) {
                        return false;
                    }
                    let l1 = item1.accuracy.length;
                    let l2 = item2.accuracy.length;
                    let c1 = Number(item1.accuracy.substring(0, l1 - 1));
                    let c2 = Number(item2.accuracy.substring(0, l2 - 1));
                    return c1 - c2;
                }
            }, 
            {
                title: '分数',
                dataIndex: 'point',
                width: '10%',
                sorter: (a, b) => a.point - b.point
            },
            {
                title: '排名',
                width: '10%',
                dataIndex: 'rank'
            }
        ];
        return result;
    }

    renderMore = record => {
        let categorys = record.categorys || [];        
        if (!categorys.length) {
            return <h4>未完成</h4>
        }
        let num = 0;
        return (
            <div>
                {
                    categorys.map((answerObj, idx) => (
                        <div key={idx}>
                            <div style={{margin: "10px 0"}}>{answerObj.categoryName + ' - 共' + answerObj.questionNum + '题 - 答对' + this.doCorrect(answerObj.answers) + '题' || ''}</div>
                            <div className="examineAnswerLists">
                                {answerObj.answers.map(item => { item.num = num; num++ })}
                                {answerObj.answers.map((item, index) => <Answer key={index} answerObj={item} questionIndex={item.num} />)}
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    /** 计算答对题目个数 */
    doCorrect = (arr) => {
        let isCorrect = 0;
        arr.map(item=> item.correct==1 && isCorrect++);
        return isCorrect;
    }

    /** 检查是否有必要出现底部的功能区域 */
    checkShowNextAndPrev = () => {
        let studentIds = [];
        let arr = [];
        let classId = this.props.match.params.classId;
        try { studentIds = window.localStorage.getItem('studentList'); }
        catch(err) { }
        studentIds = JSON.parse(studentIds);                
        studentIds.map(item => {
            if(item==null) return;
            if(classId == item[0].classId){
                arr = item;                
            }
        })
        this.studentList = arr;                                    
        this.studentIds = arr.map(item=>item.userId);
    }
    /** 点击上一位学员 */
    prevStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex <= 0) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex, true);
        this.jump2NextPrevStudent(nextStudentId);
    }
    /** 点击下一位学员 */
    nextStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();        
        if (currentIndex >= this.studentIds.length - 1) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex);                
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 返回当前学员的ID */
    findCurrentStudentIdx = () => {        
        if (Object.prototype.toString.call(this.studentIds) != '[object Array]') return;
        this.userId = this.props.match.params.userId;
        return this.studentIds.indexOf(+this.userId);
    }

    /** 返回上下位学员的ID */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.studentIds[currentIndex - 1];
        else return this.studentIds[currentIndex + 1];
    }

    /** 页面跳转到上下位学员 */
    jump2NextPrevStudent = studentId => {
        let className = getSearchQuery('className');
        let url = `/exerciseStudentDetail/${studentId}/${this.props.match.params.classId}?className=${className}`;
        this.props.history.replace(url);
        this.props.fetchStudentDetail(this.props.match.params.classId, studentId);
    }

    /** 纯js实现Excel下载，不依赖接口 */
    excelDown = () => {

        let className = getSearchQuery('className');
        let userName = this.props.detailArr[0].userName;
        let fileName = className + '-' + userName + '练习数据';
         let html = "<html><head><meta charset='utf-8' /></head><body>" + document.querySelector(".ant-table-wrapper").outerHTML + "</body></html>";
         
         html = html.replaceAll('<span class="userAnswer">', '<span class="userAnswer">错误')
         html = html.replaceAll('<span class="userAnswer correct">', '<span class="userAnswer correct">正确')
        // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
        let blob = new Blob([html], {type: "application/vnd.ms-excel"});        
        let a = document.createElement('a');
        // 利用URL.createObjectURL()方法为a元素生成blob URL
        a.href = URL.createObjectURL(blob);
        // 设置文件名
        a.download = `${fileName}.xlsx`;
        a.click();
    }

    /** 定位最近提交时间 */
    goTo = () => {
        if(this.props.lastDate) {
            let a = document.querySelector('#lastDate');
            a.scrollIntoView(true);
            a.className='active';
            setTimeout(()=>{
                a.classList.remove('active');
            }, 1000)
        } else {
            message.warn('最近未提交');
        }
    }

    render() {
        let tbodyH = parseInt(document.body.clientHeight-240);
        let stuList = this.studentList && (
            <div className="popoverStu">
                {this.studentList.map((item,idx)=> <p key={idx} className={['classStuList', item.userId==this.userId?'activeStu':''].join(' ')} onClick={()=>this.jump2NextPrevStudent(item.userId)}>{idx+1+'-'+item.userName}</p>)}
            </div>
        ) || (<div>暂无列表</div>)
        const columns = this.generateColumns();
        let currentIndex = this.findCurrentStudentIdx();                                  // 当前学员的索引位置
        let prevNums = currentIndex || 0;                                                      // 当前学员前面还有多少位学员
        let nextNums = this.studentIds ? this.studentIds.length - currentIndex - 1 : 0;   // 当前学员后面还有多少位学员
        this.props.detailArr.length && this.props.detailArr.map((item,idx) => item.key = idx );//添加唯一索引
        return ( 
            <div className="exerciseRankStudent">
                <div className="changeBtn">
                    <span className="name">学员练习数据展示-{this.props.detailArr&&this.props.detailArr[0]&&this.props.detailArr[0].userName}</span>
                    <Tooltip title={`前面还有${prevNums}位学员`}>
                        <Button className="ml10" disabled={!prevNums || this.studentIds && currentIndex <= 0} onClick={this.prevStudent} type="primary">上一位学员</Button>
                    </Tooltip>
                    <Tooltip title={`后面还有${nextNums}位学员`}>
                        <Button className="ml10" disabled={!nextNums || this.studentIds && currentIndex >= this.studentIds.length-1} onClick={this.nextStudent} type="primary">下一位学员</Button>
                    </Tooltip>
                    <Popover content={stuList} title="学员列表" trigger="click">
                        <Button className="ml10" type="primary">学员列表</Button>
                    </Popover>
                    <Button className="ml10" onClick={this.excelDown} type="primary">导出Excel</Button>
                    <Button className="ml10" onClick={()=>this.props.getSort(this.props.detailArr)}>已做排序</Button>
                    <Button className="ml10" onClick={()=>this.props.fetchStudentDetail(this.classId, this.userId)}>排序重置</Button>
                    <Button className="ml10" onClick={()=>this.goTo()}>最近做题</Button>
                </div>
                <Table
                    scroll={{ y: tbodyH }}
                    rowKey={record => record.key}
                    bordered
                    loading={this.props.fetching}
                    columns = {columns}
                    pagination = {false}
                    size = "default"
                    dataSource = {this.props.detailArr}
                    expandedRowRender={record => this.renderMore(record)}
                />
            </div>
        );
    }
}

const mapStateToProps = storeState => ({ ...storeState.exerciseRankStudent });

const mapDispatchToProps = dispatch => ({
    fetchStudentDetail: (classId, userId) => { dispatch(fetchStudentDetail(classId, userId))},
    getSort: (arr) => dispatch(getSort(arr)),
    resetState: () => { dispatch(resetState())}
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetailRank);