import {
    IMAGE_PICKER_LAST_PAGE, IMAGE_PICKER_NEXT_PAGE, IMAGE_PICKER_RECEIVE_CONTENT_OF_IMAGE_DIRECTORY,
    IMAGE_PICKER_RECEIVE_IMAGES,
    IMAGE_PICKER_REQUEST_IMAGES, IMAGE_PICKER_RESET, IMAGE_PICKER_RESET_IMAGE_MARKERS, IMAGE_PICKER_START_UPLOAD,
    IMAGE_PICKER_UPLOAD_FINISH
} from "./action";

const initialState = {
    isFetching: true,
    isSending: false,
    marker: null,   // 到达下一页的 marker
    prefixes: [],
    prefix: "",     //当前页面的prefix,默认为""
    lastPrefix: null,    //返回上一层的 prefix
    markers: [],    // 存储用于返回到上一页的marker，第-1是当前页的marker，-2是上一页的marker，以此类推
    items: [],
    uploadedUrl: null   // 已上传图片返回的url
};


export function imagePicker(state = initialState, action) {
    switch (action.type) {
        case IMAGE_PICKER_REQUEST_IMAGES:
            return Object.assign({}, state, {
                isFetching: true
            });
        case IMAGE_PICKER_RECEIVE_IMAGES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.images,
                marker: action.marker,
                prefixes: action.prefixes
            });
        case IMAGE_PICKER_LAST_PAGE:
            return {
                ...state,
                markers: state.markers.slice(0, -1),
            };
        case IMAGE_PICKER_NEXT_PAGE:
            return {
                ...state,
                markers: [...state.markers, action.marker],
            };
        case IMAGE_PICKER_RECEIVE_CONTENT_OF_IMAGE_DIRECTORY:
            return {...state, prefix: action.prefix};
        case IMAGE_PICKER_RESET_IMAGE_MARKERS:
            return {...state, markers: []};
        case IMAGE_PICKER_UPLOAD_FINISH:
            return {...state, isSending: false,uploadedUrl: action.url};
        case IMAGE_PICKER_START_UPLOAD:
            return {...state, isSending: true};
        case IMAGE_PICKER_RESET:
            return initialState;
        default:
            return state;
    }
}