import apiRequest from '../../common/request'
import { message } from 'antd';

const PREFIX = "COURSE_CLASS_LIST_";
export const REQUEST_LIST = PREFIX + "REQUEST_LIST";
export const RECEIVE_LIST = PREFIX + "RECEIVE_LIST";
export const REQUEST_DELETE = PREFIX + "REUQEST_DELETE";
export const RECEIVE_DELETE = PREFIX + "RECEIVE_DELETE";
export const UPDATE_DAY = PREFIX + "UPDATE_DAY";
export const COMMON_CHANGE = PREFIX + "COMMON_CHANGE";

export function commonChange(key, val) {
    return {type: COMMON_CHANGE, key, val}
}

function request_list() {
    return {
        type: REQUEST_LIST
    }
}

function receive_list(data) {
    return {
        type: RECEIVE_LIST,
        list: data,
    }
}

function receive_delete(id) {
    return {
        type: RECEIVE_DELETE,
        id
    }
}

export function deleteClass(id) {
    let config = {
        method: 'POST',
        body: JSON.stringify([id]),
        headers: { 'Content-Type': 'application/json' },
    };
    return dispatch => {
        dispatch(commonChange('isFetching', true));
        const hide = message.loading('正在检测该课时和用户关联性...', 0);
        apiRequest(`/gk/ops/course/class/del`, config)
          .then(json => {
              if(json.status==1) {
                dispatch(commonChange('isFetching', false));
                dispatch(receive_delete(id));
                message.success('删除课时成功');
                setTimeout(hide, 0);
              }
          })
          .catch(error => console.log(error));
    }
}

export function fetchClasses(courseId) {
    let data = {
        courseId
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_list());
        return apiRequest("/gk/ops/course/class/list", config)
          .then(json => {
              dispatch(receive_list(json.obj));
          })
          .catch(error =>{dispatch(commonChange('isFetching', false));console.log(error)});
    }
}

export function changeClassList(lists) {
    return dispatch => {
        dispatch(receive_list(lists));
    }
}

export function updateDay(index, value) {
    return {
        type: UPDATE_DAY,
        index,
        value
    }
}

export function updateClassList(courseId, ids) {

    return dispatch => {
        const params = {
            classIds: [],
            courseId,
            reorders: ids
        };

        let config = {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        };
        dispatch(commonChange('isFetching', true));
        return apiRequest("/gk/ops/course/reorder", config)
          .then(json => {
            dispatch(commonChange('isFetching', false));
            message.success('更新成功');
          })
          .catch(error =>{dispatch(commonChange('isFetching', false));console.log(error)});
    }
}

export async function updateCourseNum(data) {
    if (!data) return;
    let url = `/gk/ops/course/class/modifiedDays/${data.id}/${data.courseNum}`;
    let config = {
        method: 'GET', credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    try {
        let response = await apiRequest(url, config);
        return response;
    } catch(err) { return null; }
}