import {
    OPEN_MODLE,
    SET_STUDENT_CLASS,
    CHANGE_CURRENT_TAB,
    CHANGE_CURRENT_TAB_VALUE,
    GET_CLASS_DETAILS,
    DEAL_SELECT_REDUX,
    HANDLE_CURRENT_CLASS,
    CLEAR_SEARCH_CRITERIA,
} from './action';
import {
    GET_HEAD_TEACHER_INFORM,
    GET_TEACHER_INFORM,
    GET_OPERATION_INFORM,
    SEARCH_USER_INFO
} from '../ContractOrder/action';

const initSearch = {
    userModle: {id:'',open:false},
    classModle: {id:'',open:false},
    isStatus: null,                             // 是否退款
    custormers: [],                             // 客户集合
    userInfoIds: undefined,                     // 客户搜索id集合 
    classId: '',                                // 班级id
    classes: [],                                // 搜索班级集合
    responseObj: [],                            // 服务端返回的数据
    startDate: '',                              // 查询起始时间
    endDate: '',                                // 查询结束时间
    classIdx: null,                             // 班级索引
    classDetail: null,                          // 单个班级详情
    currentClassName: undefined,                    // 当前班级名字
    isSearch: false,                            // 客户框是否可搜索
};

const initialState = {
    current: 'supervise',                           // 默认当期tab栏
    currentTab: '盯学',
    teacherList: [],
    classHeadList: [], // 班主任
    saleList: [],
    careTeacherId: '',
    careClassList: [],
    careDate: '',
    ...initSearch
};

export const inquiryReducer = (previousState=initialState, action) => {
    switch(action.type) {
        case OPEN_MODLE:
            return { ...previousState, [ action.key ]: action.value };
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case CHANGE_CURRENT_TAB:
            return { ...previousState, current: action.value };
        case CHANGE_CURRENT_TAB_VALUE:
            return { ...previousState, currentTab: action.value };
        case GET_CLASS_DETAILS:
            return { ...previousState, responseObj: [...action.data] };
        case DEAL_SELECT_REDUX:
            return { ...previousState, [ action.key ]: action.value  };
        case HANDLE_CURRENT_CLASS:
            return { ...previousState, classDetail: { ...action.data } };
        case CLEAR_SEARCH_CRITERIA:
            return { ...previousState, ...initSearch };
        case GET_HEAD_TEACHER_INFORM:
            return { ...previousState, classHeadList: [ ...action.data ] };
        case GET_TEACHER_INFORM:
            return { ...previousState, teacherList: [ ...action.data ] };
        case GET_OPERATION_INFORM: 
            return { ...previousState, saleList: [ ...action.data ] };
        case SEARCH_USER_INFO: 
            return { ...previousState, custormers: action.data };
        default:
            return previousState;
    }
};