import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { publishPushedInfo } from './actions';

import { Card, Table, Radio, Input, Select, Button, Checkbox, message } from 'antd';

import { fetchCourses, setCurrentPage, setPageSize, fetchCourseLabels, fetchCourseTeachers } from "../CourseList/action";
import { fetchHeadTeacher } from "../ClassList/actions";
import { fetchCourseCategories } from "../ModuleSetting/actions";
import { fetchExamines, setPageSize as dailyExamineListPageSize, setActivePage } from "../DailyExamineList/actions";

const { Option } = Select;

class InfoPushRelease extends Component {

    constructor() {
        super();
        this.state = {
            display_type: 'notification',           // 消息通知类型: notification:通知,message: 消息
            id: "0",                                //推送数据唯一标志码，默认为0
            play_vibrate: 0,                        // 是否震动,
            play_lights: 0,                         // 是否闪烁
            play_sound: 0,                          // 是否发出声音,
            after_open: 'go_custom',                // 点击"通知"的后续行为，默认为打开app
            input:'',                               //搜索框文本
            type: 1,
            deviceType: 1,
            selectedCourse: '',                     //选中课程数据
            selectedExamine: '',                    //选中的每日一练数据
            title: '',                              // 通知标题
            text: '',                               // 通知文字内容
        };
        //以下是用来存储筛选条件的变量
        this.categoryId = 0;
        this.label = '';
        this.teacher = '';
        this.headTeacher = '';
        this.inputValue = '';
    }

    //条件筛选下拉框select改变时统一经过这个方法
    changeLoc = (key, val) => {
        this[key] = val
        this.props.dispatch(setCurrentPage(1))
        this.props.dispatch(fetchCourses({
            page: 1,
            pageSize: this.props.pageSize,
            categoryId: this.categoryId,
            label: this.label,
            teacher: this.teacher,
            headTeacher: this.headTeacher,
            title: this.inputValue
        }));
    };

    //点击搜索按钮触发
    handleSearch = () => {
        this.changeLoc('title', this.inputValue)
    }

    //重置搜索条件触发
    resetSearch = () => {
        this.categoryId = 0;
        this.label = '';
        this.teacher = '';
        this.headTeacher = '';
        this.inputValue = '';
        this.setState({ input: '' });
        this.props.dispatch(setCurrentPage(1));
        this.props.dispatch(fetchCourses({
            page: 1,
            pageSize: this.props.pageSize,
        }));
    }

    // 输入框改变
    inputChangeHandler(value, key) {
        this.setState({
            [key]: value,
        })
    }

    changeDeviceType(type) {
        this.setState({
            deviceType: type,
        })
    }

    onChangeRadio(e) {
        this.setState({ deviceType: e.target.value })
    }

    selectChange(value) {
        this.setState({ type: value });
        if (value == 2) {
            this.categoryId = 0;
            this.label = '';
            this.teacher = '';
            this.headTeacher = '';
            this.inputValue = '';
            this.props.dispatch(setCurrentPage(1));
            this.props.dispatch(fetchCourses({ page: 1, pageSize: 10 }));
            this.props.dispatch(fetchCourseCategories());
            this.props.dispatch(fetchCourseLabels());
            this.props.dispatch(fetchCourseTeachers());
            this.props.dispatch(fetchHeadTeacher());
        }
        if (value == 3) {
            this.props.dispatch(fetchExamines(1, 10));
        }
    }

    /** 处理分类回显 */
    getName = (arr) => {
        return this.props.categories.length && arr.map(item => (this.props.categories.find(v => v.id == item).name + ' '))
    }

    handleSelect = (current) => {
        this.props.dispatch(setCurrentPage(current))
        this.props.dispatch(fetchCourses({
            page: current,
            pageSize: this.props.pageSize,
            categoryId: this.categoryId,
            label: this.label,
            teacher: this.teacher,
            headTeacher: this.headTeacher,
            title: this.inputValue
        }));
    }

    handleSelectDailyExamines = (current) => {
        this.props.dispatch(setActivePage(current))
        this.props.dispatch(fetchExamines(current, this.props.dailyExaminesPageSize));
    }

    selectCourse(course) {
        this.setState({
            selectedCourse: course,
            id: course.id
        })
    }

    removeSelectedCourse() {
        this.setState({
            selectedCourse: ''
        })
    }

    selectExamine(examine) {
        this.setState({
            selectedExamine: examine,
            id: examine.id
        })
    }

    removeSelectedExamine() {
        this.setState({
            selectedExamine: ''
        })
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = (type) => {
        let { selectedCourse } = this.state;
        let columns = [
            {
                title: '课程标题',
                width: '20%',
                render: (text, record) => (
                    <Link to={`/course/${record.id}`} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.title }}></Link>
                ),
            },
            {
                title: '分类',
                width: '20%',
                dataIndex: 'categoryName',
                key: 'categoryName',
                render: (text, record) => this.getName(record.categoryIds)
            },
            {
                title: '班主任',
                width: '10%',
                dataIndex: 'headTeacher',
                key: 'headTeacher'
            },
            {
                title: '起止时间',
                width: '20%',
                dataIndex: 'period',
                key: 'period',
            },
            {
                title: '教师详情',
                width: '20%',
                dataIndex: 'text',
                key: 'text',
            },
            {
                title: '选择',
                width: '10%',
                key: 'id',
                render: (text, record) => {
                    return type && <input type="radio" name="course"
                        data-target={record.id}
                        onChange={() => { }}
                        checked={selectedCourse.id == record.id}
                        onClick={e => this.selectCourse(record)} />
                        ||
                        <input type="radio"
                            data-target={selectedCourse.id}
                            checked={true}
                            onChange={() => { }}
                            onClick={e => this.removeSelectedCourse()}
                        />
                }
            }
        ];
        return columns;
    }

    /** 生成antd table2的配置信息 */
    generateTableColumns2 = (type) => {
        let { selectedExamine } = this.state;
        let columns = [
            {
                title: '标题',
                width: '25%',
                render: (text, record) => (
                    <Link to={`/dailyExamine/${record.id}`} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.title }}></Link>
                ),
            },
            {
                title: '时长',
                width: '25%',
                dataIndex: 'limitTime',
                key: 'limitTime',
            },
            {
                title: '上线时间',
                width: '25%',
                dataIndex: 'publishDate',
                key: 'publishDate'
            },
            {
                title: '选择',
                width: '25%',
                key: 'id',
                render: (text, record) => {
                    return type && <input type="radio" name="examine"
                        data-target={record.id}
                        onChange={() => { }}
                        checked={selectedExamine.id == record.id}
                        onClick={e => this.selectExamine(record)} />
                        ||
                        <input type="radio"
                            data-target={selectedExamine.id}
                            checked={true}
                            onChange={() => { }}
                            onClick={e => this.removeSelectedExamine()}
                        />
                }
            }
        ];
        return columns;
    }

    // 发布消息推送, 1正式环境
    publish = () => {
        if (!this.state.title.trim().length) {
            message.warn('请输入通知标题');
        } else if (this.state.type == 2 && this.state.selectedCourse == '') {
            message.warn('请选择课程');
        } else if (this.state.type == 3 && this.state.selectedExamine == '') {
            message.warn('请选择每日一练')
        } else {
            let obj = {};
            let {deviceType, id, type, title, text, play_vibrate, play_lights, play_sound} = this.state;
            if (deviceType == 1) { //ios
                obj = {
                    id,                                // 推送数据唯一标志码，默认为0
                    type,                              // 1咨讯，2课程，3每日一练
                    title,                             // 通知标题
                    body: text,                        // 通知文字内容
                }
            } else if (deviceType == 2) { // 安卓
                obj = {
                    display_type: 'notification',      // 消息通知类型: notification:通知,message: 消息
                    after_open: 'go_custom',           // 点击"通知"的后续行为，默认为打开app
                    id,                                // 推送数据唯一标志码，默认为0
                    type,                              // 1咨讯，2课程，3每日一练
                    title,                             // 通知标题
                    text,                              // 通知文字内容
                    play_vibrate, play_lights, play_sound
                }
            } else {
                return message.error('未知类型');
            }

            publishPushedInfo(obj, deviceType);                   // 检查通过，开始推送
            this.setState({
                selectedCourse: '',                             //推送完成后，将选中课程置空
                selectedExamine: '',
                id: "0"
            })
        }
    }

    changeIs = (e, key) => {
        let boolean = e.target.checked;
        this.setState({ [key]: boolean ? 1 : 0 });
    }

    render() {
        let { courseList, teachers,
            headTeachers,
            labels,
            categories,
            dailyExamineList,
            coursesIsFetching,
            dailyExaminesIsFetching } = this.props;
        let {
            selectedCourse,
            selectedExamine,
            play_vibrate,
            play_lights,
            play_sound,
        } = this.state;


        return (
            <div>
                <h4 style={{ margin: "10px 0", fontSize: '14px' }}>添加消息推送</h4>
                <label className="push-type-title">推送客户端类型</label>
                <Radio.Group className="mb20" onChange={e => this.onChangeRadio(e)} value={this.state.deviceType}>
                    <Radio value={1}>ios</Radio>
                    <Radio value={2}>安卓</Radio>
                </Radio.Group>
                {this.state.deviceType == 2 &&
                    <div>
                        <Checkbox checked={play_vibrate} onChange={e => this.changeIs(e, 'play_vibrate')}>是否震动</Checkbox>
                        <Checkbox checked={play_lights} onChange={e => this.changeIs(e, 'play_lights')}>是否闪烁</Checkbox>
                        <Checkbox checked={play_sound} onChange={e => this.changeIs(e, 'play_sound')}>是否发出声音</Checkbox>
                    </div>
                }

                <Input className="mb20" placeholder="通知文字标题" onChange={event => this.inputChangeHandler(event.target.value, 'title')} />

                <Input className="mb20" placeholder="通知文字内容" onChange={event => this.inputChangeHandler(event.target.value, 'text')} />

                <label>推送类型</label>
                <Select
                    className="mb20"
                    value={this.state.type}
                    style={{ width: 100 }}
                    onChange={value => this.selectChange(value)}
                >
                    <Option value={1} key='1'>资讯</Option>
                    <Option value={2} key='2'>课程</Option>
                    <Option value={3} key='3'>每日一练</Option>
                </Select>

                <br />
                {this.state.type == 2 &&
                    <div>
                        <Table
                            key={1}
                            columns={this.generateTableColumns(false)}
                            dataSource={selectedCourse != '' && [selectedCourse] || []}
                            rowKey={record => record.id}
                            title={() => '已选课程'}
                            loading={false}
                            pagination={false}
                        />

                        <div className="commonSearchBox">
                            <div className="itemSearchBox">
                                <label>分类</label>
                                <Select value={this.categoryId} style={{ width: 150 }} onChange={value => this.changeLoc('categoryId', value)}>
                                    {categories.map((cat, idx) => {
                                        return <Option key={idx} value={cat.id}>{cat.name}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <label>标签</label>
                                <Select value={this.label} style={{ width: 150 }} onChange={value => this.changeLoc('label', value)}>
                                    <Option value="">全部</Option>
                                    {labels.map((label, idx) => {
                                        return <Option key={idx} value={label}>{label}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <label>教师</label>
                                <Select value={this.teacher} style={{ width: 150 }} onChange={value => this.changeLoc('teacher', value)}>
                                    <Option value="">全部</Option>
                                    {teachers.map((teacher, idx) => {
                                        return <Option key={idx} value={teacher}>{teacher}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <label>班主任</label>
                                <Select value={this.headTeacher} style={{ width: 150 }} onChange={value => this.changeLoc('headTeacher', value)}>
                                    <Option value="">全部</Option>
                                    {headTeachers.slice(1).map((headTeacher, idx) => {
                                        return <Option key={idx} value={headTeacher}>{headTeacher}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="itemSearchBox">
                                <label>标题</label>
                                <Input placeholder="输入标题的关键词查询" className="commonW400" value={this.state.input}
                                    onChange={event => { this.inputValue = event.target.value; this.setState({ input: this.inputValue }) }} />
                            </div>
                            <div className="itemSearchBox">
                                <Button onClick={this.handleSearch} type="primary" style={{ marginRight: '20px' }}>搜索</Button>
                                <Button onClick={this.resetSearch} type="danger">清空搜索条件</Button>
                            </div>
                        </div>

                        <Table
                            key={2}
                            columns={this.generateTableColumns(true)}
                            dataSource={courseList}
                            rowKey={record => record.id}
                            title={() => '课程列表'}
                            loading={coursesIsFetching}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                current: this.props.currentPage,
                                pageSize: this.props.pageSize,
                                total: this.props.totalElements,
                                onChange: this.handleSelect,
                                showQuickJumper: true,
                                showTotal: total => `共有${total}条数据`,
                            }}
                        />

                    </div>
                }

                {this.state.type == 3 &&
                    <div>
                        <Table
                            columns={this.generateTableColumns2(false)}
                            dataSource={selectedExamine != '' && [selectedExamine] || []}
                            rowKey={record => record.id}
                            title={() => '已选每日一练'}
                            loading={false}
                            pagination={false}
                        />
                        <br />
                        <Table
                            columns={this.generateTableColumns2(true)}
                            dataSource={dailyExamineList}
                            rowKey={record => record.id}
                            title={() => '每日一练列表'}
                            loading={dailyExaminesIsFetching}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                current: this.props.activePage,
                                pageSize: this.props.dailyExaminesPageSize,
                                total: this.props.dailyExaminesTotalElements,
                                onChange: this.handleSelectDailyExamines,
                                showQuickJumper: true,
                                showTotal: total => `共有${total}条数据`,
                            }}
                        />

                    </div>
                }
                <Card title="推送" className="cardR" size="small">
                    <Button type="primary" onClick={() => this.publish()}>推送</Button>
                </Card>


            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        teachers,
        labels,
        currentPage,
        pageSize,
        totalElements,
        items: courseList,
        totalPages: coursesTotalPages,
        isFetching: coursesIsFetching
    } = state.courseList;

    const {
        items: categories
    } = state.courseCategories;

    const {
        pageSize: dailyExaminesPageSize,
        totalElements: dailyExaminesTotalElements,
        activePage,
        items: dailyExamineList,
        totalPages: dailyExaminesTotalPages,
        isFetching: dailyExaminesIsFetching
    } = state.dailyExamines;

    return {
        headTeachers: state.classList.headTeachers,                 // 这个是班主任列表数据
        teachers,
        labels,
        dailyExaminesPageSize,
        dailyExaminesTotalElements,
        activePage,
        currentPage,
        pageSize,
        totalElements,
        courseList,
        coursesTotalPages,
        coursesIsFetching,
        categories,
        dailyExamineList,
        dailyExaminesTotalPages,
        dailyExaminesIsFetching
    };
}

export default connect(mapStatetoProps)(InfoPushRelease);