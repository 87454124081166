import React from 'react';
import { Card, Input, InputNumber, Switch, Button, message, Spin } from 'antd';
import Editor from "../../components/Editor/index";
import { checkPermission, validateUtil } from '../../utils/utils';
import { updateGoods, getGoodsDetail } from './action';

class GoodsDetail extends React.Component {

  condition2Chinese = { name: '商品名', price: '价格', needAddress: '是否需要发货', publish: '上下架状态', productDesc: '详情信息' };

  inputConditions = ['name'];

  inputNumberConditions = ['price'];

  switchConditions = ['needAddress', 'publish'];

  needAddress1 = '需要发货'; needAddress0 = '不需要发货';

  publish1 = '上架'; publish0 = '下架';

  state = {
    name: null,
    price: null,
    needAddress: 0,
    publish: 0,
    productDesc: '',
    loading: false,                                              // 是否处于加载状态中
    publishConst: 0,                                             // 商品原本的上下架状态，如果原来是已上架的商品的话，那么不可点击修改按钮
  };

  async componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      this.setState({ loading: true });
      let json = await getGoodsDetail(id);
      if (json.status) {
        this.setStateFun(json.obj);
      } else {
        message.error('获取商品详情错误');
      }
    }
  }

  /** 填充state */
  setStateFun = obj => {
    let publish = obj.publish;
    this.setState({ publishConst: publish });
    const keys = Object.keys(this.condition2Chinese);
    let that = this;
    keys.map(key => { that.setState({ [key]: obj[key] }) });
    this.setState({ loading: false });
  }

  renderForm = () => (
    <div className='commonDivMb20'>
      {this.renderInputStructUI()}
      {this.renderInputNumberStructUI()}
      {this.renderSwitchStructUI()}
    </div>
  )

  setStateValue = (key, value) => {
    this.setState({ [key]: value });
  }

  renderInputStructUI = () => {
    return this.inputConditions.map((key, idx) => (
      <div key={idx}>
        <label style={{width: '10em'}}>{this.condition2Chinese[key]}{key === 'name' ? '-不能超过18字' : ''}</label>
        <Input value={this.state[key]} onChange={event => this.setStateValue(key, event.target.value)} className="commonW400" />
      </div>
    ))
  }

  renderInputNumberStructUI = () => {
    return this.inputNumberConditions.map((key, idx) => (
      <div key={idx}>
        <label style={{width: '10em'}}>{this.condition2Chinese[key]}{key === 'price' ? '-单位(分)' : ''}</label>
        <InputNumber min={0} value={this.state[key]} onChange={value => this.setStateValue(key, value)} />
      </div>
    ))
  }

  renderSwitchStructUI = () => {
    return this.switchConditions.map((key, idx) => (
      <div key={idx}>
        <label style={{width: '10em'}}>{this.condition2Chinese[key]}</label>
        <Switch onChange={checked => this.setStateValue(key, Number(checked))} checked={this.state[key]} checkedChildren={this[`${key}1`]} unCheckedChildren={this[`${key}0`]} />
      </div>
    ))
  }

  submit = async () => {
    if (this.checkIsChange() && this.checkGoodsHasPublish()) {        // 修改一个已经上架的商品
      if (this.state.publish) {                                       // 对于已上架的商品来说，只能对其进行下架操作
        message.warning('商品已上架，只能进行下架操作；如果需要做其它修改，请先下架~');
        return;
      }
    }
    let keys = Object.keys(this.condition2Chinese);
    let obj = {};
    keys.map(key => obj[key] = this.state[key]);
    if (validateUtil(obj, ['name', 'price', 'productDesc'], this.condition2Chinese, true)) return;
    for (let key of this.switchConditions) {
      if (obj[key] == null) {
        message.warning(`请输入${this.condition2Chinese[key]}`);
        return;
      }
    }
    if (obj.name.length > 18) { message.warning('商品名不能超过18个字'); return; }    // 商品名超过18个字的话，短信发送会失败
    let goodsId = this.props.match.params.id;
    if (goodsId) { obj.id = goodsId; }                               // 如果是修改商品的话，那么商品ID还是需要携带上
    this.setState({ loading: true });
    let response = await updateGoods(obj);
    this.setState({ loading: false });
    if (response.status) {
      message.success('操作成功');
      this.props.history.push('/goods');
    } else {
      message.success('操作失败');
    }
  }

  /** 判断是否是修改的逻辑 */
  checkIsChange = () => this.props.match.params.id

  /** 判断商品原先是否已经上架 */
  checkGoodsHasPublish = () => this.state.publishConst

  render() {
    let { loading } = this.state;
    return (
      <Spin spinning={loading} tip={'加载中...'}>
        <div>
          {checkPermission('sys:product:edit') && <Card size="small" title="发布" className="cardR"><Button disabled={loading} onClick={() => this.submit()} type="primary">发布</Button></Card>}
          {this.renderForm()}
          <div>
            <label>商品详情</label>
            <Editor
              content={this.state.productDesc}
              onChange={value => this.setState({ productDesc: value })}
              imgPath='background/goods'
            />
          </div>
        </div>
      </Spin>
    );
  }
}

export default GoodsDetail;