import {message} from 'antd';
import apiRequest from '../../common/request'

export const REQUEST_NEW_RESOURCE = "REQUEST_NEW_RESOURCE";
export const RECEIVE_RESOURCE = "RECEIVE_RESOURCE";
export const RECEIVE_SUBRESOURCE = "RECEIVE_SUBRESOURCE";

function request_new_resource() {
    return {
        type: REQUEST_NEW_RESOURCE
    }
}
function receive_subresource(id, name, obj) {
    return {
        type: RECEIVE_SUBRESOURCE,
        id,
        name,
        obj
    }
}

function receive_resource(resource) {
    return {
        type: RECEIVE_RESOURCE,
        resource
    }
}

/**
 * 获取题型分类
 */
export function fetchResource(){
    return dispatch => {
        apiRequest('/gk/ops/interview/category/list', {credentials: 'include'})
            .then(json => {
                dispatch(receive_resource(json.obj));
            })
            .catch(error => console.log(error));
    }
}


/**
 * 删除题型分类
 * 删除一级分类和删除二级分类并不同，一级分类的话多携带一个query，修复删除失败仍提示成功
 */
export function delResource(id, recursive=false){
    let url = '/gk/ops/resource/delete/' + id;
    recursive && (url += '?recursive=true');
    return dispatch => {
      apiRequest(url, {credentials: 'include'})
          .then(json => {
            message.success('删除成功')
            dispatch(fetchResource())
          })
          .catch(error => {
              dispatch(fetchResource())
              console.log(error)
           });
    }
}

/**
 * 新建题型分类
 */
export function newResource(res, pid){
    let data = {
        "name": res,
        "parentId": pid,
        type:'interview'
    };
    return dispatch => {
        dispatch(request_new_resource());
        apiRequest('/gk/ops/resource/saveOrUpdate',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            })
            .then(json => {
                message.success('操作成功')
                dispatch(fetchResource());
            })
            .catch(error => console.log(error));
    }
}
