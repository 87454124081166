import apiRequest from '../../common/request';
import {message} from 'antd';
const PREFIX = 'POSITION_MENU_ACTION';

export const REMARKS_HANDLER = PREFIX + 'REMARKS_HANDLER';

export const remarksHandler = value => ({ type: REMARKS_HANDLER, value });

export const fetchMenuDetail = async () => {
    let url = `/gk/ops/wechat-menu/source/customizeMenus`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    let json = await apiRequest(url, config)
    if(json.status != 1) {
        message.error(json.errorMes);
    }
    return json.obj;
};

export const fetchMenuInform = (data) => {
    let url = `/gk/ops/wechat-menu/source/customizeMenus`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: data
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            if (json.status == 1) {
                message.success('操作成功');
            } else {
                message.error(json.errorMes||'操作失败');
            }
        })
        .catch(error => console.log(error));
    }
};