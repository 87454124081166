import apiRequest from '../../common/request';
import { message } from 'antd';


const PREFIX = "COURSE_CLASS_INFO_";
export const FILL_CONTENT = PREFIX + "FILL_CONTENT";
export const FILL_TEACHER = PREFIX + "FILL_TEACHER";
export const FILL_URL = PREFIX + "FILL_URL";

export const FILL_COURSEID = PREFIX + "FILL_COURSEID";
export const REQUEST_UPDATE = PREFIX + "REQUEST_UPDATE";
export const RECEIVE_UPDATE = PREFIX + "RECEIVE_UPDATE";
export const RECEIVE_INFO = PREFIX + "RECEIVE_INFO";
export const FETCH_START = PREFIX + 'FETCH_START';
export const VALUE_INPUT = PREFIX + 'VALUE_INPUT';
export const CHANGE_SELECT_QUESIDS = PREFIX + 'CHANGE_SELECT_QUESIDS';
export const CHANGE_SELECT_QUES = PREFIX + 'CHANGE_SELECT_QUES';
export const SET_EXERCISE_DETAIL = PREFIX + 'SET_EXERCISE_DETAIL';

export function setExerciseDetail(key, value) {
    return {
        type: SET_EXERCISE_DETAIL,
        key,
        value
    };
}

export function valueInput(key, value) {
    return {
        type: VALUE_INPUT,
        key,
        value
    };
}

export function changeSelectQuesIds(value) {
    return {
        type: CHANGE_SELECT_QUESIDS,
        value
    };
}

export function changeSelectQues(value) {
    return {
        type: CHANGE_SELECT_QUES,
        value
    };
}

export function fill_content(value) {
    return {
        type: FILL_CONTENT,
        value,
        key: 'content'
    }
}

export function fill_teacher(value) {
    return {
        type: FILL_TEACHER,
        value,
        key: 'teacher'
    }
}

export function fill_url(value) {
    return {
        type: FILL_URL,
        value,
        key: 'url'
    }
}

export function fill_courseId(value) {
    return {
        type:FILL_COURSEID,
        value,
        key:'courseId'
    }
}

export function receive_info(info) {
    return {
        type:RECEIVE_INFO,
        info
    }
}

function request_update() {
    return {type:REQUEST_UPDATE}
}

function receive_update() {
    return {
        type:RECEIVE_UPDATE
    }
}

// 这相当于初始化的方法，可以在必要的时候调用它
export function fetchEmpty(courseId) {
    return {
        type:RECEIVE_INFO,
        info: {
          advOptions: 1,
          allowComment: 1,
          content: "",
          courseId: courseId,
          courseNum: 0,
          description: "",
          exercise: null,
          length: 0,
          objectives: "",
          teacher: "",
          times: null,
          title: "",
          type: 0,
          url: "",
        }
    }
}

export function updateClass(info, type, cb) {
    let data = (info);
    // 从目前来看，questions，shenlunquestions，slZyQuestions可以不用传
    if (data.exercise && 'questions' in data.exercise) {
        delete data.exercise['questions'];
    }
    if (data.exercise && 'slZyQuestions' in data.exercise) {
        delete data.exercise['slZyQuestions'];
    }
    if (data.exercise && data.exercise.questionIds == null) { data.exercise.questionIds = []; };
    if ('cover' in data && !data['cover']) delete data['cover'];
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update());
        return apiRequest("/gk/ops/course/class", config)
          .then(json => {
            dispatch(receive_update());
            message.success('操作成功');
            cb && cb();
          })
          .catch(error => {
            dispatch(receive_update());
            console.log(error)
          });
    }
}

function fetchBegin() {
    return {
        type: FETCH_START
    };
}

/** 从问题详情数组里面筛选出问题ID数组 */
const findIdFromQuestions = (quesArr) => {
    let result = [];
    if (Object.prototype.toString.call(quesArr) !== '[object Array]') return result;
    quesArr.map(quesObj => { result.push(quesObj.id); });
    return result;
}

/** 获取这个课时的问题数据 */
export function getCourseData(classId) {
    let url = `/gk/ops/course/class/getExercisesByClassId/${classId}`;
    let config = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return dispatch => {
        dispatch(fetchBegin())
        return apiRequest(url, config)
          .then(json => {
            let selQuesIds = findIdFromQuestions(json.obj && json.obj.exercise && json.obj.exercise.questions);
            dispatch(changeSelectQuesIds(selQuesIds));
            dispatch(receive_info(json.obj));
          })
          .catch(error => console.log(error));
    }
}