import React from 'react';
// import PropTypes from 'prop-types';
import { Tag, Collapse, message, Button, Image } from 'antd';
import Comment from '../Comment/index';
import { formatSeconds } from '../../utils/utils'
import './index.scss';

const { Panel } = Collapse;
class Shenlun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    /** 计算距离顶部的距离 */
    computeOffset = (ele) => {
        if (!ele) return 0;
        let offsetY = 0;
        while (ele != window.document.body && ele != null) {
            offsetY += ele.offsetTop;
            ele = ele.offsetParent;
        };
        return offsetY;
    };

    jumpLatest = (str) => {
        let o = document.querySelector('.ant-layout-content');
        let ele = document.querySelector(str);
        if (!ele) {
            return message.info(str == '.recent' && '最近无观看' || '最近没有做题');
        }
        let distance = this.computeOffset(ele);
        distance = distance - 150;
        o.scrollTop = distance;
    };

    /** 根据questionIds对slZyExercises就行排序 */
    setIdToExexcise = (obj) => {
        if (obj.slZyExercises && obj.slZyExercises.length) {        //已做情况
            let res = [];
            obj.questionIds.map(item => {
                if (obj.slZyExercises.find(i => i.questionId == item)) {
                    res.push(obj.slZyExercises.find(i => i.questionId == item));
                } else {
                    res.push(item);
                }
            });
            return res;
        } else if (obj.questionIds && obj.questionIds.length) {      //未做情况
            return obj.questionIds;
        } else {     //没有练习返回false
            return false;
        }
    }

    /** 设置练习头部信息 */
    getStuInfo = (idx, slzy) => (
        <div className="common1Line" style={{ padding: 0, background: '#fafafa' }}>
            <span>练习{idx + 1} {slzy.id ? '' : '未提交'}</span>
            {slzy.id && <Tag color={slzy.isCorrect && 'blue' || 'red'}>{slzy.isCorrect && '已批改' || '未批改'}</Tag>}
        </div>
    )

    render() {
        const { lastRecordTime, lastExerciseTime } = this.props;
        if (!this.props.exercise) {
            if (!this.props.classes || !this.props.classes.length) {
                return (
                    <div className="emptyWorkContainer0348">
                        一片空白~
                    </div>
                );
            }
        }
        return (
            <div className="xingce-container">
                {
                    !this.props.exercise &&
                    <div className="positioning">
                        <Button className="mr30" type="danger" onClick={() => this.jumpLatest('.recent')}>最近观看课时</Button>
                        <Button type="danger" onClick={() => this.jumpLatest('.recent2')}>最近做题时间</Button>
                    </div>
                }
                {/** 课后练习 */}
                {
                    !this.props.exercise &&
                    <div className="exercise-container">
                        {
                            this.props.classes && this.props.classes.map((dayExerciseArr, idx) => (
                                <div key={idx}>
                                    <div className="singleModule">
                                        <span className="mr30">第{dayExerciseArr.number}天</span>
                                        <span>{dayExerciseArr.date || ''}</span>
                                    </div>
                                    {
                                        dayExerciseArr.classes && dayExerciseArr.classes.map((exerciseObj, index) => (
                                            <div key={index} className={['metaData_container', exerciseObj.recordTime && lastRecordTime == exerciseObj.recordTime ? 'outsideShenlun recent' : ''].join(' ')}>
                                                <div className="header flexr">
                                                    <span className="spanRight">### {exerciseObj.courseNum}-{index + 1}</span>
                                                    <span className="spanRight">视频标题：{exerciseObj.title || ''}</span>
                                                    <span className="spanRight">视频总时长：{formatSeconds(exerciseObj.length)}</span>
                                                    <span className="spanRight">观看时长：{formatSeconds(exerciseObj.times)}</span>
                                                    {exerciseObj.recordTime && <span className="spanRight">最近观看时间：{exerciseObj.recordTime}</span>}
                                                </div>
                                                {
                                                    exerciseObj && exerciseObj.exercise ?
                                                        <Collapse>
                                                            {
                                                                this.setIdToExexcise(exerciseObj.exercise) && this.setIdToExexcise(exerciseObj.exercise).map((slzy, idx) => (
                                                                    <Panel key={idx} header={this.getStuInfo(idx, slzy)} className={slzy.createTime && lastExerciseTime == slzy.createTime ? 'outsideShenlun recent2' : ''} collapsible={slzy.id && 'true' || 'disabled'}>
                                                                        {slzy.id && <div key={idx}>
                                                                            <Image.PreviewGroup>
                                                                                {slzy.imageUrl.map((item, idx) => (
                                                                                    <Image key={idx} style={{ width: 150, height: 150 }} src={item} />
                                                                                ))}
                                                                            </Image.PreviewGroup>
                                                                            <br/>
                                                                            {slzy.createTime && <span className="spanRight">提交时间：{slzy.submitDate}</span>}
                                                                            {slzy.remark && <span className="spanRight tinct">学员备注：{slzy.remark || '暂无备注'}</span>}
                                                                            {this.props.showComment &&
                                                                                <Comment
                                                                                    answerId={slzy.id}
                                                                                    questionId={slzy.questionId}
                                                                                    feedbackRemark={slzy.feedbackRemark}
                                                                                    feedbackUrl={slzy.feedbackUrl}
                                                                                    userImgs={slzy.imageUrl}
                                                                                    update={this.props.update}
                                                                                    classType={this.props.classType}
                                                                                />
                                                                            }
                                                                            {slzy.correctDate && <span className="spanRight">批改时间：{slzy.correctDate}</span>}
                                                                        </div>}
                                                                    </Panel>
                                                                ))
                                                            }
                                                        </Collapse>
                                                        : ''
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
                {/** 班级测试 */}
                {
                    this.props.exercise &&
                    <div className="exercise-container">
                        <Image.PreviewGroup>
                            {this.props.classes.imageUrl.map((item, idx) => (
                                <Image
                                    key={idx}
                                    style={{ width: 200, height: 200 }}
                                    src={item}
                                />
                            ))}
                        </Image.PreviewGroup>
                        {
                            this.props.showComment &&
                            <Comment
                                classType={this.props.classType}
                                answerId={this.props.classes.id}
                                feedbackRemark={this.props.classes.feedbackRemark}
                                feedbackUrl={this.props.classes.feedbackUrl}
                                userImgs={this.props.classes.imageUrl}
                                update={this.props.update}
                            />
                        }
                        <div className="footer flexr">
                            <span className="spanRight">提交时间：{this.props.classes.createTime}</span>
                            <span className="spanRight">学员备注：{this.props.classes.remark || '暂无备注'}</span>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// Shenlun.propTypes = {
//     classes: PropTypes.array.isRequired,    //  练习数据，可以包含多天，每一天可以有多个练习，每个练习可以具有多个题目
//     showComment: PropTypes.bool,            //  表示是否需要显示批注数据
//     update: PropTypes.func,                 //  StudentDetail组件更新方法
//     permission: PropTypes.number,           //  表示权限数字
// };

Shenlun.defaultProps = {
    classes: []
};

export default Shenlun;
