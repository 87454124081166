import React from 'react';
import { connect } from 'react-redux';
import {  Radio, Table } from 'antd';
import { fetchInterviewStudent, set_filter_status, receive_student_list } from './action';
import { getSearchQuery, checkPermission } from '../../utils/utils';

import './index.scss'

const RadioGroup = Radio.Group;
class InterviewCandidateList extends React.Component {

    componentDidMount() {
        this.fetchUserList()
    };

    /** 获取学员数据详情 */
    fetchUserList = async () => {
        let id = getSearchQuery('classId');
        let obj = {
            classId: id,
        }
        if (this.props.filterStatus == 'DONE') {
            obj.status = 1;
        }
        if (this.props.filterStatus == 'NOT') {
            obj.status = 0; 
        }
        await this.props.dispatch(fetchInterviewStudent(obj));
    };

    /** 跳转单个学员详情页面 */
    goSingleDetail = (studentId, userId) => {
        this.saveLocal();
        let name = this.props.responseObj.className;
        this.props.history.push(`/interviewStudentDetail?classId=${getSearchQuery('classId')}&num=${getSearchQuery('num')}&studentId=${studentId}&userId=${userId}&className=${name}`);
    };

    /** 将学员列表数据保存到localStorage */
    saveLocal = () => {
        const { datas } = this.props.responseObj;
        let arr = JSON.stringify(datas);
        try{ window.localStorage.setItem('interviewStudentList', arr); }
        catch(err) {}
    }

    /** 缴费状态 */
    payStatus = val => {
        if(val==4) return '已退费'
        if(val==1) return '未交费'
        if(val==2) return '部分缴费'
        if(val==3) return '全部缴费'
        return ''
    }

    /** 生成columns的动态显示部分 */
    generateDynamicColumns = () => {
        const columns = [
            {
                title: '学员姓名',
                dataIndex: 'studentName',
                width: '20%'
            },
            {
                title: '缴费状态',
                width: '20%',
                render: (record) => {
                    return (
                            <span>{this.payStatus(record.payStatus)}</span>
                    )
                } 
            },
            {
                title: '完成练习',
                width: '20%%',
                render: (record) => {
                    return (
                        <div>
                            <span>{record.finishedNum}</span>
                        </div>
                    )
                } 
            },
            {
                title: '点评次数',
                dataIndex: 'commentNum',
                width: '20%'
            },
            {
                title: '操作',
                width: '25%',
                render: (record) => {
                    return (
                        <div>
                            {
                                checkPermission('interview:stu:gkdp') &&
                                <span className="correct done" onClick={() => {this.goSingleDetail(record.studentId, record.userId)}}>点评</span> 
                            }
                        </div>
                    )
                }
            },
        ]
        return columns;
    };

    /** 筛选状态发生改变 */
    changeFilterStatus = async event => {
        let value = event.target.value;
        await this.props.dispatch(set_filter_status(value))
        await this.fetchUserList();
        if(value==1 || value==0) {
            let data = this.getCommentArr(this.props.responseObj.datas, value);
            let newData = this.props.responseObj;
            newData.datas = data;
            await this.props.dispatch(receive_student_list(newData));
        }
    };

    getCommentArr = (arr, val) => { // type=1,
        let list =[];
        val==1 ? arr.map(item=>(
            item.commentNum && list.push(item)
        )) : 
        arr.map(item=>(
            !item.commentNum && list.push(item)
        )) 
        return list; 
    }

    render() {
        const { responseObj } = this.props;
        const { datas } = responseObj;
        const columns = this.generateDynamicColumns();
        return(
            <div className="candidateListPage">
                <div className="filter-container flexrc borderBottom height60 fz20">
                    <span>班级:</span>
                    <span className="marginRight25">{responseObj.className}</span>
                </div>
                <div className="filter-container flexrc height50 fz16">
                    <span>班主任:</span>
                    <span className="mr40">{responseObj.headTeacher}</span>
                    <span>班级人数:</span>
                    <span className="mr40">{getSearchQuery('num')}</span>
                    <span>总练习数:</span>
                    <span>{responseObj.exerciseNumber}</span>
                    <span className="flexG"></span>
                    <RadioGroup onChange={this.changeFilterStatus} value={this.props.filterStatus}>
                        <Radio value="ALL">全部</Radio>
                        <Radio value="DONE">已完成</Radio>
                        <Radio value="NOT">未完成</Radio>
                        <Radio value={1}>已点评</Radio>
                        <Radio value={0}>未点评</Radio>
                    </RadioGroup>
                </div>

                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={datas}
                    rowKey={(record)=>record.userId}
                />
            </div>
        )
    };
};

function mapStatetoProps(state) {
    const {
        responseObj,
        filterStatus,
    } = state.interviewCandidateList;
    return {
        responseObj,
        filterStatus,
    };
}

export default connect(mapStatetoProps)(InterviewCandidateList);