import apiRequest from '../../common/request';
import {message} from 'antd';
const PREFIX = 'ADD_OR_MODIFY_ACTION';

export const SEARCH_USER_INFORM = PREFIX + 'SEARCH_USER_INFORM';
export const MODIFY_APPLICATION_AREA = PREFIX + 'MODIFY_APPLICATION_AREA';
export const SET_CONTRACT_DATE = PREFIX + 'SET_CONTRACT_DATE';
export const CHANGE_CONTRACT_NO = PREFIX + 'CHANGE_CONTRACT_NO';
export const CHANGE_CURRENT_SALE = PREFIX + 'CHANGE_CURRENT_SALE';
export const SALES_WECHAT_HANDLER = PREFIX + 'SALES_WECHAT_HANDLER';
export const SALES_WECHAT_NAME_HANDLER = PREFIX + 'SALES_WECHAT_NAME_HANDLER';
export const HANDLE_SELECT_CLASS = PREFIX + 'HANDLE_SELECT_CLASS';
export const COURSE_NAME_HANDLER = PREFIX + 'COURSE_NAME_HANDLER';
export const MODIFY_SELECT_STATUS = PREFIX + 'MODIFY_SELECT_STATUS';
export const AMOUNT_HANDLER = PREFIX + 'AMOUNT_HANDLER';
export const LOGISTICS_COMPANY_HANDLER = PREFIX + 'LOGISTICS_COMPANY_HANDLER';
export const LOGISTICS_NO_HANDLER = PREFIX + 'LOGISTICS_NO_HANDLER';
export const HANDLER_CLASS_DETAIL = PREFIX + 'HANDLER_CLASS_DETAIL';
export const REMARKS_HANDLER = PREFIX + 'REMARKS_HANDLER';
export const FROM_USER_INTERFACE = PREFIX + 'FROM_USER_INTERFACE';
export const MODIFY_CURRENT_REDUX = PREFIX + 'MODIFY_CURRENT_REDUX';
export const CLEAR_CURRENT_STATUS = PREFIX + 'CLEAR_CURRENT_STATUS';
export const MODIFY_CURRENT_DETAILS = PREFIX + 'MODIFY_CURRENT_DETAILS';

export const modifyCurrentDetails = data => ({ type: MODIFY_CURRENT_DETAILS, data });

export const clearCurrentStatus = () => ({ type: CLEAR_CURRENT_STATUS });

export const modifyCurrentRedux = data => ({ type: MODIFY_CURRENT_REDUX, key: data.key, value: data.value });

export const fromUserInterface = value => ({ type: FROM_USER_INTERFACE, value });

export const remarksHandler = value => ({ type: REMARKS_HANDLER, value });

export const handlerClassDetail = data => ({ type: HANDLER_CLASS_DETAIL, data });

export const logisticsNoHandler = value => ({ type: LOGISTICS_NO_HANDLER, value });

export const logisticsCompanyHandler = value => ({ type: LOGISTICS_COMPANY_HANDLER, value });

export const amountHandler = value => ({ type: AMOUNT_HANDLER, value });

export const modifySelectStatus = data => ({ type: MODIFY_SELECT_STATUS, key: data.key, value: data.value });

export const courseNameHandler = value => ({ type: COURSE_NAME_HANDLER, value });

export const handleSelectClass = value => ({ type: HANDLE_SELECT_CLASS, value });

export const salesWechatNameHandler = value => ({ type: SALES_WECHAT_NAME_HANDLER, value });

export const salesWechatHandler = value => ({ type: SALES_WECHAT_HANDLER, value });

export const changeCurrentSale = value => ({ type: CHANGE_CURRENT_SALE, value });

export const changeContractNo = value => ({ type: CHANGE_CONTRACT_NO, value });

export const setContractDate = value => ({ type: SET_CONTRACT_DATE, value });

export const modifyApplicationArea = value => ({ type: MODIFY_APPLICATION_AREA, value });

const search_user_inform = value => ({ type: SEARCH_USER_INFORM, value });

export const fetchContractUserInform = value => {
    let url = `/gk/ops/userInfo/getByKey/${value}`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            dispatch(search_user_inform(json.obj));  
        })
        .catch(error => console.log(error));
    }
};

export const fetchContractDetail = async id => {
    let url = `/gk/ops/contract/get/${id}`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    let json = await apiRequest(url, config)
    if(json.status != 1) {
        message.error(json.errorMes);
    }
    return json.obj;
};

export const fetchContractOrderInform = (data, cb) => {
    let url = `/gk/ops/contract/saveOrUpdate`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            if (json.status == 1) {
                message.success('操作成功');
                cb && cb();
            } else {
                message.error(json.errorMes||'操作失败');
            }
        })
        .catch(error => console.log(error));
    }
};