import { message } from 'antd';
import apiRequest from '../../common/request';

export const getSystemUserList = async (params) => {
	let data = {
		page: params.page || 1,
		pageSize: params.pageSize || 10,
        status: params.status,
		userType: params.userType,
	}
    params.mobile && (data.mobile = params.mobile) || '';
    params.nickName && (data.nickName = params.nickName) || '';

	let url = `/gk/ops/system/user/page`;
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);    
    return obj.obj;
}

/** 获取角色选择框列表 */
export const getRoleList = async (userId, status) => {
	let url = `/gk/ops/system/role/list`;
	let res =  await apiRequest(url);
	return res.obj;
}

/** 改变用户状态 */
export const changeStatus = async (userId, status) => {
	let url = `/gk/ops/system/user/changeStatus/${userId}/${status}`;
	let res =  await apiRequest(url);
	if(res.status==1){
		message.success('修改成功');
	}
	return res;
}

/** 删除菜单信息 */
export const systemResetUser = async (id) => {
	let url = `/gk/ops/system/user/reset/password/${id}`;
	let res =  await apiRequest(url);
	if(res.status==1){
		message.success('重置密码成功');
	}
	return res;
}

/** 根据用户编号获取详细信息 */
export const getUserInfo = async (id) => {
	let url = `/gk/ops/system/user/info/${id}`;
	let res =  await apiRequest(url);
	return res.obj;
}

/** 新增或者修改菜单信息 */
export const systemAddOrUpdateUser = async (params, type) => {
	let data = {
		sex: params.sex,
		status: params.status,
		userType: params.userType,
	}
	params.roleIds && (data.roleIds = [params.roleIds]) || '';
	params.nickName && (data.nickName = params.nickName) || '';
	params.mobile && (data.mobile = params.mobile) || '';
	params.email && (data.email = params.email) || '';
	params.password && (data.password = params.password) || '';
	params.id && (data.id = params.id) || '';
	let url = `/gk/ops/system/user/add`;
	type && (url = `/gk/ops/system/user/update`);

	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let res =  await apiRequest(url, config);
	if(res.status==1){
		message.success('操作成功');
	}
	return res;
}
