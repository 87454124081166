import React from "react";
import { Card, Checkbox } from 'antd';

export default class CategorySelectCard extends React.Component {

    handleChange = (e) => {        
        this.props.onChange && this.props.onChange(e);
    };

    render() {
        let options = this.props.categories && this.props.categories.map(item=>{
            item.label = item.name;
            item.value = item.id;
            return item;
        })
        let {defaultChecked} = {...this.props};
        
        return (
            <Card size="small" title="分类目录">
                <Checkbox.Group className="checkOpt" options={options} value={defaultChecked} onChange={this.handleChange}/>
            </Card>
        )
    }

}