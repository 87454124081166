import apiRequest from '../../common/request';
import { message } from "antd";

export const REQUEST_EXAMINE_CATEGORIES = "REQUEST_EXAMINE_CATEGORIES";
export const RECEIVE_EXAMINE_CATEGORIES = "RECEIVE_EXAMINE_CATEGORIES";

export const REQUEST_ADD_EXAMINE_CATEGORY = "REQUEST_ADD_EXAMINE_CATEGORY";
export const RECEIVE_ADD_EXAMINE_CATEGORY_RESULT = "RECEIVE_ADD_EXAMINE_CATEGORY_RESULT";

export const REQUEST_DELETE_EXAMINE_CATEGORY = "REQUEST_DELETE_EXAMINE_CATEGORY";
export const RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT = "RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT";

export const REQUEST_UPDATE_EXAMINE_CATEGORY = "REQUEST_UPDATE_EXAMINE_CATEGORY";
export const RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT = "RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT";

export const REQUEST_QUESTION_CATEGORIES = "REQUEST_QUESTION_CATEGORIES";
export const RECEIVE_QUESTION_CATEGORIES = "RECEIVE_QUESTION_CATEGORIES";

export const REQUEST_ADD_QUESTION_CATEGORY = "REQUEST_ADD_QUESTION_CATEGORY";

export const REQUEST_UPDATE_QUESTION_CATEGORY = "REQUEST_UPDATE_QUESTION_CATEGORY";

export const REQUEST_AUDIO_CATEGORIES = "REQUEST_AUDIO_CATEGORIES";
export const RECEIVE_AUDIO_CATEGORIES = "RECEIVE_AUDIO_CATEGORIES";

export const REQUEST_ADD_AUDIO_CATEGORY = "REQUEST_ADD_AUDIO_CATEGORY";
export const RECEIVE_ADD_AUDIO_CATEGORY_RESULT = "RECEIVE_ADD_AUDIO_CATEGORY_RESULT";

export const REQUEST_DELETE_AUDIO_CATEGORY = "REQUEST_DELETE_AUDIO_CATEGORY";
export const RECEIVE_DELETE_AUDIO_CATEGORY_RESULT = "RECEIVE_DELETE_AUDIO_CATEGORY_RESULT";

export const REQUEST_UPDATE_AUDIO_CATEGORY = "REQUEST_UPDATE_AUDIO_CATEGORY";
export const RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT = "RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT";

export const REQUEST_COURSE_CATEGORIES = "REQUEST_COURSE_CATEGORIES";
export const RECEIVE_COURSE_CATEGORIES = "RECEIVE_COURSE_CATEGORIES";
export const REQUEST_ADD_COURSE_CATEGORY = "REQUEST_ADD_COURSE_CATEGORY";
export const REQUEST_UPDATE_COURSE_CATEGORY = "REQUEST_UPDATE_COURSE_CATEGORY";

function request_question_categories() {
    return {
        type: REQUEST_QUESTION_CATEGORIES
    }
}

function receive_question_categories(categories) {
    return {
        type: RECEIVE_QUESTION_CATEGORIES,
        categories: categories,
        receivedAt: Date.now()
    }
}

function request_add_question_category() {
    return {
        type: REQUEST_ADD_QUESTION_CATEGORY
    }
}

function request_update_question_category() {
    return {
        type: REQUEST_UPDATE_QUESTION_CATEGORY
    }
}

function request_course_categories() {
    return {
        type: REQUEST_COURSE_CATEGORIES
    }
}

function receive_course_categories(categories) {
    return {
        type: RECEIVE_COURSE_CATEGORIES,
        categories: categories,
        receivedAt: Date.now()
    }
}

function request_add_course_category() {
    return {
        type: REQUEST_ADD_COURSE_CATEGORY
    }
}

function request_update_course_category() {
    return {
        type: REQUEST_UPDATE_COURSE_CATEGORY
    }
}

export function fetchQuestionCategories() {
    return dispatch => {
        dispatch(request_question_categories());
        return apiRequest(`/gk/ops/position-condition/queryCondition/1`, {credentials: 'include'})
          .then(json => {
            dispatch(receive_question_categories(json.obj[0]?.condition || []));
          })
          .catch(error => console.log(error));
    }
}

/** 添加分类类型 */
export function addQuestionCategory(type, name, pid) {
    let data = {
        name: name,
        type: type == 'gk_question_types' ? 1 : 2,
        parentId: pid || 1
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_add_question_category());
        return apiRequest('/gk/ops/position-condition/saveOrUpdate', config)
          .then(json => {
              if(json.status==1){
                message.success('添加成功');
                if(type=='gk_question_type') {   // 公基更新分类数据
                    dispatch(fetchQuestionCategories());
                } else {                                // 职测更新分类数据
                    dispatch(fetchQuestionCategories());
                }
              }
          })
          .catch(error => console.log(error));
    }
}

/** 修改分类类型 */
export function updateQuestionCategory(id, name, pid, type) {
    let data = {
        id: id,
        name: name,
        type: type,
        parentId: pid || 1
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_update_question_category());
        return apiRequest('/gk/ops/position-condition/saveOrUpdate', config)
          .then(json => {
            message.success('修改成功');
            if(type=='gk_question_types') {  // 共基更新分类数据
                dispatch(fetchQuestionCategories());
            } else {                                // 职测更新分类数据
                dispatch(fetchQuestionCategories());
            }
          })
          .catch(error => console.log(error));
    }
}

export function fetchCourseCategories() {
    return dispatch => {
        dispatch(request_course_categories());
        return apiRequest(`/gk/ops/position-condition/queryCondition/2`, {credentials: 'include'})
          .then(json => {
            dispatch(receive_course_categories(json.obj));
          })
          .catch(error => console.log(error));
    }
}

export function addCourseCategory(name) {
    let data = {
        name: name,
        type: 2,
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_add_course_category());
        return apiRequest('/gk/ops/position-condition/saveOrUpdate', config)
          .then(json => {
            message.success('添加成功');
            dispatch(fetchCourseCategories());
          })
          .catch(error => console.log(error));
    }
}

export function updateCourseCategory(id, name, sort) {
    let data = {
        id: id,
        name: name,
        type: 2,
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(request_update_course_category());
        return apiRequest('/gk/ops/position-condition/saveOrUpdate', config)
          .then(json => {
            message.success('修改成功');
            dispatch(fetchCourseCategories());
          })
          .catch(error => console.log(error));
    }
}
