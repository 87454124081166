import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "INTERVIEW_CLASS_LIST";

export const SET_REDUX_KEY_VALUE = PREFIX + "SET_REDUX_KEY_VALUE";
export const RECEIVE_CLASS_LIST = PREFIX + "RECEIVE_CLASS_LIST";
export const RESET_FILTER_STATUS = PREFIX + "RESET_FILTER_STATUS";

export const resetFilterStatus = () => ({
    type: RESET_FILTER_STATUS
});

export const setReduxKv_InterviewClass = ({ key, value }) => ({
    type: SET_REDUX_KEY_VALUE,
    key,
    value
});

export const receiveClassList = responseObj => ({
    type: RECEIVE_CLASS_LIST,
    data: responseObj
});

export async function saveOrUpdateClass(params) {
    let url = '/gk/ops/interviewClass/saveOrUpdate';
    let config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
    };
    try {
        let response = await apiRequest(url, config);
        if (response.status === 1) { message.success('操作成功'); return true; }
        else return false;
    } catch(err) {
        return false;
    }
}

export function fetchClassList(data){
    data.area==null && delete data.area;
    data.year==null && delete data.year;
    let url = '/gk/ops/interviewClass/class/page';
    let config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(setReduxKv_InterviewClass({ key: 'loading', value: true }));
        apiRequest(url, config)
            .then(json => {
                !json.obj.content.length && message.warn('该条件没有对应的班级,请重新选择');
                dispatch(receiveClassList(json.obj));
            });
    }
}
