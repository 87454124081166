import {

    REQUEST_EXAMINE_CATEGORIES,
    RECEIVE_EXAMINE_CATEGORIES,
    REQUEST_ADD_EXAMINE_CATEGORY,
    RECEIVE_ADD_EXAMINE_CATEGORY_RESULT,
    REQUEST_DELETE_EXAMINE_CATEGORY,
    RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT,
    REQUEST_UPDATE_EXAMINE_CATEGORY,
    RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT,

    COMMON_SET_STATUS,
    REQUEST_QUESTION_CATEGORIES,
    RECEIVE_QUESTION_CATEGORIES,
    REQUEST_ADD_QUESTION_CATEGORY,
    REQUEST_DELETE_QUESTION_CATEGORY,
    REQUEST_UPDATE_QUESTION_CATEGORY,

    REQUEST_AUDIO_CATEGORIES,
    RECEIVE_AUDIO_CATEGORIES,
    REQUEST_ADD_AUDIO_CATEGORY,
    RECEIVE_ADD_AUDIO_CATEGORY_RESULT,
    REQUEST_DELETE_AUDIO_CATEGORY,
    RECEIVE_DELETE_AUDIO_CATEGORY_RESULT,
    REQUEST_UPDATE_AUDIO_CATEGORY,
    RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT, REQUEST_COURSE_CATEGORIES, RECEIVE_COURSE_CATEGORIES

} from './actions';

export function examineCategories(state = {
    isFetching: true,
    isUpdating: false,
    items: []
}, action) {
    switch (action.type) {
        case REQUEST_EXAMINE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_EXAMINE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.categories,
                receivedAt: action.receivedAt
            });
        case REQUEST_ADD_EXAMINE_CATEGORY:
        case REQUEST_DELETE_EXAMINE_CATEGORY:
        case REQUEST_UPDATE_EXAMINE_CATEGORY:
            return Object.assign({}, state, {isUpdating: true});

        case RECEIVE_ADD_EXAMINE_CATEGORY_RESULT:
            return Object.assign({}, state, {
                isUpdating: false,
                items: [action.result, ...state.items]
            });

        case RECEIVE_DELETE_EXAMINE_CATEGORY_RESULT:
            return Object.assign({}, state, {
                isUpdating: false,
                items: [...state.items.filter(item => item.id !== action.id)]
            });

        case RECEIVE_UPDATE_EXAMINE_CATEGORY_RESULT:
            let index = state.items.findIndex(item => item.id === action.id);
            return Object.assign({}, state, {
                isUpdating: false,
                items: [
                    ...state.items.slice(0, index),
                    Object.assign({}, state.items[index], {name: action.name}),
                    ...state.items.slice(index+1)
                ]
            });
        default:
            return state;
    }
}

export function questionCategories(state = {
    zcLoading: true,    //职测loading
    gjLoading: true,    //公基loading
    zyLoading: true,    //综应loading
    gjCategory: [],     //公基分类数组
    zcCategory: [],     //职测分类数组
    zyCategory: [],     //综应分类数组
    isFetching: true,
    isUpdating: false,
    items: []
}, action) {
    switch (action.type) {
        case COMMON_SET_STATUS:
            return Object.assign({}, state, {
                [action.key]: action.value,
            });
        case REQUEST_QUESTION_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_QUESTION_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.categories,
                receivedAt: action.receivedAt
            });
        case REQUEST_ADD_QUESTION_CATEGORY:
        case REQUEST_DELETE_QUESTION_CATEGORY:
        case REQUEST_UPDATE_QUESTION_CATEGORY:
            return Object.assign({}, state, {isUpdating: true});
        default:
            return state;
    }
}


export function audioCategories(state = {
    isFetching: true,
    isUpdating: false,
    items: []
}, action) {
    switch (action.type) {
        case REQUEST_AUDIO_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_AUDIO_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.categories,
                receivedAt: action.receivedAt
            });
        case REQUEST_ADD_AUDIO_CATEGORY:
        case REQUEST_DELETE_AUDIO_CATEGORY:
        case REQUEST_UPDATE_AUDIO_CATEGORY:
            return Object.assign({}, state, {isUpdating: true});

        case RECEIVE_ADD_AUDIO_CATEGORY_RESULT:
            return Object.assign({}, state, {
                isUpdating: false,
                items: [action.result, ...state.items]
            });

        case RECEIVE_DELETE_AUDIO_CATEGORY_RESULT:
            return Object.assign({}, state, {
                isUpdating: false,
                items: [...state.items.filter(item => item.id !== action.id)]
            });

        case RECEIVE_UPDATE_AUDIO_CATEGORY_RESULT:
            let index = state.items.findIndex(item => item.id === action.id);
            return Object.assign({}, state, {
                isUpdating: false,
                items: [
                    ...state.items.slice(0, index),
                    Object.assign({}, state.items[index], {name: action.name}),
                    ...state.items.slice(index+1)
                ]
            });
        default:
            return state;
    }
}

export function courseCategories(state = {
    isFetching: true,
    isUpdating: false,
    items: []
}, action) {
    switch (action.type){
        case REQUEST_COURSE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_COURSE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.categories,
                receivedAt: action.receivedAt
            });
        case REQUEST_ADD_QUESTION_CATEGORY:
        case REQUEST_DELETE_QUESTION_CATEGORY:
        case REQUEST_UPDATE_QUESTION_CATEGORY:
            return Object.assign({}, state, {isUpdating: true});
        default:
            return state;
    }
}