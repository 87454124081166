import {
    SEARCH_USER_INFORM,
    MODIFY_APPLICATION_AREA,
    SET_CONTRACT_DATE,
    CHANGE_CONTRACT_NO,
    CHANGE_CURRENT_SALE,
    SALES_WECHAT_HANDLER,
    SALES_WECHAT_NAME_HANDLER,
    HANDLE_SELECT_CLASS,
    COURSE_NAME_HANDLER,
    MODIFY_SELECT_STATUS,
    AMOUNT_HANDLER,
    LOGISTICS_COMPANY_HANDLER,
    LOGISTICS_NO_HANDLER,
    HANDLER_CLASS_DETAIL,
    REMARKS_HANDLER,
    FROM_USER_INTERFACE,
    MODIFY_CURRENT_REDUX,
    CLEAR_CURRENT_STATUS,
    MODIFY_CURRENT_DETAILS,
} from './action';
import { SET_STUDENT_CLASS } from '../Inquiry/action';
    
/** 初始化状态 */
const initialState = {
    userInfoId: null,                       // 用户id
    userNames: [],                          // 当前搜索客户所返回的
    examArea: '全部',                        // 报考地区
    contractNo: '',                         // 合同编号
    courseName: '',                         // 购买课程名称
    amount: '',                             // 合同金额
    salesName: undefined,                       // 销售姓名
    salesWechat: '',                        // 销售微信号
    salesWechatName: '',                    // 销售微信名
    signingDate: '',                        // 合同签订日期

    classId: '',                            // 班级id
    className: undefined,                          // 班级名字
    headTeacher: '',                        // 班主任
    headTeacherId: '',                      // 班主任id,
    id: '',
    isEnd: '',
    startDate: '',                          //开班日期

    classes: [],                            // 搜索班级集合
    classIdx: null,                         // 班级索引
    userIdx: null,                          // 学员索引
    userDetail: null,                       // 当前选中用户信息
    classDetail: null,                      // 当前选中的班级所有数据
    remarks: '',                            // 合同备注
    protocol: '全部',                        // 协议是否收回
    isInterview: '全部',                     // 是否进面
    isRenew: '全部',                         // 是否为续费
    status: '否',                            // 是否退款
    logisticsCompany: '',                    // 快递公司
    logisticsNo: '',                         // 快递单号
    userName: undefined,                            // 当前用户
};

export function changeUserInfo(previousState=initialState, action) {
    switch(action.type) {
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case SEARCH_USER_INFORM:
            return { ...previousState, userNames: action.value };
        case MODIFY_APPLICATION_AREA:
            return { ...previousState, examArea: action.value };
        case SET_CONTRACT_DATE:
            return { ...previousState, signingDate: action.value };
        case CHANGE_CONTRACT_NO:
            return { ...previousState, contractNo: action.value };
        case CHANGE_CURRENT_SALE:
            return { ...previousState, salesName: action.value };
        case SALES_WECHAT_HANDLER:
            return { ...previousState, salesWechat: action.value };
        case SALES_WECHAT_NAME_HANDLER:
            return { ...previousState, salesWechatName: action.value };
        case HANDLE_SELECT_CLASS:
            return { ...previousState, classIdx: action.value };
        case COURSE_NAME_HANDLER:
            return { ...previousState, courseName: action.value };
        case MODIFY_SELECT_STATUS:
            return { ...previousState, [ action.key ]: action.value };
        case AMOUNT_HANDLER:
            return { ...previousState, amount: action.value };
        case LOGISTICS_COMPANY_HANDLER:
            return { ...previousState, logisticsCompany: action.value };
        case LOGISTICS_NO_HANDLER:
            return { ...previousState, logisticsNo: action.value };
        case HANDLER_CLASS_DETAIL:
            return { ...previousState, classDetail: { ...action.data } };
        case REMARKS_HANDLER:
            return { ...previousState, remarks: action.value };
        case FROM_USER_INTERFACE:
            return { ...previousState, userInfoId: action.value };
        case MODIFY_CURRENT_REDUX: 
            return { ...previousState, [ action.key ]: action.value };
        case CLEAR_CURRENT_STATUS: 
            return { ...previousState, ...initialState };
        case MODIFY_CURRENT_DETAILS: 
            return { ...previousState, ...action.data };
        default:
            return previousState;
    }
}