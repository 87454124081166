import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    fetchBanners,
    fetchPublish,
    select_banner,
    publishBanners,
    order_banner,
    deleteBanner,
    commonSetStatus,
    resetState,
} from "./actions";
import { Radio, Select, Table, Button, message, Modal, Alert } from 'antd';
import { checkPermission } from '../../utils/utils';
import { reset as resetBannerDetail } from '../BannerRelease/action';
import { ArrowUpOutlined } from '@ant-design/icons'
import './index.scss';
const Option = Select.Option;

class BannerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showModal: false, deleteId: '' }
    }

    componentDidMount() {
        this.changeType(false, this.props.breed);
    }

    /** 只要不刷新页面，就不要重置状态 */
    changeType = async (boolean, type) => {
        boolean && await this.props.dispatch(resetState());
        await this.props.dispatch(commonSetStatus('breed', type));
        this.props.dispatch(fetchPublish(type));
        this.fetchPostData();
    }

    /** 获取列表数据 */
    fetchPostData = () => {
        let { page, pageSize, breed, addressType } = this.props;
        this.props.dispatch(fetchBanners({
            page, pageSize, breed, addressType
        }));

    }

    modifyBanners = () => {
        let { breed, info } = this.props;
        if (!info.bannersArr.length) {
            message.warning('请至少选择一个banner');
            return;
        }
        this.props.dispatch(publishBanners(breed, info));
    };

    /** 这个e参数的作用是用来提供ID */
    selectBanner = (banner) => {
        this.props.dispatch(select_banner(banner.id, banner));
    };

    /** 分页回调 */
    paginationChange = async (page, pageSize) => {
        await this.props.dispatch(commonSetStatus('page', page));
        await this.props.dispatch(commonSetStatus('pageSize', pageSize));
        this.fetchPostData();
    }

    handleConfirm = () => {
        this.setState({ showModal: false });
        const cb = () => {
            this.props.dispatch(fetchBanners({
                page: this.props.page,
                pageSize: this.props.pageSize
            }));
        };
        deleteBanner(this.state.deleteId, cb);
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    getDeleteId = (id) => {
        this.setState({
            deleteId: id,
            showModal: true
        });
    };

    /** 获取对应文本 */
    getAddress = (type, arr) => {
        let res = [];
        arr.map(item => {
            item == 1 && res.push(type && '安卓' || '资讯');
            item == 2 && res.push(type && 'ios' || '课程');
            item == 3 && res.push(type && 'PC' || '每日一练');
            item == 4 && res.push(type && '小程序' || '申论批改');
            item == 5 && res.push('app估分')
            item == 6 && res.push('app职位查询')
            item == 7 && res.push('小程序跳转')
        })
        return res.toString();
    }

    /** 生成antd table的配置数据 */
    generateTableConfig = isChosed => {
        let config = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                width: '20%',
                render: (text, record) => {
                    return (checkPermission('sys:banner:gkinfo') && <Link to={`/banner/${record.id}`}>{record.title}</Link>
                        || <span>{record.title}</span>)
                }
            },
            { title: '位置', width: '15%', render: (text, record) => this.getAddress(1, record.addressTypes) },
            { title: '关联', width: '15%', render: (text, record) => this.getAddress(0, [record.dataType]) },
            { title: '品类', width: '10%', render: (text, record) => record.breed && '事业单位' || '公务员' },
            { title: '图片信息', width: '20%', render: (text, record) => <img alt="img" src={record.imgUrl} className="bannerImg" /> },
        ];
        if (isChosed) {
            let uniq = { title: '编号', width: '10%', render: (text, record, index) => <span>{index + 1}</span> };
            config.splice(config.length - 1, 0, uniq);
        }
        if (checkPermission('sys:banner:gkchange')) {
            let choseOperation = {
                title: '选择',
                width: '5%',
                render: (text, record) => (
                    <input
                        type="checkbox"
                        data-target={record.id}
                        onChange={() => { }}
                        onClick={() => this.selectBanner(record)}
                        checked={this.props.info.bannersArr.includes(record.id)}
                    />
                )
            };
            let uniq = {
                title: '操作',
                width: '5%',
                render: (text, record) => {
                    if (isChosed) {
                        return <ArrowUpOutlined onClick={() => this.props.dispatch(order_banner(record.id))} />
                    }
                    if (!isChosed && record.publish === 0) {
                        return <Button size="small" type="link" onClick={() => this.getDeleteId(record.id)}>删除</Button>
                    }
                }
            };

            [choseOperation, uniq].map(obj => config.push(obj));
        }
        return config;
    }

    /** 生成table的标题头数据 */
    generateTableHeader = isChosed => <div>{isChosed ? '已选banner' : 'banner列表'}</div>

    /** 点击新增banner按钮 */
    addNewBanner = async () => {
        await this.props.dispatch(resetBannerDetail());
        this.props.history.push('/bannerRelease');
    }

    /** 搜索条件改变回调 */
    onChangeCommon = async (key, value) => {
        await this.props.dispatch(commonSetStatus('page', 1));
        await this.props.dispatch(commonSetStatus('pageSize', 10));
        await this.props.dispatch(commonSetStatus(key, value))
        this.fetchPostData();
    }

    /** 重置搜索 */
    reset = async () => {
        await this.props.dispatch(resetState());
        this.fetchPostData();
    }

    render() {
        const { info, breed, addressType, isFetchingBanners } = this.props;
        let chosedColumns = this.generateTableConfig(true);
        let allColumns = this.generateTableConfig(false);

        return (
            <div className="bannerList">
                <div className="flex-container">
                    <main>
                        <div className="commonTableTitle topTitle">
                            <div>
                                <Radio.Group value={breed} onChange={(e) => this.changeType(true, e.target.value)} defaultValue="0" buttonStyle="solid">
                                    <Radio.Button value={0}>公务员</Radio.Button>
                                    <Radio.Button value={1}>事业单位</Radio.Button>
                                </Radio.Group>
                            </div>
                            <div>
                                {checkPermission('sys:banner:add') && <Button type="primary" style={{ marginRight: '20px' }} onClick={this.addNewBanner}>新增banner</Button>}
                                {checkPermission('sys:banner:publish') && <Button disabled={this.props.isUpdating} type="primary" onClick={this.modifyBanners}>发布</Button>}
                            </div>
                        </div>

                        <Table
                            columns={chosedColumns}
                            dataSource={[...info.selBannersList]}
                            rowKey={record => record.id}
                            title={() => this.generateTableHeader(true)}
                            pagination={{
                                pageSize: 1000,
                                showTotal: total => `共有${total}条数据`,
                            }}
                        />
                        <br />

                        <div className="commonSearchBox">
                            <div>
                                <label>位置</label>
                                <Select className="commonW150" value={addressType} onChange={(value) => this.onChangeCommon('addressType', value)}>
                                    <Option value={''}>全部</Option>
                                    <Option value={1}>安卓</Option>
                                    <Option value={2}>ios</Option>
                                    <Option value={3}>PC</Option>
                                    <Option value={4}>小程序</Option>
                                </Select>
                            </div>
                        </div>

                        <Table
                            loading={isFetchingBanners}
                            columns={allColumns}
                            dataSource={this.props.items}
                            rowKey={record => record.id}
                            title={() => this.generateTableHeader(false)}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange: this.paginationChange,
                                total: this.props.totalElements,
                                showTotal: total => `共有${total}条数据`,
                                pageSize: this.props.pageSize,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                current: this.props.page
                            }}
                        />
                    </main>

                </div>

                {/** 删除确认弹窗区域 */}
                <Modal
                    title="确定要删除该banner吗？"
                    visible={this.state.showModal}
                    onOk={this.handleConfirm}
                    onCancel={this.closeModal}
                >
                    <Alert message="一旦删除就无法撤销了哦~" type="warning" />
                </Modal>
            </div>
        )
    }

}


function mapStatetoProps(state) {
    return { ...state.bannerList };
}

export default connect(mapStatetoProps)(BannerList);
