import * as CNT from './action';

const initialState = {
    isFetching: false,
    isSend: false,
    bannerVO: {
        addressTypes: [1],                     // banner出现的位置，0,1,2,3,4分别表示所有，Android，iOS，pc，小程序
        content: '',                        // 内容，资讯banner会用上，是富文本内容
        dataId: 0,                          // 关联内容ID
        dataType: '',                       // 关联内容类型
        imgUrl: '',                         // 图片地址
        publish: false,                     // 是否直接发布
        title: '',                          // banner标题
        breed: 0                             // banner品类
    },
};

export function bannerRelease(state = initialState, action) {
    switch (action.type) {
        case CNT.FILL_DESC:
        case CNT.FILL_TITLE:
        case CNT.FILL_IMG:
        case CNT.FILL_TYPE:
            return { ...state, bannerVO: { ...state.bannerVO, [action.key]: action.value } };
        case CNT.RESET:
            return initialState;
        case CNT.SET_NEW_OBJ:
            return { ...state, bannerVO: action.data };
        case CNT.REQUEST_PUBLISH:
            return { ...state, isSend: true };
        case CNT.REQUEST_BANNER:
            return { ...state, isFetching: true };
        case CNT.RECEIVE_BANNER:
            return { ...state, bannerVO: action.data, isFetching: false };
        case CNT.RECEIVE_PUBLISH_SUC:
        default:
            return state;
    }
}