/**
 * Created by pwq on 2017/07/11.
 */
import {
    FILL_EXAMINE_TITLE,
    FILL_EXAMINE_LIMIT_TIME,
    FILL_EXAMINE_CLASS_ID,
    SELECT_QUESTION,
    REQUEST_UPDATE_EXAMINE,
    RECEIVE_UPDATE_EXAMINE_RESULT_SUC,
    RECEIVE_UPDATE_EXAMINE_RESULT_FAIL, REQUEST_DAILY_EXAMINE_INFO, RECEIVE_DAILY_EXAMINE_INFO,
    UPDATE_SEL_QUESTION,
    SET_VALUE,
    RESET_ALL_DATA
} from "./actions";
import { SET_STUDENT_CLASS } from "../Inquiry/action";
const initialState = {
    lastClassId: 0,             // 上次班级id
    lastScrollTop: 0,           // 上次滚动条位置
    isFetchingExamine: false,
    isFetchingQuestions: false,
    isUpdating: false,
    isUpdated: false,
    categoryId: '-1',
    info: {
        title: "",
        quesArr: [],
        selQuesList: [],
        classId: [],
        classIdDate: []
    },
    classes: [],
};

export function classTestInfo(state=initialState, action) {
    switch (action.type) {
        case REQUEST_DAILY_EXAMINE_INFO:
            return Object.assign({}, state, {isFetchingExamine: true});
        case RECEIVE_DAILY_EXAMINE_INFO:
            let getInfo = action.dailyExamine;
            getInfo.selQuesList = getInfo.questions;
            return Object.assign({}, state, {
                isFetchingExamine: false,
                info: getInfo,
                receivedAt: action.receivedAt
            });
        case SELECT_QUESTION:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    quesArr: action.value,
                })
            });
        case SET_STUDENT_CLASS:
            return Object.assign({}, state, {classes: action.data});
        case UPDATE_SEL_QUESTION:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    selQuesList: action.value
                })
            });
        case FILL_EXAMINE_TITLE:
        case FILL_EXAMINE_LIMIT_TIME:
        case FILL_EXAMINE_CLASS_ID:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case REQUEST_UPDATE_EXAMINE:
            return Object.assign({}, state, {isUpdating: true});
        case RECEIVE_UPDATE_EXAMINE_RESULT_SUC:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_EXAMINE_RESULT_FAIL:
            return Object.assign({}, state, {isUpdating: false, isUpdated: false});
        case RESET_ALL_DATA:
            return Object.assign({}, state, { info: {
                title: "",
                quesArr: [],
                selQuesList: [],
                classIdDate: []
            },classes: []});
        case SET_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        default:
            return state;
    }
}
