import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Button, Select, Menu, Dropdown, Upload, message, Input } from 'antd';
import {
    contractNoHandler,
    searchUserInfo,
    userHandler,
    fetchApplicationArea,
    fetchContractOrderUser,
    changeCurrentArea,
    resetCurrentPage,
    setCurrentFilterStatus,
    fetchDepartmentList,
    modifyCurrentPage,
    modifyCurrentPageSize,
    modifyCurrentSales,
    modifyCurrentHeadTeacher,
    resetSearchCriteria,
    exportContractOrder
} from './action';
import { openModleAction, fetchSearchClass } from '../Inquiry/action';
import { clearBatchClassStatus } from '../BatchClass/action';
import './index.scss';
import Cookies from 'js-cookie';
import { checkPermission } from '../../utils/utils';
import UserInfo from '../../components/UserInfo/index';
import ClassInfo from '../../components/ClassInfo/index';

const { Option } = Select;

const menu = (
    <Menu>
        <Menu.Item key="1">
            <a href="https://gk-resource.oss-cn-beijing.aliyuncs.com/background/crm/%E7%94%A8%E6%88%B7%E5%90%88%E5%90%8C%E4%BF%A1%E6%81%AF%E8%A1%A8%EF%BC%88%E6%A8%A1%E6%9D%BF%EF%BC%89.xlsx">合同订单模板下载</a>
        </Menu.Item>
        <Menu.Item key="2">
            <a href="https://gk-resource.oss-cn-beijing.aliyuncs.com/background/crm/%E5%BF%AB%E9%80%92%E5%8D%95%E5%8F%B7%E6%A8%A1%E6%9D%BF.xlsx ">快递订单模板下载</a>
        </Menu.Item>
    </Menu>
);

const classTeacher = '全程部,师资堂';
const sell = '销售部'

const headers = {
    'Sin-Access-Token': Cookies.get('accessToken'),
    'Sin-UserAgent-Sign': 'SintureGK-WEB',
    'Sin-Client-Type': 3,
};
class ContractOrder extends React.Component {

    componentDidMount() {
        this.props.dispatch(fetchApplicationArea());
        this.getContractOrderUser();
    };

    conditionsChinese = {
        status: '退款', isEnd: '结课', isInterview: '进面试', isRenew: '续费', isGroup: '分班'
    };

    selectConditions = ['status', 'isEnd', 'isInterview', 'isRenew'];

    publicSelect = [{ label: '全部', value: '全部' }, { label: '是', value: 1 }, { label: '否', value: 0 }];

    /** 获取合同订单用户信息 */
    getContractOrderUser = () => {
        const { isType, page, pageSize, currentArea, isEnd, isInterview, userInfoIds, status, isRenew, salesName, headTeacherId, contractNo, classId } = this.props;
        let params = { page, pageSize };
        (isType != null) && (params.type = isType);
        (currentArea != '全部') && (params.examArea = currentArea);
        (isEnd != '全部') && (params.isEnd = isEnd);
        (isInterview != '全部') && (params.isInterview = isInterview);
        (status != '全部') && (params.status = status);
        (isRenew != '全部') && (params.isRenew = isRenew);
        (salesName != '全部') && (params.salesName = salesName);
        (headTeacherId != '全部') && (params.headTeacherId = headTeacherId);
        (contractNo != '') && (params.contractNo = contractNo);
        (userInfoIds && userInfoIds.length > 0) && (params.userInfoIds = userInfoIds);
        classId != '全部' && (params.classId = classId);
        this.props.dispatch(fetchContractOrderUser(params));
    };

    /** 文件上传的配置 */
    uploadSetting = {
        name: 'file',
        accept: '.xls,.xlsx',         // 如果选定的文件格式不再局限于xls了，放开这个限制
    };

    uploadHandler = async obj => {
        let status = obj && obj.file && obj.file.response && obj.file.response.status;
        let errorMes = obj && obj.file && obj.file.response && obj.file.response.errorMes;
        if (status == 2) {
            message.error(`${errorMes}`);
        } else if (status == 3) {
            message.error('登录失效');
        } else if (status == 1) {
            message.success('上传成功');
            this.getContractOrderUser();
        }
    };

    value2Redux = async data => {
        await this.props.dispatch(setCurrentFilterStatus(data));
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    /** 渲染select选择框 */
    renderSelectStatus = () => {
        return this.selectConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <Select style={{ width: 150 }} value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
                    {
                        this.publicSelect && this.publicSelect.map((obj, idx) => (
                            <Option key={idx} value={obj.value}>{obj.label}</Option>
                        ))
                    }
                </Select>
            </div>
        ));
    };

    /** 新增或修改合同订单 */
    newContractOrder = () => {
        let url = `/addOrModify`;
        this.props.history.push(url);
    };

    /** 批量分配班级 */
    assignClassesBulk = async () => {
        await this.props.dispatch(clearBatchClassStatus());
        let url = `/batchClass`;
        this.props.history.push(url);
    };

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>合同订单</span>
            <div className="headerButton">
                {checkPermission('user:contract:gkadd') && <Button type="primary" className="mr10" onClick={this.newContractOrder}>新增合同订单</Button>}

                {checkPermission('user:contract:gkdown') && <Dropdown overlay={menu}>
                    <Button type="primary" className="mr10">
                        订单模板下载
                    </Button>
                </Dropdown>}

                {checkPermission('contract:logistics:upload') && <Upload
                    {...this.uploadSetting}
                    onChange={this.uploadHandler}
                    headers={headers}
                    action={`/gk/ops/contract/excel/logistics/upload`}
                >
                    <Button type="primary" className="mr10">
                        导入快递信息Excel
                    </Button>
                </Upload>}

                {checkPermission('user:contract:upload') && <Upload
                    {...this.uploadSetting}
                    onChange={this.uploadHandler}
                    headers={headers}
                    action={`/gk/ops/contract/excel/user/upload`}
                >
                    <Button type="primary" className="mr10">
                        导入合同订单Excel
                    </Button>
                </Upload>}
                {checkPermission('user:contract:export') && <Button type="primary" className="mr10" onClick={this.downExcel}>导出合同订单Excel</Button>}
            </div>
        </div>
    );

    /** 导出合同订单 */
    downExcel = () => {
        const { page, pageSize, currentArea, isEnd, isInterview, userInfoIds, status, isRenew, salesName, headTeacherId, classId } = this.props;
        let params = { page, pageSize };
        (currentArea != '全部') && (params.examArea = currentArea);
        (isEnd != '全部') && (params.isEnd = isEnd);
        (isInterview != '全部') && (params.isInterview = isInterview);
        (status != '全部') && (params.status = status);
        (isRenew != '全部') && (params.isRenew = isRenew);
        (salesName != '全部') && (params.salesName = salesName);
        (headTeacherId != '全部') && (params.headTeacherId = headTeacherId);
        userInfoIds && (userInfoIds.length > 0) && (params.userInfoIds = userInfoIds);
        (classId != '全部') && (params.classId = classId);
        this.props.dispatch(exportContractOrder(params));
    };

    /** 处理客户搜索框 */
    handleChange = async value => {
        await this.props.dispatch(userHandler(value));
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(resetCurrentPage(1));
    };

    searchCustormer = value => {
        if (!value) return;
        this.props.dispatch(searchUserInfo(value));
    };

    changeApplicationArea = async value => {
        await this.props.dispatch(changeCurrentArea(value));
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    changeApplicationType = async value => {
        await this.props.dispatch(setCurrentFilterStatus({ key: 'isType', value: value }));
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        let data = { title: value }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 督学详情 */
    superviseDetail = (type, record) => {
        let userName = record.userName;
        let index1 = userName.indexOf('(')
        let index2 = userName.indexOf(')')
        let name = userName.slice(0, index1);
        let mobile = userName.slice(index1 + 1, index2)
        let url = `/inquiryDetail?type=${type}&userInfoId=${record.userInfoId}&className=${record.className}&name=${name}&mobile=${mobile}`
        this.props.history.push(url);
    }

    /** 打开学生信息详情 */
    open = (data) => {
        this.props.dispatch(openModleAction(data))
    }

    generateColumns() {
        const columns = [
            {
                title: '客户',
                width: '180px',
                fixed: 'left',
                render: (text, record) => (
                    checkPermission('user:info:detail') && <span className="active" onClick={() => this.open({ key: 'userModle', value: { open: true, id: record.userInfoId } })}>{record.userName}</span> || <span>{record.userName}</span>
                ),
            },
            {
                title: '班级',
                render: (text, record) => (
                    checkPermission('sys:clazz:info') && <span className="active" onClick={() => this.open({ key: 'classModle', value: { open: true, id: record.classId } })}>{record.className}</span> || <span>{record.className}</span>
                ),
                width: '150px',
            },
            {
                title: '合同编号',
                render: record => (
                    checkPermission('user:contract:info') && <Link to={`/addOrModify?contractId=${record.id}`}>
                        <span>{record.contractNo}</span>
                    </Link> || <span>{record.contractNo}</span>
                ),
                width: '170px'
            },
            {
                title: '报考地区',
                dataIndex: 'examArea',
                width: '100px',
            },
            {
                title: '合同签订日期',
                width: '140px',
                dataIndex: 'signingDate',
            },
            {
                title: '合同金额',
                dataIndex: 'amount',
                width: '100px'
            },
            {
                title: '是否退款',
                width: '100px',
                render: record => (
                    <span>{record.status == 1 ? '是' : '否'}</span>
                )
            },
            {
                title: '销售',
                dataIndex: 'salesName',
                width: '80px',
            },
            {
                title: '销售微信',
                dataIndex: 'salesWechat',
                width: '120px',
            },
            {
                title: '课程',
                dataIndex: 'courseName',
                width: '150px',
            },
            {
                title: '开班日期',
                width: '140px',
                dataIndex: 'startDate',
            },
            {
                title: '协议书是否收回',
                width: '80px',
                render: record => (
                    <span>{record.protocol == 1 ? '是' : '否'}</span>
                )
            },
            {
                title: '班主任',
                dataIndex: 'headTeacher',
                width: '80px',
            },
            {
                title: '盯学',
                width: '60px',
                render: record => (
                    <div>
                        {
                            (!checkPermission('user:supervise:page') && record.superviseNum == 0) ?
                                <span>{record.superviseNum}</span>
                                :
                                <span className="detail" onClick={() => this.superviseDetail('supervise', record)}>{record.superviseNum}</span>
                        }
                    </div>
                )
            },
            {
                title: '答疑',
                width: '60px',
                render: record => (
                    <div>
                        {
                            (!checkPermission('user:ask:page') && record.userAskNum == 0) ?
                                <span>{record.userAskNum}</span>
                                :
                                <span className="detail" onClick={() => this.superviseDetail('ask', record)}>{record.userAskNum}</span>
                        }
                    </div>
                )
            },
            {
                title: '关怀',
                width: '60px',
                render: record => (
                    <div>
                        {
                            (!checkPermission('user:care:page') && record.userCareNum == 0) ?
                                <span>{record.userCareNum}</span>
                                :
                                <span className="detail" onClick={() => this.superviseDetail('userCare', record)}>{record.userCareNum}</span>
                        }
                    </div>
                )
            },
            {
                title: '回访',
                width: '60px',
                render: record => (
                    <div>
                        {
                            (!checkPermission('user:visit:page') && record.returnVisitNum == 0) ?
                                <span>{record.returnVisitNum}</span>
                                :
                                <span className="detail" onClick={() => this.superviseDetail('returnVisit', record)}>{record.returnVisitNum}</span>
                        }
                    </div>
                )
            },
            {
                title: '是否进面',
                width: '100px',
                render: record => (
                    <span>
                        {record.isInterview == 0 && '否'}
                        {record.isInterview == 1 && '是'}
                        {!record.isInterview && ''}
                    </span>
                )
            },
            {
                title: '是否续费',
                width: '100px',
                render: record => (
                    <span>
                        {record.isRenew == 1 && '是'}
                        {record.isRenew == 0 && '否'}
                        {!record.isRenew && ''}
                    </span>
                )
            },
            {
                title: '是否结课',
                width: '100px',
                render: record => (
                    <span>
                        {record.isEnd == 1 && '是'}
                        {record.isEnd == 0 && '否'}
                        {!record.isEnd && ''}
                    </span>
                )
            },
            {
                title: '快递',
                width: '150px',
                dataIndex: 'logisticsNo',
            },
            {
                title: '快递公司',
                width: '120px',
                dataIndex: 'logisticsCompany'
            },
            {
                title: '备注',
                width: '120px',
                dataIndex: 'remarks',
            },
        ];
        return columns;
    };

    /** 销售筛选搜索 */
    salesNameSearch = async event => {
        await this.props.dispatch(modifyCurrentSales(event));
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    /** 班主任筛选搜索 */
    headTeacherSearch = async event => {
        await this.props.dispatch(modifyCurrentHeadTeacher(event));
        await this.resetActivePage1();
        await this.getContractOrderUser();
    };

    /** 分页组件改变页码 */
    paginationChange = async page => {
        await this.props.dispatch(modifyCurrentPage(page));
        this.getContractOrderUser();
    };

    /** 分页组件改变每页条数 */
    showSizeChange = async (current, size) => {
        await this.resetActivePage1();
        await this.props.dispatch(modifyCurrentPageSize(size));
        this.getContractOrderUser();
    };

    /** 清空搜索条件 */
    resetSearch = async () => {
        await this.props.dispatch(resetSearchCriteria());
        await this.resetActivePage1();
        this.getContractOrderUser();
    };

    /** 处理当前选中的班级 */
    handlerClassSelect = async value => {
        await this.props.dispatch(setCurrentFilterStatus({ key: 'classIdx', value }));
        const { classIdx, classes } = this.props;
        if (classIdx !== null) {
            let currentClasses = [{ className: '全部', id: '全部' }].concat(classes);
            let currentClass = currentClasses[classIdx];
            await this.props.dispatch(setCurrentFilterStatus({ key: 'className', value: currentClass.className }));
            await this.props.dispatch(setCurrentFilterStatus({ key: 'classId', value: currentClass.id }));
            await this.resetActivePage1();
            this.getContractOrderUser();
        }
    };

    /** 合同编号回车查询 */
    onPressEnter = async (e) => {
        await this.props.dispatch(contractNoHandler(e.target.value.trim()));
        await this.resetActivePage1();
        this.getContractOrderUser();
    }

    /** 获取合同编号值 */
    contractNoChange = (e) => {
        this.props.dispatch(contractNoHandler(e.target.value.trim()));
    }

    render() {
        const {
            isType, classModle, userModle, custormers, userInfoIds, contractNo, appLicationArea, currentArea, contractInfo, headTeacherId, salesName, salles, headTeachers, page, pageSize, loading, classes, className
        } = this.props;
        let columns = this.generateColumns();
        return (
            <div className="contract-order">
                {userModle.open && <UserInfo updeteCb={this.getContractOrderUser} />}
                {classModle.open && <ClassInfo updeteCb={this.getContractOrderUser} />}
                <div className="commonSearchBox">
                    <div>
                        <label>客户</label>
                        <Select
                            mode="multiple"
                            value={userInfoIds}
                            style={{ width: '400px' }}
                            filterOption={false}
                            placeholder="请输入客户姓名或手机号"
                            onSearch={value => { this.searchCustormer(value) }}
                            onChange={value => { this.handleChange(value) }}
                        >
                            {
                                custormers.map((item, idx) => (
                                    <Option key={idx} value={item.id}>{`${item.name}(${item.mobile})`}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班级</label>
                        <Select
                            showSearch
                            placeholder="请输入班级"
                            style={{ width: '400px' }}
                            filterOption={false}
                            value={className}
                            onSearch={value => { this.searchClass(value) }}
                            onChange={value => { this.handlerClassSelect(value) }}
                        >
                            {
                                [{ className: '全部', id: '全部' }].concat(classes).map((item, index) => (
                                    <Option key={index} value={index}>{item.className}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>合同编号</label>
                        <Input style={{ width: '400px' }} placeholder="请输入合同编号,回车键查询" value={contractNo} onChange={this.contractNoChange} onPressEnter={this.onPressEnter} />
                    </div>
                    {this.renderSelectStatus()}
                    <div>
                        <label>销售</label>
                        <Select
                            style={{ width: 150 }}
                            showSearch
                            filterOption={false}
                            onSearch={value => { this.employeeSearch(sell, value) }}
                            value={salesName}
                            onChange={event => { this.salesNameSearch(event) }}
                        >
                            {
                                [{ name: '全部' }].concat(salles).map((item, idx) => (
                                    <Option key={idx} value={item.name}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班主任</label>
                        <Select
                            style={{ width: 150 }}
                            showSearch
                            filterOption={false}
                            onSearch={value => { this.employeeSearch(classTeacher, value) }}
                            value={headTeacherId}
                            onChange={event => { this.headTeacherSearch(event) }}
                        >
                            {
                                [{ name: '全部', id: '全部' }].concat(headTeachers).map((item, idx) => (
                                    <Option key={idx} value={item.id}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>报考地区</label>
                        <Select
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={currentArea}
                            onChange={event => { this.changeApplicationArea(event) }}
                        >
                            {
                                ['全部'].concat(appLicationArea).map((item, idx) => (
                                    <Option key={idx} value={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>科目</label>
                        <Select
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={isType}
                            onChange={event => { this.changeApplicationType(event) }}
                        >
                            <Option value={null}>全部</Option>
                            <Option value={0}>行测</Option>
                            <Option value={1}>申论</Option>
                            <Option value={3}>公基</Option>
                            <Option value={4}>职测</Option>
                            <Option value={5}>综应</Option>
                        </Select>
                    </div>
                    <div>
                        <Button disabled={loading} type="danger" onClick={this.resetSearch}>清空搜索条件</Button>
                        {checkPermission('user:batch:gkfp') && <Button className="ml10" type="primary" onClick={this.assignClassesBulk}>批量分配班级</Button>}
                    </div>
                </div>
                <Table
                    columns={columns}
                    title={this.renderHeader}
                    dataSource={contractInfo && contractInfo.content || []}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: 2500, y: 'calc(70vh)' }}
                    className="mt30"
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: contractInfo && contractInfo.totalElements || 0,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.paginationChange,
                        onShowSizeChange: this.showSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                />
            </div>
        )
    }
}

function mapStatetoProps(state) {

    const {
        classModle,
        userModle
    } = state.inquiryReducer;


    const {
        ...initialState
    } = state.contractOrder;

    return {
        classModle,
        userModle,
        ...initialState
    };
}

export default connect(mapStatetoProps)(ContractOrder);
