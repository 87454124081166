import {UPLOAD_WORD} from './action'
export function upload(state = {
  questionList:''
}, action) {
switch (action.type) {
  case UPLOAD_WORD:
    return Object.assign({}, state, {questionList: action.data});
  default:
    return state;
  }
}
