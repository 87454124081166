import {message} from 'antd';
import apiRequest from '../../common/request'
import OSS from 'ali-oss';

const PREFIX = "ZHY_INFO";

export const REQUEST_UPDATE_QUESTION = PREFIX + "REQUEST_UPDATE_QUESTION";
export const REQUEST_UPDATE_QUESTION_VIDEO = PREFIX + "REQUEST_UPDATE_QUESTION_VIDEO";
export const REQUEST_QUESTION_INFO = PREFIX + "REQUEST_QUESTION_INFO";
export const RECEIVE_QUESTION_INFO = PREFIX + "RECEIVE_QUESTION_INFO";
export const RECEIVE_UPDATE_QUESTION_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_RESULT";
export const RECEIVE_UPDATE_QUESTION_VIDEO_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_VIDEO_RESULT";

export const UPDATE_QUESTION_SOURCE = PREFIX + "UPDATE_QUESTION_SOURCE";
export const UPDATE_QUESTION_SUITCROWDS = PREFIX + "UPDATE_QUESTION_SUITCROWDS";
export const UPDATE_QUESTION_MATERIALS = PREFIX + "UPDATE_QUESTION_MATERIALS";
export const ADD_QUESTION_MATERIALS = PREFIX + "ADD_QUESTION_MATERIALS";
export const REMOVE_QUESTION_MATERIALS = PREFIX + "REMOVE_QUESTION_MATERIALS";
export const ADD_QUESTION_QUESTION = PREFIX + "ADD_QUESTION_QUESTION";
export const REMOVE_QUESTION_QUESTION = PREFIX + "REMOVE_QUESTION_QUESTION";

export const UPDATE_QUESTION_JOINMATERIAL = PREFIX + "UPDATE_QUESTION_JOINMATERIAL";
export const UPDATE_QUESTION_DESCRIPTION = PREFIX + "UPDATE_QUESTION_DESCRIPTION";
export const UPDATE_QUESTION_METHOD = PREFIX + "UPDATE_QUESTION_METHOD";
export const UPDATE_QUESTION_SOLUTION = PREFIX + "UPDATE_QUESTION_SOLUTION";
export const UPDATE_QUESTION_YEAR = PREFIX + "UPDATE_QUESTION_YEAR";
export const UPDATE_QUESTION_AREA = PREFIX + "UPDATE_QUESTION_AREA";
export const UPDATE_QUESTION_QUESTYPE = PREFIX + "UPDATE_QUESTION_QUESTYPE";
export const UPDATE_QUESTION_TAG = PREFIX + "UPDATE_QUESTION_TAG";
export const UPDATE_QUESTION_DISPLAYTITLE = PREFIX + "UPDATE_QUESTION_DISPLAYTITLE";

export const UPDATE_VIDEO_PROGRESS = PREFIX + "UPDATE_VIDEO_PROGRESS";

export function request_question_info() {
    return {
        type: REQUEST_QUESTION_INFO
    }
}

export function receive_question_info(question) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question,
        receivedAt: Date.now()
    }
}

function request_update_question() {
    return {
        type: REQUEST_UPDATE_QUESTION
    }
}

function request_update_question_video() {
    return {
        type: REQUEST_UPDATE_QUESTION_VIDEO
    }
}

function receive_update_question_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_RESULT
    }
}

function receive_update_question_video_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_VIDEO_RESULT
    }
}

export function update_question_displaytitle(value) {
    return {
        type: UPDATE_QUESTION_DISPLAYTITLE,
        key: 'title',
        value
    }
}

export function update_question_source(value) {
    return {
        type: UPDATE_QUESTION_SOURCE,
        key: 'source',
        value
    }
}

export function update_question_suitCrowds(value) {
    return {
        type: UPDATE_QUESTION_SUITCROWDS,
        key: 'suitCrowds',
        value
    }
}

export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function update_question_materials(value, index) {
    return {
        type: UPDATE_QUESTION_MATERIALS,
        key: 'materials',
        value,
        index
    }
}

export function add_question_materials() {
    return {
        type: ADD_QUESTION_MATERIALS,
    }
}

export function remove_question_materials(index) {
    return {
        type: REMOVE_QUESTION_MATERIALS,
        index
    }
}

export function add_question_question() {
    return {
        type: ADD_QUESTION_QUESTION,
    }
}

export function remove_question_question(index) {
    return {
        type: REMOVE_QUESTION_QUESTION,
        index
    }
}

export function update_question_description(value, index) {
    return {
        type: UPDATE_QUESTION_DESCRIPTION,
        key: 'question',
        value,
        index
    }
}

export function update_question_joinmaterial(value, index) {
    return {
        type: UPDATE_QUESTION_JOINMATERIAL,
        key: 'materialStr',
        value,
        index
    }
}

export function update_question_method(value, index) {
    return {
        type: UPDATE_QUESTION_METHOD,
        key: 'method',
        value,
        index
    }
}

export function update_question_solution(value, index) {
    return {
        type: UPDATE_QUESTION_SOLUTION,
        key: 'solution',
        value,
        index
    }
}

export function update_question_year(value) {
    return {
        type: UPDATE_QUESTION_YEAR,
        key: 'year',
        value
    }
}

export function update_question_area(value) {
    return {
        type: UPDATE_QUESTION_AREA,
        key: 'area',
        value
    }
}

export function update_question_tag(value) {
    return {
        type: UPDATE_QUESTION_TAG,
        key: 'questionTag',
        value
    }
}

export function update_question_quesType(value, index) {
    return {
        type: UPDATE_QUESTION_QUESTYPE,
        key: 'categoryId',
        value,
        index
    }
}

export function fetchEmpty() {
    return {
        type:RECEIVE_QUESTION_INFO,
        question: {
          title: '',
        //   categoryId:"-1",
          area: "",
          year: "",
          suitCrowds: "",
          materials: [""],
        //   tag: '-1',
          source: "",
          questions: [{method:"",question:"",solution:"",materials:[]}]
        },
        receivedAt: Date.now()
    }
}

export function updateQuestionVideo(file, info){

    return dispatch => {
        dispatch(request_update_question_video());
        return apiRequest("/gk/ops/sts/getToken?type=oss",{method: 'GET',credentials: 'include'})
          .then(json => {
            let result = json.obj;
            let client = new OSS({
                accessKeyId: result.accessKeyId,
                accessKeySecret: result.accessKeySecret,
                stsToken: result.securityToken,
                endpoint: 'https://oss-cn-beijing.aliyuncs.com',
                bucket: 'gk-course'
            });

            client.multipartUpload('/zongying/'+info.categoryId+"/"+file.name, file, {
                progress: function (p) {
                  return function (done) {
                    dispatch(update_video_progress(p*100+"%"));
                    done();
                  };
                },
            }).then((result) => {
                dispatch(receive_update_question_video_result());
            }).catch(function (err) {
                dispatch(receive_update_question_video_result());
                message.error(json.errorMes);
                console.log(err);
            });
          })
          .catch(error => console.log(error));
    }
}

/** 除0外，基本数据类型的简单验证 */
function simpleVerify(key, source, target) {
    if (source[key]) {
        target[key] = source[key];
    }
}

/** 删除无效数据 */
function deleteUseless(obj) {
    if (Object.prototype.toString.call(obj) !== "[object Object]") return;
    Object.keys(obj).map(key => {
        if (!obj[key]) {
            delete obj[key];
        }
        if (Object.prototype.toString.call(obj[key]) === "[object Array]" && obj[key].length === 0) delete obj[key];
    });
}

export function updateQuestion(questionInfo) {
    let data = {};
    let verifyKeys = [ 'id', 'categoryId', 'year', 'area', 'suitCrowds', 'title', 'questionTag', 'method', 'solution', 'source' ];
    verifyKeys.map(key => simpleVerify(key, questionInfo, data));
    if (questionInfo.materials) {
        data.materials = questionInfo.materials.map((value, index)=> {return {id:index+1,materials:value,number: index+1}});
    }
    data.questions = questionInfo.questions.map(item => {
        delete item['detailMaterials'];
        delete item['materialStr'];
        delete item['area'];
        delete item['year'];
        deleteUseless(item);
        return item
    });
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_question());
        return apiRequest('/gk/ops/zongying/saveOrUpdate', config)
          .then(json => {
              dispatch(receive_update_question_result());
              message.success('操作成功');
              window.history.back();
          })
          .catch(error => {
              dispatch(receive_update_question_result());
              console.log(error)
          });
    }
}

export function fetchShenlun(id) {
    if (id)
        return dispatch => {
            return apiRequest(`/gk/ops/zongying/details/${id}`, {credentials: 'include'})
              .then(json => {
                  let question = json.obj
                  if (question){
                      question.materialOrigin = question.materials;
                      question.materials = question.materials ? question.materials.map(item => item.materials) : [];
                      dispatch(receive_question_info(question))
                  }
              })
              .catch(error => console.log(error));
        }
}
