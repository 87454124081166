import {
    RESET_STATE,
    COMMON_SET_STATUS
} from './actions.js';

const init = {
    year: '',
    area: '',
    publish: '',
    query: '',
    page: 1,
    pageSize: 10,
};

const initialState = {
    ...init,
    examType: 'gj',
    jobYear: [],
    jobArea: [],
    loading: true,
    institutionExamObj: {}
}

export function paperList (state=initialState, action) {
    switch(action.type){
        case COMMON_SET_STATUS:
            return Object.assign({}, state, {[action.key]: action.value,});
        case RESET_STATE:
            return Object.assign({}, state, {...init});    
        default:
            return state;
    }
}