import {
    RECEIVE_EXERCISE,
    SEARCH_DATA_ARRIVE,
    BEGIN_FETCHING_DATA,
    SET_EXERCISE_LIST,
    SET_VALUE
} from './action'

const initialState = {
    isFetching: false,
    title: '',                // 搜索
    exerciseList: [],
    originExerciseList: [],      // 原始的练习列表数据
    exerciseTotalElements: 10,
    searchedExerciseList: [],   // 根据标题所匹配到的班级测试列表数据
    searchTotalElements: 10,    // 搜索出来的结果总共有多少元素
    searchPage: 1,
    searchPageSize: 10,
};

export function interviewClassExercise (state=initialState, action) {
    switch (action.type) {
        case RECEIVE_EXERCISE:
            let newConst = [...action.exerciseList];
            return Object.assign({}, state, {
                exerciseList: action.exerciseList, 
                isFetching: false,
                originExerciseList: newConst,
                exerciseTotalElements: action.exerciseTotalElements,
            });
        case SEARCH_DATA_ARRIVE:
            let result = action.data;
            let searchedExerciseList = result.content;
            let searchTotalElements = result.totalElements;
            return Object.assign({}, state, { searchedExerciseList, searchTotalElements, isFetching: false });
        case BEGIN_FETCHING_DATA:
            return Object.assign({}, state, { isFetching: true });
        case SET_EXERCISE_LIST:
            return Object.assign({}, state, { exerciseList: action.data });
        case SET_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        default:
            return state;
    }
}