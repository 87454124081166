import {
    CHECK_LOADING,
    SET_CURRENT_COURSENAME,
    SET_DAY_NUMBER,
    SET_FILTER_STATUS,
    SET_COURSE_METADATA,
    LOADING_COURSE_METADATA,
    LOADING_STUDENT_LEARN_INFO,
    UNLOADING_STUDENT_LEARN_INFO,
    SET_CURRENT_DAY,
    SET_STUDENT_LEARN_INFO,
    SHOW_SIZE_CHANGE,
    SET_CURRENT_PAGE,
    FETCH_CLASS_DETAIL_SUCCED,
    UNLOADING,
    SET_STUDENT_LIST
} from './actions';

import { SET_STUDENT_CLASS } from "../Inquiry/action";

const initialState = {
    checkLoading: false,
    currentClassObj: {
        courseName: ''
    },                                  // 当前选中的班级对象
    currentCourseName: '',              // 当前选中的课程名字
    dayNumberArr: [],                   // 天数数组
    currentDay: 1,                      // 当前选中天数
    filterStatus: 'ALL',                // 筛选所有，三个可选值，分别是DONE, ALL, NOT
    courseMetadata: {},                 // 课程元数据
    loadingCourseMetadata: true,        // 表示正在加载课程元数据信息
    loadingStudentLearnInfo: true,      // 表示正在班级学员的练习情况数据
    showSize: 100,                       // 每页展示多少条数据
    currentPage: 1,                     // 当前是第几页
    studentLearnInfo: {
        content: []
    },                                  // 指定的课程所对应的练习数据
    originStudentLearnInfo: {
        content: []
    },                                  // 这是最原始的数据，在搜索学员的时候需要用上
    classes: [],                        // 班级
};


const classDetail = (previousState=initialState, action) => {
    switch(action.type) {
        case CHECK_LOADING:
            return { ...previousState, checkLoading: action.data }
        case FETCH_CLASS_DETAIL_SUCCED:
            return { ...previousState, currentClassObj: action.data };
        case SET_CURRENT_COURSENAME:
            return { ...previousState, currentCourseName: action.data };
        case SET_DAY_NUMBER:
            return { ...previousState, dayNumberArr: action.data };
        case SET_FILTER_STATUS:
            return { ...previousState, filterStatus: action.data };
        case LOADING_COURSE_METADATA:
            return { ...previousState, loadingCourseMetadata: true };
        case UNLOADING_STUDENT_LEARN_INFO:
            return { ...previousState, studentLearnInfo:{content:[]},loadingStudentLearnInfo: false };
        case UNLOADING:
            return { ...previousState, loadingStudentLearnInfo: false };
        case SET_COURSE_METADATA:
            return { ...previousState, courseMetadata: action.data, loadingCourseMetadata: false };
        case LOADING_STUDENT_LEARN_INFO:
            return { ...previousState, loadingStudentLearnInfo: true };
        case SET_CURRENT_DAY:
            return { ...previousState, currentDay: action.data };
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case SET_STUDENT_LEARN_INFO:
            return { ...previousState, studentLearnInfo: action.data, originStudentLearnInfo: action.data, loadingStudentLearnInfo: false };
        case SET_STUDENT_LIST:
            return { ...previousState, studentLearnInfo: { ...previousState.studentLearnInfo, content: [...action.data] } };
        case SHOW_SIZE_CHANGE:
            return { ...previousState, showSize: action.data };
        case SET_CURRENT_PAGE:
            return { ...previousState, currentPage: action.data };
        default:
            return previousState;
    }
}

export { classDetail };
