import { message } from 'antd';
import apiRequest from '../../common/request';

export const getSystemRoleList = async (params) => {
	let data = {
		page: params.page || 1,
		pageSize: params.pageSize || 10,
	};
	(params.status !='') && (data.status = params.status) || '';
    params.endTime && (data.endTime = params.endTime) || '';
    params.startTime && (data.startTime = params.startTime) || '';
    params.roleKey && (data.roleKey = params.roleKey) || '';
    params.roleName && (data.roleName = params.roleName) || '';

	let url = `/gk/ops/system/role/page`;
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);    
    return obj.obj;
}

/** 获取角色管理新增角色树结构 */
export const getSystemTreeSelect = async (params) => {
	let data = {
		menuName: params.menuName || '',
		status: params.status || '',
		visible: params.visible || ''
	}
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let url = `/gk/ops/system/menu/treeSelect`;
	let obj = await apiRequest(url, config);
	return (obj.obj) || [];
}

/** 获取角色管理修改角色树结构 */
export const roleMenuTreeSelect = async (roleId) => {
	let url = `/gk/ops/system/menu/roleMenuTreeSelect/${roleId}`;
	let obj = await apiRequest(url);
	return (obj.obj) || [];
}

/** 改变用户状态 */
export const changeStatus = async (roleId, status) => {
	let url = `/gk/ops/system/role/changeStatus/${roleId}/${status}`;
	await apiRequest(url).then(res=>{
		if(res.status==1){
			message.success('修改成功');
		}
	}).catch(err => {
		console.log('err',err);
		message.success(err);
	});
}

/** 删除菜单信息 */
export const systemDeleteRole = async (id) => {
	let url = `/gk/ops/system/role/delete/${id}`;
	let config = {
		method: 'POST',
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let res =  await apiRequest(url, config);
	if(res.status==1){
		message.success('删除成功');
	}
	return res;
}


/** 新增或者修改菜单信息 */
export const systemAddOrUpdateRole = async (params, type) => {
	let data = {
		status: params.status,
		roleSort: params.roleSort
	}
	params.menuIds.length && (data.menuIds = params.menuIds) || '';
	params.roleName && (data.roleName = params.roleName) || '';
	params.roleKey && (data.roleKey = params.roleKey) || '';
	params.password && (data.password = params.password) || '';
	params.id && (data.id = params.id) || '';
	let url = `/gk/ops/system/role/save`;
	type && (url = `/gk/ops/system/role/update`);

	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let res =  await apiRequest(url, config);
	if(res.status==1){
		message.success('操作成功');
	}
	return res;
}
