import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Input, Button, message, Popconfirm, Typography } from "antd";
import { commonChange, deleteClass, fetchClasses, changeClassList, updateClassList, updateCourseNum } from "./action";
import { checkPermission, generateBaseUrl, getSearchQuery } from '../../utils/utils'
import apiRequest from '../../common/request';
import { fetchEmpty } from '../CourseClassInfo/action';
import { resetSearchStatus } from '../../components/ShenlunSelectCard/actions';
import { resetSearchStatus as resetXingceSearchStatus } from '../../components/QuestionSelectCard/actions';

const { Paragraph } = Typography;

class CourseClassList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copyIds: [],            // 批量复制的课时id,用于勾选
            copyCourseId: '',    // 批量复制的课时id, 用于文本框
        }
    }

    componentDidMount() {
        // title在上个页面更新返回会丢失，所以要存redux
        if(getSearchQuery('title')) {
            this.props.dispatch(commonChange('title', getSearchQuery('title')));
        }
        this.baseUrl = generateBaseUrl();
        this.props.dispatch(fetchClasses(this.props.match.params.courseId));
    }

    /** 移动方法 */
    handleResort = (fromIndex, e) => {
        let toIndex = parseInt(e.target.value) - 1;
        e.target.value = "";
        let items = this.props.items || [];
        items = items.slice(0);
        if (toIndex > (items.length - 1)) {
            message.warning('不能超过当前课时数量');
            return;
        }
        if (toIndex < 0) {
            message.warning('不能小于1');
            return;
        }
        let target = items.splice(fromIndex, 1)[0];         // 当前索引对对应的课时
        items.splice(toIndex, 0, target);                   // 往目标位置插入该课时
        this.props.dispatch(changeClassList(items));
    }

    updateClassList = () => {
        const items = this.props.items;
        const courseId = items[0].courseId;
        const ids = items.map(item => {
            return { classId: item.id, courseNum: item.courseNum }
        });
        this.props.dispatch(updateClassList(courseId, ids));
    };

    /** 筛选id对应的文本内容，用于点击复制二次确认提示 */
    getTitle = () => {
        let copyCourseId = this.state.copyCourseId;
        let text = '请输入正确id';
        
        if(copyCourseId) {
            let text2 = ''
            copyCourseId.split(',').map(i=>{
                this.props.items.find(t=>{
                    if(t.id==i) text2 += t.teacher+'的'+t.title+',';
                })
            })
            text = '确定复制"' + text2 + '"吗';
        }
        return text;
    };

    /** 把A班级的A1课时复制到B班级，那么courseId是B的id, classId是A1的ID */
    copyCourse = () => {
        let that = this;
        let courseId = this.props.match.params.courseId;
        let copyCourseId = this.state.copyCourseId;
        if (!copyCourseId) return message.warn('请输入内容');
        let url = `/gk/ops/course/class/copyClass/${courseId}`;
        let config = {
            body: JSON.stringify(copyCourseId.split(',')),
            method: 'POST',
            headers: { 'Acceppt': 'application/json', 'Content-Type': 'application/json' },
        };
        return apiRequest(url, config)
            .then(json => {
                message.success('复制成功');
                that.setState({ copyCourseId: '', copyIds: [] });
                that.props.dispatch(fetchClasses(this.props.match.params.courseId));
            })
            .catch(error => console.log(error));
    }

    /** 处理课时ID的输入 */
    courseIdChange = e => {
        this.setState({ copyCourseId: e.target.value });
    }

    /** 点击添加新课时 */
    addNewCourse = async () => {
        await this.props.dispatch(fetchEmpty(this.props.match.params.courseId));                    // 先初始化课程详情页面的数据
        await this.props.dispatch(resetSearchStatus());
        await this.props.dispatch(resetXingceSearchStatus());
        let url = `/course/${this.props.match.params.courseId}/classRelease/${this.props.match.params.courseType}`;
        this.props.history.push(url);
    }

    /** 查看已有课时 */
    gotoLessonDetail = async item => {
        await this.props.dispatch(fetchEmpty(this.props.match.params.courseId));
        await this.props.dispatch(resetSearchStatus());
        await this.props.dispatch(resetXingceSearchStatus());
        let url = `/course/${item.courseId}/class/${item.id}/${this.props.match.params.courseType}`;
        this.props.history.push(url);
    }

    /** 课时天数input按下回车的时候被调用 */
    courseNumChangeHandler = async (event, item) => {
        if (event.keyCode == 13) {
            const newCourseNum = event.target.value;
            item = Object.assign({}, item);
            item.courseNum = +newCourseNum;
            let result = await updateCourseNum(item);
            if (result && result.status) {
                message.success('操作成功');
                this.props.dispatch(fetchClasses(this.props.match.params.courseId));
            } else {
                message.error((result && result.errorMes) || '操作失败');
            }
        }
    }

    /** 输入框失去焦点，为什么需要加上这一步，因为我需要确保用户在脱离了输入区域后所看到的课时天数就是准确的值 */
    courseNumInputLostBlur = (event, item) => {
        const originCourseNum = item.courseNum;
        event.target.placeholder = originCourseNum;
        event.target.value = null;
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '标题',
                width: '20%',
                render: (text, record) => (
                    checkPermission('sys:class:info') && <Link to={`/course/${record.courseId}/class/${record.id}/${this.props.match.params.courseType}`} className="title">{record.title}</Link> || <span className="title">{record.title}</span>
                ),
            },
            {
                title: 'id',
                width: '10%',
                dataIndex: 'id',
            },
            {
                title: '老师',
                width: '10%',
                dataIndex: 'teacher',
            },
            {
                title: '第几天',
                width: '10%',
                render: (text, record) => {
                    return (
                        checkPermission('sys:class:edit') && <input
                            style={{maxWidth: '80px'}}
                            type="number"
                            min={1}
                            placeholder={record.courseNum}
                            onKeyUp={event => this.courseNumChangeHandler(event, record)}
                            onBlur={event => this.courseNumInputLostBlur(event, record)}
                        /> || <span>{record.courseNum}</span>
                    )
                }
            },
            {
                title: '练习题',
                width: '10%',
                render: (text, record) => {
                    return (
                        <span>{record.exercise && '已配置'}</span>
                    )
                }
            },
            {
                title: 'h5链接',
                width: '10%',
                render: (text, record) => {
                    return (
                        checkPermission('sys:class:gkfz') && <Paragraph copyable={{ text: `${this.baseUrl}/share/pages/course.html?courseId=${this.props.match.params.courseId}&classId=${record.id}` }}>复制</Paragraph>
                    )
                }
            },
            {
                title: '删除',
                width: '10%',
                render: (text, record) => 
                <Popconfirm
                    title="确定删除该课时吗？"
                    onConfirm={() => this.props.dispatch(deleteClass(record.id))}
                    okText="确定"
                    cancelText="取消"
                    disabled={!checkPermission('sys:class:del')}
                >
                    <Button type="link" disabled={!checkPermission('sys:class:del')}>删除</Button>
                </Popconfirm>
            },
            {
                title: '序号',
                width: '10%',
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: '移动',
                width: '10%',
                render: (text, record, index) => {
                    return (
                        checkPermission('sys:class:gkyd') && <input
                            type="number"
                            style={{width: '90%'}}
                            max={this.props.items.length}
                            min={1}
                            onKeyUp={(e) => {
                                if (e.keyCode == 13) {
                                    this.handleResort(index, e);
                                }
                            }}
                        />
                    )
                }
            },
        ];
        return columns;
    }

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let sel = this.state.copyIds;
        if (selected) {
            sel.push(record.id);
        } else {
            let idx = sel.indexOf(record.id);
            sel.splice(idx, 1);
        }
        this.setState({copyIds: [...sel]});
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let sel = this.state.copyIds;
        if (selected) {
            changeRows.map(item => {
                sel.push(item.id);
            })
        } else {
            changeRows.map(item => {
                sel = sel.filter(v => v != item.id);
            })
        }
        this.setState({copyIds: [...sel]});
    };

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <div>课时列表</div>
            <Paragraph copyable={this.state.copyIds.length && { text: this.state.copyIds.toString() } || false}>复制多个课时id</Paragraph>
        </div>
    );

    render() {
        let { isFetching, items, title} = this.props;
        return (
            <div>
                <h3>课程标题：{getSearchQuery('title') || title}</h3>

                {checkPermission('sys:class:copy') && <div style={{position: 'sticky', padding:'10px 0', top: '0', background: '#f2f2f2', zIndex: '20'}}>
                    <Input style={{width: '50%', marginRight: '10px'}} value={this.state.copyCourseId} onChange={this.courseIdChange} className="inlineBlock" placeholder="输入目标课时复制到该课程" />
                    <Popconfirm
                        title={this.getTitle()}
                        onConfirm={this.copyCourse}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button type="primary">复制</Button>
                    </Popconfirm>

                    { checkPermission('sys:class:edit') && <Button type="primary" style={{marginLeft: '50px'}} onClick={this.addNewCourse}>添加课时</Button> }

                    { checkPermission('sys:class:gknew') && items.length !== 0 && <Button style={{marginLeft: '10px'}} onClick={this.updateClassList.bind(this)}>更新顺序</Button> }
                </div>}

                <div>
                    <p><span style={{color: 'red'}}>*1.</span>修改<b>第几天</b>功能注意事项：先输入，<b>输入完按下回车</b></p>
                    <p><span style={{color: 'red'}}>*2.</span><b>移动</b>功能注意事项：先输入，<b>输入完按下回车</b>，顺序调好后，<b>点击更新顺序按钮</b></p>
                    <p><span style={{color: 'red'}}>*3.</span>批量复制课时id，先选择课时，再点击复制多个课时id，id之间用<span style={{color: 'red'}}>英文逗号隔开</span></p>
                    <br/>
                    
                    <Table
                        columns={this.generateTableColumns()}
                        rowSelection={{
                            selectedRowKeys: this.state.copyIds,
                            onSelect: this.onSelect,
                            onSelectAll: this.onSelectAll,
                        }}
                        dataSource={items}
                        rowKey={record => record.id}
                        title={() => this.renderHeader()}
                        loading={isFetching}
                        pagination={false}
                    />
                </div>
            </div>

        )
    }
}

function mapStatetoProps(state) {
    return { ...state.courseClassList };
}

export default connect(mapStatetoProps)(CourseClassList);
