import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "INTERVIEW_STUDENT_DETAIL";

export const TO_GET_DATA = PREFIX + "TO_GET_DATA";
export const BEGIN_FETCH = PREFIX + "BEGIN_FETCH";

/** 改变数据类型，加入id */
export const to_get_data = data => {
    if (data.classExerciseList && data.classExerciseList.length) {
        let classExerciseList = data.classExerciseList;
        classExerciseList.map(classExercise => {
            if (classExercise.interviewQuestions && classExercise.interviewQuestions.length) {
                let interviewQuestions = classExercise.interviewQuestions;
                interviewQuestions.map(interviewQuestion => {
                    const { interviewArchivesUser } = interviewQuestion;
                    if (interviewArchivesUser) interviewArchivesUser.exerciseId = classExercise.id;
                })
            }
        })
    }
    return {
        type: TO_GET_DATA,
        data
    }
};

export const beginFetch = () => ({ type: BEGIN_FETCH });

export function get_interview_detail(studentId) {
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(beginFetch());
        apiRequest(`/gk/ops/interviewClass/getStudentLearnInfo/${studentId}`, config)
        .then(json => {
            dispatch(to_get_data(json.obj));
        })
        .catch(error => console.log(error));
    }
};

export function get_global_assessment(obj, cb) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
        apiRequest(`/gk/ops/interviewArchives/comment/saveOrUpdate`, config)
        .then(json => {
            message.success('操作成功');
            cb && cb();
        })
        .catch(error => console.log(error));
    }
};

