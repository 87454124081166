import {
    RECEIVE_UPDATE_QUESTION_RESULT,
    RECEIVE_UPDATE_QUESTION_VIDEO_RESULT,
    REQUEST_UPDATE_QUESTION,
    REQUEST_UPDATE_QUESTION_VIDEO,
    REQUEST_QUESTION_INFO,
    UPDATE_QUESTION_SOURCE,
    UPDATE_QUESTION_DISPLAYTITLE,
    UPDATE_QUESTION_SUITCROWDS,
    UPDATE_QUESTION_MATERIALS,
    ADD_QUESTION_MATERIALS,
    REMOVE_QUESTION_MATERIALS,
    ADD_QUESTION_QUESTION,
    REMOVE_QUESTION_QUESTION,
    UPDATE_QUESTION_METHOD,
    UPDATE_QUESTION_SOLUTION,
    UPDATE_QUESTION_DESCRIPTION,
    UPDATE_QUESTION_YEAR,
    UPDATE_QUESTION_AREA,
    UPDATE_QUESTION_QUESTYPE,
    UPDATE_QUESTION_TAG,
    UPDATE_VIDEO_PROGRESS,
    RECEIVE_QUESTION_INFO,
    UPDATE_QUESTION_JOINMATERIAL
} from './actions';

export const NO_ANSWER = [];

export function essayInfo(state = {
    isFetching: true,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: {},
    materialsInfo: {},
    isFetchingMaterial: false,
    progress: "0"
}, action) {
    switch (action.type) {
        case UPDATE_VIDEO_PROGRESS:
            return Object.assign({}, state, {
                progress: action.value
            })
        case REQUEST_QUESTION_INFO:
            return Object.assign({}, state, {isFetching: true});

        case RECEIVE_QUESTION_INFO:
            if (action.question.quesType == 0) {
                action.question.answer = [action.question.answer];
            } else if (action.question.quesType == 1 && typeof action.question.answer === "string") {
                action.question.answer = action.question.answer.split(',');
            }

            let infoData = action.question;
            if (infoData.questions) {
                let keys = [ 'method', 'question', 'solution' ];
                infoData.questions.map(obj => {
                    keys.map(key => {
                        if (obj[key] == null) {
                            obj[key] = '';
                        }
                    });
                });
            }

            return Object.assign({}, state, {
                qId: (new Date()).getTime()+'',
                mId: (new Date()).getTime()+'',
                isFetching: false,
                info: infoData,
                receivedAt: action.receivedAt
            });
        case REQUEST_UPDATE_QUESTION:
            return Object.assign({}, state, {isUpdating: true});
        case REQUEST_UPDATE_QUESTION_VIDEO:
            return Object.assign({}, state, {isVideoUpdating: true, progress: "0"});
        case RECEIVE_UPDATE_QUESTION_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_QUESTION_VIDEO_RESULT:
            return Object.assign({}, state, {isVideoUpdating: false, isVideoUpdated: true});
        case UPDATE_QUESTION_DISPLAYTITLE:
        case UPDATE_QUESTION_SUITCROWDS:
        case UPDATE_QUESTION_YEAR:
        case UPDATE_QUESTION_AREA:
        case UPDATE_QUESTION_TAG:
        case UPDATE_QUESTION_SOURCE:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case UPDATE_QUESTION_MATERIALS:
            let newMaterials = state.info.materials;
            newMaterials[action.index] = action.value;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {materials: newMaterials})
            });
        case ADD_QUESTION_MATERIALS:
            let newMaterials2 = state.info.materials;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {materials: [...newMaterials2,""]})
            });
        case REMOVE_QUESTION_MATERIALS:
            let m = state.info.materials;
            return Object.assign({}, state, {
                mId: (new Date()).getTime()+'',
                info: Object.assign({}, state.info, {materials: [...m.slice(0,action.index), ...m.slice(action.index+1)]})
            });
        case ADD_QUESTION_QUESTION:
            let newQuestion = state.info.questions;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {questions: [...newQuestion, {method:"",question:"",solution:"", "materials": []}]})
            });
        case REMOVE_QUESTION_QUESTION:
            let q = state.info.questions;
            return Object.assign({}, state, {
                qId: (new Date()).getTime()+'',
                info: Object.assign({}, state.info, {questions: [...q.slice(0,action.index), ...q.slice(action.index+1)]})
            });
        case UPDATE_QUESTION_METHOD:
        case UPDATE_QUESTION_SOLUTION:
        case UPDATE_QUESTION_DESCRIPTION:
        case UPDATE_QUESTION_QUESTYPE:
            let newQuestion2 = state.info.questions;
            newQuestion2[action.index][action.key] = action.value;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {questions: newQuestion2})
            });
        case UPDATE_QUESTION_JOINMATERIAL:
            let newQuestion3 = state.info.questions;
            newQuestion3[action.index].materials = action.value;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {questions: newQuestion3})
            });
        default:
            return state;
    }
}
