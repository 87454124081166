import {
    RECEIVE_QUESTIONS,
    SET_KEY_VALUE,
    RESET_FILTER_STATUS,
    RECEIVE_SHENLUN_AREAS
} from './actions';

const defaultFilter = {
    categoryId: '',                    // 题型分类
    page: 1,                           // 当前页
    pageSize: 10,                      // 每页显示多少条
    province: '',                      // 地区
    query: '',                         // 问题关键字
    questionTag: '',                   // 试题标签
    title: '',                         // 标题
    year: '',                          // 年份
};

export function zhyList(state = {
    items: [],
    areas: [],
    years: [],
    zyLoading: false,
    zyQuestionsObj: {content:[]},
    totalElements: 1,
    ...defaultFilter
}, action) {
    switch (action.type) {
       case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                zyLoading: false,
                items: action.questionList,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
            });
        case RECEIVE_SHENLUN_AREAS:
            return Object.assign({}, state, {areas: action.areas});
        case SET_KEY_VALUE:
            return Object.assign({}, state, {[action.key]: action.value});
        case RESET_FILTER_STATUS:
            return Object.assign({}, state, {...defaultFilter});
        default:
            return state;
    }
}
