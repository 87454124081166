import { message } from 'antd';
import apiRequest from '../../common/request';

export const getSystemMenuList = async (params) => {
	let data = {
		menuName: params.menuName || '',
		status: params.status || '',
		visible: params.visible || ''
	}
	let url = `/gk/ops/system/menu/list`;
	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let obj = await apiRequest(url, config);
	return handleTree(obj.obj) || [];
}

/** 新增或者修改菜单信息 */
export const systemAddOrUpdateMenu = async (params, type) => {
	let data = {
		menuType: params.menuType || '',
		menuName: params.menuName || '',
		parentId: params.parentId,
		orderNum: Number(params.orderNum),
		isFrame: params.isFrame,
		status: params.status,
		isCache: params.isCache,
		remark: params.remark,
		visible: params.visible
	}
	params.icon && (data.icon = params.icon) || '';
	params.path && (data.path = params.path) || '';
	params.component && (data.component = params.component) || '';
	params.id && (data.id = params.id) || '';
	params.perms && (data.perms = params.perms) || '';
	params.remark && (data.remark = params.remark) || '';
	let url = `/gk/ops/system/menu/addMenu`;
	type && (url = `/gk/ops/system/menu/updateMenu`);

	let config = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'accept': 'application/json', 'content-type': 'application/json' },
	};
	let res =  await apiRequest(url, config);
	if(res.status==1){
		message.success('操作成功');
	}
	return res;
}

/** 删除菜单信息 */
export const systemDeleteMenu = async (id) => {
	let url = `/gk/ops/system/menu/delete/${id}`;
	let config = {
		method: 'POST',
	};
	let res =  await apiRequest(url, config);
	if(res.status==1){
		message.success('删除成功');
	}
	return res;
}

/** 刷新获取最新路由信息 */
export const getRouters = async () => {
    let response = await apiRequest(`/gk/ops/user/getRouters`);	
    localStorage.setItem('routers', JSON.stringify(response.obj));
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
	let config = {
		id: id || 'id',
		parentId: parentId || 'parentId',
		childrenList: children || 'children'
	};

	var childrenListMap = {};
	var nodeIds = {};
	var tree = [];

	for (let d of data) {
		let parentId = d[config.parentId];
		if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = [];
		}
		nodeIds[d[config.id]] = d;
		childrenListMap[parentId].push(d);
	}

	for (let d of data) {
		let parentId = d[config.parentId];
		if (nodeIds[parentId] == null) {
			tree.push(d);
		}
	}

	for (let t of tree) {
		adaptToChildrenList(t);
	}

	function adaptToChildrenList(o) {
		if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]];
		}
		if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
				adaptToChildrenList(c);
			}
		}
	}
	return tree;
}