import {
    REMARKS_HANDLER,
} from './action';
    
/** 初始化状态 */
const initialState = {
    remarks: '',
    loading: false,
};

export function positionMenuInfo(previousState=initialState, action) {
    switch(action.type) {
        case REMARKS_HANDLER:
            return { ...previousState, remarks: action.value };
        default:
            return previousState;
    }
}