import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "USER_CONFIG_PAGE";

export const REQUEST_USER_COURSE = PREFIX + "REQUEST_USER_COURSE";
export const RECEIVE_USER_COURSE = PREFIX + "RECEIVE_USER_COURSE";
export const RECEIVE_USERINFO = PREFIX + "RECEIVE_USERINFO";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

function request_user_course() {
    return {
        type: REQUEST_USER_COURSE
    }
}

function receive_user_course(data) {
    return {
        type: RECEIVE_USER_COURSE,
        list: data,
    }
}

function receive_userinfo(data) {
    return {
        type: RECEIVE_USERINFO,
        data: data
    }
}

export function fetchUserCourses(mobile, userId) {
    let url=mobile?`/gk/ops/usercourse/mobile/${mobile}`:`/gk/ops/usercourse/userId/${userId}`
    return dispatch => {
        dispatch(request_user_course());
        apiRequest(url, {credentials: 'include'})
            .then(json => {
                dispatch(receive_user_course(json.obj));
            });
    }
}

export function fetchUserInfo(mobile) {
    return dispatch => {
        dispatch(request_user_course());
        apiRequest('/gk/ops/user/mobile/'+mobile, {credentials: 'include'})
            .then(json => {
                dispatch(receive_userinfo(json.obj));
            });
    }
}

export function addCourse(userId, courseId) {
    return dispatch => {
        apiRequest(`/gk/ops/usercourse/add?userId=${userId}&courseId=${courseId}`, {credentials: 'include', method: 'POST'})
            .then(() => {
                message.success('操作成功');
                dispatch(fetchUserCourses(null, userId))
            });
    }
}

export function deleteCourse(userId, courseId) {
    return dispatch => {
        apiRequest(`/gk/ops/usercourse/delete?userId=${userId}&courseId=${courseId}`, {credentials: 'include', method: 'POST'})
            .then(() => {
                message.success('操作成功');
                dispatch(fetchUserCourses(null, userId))
            });
    }
}