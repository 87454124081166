import apiRequest from '../../common/request';

const PREFIX  = "_INTERVIEW_QUESTION_PAGE_";

export const SUCCESS_GET_INTERVIEW_QUESTION = PREFIX + "SUCCESS_GET_INTERVIEW_QUESTION";
export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const RESET_SEARCH_STATUS = PREFIX + "RESET_SEARCH_STATUS";

export const resetSearchStatus = () => ({ type: RESET_SEARCH_STATUS });

export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

const getInterviewQuestion = data => ({
    type: SUCCESS_GET_INTERVIEW_QUESTION,
    data
});

export const fetchInterviewQuestions = data => {
    if (data.questionTag && data.questionTag == '其他') {
        data.questionTag = 1
    } else {
        data.questionTag = 2
    };
    let url = `/gk/ops/interview/question/page`;
    let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(setKeyValue('isFetching', true));
        apiRequest(url, config).then(json => {
            dispatch(getInterviewQuestion(json.obj));
            dispatch(setKeyValue('isFetching', false));
    })
  }
}