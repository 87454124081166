/**
 * Created by pwq on 2016/12/7.
 */

import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    SET_KEY_VALUE,
    RESET_FILTER_STATUS,
    RECEIVE_SHENLUN_AREAS
} from './actions';

const defaultFilter = {
    categoryId: '',                    // 题型分类
    page: 1,                           // 当前页
    pageSize: 10,                      // 每页显示多少条
    province: '',                      // 地区
    query: '',                         // 问题关键字
    questionTag: '',                   // 试题标签
    title: '',                         // 标题
    year: '',                          // 年份
    areas: [],                         // 地区列表
};

export function essayList(state = {
    isFetching: true,
    items: [],
    totalElements: 1,
    ...defaultFilter
}, action) {
    switch (action.type) {
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.questionList,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
            });
        case RECEIVE_SHENLUN_AREAS:
            return Object.assign({}, state, {areas: action.areas});
        case SET_KEY_VALUE:
            return Object.assign({}, state, {[action.key]: action.value});
        case RESET_FILTER_STATUS:
            return Object.assign({}, state, {...defaultFilter});
        default:
            return state;
    }
}
