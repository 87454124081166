/**
 * Created by pwq on 2017/07/11.
 */

import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    FILL_EXAMINE_TITLE, 
    FILL_EXAMINE_LIMIT_TIME, 
    FILL_EXAMINE_UP_TIME, 
    SELECT_QUESTION, 
    ORDER_QUESTION,
    REQUEST_PUBLISH_EXAMINE, RECEIVE_PUBLISH_EXAMINE_RESULT_SUC, RECEIVE_PUBLISH_EXAMINE_RESULT_FAIL, RESET_DAILY_EXAMINE,
    FILL_EXAMINE_QARR,
    FILL_EXAMINE_QUESTIONS
} from './actions';

const initialState = {
    isFetching: false,
    isSend: false,
    items: [],
    categoryId: '-1',
    totalPages: 1,
    info: {
        title: "",
        quesArr: [],
        selQuesList: []
    }
};

export function dailyExamineRelease(state = initialState, action) {
    switch (action.type) {
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.questionList,
                totalPages: action.totalPages,
                lastUpdated: action.receivedAt
            });
        case FILL_EXAMINE_TITLE:
        case FILL_EXAMINE_LIMIT_TIME:
        case FILL_EXAMINE_UP_TIME:
        case FILL_EXAMINE_QARR:
        case FILL_EXAMINE_QUESTIONS:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case REQUEST_PUBLISH_EXAMINE:
            return Object.assign({}, state, {isFetching: true, isSend: true});
        case RECEIVE_PUBLISH_EXAMINE_RESULT_SUC:
            return Object.assign({}, state, {isFetching: false, isSend: true});
        case RECEIVE_PUBLISH_EXAMINE_RESULT_FAIL:
            return Object.assign({}, state, {isFetching: false, isSend: false});
        case RESET_DAILY_EXAMINE:
            return {
                isFetching: false,
                isSend: false,
                items: [],
                categoryId: '-1',
                totalPages: 1,
                info: {
                    title: "",
                    quesArr: [],
                    selQuesList: []
                }
            };
        default:
            return state;
    }
}
