import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "INTERVIEW_STUDENT_LIST";

export const LOAD_DATA = PREFIX + "LOAD_DATA";
export const GET_QUESTION = PREFIX + "GET_QUESTION";
export const SET_VALUE = PREFIX + "SET_VALUE";

export const setValue = (key, value) => ({ type: SET_VALUE, key, value });

export const load_data = data => ({
    type: LOAD_DATA,
    data
});

export const get_question = data => ({
    type: GET_QUESTION,
    data
})

export function fetchStudentList(obj) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
        return apiRequest(`/gk/ops/interview/user/page`, config)
        .then(json => {
            dispatch(load_data(json.obj))
        })
        .catch(error => console.log(error));
    }
};

export function fetchQuestionDetail(questionId) {
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        apiRequest(`/gk/ops/interview/question/details/${questionId}`, config)
        .then(json => {
            dispatch(get_question(json.obj));
        })
        .catch(error => console.log(error));
    }
};

export function fetchQuestionFeedback(obj, cb) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
        apiRequest(`/gk/ops/interview/interviewFeedback`, config)
        .then(json => {
            message.success('操作成功');
            cb && cb();
        })
        .catch(error => console.log(error));
    }
}