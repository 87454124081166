import {
    VIDEO_PICKER_LAST_PAGE, VIDEO_PICKER_NEXT_PAGE, VIDEO_PICKER_RECEIVE_CONTENT_OF_VIDEO_DIRECTORY,
    VIDEO_PICKER_RECEIVE_VIDEOS,
    VIDEO_PICKER_REQUEST_VIDEOS, VIDEO_PICKER_RESET, VIDEO_PICKER_RESET_VIDEO_MARKERS, VIDEO_PICKER_START_UPLOAD,
    VIDEO_PICKER_UPLOAD_FINISH,
} from "./action";

const initialState = {
    isFetching: true,
    isSending: false,
    marker: null,   // 到达下一页的 marker
    prefixes: [],
    prefix: "",     //当前页面的prefix,默认为""
    lastPrefix: null,    //返回上一层的 prefix
    markers: [],    // 存储用于返回到上一页的marker，第-1是当前页的marker，-2是上一页的marker，以此类推
    items: [],
    uploadedUrl: null,   // 已上传图片返回的url
};


export function videoPicker(state = initialState, action) {
    switch (action.type) {
        case VIDEO_PICKER_REQUEST_VIDEOS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case VIDEO_PICKER_RECEIVE_VIDEOS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.videos,
                marker: action.marker,
                prefixes: action.prefixes
            });
        case VIDEO_PICKER_LAST_PAGE:
            return {
                ...state,
                markers: state.markers.slice(0, -1),
            };
        case VIDEO_PICKER_NEXT_PAGE:
            return {
                ...state,
                markers: [...state.markers, action.marker],
            };
        case VIDEO_PICKER_RECEIVE_CONTENT_OF_VIDEO_DIRECTORY:
            return {...state, prefix: action.prefix};
        case VIDEO_PICKER_RESET_VIDEO_MARKERS:
            return {...state, markers: []};
        case VIDEO_PICKER_UPLOAD_FINISH:
            return {...state, isSending: false,uploadedUrl: action.url};
        case VIDEO_PICKER_START_UPLOAD:
            return {...state, isSending: true};
        case VIDEO_PICKER_RESET:
            return initialState;
        default:
            return state;
    }
}
