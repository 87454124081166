import apiRequest from '../../common/request';

const PREFIX = 'QUERY_DETAIL_ACTION';

export const GET_INQUIRY_DETAILS = PREFIX + 'GET_INQUIRY_DETAILS';
export const DEAL_CURRENT_SEARCH = PREFIX + 'DEAL_CURRENT_SEARCH';
export const EMPTY_CURRENT_STATUS = PREFIX + 'EMPTY_CURRENT_STATUS';
export const CHANGE_DATA_STATUS = PREFIX + 'CHANGE_DATA_STATUS';
export const SET_INQUIRY_LOADING = PREFIX + 'SET_INQUIRY_LOADING';
export const SET_INQUIRY_DETAIL_LOADING = PREFIX + 'SET_INQUIRY_DETAIL_LOADING';
export const ASK_NUM = PREFIX + 'ASK_NUM';

const get_inquiry_details = data => ({ type: GET_INQUIRY_DETAILS, data });

export const emptyCurrentStatus = () => ({ type: EMPTY_CURRENT_STATUS });

export const changeDataStatus = data => ({ type: CHANGE_DATA_STATUS, data });

export const dealCurrentSearch = data => ({ type: DEAL_CURRENT_SEARCH, key: data.key, value: data.value });

const set_inquiry_loading = () => ({ type: SET_INQUIRY_LOADING });

const set_inquiryDetail_loading = () => ({ type: SET_INQUIRY_DETAIL_LOADING });

export const askNum = value => ({ type: ASK_NUM, value });

/** 用户的答疑次数汇总 */
export const getAskNum = (data) => {
    let url = `/gk/ops/ask/askNums`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        let num = json.obj;
        dispatch(askNum(num));
    }
};

export const getCurrentDetails = (data, type) => {
    let url = `/gk/ops/supervise/page`;
    if (type === 'ask') url = `/gk/ops/ask/page`;
    if (type === 'userCare') url = `/gk/ops/userCare/page`;
    if (type === 'returnVisit') url = `/gk/ops/visit/page`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(set_inquiry_loading());
        return apiRequest(url, config).then(json => {
            dispatch(get_inquiry_details(json.obj));
        }).catch(err => {
            dispatch(set_inquiryDetail_loading());
        })
    }
}