import {
    COMMON,
    FETCHING,
    FETCHING_SUCCED,
    RESET_STATE
} from './action';

const initialState = {
    lastDate: 0,            // 最近一次提交时间
    detailArr: [],          // 学员详情数据
    fetching: false,        // 是否处于加载过程中
};

export function exerciseRankStudent(prevState=initialState, action) {
    switch(action.type) {
        case COMMON:
            return { ...prevState, [action.key]: action.value };
        case FETCHING:
            return { ...prevState, fetching: true };
        case FETCHING_SUCCED:
            return { ...prevState, fetching: false, detailArr: action.value };
        case RESET_STATE:
            return { detailArr: [], fetching: false };
        default:
            return prevState;
    }
}