/**
 * Created by myk on 2016/12/18.
 */
import React from "react";
import {Button} from "antd";

class PositionDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        let { detail, positionConfirm } = this.props;
        return (<div className="position-panel">
          <div className="position-title">职位ID: {detail.id}</div>
          <div className="position-title">职位名称: {detail.position}</div>
          <div>
            <span className="position-label">单位信息:</span>
            <div>
              {detail.location && <span>工作地点：{detail.location}</span>}
              <span>省份：{detail.province||'无'}</span>
              {detail.city && <span>地市：{detail.city}</span>}
              {detail.district && <span>区：{detail.district}</span>}
              {detail.type && <span>系统类型：{detail.type}</span>}
              <span>单位名称：{detail.office}</span>
              {detail.bureau && <span>用人司局：{detail.bureau}</span>}
              {detail.deptCode && <span>单位代码：{detail.deptCode}</span>}

            </div>
          </div>
          <div>
            <span className="position-label">职位信息:</span>
            <div>
              <span>职位名称：{detail.position||'无'}</span>
              <span>职位类别：{detail.positionCategory||'无'}</span>
              <span>职位代码：{detail.postCode||'无'}</span>
              <span>职位简介：{detail.synopsis||'无'}</span>
              <span>开考比例：{detail.proportion||'无'}</span>
              <span>招考人数：{detail.number||'无'}</span>
            </div>
          </div>
          <div>
            <span className="position-label">报考条件:</span>
            <div>
              {detail.education && <span>学历：{detail.education}</span>}
              {detail.degree && <span>学位：{detail.degree}</span>}
              <span>专业：{detail.major||'无'}</span>
              {detail.politicalStatus && <span>政治面貌：{detail.politicalStatus}</span>}
              {detail.workYear && <span>工作年限：{detail.workYear}</span>}
              <span>其他：{detail.remarks||'无'}</span>
            </div>
          </div>
          {positionConfirm && <div style={{backgroundColor: 'white', paddingBottom: '15px', display:'flex'}}>
            <Button style={{margin: 'auto'}} disabled={this.props.job&&this.props.job.id == detail.id}  type="primary" onClick={positionConfirm}>确定</Button>
          </div>}
        </div>

        )
    }
}


export default PositionDetail
