import React from "react";
import { connect } from "react-redux";
import {
    updateTvInfo,
    update_title,
    update_url,
    update_type,
    update_url_key,
    update_img_url,
    update_content,
    updateVideo,
    update_recommend,
    update_times,
    fetchTv,
    fetchEmptyTV
} from "./actions";
import { fetchTagList } from "../TvList/actions";
import { checkPermission, validateUtil } from '../../utils/utils';
import { OSS_PUB_GK_NEWS, PUB_TO_ENDPOINT } from '../../common/consts';
import { Input, Button, message, Card, Spin, Select, Checkbox } from 'antd';
import VideoPicker from '../../components/VideoPicker';
import ImagePicker from '../../components/ImagePicker';
import Editor from "../../components/Editor/index";

const { Option } = Select;

class TvInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideoModal: false,
            showModal: false,
            isNew: true
        };
    }

    componentDidMount() {
        this.setState({
            showVideoModal: false,
            showModal: false,
            isNew: true,
            size: null,
        });

        if (this.props.match.params.id) {
            this.props.dispatch(fetchTv(this.props.match.params.id));
            this.setState({ isNew: false })
        } else {
            this.props.dispatch(fetchEmptyTV());
        }
        this.props.dispatch(fetchTagList());
    }

    uploadFile = (type) => {
        let file = type == 0 ? this.refs.upload.files[0] : this.refs.img.files[0];
        if (file) {
            this.props.dispatch(updateVideo(file, type));
        } else {
            message.info('请上传文件')
        }
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    imgPickerFeedBack = (v) => {
        this.setState({ showModal: false });
        this.props.dispatch(update_img_url(v));
    };

    closeVideoModal = () => {
        this.setState({ showVideoModal: false });
    };

    videoPickerFeedBack = (v) => {
        this.setState({ showVideoModal: false });
        let host = `https://${OSS_PUB_GK_NEWS}.${PUB_TO_ENDPOINT[OSS_PUB_GK_NEWS]}/`;
        this.props.dispatch(update_url_key(v));
        this.props.dispatch(update_url(host + v));
    };

    videoPickerSize = value => {
        this.setState({ size: value })
    };

    modifyTvInfo = () => {
        let blackList = ['title', 'tag_id'];
        let tips = { title: '标题', 'tag_id': '分类' };
        if (validateUtil(this.props.info, blackList, tips)) return;
        this.props.dispatch(updateTvInfo(this.props.info, 0));
    };

    render() {
        const { info } = this.props;
        return (
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <h4 style={{ margin: "0 0 30px" }}>{this.state.isNew ? '发布Tv' : 'Tv详情'}</h4>
                    {
                        this.props.isFetching &&
                        <Spin /> ||
                        <div>
                            <label>标题</label>
                            <Input value={info.title} onChange={e => this.props.dispatch(update_title(e.target.value))} className="mb20 mr10" />
                            <label>时长</label>
                            <Input value={info.times} onChange={e => this.props.dispatch(update_times(e.target.value))} className="mb20 mr10" />
                            <label>分类</label>
                            <Select
                                className="mb20"
                                value={this.props.info.tag_id}
                                style={{ width: 100 }}
                                onChange={value => this.props.dispatch(update_type(value))}
                            >
                                <Option value="" key=''>全部</Option>
                                {this.props.tags.map((item, idx) => (
                                    <Option value={item.id} key={idx}>{item.name}</Option>
                                ))}
                            </Select>
                            <br />
                            <label>视频简介</label>
                            <Editor
                                id={this.props.createTime}
                                content={info.content}
                                onChange={value => this.props.dispatch(update_content(value))}
                                imgPath='background/tv'
                            />
                            <br />
                            
                            <label>封面图片</label>
                            {info.imgurl && <img alt="img" style={{ width: "70%", marginBottom: '10px' }} src={info.imgurl} />}
                            {
                                this.props.isVideoUpdating && <div className="progress">
                                    <div className="progress-bar" style={{ width: this.props.progress + "" }}></div>
                                </div>
                            }
                            <br />
                            <div>
                                <input type="file" ref="img" style={{ margin: '0 0 20px 0' }} />
                                <Button type="primary" onClick={() => this.uploadFile(1)} disabled={this.props.isVideoUpdating}>上传</Button>
                            </div>
                            <div>
                                <Button type="primary" onClick={() => this.setState({ showModal: true })}>从图片库上传</Button>
                            </div>
                            <ImagePicker
                                showModal={this.state.showModal}
                                closeModal={this.closeModal}
                                feedBack={this.imgPickerFeedBack}
                                imgPath='background/tv'
                            />

                            <br />
                            <label>视频</label>
                            {info.key && <video style={{ width: "100%" }} controls><source src={info.url} /></video>}
                            <div>
                                <input type="file" ref="upload" style={{ margin: '0 0 20px 0' }} />
                                <Button type="primary" onClick={() => this.uploadFile(0)} disabled={this.props.isVideoUpdating}>上传</Button>
                            </div>
                            <div>
                                <Button type="primary" onClick={() => this.setState({ showVideoModal: true })}>从视频库上传</Button>
                                <Input disabled={true} value={info.key} />
                            </div>
                            <VideoPicker
                                pubName={OSS_PUB_GK_NEWS}
                                videoKey={"key"}
                                showModal={this.state.showVideoModal}
                                closeModal={this.closeVideoModal}
                                feedBack={this.videoPickerFeedBack}
                                getSize={this.videoPickerSize}
                            />
                            <br />
                            {
                                this.state.isNew &&
                                <Checkbox onChange={() => this.props.dispatch(update_recommend(info.recommend))}>推荐</Checkbox>
                            }
                        </div>

                    }
                </div>


                <div className="commonsubmit">
                    {checkPermission('gkvideo:tv:edit') &&
                        <Card className="cardR" size="small" title={this.state.isNew ? '发布' : '更新'}>
                            <Button type="primary" onClick={()=>this.modifyTvInfo()}>{this.state.isNew ? '发布' : '更新'}</Button>
                        </Card>
                    }
                </div>

            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        tags
    } = state.tvList;

    return {
        tags,
        ...state.tvInfo,
    };
}

export default connect(mapStatetoProps)(TvInfo);
