import React from 'react';
import { Popconfirm, Table, Input, Button, Modal, Tag } from 'antd';
import { getSysRoleList, getUnSysRoleList, systemDeleteUser, sysRoleUser } from './action';
import './style.scss'
import { checkPermission, getSearchQuery } from '../../utils/utils';

export default class SysRoleList extends React.Component {

    constructor(props) {
        super(props)
        this.init = {
            id: 0,
            email: '',
            nickName: '',
            mobile: '',
            password: '',
            sex: 1,
            status: 0,
            userType: 0,
            roleIds: '',
        }

        this.state = {
            ...this.init,
            roleId: 0,
            isModalVisible: false,
            mobileSel: '',
            nickNameSel: '',
            statusSel: '',
            userTypeSel: '',
            page: 1,
            pageSize: 10,
            userListObj: {},
            systemUserList: [],
            UnRoleListObj: {},
            unRoleList: [],
            selectedRowKeys: []
        }
    }

    async componentDidMount() {
        this.refrshData();
    }

    /** 获取表格全部数据 */
    refrshData = async () => {
        this.state.roleId = getSearchQuery('roleId');
        let obj = {
            roleId: this.state.roleId
        }
        let userListObj = await getSysRoleList(obj);
        let UnRoleListObj = await getUnSysRoleList(obj)
        let systemUserList = userListObj.content;
        let unRoleList = UnRoleListObj.content;
        this.setState({ userListObj, unRoleList, UnRoleListObj, systemUserList });
    }

    /** 渲染表格头部 */
    tableTitle = () => (
        <div className="tableHeader">
            {checkPermission('sys:user:add') && <Button type="primary" onClick={() => this.handleAdd()}>新增</Button>}
        </div>
    )

    /** 页改变 */
    pageChange = async (p) => {
        await this.setState({ page: p });
        await this.getSearchData();
    }

    /** 模态框页改变 */
    modelPageChange = async (p) => {
        await this.setState({ page: p });
        await this.getModalSearchData();
    }
    
    /** 页大小改变 */
    onShowSizeChange = async (p, s) => {
        await this.setState({ page: 1, pageSize: s });
        await this.getSearchData();
    }

    /** 模态框页大小改变 */
    modelOnShowSizeChange = async (p, s) => {
        await this.setState({ page: 1, pageSize: s });
        await this.getModalSearchData();
    }

    /** 条件搜索列表数据 */
    getSearchData = async () => {
        let { mobileSel, nickNameSel, statusSel, userTypeSel, page, pageSize } = this.state;
        let roleId = getSearchQuery('roleId');
        let data = {
            page,
            pageSize,
            roleId: roleId,
            status: statusSel,
            userType: userTypeSel,
            mobile: mobileSel,
            nickName: nickNameSel
        }
        let userListObj = await getSysRoleList(data);
        let systemUserList = userListObj.content;
        this.setState({ userListObj, systemUserList });
    }

    /** 模态框条件搜索列表数据 */
    getModalSearchData = async () => {
        let { mobileSel, nickNameSel, statusSel, userTypeSel, page, pageSize } = this.state;
        let roleId = getSearchQuery('roleId');
        let data = {
            page,
            pageSize,
            roleId: roleId,
            status: statusSel,
            userType: userTypeSel,
            mobile: mobileSel,
            nickName: nickNameSel
        }
        let UnRoleListObj = await getUnSysRoleList(data)
        let unRoleList = UnRoleListObj.content;
        this.setState({ unRoleList, UnRoleListObj });
    }

    /** 搜索 */
    search = async () => {
        await this.setState({ page: 1 });
        this.getSearchData();
    }

    /** 模态框搜索 */ 
    modalSearch = async () => {
        await this.setState({ page: 1 });
        this.getModalSearchData();
    }

    /** 重置表格 */
    reset = async () => {
        await this.setState({ page: 1, mobileSel: '', nickNameSel: '', statusSel: '', userTypeSel: '' });
        this.refrshData();
    }

    /** 新增列表 */
    handleAdd = (record) => {
        if (record) {
            this.setState({ parentId: record.id })
        }
        this.setState({ isModalVisible: true });
        this.refrshData()
    }

    /** 提交授权 */
    sendRoles = async () => {
        let { selectedRowKeys ,roleId} = this.state;
        let data = {
            roleId:roleId,
            userIds:selectedRowKeys,
        }
        await sysRoleUser(data);
        await this.setState({ isModalVisible: false, ...this.init, selectedRowKeys: []});
        await this.getSearchData();
    }

    /** 取消授权 */
    deleteList = async (record) => {
        await systemDeleteUser(record,this.state.roleId);
        await this.getSearchData();
    }
    
    /** 模态框内的选择框变化 */ 
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
      };

    /** 关闭模态框，重置状态 */
    onCancelModal = () => {
        this.setState({ ...this.init, isModalVisible: false })
    }

    render() {
        let { isModalVisible, mobileSel, nickNameSel, page, pageSize, userListObj, UnRoleListObj, systemUserList, unRoleList, selectedRowKeys } = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            type:'checkbox',
            selectedRowKeys,
            onChange: this.onSelectChange
          };
        const columns = [
            {
                title: '用户id',
                dataIndex: 'id',
                key: 'id',
                width: '20%',
            },
            {
                title: '用户名称',
                width: '20%',
                dataIndex: 'nickName',
                key: 'nickName',
            },
            {
                title: '手机号码',
                width: '20%',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '性别',
                width: '10%',
                render: (record, text) => {
                    return (
                        <span>{record.sex ? <Tag color="#2db7f5">男</Tag> : <Tag color="pink">女</Tag>}</span>
                    )
                }
            },
            {
                title: '状态',
                width: '10%',
                render: (record, text) => {
                    return (
                        <span> {record.status ? <Tag color='#ee433c'>停用</Tag> : <Tag color='#1f90e6'>正常</Tag> } </span>
                    )
                }
            },
            {
                title: '操作',
                width: '20%',
                render: (record, text) => {
                    return (
                        <div>
                            <Popconfirm
                                title="是否确定取消该用户授权吗?"
                                onConfirm={() => this.deleteList(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                                {checkPermission('sys:user:del') && <Button size="small" style={{ marginRight: '5px' }} type="danger">取消授权</Button>}
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ];
        const columns2 = [
            {
                title: '用户id',
                dataIndex: 'id',
                key: 'id',
                width: '20%',
            },
            {
                title: '用户名称',
                width: '20%',
                dataIndex: 'nickName',
                key: 'nickName',
            },
            {
                title: '手机号码',
                width: '20%',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '性别',
                width: '20%',
                render: (record, text) => {
                    return (
                        <span>{record.sex ? <Tag color="#2db7f5">男</Tag> : <Tag color="pink">女</Tag>}</span>
                    )
                }
            },
            {
                title: '状态',
                width: '20%',
                render: (record, text) => {
                    return (
                        <span> {record.status ? <Tag color='#ee433c'>停用</Tag> : <Tag color='#1f90e6'>正常</Tag> } </span>
                    )
                }
            },
        ];
        return (
            <div className="systemList">
                {checkPermission('sys:role:update') && <div className="commonSearchBox">
                    <div><label>手机号</label><Input value={mobileSel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ mobileSel: e.target.value })} placeholder="请输入手机号" style={{ width: 200 }} /></div>
                    <div><label>用户昵称</label><Input value={nickNameSel} onPressEnter={() => this.search()} onChange={(e) => this.setState({ nickNameSel: e.target.value })} placeholder="请输入用户昵称" style={{ width: 200 }} /></div>
                    <div><Button type="primary" onClick={() => this.search()}>搜索</Button></div>
                    <div><Button onClick={() => this.reset()}>重置</Button></div>
                </div>}

                <Table
                    columns={columns}
                    dataSource={systemUserList}
                    rowKey={(record) => record.id}
                    title={() => this.tableTitle()}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: page,
                        pageSize: pageSize,
                        total: userListObj.totalElements,
                        onShowSizeChange: this.onShowSizeChange,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                <Modal title={'选择用户'} width={800} visible={isModalVisible} onOk={() => this.sendRoles()} onCancel={() => this.onCancelModal()}>
                    <div className="systemL">
                        {checkPermission('sys:role:update') && <div className="searchBox ">
                            <div><label>手机号</label><Input value={mobileSel} onPressEnter={() => this.modalSearch()} onChange={(e) => this.setState({ mobileSel: e.target.value })} placeholder="请输入手机号" style={{ width: 200 }} /></div>
                            <div><label>用户昵称</label><Input value={nickNameSel} onPressEnter={() => this.modalSearch()} onChange={(e) => this.setState({ nickNameSel: e.target.value })} placeholder="请输入用户昵称" style={{ width: 200 }} /></div>
                            <div><Button type="primary" onClick={() => this.modalSearch()}>搜索</Button></div>
                            <div><Button onClick={() => this.reset()}>重置</Button></div>
                        </div>}

                        <div style={{ marginBottom: 10 }}>
                            <span>
                                {hasSelected ? `已选择 ${selectedRowKeys.length} 位用户` : ''}
                            </span>
                        </div>

                        <Table
                            columns={columns2}
                            dataSource={unRoleList}
                            rowKey={(record) => record.id}
                            rowSelection={rowSelection}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                current: page,
                                pageSize: pageSize,
                                total: UnRoleListObj.totalElements,
                                onShowSizeChange: this.modelOnShowSizeChange,
                                onChange: this.modelPageChange,
                                showQuickJumper: true,
                                showTotal: total => `共有${total}条数据`,
                            }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}