import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setKeyValue, resetSearchStatus, fetchHistoryList, fetchHistoryInfo } from "./actions";
import { fetchQuestionCategories, fetchGJCategories, fetchZCCategories, fetchZYCategories } from "../../containers/ModuleSetting/actions";
import { fetchQuestions, fetchQuestionAreas, fetchQuestionYears, fetchShenlunAreas } from "../../containers/QuestionList/actions";
import { fetchInstListPage } from "../../containers/InstitutionList/actions";
import { fetchInstitutionYearOrArea } from "../../containers/PaperList/actions";
import { fetchShenlunQuestions } from "../../components/ShenlunSelectCard/actions";
import { fetchZyQuestions, fetchShenlunAreas as  fetchZyShenlunAreas} from "../../containers/ZhyList/actions";
import { fetchTag } from "../../containers/EssayCategory/actions";
import { Modal, Spin, Table, Select, Button, Tooltip, InputNumber, Cascader, message, Input } from "antd";
import Preview from '../../components/Preview';
import './index.scss';

const Option = Select.Option;

/** 这是一个复杂的复用组件 
 * 通过传入不同的exerciseType来区分不同的类型
 * 不同的类型调用不同的接口，渲染不同的数据
 * 请注意区分
 * exerciseType==0  行测
 * exerciseType==1  申论
 * exerciseType==gj 公基
 * exerciseType==zc 职测
 * exerciseType==zy 综应
*/
class QuestionSelectCard extends React.Component {

    previewType = 'selQuesList';

    constructor(props) {
        super(props);
        this.state = {
            showCoverModal: false,
            showVideoModal: false,
            selQuesList: [],
            quesArr: [],
            curQuestionIndex: 0,
            previewShow: false,
            keys: [],
            signedVideoUrl: '',
        }
    }

    componentDidMount() {
        let { exerciseType } = this.props;
        this.props.dispatch(fetchQuestionYears());
        if (exerciseType == 1) {                        //申论
            this.props.dispatch(fetchShenlunAreas());
        } else if (exerciseType == 0) {                 //行测
            this.props.dispatch(fetchQuestionCategories());
            this.props.dispatch(fetchQuestionAreas());
        } else if (exerciseType == 'gj') {              //公基
            this.props.dispatch(fetchGJCategories());
            this.props.dispatch(fetchInstitutionYearOrArea('gj', 'area', 'jobArea'));
        } else if (exerciseType == 'zc') {              //职测
            this.props.dispatch(fetchZCCategories());
            this.props.dispatch(fetchInstitutionYearOrArea('zc', 'area', 'jobArea'));
        }   else if (exerciseType == 'zy') {              //综应
            this.props.dispatch(fetchZYCategories());
            this.props.dispatch(fetchZyShenlunAreas());
        }
        this.props.dispatch(fetchTag());                // 获取tag列表
        this.fetchQuestionHandler();
    }

    /** 组件销毁需要重置状态 */
    componentWillUnmount() {
        this.props.dispatch(resetSearchStatus());
    }

    /** 加载问题的统一方法 */
    fetchQuestionHandler = () => {
        let obj = {};
        obj.page = this.props.page || 1;
        obj.pageSize = this.props.pageSize || 10;
        let exerciseType = this.props.exerciseType;
        if (this.getExerciseType()) {
            this.setState({ keys: ['year', 'area', 'query'] });
        } else {
            this.setState({ keys: ['searchName', 'options', 'questionTag', 'year', 'province', 'historyList', 'qdesc'] });
        }
        let keys = this.state.keys;
        keys.map(key => { this.props[key] && (obj[key] = this.props[key]); });
        obj = this.checkId(obj);
        if (exerciseType == 1) {
            this.props.dispatch(fetchShenlunQuestions(obj))
        } else if (exerciseType == 0) {
            this.props.dispatch(fetchQuestions(obj));
        } else if (exerciseType == 'gj') {
            this.props.dispatch(fetchInstListPage(exerciseType, obj));
        } else if (exerciseType == 'zc') {
            this.props.dispatch(fetchInstListPage(exerciseType, obj));
        }  else if (exerciseType == 'zy') {
            this.props.dispatch(fetchZyQuestions(obj));
        }
    }

    checkId = obj => {
        let id = this.props.id;
        if (Object.prototype.toString.call(id) !== '[object Array]') return obj;
        for (let categoryId of id) {
            categoryId && (obj['id'] = categoryId);
            categoryId && (obj['categoryId'] = [categoryId]);
        }
        obj.searchName && (obj.query = obj.searchName);
        return obj;
    }


    /** 将已选试题id保存到localStorage */
    saveLocal = (nextProps) => {
        let arr = nextProps.quesArr;
        arr = JSON.stringify(arr);
        try { window.localStorage.setItem('questionsIds', arr); }
        catch (err) { }
    }

    handleInput = e => {
        this.props.dispatch(setKeyValue('searchName', e.target.value));
    };

    queryInput = e => {
        this.props.dispatch(setKeyValue('query', e.target.value));
    }

    handleSearch = async () => {
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    resetSearch = async () => {
        await this.props.dispatch(resetSearchStatus());
        this.fetchQuestionHandler();
    }

    handleResort = (fromIndex, e) => {
        let toIndex = parseInt(e.target.value) - 1
        e.target.value = ""
        let selQuesList = this.props.selQuesList;
        if (toIndex > (selQuesList.length - 1)) {
            message.warn('题号不能超过当前题目数量')
            return;
        }
        if (toIndex < 0) {
            message.warn('题号不能小于1')
            return;
        }
        let target = selQuesList.splice(fromIndex, 1)[0]
        selQuesList.splice(toIndex, 0, target);
        let quesArr = selQuesList.map(item => item.id);
        this.props.updateQuesArr([...quesArr]);
        this.props.updateSelQues([...selQuesList]);
        this.setState({ selQuesList: selQuesList, quesArr: quesArr });
    };

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };

    /** tag变化时触发 */
    handleQuestionTagChange = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('questionTag', value));
        this.fetchQuestionHandler();
    };

    selectYear = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('year', value));
        this.fetchQuestionHandler();
    };

    /** 申论,综应 area 行测，公基，职测 province*/
    selectProvince = async value => {
        await this.props.dispatch(setKeyValue(this.getExerciseType() && 'area' || 'province', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    };

    selectArea = async value => {
        await this.props.dispatch(setKeyValue('area', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    }

    selectSource = async value => {
        await this.props.dispatch(setKeyValue('page', 1));
        await this.props.dispatch(setKeyValue('source', value));
        this.fetchQuestionHandler();
    };

    showPreview = (kind) => {
        this.previewType = kind;
        let len = this.props[kind].length;
        if (len == 0) {
            return message.info('您还没有勾选试题');
        }
        this.setState({
            previewShow: true
        })
    };

    //kind判断的当前点击的列表是已选中还是未选中的试题，selQuesList表示选中，questList表示未选中
    currentPreview = (order, kind) => {
        this.previewType = kind;
        this.setState({
            previewShow: true,
            curQuestionIndex: order
        })
    }

    hidePreview = () => {
        this.setState({
            previewShow: false
        })
    };

    preQuestion = () => {
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex > 0) {
            // 从最后一题开始
            this.setState({
                curQuestionIndex: curQuestionIndex - 1
            });
        } else {
            message.warn('已到第一题')
        }
    };

    nextQuestion = () => {
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex < (this.getQuestionList(this.previewType).length - 1)) {
            this.setState({
                curQuestionIndex: curQuestionIndex + 1
            });
        } else {
            message.warn('已到最后第一题')
        }
    };

    handleSelect = async (current, pageSize) => {
        await this.props.dispatch(setKeyValue('page', current));
        await this.props.dispatch(setKeyValue('pageSize', pageSize));
        this.fetchQuestionHandler();
    };

    /** 修改题目分类类型 */
    changeCategoryId = async value => {
        await this.props.dispatch(setKeyValue('id', value));
        await this.props.dispatch(setKeyValue('page', 1));
        this.fetchQuestionHandler();
    }

    /** 处理题目类型数据使其符合antd2~版本 */
    sourceHandler = arr => {
        let that = this;
        if (Object.prototype.toString.call(arr) !== '[object Array]') return [];
        let defaultItem = { id: '', name: '全部', subset: [], };
        arr.unshift(defaultItem);
        arr = arr.map(obj => {
            obj['value'] = obj.id;
            obj['label'] = obj.name;
            if (obj.subset && obj.subset.length) {
                obj.subset = that.sourceHandler(obj.subset);
            }
            obj['children'] = obj.subset;
            return obj;
        });
        return arr;
    }

    /** 申论综应没有预览 */
    getQuestionList = key => {
        let { exerciseType } = this.props;
        if(exerciseType==0) {
            // 行测
            return this.props[key];
        } else {
            // 公基 职测
            return (key == 'selQuesList') ? this.props.selQuesList : this.props.institutionQuestionObj.content;
        }
    }

    /** 把标签数字转换为中文字面意思 */
    questionTag2Cn = number => {
        let result = '无标签';
        if (Object.prototype.toString.call(this.props.tags) !== '[object Array]') return <span>{result}</span>;
        if (this.props.tags.length === 0) return <span>{result}</span>;
        for (let i = 0, len = this.props.tags.length; i < len; i++) {
            let obj = this.props.tags[i];
            if (obj.value == number) {
                result = obj.desc;
                break;
            }
        }
        return <span>{result}</span>;
    }

    updatePoint = (order, value) => {
        let selQuesList = this.props.selQuesList;
        let newArr = [...selQuesList];
        if (order >= 0 && order < selQuesList.length) {
            newArr[order].point = value == "" ? 0 : value;
        }
        this.props.updateSelQues(newArr);
        this.setState({
            selQuesList: newArr
        })
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = (type) => {
        let shenlun = [
            {
                title: '标题',
                width: '30%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.examineName)}>
                        <Link className="ellipsis2" to={this.getDifferentData(record.examineId).path}>{record.examineName}</Link>
                    </Tooltip>
                ),
            },
            {
                title: '问题',
                width: '50%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.question)}>
                        <div className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.question }}></div>
                    </Tooltip>
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
                ellipsis: true
            },
            {
                title: '年份',
                width: '10%',
                dataIndex: 'year',
                key: 'year',
            },

        ];
        let xingce = [
            {
                title: '内容',
                width: '30%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.content)}>
                        <Link to={this.getDifferentData(record.id).path} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></Link>
                    </Tooltip>
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
                render: (text, record) => <Tooltip title={record.area}><div className="ellipsis1" style={{width: '3em'}}>{record.area}</div></Tooltip>
            },
            {
                title: '分类',
                width: '10%',
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: '类型',
                width: '10%',
                dataIndex: 'quesTypeName',
                key: 'quesTypeName'
            },
            {
                title: '标签',
                width: '10%',
                render: (text, record) => this.questionTag2Cn(record.questionTag)
            },
            {
                title: '来源',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.source)}>
                        <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.source }}></span>
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                width: '10%',
                render: (text, record, index) => {
                    return <div className="commonTableBtn">
                        <Button size="small" type="primary" onClick={() => this.currentPreview(index, 'questionList')}>预览</Button>
                        <Button size="small" danger onClick={() => this.playVideo(record)} disabled={!record.signedVideoUrl}>视频</Button>
                    </div>
                }
            }
        ];
        if (type == 1 || type == 'zy') {    // 1是申论
            return [...shenlun];
        } else {    //gj zc 0 都是行测模板
            return [...xingce];
        }
    }

    /** 生成antd table已选列表的配置信息 */
    generateTableColumnsSel = (type) => {
        let shenlun = [
            {
                title: '题号',
                width: '10%',
                render: (text, record, idx) => (
                    idx + 1
                ),
            },
            {
                title: '移动',
                width: '10%',
                render: (text, record, index) => (
                    <InputNumber min={1} max={this.props.quesArr.length} onPressEnter={e => this.handleResort(index, e)} />
                ),
            },
            {
                title: '标题',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.examineName)}>
                        <Link className="ellipsis2" to={this.getDifferentData(record.examineId).path}>{record.examineName}</Link>
                    </Tooltip>
                ),
            },
            {
                title: '问题',
                width: '40%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.question)}>
                        <div className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.question }}></div>
                    </Tooltip>
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
                ellipsis: true
            },
            {
                title: '年份',
                width: '10%',
                dataIndex: 'year',
                key: 'year',
            },

        ];
        let xingce = [
            {
                title: '题号',
                width: '5%',
                render: (text, record, idx) => (
                    idx + 1
                ),
            },
            {
                title: '移动',
                width: '10%',
                render: (text, record, index) => (
                    <InputNumber min={1} max={this.props.quesArr.length} onPressEnter={e => this.handleResort(index, e)} />
                ),
            },
            {
                title: '内容',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.content)}>
                        <Link to={this.getDifferentData(record.id).path} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></Link>
                    </Tooltip>
                ),
            },
            {
                title: '地区',
                width: '10%',
                dataIndex: 'area',
                key: 'area',
                render: (text, record) => <Tooltip title={record.area}><div className="ellipsis1" style={{width: '3em'}}>{record.area}</div></Tooltip>
            },
            {
                title: '分类',
                width: '10%',
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: '类型',
                width: '10%',
                dataIndex: 'quesTypeName',
                key: 'quesTypeName'
            },
            {
                title: '标签',
                width: '5%',
                render: (text, record) => this.questionTag2Cn(record.questionTag)
            },
            {
                title: '来源',
                width: '10%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.source)}>
                        <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.source }}></span>
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                width: '10%',
                render: (text, record, index) => {
                    return <div className="commonTableBtn">
                        <Button size="small" type="primary" onClick={() => this.currentPreview(index, 'selQuesList')}>预览</Button>
                        <Button size="small" danger onClick={() => this.playVideo(record)} disabled={!record.signedVideoUrl}>视频</Button>
                    </div>
                }
            }
        ];
        this.props.updatePoint && xingce.splice(1, 0,
            {
                title: '分值',
                width: '10%',
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            step={0.1}
                            precision={2}
                            id={index}
                            onChange={value => this.updatePoint(index, value)}
                            value={record.point} />
                    )
                }
            }
        )
        if (type == 1 || type == 'zy') {    // 1是申论
            return [...shenlun];
        } else {    //gj zc 0 都是行测模板
            return [...xingce];
        }
    }

    /** 点击播放视频按钮 */
    playVideo = record => {
        this.setState({ showVideoModal: true, signedVideoUrl: record.signedVideoUrl });
    }

    titleTable = () => (
        <div className="commonTableTitle">
            <div>{this.getDifferentData().name}已选列表<span style={{ color: 'red', fontSize: '12px' }}>-(在试题“移动”栏输入题号回车可以将试题移动到相应题号)</span></div>
            { !this.getExerciseType() && <Button onClick={() => this.showPreview('selQuesList')} type="primary">预览</Button>}
        </div>
    )

    /** 已选列表最简单，只能取消不能勾选，所见即所得 */
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.props.updateQuesArr([...selectedRowKeys]);
        this.props.updateSelQues([...selectedRows]);
    };

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let selQuesList = this.props.selQuesList;
        let quesArr = this.props.quesArr;
        if (selected) {
            if (this.props.updatePoint) {    // 行测班级添加练习需要分值
                record.point = 1.0;
            }
            selQuesList.push(record);
            quesArr.push(record.id);
        } else {            
            let idx = quesArr.indexOf(record.id);
            quesArr.splice(idx, 1);
            selQuesList.forEach((item, idx, arr) => {
                if (item.id == record.id) {
                    arr.splice(idx, 1)
                }
            });
        }
        this.props.updateQuesArr([...quesArr]);
        this.props.updateSelQues([...selQuesList]);
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let selQuesList = this.props.selQuesList;
        let quesArr = this.props.quesArr;
        if (selected) {
            changeRows.map(item => {
                if (this.props.updatePoint) { item.point = 1.0; }  // 行测班级添加练习需要分值
                quesArr.push(item.id);
            })
            selQuesList.push(...changeRows);
        } else {
            changeRows.map(item => {
                quesArr = quesArr.filter(v => v != item.id);
                selQuesList = selQuesList.filter(v => v.id != item.id);
            })
        }
        this.props.updateQuesArr([...quesArr]);
        this.props.updateSelQues([...selQuesList]);
    };

    sourceHandler = (arr) => {
        if (!arr.length) return [];
        if (arr[0].name == '全部') return arr;
        let defaultItem = { id: '', name: '全部', subset: [] };
        arr.map((item) => {
            if (item.subset && item.subset.length) {
                this.sourceHandler(item.subset);
            }
        });
        arr.unshift(defaultItem);
        return arr;
    }

    /** 全局动态响应数据结构，用于页面不同类型展示数据, id是试题id */
    getDifferentData = (id) => {
        let { zyQuestionsObj, questionList, questions, institutionQuestionObj,
            zyLoading, loading, isFetching, instloading,
            shenlunTotal, totalElements,
            zyAreas, jobYear, jobArea, years, areas,
            zyCategory, gjCategory, zcCategory, questionCategories,
            exerciseType } = this.props;
        if (exerciseType == 0) {
            return {
                name: '行测',
                years, areas,
                loading: isFetching,
                categories: this.sourceHandler(questionCategories),
                total: totalElements,
                questionList: questionList,
                path: `/question/${id}`
            }
        }
        if (exerciseType == 1) {
            return {
                name: '申论',
                years, areas,
                loading: loading,
                total: shenlunTotal,
                categories: [],
                questionList: questions,
                path: `/essayInfo/${id}`
            }
        } 
        if (exerciseType == 'gj') {
            return {
                name: '公基',
                areas: jobArea,
                years,
                loading: instloading,
                categories: gjCategory,
                total: institutionQuestionObj.totalElements,
                questionList: institutionQuestionObj.content,
                path: `/institutionInfo/gj/${id}`
            }
        } 
        if (exerciseType == 'zc') {
            return {
                name: '职测',
                areas: jobArea,
                years,
                loading: instloading,
                categories: zcCategory,
                total: institutionQuestionObj.totalElements,
                questionList: institutionQuestionObj.content,
                path: `/institutionInfo/zc/${id}`
            }
        } 
        if (exerciseType == 'zy') {
            return {
                name: '综应',
                areas: zyAreas,
                years,
                loading: zyLoading,
                categories: zyCategory,
                total: zyQuestionsObj.totalElements,
                questionList: zyQuestionsObj.content,
                path: `/zhyInfo/${id}`
            }
        } 
    }

    /** 判断是申论，综应返回true，还是行测，公基职测返回false */
    getExerciseType = () => {
        let {exerciseType} = this.props;
        if(exerciseType==1 || exerciseType == 'zy') { // 申论，综应
            return true;
        } else {    // 行测，公基职测
            return false;
        }
    }

    /** 清空已选 */
    resetSel = () => {
        this.props.updateQuesArr([]);
        this.props.updateSelQues([]);
    }

    render() {
        let {selLoading, exerciseType, quesArr, selQuesList, area, province} = this.props;
        
        return (
            <Spin tip="生成试题中, 请稍等..." spinning={selLoading}>
                <Table
                    rowSelection={{
                        selectedRowKeys: quesArr,
                        onChange: this.onSelectChange,
                    }}
                    columns={this.generateTableColumnsSel(exerciseType)}
                    dataSource={selQuesList}
                    rowKey={record => record.id}
                    title={() => this.titleTable()}
                    pagination={false}
                />

                <div className="commonSearchBox" id="inputQuestionAnchor">

                    <div className="itemSearchBox">
                        <label>年份</label>
                        <Select
                            mode="multiple"
                            style={{ width: 400 }}
                            value={this.props.year}
                            dropdownMatchSelectWidth={false}
                            onChange={this.selectYear}>
                            {this.getDifferentData().years && this.getDifferentData().years.map((year, idx) => {
                                return <Option key={idx} value={year + ""}>{year}</Option>
                            })}
                        </Select>
                    </div>

                    <div className="itemSearchBox">
                        <label>省份</label>
                        <Select
                            value={this.getExerciseType() && area || province}
                            className="commonW150"
                            dropdownMatchSelectWidth={false}
                            onChange={this.selectProvince}>
                            <Option value="">全部</Option>
                            {this.getDifferentData().areas && this.getDifferentData().areas.map((province, idx) => {
                                return <Option key={idx} value={province}>{province}</Option>
                            })}
                        </Select>
                    </div>
                    {!this.getExerciseType() &&
                        <div className="itemSearchBox">
                            <label>标签</label>
                            <Select
                                value={this.props.questionTag}
                                className="commonW150"
                                dropdownMatchSelectWidth={false}
                                onChange={this.handleQuestionTagChange}>
                                <Option value="">全部</Option>
                                {this.props.tags && this.props.tags.map((tag, idx) => {
                                    return <Option key={idx} value={tag.value + ""}>{tag.desc}</Option>
                                })}
                            </Select>
                        </div>
                    }

                    {!this.getExerciseType() &&
                        <div className="itemSearchBox">
                            <label>试题分类</label>
                            <Cascader
                                fieldNames={{ label: 'name', value: 'id', children: 'subset' }}
                                value={this.props.id}
                                changeOnSelect
                                showSearch
                                options={this.getDifferentData().categories}
                                className="commonW400"
                                onChange={this.changeCategoryId}
                            />
                        </div>
                    }

                    <div>
                        <label>试题搜索</label>
                        <Input
                            className="commonW400"
                            placeholder="输入试题的关键词查询"
                            value={this.getExerciseType() ? this.props.query : this.props.searchName}
                            onChange={this.getExerciseType() ? this.queryInput : this.handleInput}
                            onPressEnter={() => this.handleSearch()}
                        />
                    </div>

                    {!this.getExerciseType() &&
                        <div>
                            <label>选项搜索</label>
                            <Input
                                className="commonW400"
                                placeholder="输入选项的关键词查询"
                                value={this.props.options}
                                onChange={e => this.props.dispatch(setKeyValue('options', e.target.value))}
                                onPressEnter={() => this.handleSearch()}
                            />
                        </div>
                    }

                    {!this.getExerciseType() && <div>
                        <label>选择套卷</label>
                        <Select
                            className="commonW400"
                            placeholder="输入试卷名称后进行选择"
                            showSearch
                            onSearch={value=>this.props.dispatch(fetchHistoryList(value, exerciseType))}
                            onChange={value=>this.props.dispatch(fetchHistoryInfo(value, exerciseType, this.onSelectAll))}
                            optionFilterProp="children"
                        >
                            {this.props.historyList.map((tag, idx) => <Option key={idx} value={tag.examineId}>{tag.title}</Option>)}
                        </Select>
                    </div>}
                    <div>
                        <Button disabled={this.getDifferentData().loading} type="primary" onClick={this.handleSearch} className="mr15">搜索</Button>
                        <Button disabled={this.getDifferentData().loading} type="danger" onClick={this.resetSearch} className="mr15">重置搜索条件</Button>
                        <Button disabled={this.getDifferentData().loading} type="danger" onClick={this.resetSel} className="mr15">清空已选试题</Button>
                        <span><span style={{color: 'red'}}>说明*: 选择套卷</span>是从对应类型试卷列表里面直接选择一套试题作为已选试题</span>
                    </div>
                </div>

                <Table
                    rowSelection={{
                        selectedRowKeys: quesArr,
                        onSelect: this.onSelect,
                        onSelectAll: this.onSelectAll,
                    }}
                    loading={this.getDifferentData().loading}
                    columns={this.generateTableColumns(exerciseType)}
                    dataSource={this.getDifferentData().questionList}
                    rowKey={record => record.id}
                    title={() => this.getDifferentData().name+'试题列表'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.getDifferentData().total,
                        onChange: this.handleSelect,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
                <div id="bottomAnchor"></div>

                <Modal
                    title="播放解析视频"
                    visible={this.state.showVideoModal}
                    footer={null}
                    width="700px"
                    closable={false}
                    onCancel={()=>this.setState({ showVideoModal: false, signedVideoUrl: '' })}
                >
                    <video width="650px" className="videoClass" src={this.state.signedVideoUrl} controls="controls" />
                </Modal>

                {
                    this.state.previewShow ?
                        <Preview
                            previewShow={this.state.previewShow}
                            fromType={this.props.exerciseType}
                            hidePreviewCb={this.hidePreview}
                            question={this.getQuestionList(this.previewType)[this.state.curQuestionIndex]}
                            pre={this.preQuestion}
                            next={this.nextQuestion}
                            number={this.state.curQuestionIndex}
                        />
                        : null
                }

            </Spin>
        )
    }

}

function mapStatetoProps(state) {
    const {
        gjCategory, zcCategory, zyCategory,
        items: questionCategories
    } = state.questionCategories;

    const {
        isFetching,
        items: questionList,
        totalElements,
        numberOfElements,
        areas,
        years,
    } = state.questions;

    const {
        tags
    } = state.essayCategory;

    const {
        loading,
        questions,
        totalElements: shenlunTotal,
        query
    } = state.shenlunQuestions;

    const {
        institutionQuestionObj, loading: instloading
    } = state.institutionList;

    let { jobYear, jobArea } = state.paperList;

    let { zyLoading, zyQuestionsObj, areas: zyAreas } = state.zhyList;


    return {
        zyQuestionsObj,
        zyAreas, zyLoading,
        zyCategory,
        gjCategory, zcCategory,
        jobYear, jobArea,
        institutionQuestionObj,
        instloading,
        loading,
        isFetching,
        questionList,
        totalElements,
        shenlunTotal,
        query,
        numberOfElements,
        questionCategories,
        tags,
        areas,
        years,
        questions,
        ...state.questionSelectCard
    };
}

export default connect(mapStatetoProps)(QuestionSelectCard);
