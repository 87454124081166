import React, { Component } from 'react'
import { Editor as Tinymce } from '@tinymce/tinymce-react';
import 'tinymce/skins/ui/oxide/skin.min.css';
import ImagePicker from '../ImagePicker';
import { notification } from 'antd';

export default class Editor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            imgSelected: false,
            url: ""
        }
    }

    setupEditor = (editor) => {
        // 注册一个工具栏按钮名称
        let that = this;
        editor.ui.registry.addButton('example', {
            icon: "image",
            tooltip: "Insert/edit image",
            onAction: function () {
                that.setState({ showModal: true, url: "", imgSelected: false });
                let timer = setInterval(function () {
                    if (!that.state.showModal) {
                        if (that.state.imgSelected) {
                            editor.insertContent(`<p><img src=${that.state.url} alt="image" width="400"/></p>`);
                        }
                        clearInterval(timer);
                    }
                }, 500);
            }
        });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    imgPickerFeedBack = (value) => {
        this.setState({ showModal: false, url: value, imgSelected: true });
    };

    handleEditorChange = (content) => {
        // 复制粘贴的图片必带data:image/png;base64
        if (content.includes('data:image/png;base64')) {
            notification.error({
                duration: 10,
                message: '禁止操作',
                description: <div>禁止粘贴图片<div>请手动上传图片,或者去图片库选择图片</div>请删除粘贴的图片</div>,
            });
        } else {
            this.props.onChange(this.strCode(content));
        }
    };

    /** 基本汉字、部首扩展、康熙部首对照字典 */
    strCode = (str) => {
        // 第一个是康熙部首，第二个是日常用的基本汉字
        str = str.replace(/⼀/g, '一');
        str = str.replace(/⼄/g, '乙');
        str = str.replace(/⼆/g, '二');
        str = str.replace(/⼈/g, '人');
        str = str.replace(/⼉/g, '儿');
        str = str.replace(/⼊/g, '入');
        str = str.replace(/⼋/g, '八');
        str = str.replace(/⼏/g, '几');
        str = str.replace(/⼑/g, '刀');
        str = str.replace(/⼒/g, '力');
        str = str.replace(/⼔/g, '匕');
        str = str.replace(/⼗/g, '十');
        str = str.replace(/⼘/g, '卜');
        str = str.replace(/⼚/g, '厂');
        str = str.replace(/⼜/g, '又');
        str = str.replace(/⼝/g, '口');
        str = str.replace(/⼞/g, '口');
        str = str.replace(/⼟/g, '土');
        str = str.replace(/⼠/g, '士');
        str = str.replace(/⼤/g, '大');
        str = str.replace(/⼥/g, '女');
        str = str.replace(/⼦/g, '子');
        str = str.replace(/⼨/g, '寸');
        str = str.replace(/⼩/g, '小');
        str = str.replace(/⼫/g, '尸');
        str = str.replace(/⼭/g, '山');
        str = str.replace(/⼯/g, '工');
        str = str.replace(/⼰/g, '己');
        str = str.replace(/⼲/g, '干');
        str = str.replace(/⼴/g, '广');
        str = str.replace(/⼸/g, '弓');
        str = str.replace(/⼼/g, '心');
        str = str.replace(/⼽/g, '戈');
        str = str.replace(/⼿/g, '手');
        str = str.replace(/⽀/g, '支');
        str = str.replace(/⽂/g, '文');
        str = str.replace(/⽃/g, '斗');
        str = str.replace(/⽄/g, '斤');
        str = str.replace(/⽅/g, '方');
        str = str.replace(/⽆/g, '无');
        str = str.replace(/⽇/g, '日');
        str = str.replace(/⽈/g, '曰');
        str = str.replace(/⽉/g, '月');
        str = str.replace(/⽊/g, '木');
        str = str.replace(/⽋/g, '欠');
        str = str.replace(/⽌/g, '止');
        str = str.replace(/⽍/g, '歹');
        str = str.replace(/⽏/g, '毋');
        str = str.replace(/⽐/g, '比');
        str = str.replace(/⽑/g, '毛');
        str = str.replace(/⽒/g, '氏');
        str = str.replace(/⽓/g, '气');
        str = str.replace(/⽔/g, '水');
        str = str.replace(/⽕/g, '火');
        str = str.replace(/⽖/g, '爪');
        str = str.replace(/⽗/g, '父');
        str = str.replace(/⽚/g, '片');
        str = str.replace(/⽛/g, '牙');
        str = str.replace(/⽜/g, '牛');
        str = str.replace(/⽝/g, '犬');
        str = str.replace(/⽞/g, '玄');
        str = str.replace(/⽟/g, '玉');
        str = str.replace(/⽠/g, '瓜');
        str = str.replace(/⽡/g, '瓦');
        str = str.replace(/⽢/g, '甘');
        str = str.replace(/⽣/g, '生');
        str = str.replace(/⽤/g, '用');
        str = str.replace(/⽥/g, '田');
        str = str.replace(/⽩/g, '白');
        str = str.replace(/⽪/g, '皮');
        str = str.replace(/⽫/g, '皿');
        str = str.replace(/⽬/g, '目');
        str = str.replace(/⽭/g, '矛');
        str = str.replace(/⽮/g, '矢');
        str = str.replace(/⽯/g, '石');
        str = str.replace(/⽰/g, '示');
        str = str.replace(/⽲/g, '禾');
        str = str.replace(/⽳/g, '穴');
        str = str.replace(/⽴/g, '立');
        str = str.replace(/⽵/g, '竹');
        str = str.replace(/⽶/g, '米');
        str = str.replace(/⽸/g, '缶');
        str = str.replace(/⽹/g, '网');
        str = str.replace(/⽺/g, '羊');
        str = str.replace(/⽻/g, '羽');
        str = str.replace(/⽼/g, '老');
        str = str.replace(/⽽/g, '而');
        str = str.replace(/⽿/g, '耳');
        str = str.replace(/⾁/g, '肉');
        str = str.replace(/⾂/g, '臣');
        str = str.replace(/⾃/g, '自');
        str = str.replace(/⾄/g, '至');
        str = str.replace(/⾆/g, '舌');
        str = str.replace(/⾈/g, '舟');
        str = str.replace(/⾉/g, '艮');
        str = str.replace(/⾊/g, '色');
        str = str.replace(/⾍/g, '虫');
        str = str.replace(/⾎/g, '血');
        str = str.replace(/⾏/g, '行');
        str = str.replace(/⾐/g, '衣');
        str = str.replace(/⾒/g, '儿');
        str = str.replace(/⾓/g, '角');
        str = str.replace(/⾔/g, '言');
        str = str.replace(/⾕/g, '谷');
        str = str.replace(/⾖/g, '豆');
        str = str.replace(/⾚/g, '赤');
        str = str.replace(/⾛/g, '走');
        str = str.replace(/⾜/g, '足');
        str = str.replace(/⾝/g, '身');
        str = str.replace(/⾞/g, '車');
        str = str.replace(/⾟/g, '辛');
        str = str.replace(/⾠/g, '辰');
        str = str.replace(/⾢/g, '邑');
        str = str.replace(/⾣/g, '酉');
        str = str.replace(/⾤/g, '采');
        str = str.replace(/⾥/g, '里');
        str = str.replace(/⾦/g, '金');
        str = str.replace(/⾧/g, '長');
        str = str.replace(/⾨/g, '門');
        str = str.replace(/⾩/g, '阜');
        str = str.replace(/⾪/g, '隶');
        str = str.replace(/⾬/g, '雨');
        str = str.replace(/⾭/g, '青');
        str = str.replace(/⾮/g, '非');
        str = str.replace(/⾯/g, '面');
        str = str.replace(/⾰/g, '革');
        str = str.replace(/⾲/g, '韭');
        str = str.replace(/⾳/g, '音');
        str = str.replace(/⾴/g, '頁');
        str = str.replace(/⾵/g, '風');
        str = str.replace(/⾶/g, '飛');
        str = str.replace(/⾷/g, '食');
        str = str.replace(/⾸/g, '首');
        str = str.replace(/⾹/g, '香');
        str = str.replace(/⾺/g, '馬');
        str = str.replace(/⾻/g, '骨');
        str = str.replace(/⾼/g, '高');
        str = str.replace(/⿁/g, '鬼');
        str = str.replace(/⿂/g, '魚');
        str = str.replace(/⿃/g, '鳥');
        str = str.replace(/⿄/g, '鹵');
        str = str.replace(/⿅/g, '鹿');
        str = str.replace(/⿇/g, '麻');
        str = str.replace(/⿉/g, '黍');
        str = str.replace(/⿊/g, '黑');
        str = str.replace(/⿍/g, '鼎');
        str = str.replace(/⿎/g, '鼓');
        str = str.replace(/⿏/g, '鼠');
        str = str.replace(/⿐/g, '鼻');
        str = str.replace(/⿒/g, '齒');
        str = str.replace(/⿓/g, '龍');
        str = str.replace(/⼣/g, '夕');
        // 第一个是扩展部首，第二个是基本汉字
        str = str.replace(/⺁/g, '厂');
        str = str.replace(/⺇/g, '几');
        str = str.replace(/⺌/g, '小');
        str = str.replace(/⺎/g, '兀');
        str = str.replace(/⺏/g, '尣');
        str = str.replace(/⺐/g, '尢');
        str = str.replace(/⺑/g, '𡯂');
        str = str.replace(/⺒/g, '巳');
        str = str.replace(/⺓/g, '幺');
        str = str.replace(/⺛/g, '旡');
        str = str.replace(/⺝/g, '月');
        str = str.replace(/⺟/g, '母');
        str = str.replace(/⺠/g, '民');
        str = str.replace(/⺱/g, '冈');
        str = str.replace(/⺸/g, '芈');
        str = str.replace(/⻁/g, '虎');
        str = str.replace(/⻄/g, '西');
        str = str.replace(/⻅/g, '见');
        str = str.replace(/⻆/g, '角');
        str = str.replace(/⻇/g, '𧢲');
        str = str.replace(/⻉/g, '贝');
        str = str.replace(/⻋/g, '车');
        str = str.replace(/⻒/g, '镸');
        str = str.replace(/⻓/g, '长');
        str = str.replace(/⻔/g, '门');
        str = str.replace(/⻗/g, '雨');
        str = str.replace(/⻘/g, '青');
        str = str.replace(/⻙/g, '韦');
        str = str.replace(/⻚/g, '页');
        str = str.replace(/⻛/g, '风');
        str = str.replace(/⻜/g, '飞');
        str = str.replace(/⻝/g, '食');
        str = str.replace(/⻡/g, '𩠐');
        str = str.replace(/⻢/g, '马');
        str = str.replace(/⻣/g, '骨');
        str = str.replace(/⻤/g, '鬼');
        str = str.replace(/⻥/g, '鱼');
        str = str.replace(/⻦/g, '鸟');
        str = str.replace(/⻧/g, '卤');
        str = str.replace(/⻨/g, '麦');
        str = str.replace(/⻩/g, '黄');
        str = str.replace(/⻬/g, '齐');
        str = str.replace(/⻮/g, '齿');
        str = str.replace(/⻯/g, '竜');
        str = str.replace(/⻰/g, '龙');
        str = str.replace(/⻳/g, '龟');
        str = str.replace(/⾅/g, '臼');
        str = str.replace(/⼝/g, '口');
        str = str.replace(/⼾/g, '户');
        str = str.replace(/⼉/g, '儿');
        str = str.replace(/⼱/g, '巾');
        return str;
    }

    render() {
        return (
            <div>
                <Tinymce
                    init={{
                        language: 'zh_CN',
                        plugins: [
                            'advlist autolink lists link image charmap hr anchor',
                            'searchreplace visualblocks visualchars code fullscreen autoresize',
                            'insertdatetime save table directionality imagetools',
                            'emoticons paste textpattern preview',
                        ],
                        toolbar: ['insertfile undo redo | bold italic | forecolor backcolor | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist | link example fullscreen | mathquill preview'],
                        file_picker_types: 'file image media',
                        setup: this.setupEditor
                    }}
                    selector='editorRef'
                    inline={false}
                    // apiKey='twxwhsf5pg8eel5yba1jjktvuo8trxpa6fqjw6elel78dfuo'
                    value={this.props.content || ''}
                    onEditorChange={this.handleEditorChange}
                />
                {/* ImagePicker组件bucket说明：imgPath非试题组件才会有
                */}
                {this.state.showModal ? <ImagePicker
                    showModal={this.state.showModal}
                    closeModal={this.closeModal}
                    feedBack={this.imgPickerFeedBack}
                    bucket={'jd-questions'}
                    imgPath={this.props.imgPath}
                /> : null}
            </div>
        )
    }
}
