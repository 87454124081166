import {message} from 'antd';
import apiRequest from '../../common/request'

export const REQUEST_NEW_RESOURCE = "REQUEST_NEW_RESOURCE";
export const RECEIVE_RESOURCE = "RECEIVE_RESOURCE";
export const RECEIVE_TAG = "RECEIVE_TAG";
const shenlunTagId = 509;

function request_new_resource() {
    return {
        type: REQUEST_NEW_RESOURCE
    }
}
function receive_resource(resource) {
    return {
        type: RECEIVE_RESOURCE,
        resource
    }
}

function receive_tag(tags) {
    return {
        type: RECEIVE_TAG,
        tags
    }
}

/**
 * 获取题型分类
 */
export function fetchResource(){
    return dispatch => {
      apiRequest('/gk/ops/resource/findByType/shenlun', {credentials: 'include'})
        .then(json => {
            dispatch(receive_resource(json.obj));
        })
        .catch(error => console.log(error));
    }
}

/**
 * 删除题型分类
 */
export function delResource(id){
    return dispatch => {
      apiRequest('/gk/ops/resource/delete/' + id, {credentials: 'include'})
        .then(json => {
            message.success('删除成功');
            dispatch(fetchResource())
        })
        .catch(error => console.log(error));
    }
}

/**
 * 新建题型分类
 */
export function newResource(res){
    let data = {
        "name": res,
        "parentId": 0,
        "type": "shenlun"
    };
    return dispatch => {
        dispatch(request_new_resource());
        apiRequest('/gk/ops/resource/saveOrUpdate',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            })
          .then(json => {
              message.success('操作成功');
              dispatch(fetchResource());
          })
          .catch(error => console.log(error));
    }
}

/**
 * 获取Tag
 */
export function fetchTag(){
    return dispatch => {
      apiRequest('/gk/ops/question/tag/list', {credentials: 'include'})
        .then(json => {
              dispatch(receive_tag(json.obj));
        })
        .catch(error => console.log(error));
    }
}

/**
 * 删除Tag
 */
export function delTag(id){
    return dispatch => {
      apiRequest('/gk/ops/tag/delete/' + id, {credentials: 'include'})
        .then(json => {
            message.success('删除成功');
          dispatch(fetchTag())
        })
        .catch(error => console.log(error));
    }
}

/**
 * 新建Tag
 */
export function newTag(tag){
    let data = {
        "name": tag,
        "parentId": shenlunTagId,
    };
    return dispatch => {
      apiRequest('/gk/ops/tag/saveOrUpdate',
          {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
              },
              credentials: 'include'
          })
        .then(json => {
            message.success('创建成功');
            dispatch(fetchTag());
        })
        .catch(error => console.log(error));
        
    }
}
