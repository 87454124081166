import React from 'react';
import { connect } from 'react-redux';
import { checkPermission } from '../../utils/utils';
import { DatePicker, Select, Input, Button, Table } from 'antd';
import { wecharInformation, setReduxKeyValue, resetFilterValue, downloadExcel, setReduxInputWeChat } from './action';
import moment from 'moment';
import './index.scss';
const Option = Select.Option;

const materialVersion = [
    { label: '全部', value: null },
    { label: '公务员考试上岸大礼包', value: 1 }
];

const getPlatform = [
    { label: '全部', value: null },
    { label: '安卓', value: 1 },
    { label: 'IOS', value: 2 },
    { label: 'PC官网', value: 3 },
    { label: '小程序', value: 4 },
    { label: '百度小程序', value: 5 },
    { label: '抖音小程序', value: 6 },
    { label: '职位查询小程序', value: 7 },
    { label: '常识小程序', value: 8 },
    { label: '估分小程序', value: 9 },
    { label: '广告落地页H5', value: 12 },
    { label: '移动官网', value: 13 },
    { label: '提分宝典小程序', value: 14 },
    { label: '事业单位小程序', value: 15 },
];

const getRepeat = [
    { label: '全部', value: null },
    { label: '是', value: 1 },
    { label: '否', value: 0 },
]

const getPlace = [
    {label: '全部', value: null},
    {label: '首页按钮', value: 1},
    {label: '首页H5', value: 2},
    {label: '行测真题按钮', value: 3},
    {label: '行测真题H5', value: 4},
    {label: '职位按钮', value: 5},
    {label: '职位H5', value: 6},
    {label: '知乎平台', value: 7},
    {label: '喜马拉雅', value: 8},
]

class DataOrder extends React.Component {
    componentWillMount() {
        this.permission = 2|| 1 ;
    };

    componentDidMount() {
        this.getWeChatInformation();
    };

    getWeChatInformation = () => {
        let params = {
           page: this.props.page,
           pageSize: this.props.pageSize, 
        }
        if (this.props.place) params.place = this.props.place;
        if (this.props.startTime) params.startTime = this.props.startTime;
        if (this.props.endTime) params.endTime = this.props.endTime;
        if (this.props.version) params.version = this.props.version;
        if (this.props.wechats) params.wechats = this.props.wechats;
        if (this.props.clientType) params.clientType = this.props.clientType;
        if (this.props.isRepeat != null) params.isRepeat = this.props.isRepeat;
        this.props.dispatch(wecharInformation(params))
        return params;
    };

    downExcel = () => {
        let obj = this.getWeChatInformation();
        this.props.dispatch(downloadExcel(obj));
    }

    conditionsChinese = {
        startTime: '起始时间', endTime: '截止时间', version: '资料名称', wechats: '微信号', clientType: '领取平台', isRepeat: '是否重复',
    };

    selectConditions = [ 'version',];

    clientSelect = ['clientType'];

    repeatSelect = ['isRepeat']

    dateConditions = ['startTime', 'endTime'];

    inputConditions = ['wechats'];

    /** 把用户输入的值反馈到redux里面 */
    value2Redux = async data => {
        await this.props.dispatch(setReduxKeyValue(data));
        await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
        this.getWeChatInformation();
    };

    /** 渲染订单筛选条件日期选择形式的相关UI结构 */
    renderCalendarUI = () => {
        return this.dateConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <DatePicker
                    className="w260"
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                    value={this.calendarValuePipe(this.props[key])}
                    onChange={(date, dateString) => {this.calendarValueChangeHandler(dateString, key);}}
                    onOk={this.calendarClickOkHandler}
                />
            </div>
        ));
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''

    /** 设置日期输入的值 */
    calendarValueChangeHandler = async (dateString, key) => {
        await this.props.dispatch(setReduxKeyValue({ key, value: dateString }));
    };

    /** 监听日历组件点击确定按钮 */
    calendarClickOkHandler = async () => {
        await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
        this.getWeChatInformation();
    };

    /** 监听点击查询订单按钮 */
    searchHandler = async () => {
        await this.props.dispatch(setReduxKeyValue({ key: 'page', value: 1 }));
        this.getWeChatInformation();
    };

    /** 监听点击清空查询条件按钮 */
    resetBtnClickHandler = async () => {
        await this.props.dispatch(resetFilterValue());
        this.getWeChatInformation();
    };

    /** 分页组件改变页码 */
    paginationChange = async page => {
        await this.props.dispatch(setReduxKeyValue({key: 'page', value: page}));
        this.getWeChatInformation();
    }

    /** 分页组件改变每页条数 */
    showSizeChange = async (current, size) => {
        await this.props.dispatch(setReduxKeyValue({key: 'pageSize', value: size}));
        await this.props.dispatch(setReduxKeyValue({key: 'page', value: 1}));
        this.getWeChatInformation();
    }

    /** 渲染select类型相关的筛选数据 */
    renderSelectStatus = () => {
        return this.selectConditions.map((key, idx) => (
        <div key={idx}>
            <label>{this.conditionsChinese[key]}</label>
            <Select className="commonW150" value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
            {
                materialVersion.map((obj,idx) => (
                <Option key={idx} value={obj.value}>{obj.label}</Option>
                ))
            }
            </Select>
        </div>
        ));
    };

    /** 渲染领取平台选择 */
    renderSelectPlatform = () => {
        return this.clientSelect.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <Select className="commonW150" value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
                {
                    getPlatform.map((obj,idx) => (
                    <Option key={idx} value={obj.value}>{obj.label}</Option>
                    ))
                }
                </Select>
            </div>
        ))
    };

    /** 渲染重复筛选 */
    renderRepeatSelect = () => {
        return this.repeatSelect.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <Select className="commonW150" value={this.props[key]} onChange={value => this.value2Redux({ key, value })}>
                {
                    getRepeat.map((obj,idx) => (
                    <Option key={idx} value={obj.value}>{obj.label}</Option>
                    ))
                }
                </Select>
            </div>
        ))
    }

    /** 渲染input类型相关的筛选数据 */
    renderInputsStatus = () => {
        return this.inputConditions.map((key, idx) => (
        <div key={idx}>
            <label>{this.conditionsChinese[key]}</label>
            <Input placeholder="微信号之间请用逗号隔开" onPressEnter={() => this.searchHandler()} value={this.props[key]} style={{ width: '400px' }} onChange={event => this.props.dispatch(setReduxInputWeChat({ key, value: event.target.value }))} />
        </div>
        ));
    };

    /** antd table 的数据格式约定配置 */
    generateColumns() {
        const columns = [
            {
                title: '资料名称',
                dataIndex: 'dataName',
                width: '20%',
            },
            {
                title: '版本',
                dataIndex: 'version',
                width: '5%'
            },
            {
                title: '重复',
                width: '10%',
                render: record => (
                    <span>{record.isRepeat ? '是' : '否'}</span>
                )
            },
            {
                title: '微信号',
                dataIndex: 'wechat',
                width: '20%',
            },
            {
                title: '领取平台',
                width: '15%',
                render: record => (
                    getPlatform && getPlatform.map((item,idx) => {
                        return(
                            <span key={idx}>{item.value == record.clientType ? item.label : ''}</span>
                        )
                    }) 
                )
            },
            {
                title: '领取位置',
                width: '10%',
                render: record => (
                    getPlace && getPlace.map((item,idx) => {
                        return(
                            <span key={idx}>{item.value == record.place ? item.label : ''}</span>
                        )
                    })  
                )
            },
            {
                title: '领取时间',
                dataIndex: 'gmtCreate',
                width: '20%',
            },
        ];
        return columns;
    }


    render() {
        let columns = this.generateColumns();
        const { content, totalElements } = this.props.productOrdersVO;
        let loadingList = this.props.loadingList;
        return(
            <div className="dataOrderContainer">
               <div className="commonSearchBox">
                    { this.renderInputsStatus() }
                    { this.renderSelectStatus() }
                    { this.renderSelectPlatform() }
                    { this.renderRepeatSelect()}
                    <div>
                        <label>领取位置</label>
                        <Select className="commonW150" value={this.props.place} onChange={value => this.value2Redux({ key: 'place', value })}>
                            {getPlace.map((obj,idx)=>(
                                <Option key={idx} value={obj.value}>{obj.label}</Option>
                            ))}
                        </Select>
                    </div>
                    { this.renderCalendarUI() }
                    <div>
                    <Button type="primary" onClick={this.searchHandler} className="mr30">查询</Button>
                    <Button type="danger" onClick={this.resetBtnClickHandler} className="mr30">重置</Button>
                    { checkPermission('sys:wxCollection:export') && <Button type="primary" onClick={this.downExcel}>导出Excel订单数据</Button> }
                    </div>
                </div>
               
                <div className="tableWrapper">
                    <Table
                        loading={loadingList}
                        columns={columns}
                        dataSource={content}
                        rowKey={record => record.id}
                        pagination={{
                            current: this.props.page,
                            pageSize: this.props.pageSize,
                            total: totalElements,
                            showTotal: total => `共有${total}条数据`,
                            onChange: this.paginationChange,
                            onShowSizeChange: this.showSizeChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions:  ['10','20','50','100']
                        }}
                    />
                </div>

            </div>
        )
    };
};

const mapToState = storeState => ({ ...storeState.dataOrder });

export default connect(mapToState)(DataOrder);