import apiRequest from '../../common/request'
import {message} from 'antd';

const PREFIX = "_ORDER_DETAIL_";

export const FETCH_ORDERDETAIL_START = PREFIX + "FETCH_ORDERDETAIL_START";
export const FETCH_ORDERDETAIL_SUCCED = PREFIX + "FETCH_ORDERDETAIL_SUCCED";
export const FETCH_ORDERDETAIL_FAIL = PREFIX + "FETCH_ORDERDETAIL_FAIL";
export const POST_WULIU_MESSAGE_SUCCED = PREFIX + "POST_WULIU_MESSAGE_SUCCED";

const fetch_start_creator = () => ({
    type: FETCH_ORDERDETAIL_START
});

const fetch_succed_creator = (responseData) => ({
    type: FETCH_ORDERDETAIL_SUCCED,
    data: responseData
});

const fetch_fail_creator = () => ({
    type: FETCH_ORDERDETAIL_FAIL
});

const post_wuliu_creator = () => ({
    type: POST_WULIU_MESSAGE_SUCCED
});

export const fetchOrderDetail = (orderNumber) => {
    /** 根据订单号发送GET请求 */
    //sinture_gk_web_ops
    //gk/ops
    let url = `gk/ops/order/details/${orderNumber}`;
    return (dispatch) => {
        apiRequest(url, {credentials: 'include'})
            .then(json => {
                dispatch(fetch_succed_creator(json.obj))
            })
            .catch(error => {
                dispatch(fetch_fail_creator());
                console.error(error)
              })
    }
}
//
///order/logistics
export const postWuliuData = (obj, cb) => {
    let url = 'gk/ops/order/logistics';
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include'
    };
    return (dispatch) => {
        apiRequest(url, config)
            .then(json => {
                message.success('操作成功');
              dispatch(post_wuliu_creator());
              cb && cb();
            })
            .catch(error => console.error(error))
    }
}
