import React from 'react';
import { connect } from 'react-redux';
import { Table, Select, Input, Button, Tooltip, Modal } from "antd";
import {
    updateStatus, fetchStudents,
    exportStudents, setReduxKeyValue, resetFilterValue,
    changeCurrentClass
} from "./actions";
import { fetchClassList } from '../InterviewClass/actions'
import { PAY_STATUS_LIST } from '../IStudentInfo/const'
import { checkPermission, getSearchQuery } from '../../utils/utils';
import { fetchQuestionAreas, fetchQuestionYears } from '../QuestionList/actions'
import './index.scss';

const Option = Select.Option;


class InterviewStudent extends React.Component {

    conditions2Cn = { year: '年份', area: '地区',classId: '班级', jobId: '职位id', mobile: '手机号', payStatus: '缴费状态', studentName: '学员姓名', type: '职位类型', page: '当前页码', pageSize: '每页条数' }

    inputConditions = ['studentName', 'mobile', 'jobId']

    selectConditions = ['year', 'area', 'type', 'payStatus', 'classId']
    //用于缴费状态更改时的临时变量
    payValue = ""
    payRecord = ""
    
    constructor(props) {
        super(props);
        this.yearsList = this.props.years;
        this.areasList = this.props.areas;
        this.typeList = [{ value: 1, name: '国考' }, { value: 2, name: '省考' }, { value: 3, name: '事业单位' }];
        this.payStatusList = PAY_STATUS_LIST;
        this.state = { visible: false };//缴费类型更改前模态框的初始状态
    }

    componentWillMount = async () => {
        this.props.dispatch(resetFilterValue());    //每次页面加载都初始化一下，防止搜索条件筛选没有数据
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchQuestionAreas());
        await this.getCurrentClass();
        this.fetchStudentsHandler();
        this.props.dispatch(fetchClassList({ page: 1, pageSize: 1000, }));      // 加载面试班数据
    }

    /** 获取当前课程id */
    getCurrentClass = () => {
        let classId = Number(getSearchQuery('classId'));
        if (classId) this.props.dispatch(changeCurrentClass(classId));
    };

    /** 生成加载学员时给接口所传递的数据 */
    generatePostParams = () => {
        let keys = Object.keys(this.conditions2Cn);
        let result = {};
        keys.map(key => { this.checkValueIsValid(this.props[key]) && (result[key] = this.props[key]) });
        return result;
    }

    /** 在获取数据的时候判断某个值是否有效 */
    checkValueIsValid = value => (value != '' && value != null)

    /** 加载学员列表数据包装器 */
    fetchStudentsHandler = () => {
        let data = this.generatePostParams();
        this.props.dispatch(fetchStudents(data));
    }

    /** 改变筛选值的时候同步更新到redux上面，isRealTime参数表示是否需要实时刷新值 */
    value2Redux = async (key, value, isRealTime=false) => {
        await this.props.dispatch(setReduxKeyValue(key, value));
        if (isRealTime) {
            await this.props.dispatch(setReduxKeyValue('page', 1));
            this.fetchStudentsHandler();
        }
    }

    /** 渲染条件筛选数据结构 */
    renderFilterUIStruct = () => (
        <div className="commonSearchBox">
            { this.renderInputFilterUIStruct() }
            { this.renderSelectFilterUIStruct() }
            <div>
            <Button disabled={this.props.loading} type="primary" onClick={this.clickSearch} className="mr30">搜索</Button>
            <Button disabled={this.props.loading} type="danger" onClick={this.resetSearchHandler} className="mr30">重置</Button>
            {checkPermission('interview:stu:export') && <Button type="primary" onClick={this.handleExport} className="mr10">导出excel</Button>}
            </div>
        </div>
    )

    /** 点击搜索按钮 */
    clickSearch = async () => {
        await this.props.dispatch(setReduxKeyValue('page', 1));
        this.fetchStudentsHandler();
    }

    /** 渲染输入类型的条件输入数据 */
    renderInputFilterUIStruct = () => (
        this.inputConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditions2Cn[key]}</label>
                <Input style={{width: '150px'}} onPressEnter={() => this.clickSearch()} onChange={event => this.value2Redux(key, event.target.value, false)} value={this.props[key]} placeholder={`输入${this.conditions2Cn[key]}`} />
            </div>
        ))
    )

    /** 渲染select类型的条件输入数据 */
    renderSelectFilterUIStruct = () => (
        this.selectConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditions2Cn[key]}</label>
                <Select
                    optionFilterProp="children"
                    showSearch
                    placeholder={`请选择${this.conditions2Cn[key]}`}
                    style={{width: (key=='classId' && 400 || 150)}}
                    onChange={value => this.value2Redux(key, value, true)}
                    value={this.props[key]}
                >
                    {key !== 'classId' && <Option value={null}>全部</Option>}
                    {
                        key === 'classId' ?
                        this.props.classList && [{ id: '', className: '全部' }].concat(this.props.classList).map((obj, idx) => (<Option key={idx} value={obj.id}>{obj.className}</Option>))
                        :
                        (key === 'type' || key === 'payStatus') &&
                        this[`${key}List`].map((obj, idx) => (<Option key={idx} value={obj.value+''}>{obj.name}</Option>))
                        ||
                        this.props[`${key}List`].map((obj, idx) => (<Option key={idx} value={obj+''}>{obj}</Option>))
                    }
                </Select>
            </div>
        ))
    )

    /** 清空筛选条件 */
    resetSearchHandler = async () => { 
        await this.props.dispatch(resetFilterValue());
        this.fetchStudentsHandler();
    }

    /** 分页器页码改变的时候触发 */
    paginationChange = async page => {
        await this.props.dispatch(setReduxKeyValue('page', page));
        this.fetchStudentsHandler();
    };

    onShowSizeChangeHandler = async (current, pageSize) => {
        await this.props.dispatch(setReduxKeyValue('page', 1));
        await this.props.dispatch(setReduxKeyValue('pageSize', pageSize));
        this.fetchStudentsHandler();
    }

    // updateStatusHandler = async (value, record) => {
    //     let result = await updateStatus(record.id, value)
    //     if (result) this.fetchStudentsHandler();
    // }

    toDetail = (info) => {
        this.props.history.push(`/iStudentInfo/${info.id}/${info.year}`)
    };

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>学员列表</span>
            {checkPermission('interview:stu:edit') && <Button type="primary" onClick={()=>this.props.history.push(`/iStudentInfoRelease`)}>添加学员</Button>}
        </div>
    );

    //更改缴费类型二次确认
    showModal =  (value, record ) => {
        this.payValue = value
        this.payRecord = record
        this.setState({
            visible: true,
        });
      };

    handleOk = async e => {
        let result = await updateStatus(this.payRecord.id, this.payValue);
        if (result) this.fetchStudentsHandler();
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };  

    /** antd table 的数据格式约定配置 */
    generateColumns() {
        const columns = [
            {
                title: '姓名',
                dataIndex: 'studentName',
                width: '6%',
            },
            {
                title: '手机',
                dataIndex: 'mobile',
                width: '6%',
            },
            {
                title: '性别',
                width: '6%',
                render: (text, record) => {
                    return (
                        record.sex==0?'女':'男'
                    );
                }
            },
            {
                title: '年份',
                dataIndex: 'year',
                width: '6%'
            },
            {
                title: '地区',
                dataIndex: 'area',
                width: '6%'
            },
            {
                title: '班级',
                dataIndex: 'className',
                render: (text, record) => (
                    <Tooltip title={(record.className)}>
                        <span className="title">{record.className}</span>
                    </Tooltip>
                ),
                width: '8%'
            },
            {
                title: '创建人',
                dataIndex: 'creatorName',
                width: '6%'
            },
            {
                title: '职位id',
                dataIndex: 'jobId',
                width: '6%'
            },
            {
                title: '类型',
                width: '6%',
                render: (text, record) => {
                    return (
                        record.type==1?'国考':(record.type==2?'省考':record.type==3?'事业单位':'未知')
                    );
                }
            },
            {
                title: '得分',
                dataIndex: 'point',
                width: '6%',
            },
            {
                title: '排名',
                dataIndex: 'rank',
                width: '6%'
            },
            {
                title: '缴费状态',
                render: (text, record) => {
                    return (
                        <Select disabled={!checkPermission('interview:stu:update')} onChange={value=>this.showModal(value, record)} value={record.payStatus} style={{ width: 100 }}>
   
                            {
                                PAY_STATUS_LIST.map(item => (
                                    <Option value={item.value} key={item}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    );
                }
            },
            {
                title: '优惠金额(元)',
                width: '6%',
                render: (text, record) => {
                    return (
                        <span style={{color: 'red'}}>{record.discountAmount}</span>
                    );
                }
            },
            {
                title: '备注',
                width: '6%',
                render: (text, record) => {
                    return (
                        <Tooltip title={record.remark}>
                            {record.remark && record.remark.length>10?record.remark.substr(0,10)+'...':record.remark}
                        </Tooltip>
                    );
                }
            },

        ];
        if (true) {
            columns.push({
                title: '操作',
                render: (text, record) => {
                    return (
                        <div>
                            {
                                checkPermission('interview:stu:info') &&
                                <Button size="small" type="primary" onClick={() => {this.toDetail(record)}} className="mb5">修改</Button>
                            }
                        </div>
                    );
                }
            })
        }
        return columns;
    }

    handleExport = e => {
        let data = this.generatePostParams();
        exportStudents(data);
    }

    render() {
        let columns = this.generateColumns();
        return (
            <div className="interviewStudentPageWrapper">
                <main>
                    { checkPermission('interview:stu:page') && this.renderFilterUIStruct() }

                    {/** 班级列表展示区域 */}
                    <div className="tableWrapper">
                        <Table
                            columns={columns}
                            dataSource={this.props.responseObj.content}
                            rowKey={record => record.id}
                            title={this.renderHeader}
                            loading={this.props.loading}
                            pagination={{
                                current: this.props.page,
                                pageSize: this.props.pageSize,
                                total: this.props.responseObj.totalElements,
                                onChange: this.paginationChange,
                                onShowSizeChange: this.onShowSizeChangeHandler,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                showTotal: total => `共有${total}位学员`,
                                pageSizeOptions:['10','20','50','100']
                            }}
                        />
                    </div>
                    <Modal
                        title="请再次确认"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <p>您确定修改缴费状态吗？</p>
                    </Modal>
                </main>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.interviewStudent, 
        classList: state.interviewClass.responseObj.content,
        areaList: state.questions.areas,
        yearList: state.questions.years,
    };
}

export default connect(mapStatetoProps)(InterviewStudent);
