import {
    RECEIVE_UPDATE_QUESTION_RESULT,
    RECEIVE_UPDATE_QUESTION_VIDEO_RESULT,
    REQUEST_UPDATE_QUESTION,
    REQUEST_UPDATE_QUESTION_VIDEO,
    REQUEST_QUESTION_INFO,
    UPDATE_QUESTION_TITLE,
    UPDATE_QUESTION_POINT,
    UPDATE_QUESTION_KNOWLEDGE,
    UPDATE_QUESTION_SOURCE,
    UPDATE_QUESTION_TYPE,
    UPDATE_QUESTION_ANSWER_DESC,
    UPDATE_QUESTION_ANALYSIS,
    UPDATE_QUESTION_CATEGORY,
    RECEIVE_QUESTION_INFO, UPDATE_QUESTION_SELECTION, UPDATE_SINGLE_QUESTION_ANSWER,
    REMOVE_UPDATE_QUESTION_OPTION, ADD_UPDATE_QUESTION_OPTION, UPDATE_MULTIPLE_QUESTION_ANSWER,
    UPDATE_QUESTION_MATERIALS_TYPE, QUESTION_RECEIVE_MATERIAL_INFO, QUESTION_REQUEST_MATERIAL_INFO,
    UPDATE_VIDEO_PROGRESS,
    UPDATE_URL,
    UPDATE_QUESTION_TAG_TYPE,
    UPDATE_SIGNED_VIDEO_URL,
    UPDATE_PROVINCE_TYPE,
    UPDATE_YEARS_TYPE
} from './actions';

export const NO_ANSWER = [];

export function institutionInfo(state = {
    isFetching: true,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: { area:[], year:[] },
    materialsInfo: {},
    isFetchingMaterial: false,
    progress: "0",
}, action) {
    switch (action.type) { 
        case UPDATE_PROVINCE_TYPE:
            return  Object.assign({}, state, {
                info: { ...state.info, area: action.value }
            });
        case UPDATE_YEARS_TYPE:
            return  Object.assign({}, state, {
                info: { ...state.info, year: action.value }
            });       
        case UPDATE_VIDEO_PROGRESS:
            return Object.assign({}, state, {
                progress: action.value
            });
        case REQUEST_QUESTION_INFO:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_QUESTION_INFO:
            if (action.question.quesType == 0) {
                action.question.answer = [action.question.answer];
            } else if (action.question.quesType != 0) {
                action.question.answer = action.question.answer.split(',').filter(i=>i.length>0);               
            }
            return Object.assign({}, state, {
                isFetching: false,
                info: action.question
            });
        case REQUEST_UPDATE_QUESTION:
            return Object.assign({}, state, {isUpdating: true});
        case REQUEST_UPDATE_QUESTION_VIDEO:
            return Object.assign({}, state, {isVideoUpdating: true, progress: "0"});
        case RECEIVE_UPDATE_QUESTION_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_QUESTION_VIDEO_RESULT:
            return Object.assign({}, state, {isVideoUpdating: false, isVideoUpdated: true});
        case UPDATE_QUESTION_SELECTION: {
            let options = state.info.options;
            options[action.index]['value'] = action.value;
            options[action.index]['key'] = action.key;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {options: options})
            });
        }
        case UPDATE_SINGLE_QUESTION_ANSWER: {
            let answer = state.info.answer;
            if (answer.length <= 0)
                answer.push(action.value);
            else {
                answer[0] = action.value;
            }
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {answer: answer})
            });
        }
        case UPDATE_MULTIPLE_QUESTION_ANSWER:
        case UPDATE_QUESTION_TITLE:
        case UPDATE_QUESTION_POINT:
        case UPDATE_QUESTION_KNOWLEDGE:
        case UPDATE_QUESTION_SOURCE:
        case UPDATE_QUESTION_CATEGORY:
        case UPDATE_QUESTION_ANSWER_DESC:
        case UPDATE_QUESTION_ANALYSIS:            
        case UPDATE_QUESTION_MATERIALS_TYPE:
        case UPDATE_URL:
        case UPDATE_QUESTION_TAG_TYPE:
        case UPDATE_SIGNED_VIDEO_URL:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case UPDATE_QUESTION_TYPE:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    [action.key]: action.value,
                    answer: NO_ANSWER.concat(),
                })
            });
        case ADD_UPDATE_QUESTION_OPTION: {
            let options = state.info.options;
            options.push({"key": "", "value": ""});
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {options: options})
            });
        }
        case REMOVE_UPDATE_QUESTION_OPTION: {
            let options = state.info.options;
            options.pop();
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {options: options})
            });
        }
        case QUESTION_RECEIVE_MATERIAL_INFO:
            return {...state, materialsInfo: action.info, isFetchingMaterial: false};
        case QUESTION_REQUEST_MATERIAL_INFO:
            return {...state, isFetchingMaterial: true};   
        default:
            return state;
    }
}
