import React from "react";
import { connect } from "react-redux";
import {
    updateTvInfo,
    update_title,
    update_type,
    update_img_url,
    updateVideo,
    update_content,
    update_recommend,
    fetchEmptyTV,
    fetchTv
} from "../TvInfo/actions";
import { fetchTagList } from "../TvList/actions";
import { checkPermission, validateUtil } from '../../utils/utils';
import { Input, Button, message, Card, Spin, Select, Checkbox } from 'antd';
import Editor from "../../components/Editor/index";
import ImagePicker from '../../components/ImagePicker';

const { Option } = Select;
class TvArticleInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isNew: true
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.dispatch(fetchTv(this.props.match.params.id, 'a'));
            this.setState({ isNew: false })
        } else {
            this.props.dispatch(fetchEmptyTV('a'));
        }
        this.props.dispatch(fetchTagList('a'));
    }

    // componentWillReceiveProps(nextProps) {
    //     if (this.props.match.params.id && !nextProps.params.id) {
    //         this.props.dispatch(fetchEmptyTV('a'));
    //     }
    // }

    uploadFile = () => {
        let file = this.refs.upload.files[0];
        if (file) {
            this.props.dispatch(updateVideo(file, 1));
        } else {
            message.warn('请上传文件')
        }
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    imgPickerFeedBack = (v) => {
        this.setState({ showModal: false });
        this.props.dispatch(update_img_url(v));
    };

    modifyTvInfo = () => {
        let blackList = ['title', 'tag_id'];
        let tips = { title: '标题', 'tag_id': '分类' };
        if (validateUtil(this.props.info, blackList, tips)) return;
        this.props.dispatch(updateTvInfo(this.props.info, 1));
    };

    render() {
        const { info } = this.props;
        return (
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <h4 style={{ margin: "0 0 30px" }}>{this.state.isNew ? '发布Tv文章' : '文章详情'}</h4>
                    {
                        this.props.isFetching &&
                        <Spin /> ||
                        <div>
                            <label>标题</label>
                            <Input value={info.title} onChange={e => this.props.dispatch(update_title(e.target.value))} className="mb20 mr10" />
                            <label>分类</label>
                            <Select
                                className="mb20"
                                value={this.props.info.tag_id}
                                style={{ width: 100 }}
                                onChange={value => this.props.dispatch(update_type(value))}
                            >
                                <Option value="" key=''>全部</Option>
                                {this.props.tags.map((item, idx) => (
                                    <Option value={item.id} key={idx}>{item.name}</Option>
                                ))}
                            </Select>
                            <br />
                            <label>内容</label>
                            <Editor
                                id={this.props.createTime}
                                content={info.content}
                                onChange={value => this.props.dispatch(update_content(value))}
                                imgPath='background/tv'
                            />
                            <br />
                            <label>封面图片</label>
                            {info.imgurl && <img alt="img" style={{ width: "70%" }} src={info.imgurl} />}
                            {
                                this.props.isVideoUpdating && <div className="progress">
                                    <div className="progress-bar" style={{ width: this.props.progress + "" }}></div>
                                </div>
                            }
                            <div>
                                <input type="file" ref="upload" style={{ margin: '0 0 20px 0' }} />
                                <Button type="primary" onClick={this.uploadFile} disabled={this.props.isVideoUpdating}>上传</Button>
                            </div>
                            <div>
                                <Button type="primary" onClick={() => this.setState({ showModal: true })}>从图片库上传</Button>
                                <ImagePicker
                                    showModal={this.state.showModal}
                                    closeModal={this.closeModal}
                                    feedBack={this.imgPickerFeedBack}
                                    imgPath='background/tv'
                                />
                            </div>
                            <br />

                            {
                                this.state.isNew &&
                                <Checkbox onChange={() => this.props.dispatch(update_recommend(info.recommend))}>推荐</Checkbox>
                            }
                        </div>
                    }
                </div>
                
                <div className="commonsubmit">
                    {checkPermission('gknews:tv:edit') &&
                        <Card className="cardR" size="small" title={this.state.isNew ? '发布' : '更新'}>
                            <Button type="primary" onClick={()=>this.modifyTvInfo()}>{this.state.isNew ? '发布' : '更新'}</Button>
                        </Card>
                    }
                </div>
                
            </div>
        )
    }

}


function mapStatetoProps(state) {
    const {
        tags
    } = state.tvList;

    return {
        tags,
        ...state.tvInfo,
    };
}

export default connect(mapStatetoProps)(TvArticleInfo);
