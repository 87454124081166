import React from "react";
import { connect } from "react-redux";
import {
    fill_content,
    fill_qArr,
    fill_questions,
    publish
} from "./action";
import Editor from "../../components/Editor/index";
import { Button, Card } from "antd";
import QuestionSelectCard from '../../components/QuestionSelectCard';
import { checkPermission, validateUtil } from '../../utils/utils';

const styles = {
    aside: {
        position: 'absolute',
        width: 240,
        height: 100,
        top: 60,
        right: 10
    }
};

class MaterialQuestionRelease extends React.Component {

    submit = async () => {
        let { type } = this.props.match.params;
        let blackList = ['content', 'questionIds'];
        let key2Cn = { content: '资料内容', questionIds: '问题' };
        if (validateUtil(this.props.info, blackList, key2Cn)) return;
        await this.props.dispatch(publish(type, this.props.info));
        this.props.history.push('/materialQuestions');
    };

    updateQuesArr = (arr) => {
        this.props.dispatch(fill_qArr(arr))
    };

    updateSelQues = (arr) => {
        this.props.dispatch(fill_questions(arr));
    };

    render() {
        let { isSend, info } = this.props;
        let { type } = this.props.match.params;
        let quesArr = info.questionIds;
        let selQuesList = info.questions;

        return (
            <div>
                <h4 style={{ margin: "0 0 30px" }}>发布资料题</h4>
                <div>
                    <main>
                        <div>
                            <label>资料内容</label>
                            <Editor
                                content={info.content}
                                onChange={value => this.props.dispatch(fill_content(value))}
                            />
                        </div>
                        <br/>
                        {/** 原先增加了一个categoryId这个props，值为326，但是加了这个props却又没有回显，干脆去掉 */}
                        <QuestionSelectCard
                            quesArr={quesArr}
                            selQuesList={selQuesList || []}
                            updateQuesArr={this.updateQuesArr}
                            updateSelQues={this.updateSelQues}
                            exerciseType={type=='xc' ? 0 : type}
                        />
                    </main>
                    {checkPermission('sys:material:edit') && <aside style={styles.aside}>
                        <Card title="发布" size="small">
                            <Button disabled={isSend} type="primary" onClick={this.submit}>
                                发布
                            </Button>
                        </Card>
                    </aside>}
                </div>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        isSend,
        info,
    } = state.materialQuestionRelease;

    return {
        isFetching,
        isSend,
        info,
    }
}

export default connect(mapStatetoProps)(MaterialQuestionRelease);