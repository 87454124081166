import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "TV_LIST";

export const RECEIVE_TAG_LIST = PREFIX + "RECEIVE_TAG_LIST";
export const RECEIVE_VIDEO_LIST = PREFIX + "RECEIVE_VIDEO_LIST";
export const RECEIVE_ARTICLE_LIST = PREFIX + "RECEIVE_ARTICLE_LIST";
export const RECEIVE_DELETE_VIDEO_RESULT = PREFIX + "RECEIVE_DELETE_VIDEO_RESULT";
export const REQUEST_DELETE_VIDEO = PREFIX + "REQUEST_DELETE_VIDEO";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

function receive_tag_list(value) {
    return {
        type: RECEIVE_TAG_LIST,
        value
    }
}

function receive_video_list(data) {
    return {
        type: RECEIVE_VIDEO_LIST,
        tvs: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements
    }
}

function receive_article_list(data) {
    return {
        type: RECEIVE_ARTICLE_LIST,
        tvs: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements
    }
}

function receive_request_delete_video(id) {
    return {
        type: RECEIVE_DELETE_VIDEO_RESULT,
        id
    }
}

function request_delete_video() {
    return {
        type: REQUEST_DELETE_VIDEO
    }
}


export function deleteTv(id, type){
    type = type || 'v'
    return dispatch => {
        dispatch(request_delete_video());
        apiRequest(`/gk/ops/Tv/delete/${type}/${id}`, {credentials: 'include'})
            .then(() => {
                message.success('操作成功');
                dispatch(receive_request_delete_video(id))
                dispatch(fetchList({type}));
            });
    }
}

export function fetchList(params){
    let data = {
        page: params.page || 1,
        type: params.type || 'v',
        pageSize: params.pageSize || 10
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        apiRequest(`/gk/ops/Tv/page`, config)
            .then(json => {
                if (data.type == 'v'){
                    dispatch(receive_video_list(json.obj))
                }else {
                    dispatch(receive_article_list(json.obj))
                }
            });
    }
}

export function fetchTagList(){
    return dispatch => {
        apiRequest(`/gk/ops/tvtag/list`, {credentials: 'include'})
            .then(json => {
                dispatch(receive_tag_list(json.obj))
            });
    }
}


export function delTag(id, type) {
    return dispatch => {
        apiRequest('/gk/ops/tvtag/delete/' + id, {credentials: 'include'})
            .then(() => {
                message.success('操作成功');
                dispatch(fetchTagList(type))
            });
    }
}

export function newTag(tag) {
    let data = {
        name: tag,
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        apiRequest("/gk/ops/tvtag/saveOrUpdate", config)
            .then(() => {
                message.success('操作成功');
                dispatch(fetchTagList());
            });
    }
}