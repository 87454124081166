import apiRequest from '../../common/request'
import { message } from 'antd';

const PREFIX = "_CLASS_EXERCISE_PAGE_";

export const RECEIVE_EXERCISE = PREFIX + 'RECEIVE_EXERCISE';
export const BEGIN_FETCHING_DATA = PREFIX + 'BEGIN_FETCHING_DATA';
export const SET_EXERCISE_LIST_AFTER_SEARCH = PREFIX + 'SET_EXERCISE_LIST_AFTER_SEARCH';
export const SET_EXERCISE_LIST = PREFIX + 'SET_EXERCISE_LIST';
export const START_SEARCH = PREFIX + 'START_SEARCH';
export const SEARCH_DATA_ARRIVE = PREFIX + 'SEARCH_DATA_ARRIVE';
export const SET_VALUE = PREFIX + 'SET_VALUE';
export const END_FETCHING_DATA = PREFIX + 'END_FETCHING_DATA';

export const setValue = (key, value) => ({ type: SET_VALUE, key, value });

export const setExerciseList = data => ({ type: SET_EXERCISE_LIST, data });

const startSearch = () => ({ type: START_SEARCH });

const searchDataArrives = data => ({ type: SEARCH_DATA_ARRIVE, data });

function beginFetch() {
    return {
        type: BEGIN_FETCHING_DATA
    };
}
function endFetch() {
    return {
        type: END_FETCHING_DATA
    };
}

/** 将筛选出来之后的数据更新redux中所存储的练习数组 */
export function setExerciseListBySearch(data) {
    return {
        type: SET_EXERCISE_LIST_AFTER_SEARCH,
        data
    };
}

export function receive_exercise_list(data) {
    return {
        type: RECEIVE_EXERCISE,
        exerciseList: data.content,
    }
}

/** 检验该班级测试在该班级是否有人做过 */
export const checkExercise = (id, classId) => {
    let url = `/gk/ops/class/exercise/checkExercise/${id}/${classId}`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        dispatch(beginFetch());
        return apiRequest(url, config)
        .then(json => {
            dispatch(endFetch());
            json.obj==1 && message.error('该测试在该班级已有人做过');
        })
        .catch(error => console.log(error));
    }
};

/** 获取班级练习列表数据 */
export function fetchExerciseList(params){
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10,
        classId: +params.id,
        exerciseType: params.type
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(beginFetch());
        return apiRequest(`/gk/ops/class/exercise/getClassExercise`, config)
            .then(json => {
              dispatch(receive_exercise_list(json.obj));
              // 同步已选试题ID，用于回显
              dispatch(setValue('selArr', json.obj.content && json.obj.content.map(item=>item.id) || []));
            })
            .catch(error => console.log(error));
    }
}

export function removeClass(id, cb) {
    return dispatch => {
        apiRequest('/gk/ops/class/delete/' + id, {method: 'POST',credentials: 'include'})
            .then(response => {
                message.success('操作成功');
            })
            .catch(error => console.log(error));
    }
}

export function addClass(newClassName, cb) {
    let data = {
        name: newClassName
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        return apiRequest("/gk/ops/class/saveOrUpdate", config)
            .then(response => {
                message.success('发布成功');
            })
            .catch(error => console.log(error));
    }
}



/** 搜索班级测试 */
export function searchClassTests(obj) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
        dispatch(startSearch());
        apiRequest(`/gk/ops/class/exercise/page`, config)
            .then(json => {
                let result = json.obj || {};
                dispatch(searchDataArrives(result));
            })
        }
    }

/** 更新班级测试 */
export function updateClassTests(obj) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
      apiRequest('/gk/ops/class/exercise/saveOrUpdateExercise', config).then(json => {
          json.status == 1 ? message.success('操作成功') : message.error(json.errorMes)         
      }).catch(e => {});
    }
}

/** 生成PDF */
export function createExercisePdf(type,examineId){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };   
    return apiRequest(`/gk/ops/examine/generatePdf/${type}/${examineId}` , config)
            .then(json => {
                message.success('操作成功')
            })
            .catch(error => console.log(error));
    
}

/** 生成申论PDF */
export function createShenlunPdf(exerciseType, examineId) {
    let url = `/gk/ops/examine/createPdf/${examineId}`;
    if(exerciseType == 3) url = `/gk/ops/gj/historyExamine/generatePdf/22/${examineId}`;
    if(exerciseType == 4) url = `/gk/ops/zc/historyExamine/generatePdf/23/${examineId}`;
    if(exerciseType == 5) url = `/gk/ops/zongying/create/exercisePdf/${examineId}`;

    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return apiRequest( url, config)
            .then(json => {
                message.success('生成PDF成功')
            })
            .catch(error => console.log(error));
}
