import apiRequest from '../../common/request'

const PREFIX = "CLASS_LIST_INDEX_";

export const FETCH_DATA_START = PREFIX + "FETCH_DATA_START";
export const FETCH_DATA_SUCCED = PREFIX + "FETCH_DATA_SUCCED";
export const FETCH_DATA_FAIL = PREFIX + "FETCH_DATA_FAIL";
export const CHANGE_ACTIVE_PAGE = PREFIX + "CHANGE_ACTIVE_PAGE";
export const CHANGE_PAGE_SIZE = PREFIX + "CHANGE_PAGE_SIZE";
export const CHANGE_SUBJECT = PREFIX + "CHANGE_SUBJECT";

export const FETCH_CLASS_LABEL_START = PREFIX + "FETCH_CLASS_LABEL_START";
export const FETCH_CLASS_LABEL_SUCCED = PREFIX + "FETCH_CLASS_LABEL";
export const CHANGE_LABEL = PREFIX + "CHANGE_LABEL";

export const FETCH_CLASS_TEACHER_START = PREFIX + "FETCH_CLASS_TEACHER_START";
export const FETCH_CLASS_TEACHER_SUCCED = PREFIX + "FETCH_CLASS_TEACHER";
export const CHANGE_HEAD_TEACHER = PREFIX + "CHANGE_HEAD_TEACHER";
export const CHANGE_TEACHER = PREFIX + "CHANGE_TEACHER";
export const RESET_SEARCH_STATUS = PREFIX + "RESET_SEARCH_STATUS";
export const CHANGE_HEADTEACHER_ID = PREFIX + 'CHANGE_HEADTEACHER_ID'; 

export const resetSearchStatus = () => ({ type: RESET_SEARCH_STATUS });

export const changeTeacher = data => ({ type: CHANGE_TEACHER, data });

export const CHANGE_TITLE = PREFIX + "CHANGE_TITLE";

export const FETCH_COURSE_LIST_SUCCED = PREFIX + "FETCH_COURSE_LIST_SUCCED";

const fetch_course_list_succed = data => ({ type: FETCH_COURSE_LIST_SUCCED, data });

const fetch_data_start = () => ({ type: FETCH_DATA_START });

const fetch_data_succed = data => ({
    type: FETCH_DATA_SUCCED,
    data
});

const fetch_data_fail = () => ({ type: FETCH_DATA_FAIL });

const fetch_class_label_start = () => ({ type: FETCH_CLASS_LABEL_START });

const fetch_class_label_succed = data => ({
    type: FETCH_CLASS_LABEL_SUCCED,
    data
});

export const changeLabel = data => ({
    type: CHANGE_LABEL,
    data
});

export const changeHeadTeacherId = value => ({
    type: CHANGE_HEADTEACHER_ID,
    value
});

const fetch_class_teacher_start = () => ({ type: FETCH_CLASS_TEACHER_START });

const fetch_class_teacher_succed = data => ({
    type: FETCH_CLASS_TEACHER_SUCCED,
    data
});

export const changeHeadTeacher = data => ({
    type: CHANGE_HEAD_TEACHER,
    data
});

export const changeSubject = data => ({
    type: CHANGE_SUBJECT,
    data
})

export const changeTitle = data => ({
    type: CHANGE_TITLE,
    data
});

/** 改变第几页 */
export const changeActivePage = data => ({
    type: CHANGE_ACTIVE_PAGE,
    data
});

/** 改变每页分页数目 */
export const changePageSize = data => ({
    type: CHANGE_PAGE_SIZE,
    data
});

/** 删除关怀记录 */
export const fetchDateDel = async id => {
    let url = `/gk/ops/userCare/data/del/${id}`;
    let config = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', },
    };
    let json = await apiRequest(url, config);
    return json || {};
};

/** 新增或修改关怀记录 */
export const fetchDateEdit = async data => {
    let url = `/gk/ops/userCare/date/edit`;
    let config = {
        method: 'POST',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    let json = await apiRequest(url, config);
    return json || {};
};

/** 获取关怀记录 */
export const fetchDateList = async data => {
    let url = `/gk/ops/userCare/date/list`;
    let config = {
        method: 'POST',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    let json = await apiRequest(url, config);
    let obj = json.obj || {};
    return obj;
};

/** 获取班级标签 */
export const fetchClassLabel = errCb => {
    let url = '/gk/ops/student/class/label/list';
    let config = {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return async dispatch => {
        dispatch(fetch_class_label_start());
        apiRequest(url, config).then(json => {
          let arr = json.obj;
          arr.unshift('全部');
          dispatch(fetch_class_label_succed(arr));
        });
    }
}

/** 获取班级老师数据 */
export const fetchHeadTeacher = errCb => {
    let url = '/gk/ops/student/class/teacher/list';
    let config = {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return dispatch => {
        dispatch(fetch_class_teacher_start());
        return apiRequest(url, config).then(json => {
          let arr = json.obj;
          arr.unshift('全部');
          dispatch(fetch_class_teacher_succed(arr));
        }).catch(err => {
            errCb && errCb('获取老师数据出错');
        })
    }
}

/** 获取班级列表数据 */
export const fetchClassList = (params={}, errCb) => {
    const url = '/gk/ops/student/class/page';
    let data = { page: params.page || 1, pageSize: params.pageSize || 10 };
    params.type && (data.type = params.type);
    if (params.type == 0) data.type = 0;
    params.label && params.label != '全部' && (data.label = params.label);
    params.teacher && params.teacher != '全部' && (data.teacher = params.teacher);
    params.headTeacher && params.headTeacher != '全部' && (data.headTeacher = params.headTeacher);
    params.title && (data.title = params.title);
    params.teacherId && params.teacherId != '全部' && (data.teacherId = params.teacherId);


    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        dispatch(fetch_data_start());
        return apiRequest(url, config).then(json => {
            dispatch(fetch_data_succed(json.obj));
        }).catch(err => {
            errCb && errCb('获取班级列表出错');
            dispatch(fetch_data_fail());
        })
    }
}

/** 获取课程列表数据 */
export const fetchCourseList = errCb => {
    let url = '/gk/ops/student/courseList';
    let config = {
        'method': 'GET',
        'headers': {
            'Accept': 'application/json'
        },
        'credentials': 'include'
    };
    return async dispatch => {
        apiRequest(url, config).then(json => {
          dispatch(fetch_course_list_succed(json.obj));
        }).catch(e=>{
          errCb && errCb(e.errorMes || '获取课程列表数据出错');
        });
    }
}

/** 添加或修改班级 */
export const saveOrUpdateClass = (params, successCb, errCb) => {
    let url = '/gk/ops/student/class/saveOrUpdate';
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(params)
    };
    return async dispatch => {
        apiRequest(url, config).then(json => {
          successCb && successCb();
        }).catch(e => {
          errCb && errCb(e.errorMes || '操作失败');
        });
    }
}
