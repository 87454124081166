import {
    RECEIVE_CLASS_LIST,
    SET_REDUX_KEY_VALUE,
    RESET_FILTER_STATUS
} from './actions';

const filterProperty = {
    page: 1,                                    // 当前页码
    pageSize: 10,                               // 每页显示多少条数据
    area: null,                                 // 地区
    year: null,                                 // 年份
    className: null,                            // 班级名
    headTeacher: null,                          // 班主任
};

const initialState = {
    responseObj: {
        content: [],                                // 班级列表数据
    },                                              // 接口所返回的响应数据
    loading: false,                                 // 是否处于加载过程中
    ...filterProperty
};

export function interviewClass (state=initialState, action) {
    switch(action.type) {
        case RECEIVE_CLASS_LIST:
            return Object.assign({}, state, { responseObj: action.data, loading: false });
        case SET_REDUX_KEY_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        case RESET_FILTER_STATUS:
            return Object.assign({}, state, { ...filterProperty });
        default:
            return state;
    }
}
