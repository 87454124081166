import apiRequest from '../../common/request'
import { message } from 'antd';

const PREFIX = "ZHY_LIST";

export const RECEIVE_SHENLUN_AREAS = PREFIX + "RECEIVE_SHENLUN_AREAS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";
export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const RESET_FILTER_STATUS = PREFIX + "RESET_FILTER_STATUS";

export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

export const resetFilterStatus = () => ({ type: RESET_FILTER_STATUS });

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        numberOfElements: data.numberOfElements,
    }
}

/** 生成PDF */
export function createExercisePdf(examineId){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };   
    return apiRequest(`/gk/ops/zongying/createPdf/${examineId}` , config)
            .then(json => {
                message.success('生成PDF成功')
            })
            .catch(error => console.log(error));
}

export function fetchQuestions(params, cb) {
    let data = params || {};
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(setKeyValue('zyLoading', true));
        apiRequest('/gk/ops/zongying/page', config)
            .then(json => {
                dispatch(receive_questions(json.obj));
                cb && cb();
            })
            .catch(error => console.log(error));
    }
}

/** 获取综合应用问题列表, 用于试题勾选 */
export function fetchZyQuestions (params) {
    let data = params || {};
    params.year ? data.years = params.year.toString() : '';
    delete data.year;
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    return dispatch => {
        dispatch(setKeyValue('zyLoading', true));
        apiRequest('/gk/ops/zongying/question/page', config)
            .then(json => {
                dispatch(setKeyValue('zyLoading', false));
                dispatch(setKeyValue('zyQuestionsObj', json.obj));
            })
            .catch(error => console.log(error));
    }
}

function receive_shenlun_areas(areas){
    return {
        type: RECEIVE_SHENLUN_AREAS,
        areas
    }
}

/** 综应地区列表 */
export function fetchShenlunAreas(){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
      apiRequest('/gk/ops/zongying/area', config)
        .then(json => {            
            dispatch(receive_shenlun_areas(json.obj));
        })
        .catch(error => console.log(error));
    }
}

/** 综应年份列表 */
export function fetchZyYearList(){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
      apiRequest('/gk/ops/zongying/year/list', config)
        .then(json => {            
            dispatch(setKeyValue('years', json.obj));
        })
        .catch(error => console.log(error));
    }
}