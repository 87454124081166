import React from 'react';
import { fetchCourseLists, uploadFile } from './actions';
import { Alert, Breadcrumb, Select, message, Upload, Button, Spin, Popconfirm, notification } from 'antd';
import { checkPermission } from '../../utils/utils';
import './index.scss';
import { UploadOutlined } from '@ant-design/icons';

const Option = Select.Option;

class ConfigUserPageByExcel extends React.Component {

  state = {
    selected: [],
    selectCourseId: [],                                 // 所选中课程的ID
    courseLists: [],                                    // 所有的课程列表数据
    fileList: [],                                       // 用户所上传的文件对象
    showLoading: false,                                 // 是否显示加载状态
    loadingTip: '',                                     // 上传中
  };

  async componentDidMount() {
    this.setState({ showLoading: true, loadingTip: '加载课程列表' });
    let response = await fetchCourseLists();
    this.setState({ showLoading: false });
    if (response && response.status === 1) {
      let courseLists = response.obj.content || [];
      this.setState({ courseLists });
    } else {
      message.error(response && response.errorMes || '获取课程列表数据发生错误');
    }
  }

  /** 监听选择课程 */
  selectCourseHandler = value => {
    let selected = this.getSelected(value);
    this.setState({ selectCourseId: value, selected });
  }

  /** 生成upload组件的配置参数 : Fake Single File Upload */
  generateUploadSettings = () => {
    const { fileList } = this.state;
    // if (fileList.length > 1) return;
    return {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return { fileList: newFileList, };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      accept: '.xls',
      fileList
    };
  }

  /** 配置成功后生成提示语 */
  generateSuccessMessage = responseObj => {
    let result = [];
    if (Object.prototype.toString.call(responseObj) !== '[object Array]') return '';
    responseObj.map(obj => {
      let str = `${this.findCourseNameByCourseId(obj.courseId)}成功配置${obj.count}位学员`;
      result.push(str);
    });
    return result.join('，\n');
  }

  /** 根据课程ID找出课程名 */
  findCourseNameByCourseId = courseId => {
    let result = '';
    if (Object.prototype.toString.call(this.state.courseLists) === '[object Array]') {
      let matchObj = this.state.courseLists.find(obj => obj.id == courseId);
      return matchObj.title;
    }
    return result;
  }

  /** 监听上传文件 */
  uploadFileHandler = async () => {
    if (!this.checkCourseHasChose()) { message.warning('请先选择课程'); return; }
    if (!this.checkFileHasChose()) { message.warning('请先选择文件'); return; }
    this.setState({ showLoading: true, loadingTip: '配置中' });
    let response = null;
    try {
      response = await uploadFile(this.state.fileList, this.state.selectCourseId);
    } catch (err) { }
    this.setState({ showLoading: false });
    if (response && response.status === 1) {
      let str = this.generateSuccessMessage(response.obj);
      notification.open({
        message: '操作结果',
        description: str,
        duration: 7,
      });
      this.setState({ selectCourseId: [], fileList: [] });
    } else if (response && response.status === 2) {
      notification.warning({
        message: '配置失败',
        description: response.errorMes + '请检查文件内容',
        duration: 7,
      });

    }
  }

  /** 判断是否选择课程 */
  checkCourseHasChose = () => {
    return this.state.selectCourseId && this.state.selectCourseId.length;
  }

  /** 判断是否选择文件 */
  checkFileHasChose = () => {
    return this.state.fileList && this.state.fileList.length;
  }

  /** 获取当前选中课程的名字 */
  getChoseCourseNames = () => {
    let result = [];
    this.state.selectCourseId.map(id => {
      let name = this.findCourseNameByCourseId(id);
      result.push(name);
    });
    return result.join('，');
  }

  /** 实时搜索时，课程列表是时刻变化的，要保持已选课程的正常回显，需要把已选课程加入当前课程列表并去重处理 */
  onSearch = async (val) => {
    let {selected} = this.state;
    let response = await fetchCourseLists(val);
    if (response.status === 1) {
      let courseLists = response.obj.content || [];
      // 如果前面已经选择了课程，需要把已选课程加入当前课程列表并去重处理
      if(selected.length) {
        courseLists = courseLists.concat(selected);
        courseLists = [...new Set(courseLists)];
      }
      this.setState({ courseLists });
    } else {
      message.error(response.errorMes || '获取课程列表数据发生错误');
    }
  }

  /** 在已有的课程列表中找出已选的课程 */
  getSelected = (arr) => {
    let {courseLists} = this.state;
    let selected = [];
    courseLists.length && courseLists.map(item=>{
      arr.map(i=>{
        if(i==item.id) selected.push(item);
      })
    });
    return selected;
  }

  render() {
    return (
      <div className="userConfigPageByExcelWrapper">
        <Breadcrumb>
          <Breadcrumb.Item>课程管理</Breadcrumb.Item>
          <Breadcrumb.Item>Excel配置</Breadcrumb.Item>
        </Breadcrumb>
        <Alert
          message="注意以下几点"
          description={
            <div>
              <p style={{fontSize: '12px'}}>上传的Excel文件包含两列，都是必填。第一列表示手机号码，第二列表示用户名(没有用户名的话可以用手机号替代)</p>
              <p style={{fontSize: '12px'}}>目前只支持上传xls格式的文件，在上传Excel文件之前应该先将非xls文件另存为xls格式文件</p>
              <p style={{fontSize: '12px'}}>如果成功配置的学员数量和Excel文件数量不一致的话，一般情况下是因为该学员已经配置过该课程或者Excel文件里面具有重复号码</p>
              <p style={{fontSize: '12px'}}>一次最多支持5000位学员，建议每次配置前针对Excel文件进行一下去重处理</p>
            </div>
          }
          type="warning"
          showIcon
        />
        <div className="selectRow">
          <label>课程：</label>
          <Select
            mode="multiple"
            optionFilterProp="children"
            value={this.state.selectCourseId}
            style={{ width: 400 }}
            onSearch={this.onSearch}
            onChange={value => { this.selectCourseHandler(value) }}
          >
            {
              this.state.courseLists.map((obj, idx) => <Option key={idx} value={obj.id}>{obj.title}</Option>)
            }
          </Select>
        </div>

        {/** 上传文件 */}
        <div className="mb40 w500">
          <Upload
            {...this.generateUploadSettings()}
          >
            <Button icon={<UploadOutlined />} type="primary" disabled={!checkPermission('user:course:excel') || this.state.fileList && this.state.fileList.length > 0}>
              点击上传学员Excel表格
            </Button>
          </Upload>
        </div>

        {
          checkPermission('user:course:excel') ?
            this.checkCourseHasChose() && this.checkFileHasChose() ?
              <Popconfirm
                title={`确定添加${this.getChoseCourseNames()}`}
                onConfirm={this.uploadFileHandler}
                okText="确定"
                cancelText="否"
              >
                <Button type="primary">配置课程</Button>
              </Popconfirm>
              :
              <Button onClick={this.uploadFileHandler} type="primary">配置课程</Button>
            :
            <div></div>
        }

        {
          this.state.showLoading &&
          <div className="loadingBgFixed">
            <div className="loadingWrapper">
              <Spin size="large" tip={this.state.loadingTip} />
            </div>
          </div>
        }

      </div>
    );
  }
}

export default ConfigUserPageByExcel;