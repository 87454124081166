import {
  RECEIVE_SYSTEM_INFO,
  SET_PAGE_SIZE,
  SET_PAGE_ACTIVE,
  SET_LOADING,
} from './action';
const initialState = {
  systemList: [],
  totalElements:0,
  pageSize:10,
  activePage:1,
  loading:false,
};

export function infoSystemPush(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {loading:true});
    case RECEIVE_SYSTEM_INFO:
      return Object.assign({}, state, {systemList:action.data.content,totalElements:action.data.totalElements,loading:false});
    case SET_PAGE_SIZE:
      return Object.assign({}, state, { pageSize: action.data });    
    case SET_PAGE_ACTIVE:
      return Object.assign({}, state, { activePage: action.data });    
    default:
      return state;
  }
}