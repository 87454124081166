/**
 * Created by pwq on 2016/12/7.
 */

import apiRequest from '../../common/request'
import { message } from 'antd';
export const REQUEST_UPDATE = "REQUEST_UPDATE";
export const REQUEST_INFO = "REQUEST_INFO";
export const RECEIVE_INFO = "RECEIVE_INFO";
export const REQUEST_EMPTY_INFO = "REQUEST_EMPTY_INFO";
export const RECEIVE_UPDATE_RESULT = "RECEIVE_UPDATE_RESULT";


export function request_info() {
    return {
        type: REQUEST_INFO
    }
}

export function receive_info(info) {
    return {
        type: RECEIVE_INFO,
        info,
        receivedAt: Date.now()
    }
}

function request_update() {
    return {
        type: REQUEST_UPDATE
    }
}

export function receive_update_result() {
    return {
        type: RECEIVE_UPDATE_RESULT,
    }
}

// type：国考/省考
export async function getYearsAndProvincesByType(type) {
    let url = '/gk/ops/gkProvinceJob/getYearsAndProvincesByType/'+type
    let json = await apiRequest(url)
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

/** 事业单位职位查询具体的县区 */
export async function getInstitutionDistrict(year, province, city) {
  let url = '/gk/ops/institution/district?province='+province+'&year='+year+'&city='+city
  let json = await apiRequest(url)
  if(json.status!=1){
    message.error(json.errorMes);
  }
  return json.obj;
}

/** 根据年份省份，获取相对于下面的城市或者类型 */
export async function getCityAndType(year, area, type) {
    let url = '/gk/ops/gkProvinceJob/condition?province='+area+'&year='+year
    type==3 && (url = '/gk/ops/institution/condition?province='+area+'&year='+year)
    let json = await apiRequest(url, {method: 'POST'})
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return {cities: json.obj.citys, types: json.obj.types};
}

export async function getProvinceAndType(year) {
    let url = '/gk/ops/gkJob/getProvinceAndType/'+year
    let json = await apiRequest(url)
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function getOfficeByProvince(year, province, type) {
    let url = `/gk/ops/gkJob/getOfficeByProvince/${year}/${province}/${type}`
    let json = await apiRequest(url, {method: 'POST'})
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function getOfficeByPerson(params) {
    let url = '/gk/ops/gkJob/getOfficeByPerson'
    let data = { page: params.page || 1, pageSize: params.pageSize || 10 };
    params.city!=='' && (data.city = params.city);
    params.bureau!=='' && (data.bureau = params.bureau);
    params.education!=='' && (data.education = params.education);
    params.deptCode!=='' && (data.deptCode = params.deptCode);
    params.major!=='' && (data.major = params.major);
    params.politicalStatus!=='' && (data.politicalStatus = params.politicalStatus);
    params.year!=='' && (data.year = params.year);
    params.province!=='' && (data.province = params.province);
    params.workedYear!=='' && (data.workedYear = params.workedYear);
    params.position!=='' && (data.position = params.position);
    params.office!=='' && (data.office = params.office);
    params.postCode!=='' && (data.postCode = params.postCode);
    params.type!=='' && (data.type = params.type);

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    let json = await apiRequest(url, config);
    json.obj && !json.obj.content.length && message.warn("该条件没有查询结果!");
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function getProvinceOffice(params, type) {

    let url = `/gk/ops/gkProvinceJob/pageDeptsPositions`;
    (type == 3) && (url = '/gk/ops/institution/pageDeptsPositions');

    let data = { page: params.page || 1, pageSize: params.pageSize || 10 };
    params.city!=='' && (data.city = params.city);
    params.deptName!=='' && (data.deptName = params.deptName);
    params.education!=='' && (data.education = params.education);
    params.major!=='' && (data.major = params.major);
    params.politicalStatus!=='' && (data.politicalStatus = params.politicalStatus);
    params.positionName!=='' && (data.positionName = params.positionName.trim());
    params.province!=='' && (data.province = params.province);
    params.type!=='' && (data.type = params.type);
    params.workedYear!=='' && (data.workedYear = params.workedYear);
    params.year!=='' && (data.year = params.year);
    params.deptCode!=='' && (data.deptCode = params.deptCode);
    params.positionCode!=='' && (data.positionCode = params.positionCode);
    params.district!=='' && (data.district = params.district);

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    let json = await apiRequest(url, config);
    json.obj && !json.obj.content.length && message.warn("该条件没有查询结果!");
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}


export async function getPositionDetailById(id) {
    let url = `/gk/ops/gkJob/getPositionDetailById/${id}`;
    let json = await apiRequest(url)
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function getPositionDetailByIdPro(id, type) {
    let url = `/gk/ops/gkProvinceJob/selectPositionDetailById/${id}`;
    (type == 3) && (url = `/gk/ops/institution/selectPositionDetailById/${id}`);
    let json = await apiRequest(url)
    if(json.status!=1){
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function fetchInfo(id) {
    let url = `/gk/ops/interviewStudent/details/${id}`
    let json = await apiRequest(url, {method: 'POST'})
    if(json.status != 1) {
      message.error(json.errorMes);
    }
    return json.obj;
}

export async function checkJob(id, type) {
    let url = `/gk/ops/interviewStudent/checkJob/${id}/${type}`
    let json = await apiRequest(url)
    if(json.status != 1) {
      message.error(json.errorMes);
    }
    return json;
}

export function saveOrUpdateStudent(data) {
    data.remark == '' && delete data.remark;
    data.point == '' && delete data.point;
    data.rank == '' && delete data.rank;   
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update());
        apiRequest("/gk/ops/interviewStudent/saveOrUpdate", config)
            .then(json => {
              if (json.status == 1) {
                dispatch(receive_update_result());
                message.success('操作成功');
                window.history.back();
              }else {
                message.error(json.errorMes||'操作失败');
              }
            }).catch(e => {
              dispatch(receive_update_result());
            });
    }
}
