import * as CNT from './action';

const DEFAULT_INFO = {
    title: '',
    period: '',
    bookDesc: '',
    courseNums: [],
    remark: '',
    target: '',
    // area: '',
    label: '',
    teachers: [],
    publish: 0,
    recommend: 0,
    jifenUse: 0,
    packageSend: 0,
    price: 0,
    classCount: 0,
    limits: 0,
    teacherDesc: '',
    classDesc: '',
    bannerType: 0,
    categoryIds: [0],//分类目录默认值
    courseBanner: '',
    type: null,
};

let initState = {
    isFetching: true,
    isUpdating: false,
    isUpdated: false,
    isUploading: false,
    info: DEFAULT_INFO,
    teacherArr: [],
    labelArr: [],
    originalCourseNums: [],                           // 模拟不变的courseNums数组，用在通过input进行比较的场合中，来自服务器的返回；当通过日历形式改变的时候也会改变它
    confirmCourseNums: [],                    //确认过后的日期集合
};

export function courseInfo(state = initState, action) {
    switch (action.type) {
        case CNT.FILL_BANNER:
            return {...state, info: {...state.info, [action.key]: action.value}};
        case CNT.FILL_PROPERTY:
            return { ...state, info: { ...state.info, [action.data.key]: action.data.value } };
        case CNT.REQUEST_UPDATE:
            return {...state, isUpdating: true};
        case CNT.RECEIVE_UPDATE_SUC:
        case CNT.RECEIVE_UPDATE_FAIL:
            return {...state, isUpdating: false, isUpdated: true};
        case CNT.FETCHING_START:
            return { ...state, isFetching: true };
        case CNT.RECEIVE_INFO:
            let originalCourseNums = action.info.courseNums;
            let confirmCourseNums = action.info.courseNums;
            delete action.info.area;
            return { ...state, info: action.info, isFetching: false, originalCourseNums, confirmCourseNums };
        case CNT.SET_UPDATE_STATUS:
            return Object.assign({}, state, { confirmCourseNums: action.data });
        case CNT.NOT_SET_UPDATE_STATUS:
            return { ...state, info: { ...state.info, courseNums: action.data }, originalCourseNums: action.data };
        case CNT.RECEIVE_UPLOAD_PIC_SUC:
        case CNT.RECEIVE_UPLOAD_PIC_FAIL:
            return Object.assign({}, state, {isUploading: false});
        case CNT.REQUEST_UPLOAD:
            return Object.assign({}, state, {isUploading: true});
        case CNT.RESET_INFO:
            return Object.assign({}, state, {info: DEFAULT_INFO});
        case CNT.RECEIVE_TEACHER_INFO:
            return Object.assign({}, state, {teacherArr: action.data});
        case CNT.RECEIVE_LABEL:
            return Object.assign({}, state, {labelArr: action.data});
        case CNT.SET_ISFETCHING_FALSE:
            return Object.assign({}, state, {isFetching: false});
        case CNT.SET_ORIGINAL_COURSENUMS:
            return Object.assign({}, state, { originalCourseNums: action.data });
        case CNT.SET_COURSENUMS:
            if (action.isFromCalendar) {  // 如果通过日历形式改变日期的话，那么不仅需要更新courseNums，而且需要同步更新originalCourseNums
                return { ...state, info: { ...state.info, courseNums: action.value }, originalCourseNums: action.value };
            } else {
                return { ...state, info: { ...state.info, courseNums: action.value } };
            }
        default:
            return state;
    }
}
