import apiRequest from '../../common/request';
const PREFIX = "SHENLUN_EXERCISE_DETAIL";

export const SHENLUN_INDIVIDUAL = PREFIX + "SHENLUN_INDIVIDUAL";
export const LOADING = PREFIX + "LOADING";

export const loading = () => ({
  type: LOADING,
});

export const shenlun_individual_detail = (data) => ({
  type: SHENLUN_INDIVIDUAL,
  data
});

export const individual_detail = (id, type) => {
  let url = `/gk/ops/class/exercise/getDetails/${id}/${type}`;
  let config = {
    method: 'GET', credentials: 'include', 
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
  };
  return dispatch => {
    dispatch(loading());
    apiRequest(url, config)
    .then(json => {
      dispatch(shenlun_individual_detail(json.obj));
    })
  }
};
