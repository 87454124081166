import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchQuestionAreas, fetchQuestionTypes } from '../PositionList/actions';
import { Typography, Spin, Breadcrumb, message, Select, Tooltip, Button, Card, Space, Input, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    receive_question_info,
    request_question_info,
    update_question_notice,
    update_question_major,
    update_question_maxyear,
    update_question_deptcode,
    update_question_deptname,
    update_question_number,
    update_question_positioncode,
    update_question_positionname,
    update_position_tags,
    update_question_deptmobile,
    update_question_remarks,
    update_question_positiondesc,
    update_question_sex,
    updateQuestionVideo,
    addFileInput,
    updateQuestion,
    update_question_workedyear,
    update_question_education,
    fetchEmpty,
    update_url,
    update_signedVideoUrl,
    update_years_type,
    update_province_type,
    update_positon_type,
    setReduxKeyValue
} from "./actions";
import Editor from "../../components/Editor/index";

import { checkPermission } from '../../utils/utils';
import apiRequest from '../../common/request'
import {WORK_YEAR_LIST, EDUCATION_LIST, EMPTY_INFO} from  './const.js'

import dayjs from 'dayjs';
const disabledDate = (current) => {
    return current && current >= dayjs().endOf('day');
};

const { Option } = Select;
const { Paragraph } = Typography;

class PositionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...EMPTY_INFO
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchQuestionTypes());
        if (this.props.match.params.positionId) {
            this.fetchQuestion(this.props.match.params.positionId);
        } else {
            this.props.dispatch(fetchEmpty());
        }
    }

    fetchQuestion = (positionId) => {
        this.props.dispatch(request_question_info());
        let config = {
            method: 'POST',
            body: JSON.stringify({id: this.props.match.params.positionId}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        };

        apiRequest(`/gk/ops/positions/selectPosition`, config)
            .then(json => {
                console.log('fileList', {...json.obj[0].files})
                this.props.dispatch(receive_question_info(json.obj[0]));
                if (json.obj.area) {
                    this.getProvince(json.obj.area);
                    this.getPositionType(json.obj.area)
                }
                if (json.obj.year) {
                    this.getYear(json.obj.year);
                }
            })
            .catch(error => console.log(error));
    };

    // 获取省份列表
    getProvince = value => {
        this.props.dispatch(update_province_type(value));
    }

    // 获取类型列表
    getPositionType = value => {
        this.props.dispatch(update_positon_type(value))
    }

    // 获取年份列表
    getYear = value => {
        this.props.dispatch(update_years_type(value));
    }

    handleAddFileInput = () => {
        this.props.dispatch(addFileInput({name: '', path: ''}));
    }

    uploadFile = (idx) => {
        // console.log('upload file', this[`upload-${idx-1}`].files[0])
        let file = this[`upload-${idx-1}`].files[0];
        if (file) {
            this.props.dispatch(updateQuestionVideo(file, this.props.info, idx));
        } else {
            message.warning('请先选择文件');
        }
    };

    /** 设置日期输入的值 */
    calendarValueChangeHandler = async (dateString, key) => {
        await this.props.dispatch(setReduxKeyValue({ key, value: dateString }))
    }

    modifyQuestion = () => {
        if (!this.props.info.area || !this.props.info.area.length) {
            message.warning('请选择报考地区');
            return;
        }
        if (!this.props.info.number) {
            message.warning('请输入招考人数');
            return;
        }
        if ((!this.props.info.positionName) || this.props.info.positionName.trim() === "") {
            message.warning('请输入职位名称');
            return;
        }
        if ((!this.props.info.type) || this.props.info.type.trim() === "") {
            message.warning('请输入考试类型');
            return;
        }
        // console.log('this.props.info', this.props.info)
        this.props.dispatch(updateQuestion(this.props.info));
    };

    deleteVideo = (idx) => {
        // this.props.dispatch(update_signedVideoUrl('', idx));
        this.props.dispatch(update_url('', idx));
    };

    render() {
        let { info, isUpdating, isFetching } = {...this.props};

        return (
            <Spin spinning={isUpdating || isFetching}>
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <Breadcrumb>
                        <Breadcrumb.Item>职位管理</Breadcrumb.Item>
                        <Breadcrumb.Item>职位详情</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="commonSearchBox">
                        <div className="itemSearchBox">
                            <label>职位名称</label>
                            <Input style={{ width: 250 }} placeholder="请输入职位名称" value={info.positionName} type="text" onChange={e => this.props.dispatch(update_question_positionname(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>单位名称</label>
                            <Input style={{ width: 250 }} placeholder="请输入单位名称" value={info.deptName} type="text" onChange={e => this.props.dispatch(update_question_deptname(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>年份</label>
                            <DatePicker
                                picker="year"
                                style={{ width: 150 }}
                                value={info.year ? moment(info.year, 'YYYY') : ''}
                                onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'year'); }}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>报考地区</label>
                            <Select
                                placeholder="请选择"
                                style={{ width: '150px' }}
                                dropdownMatchSelectWidth={false}
                                value={info.area}
                                onChange={(value) => { this.getProvince(value) }}
                            >
                                {this.props.areas.map((province, idx) => {
                                    return <Option key={idx} value={province}>{province}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>考试类型</label>
                            <Select
                                placeholder="请选择"
                                style={{ width: '250px' }}
                                dropdownMatchSelectWidth={false}
                                value={info.type}
                                onChange={(value) => { this.getPositionType(value) }}
                            >
                                {this.props.types.map((province, idx) => {
                                    return <Option key={idx} value={province}>{province}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>专业</label>
                            <Input style={{ width: 250 }} placeholder="请输入专业" value={info.major} type="text" onChange={e => this.props.dispatch(update_question_major(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>招考人数</label>
                            <Input style={{ width: 80 }} placeholder="请输入招考人数" value={info.number} type="text" onChange={e => this.props.dispatch(update_question_number(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>年龄要求</label>
                            <Input style={{ width: 150 }} placeholder="请输入年龄要求" value={info.maxYear} type="text" onChange={e => this.props.dispatch(update_question_maxyear(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>学历要求</label>
                            <Select style={{ width: 150 }} placeholder="请选择" value={info.education} onChange={value => this.props.dispatch(update_question_education(value))}>
                                {/* <Option value="">请选择</Option> */}
                                {EDUCATION_LIST.map((i, idx) => {
                                    return <Option key={idx} value={i.value}>{i.name}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>基层服务要求</label>
                            <Select style={{ width: 150 }} placeholder="请选择" value={info.workedYearLevel} onChange={value => this.props.dispatch(update_question_workedyear(value))}>
                                {/* <Option value="">请选择</Option> */}
                                {WORK_YEAR_LIST.map((i, idx) => {
                                    return <Option key={idx} value={i.value}>{i.name}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>性别要求</label>
                            <Select style={{ width: 150 }} value={info.conditionSex} onChange={value => this.props.dispatch(update_question_sex(value))}>
                                <Option value="">请选择</Option>
                                <Option value={'不限'}>不限</Option>
                                <Option value={'男'}>男</Option>
                                <Option value={'女'}>女</Option>
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>单位代码</label>
                            <Input style={{ width: 150 }} placeholder="请输入单位代码" value={info.deptCode} type="text" onChange={e => this.props.dispatch(update_question_deptcode(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>职位代码</label>
                            <Input style={{ width: 150 }} placeholder="请输入职位代码" value={info.positionCode} type="text" onChange={e => this.props.dispatch(update_question_positioncode(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>职位Tags</label>
                            <Input style={{ width: 500 }} placeholder="使用英文逗号,分隔" value={info.tags} type="text" onChange={e => this.props.dispatch(update_position_tags(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>联系电话</label>
                            <Input style={{ width: 150 }} placeholder="请输入联系电话" value={info.deptMobile} type="text" onChange={e => this.props.dispatch(update_question_deptmobile(e.target.value))} />
                        </div>
                        <div className="itemSearchBox">
                            <label>报名开始时间</label>
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: 185 }}
                                showTime
                                value={info.applyStartTime ? moment(info.applyStartTime, 'YYYY-MM-DD HH:mm:ss') : ''}
                                onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'applyStartTime'); }}
                            />
                        </div>
                        <div className="itemSearchBox">
                            <label>报名截止时间</label>
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: 185 }}
                                showTime
                                value={info.applyEndTime ? moment(info.applyEndTime, 'YYYY-MM-DD HH:mm:ss') : ''}
                                onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'applyEndTime'); }}
                            />
                        </div>
                    </div>

                    <Card size="small" title="职位介绍">
                        <Input.TextArea value={info.positionDesc || ''} onChange={e => this.props.dispatch(update_question_positiondesc(e.target.value))} placeholder="请输入职位介绍" rows="4" />
                    </Card>
                    <Card size="small" title="备注">
                        <Input.TextArea value={info.remarks || ''} onChange={e => this.props.dispatch(update_question_remarks(e.target.value))} placeholder="请输入备注" rows="4" />
                    </Card>

                    <Card size="small" title="职位公告">
                        <Editor
                            content={info.notice || ''}
                            onChange={value => this.props.dispatch(update_question_notice(value))}
                        />
                    </Card>
                </div>

                <div className="commonsubmit">
                    <div className="cardR" style={{ height: '88vh', overflow: 'auto' }}>
                        <Card size="small" title="附件" extra={<Tooltip title="增加附件"><Button shape="circle" icon={<PlusOutlined />} onClick={this.handleAddFileInput}/></Tooltip>}>
                            <Spin spinning={this.props.isVideoUpdating} tip="上传中...">
                            {info.files?.map((item, idx) => {
                    return <Space key={idx} direction="vertical" style={{ borderTop: idx > 0 ? '1px solid #ccc' : '', paddingTop: idx > 0 ? '10px' : '', marginTop: idx > 0 ? '15px' : ''}}>
                        {info.files.length > 1 && <div>第{idx + 1}个附件：</div>}
                        {item.name && <Paragraph style={{fontSize: '12px'}} copyable={{ text: item.path }}>{item.name}</Paragraph>}
                        {checkPermission('sys:question:edit') && <div>
                            <input type="file" ref={(element) => { this[`upload-${idx}`] = element; }} style={{ margin: '10px 0' }} />
                            {(item.name || idx > 0) && <Button style={{ marginBottom: 10 }} type="danger" onClick={() => this.deleteVideo(idx+1)}>删除附件</Button>}
                            <br />
                            <Button type="primary" onClick={() => this.uploadFile(idx+1)}>上传本地文件</Button>
                        </div>}
                    </Space>
                                })}
                            </Spin>
                        </Card>
                        {checkPermission('sys:question:edit') && <Card size="small" title="保存">
                            <Button disabled={isUpdating} type="primary" onClick={this.modifyQuestion}>保存</Button>
                        </Card>}
                    </div>
                </div>
            </div>
            </Spin>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        isUpdating,
        isVideoUpdating,
        info,
    } = state.selectedPosition;

    const {
        areas,
        types
    } = state.positionList;

    return {
        areas,
        types,
        isFetching,
        isUpdating,
        isVideoUpdating,
        info,
    };
}

export default connect(mapStatetoProps)(PositionInfo);
