import apiRequest from '../../common/request';
import Cookies from 'js-cookie';
import {message} from 'antd';

const PREFIX = 'CONTRACT_ORDER_ACTION';

export const SEARCH_USER_INFO = PREFIX + 'SEARCH_USER_INFO';
export const USER_HANDLER = PREFIX + 'USER_HANDLER';
export const GET_APPLICATION_AREA = PREFIX + 'GET_APPLICATION_AREA';
export const SET_CONTRACT_INFO = PREFIX + 'SET_CONTRACT_INFO';
export const CHANGE_CURRENT_AREA = PREFIX + 'CHANGE_CURRENT_AREA';
export const RESET_CURRENT_PAGE = PREFIX + 'RESET_CURRENT_PAGE';
export const SET_CURRENT_FILTER_STATUS = PREFIX + 'SET_CURRENT_FILTER_STATUS';
export const GET_SALE_INFORM = PREFIX + 'GET_SALE_INFORM';
export const GET_HEAD_TEACHER_INFORM = PREFIX + 'GET_HEAD_TEACHER_INFORM';
export const MODIFY_CURRENT_PAGE = PREFIX + 'MODIFY_CURRENT_PAGE';
export const MODIFY_CURRENT_PAGESIZE = PREFIX + 'MODIFY_CURRENT_PAGESIZE';
export const MODIFY_CURRENT_SALES = PREFIX + 'MODIFY_CURRENT_SALES';
export const MODIFY_CURRENT_HEAD_TEACHER = PREFIX + 'MODIFY_CURRENT_HEAD_TEACHER';
export const RESET_SEARCH_CRITERIA = PREFIX + 'RESET_SEARCH_CRITERIA';
export const GET_OPERATION_INFORM = PREFIX + 'GET_OPERATION_INFORM';
export const GET_TEACHER_INFORM = PREFIX + 'GET_TEACHER_INFORM';
export const LOADING_CONTRACT = PREFIX + 'LOADING_CONTRACT';
export const SET_CONTRACT_LOADING = PREFIX + 'SET_CONTRACT_LOADING';
export const CONTRACT_NO = PREFIX + 'CONTRACT_NO';

export const resetSearchCriteria = () => ({ type: RESET_SEARCH_CRITERIA });

export const modifyCurrentHeadTeacher = value => ({ type: MODIFY_CURRENT_HEAD_TEACHER, value });

export const modifyCurrentSales = value => ({ type: MODIFY_CURRENT_SALES, value });

export const modifyCurrentPageSize = value => ({ type: MODIFY_CURRENT_PAGESIZE, value });

export const modifyCurrentPage = value => ({ type: MODIFY_CURRENT_PAGE, value });

export const userHandler = data => ({ type: USER_HANDLER, data });

export const contractNoHandler = data => ({ type: CONTRACT_NO, data });//合同编号改变

export const resetCurrentPage = value => ({ type: RESET_CURRENT_PAGE, value });

export const changeCurrentArea = value => ({ type: CHANGE_CURRENT_AREA, value });

export const setCurrentFilterStatus = data => ({ type: SET_CURRENT_FILTER_STATUS, key: data.key, value: data.value });

const search_user_info = (data, userArr) => {
    if (userArr) {
        for (let single of data) {
            for (let userIdx in userArr) {
                if (userArr[userIdx].id == single.id) {
                    userArr.splice(userIdx, 1);
                }
            }
        }
        data = userArr.concat(data);
        return {
            type: SEARCH_USER_INFO,
            data
        }
    } else {
        return {
            type: SEARCH_USER_INFO,
            data
        }
    }
};

const get_application_area = data => ({ type: GET_APPLICATION_AREA, data });

const set_contract_info = data => ({ type: SET_CONTRACT_INFO, data });

const get_sale_inform = data => ({ type: GET_SALE_INFORM, data });

const get_head_teacher_inform = data => ({ type: GET_HEAD_TEACHER_INFORM, data });

const get_operation_inform = data => ({ type: GET_OPERATION_INFORM, data });

const get_teacher_inform = data => ({ type: GET_TEACHER_INFORM, data });

const loading_contract = () => ({ type: LOADING_CONTRACT });

const set_contract_loading = () => ({ type: SET_CONTRACT_LOADING });

/** 获取报考地区 */
export const fetchApplicationArea = () => {
    let url = `/gk/ops/contract/examArea/list`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            dispatch(get_application_area(json.obj));  
        })
        .catch(error => console.log(error));
    }
};

/** 获取部门列表 */
export const fetchDepartmentList = (department, name) => {
    let url = `/gk/ops/employee/selectEmployee`
    let data = { department, name };
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return async dispatch => {
        let json = await apiRequest(url, config);
        if (department == '销售部') {
            dispatch(get_sale_inform(json.obj));
        } else if (department == '运营部') {
            dispatch(get_operation_inform(json.obj));
        } else { //全程部师资堂合并
            dispatch(get_teacher_inform(json.obj));
            dispatch(get_head_teacher_inform(json.obj));
        }
    }
};

export const searchUserInfo = (value, userArr) => {
    let url = `/gk/ops/userInfo/getByKey/${value}`;
    let config = {
        mothod: 'GET', credentials: 'include', 
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    };
    return dispatch => {
        apiRequest(url, config)
        .then(json => {
            dispatch(search_user_info(json.obj, userArr));  
        })
        .catch(error => console.log(error));
    }
};

/** 获取合同信息列表 */
export const fetchContractOrderUser = data => {
    let url = `/gk/ops/contract/page`;
    let config = {
        method: 'POST', credentials: 'include',
        headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(loading_contract());
        return apiRequest(url, config).then(json => {
            dispatch(set_contract_info(json.obj));
        }).catch(err => {
            dispatch(set_contract_loading());
        })
    }
};

/** 导出合同订单Excel */
export const exportContractOrder = data => {
    let url = `/gk/ops/contract/excel/exportUserContract`;
    let config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Sin-Access-Token': Cookies.get('accessToken'),
          'Sin-UserAgent-Sign': 'SintureGK-WEB',
          'Sin-Client-Type': 3,
          Accept: '*/*',
        },
        body: JSON.stringify(data),
        credentials: 'include'
      };
      return dispatch => {
          fetch(url, config).then(response => {
              response.blob().then(blob => {
                  let a = document.createElement('a');
                  let url = window.URL.createObjectURL(blob);
                  a.href = url;
                  let fileName = "用户合同数据.xls";
                  a.download = fileName;
                  document.body.appendChild(a).click();
                  window.URL.revokeObjectURL(url);
              })
          }).catch(err => {
              message.error('下载出错');
          })
      }
}