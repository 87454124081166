import React from 'react';
import apiRequest from '../../common/request'
import { Input, Select, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { openModleAction } from '../../containers/Inquiry/action';
import { addOrChangeUserInfo, fetchBirthPlace } from '../../containers/StudentInfo/action';
const Option = Select.Option;
const { TextArea } = Input;

const sexList = ['男', '女'];
const statusList = ['在职', '全职', '应届生'];
class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userObj: null,
            name: null,                   // 用户姓名
            sex: null,                    // 性别
            wechat: null,                 // 性别
            mobile: null,                 // 手机号
            mobile2: null,                // 紧急联系人
            birthPlace: null,             // 生源地
            status: null,                 // 在职/全职/应届
            isTrained: null,              // 是否上过培训
            examsNum: null,               // 考试次数
            remarks: null,                // 备注
            id: null,                     // 学员id
        };
    }

    async componentDidMount() {
        let { userModle } = this.props;
        await this.props.dispatch(fetchBirthPlace());
        let userObj = await this.getUserInfo(userModle.id);
        this.setState({
            userObj,
            name: userObj.name,
            sex: userObj.sex,
            wechat: userObj.wechat,
            mobile: userObj.mobile,
            mobile2: userObj.mobile2,
            birthPlace: userObj.birthPlace,
            status: userObj.status,
            examsNum: userObj.examsNum,
            remarks: userObj.remarks,
            id: userObj.id,
            isTrained: userObj.isTrained,
        });
    }

    getUserInfo = async (id) => {
        let url = `/gk/ops/userInfo/getById/${id}`;
        let json = await apiRequest(url, { method: 'GET' })
        if (json.status != 1) {
            message.error(json.errorMes);
        }
        return json.obj;
    }

    /** 点击确定 */
    handleOk = async () => {
        if (this.props.notUpdate) return this.props.dispatch(openModleAction({ key: 'userModle', value: { open: false } }));
        let myreg = /^1[3456789]\d{9}$/;
        const { name, wechat, mobile, isTrained, birthPlace, sex, status, examsNum, mobile2, remarks, id } = this.state;
        if (!name) {
            message.warn('请输入姓名');
            return;
        };
        if (!wechat) {
            message.warn('请输入微信号');
            return;
        };
        if (!mobile) {
            message.warn('请输入手机号');
            return;
        };
        if (mobile) {
            if ((mobile.indexOf('*') == -1)) {
                if (!myreg.test(mobile)) {
                    message.warn("手机号码有误，请重填");
                    return;
                }
            }
        };
        if (mobile2) {
            if ((mobile2.indexOf('*') == -1)) {
                if (!myreg.test(mobile2)) {
                    message.warn("紧急手机号码有误，请重填");
                    return;
                }
            }
        };
        if (!birthPlace) {
            message.warn('请选择生源地');
            return;
        };
        if (!sex) {
            message.warn('请选择性别');
            return;
        };
        if (!status) {
            message.warn('请选择在职/全职/应届');
            return;
        };
        if (isTrained == null) {
            message.warn('请选择是否上过培训');
            return;
        };
        if (examsNum == null) {
            message.warn('请输入考试次数');
            return;
        };
        let params = {
            id, name, wechat, mobile, birthPlace, sex, status, examsNum, isTrained
        };
        isTrained && (isTrained == '是' ? params.isTrained = 1 : params.isTrained = 0);
        mobile2 && (params.mobile2 = mobile2);
        remarks && remarks.trim().length && (params.remarks = remarks);
        const successCb = () => {
            this.props.updeteCb();
            message.success('操作成功');
        };
        await this.props.dispatch(addOrChangeUserInfo(params, successCb, false, this.errMessageHandler));
        await this.props.dispatch(openModleAction({ key: 'userModle', value: { open: false } }));
    };

    /** 错误回调 */
    errMessageHandler = errorMes => {
        message.error(errorMes);
    };

    /** 关闭蒙版 */
    handleCancel = () => {
        this.props.dispatch(openModleAction({ key: 'userModle', value: { open: false } }));
    };

    /** 文本值更新 */
    classInputHandler = (value, key) => {
        this.setState({ [key]: value });
    };

    render() {
        const { notUpdate, birthPlaces, userModle } = this.props;
        let data = this.state.userObj;
        return (
            <div>
                <Modal title="学生详情" visible={userModle.open} onOk={this.handleOk} onCancel={this.handleCancel}>
                    {data &&
                        (notUpdate &&
                            <div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>姓名</b><span>{this.state.name}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>性别</b><span>{this.state.sex}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>微信号</b><span>{this.state.wechat}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>手机号</b><span>{this.state.mobile}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>紧急联系人</b><span>{this.state.mobile2 || '未填'}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>生源地</b><span>{this.state.birthPlace}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>在职/全职/应届</b><span>{this.state.status}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>是否上过培训</b><span>{this.state.isTrained && '是' || '否'}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>考试次数</b><span>{this.state.examsNum}</span></div>
                                <div><b style={{display: 'inline-block', width: '110px'}}>备注</b><span>{this.state.remarks || '暂无'}</span></div>
                            </div>
                            || <div>
                                <div className="commonSearchBox" style={{ padding: 0, margin: 0 }}>
                                    <div>
                                        <label>姓名<span style={{ color: 'red' }}>*</span></label>
                                        <Input
                                            placeholder="请输入姓名"
                                            value={this.state.name}
                                            onChange={event => { this.classInputHandler(event.target.value, 'name') }}
                                            style={{ width: 150 }}
                                        />
                                    </div>
                                    <div>
                                        <label>性别<span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: 150 }}
                                            onChange={value => { this.classInputHandler(value, 'sex') }}
                                            value={this.state.sex}
                                        >
                                            {
                                                sexList.map((item, index) => (
                                                    <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div>
                                        <label>微信号<span style={{ color: 'red' }}>*</span></label>
                                        <Input
                                            placeholder="请输入微信号"
                                            style={{ width: 150 }}
                                            value={this.state.wechat}
                                            onChange={event => { this.classInputHandler(event.target.value, 'wechat') }}
                                        />
                                    </div>
                                    <div>
                                        <label>生源地<span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: 150 }}
                                            onChange={value => { this.classInputHandler(value, 'birthPlace') }}
                                            value={this.state.birthPlace}
                                        >
                                            {
                                                birthPlaces.map((item, index) => (
                                                    <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div>
                                        <label>手机号<span style={{ color: 'red' }}>*</span></label>
                                        <Input
                                            placeholder="请输入手机号"
                                            style={{ width: 150 }}
                                            value={this.state.mobile}
                                            onChange={event => { this.classInputHandler(event.target.value, 'mobile') }}
                                        />
                                    </div>
                                    <div>
                                        <label>紧急联系</label>
                                        <Input
                                            placeholder="紧急联系人手机号"
                                            style={{ width: 150 }}
                                            value={this.state.mobile2}
                                            onChange={event => { this.classInputHandler(event.target.value, 'mobile2') }}
                                        />
                                    </div>
                                    <div>
                                        <label>身份类型<span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="在职/全职/应届"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: 150 }}
                                            onChange={value => { this.classInputHandler(value, 'status') }}
                                            value={this.state.status}
                                        >
                                            {
                                                statusList.map((item, index) => (
                                                    <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div>
                                        <label>考试次数<span style={{ color: 'red' }}>*</span></label>
                                        <Input
                                            placeholder="请输入考试次数"
                                            style={{ width: 150 }}
                                            value={this.state.examsNum}
                                            onChange={event => { this.classInputHandler(event.target.value, 'examsNum') }}
                                        />
                                    </div>
                                    <div>
                                        <label>是否上过培训<span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: 150 }}
                                            onChange={value => { this.classInputHandler(value, 'isTrained') }}
                                            value={this.state.isTrained}
                                        >
                                            <Option value={1}>是</Option>
                                            <Option value={0}>否</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="mt10">
                                    <label className="remark">备注</label>
                                    <br />
                                    <TextArea
                                        maxLength="500"
                                        autosize={{ minRows: 3 }}
                                        value={this.state.remarks || ''}
                                        onChange={event => { this.classInputHandler(event.target.value, 'remarks') }}
                                        style={{ width: '90%' }}
                                    />
                                </div></div>)
                        || <p>加载中...</p>
                    }
                </Modal>
            </div>
        );
    }
}

function mapStatetoProps(state) {
    const {
        userModle
    } = state.inquiryReducer;
    const {
        birthPlaces,
    } = state.studentInfo;

    return {
        userModle,
        birthPlaces,
    };
}

export default connect(mapStatetoProps)(UserInfo);