import apiRequest from '../../common/request'
import { message } from 'antd';
import OSS from 'ali-oss';

const PREFIX = "_INTERVIEW_INFO_";

export const REQUEST_UPDATE_QUESTION = PREFIX + "REQUEST_UPDATE_QUESTION";
export const REQUEST_UPDATE_QUESTION_VIDEO = PREFIX + "REQUEST_UPDATE_QUESTION_VIDEO";
export const REQUEST_QUESTION_INFO = PREFIX + "REQUEST_QUESTION_INFO";
export const RECEIVE_QUESTION_INFO = PREFIX + "RECEIVE_QUESTION_INFO";
export const RECEIVE_UPDATE_QUESTION_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_RESULT";
export const RECEIVE_UPDATE_QUESTION_VIDEO_RESULT = PREFIX + "RECEIVE_UPDATE_QUESTION_VIDEO_RESULT";

export const UPDATE_QUESTION_SOURCE = PREFIX + "UPDATE_QUESTION_SOURCE";
export const UPDATE_QUESTION_SUITCROWDS = PREFIX + "UPDATE_QUESTION_SUITCROWDS";
export const ADD_QUESTION_QUESTION = PREFIX + "ADD_QUESTION_QUESTION";
export const REMOVE_QUESTION_QUESTION = PREFIX + "REMOVE_QUESTION_QUESTION";

export const UPDATE_QUESTION_JOINMATERIAL = PREFIX + "UPDATE_QUESTION_JOINMATERIAL";
export const UPDATE_QUESTION_DESCRIPTION = PREFIX + "UPDATE_QUESTION_DESCRIPTION";
export const UPDATE_QUESTION_SOLUTION = PREFIX + "UPDATE_QUESTION_SOLUTION";
export const UPDATE_QUESTION_YEAR = PREFIX + "UPDATE_QUESTION_YEAR";
export const UPDATE_QUESTION_AREA = PREFIX + "UPDATE_QUESTION_AREA";
export const UPDATE_QUESTION_QUESTYPE = PREFIX + "UPDATE_QUESTION_QUESTYPE";
export const UPDATE_QUESTION_DISPLAYTITLE = PREFIX + "UPDATE_QUESTION_DISPLAYTITLE";
export const UPDATE_QUESTION_MINDMAP = PREFIX + "UPDATE_QUESTION_MINDMAP";
export const UPDATE_QUESTION_QUESCATE = PREFIX + "UPDATE_QUESTION_QUESCATE";
export const UPDATE_QUESTION_TAG = PREFIX + "UPDATE_QUESTION_TAG";
export const UPDATE_RECRUIT_TYPE = PREFIX + "UPDATE_RECRUIT_TYPE";
export const UPDATE_SCHOOL = PREFIX + "UPDATE_SCHOOL";

export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const CHANGE_COMMON = PREFIX + "CHANGE_COMMON";
export const RECEIVE_INTERVIEW_CONDITION = PREFIX + "RECEIVE_INTERVIEW_CONDITION";

export const UPDATE_VIDEO_PROGRESS = "UPDATE_VIDEO_PROGRESS";

const TYPE_ID = [524, 334, 525]
const TYPE = ['人际关系', '综合分析', '危机处理'];


export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

export const changeCommon = data => ({
    type: CHANGE_COMMON,
    key: data.key,
    val: data.val
});

function receive_interview_condition(condition){
    return {
        type: RECEIVE_INTERVIEW_CONDITION,
        condition
    }
}

export function update_school(value) {
    return {
        type: UPDATE_SCHOOL,
        key: 'school',
        value
    }
}

export function update_recruit_type(value) {
    return {
        type: UPDATE_RECRUIT_TYPE,
        key: 'type',
        value
    }
}

export function update_question_tag(value) {
    return {
        type: UPDATE_QUESTION_TAG,
        key: 'questionTag',
        value
    }
}

export function request_question_info() {
    return {
        type: REQUEST_QUESTION_INFO
    }
}

export function receive_question_info(question) {
    return {
        type: RECEIVE_QUESTION_INFO,
        question,
        receivedAt: Date.now()
    }
}

function request_update_question() {
    return {
        type: REQUEST_UPDATE_QUESTION
    }
}

function request_update_question_video() {
    return {
        type: REQUEST_UPDATE_QUESTION_VIDEO
    }
}

function receive_update_question_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_RESULT
    }
}

function receive_update_question_video_result() {
    return {
        type: RECEIVE_UPDATE_QUESTION_VIDEO_RESULT
    }
}

export function update_question_displaytitle(value) {
    return {
        type: UPDATE_QUESTION_DISPLAYTITLE,
        key: 'title',
        value
    }
}

export function update_question_source(value) {
    return {
        type: UPDATE_QUESTION_SOURCE,
        key: 'source',
        value
    }
}

export function update_question_suitCrowds(value) {
    return {
        type: UPDATE_QUESTION_SUITCROWDS,
        key: 'suitCrowds',
        value
    }
}

export function update_video_progress(value) {
    return {
        type: UPDATE_VIDEO_PROGRESS,
        value
    }
}

export function add_question_question() {
    return {
        type: ADD_QUESTION_QUESTION,
    }
}

export function remove_question_question(index) {
    return {
        type: REMOVE_QUESTION_QUESTION,
        index
    }
}

export function update_question_quesCate(value, index) {
    return {
        type: UPDATE_QUESTION_QUESCATE,
        key: 'categoryId',
        value,
        index
    }
}

export function update_question_description(value, index) {
    return {
        type: UPDATE_QUESTION_DESCRIPTION,
        key: 'question',
        value,
        index
    }
}

export function update_question_joinmaterial(value, index) {
    return {
        type: UPDATE_QUESTION_JOINMATERIAL,
        key: 'material',
        value,
        index
    }
}

export function update_question_solution(value, index) {
    return {
        type: UPDATE_QUESTION_SOLUTION,
        key: 'solution',
        value,
        index
    }
}

export function update_question_year(value) {
    return {
        type: UPDATE_QUESTION_YEAR,
        key: 'year',
        value
    }
}

export function update_question_area(value) {
    return {
        type: UPDATE_QUESTION_AREA,
        key: 'area',
        value
    }
}

export function update_question_quesType(value, index) {
    return {
        type: UPDATE_QUESTION_QUESTYPE,
        key: 'type',
        value,
        index
    }
}

export function fetchEmpty(value, index) {
    return {
        type: RECEIVE_QUESTION_INFO,
        receivedAt: Date.now(),
        question:{
            title: '',
            year: "",
            type: '',
            school: "",
            questionTag: 1,
            questions: [{categoryId: '', type:"-1",material:"",question:"",solution:""}]
        }
    }
}

export function updateQuestionVideo(file, info){

    return dispatch => {
        dispatch(request_update_question_video());
        return apiRequest("/gk/ops/sts/getToken?type=oss",{method: 'GET',credentials: 'include'})
            .then(json => {
              let result = json.obj;
              let client = new OSS({
                  accessKeyId: result.accessKeyId,
                  accessKeySecret: result.accessKeySecret,
                  stsToken: result.securityToken,
                  endpoint: 'https://oss-cn-beijing.aliyuncs.com',
                  bucket: 'gk-course'
              });

              client.multipartUpload('/shenlun/'+info.categoryId+"/"+file.name, file, {
                  progress: function (p) {
                    return function (done) {
                      dispatch(update_video_progress(p*100+"%"));
                      done();
                    };
                  },
              }).then((result) => {
                  dispatch(receive_update_question_video_result());
                  dispatch(updateVideoUrl(info.id, result.name));
              }).catch(function (err) {
                  dispatch(receive_update_question_video_result());
                  message.error('上传失败');
                  console.log(err);
              });
            })
            .catch(error => console.log(error));
    }
}

function updateVideoUrl(id, videoUrl) {
    if(videoUrl.startsWith("/")) videoUrl = videoUrl.substring(1)
    return dispatch => {
        return apiRequest(`/gk/ops/question/updateVideoUrl?id=${id}&videoUrl=${videoUrl}`, {method: 'GET',credentials: 'include'})
                .then(json => {
                  dispatch(receive_update_question_video_result());
                  message.success('操作成功');
                })
                .catch(error => {
                  dispatch(receive_update_question_video_result());
                  console.log(error)
                });
    }
}

/** 删除无效字段 */
function deleteUseless(obj) {
    if (Object.prototype.toString.call(obj) !== "[object Object]") return;
    Object.keys(obj).map(key => {
        if (!obj[key]) delete obj[key];
        if (Object.prototype.toString.call(obj[key]) === "[object Array]" && obj[key].length === 0) delete obj[key];
    });
}

/** 字段具有有效值的话，才加入进去 */
function checkUseful(key, source, obj) {
    if (source[key]) {
        obj[key] = source[key];
    }
}

export function updateQuestion(questionInfo) {
    let data = {};
    let keys = [ 'id', 'questionTag', 'school', 'title', 'type', 'year', 'categoryId', 'material', 'suitCrowds', 'source', 'solution', ];
    keys.map(key => { checkUseful(key, questionInfo, data); });
    data.questions = questionInfo.questions.map((item, index) => {
        // delete item['detailMaterials'];
        // delete item['materialStr'];
        // delete item['type'];
        deleteUseless(item);
        return item
    });
    // data.type = 'INTERVIEW';

    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_question());
        return apiRequest("/gk/ops/interview/saveOrUpdate", config)
            .then(json => {
              dispatch(receive_update_question_result());
              message.success('操作成功');
            })
            .catch(error => {
              dispatch(receive_update_question_result())
              console.log(error)
            });
    }
}

export function fetchInterview(id) {
    if (id)
        return dispatch => {
            return apiRequest(`/gk/ops/interview/details/${id}`, {credentials: 'include'})
                .then(json => {
                  let question = {...json.obj}
                  if (question){
                      dispatch(receive_question_info(question))
                  }
                }).catch(error => console.log(error));
        }

}

/** 面试分类列表 */
export function fetchInterviewCondition(){
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
      apiRequest('/gk/ops/interview/condition', config)
        .then(json => {
            dispatch(receive_interview_condition(json.obj));
        })
        .catch(error => console.log(error));
    }
}
