import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Input, Select, Modal, message } from 'antd';
import {
    fetchBirthPlace,
    changeOriginPlace,
    fetchInformation,
    fetchContractUserInfo,
    setReduxKeyValue,
    clearSearch,
    changeCurrentPage,
    resetCurrentPage,
    changePageSize,
    addOrChangeUserInfo
} from './action';
import { checkPermission } from '../../utils/utils';
import './index.scss';

const Option = Select.Option;
const { TextArea } = Input;

const sexList = ['男', '女'];
const isTrainedList = ['是', '否'];
const statusList = ['在职', '全职', '应届生'];
class StudentInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,             // 是否显示编辑弹框
            name: null,                   // 用户姓名
            sex: null,                    // 性别
            wechat: null,                 // 性别
            mobile: null,                 // 手机号
            mobile2: null,                // 紧急联系人
            birthPlace: null,             // 生源地
            status: null,                 // 在职/全职/应届
            isTrained: null,              // 是否上过培训
            examsNum: null,               // 考试次数
            remarks: null,                // 备注
            id: null,                     // 学员id
        };
    };

    componentDidMount() {
        this.getContractUserInfo();
        this.props.dispatch(fetchBirthPlace());
    };

    /** 获取合同用户数据 */
    getContractUserInfo = () => {
        let data = this.currentInformation();
        this.props.dispatch(fetchContractUserInfo(data));
    };

    conditionsChinese = {
        name: '姓名', mobile: '手机号', wechat: '微信号'
    };

    inputConditions = ['name', 'mobile', 'wechat'];

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>学员信息</span>
            <span className="flexg"></span>
            <div>
                {checkPermission('user:info:edit') &&
                    <Button type="primary" className="mr10" onClick={this.addUserInfo}>新增学员信息</Button>
                }
            </div>
        </div>
    );

    /** 当前用户信息 */
    currentInformation = () => {
        const { birthPlace, name, wechat, mobile } = this.props;
        let params = {
            page: this.props.page,
            pageSize: this.props.pageSize,
        }
        birthPlace !== '全部' && (params.birthPlace = birthPlace);
        mobile && (params.mobile = mobile);
        name && (params.name = name);
        wechat && (params.wechat = wechat);
        return params;
    };

    /** 导出合同用户信息Excel */
    exportInformation = () => {
        let data = this.currentInformation();
        this.props.dispatch(fetchInformation(data));
    };

    /** 渲染input类型相关的筛选数据 */
    renderInputsStatus = () => {
        return this.inputConditions.map((key, idx) => (
            <div key={idx}>
                <label>{this.conditionsChinese[key]}</label>
                <Input
                    style={{ width: 150 }}
                    onPressEnter={() => this.searchContractUser()}
                    placeholder={`请输入学员${this.conditionsChinese[key]}`}
                    value={this.props[key]}
                    onChange={event => this.props.dispatch(setReduxKeyValue({ key, value: event.target.value }))}
                />
            </div>
        ));
    };

    searchContractUser = async () => {
        await this.resetActivePage1();
        this.getContractUserInfo();
    };

    resetSearch = async () => {
        await this.props.dispatch(clearSearch());
        this.getContractUserInfo();
    };

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(resetCurrentPage(1));
    };

    /** 分页组件改变页码 */
    paginationChange = async page => {
        await this.props.dispatch(changeCurrentPage(page));
        this.getContractUserInfo();
    };

    /** 分页组件改变每页条数 */
    showSizeChange = async (current, size) => {
        await this.resetActivePage1();
        await this.props.dispatch(changePageSize(size));
        this.getContractUserInfo();
    };

    /** 更改生源地 */
    changeBirthPlaceHandler = async value => {
        await this.props.dispatch(changeOriginPlace(value));
        await this.resetActivePage1();
        this.getContractUserInfo();
    };

    /** 新增学员信息页面 */
    addUserInfo = () => {
        this.setState({ showModal: true })
    };

    classInputHandler = (value, key) => {
        this.setState({ [key]: value });
    };

    /** 确定新增或修改学员信息 */
    handleOk = () => {
        let myreg = /^1[3456789]\d{9}$/;
        const { name, wechat, mobile, isTrained, birthPlace, sex, status, examsNum, mobile2, remarks, id } = this.state;
        if (!name) {
            message.warn('请输入姓名');
            return;
        };
        if (!wechat) {
            message.warn('请输入微信号');
            return;
        };
        if (!mobile) {
            message.warn('请输入手机号');
            return;
        };
        if (mobile) {
            if ((mobile.indexOf('*') == -1)) {
                if (!myreg.test(mobile)) {
                    message.warn("手机号码有误，请重填");
                    return;
                }
            }
        };
        if (mobile2) {
            if ((mobile2.indexOf('*') == -1)) {
                if (!myreg.test(mobile2)) {
                    message.warn("紧急手机号码有误，请重填");
                    return;
                }
            }
        };
        if (!birthPlace) {
            message.warn('请选择生源地');
            return;
        };
        if (!sex) {
            message.warn('请选择性别');
            return;
        };
        if (!status) {
            message.warn('请选择在职/全职/应届');
            return;
        };
        if (!isTrained) {
            message.warn('请选择是否上过培训');
            return;
        };
        if (examsNum == null) {
            message.warn('请输入考试次数');
            return;
        };
        let params = {
            name, wechat, mobile, birthPlace, sex, status, examsNum, isTrained
        };
        isTrained && (isTrained == '是' ? params.isTrained = 1 : params.isTrained = 0);
        mobile2 && (params.mobile2 = mobile2);
        remarks && (params.remarks = remarks);
        id && (params.id = id);
        const successCb = () => {
            this.setState({
                showModal: false,
                name: null,
                sex: null,
                wechat: null,
                mobile: null,
                mobile2: null,
                birthPlace: null,
                status: null,
                isTrained: null,
                examsNum: null,
                remarks: null,
            });
            message.success('操作成功');
        };
        this.props.dispatch(addOrChangeUserInfo(params, successCb, this.getContractUserInfo, this.errMessageHandler));
    };

    errMessageHandler = errorMes => {
        message.error(errorMes);
    };

    /** 修改用户信息 */
    updateUserInfo = (userObj = {}) => {
        if (userObj.isTrained == 1) this.setState({ isTrained: '是' });
        else this.setState({ isTrained: '否' })
        this.setState({
            name: userObj.name,
            sex: userObj.sex,
            wechat: userObj.wechat,
            mobile: userObj.mobile,
            mobile2: userObj.mobile2,
            birthPlace: userObj.birthPlace,
            status: userObj.status,
            examsNum: userObj.examsNum,
            remarks: userObj.remarks,
            id: userObj.id,
            showModal: true
        });
    };

    /** 用户新增合同订单 */
    addContractInfo = record => {
        let url = `/addOrmodify?userInfoId=${record.id}&name=${record.name}&phone=${record.mobile}`;
        this.props.history.push(url);
    }

    /** 关闭弹窗 */
    modalCancelHandler = () => {
        this.setState({
            showModal: false,
            name: null,
            sex: null,
            wechat: null,
            mobile: null,
            mobile2: null,
            birthPlace: null,
            status: null,
            isTrained: null,
            examsNum: null,
            remarks: null,
        });
    };

    /** 动态生成table表格 */
    generateColumns() {
        const columns = [
            {
                title: '姓名',
                width: '8%',
                dataIndex: 'name',
            },
            {
                title: '性别',
                width: '5%',
                dataIndex: 'sex',
            },
            {
                title: '微信号',
                width: '10%',
                dataIndex: 'wechat',
            },
            {
                title: '手机号',
                width: '10%',
                dataIndex: 'mobile',
            },
            {
                title: '紧急联系人',
                width: '10%',
                dataIndex: 'mobile2',
            },
            {
                title: '生源地',
                width: '8%',
                dataIndex: 'birthPlace',
            },
            {
                title: '身份类型',
                width: '8%',
                dataIndex: 'status',
            },
            {
                title: '是否上过培训',
                width: '6%',
                render: record => (
                    <span>{record.isTrained == 1 ? '是' : '否'}</span>
                )
            },
            {
                title: '考试次数',
                width: '5%',
                dataIndex: 'examsNum',
            },
            {
                title: '备注',
                width: '10%',
                dataIndex: 'remarks',
            },
            {
                title: '操作',
                width: '15%',
                render: record => {
                    return (
                        <div>
                            {checkPermission('user:info:edit') && <Button size="small" type="primary" className="mr5 mb5" onClick={() => { this.updateUserInfo(record) }}>修改</Button>}
                            {checkPermission('user:contract:edit') && <Button size="small" type="primary" className="mr5 mb5" onClick={() => { this.addContractInfo(record) }}>新增合同订单</Button>}
                        </div>
                    )
                }
            }
        ];
        if (this.state.std) {
            columns.splice(2, 2)
        }
        return columns;
    }

    render() {
        let columns = this.generateColumns();
        const { birthPlaces, birthPlace, userInfo, page, pageSize, loading } = this.props;
        return (
            <div className="student-info">
                {/* 筛选区域开始 */}
                {checkPermission('user:info:page') && <div className="commonSearchBox">
                    {this.renderInputsStatus()}
                    <div>
                        <label>生源地</label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={birthPlace}
                            style={{ width: 150 }}
                            onChange={event => { this.changeBirthPlaceHandler(event) }}
                        >
                            {
                                ['全部'].concat(birthPlaces).map((item, idx) => (
                                    <Option key={idx} value={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <Button disabled={this.props.loading} type="primary" onClick={this.getContractUserInfo}>搜索</Button>
                    </div>
                    <div>
                        <Button disabled={this.props.loading} type="danger" onClick={this.resetSearch}>重置</Button>
                    </div>
                </div>}
                <Table
                    columns={columns}
                    title={this.renderHeader}
                    dataSource={userInfo.content}
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: userInfo.totalElements,
                        showTotal: total => `共有${total}条数据`,
                        onChange: this.paginationChange,
                        onShowSizeChange: this.showSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                />

                {/* 新增或修改学员信息 */}
                <Modal
                    title="新增/修改学员信息"
                    visible={this.state.showModal}
                    onOk={this.handleOk}
                    onCancel={this.modalCancelHandler}
                >
                    <div className="commonSearchBox" style={{ padding: 0, margin: 0 }}>
                        <div>
                            <label>姓名<span style={{ color: 'red' }}>*</span></label>
                            <Input
                                placeholder="请输入姓名"
                                value={this.state.name}
                                onChange={event => { this.classInputHandler(event.target.value, 'name') }}
                                style={{ width: 150 }}
                            />
                        </div>
                        <div>
                            <label>性别<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: 150 }}
                                onChange={value => { this.classInputHandler(value, 'sex') }}
                                value={this.state.sex}
                            >
                                {
                                    sexList.map((item, index) => (
                                        <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div>
                            <label>微信号<span style={{ color: 'red' }}>*</span></label>
                            <Input
                                placeholder="请输入微信号"
                                style={{ width: 150 }}
                                value={this.state.wechat}
                                onChange={event => { this.classInputHandler(event.target.value, 'wechat') }}
                            />
                        </div>
                        <div>
                            <label>生源地<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: 150 }}
                                onChange={value => { this.classInputHandler(value, 'birthPlace') }}
                                value={this.state.birthPlace}
                            >
                                {
                                    birthPlaces.map((item, index) => (
                                        <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div>
                            <label>手机号<span style={{ color: 'red' }}>*</span></label>
                            <Input
                                placeholder="请输入手机号"
                                style={{ width: 150 }}
                                value={this.state.mobile}
                                onChange={event => { this.classInputHandler(event.target.value, 'mobile') }}
                            />
                        </div>
                        <div>
                            <label>紧急联系</label>
                            <Input
                                placeholder="紧急联系人手机号"
                                style={{ width: 150 }}
                                value={this.state.mobile2}
                                onChange={event => { this.classInputHandler(event.target.value, 'mobile2') }}
                            />
                        </div>
                        <div>
                            <label>身份类型<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="在职/全职/应届"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: 150 }}
                                onChange={value => { this.classInputHandler(value, 'status') }}
                                value={this.state.status}
                            >
                                {
                                    statusList.map((item, index) => (
                                        <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div>
                            <label>考试次数<span style={{ color: 'red' }}>*</span></label>
                            <Input
                                placeholder="请输入考试次数"
                                style={{ width: 150 }}
                                value={this.state.examsNum}
                                onChange={event => { this.classInputHandler(event.target.value, 'examsNum') }}
                            />
                        </div>
                        <div>
                            <label>是否上过培训<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: 150 }}
                                onChange={value => { this.classInputHandler(value, 'isTrained') }}
                                value={this.state.isTrained}
                            >
                                {
                                    isTrainedList.map((item, index) => (
                                        <Option key={index} value={item} disabled={item == '全部'}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label className="remark">备注</label>
                        <br />
                        <TextArea
                            maxLength="500"
                            autosize={{ minRows: 3 }}
                            value={this.state.remarks || ''}
                            onChange={event => { this.classInputHandler(event.target.value, 'remarks') }}
                            style={{ width: '90%' }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStatetoProps(state) {

    const {
        ...initialState
    } = state.studentInfo;

    return {
        ...initialState
    };
}

export default connect(mapStatetoProps)(StudentInfo);