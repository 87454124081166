import apiRequest from '../../common/request'
import { message } from 'antd';

const PREFIX = "DAILY_EXAMINEINFO_";

export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";

export const REQUEST_DAILY_EXAMINE_INFO = PREFIX + "REQUEST_DAILY_EXAMINE_INFO";
export const RECEIVE_DAILY_EXAMINE_INFO = PREFIX + "RECEIVE_DAILY_EXAMINE_INFO";
export const RECEIVE_SIMPLE_DAILY_EXAMINE_INFO = PREFIX + "RECEIVE_SIMPLE_DAILY_EXAMINE_INFO";

export const FILL_EXAMINE_TITLE = PREFIX + "FILL_EXAMINE_TITLE";
export const FILL_EXAMINE_LIMIT_TIME = PREFIX + "FILL_EXAMINE_LIMIT_TIME";
export const FILL_EXAMINE_UP_TIME = PREFIX + "FILL_EXAMINE_UP_TIME";


export const REQUEST_UPDATE_EXAMINE = PREFIX + "REQUEST_UPDATE_EXAMINE";
export const RECEIVE_UPDATE_EXAMINE_RESULT_SUC = PREFIX + "RECEIVE_UPDATE_EXAMINE_RESULT_SUC";
export const RECEIVE_UPDATE_EXAMINE_RESULT_FAIL = PREFIX + "RECEIVE_UPDATE_EXAMINE_RESULT_FAIL";
export const FILL_EXAMINE_QUESTIONS = PREFIX + "FILL_EXAMINE_QUESTIONS";
export const FILL_EXAMINE_QARR = PREFIX + "FILL_EXAMINE_QARR";

function request_examine_info() {
    return {
        type: REQUEST_DAILY_EXAMINE_INFO
    }
}

function receive_examine_info(dailyExamine) {
    return {
        type: RECEIVE_DAILY_EXAMINE_INFO,
        dailyExamine,
        receivedAt: Date.now()
    }
}

function receive_simple_examine_info(dailyExamine) {
    return {
        type: RECEIVE_SIMPLE_DAILY_EXAMINE_INFO,
        dailyExamine,
        receivedAt: Date.now()
    }
}


function request_update_examine() {
    return {
        type: REQUEST_UPDATE_EXAMINE
    }
}

function receive_update_examine_result_suc() {
    return {
        type: RECEIVE_UPDATE_EXAMINE_RESULT_SUC
    }
}
function receive_update_examine_result_fail() {
    return {
        type: RECEIVE_UPDATE_EXAMINE_RESULT_FAIL
    }
}

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalPages: data.totalPages,
        receiveAt: Date.now()
    }
}

export function fill_examine_title(value) {
    return {
        type: FILL_EXAMINE_TITLE,
        key: 'title',
        value
    }
}

export function fill_examine_questions(value) {
    return {
        type: FILL_EXAMINE_QUESTIONS,
        key: 'questions',
        value
    }
}

export function fill_examine_qArr(value) {
    return {
        type: FILL_EXAMINE_QARR,
        key: 'quesArr',
        value
    }
}

export function fill_examine_limit_time(value) {
    return {
        type:FILL_EXAMINE_LIMIT_TIME,
        key:'limitTime',
        value
    }
}

export function fill_examine_up_time(value) {
    return {
        type:FILL_EXAMINE_UP_TIME,
        key:'publishDate',
        value
    }
}

export function fetchExamine(examineId, simpleExamineInfo) {
    return dispatch => {
        dispatch(request_examine_info());
        return apiRequest(`/gk/ops/dailyExercise/details/${examineId}`, {credentials: 'include'})
            .then(json => {
                //添加simpleExamineInfo的原因：我只需要取部分信息，取全部信息和子问题的函数存在bug
                //当子问题为空时，浏览器打印错误信息：TypeError: Cannot read property 'questions' of null
                if(simpleExamineInfo != undefined) {
                    dispatch(receive_simple_examine_info(json.obj));
                }else {
                    dispatch(receive_examine_info(json.obj));
                }
            })
            .catch(error => console.log(error));
    }

}


export function updateExamine(examineInfo, cb) {
    let data = {
        id: examineInfo.id,
        limitTime: examineInfo.limitTime,
        quesArr: examineInfo.quesArr,
        title: examineInfo.title,
        publishDate: examineInfo.publishDate
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_update_examine());
        return apiRequest("/gk/ops/dailyExercise/saveOrUpdate", config)
            .then(json => {
              dispatch(receive_update_examine_result_suc());
              message.success('更新成功');
              cb && cb();
            })
            .catch(error => {
                dispatch(receive_update_examine_result_fail());
                console.log(error)
              });
    }
}
