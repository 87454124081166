import React, { useEffect, useState } from 'react'
import { Result, message } from 'antd';
import Cookies from 'js-cookie';

function Index(props) {
  const [time, setTime] = useState('')

  useEffect(() => {
    if(!Cookies.get('accessToken')) {
      message.warn('未登录');
      return props.history.push('/login');
    }
    let date = new Date();
    setTime(String(date.toLocaleDateString() + '  周' + getDayType(date.getDay())))
  }, [props]);

  const getDayType = (day) => {
    if(day==1) return '一';
    if(day==2) return '二';
    if(day==3) return '三';
    if(day==4) return '四';
    if(day==5) return '五';
    if(day==6) return '六';
    if(day==0) return '日';
  }

  return (
    <Result
      icon={<img style={{ width: '120px' }} alt="logo" src="https://jd-media-resource.oss-cn-hangzhou.aliyuncs.com/background/jiaodian-logo.jpg" />}
      title="欢迎使用焦点三位一体后台管理系统!"
      subTitle={[time]}
    />
  );
}

export default Index