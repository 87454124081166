import {
    GET_STUDENT_BIRTHPLACE,
    CHANGE_ORIGIN_PLACE,
    LOADING_CONTRACT_USER,
    SET_CONTRACT_USER,
    SET_REDUX_KEY_VALUE,
    CLEAR_SEARCH,
    CHANGE_CURRENT_PAGE,
    RESET_CURRENT_PAGE,
    CHANGE_PAGE_SIZES,
    SET_STUDENT_LOADING,
} from './action';

const initSearch = {
    page: 1,                                        // 当前页
    pageSize: 10,                                   // 每页多少条
    birthPlace: '全部',                              // 默认生源地
    name: '',                                       // 用户名
    mobile: '',                                     // 用户电话
    wechat: '',                                     // 用户微信
};
const initialState = {
    loading: false,                                 // 是否处于加载状态中
    birthPlaces: [],                                //生源地列表
    userInfo: {},                                   // 合同用户信息
    ...initSearch
};

const studentInfo = (previousState=initialState, action) => {
    switch(action.type) {
        case GET_STUDENT_BIRTHPLACE:
            return { ...previousState, birthPlaces: action.data };
        case CHANGE_ORIGIN_PLACE:
            return { ...previousState, birthPlace: action.value};
        case LOADING_CONTRACT_USER:
            return { ...previousState, loading: true };
        case SET_STUDENT_LOADING:
            return { ...previousState, loading: false };
        case SET_CONTRACT_USER:
            return {...previousState, userInfo: action.data, loading: false };
        case SET_REDUX_KEY_VALUE:
            return { ...previousState, [action.key]: action.value };
        case CLEAR_SEARCH:
            return { ...previousState, ...initSearch };
        case CHANGE_CURRENT_PAGE:
            return { ...previousState, page: action.value };
        case RESET_CURRENT_PAGE:
            return { ...previousState, page: action.value };
        case CHANGE_PAGE_SIZES:
            return { ...previousState, pageSize: action.value }
        default:
            return previousState;
    }
}

export { studentInfo };