import {
  LOADING,
  SHENLUN_INDIVIDUAL
} from './action';

const initialState = {
  loading: true,
  individualObj: {},
}

export const shenlunStudentDetail = (prevState=initialState, action) => {
  switch(action.type) {
    case LOADING:
      return { ...prevState, loading: true };
    case SHENLUN_INDIVIDUAL:
      return Object.assign({}, prevState, { loading: false, individualObj: action.data });
    default:
      return prevState;
  }
}