import React from 'react';
import { connect } from 'react-redux';
import { Card, Select, Button, message, Table } from 'antd';
import {
    modifyBatchClassRedux,
    fetchBatchUpdate,
    clearBatchClassSearch
} from './action';
import { bulkOperation } from '../Batch/action';
import { fetchApplicationArea, fetchDepartmentList } from '../ContractOrder/action';
import { openModleAction, fetchSearchClass } from '../Inquiry/action';
import { fetchContractUserInform } from '../AddOrModify/action';
import { checkPermission } from '../../utils/utils';
import './index.scss';
import UserInfo from '../../components/UserInfo/index';

const { Option } = Select;
const sell = '销售部';

class BatchClass extends React.Component {

    state = {
        isAllActive: false,
    };

    componentDidMount() {
        this.getUserListInfo();
        this.props.dispatch(fetchApplicationArea());
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: [] }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: [] }));
    };

    /** 获取所有学员信息 */
    getUserListInfo = () => {
        const { page, pageSize, userInfoIds, examArea, salesName } = this.props;
        let data = {
            page,
            pageSize,
            isGroup: 0,
        }
        userInfoIds && userInfoIds.length > 0 && (data.userInfoIds = userInfoIds);
        examArea != '全部' && (data.examArea = examArea);
        salesName != '全部' && (data.salesName = salesName);
        this.props.dispatch(bulkOperation(data))
    };

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        let data = { title: value }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 全选 */
    clickPageAll = event => {
        let checked = event.target.checked;
        this.setState({ isAllActive: checked });
        const { content } = this.props;
        let that = this
        content.map(user => {
            setTimeout(function () {
                that.selectUser(user, checked);
            }, 0);
        })
    };

    /** 全选逻辑 */
    selectUser = (user, check) => {
        const { selectArr, userArr } = this.props;
        let currentSelectArr = [...selectArr];
        let currentUserArr = [...userArr];
        if (check) {                                    // 全选逻辑
            if (!userArr.includes(user.contractId)) {
                currentSelectArr.push(user);
                currentUserArr.push(user.contractId);
            }
        } else {
            currentUserArr = [];
            currentSelectArr = [];
        }
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: currentUserArr }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: currentSelectArr }));
    };

    /** 单个选择情况 */
    selectCurrentUser = user => {
        const { selectArr, userArr } = this.props;
        let currentSelectArr = [...selectArr];
        let currentUserArr = [...userArr];
        if (!userArr.includes(user.contractId)) {                        // 没有push进去
            currentSelectArr.push(user);
            currentUserArr.push(user.contractId)
        } else {
            currentUserArr = userArr.filter(v => v != user.contractId);
            currentSelectArr = selectArr.filter(v => v.contractId != user.contractId)
        }
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: currentUserArr }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: currentSelectArr }));
    };

    handlerClassSelect = async value => {
        await this.props.dispatch(modifyBatchClassRedux({ key: 'classIdx', value }));
        const { classIdx, classes } = this.props;
        if (classIdx !== null) {
            let currentClass = classes[classIdx];
            this.props.dispatch(modifyBatchClassRedux({ key: 'className', value: currentClass.className }));
            this.props.dispatch(modifyBatchClassRedux({ key: 'classId', value: currentClass.id }));
            this.props.dispatch(modifyBatchClassRedux({ key: 'teacher', value: currentClass.teacher }));
            this.props.dispatch(modifyBatchClassRedux({ key: 'startDate', value: currentClass.startDate }));
        }
    };

    /** 批量更新班级 */
    updateBatchClasses = () => {
        const { classId, userArr } = this.props;
        if (!userArr || userArr <= 0) {
            message.error('请选择学员');
            return;
        };
        if (!classId) {
            message.error('请选择班级');
            return;
        };
        let contractIds = [...userArr];
        let params = { classId, contractIds };
        const cb = () => this.props.history.push('/contractOrder');
        this.props.dispatch(fetchBatchUpdate(params, cb));
    };

    /** 重置为第一页 */
    resetActivePage1 = () => {
        this.props.dispatch(modifyBatchClassRedux({ key: 'page', value: 1 }));
    };

    /** 改变分页大小 */
    onShowSizeChange = async (current, size) => {
        await this.resetActivePage1();
        await this.props.dispatch(modifyBatchClassRedux({ key: 'pageSize', value: size }));
        this.getUserListInfo();
    };

    /** 改变当前页数 */
    handlerPage = async page => {
        await this.props.dispatch(modifyBatchClassRedux({ key: 'page', value: page }));
        this.getUserListInfo();
    };

    /** 搜索合同用户 */
    searchContractInform = value => {
        if (!value) return;
        this.props.dispatch(fetchContractUserInform(value));
    };

    /** 用户筛选 */
    handleChange = async value => {
        await this.props.dispatch(modifyBatchClassRedux({ key: 'userInfoIds', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 销售筛选 */
    modifySales = async value => {
        await this.props.dispatch(modifyBatchClassRedux({ key: 'salesName', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    /** 报考地区筛选 */
    modifyExamArea = async value => {
        await this.props.dispatch(modifyBatchClassRedux({ key: 'examArea', value }));
        await this.resetActivePage1();
        this.getUserListInfo();
    };

    /** 清空搜索记录 */
    clearRecord = async () => {
        await this.props.dispatch(clearBatchClassSearch());
        this.getUserListInfo();
    };

    /** 打开学生信息详情 */
    open = (data) => {
        this.props.dispatch(openModleAction(data))
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '客户',
                width: '20%',
                dataIndex: 'userName',
                render: (text, record) => (
                    <span className="active" onClick={() => this.open({ key: 'userModle', value: { open: true, id: record.userInfoId } })}>{record.userName || ''}</span>
                ),
            },
            {
                title: '课程',
                width: '20%',
                dataIndex: 'courseName',
                ellipsis: true
            },
            {
                title: '报考地区',
                width: '10%',
                dataIndex: 'examArea',
            },
            {
                title: '销售',
                width: '5%',
                dataIndex: 'salesName',
            },
            {
                title: '销售微信',
                width: '10%',
                dataIndex: 'salesWechat',
            },
            {
                title: '合同编号',
                width: '20%',
                dataIndex: 'contractNo',
            },
            {
                title: '签订日期',
                width: '10%',
                dataIndex: 'signingDate',
            },
            {
                title: '备注',
                width: '5%',
                dataIndex: 'remarks',
                ellipsis: true
            }
        ];
        return columns;
    }

    /** 已选列表最简单，只能取消不能勾选，所见即所得 */
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: selectedRowKeys }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: selectedRows }));
    };

    /** 单选逻辑 */
    onSelect = (record, selected, selectedRows) => {
        let selectArr = this.props.selectArr;
        let userArr = this.props.userArr;
        if (selected) {
            selectArr.push(record);
            userArr.push(record.contractId);
        } else {
            let idx = userArr.indexOf(record.contractId);
            userArr.splice(idx, 1);
            selectArr.forEach((item, idx, arr) => {
                if (item.contractId == record.contractId) {
                    arr.splice(idx, 1)
                }
            });
        }
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: [...userArr] }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: [...selectArr] }));
    };

    /** 全选反选逻辑 */
    onSelectAll = (selected, selectedRows, changeRows) => {
        let selectArr = this.props.selectArr;
        let userArr = this.props.userArr;
        if (selected) {
            changeRows.map(item => {
                userArr.push(item.contractId);
            })
            selectArr.push(...changeRows);
        } else {
            changeRows.map(item => {
                userArr = userArr.filter(v => v != item.contractId);
                selectArr = selectArr.filter(v => v.contractId != item.contractId);
            })
        }
        this.props.dispatch(modifyBatchClassRedux({ key: 'userArr', value: [...userArr] }));
        this.props.dispatch(modifyBatchClassRedux({ key: 'selectArr', value: [...selectArr] }));
    };

    render() {
        const {
            userModle, classes, className, teacher, startDate, content, totalElements, userArr, selectArr, appLicationArea, salles, userNames, salesName, examArea, userInfoIds
        } = this.props;
        return (
            <div className="batch-class-container">
                {userModle.open && <UserInfo
                    updeteCb={this.getUserListInfo}
                    notUpdate={true}
                />}
                <div className="commonSearchBox">
                    <div>
                        <label className="mr10">班级<span style={{ color: 'red' }}>*</span></label>
                        <Select
                            showSearch
                            placeholder="请输入班级关键词"
                            style={{ width: 300 }}
                            filterOption={false}
                            value={className}
                            onSearch={value => { this.searchClass(value) }}
                            onChange={value => { this.handlerClassSelect(value) }}
                            className="mr10"
                        >
                            {
                                classes && classes.length && classes.map((item, index) => (
                                    <Option key={index} value={index}>{item.className}</Option>
                                ))
                            }
                        </Select>
                        {
                            className &&
                            <span>{`班主任:${teacher}——开班日期:${startDate}`}</span>
                        }
                    </div>
                </div>
                {
                    checkPermission('user:batch:clazz') &&
                    <Card size="small" title="更新" className="cardR">
                        <Button type="primary" onClick={this.updateBatchClasses}>更新</Button>
                    </Card>
                }
                <Table
                    rowSelection={{
                        selectedRowKeys: userArr,
                        onChange: this.onSelectChange,
                    }}
                    columns={this.generateTableColumns()}
                    dataSource={selectArr}
                    rowKey={record => record.contractId}
                    title={() => '已选学员'}
                    pagination={false}
                />

                <div className="commonSearchBox">
                    <div>
                        <label className="mr20">客户</label>
                        <Select
                            style={{ width: 300 }}
                            showSearch
                            mode="multiple"
                            placeholder="请输入客户姓名或手机号"
                            filterOption={false}
                            onSearch={value => { this.searchContractInform(value) }}
                            onChange={value => { this.handleChange(value) }}
                        >
                            {
                                userNames && userNames.map((item, idx) => (
                                    <Option key={idx} value={item.id}>{`${item.name}(${item.mobile})`}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label className="mr20">销售</label>
                        <Select
                            style={{ width: 150 }}
                            showSearch
                            filterOption={false}
                            value={salesName}
                            onSearch={value => { this.employeeSearch(sell, value) }}
                            onChange={event => { this.modifySales(event) }}
                        >
                            {
                                salles && [{ name: '全部' }].concat(salles).map((item, idx) => (
                                    <Option key={idx} value={item.name}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label className="mr20">报考地区</label>
                        <Select
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={examArea}
                            onChange={event => { this.modifyExamArea(event) }}
                        >
                            {
                                appLicationArea && ['全部'].concat(appLicationArea).map((item, idx) => (
                                    <Option key={idx} value={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <Button type="danger" onClick={this.clearRecord}>清空搜索记录</Button>
                    </div>
                </div>

                <Table
                    rowSelection={{
                        selectedRowKeys: userArr,
                        onSelect: this.onSelect,
                        onSelectAll: this.onSelectAll,
                    }}
                    columns={this.generateTableColumns()}
                    dataSource={content}
                    rowKey={record => record.contractId}
                    title={() => '未分班学员'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: totalElements,
                        onChange: this.handlerPage,
                        onShowSizeChange: this.onShowSizeChange,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    };
};

function mapStatetoProps(state) {

    const {
        userModle
    } = state.inquiryReducer;

    const {
        ...initialState
    } = state.batchClassReducer;

    const {
        appLicationArea,
        salles
    } = state.contractOrder;

    return {
        userModle,
        appLicationArea,
        salles,
        ...initialState
    };
}

export default connect(mapStatetoProps)(BatchClass);