import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Button, Menu, Select, DatePicker, Input, message, Modal, Tooltip } from 'antd';
import {
    fetchCareClass,
    openModleAction,
    fetchSearchClass,
    getInquiryList,
    changeCurrentTab,
    changeCurrentTabValue,
    dealSelectRedux,
    handleCurrentClass,
    clearSearchCriteria,
    fetchAddStatus,
} from './action';
import { clearBatchStatus } from '../Batch/action';
import { fetchDepartmentList, searchUserInfo } from '../ContractOrder/action';
import './index.scss';
import moment from 'moment';
import { checkPermission } from '../../utils/utils';
import UserInfo from '../../components/UserInfo/index';
import ClassInfo from '../../components/ClassInfo/index';

const { Option } = Select;
const { Item } = Menu;
const { TextArea } = Input;
const classTeacher = '师资堂,全程部';
const sale = '运营部';
const superviseStatus = [{ label: '全部', value: '全部' }, { label: '正常', value: 0 }, { label: '异常', value: 1 }];
const returnVisitStatus = [{ label: '全部', value: '全部' }, { label: '正常', value: 0 }, { label: '异常', value: 1 }, { label: '未回复', value: 2 }];
const questionType = ['申论', '言语理解与表达', '数量关系', '判断推理', '资料分析', '科学推理'];
class Inquiry extends React.Component {

    state = {
        showModal: false,                       // 是否显示模态框
        classId: null,                          // 班级id
        userInfoId: null,                       // 用户id
        date: null,                             // 日期
        headTeacherId: '全部',                   // 班主任id
        teacherId: '全部',                       // 老师id
        type: '全部',                            // 答疑类型
        remarks: null,                          // 备注
        status: '全部',                          // 回访状态  盯学状态
        askNum: 0,                              // 答疑次数

        teacherName: null,                      // 老师名字
        headTeacherName: null,                  // 班主任名字
        className: null,                       // 班级名字
        userName: null,                         // 用户信息                      
    }

    componentWillUnmount() {
        this.props.dispatch(dealSelectRedux({ key: 'careTeacherId', value: '' }))
    }

    changeCurrentHandle = async e => {
        await this.props.dispatch(changeCurrentTab(e.key));
        e.key == 'supervise' && (this.props.dispatch(changeCurrentTabValue({ value: '盯学' })));
        e.key == 'ask' && (this.props.dispatch(changeCurrentTabValue({ value: '答疑' })));
        e.key == 'userCare' && (this.props.dispatch(changeCurrentTabValue({ value: '关怀' })));
        e.key == 'returnVisit' && (this.props.dispatch(changeCurrentTabValue({ value: '回访' })));
        const { isStatus, classId, current, startDate, endDate, userInfoIds } = this.props;
        if (classId) {
            let params = { classId };
            isStatus != null && (params.status = isStatus);
            startDate && (params.startDate = startDate);
            endDate && (params.endDate = endDate);
            userInfoIds && userInfoIds.length > 0 && (params.userInfoIds = userInfoIds);
            this.props.dispatch(getInquiryList(params, current));
        };
    }

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        if (!value) {
            this.props.dispatch(clearSearchCriteria())
            return;
        }
        let data = { title: value, isUserContract: 1, pageSize: 100 }
        this.props.dispatch(fetchSearchClass(data));
    };

    /** 处理选择班级  只能选择一个 */
    handleSelectClass = async index => {
        await this.props.dispatch(dealSelectRedux({ key: 'classIdx', value: index }));
        const { classIdx, classes } = this.props;
        if (classIdx !== null) {
            let currentClass = classes[classIdx];
            await this.props.dispatch(handleCurrentClass(currentClass));
            const { classDetail } = this.props;
            if (classDetail) {
                this.props.dispatch(dealSelectRedux({ key: 'currentClassName', value: classDetail.className }));
                this.props.dispatch(dealSelectRedux({ key: 'classId', value: classDetail.id }));
            }
        };
        this.getQueryResult();
    };

    /** 获取查询数据 */
    getQueryResult = () => {
        const { isStatus, classId, current, startDate, endDate, userInfoIds } = this.props;
        if (!classId) return;      // 未选班级  直接返回
        let params = { classId }
        isStatus != null && (params.status = isStatus);
        startDate && (params.startDate = startDate);
        endDate && (params.endDate = endDate);
        userInfoIds && userInfoIds.length > 0 && (params.userInfoIds = userInfoIds);
        this.props.dispatch(getInquiryList(params, current));
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : '';

    /** 设置日期输入的值 */
    calendarValueChangeHandler = async (dateString, key) => {
        const { classId } = this.props;
        if (!classId) {
            message.warn('请选择班级');
            return;
        };
        await this.props.dispatch(dealSelectRedux({ key, value: dateString }));
        this.getQueryResult();
    };

    /** 生成表头方法 */
    renderHeader = () => {
        return (
            <div className="commonTableTitle">
                <span>查询结果</span>
                {this.props.responseObj.length && <span>班级人数<span style={{ color: 'red' }}>{this.props.classDetail && this.props.classDetail.studentNumber || this.props.responseObj.length}</span>人</span> || ''}
            </div>
        )
    };

    /** 用户实时查询 */
    searchCustormer = value => {
        const { classId } = this.props;
        if (!classId) {
            message.warn('请选择班级');
            return;
        };
        if (!value) return;
        this.props.dispatch(searchUserInfo(value));
    };

    /** 处理客户搜索框 */
    handleChange = async value => {
        await this.props.dispatch(dealSelectRedux({ key: 'userInfoIds', value }));
        this.getQueryResult();
    };

    /** 是否退款 */
    modifyStatus = async value => {
        await this.props.dispatch(dealSelectRedux({ key: 'isStatus', value }));
        this.getQueryResult();
    };

    /** 设置学员缓存 */
    setLocal = () => {
        let obj = this.props.responseObj;
        let arr = [];
        let list = [];
        obj.map(item => list.push({ 'id': item.userInfoId, 'name': item.userName }))
        obj.map(item => arr.push(item.userInfoId))
        localStorage.setItem('inquiryIds', JSON.stringify(arr));
        localStorage.setItem('inquiryList', JSON.stringify(list));
    };

    /** 查询单个学员详情 */
    inquiryCurrentDetail = record => {
        this.setLocal();
        const { current } = this.props;
        let userName = record.userName;
        let index1 = userName.indexOf('(')
        let index2 = userName.indexOf(')')
        let name = userName.slice(0, index1);
        let mobile = userName.slice(index1 + 1, index2)
        let url = `/inquiryDetail?type=${current}&userInfoId=${record.userInfoId}&name=${name}&mobile=${mobile}`;
        this.props.history.push(url);
    };

    /** 新增记录 */
    handleOk = () => {
        if (!this.verifyInput()) return;
        const { current } = this.props;
        let params = {
            classId: this.state.classId,
            userInfoId: this.state.userInfoId,
            date: this.state.date,
        };
        this.state.remarks && (params.remarks = this.state.remarks);
        const successCb = () => {
            this.setState({
                showModal: false,
                classId: null,
                userInfoId: null,
                date: null,
                headTeacherId: '全部',
                teacherId: '全部',
                type: '全部',
                remarks: null,
                status: '全部',
                teacherName: null,
                headTeacherName: null,
                className: null,
                userName: null,
                askNum: 0,
            });
            message.success('操作成功');
        };
        if (current === 'supervise') {
            params.teacherId = parseInt(this.state.teacherId);
            params.status = this.state.status;
            this.props.dispatch(fetchAddStatus(params, 'supervise', successCb, this.getQueryResult, this.errMessageHandler));
        };
        if (current === 'ask') {
            params.type = this.state.type;
            params.askNum = this.state.askNum;
            params.teacherId = parseInt(this.state.teacherId);
            params.headTeacherId = this.state.headTeacherId;
            this.props.dispatch(fetchAddStatus(params, 'ask', successCb, this.getQueryResult, this.errMessageHandler));
        };
        if (current === 'userCare') {
            params.teacherId = parseInt(this.state.teacherId);
            params.headTeacherId = this.state.headTeacherId;
            this.props.dispatch(fetchAddStatus(params, 'userCare', successCb, this.getQueryResult, this.errMessageHandler));
        };
        if (current === 'returnVisit') {
            params.teacherId = parseInt(this.state.teacherId);
            params.headTeacherId = this.state.headTeacherId;
            params.status = this.state.status;
            this.props.dispatch(fetchAddStatus(params, 'returnVisit', successCb, this.getQueryResult, this.errMessageHandler));
        };
    };

    errMessageHandler = errorMes => {
        message.error(errorMes);
    };

    /** 员工实时查询 */
    employeeSearch = (department, value) => {
        if (!value) return;
        this.props.dispatch(fetchDepartmentList(department, value));
    };

    verifyInput = () => {
        let result = true;
        const { current } = this.props;
        if (!this.state.date) {
            result = false;
            message.error('请选择日期');
            return result;
        };
        if (current === 'supervise') {
            if (this.state.status === '全部') {
                result = false;
                message.error('请选择盯学情况');
                return result;
            };
        };
        if (current === 'ask' || current === 'userCare' || current === 'returnVisit') {
            if (this.state.teacherId === '全部') {
                result = false;
                message.error('请选择老师');
                return result;
            };
        };
        if (current === 'ask') {
            if (this.state.type === '全部') {
                result = false;
                message.error('请选择答疑类型');
                return result;
            };
            if (this.state.askNum <= 0) {
                result = false;
                message.error('请输入正确的答题次数');
                return result;
            }
        };
        if (current === 'returnVisit') {
            if (this.state.status === '全部') {
                result = false;
                message.error('请选择回访情况');
                return result;
            };
        }
        return result;
    };

    modalCancelHandler = () => {
        this.setState({
            showModal: false,
            classId: null,
            userInfoId: null,
            date: null,
            headTeacherId: '全部',
            teacherId: '全部',
            type: '全部',
            remarks: null,
            status: '全部',
            teacherName: null,
            headTeacherName: null,
            className: null,
            userName: null,
            askNum: 0,
        });
    };

    /** 新增 */
    addStudentStatus = record => {
        const { current } = this.props;
        if (current === 'supervise') this.setState({ teacherId: record.headTeacherId })
        else this.setState({ headTeacherId: record.headTeacherId })
        this.setState({
            classId: record.classId,
            userInfoId: record.userInfoId,
            className: record.className,
            userName: record.userName,
            headTeacher: record.headTeacher,
            showModal: true,
        });
    };

    /** 批量操作 */
    batchOperation = async () => {
        await this.props.dispatch(clearBatchStatus());
        let url = `/batch/${this.props.current}`;
        this.props.history.push(url)
    }

    addDate = value => {
        this.setState({ date: value });
    };

    /** 打开学生或者班级信息详情 */
    open = (data) => {
        this.props.dispatch(openModleAction(data))
    }

    generateColumns() {
        const { current, currentTab } = this.props;
        const columns = [
            {
                title: '客户',
                width: '15%',
                render: (text, record) => (
                    checkPermission('user:info:detail') && <span className="active" onClick={() => this.open({ key: 'userModle', value: { open: true, id: record.userInfoId } })}>{record.userName}</span> || <span>{record.userName}</span>
                ),
            },
            {
                title: '班级',
                width: '15%',
                render: (text, record) => (
                    checkPermission('sys:clazz:info') && <span className="active" onClick={() => this.open({ key: 'classModle', value: { open: true, id: record.classId } })}>{record.className}</span> || <span>{record.className}</span>
                ),
            },
            {
                title: '班主任',
                width: '8%',
                dataIndex: 'headTeacher'
            },
            {
                title: '销售微信',
                width: '10%',
                dataIndex: 'salesWechat'
            },
            {
                title: '开班日期',
                width: '10%',
                dataIndex: 'startDate',
            },
            {
                title: '最近一次',
                width: '10%',
                dataIndex: 'latestDate',
            },
            {
                title: '合同备注',
                width: '10%',
                render: (text, record) => (
                    <Tooltip title={record.remarks}>
                        <span className="remark">{record.remarks}</span>
                    </Tooltip>
                ),
            }
        ];
        if (current === 'supervise') {
            columns.push({
                title: '盯学次数',
                width: '8%',
                dataIndex: 'superviseNum'
            });
        };
        if (current === 'ask') {
            columns.push({
                title: '答疑记录',
                width: '8%',
                dataIndex: 'userAskNum'
            });
        };
        if (current === 'userCare') {
            columns.push({
                title: '关怀次数',
                width: '8%',
                dataIndex: 'userCareNum'
            });
        };
        if (current === 'returnVisit') {
            columns.push({
                title: '回访次数',
                width: '8%',
                dataIndex: 'returnVisitNum'
            });
        };
        columns.push({
            title: '操作',
            width: '12%',
            render: record =>
                <div className='commonTableBtn'>
                    {checkPermission(this.getType('add')) && <Button type="primary" size="small" onClick={() => this.addStudentStatus(record)}>新增</Button>}
                    {((current === 'supervise' && record.superviseNum) || (current === 'ask' && record.userAskNum) || (current === 'userCare' && record.userCareNum) || (current === 'returnVisit' && record.returnVisitNum)) ?
                        (checkPermission(this.getType('global')) && <Button type="primary" size="small" onClick={() => this.inquiryCurrentDetail(record)}>{`${currentTab}详情`}</Button>)
                        :<span></span>}
                </div>
        })
        return columns;
    };

    getType = (type) => {
        let { currentTab } = this.props;
        if (type == 'global') {
            if (currentTab == '盯学') return 'user:supervise:page';
            if (currentTab == '回访') return 'user:visit:page';
            if (currentTab == '答疑') return 'user:ask:page';
            if (currentTab == '关怀') return 'user:care:page';
        }
        if (type == 'add') {
            if (currentTab == '盯学') return 'user:supervise:edit';
            if (currentTab == '回访') return 'user:visit:edit';
            if (currentTab == '答疑') return 'user:ask:edit';
            if (currentTab == '关怀') return 'user:care:edit';
        }
        if (type == 'all') {
            if (currentTab == '盯学') return 'user:supervise:batchSave';
            if (currentTab == '回访') return 'user:visit:batchSave';
            if (currentTab == '关怀') return 'user:care:batchSave';
        }
    }

    /** 查看老师关怀班级学员详情 */
    toDetail = (obj) => {
        let data = {
            startDate: obj.startDate,
            endDate: obj.endDate,
            classId: obj.classId
        }
        this.searchClass(obj.className);
        this.props.dispatch(dealSelectRedux({ key: 'classId', value: obj.classId }));
        this.props.dispatch(dealSelectRedux({ key: 'currentClassName', value: obj.className }));
        this.props.dispatch(dealSelectRedux({ key: 'startDate', value: obj.startDate }));
        this.props.dispatch(dealSelectRedux({ key: 'endDate', value: obj.endDate }));
        this.props.dispatch(getInquiryList(data, 'userCare'));
        let o = document.querySelector('.site-layout-background');
        let a = document.querySelector('#isSelect');
        setTimeout(()=>{o.scrollTop = a.offsetTop-40;}, 500);
    }

    /** 查询老师关怀班级列表 */
    searchCareClass = () => {
        let {careDate, careTeacherId} = this.props;
        if(careDate=='') return message.warn('请选择日期');
        if(careTeacherId=='') return message.warn('请选择老师');
        this.props.dispatch(fetchCareClass(careTeacherId, careDate));
    }

    render() {
        const {
            userModle, classModle, isStatus, classes, current, currentTab, responseObj, startDate, endDate, currentClassName, teacherList, saleList, custormers, careDate, careClassList, careTeacherId
        } = this.props;
        teacherList.length && (this.teaArr = teacherList);  //用于答疑关怀老师回显
        saleList.length && (this.saleArr = saleList);       //用于回访老师回显
        let columns = this.generateColumns();
        return (
            <div className="inquiry-container">
                {userModle.open && <UserInfo
                    updeteCb={this.getQueryResult}
                />}
                {classModle.open && <ClassInfo
                    updeteCb={this.getQueryResult}
                />}
                <div className="flexRow inquiry">
                    <Menu
                        mode="horizontal"
                        className="pageMenu"
                        onClick={e => { this.changeCurrentHandle(e) }}
                        selectedKeys={[current]}
                    >
                        {checkPermission('user:supervise:classInfo') && <Item key="supervise">盯学查询</Item>}
                        {checkPermission('user:ask:classInfo') && <Item key="ask">答疑查询</Item>}
                        {checkPermission('user:care:classInfo') && <Item key="userCare">关怀查询</Item>}
                        {checkPermission('user:visit:classInfo') && <Item key="returnVisit">回访查询</Item>}
                    </Menu>
                    <span className="flexg"></span>
                    {checkPermission(this.getType('global')) &&
                        <Link to={`inquiryDetail?type=${current}&global=true`}>
                            <Button type="primary" className="mr10">{`${currentTab}详情全局搜索`}</Button>
                        </Link>
                    }
                    {
                        current !== 'ask' && checkPermission(this.getType('all')) &&
                        <Button type="primary" className="mr10" onClick={this.batchOperation}>{`批量${currentTab}`}</Button>
                    }
                </div>

                {(current === 'userCare') && <div>
                    <div className="commonSearchBox">
                        <div>
                            <label>选择老师/班主任</label>
                            <Select
                                showSearch
                                style={{ width: 150 }}
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(classTeacher, value); }}
                                value={careTeacherId}
                                onChange={value => this.props.dispatch(dealSelectRedux({ key: 'careTeacherId', value: value }))}
                            >
                                {(teacherList || []).map((item, idx) => (
                                    <Option key={idx} value={item.id + ''}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <label>选择时间</label>
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: 150 }}
                                value={this.calendarValuePipe(careDate)}
                                onChange={(date, dateString) => { this.props.dispatch(dealSelectRedux({ key: 'careDate', value: dateString })) }}
                            />
                        </div>
                        <div><Button type='primary' onClick={()=>this.searchCareClass()}>查询</Button></div>
                    </div>
                    <div className="commonSearchBox">
                        {careClassList.length && careClassList.map((item, idx)=>{return item.className && <div className='classL' key={idx}>
                            <p className='active' style={{fontWeight: 'bold'}} onClick={()=>this.toDetail(item)}>{item.className}</p>
                            <p>{item.startDate}/{item.endDate}(关怀人数:{item.num})</p>
                            <Tooltip title={item.remarks || '无备注'}><span style={{color: 'cornflowerblue'}}>查看备注</span></Tooltip>
                        </div> || ''}) || <div>暂无关怀班级</div>}
                    </div>
                </div>}

                <div className="commonSearchBox">
                    <div>
                        <label>班级<span style={{ color: 'red' }}>*</span></label>
                        <Select
                            showSearch
                            style={{ width: 400 }}
                            filterOption={false}
                            placeholder="请输入班级"
                            value={currentClassName}
                            onSearch={value => { this.searchClass(value) }}
                            onChange={value => { this.handleSelectClass(value) }}
                        >
                            {
                                classes.map((item, index) => (
                                    <Option key={index} value={index}>{item.className}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label className="mr10">客户</label>
                        <Select
                            mode="multiple"
                            style={{ width: '400px' }}
                            filterOption={false}
                            placeholder="请输入客户手机号或姓名"
                            onSearch={value => { this.searchCustormer(value) }}
                            onChange={value => { this.handleChange(value) }}
                        >
                            {
                                custormers && custormers.map((item, idx) => (
                                    <Option key={idx} value={item.id}>{`${item.name}(${item.mobile})`}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>起始时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(startDate)}
                            onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'startDate'); }}
                        />
                    </div>
                    <div>
                        <label>截止时间</label>
                        <DatePicker
                            style={{ width: 150 }}
                            format="YYYY-MM-DD"
                            value={this.calendarValuePipe(endDate)}
                            onChange={(date, dateString) => { this.calendarValueChangeHandler(dateString, 'endDate'); }}
                        />
                    </div>
                    <div>
                        <label>是否退款</label>
                        <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="请选择"
                            value={isStatus}
                            onChange={event => { this.modifyStatus(event) }}
                        >
                            <Option value={null}>全部</Option>
                            <Option value={1}>是</Option>
                            <Option value={0}>否</Option>
                        </Select>
                    </div>
                    <Button type="danger" onClick={() => this.props.dispatch(clearSearchCriteria())}>清空搜索条件</Button>
                </div>
                <div id='isSelect'></div>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={responseObj}
                    rowKey={record => record.id}
                    title={this.renderHeader}
                    className="mt30"
                />

                <Modal
                    title="新增"
                    className="inModal"
                    visible={this.state.showModal}
                    onOk={this.handleOk}
                    onCancel={this.modalCancelHandler}
                >
                    <div className="flexRow">
                        <label style={{ width: 70 }}>客户:</label>
                        <label>{this.state.userName}</label>
                    </div>
                    <div className="mt10 flexRow">
                        <label style={{ width: 70 }}>班级:</label>
                        <label>{this.state.className}</label>
                    </div>
                    <div className="mt10 flexRow">
                        <label style={{ width: 70 }}>班主任:</label>
                        <label>{this.state.headTeacher}</label>
                    </div>
                    <div className="mt10 flexRow">
                        <label style={{ width: 70 }}>日期:</label>
                        <DatePicker
                            style={{ width: 200 }}
                            format="YYYY-MM-DD"
                            placeholder="请选择日期"
                            value={this.calendarValuePipe(this.state.date) || ''}
                            onChange={(date, dateString) => { this.addDate(dateString) }}
                            disabledDate={current => (current && current > moment().endOf('day'))}
                        />
                    </div>
                    {
                        current === 'supervise' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>盯学情况:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.status}
                                onChange={event => this.setState({ status: event })}
                            >
                                {
                                    superviseStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        current === 'returnVisit' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>回访情况:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.status}
                                onChange={event => this.setState({ status: event })}
                            >
                                {
                                    returnVisitStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        (current === 'ask' || current === 'userCare') &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>老师:</label>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(classTeacher, value) }}
                                value={this.state.teacherId}
                                onChange={event => this.setState({ teacherId: event })}
                            >
                                {
                                    (teacherList.length && teacherList || this.teaArr || []).map((item, idx) => (
                                        <Option key={idx} value={item.id + ''}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        current === 'ask' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>答疑类型:</label>
                            <Select
                                style={{ width: 200 }}
                                value={this.state.type}
                                onChange={event => this.setState({ type: event })}
                            >
                                {
                                    questionType.map((item, idx) => (
                                        <Option key={idx} value={item}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        current === 'ask' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>答疑次数:</label>
                            <Input type="number" style={{ width: 200 }} value={this.state.askNum} onChange={e => this.setState({ askNum: e.target.value })} />
                        </div>
                    }
                    {
                        current === 'returnVisit' &&
                        <div className="mt10 flexRow">
                            <label style={{ width: 70 }}>老师:</label>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                filterOption={false}
                                onSearch={value => { this.employeeSearch(sale, value) }}
                                value={this.state.teacherId}
                                onChange={event => this.setState({ teacherId: event })}
                            >
                                {
                                    (saleList.length && saleList || this.saleArr || []).map((item, idx) => (
                                        <Option key={idx} value={item.id + ''}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    <div className="mt10 flexRow" >
                        <label style={{ width: 70 }}>备注:</label>
                        <TextArea
                            value={this.state.remarks}
                            onChange={e => this.setState({ remarks: e.target.value })}
                            style={{ width: '60%' }}
                            autosize={{ minRows: 5 }}
                        />
                    </div>
                </Modal>

            </div>
        )
    }

};

function mapStatetoProps(state) {

    const {
        ...initialState
    } = state.inquiryReducer;

    return {
        ...initialState
    };
}

export default connect(mapStatetoProps)(Inquiry);