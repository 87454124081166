import React, { useState, useEffect } from 'react';
import { Table, Input, message } from 'antd';
import apiRequest from '../../common/request';
import { checkPermission } from '../../utils/utils';

const { Search } = Input;

function TelSearch() {

    const [mobile, setMobile] = useState('');       // 手机号
    const [stuList, setStuList] = useState([]);     // 列表信息

    useEffect(() => {
        async function fetchData() {
            let url = `/gk/ops/student/query/${mobile}`;
            let res = await apiRequest(url);
            if (res.status == 1) {
                setStuList(res.obj);
                message.success(`搜索到${res.obj.length}个班级`);
            }
        }
        mobile && fetchData();
    }, [mobile]);

    /** 搜索手机号 */
    const onSearch = value => {
        if (value == '') return;
        if (!(/^1[3456789]\d{9}$/.test(value))) return message.warning('手机号格式有误');
        setMobile(value);
    }

    /** 渲染表格列表信息 */
    const generateTableColumns = [
        { title: '序号', render: (text, record, index) => index + 1 },
        { title: '班级名', render: record => record.className },
        { title: '姓名', render: record => record.studentName },
        { title: 'id', render: record => record.id },
        { title: 'userId', render: record => record.userId },
    ];

    /** 渲染表格头部 */
    const tableTitle = () => (
        <div className="commonTableTitle">
            <div>学员查班</div>
            <Search
                placeholder="请输入学员手机号"
                allowClear
                disabled={!checkPermission('class:student:query')}
                enterButton="搜索"
                className="commonW400"
                onSearch={onSearch}
            />
        </div>
    )

    return (
        <div className="telSearch">
            <Table
                columns={generateTableColumns}
                dataSource={stuList}
                rowKey={record => record.id}
                title={() => tableTitle()}
                size={'small'}
                pagination={false}
            />
        </div>
    );
};

export default TelSearch;