import {
	RECEIVE_TAG_LIST,
    RECEIVE_VIDEO_LIST,
    RECEIVE_ARTICLE_LIST,
    RECEIVE_DELETE_VIDEO_RESULT,
    SET_ACTIVE_PAGE,
    SET_PAGE_SIZE
} from './actions';

const initialState = {
    isFetching: false,
    isSend: false,
    tags: [],
    tvs: [],
    totalPages: 1,
    activePage: 1,
    pageSize: 10,
    totalElements: 1
};

export function tvList (state=initialState, action) {
    switch (action.type) {
    	case RECEIVE_TAG_LIST: 
    		return Object.assign({},state, {tags: action.value||[]})
        case RECEIVE_VIDEO_LIST:
        case RECEIVE_ARTICLE_LIST:
            return Object.assign({},state, {tvs: action.tvs||[], totalPages:action.totalPages, totalElements: action.totalElements})
        case RECEIVE_DELETE_VIDEO_RESULT:
            return Object.assign({}, state, {
                isDeleting: false,
                tvs: [...state.tvs.filter(item => item.id != action.id)]
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, { activePage: action.data });
        default:
            return state;
    }
}