import {
    FILL_CONTENT, RECEIVE_PUBLISH_FAIL, RECEIVE_PUBLISH_SUC,
    REQUEST_PUBLISH, FILL_QUESTIONS, FILL_QARR
} from "./action";

const initialState = {
    isFetching: false,
    isSend: false,
    info: {
        // categoryId: 0,  // @小刚 说这个分类暂时没用
        content: "",
        questionIds: []   
    }
};

export function materialQuestionRelease(state = initialState, action) {
    switch (action.type) {
        case FILL_CONTENT:
        case FILL_QARR:
        case FILL_QUESTIONS:
            return {...state, info: {...state.info, [action.key]: action.value}};
        case REQUEST_PUBLISH:
            return {...state, isFetching: true, isSend: true};
        case RECEIVE_PUBLISH_SUC:
            return Object.assign({}, state, {isFetching: false, isSend: false});
        case RECEIVE_PUBLISH_FAIL:
            return Object.assign({}, state, {isFetching: false, isSend: false});
        default:
            return state;
    }
}