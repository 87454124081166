/**
 * Created by pwq on 2016/12/7.
 */

import {
    REQUEST_DAILY_EXAMINES,
    RECEIVE_DAILY_EXAMINES,
    RECEIVE_DELETE_DAILY_EXAMINES_RESULT,
    REQUEST_DELETE_DAILY_EXAMINES,
    SET_PAGE_SIZE,
    SET_ACTIVE_PAGE
} from './actions';

export function dailyExamines(state = {
    isFetching: true,
    isDeleting: true,
    items: [],
    totalElements: 1,
    numberOfElements: 1,
    pageSize: 10,                           // 每页显示的条数
    activePage: 1,                          // 当前是处于第几页
}, action) {
    switch (action.type) {
        case REQUEST_DAILY_EXAMINES:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_DAILY_EXAMINES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.dailyExamineList,
                totalElements: action.totalElements,
                numberOfElements: action.numberOfElements,
                lastUpdated: action.receivedAt
            });
        case REQUEST_DELETE_DAILY_EXAMINES:
            return Object.assign({}, state, {isDeleting: true});
        case RECEIVE_DELETE_DAILY_EXAMINES_RESULT:
            return Object.assign({}, state, {
                isDeleting: false,
                items: [...state.items.filter(item => item.id != action.id)]
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, { activePage: action.data });
        default:
            return state;
    }
}
