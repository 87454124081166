import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Table, Button, BackTop, Radio, Drawer } from 'antd';
import {
  fetch_feedback,
  updateStatus,
  setActivePage,
  setPageSize,
  changeStatus
} from './actions';

import { checkPermission } from '../../utils/utils';

const RadioGroup = Radio.Group;

class UserFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.statusArr = [
      { value: -1, text: '全部' },
      { value: 1, text: '已完成' },
      { value: 0, text: '未完成' },
    ];
    this.state = { 
      imgList: [],
      setVisible: false,
      permission: 0 
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  /** 获取反馈数据的方法 */
  getTableData = () => {
    let params = {};
    this.props.page && (params.page = this.props.page);
    this.props.pageSize && (params.pageSize = this.props.pageSize);
    (this.props.status !== this.statusArr[0].value) && (params.status = this.props.status);
    this.props.fetchFeedbackList(params);
  }

  doneOrNot = async record => {
    await updateStatus(record.id);
    this.getTableData();
  }

  img = (list) => (
    this.setState({
      setVisible: true,
      imgList: list
    })

  )

  /** antd table 的数据格式约定配置 */
  generateColumns = () => {
    const columns = [
      {
        title: '问题描述',
        width: '23%',
        render:(text, record)=>(
          <Tooltip title={record.description}>
            <div className="ellipsis2">{record.description}</div>
          </Tooltip>
        )
      },
      {
        title: '学员ID',
        dataIndex: 'userId',
        width: '10%',
        key: 'userId'
      },
      {
        title: '设备信息',
        width: '20%',
        dataIndex: 'model',
        key: 'model'
      },
      {
        title: '联系方式',
        width: '12%',
        dataIndex: 'link',
        key: 'link'
      },
      {
        title: '提交时间',
        width: '15%',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate'
      },
      {
        title: '完成状态',
        width: '10%',
        render: (text, record) => {
          if (record.status === 0) {
            return <span style={{color: 'red'}}>未完成</span>
          } else {
            return <span>已完成</span>
          }
        }
      },
      {
        title: '操作',
        width: '10%',
        render: (text, record) => {
          return (
            <div className="commonTableBtn">
              {checkPermission('user:feedback:update') &&
                <Button size="small" type="primary" onClick={() => { this.doneOrNot(record) }}>改变完成状态</Button>
              }
              {checkPermission('user:feedback:gkview') && record.img && record.img.length > 0 &&
                <Button onClick={() => this.img(record.img)} size="small" type="primary">查看图片描述</Button>
              }
            </div>
          );
        }
      }
    ];
    return columns;
  }

  /** 生成表头方法 */
  renderHeader = () => (
    <div>
      <span>问题反馈列表</span>
    </div>
  );

  /** 分页组件改变页码 */
  paginationChange = async page => {
    await this.props.setActivePage(page);
    this.getTableData();
  }

  /** 分页组件改变每页条数 */
  showSizeChange = async (current, size) => {
    await this.props.setPageSize(size);
    await this.props.setActivePage(1);
    this.getTableData();
  }

  changeStatusHandler = async event => {
    let status = event.target.value;
    await this.props.changeStatus(status);
    await this.props.setActivePage(1);
    this.getTableData();
  }

  onClose = () => {
    this.setState({
      setVisible: false,
      imgList: []
    })
  };

  render() {
    let columns = this.generateColumns();
    let { responseObj, loading, page, pageSize, status } = this.props;
    return (
      <div className="userfeedback-wrapper">
        <div className="commonSearchBox">
          <label>问题完成状态：</label>
          <RadioGroup onChange={this.changeStatusHandler} value={status}>
            {
              this.statusArr.map((item, idx) => (
                <Radio key={idx} value={item.value}>{item.text}</Radio>
              ))
            }
          </RadioGroup>
        </div>

        <Table
          columns={columns}
          dataSource={responseObj.content}
          rowKey={record => record.id}
          title={this.renderHeader}
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: responseObj.totalElements,
            showTotal: total => `共有${total}条数据`,
            onChange: this.paginationChange,
            onShowSizeChange: this.showSizeChange,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100']
          }}
        />

        <Drawer
          width='75%'
          title="查看图片"
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.setVisible}
        >
          {this.state.imgList.map((item,idx)=>(
            <img style={{maxWidth: '48%', height:'auto', margin: '0 2px 4px'}} key={idx} src={item} alt={idx}/>
          ))}
        </Drawer>

        <BackTop />
      </div>
    );
  }
}

const mapStateToProps = storeState => ({ ...storeState.userFeedback })

const mapDispatchToProps = dispatch => ({
  fetchFeedbackList: params => dispatch(fetch_feedback(params)),
  setActivePage: data => dispatch(setActivePage(data)),
  setPageSize: data => dispatch(setPageSize(data)),
  changeStatus: data => dispatch(changeStatus(data))                          // 改变筛选状态
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedback);