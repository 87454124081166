/**
 * Created by pwq on 2016/12/7.
 */
import React from "react";
import { connect } from "react-redux";
import { fetchResource, delResource, newResource } from "./actions";
import { checkPermission } from '../../utils/utils';
import { Input, Button, message } from 'antd';
import './index.scss';

class EssayCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = { type: "" };
    }

    componentWillMount() {
        this.props.dispatch(fetchResource());
        let permission = 2|| 1 ;
        this.setState({ permission });
    }

    /** id: 题型的ID值 */
    deleteResource = (id) => {
        this.props.dispatch(delResource(id));
    };

    createResource = () => {
        if (this.state.type.trim() === "") {
            message.warning('请输入新题型名称');
            return;
        }
        this.props.dispatch(newResource(this.state.type));
        this.setState({type: ""});
    };

    valueInput = (key, value) => { this.setState({ [key]: value }); }

    render() {
        return (
            <div className="essayCategory">
                <div>
                    <h4 className="mb15">题型分类</h4>
                    {checkPermission('sys:resource:edit') &&
                        <div className="mb25">
                            <Input className="mb15" value={this.state.type} onChange={event => this.valueInput('type', event.target.value)} placeholder="请输入题型分类名" type="text" />
                            <Button type="primary" onClick={this.createResource} disabled={this.props.isSend}>创建新题型</Button>
                        </div>
                    }

                    <div>
                        {checkPermission('sys:resource:gksee') &&
                            this.props.resource.map((res, idx) => <span key={idx} className="padding10 bgw mr5">{res.name}</span>)
                        }
                    </div>
                </div>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.essayCategory
    };
}

export default connect(mapStatetoProps)(EssayCategory);