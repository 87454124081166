import {
    REQUEST_UPDATE_TV,
    REQUEST_UPDATE_TV_VIDEO,
    REQUEST_TV_INFO,
    RECEIVE_TV_INFO,
    RECEIVE_UPDATE_TV_RESULT,
    RECEIVE_UPDATE_TV_VIDEO_RESULT,
    UPDATE_VIDEO_PROGRESS,
    UPDATE_URL,
    UPDATE_URL_KEY,
    UPDATE_TYPE,
    UPDATE_TITLE,
    UPDATE_RECOMMEND,
    UPDATE_TIMES,
    UPDATE_CONTENT,
    UPDATE_IMG_URL,
    REQUEST_EMPTY_TV_INFO,
    RESET_INFO
} from './actions';


export function tvInfo(state = {
    isFetching: false,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: {
        tag_id: '',
        recommend: 0,
        key: '',
        title: '',
        times: '',
        type: 'v'
    },
    progress: "0",
    creatTime: 0
}, action) {
    switch (action.type) {
        case REQUEST_EMPTY_TV_INFO:
            return Object.assign({}, state, {
                info: {
                    tag_id: '',
                    recommend: 0,
                    title: '',
                    type: action.value,
                    imgurl: '',
                    times: '',
                    content: '无',
                },
                creatTime: (new Date()).getTime()
            });
        case UPDATE_VIDEO_PROGRESS:
            return Object.assign({}, state, {
                progress: action.value
            });
        case REQUEST_TV_INFO:
            return Object.assign({}, state, {isFetching: true, creatTime: (new Date()).getTime()});
        case RECEIVE_TV_INFO:
            return Object.assign({}, state, {
                isFetching: false,
                info: action.info,
            });
        case REQUEST_UPDATE_TV:
            return Object.assign({}, state, {isUpdating: true});
        case REQUEST_UPDATE_TV_VIDEO:
            return Object.assign({}, state, {isVideoUpdating: true, progress: "0"});
        case RECEIVE_UPDATE_TV_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_TV_VIDEO_RESULT:
            return Object.assign({}, state, {isVideoUpdating: false, isVideoUpdated: true});
        case UPDATE_TYPE:
        case UPDATE_TITLE:
        case UPDATE_URL:
        case UPDATE_URL_KEY:
        case UPDATE_RECOMMEND:
        case UPDATE_TIMES:
        case UPDATE_CONTENT:
        case UPDATE_IMG_URL:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case RESET_INFO:
            return { 
                ...state, 
                info: { 
                    tag_id: '',
                    recommend: 0,
                    key: '',
                    title: '',
                    times: '',
                    type: 'v'
                }
            };
        default:
            return state;
    }
}