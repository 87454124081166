import {
    SET_BATCH_LIST,
    CHANGE_TABLE_LIST,
    MODIFY_TABLE_LIST,
    SET_CURRENT_DATE,
    SET_CURRENT_STATUS,
    ADD_MODIFY_REMARKS,
    MODIFY_BATCH_CURRENT_REDUX,
    CLEAR_BATCH_SEARCH,
    MODIFY_SELECT_VALUE,
    CLEAR_BATCH_STATUS
} from './action';

import { SEARCH_USER_INFORM } from '../AddOrModify/action';

import { SET_STUDENT_CLASS } from '../Inquiry/action';

import {
    GET_OPERATION_INFORM,
    GET_TEACHER_INFORM,
    GET_HEAD_TEACHER_INFORM
} from '../ContractOrder/action';

const initSearch = {
    isStatus: 0,                             //是否退款
    page: 1,
    pageSize: 10,
    userInfoIds: [],                        // 当前用户Id
    userNames: [],                          // 当前搜索客户所返回的
    salesName: '全部',                       // 默认销售
    examArea: '全部',                        // 默认地区  
    classId: '全部',  
    currentClassName: '全部',
    classes: [],                                    // 班级集合  
    startDate: '',
    endDate: '',     
    headTeacherName: '全部',
    headTeacherId: '全部'
};

const initStatus = {
    selectArr: [],                                      // 上面已选表格的数据
    userArr: [],                                        // id集合数组
    date: '',
    status: null,
    teacherName: '全部',
    teacherId: '全部',
};

const initialState = {
    content: [],
    totalPages: 1,
    totalElements: 1,
    operational: [],                                    // 运营部
    teacherDepartment: [],                              // 师资堂 
    headTeachers: [],                                   // 全程班班主任                        
    ...initSearch,
    ...initStatus,
};

export const batchReducer = (previousState=initialState, action) => {
    switch(action.type) {
        case SET_BATCH_LIST:
            return Object.assign({}, previousState, {
                content: action.content,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
            });
        case CHANGE_TABLE_LIST:
            return { ...previousState, userArr: [ ...action.data ] };
        case MODIFY_TABLE_LIST:
            return { ...previousState, selectArr: [ ...action.data ] };
        case SET_CURRENT_DATE:
            return { ...previousState, date: action.value };
        case SET_CURRENT_STATUS:
            return { ...previousState, status: action.value };
        case ADD_MODIFY_REMARKS:
            return { ...previousState, selectArr: [ ...action.data ] };
        case SEARCH_USER_INFORM:
            return { ...previousState, userNames: action.value };
        case MODIFY_BATCH_CURRENT_REDUX:
            return { ...previousState, [ action.key ]: action.value };
        case MODIFY_SELECT_VALUE:
            return { ...previousState, [ action.key ]: action.value };
        case SET_STUDENT_CLASS:
            return { ...previousState, classes: action.data };
        case GET_OPERATION_INFORM:
            return { ...previousState, operational: [ ...action.data ] };
        case GET_HEAD_TEACHER_INFORM:
            return { ...previousState, headTeachers: [ ...action.data ] };
        case GET_TEACHER_INFORM:
            return { ...previousState, teacherDepartment: [ ...action.data ] };
        case CLEAR_BATCH_STATUS:
            return { ...previousState, ...initStatus }
        case CLEAR_BATCH_SEARCH: 
            return { ...previousState, ...initSearch };
        default:
            return previousState;
    }
}