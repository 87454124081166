import {
    RECEIVE_RESOURCE,
    REQUEST_NEW_RESOURCE,
    RECEIVE_SUBRESOURCE
} from './actions';

export function interviewCategory(state = {
    isFetching: true,
    isSending: false,
    resource: [],
    typeList: {}
}, action) {
    switch (action.type) {
        case REQUEST_NEW_RESOURCE:
            return Object.assign({}, state, {
                isSending: true,
            });
        case RECEIVE_RESOURCE:
            return Object.assign({}, state, {
                isSending: false,
                resource: action.resource
            });
        case RECEIVE_SUBRESOURCE:
            let typeList = state.typeList
            typeList[action.id]={id:action.id, value:action.obj}
            return Object.assign({}, state, {
                isSending: false,
                typeList: typeList
            });
        default:
            return state;
    }
}
