import React, { Component } from 'react'
import { connect } from "react-redux";
import { Breadcrumb, Upload, Popconfirm, Button, notification, Table, Tooltip, message } from 'antd';
import { uploadFile } from './action';
import { Link } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/utils';

import './index.scss';
class UploadWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      curQuestionIndex: 0,
      previewShow: false,
    };
    this.wordName = ''
  }

  /** 生成upload组件的配置参数 : Fake Single File Upload */
  generateUploadSettings = () => {
    const { fileList } = this.state;
    // if (fileList.length > 0) return;
    return {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return { fileList: newFileList, };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        this.wordName = file.name
        return false;
      },
      accept: '.doc',
      fileList
    };
  }
  /** 监听上传文件 */
  uploadFileHandler = async () => {
    this.setState({ showLoading: true });
    await this.props.dispatch(uploadFile(this.state.fileList));
    this.setState({ showLoading: false });
    if (this.props.questionList && this.props.questionList.status === 1) {
      notification.open({
        message: '操作结果',
        description: `成功生成${this.props.questionList.obj.length}道题`,
        duration: 7,
      });
    } else if (this.props.questionList && this.props.questionList.status === 2) {
      notification.warning({
        message: '配置失败',
        description: this.props.questionList.errorMes + '请检查文件内容',
        duration: 7,
      });
    }
  }
  getTooltip = (record) => {
    return <div dangerouslySetInnerHTML={{ __html: record ? record : '' }}></div>
  };

  currentPreview(index) {
    if (this.props.questionList.obj.length == 0) {
      return;
    }
    this.setState({
      previewShow: true,
      curQuestionIndex: index
    })
  }
  hidePreview() {
    this.setState({
      previewShow: false
    })
  }
  preQuestion() {
    const curQuestionIndex = this.state.curQuestionIndex;
    if (curQuestionIndex === 0) {
      message.info('前面已经没有题目了');
      return;
    } else {
      this.setState({
        curQuestionIndex: curQuestionIndex - 1
      });
    }
  }
  nextQuestion() {
    const curQuestionIndex = this.state.curQuestionIndex;
    if (curQuestionIndex > this.props.questionList.obj.length - 2) {
      message.info('已经是最后一题了');
      return;
    } else {
      this.setState({
        curQuestionIndex: curQuestionIndex + 1
      });
    }
  }
  /** 生成antd table的配置信息 */
  generateTableColumns = () => {
    let columns = [
      {
        title: '题号',
        width: '10%',
        render: (text, record, index) => (
          <span>{index + 1}</span>
        ),
      },
      {
        title: '内容',
        width: '60%',
        render: (text, record) => (
          <Tooltip title={this.getTooltip(record.content)}>
            <Link to={`/question/${record.id}`} className="title" dangerouslySetInnerHTML={{ __html: record.content }}></Link>
          </Tooltip>),
      },
      {
        title: '分类',
        width: '15%',
        dataIndex: 'categoryName',
        key: 'categoryName',
      },
      {
        title: '类型',
        width: '10%',
        dataIndex: 'quesTypeName',
        key: 'quesTypeName'
      },
      {
        title: '预览',
        width: '10%',
        render: (text, record, index) => {
          return (
            <Button size="small" type="primary" onClick={() => this.currentPreview(index)}>预览</Button>
          )
        }
      }
    ];
    return columns;
  }
  /** 生成antd table的表头数据 */
  renderHeader = () => (
    <div className="tableHeader">
      word生成试题列表--共{this.props.questionList.obj.length}题
    </div>
  )

  render() {
    const { questionList } = this.props
    const curQuestion = questionList.obj ? questionList.obj[this.state.curQuestionIndex] : {};
    const columns = this.generateTableColumns();
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>试题</Breadcrumb.Item>
          <Breadcrumb.Item>通过上传word文件批量生成试题</Breadcrumb.Item>
        </Breadcrumb>
        <br/>
        <div className="mb5"><span style={{ color: 'red' }}>*</span>说明：上传的Word文件格式必须为.doc,题目内容排版也有规范</div>
        <br/>
        {checkPermission('sys:question:docUpload') &&<div className="upload">
          <Upload
            // disabled={this.state.fileList && this.state.fileList.length > 0}
            {...this.generateUploadSettings()}
          >
            <Button type="primary" icon={<UploadOutlined />} disabled={this.state.fileList && this.state.fileList.length > 0}>
              上传试题word
            </Button>
          </Upload>
          <Popconfirm
            disabled={!(this.state.fileList && this.state.fileList.length)}
            title={`确定开始生成'${this.wordName}'`}
            onConfirm={this.uploadFileHandler}
            okText="确定"
            cancelText="否"
          >
            <Button type="primary" className="up" loading={this.state.showLoading} disabled={!(this.state.fileList && this.state.fileList.length)}>{this.state.showLoading ? '生成中...' : '生成试题'}</Button>
          </Popconfirm>
        </div>}
        {questionList && questionList.obj.length ?
          <Table
            className="table"
            columns={columns}
            dataSource={questionList.obj}
            rowKey={record => record.id}
            title={this.renderHeader}
            pagination={{
              pageSize: 1000
            }}
          /> : ''
        }
        {
          this.state.previewShow ?
            1 : ''
        }
      </div>
    )
  }
}
function mapStatetoProps(state) {
  const {
    questionList
  } = state.upload;

  return {
    questionList
  };
}
export default connect(mapStatetoProps)(UploadWord);