import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import {
    newTag,
    fetchTagList,
    delTag,
    fetchList,
    deleteTv,
    setActivePage,
    setPageSize
} from "./actions";
import { updateTvInfo } from "../TvInfo/actions";
import { Input, Table, Button, message, Tag, Popconfirm } from 'antd';
import './index.scss';
import { checkPermission } from '../../utils/utils';

class TvList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tag: '',
            showModal: false,
            permission: 0,
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchList({ page: this.props.activePage, pageSize: this.props.pageSize }));
        this.props.dispatch(fetchTagList());
    }

    recommend = (item, value) => {
        item.recommend = value;
        this.props.dispatch(updateTvInfo(item, 0));
    };

    handleConfirm = (id) => {
        this.props.dispatch(deleteTv(id));
    };

    mdeleteTv = (e) => {
        e.preventDefault();
        this.setState({
            showModal: true,
            deleteId: e.target.getAttribute('data-target')
        });
    };

    handleSelect = (eventKey) => {
        this.props.dispatch(setActivePage(eventKey));
        this.props.dispatch(fetchList({
            page: eventKey,
            pageSize: this.props.pageSize
        }));
    };

    createTag = () => {
        if (this.state.tag.trim() === "") {
            message.warn('请输入标签名');
            return;
        }

        this.props.dispatch(newTag(this.state.tag));
        this.setState({ tag: "" });
    };

    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setActivePage(1));
        await this.props.dispatch(setPageSize(pageSize));
        this.props.dispatch(fetchList({ page: this.props.activePage, pageSize: this.props.pageSize }));
    }

    paginationChange = async page => {
        await this.props.dispatch(setActivePage(page));
        this.props.dispatch(fetchList({ page: this.props.activePage, pageSize: this.props.pageSize }));
    }

    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '标题',
                width: '25%',
                render: (text, record) => (
                    checkPermission('video:tv:gkinfo') &&<Link to={`/tv/${record.id}`} className="title">{record.title}</Link>
                    || <span className="title">{record.title}</span>
                ),
            },
            {
                title: '分类',
                width: '25%',
                render: (text, record) => (
                    <span>{record.tvTag && record.tvTag.name}</span>
                ),
            },
            {
                title: '视频',
                width: '25%',
                dataIndex: 'key',
            },
            {
                title: '操作',
                render: (text, record) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('sys:tv:edit') &&
                                record.recommend > 0 ?
                                    <Button size="small" type="primary" danger onClick={() => this.recommend(record, 0)}>取消推荐</Button> :
                                    <Button size="small" type="primary" onClick={() => this.recommend(record, 1)}>推荐</Button>
                            }
                            {checkPermission('sys:tv:edit') &&
                                record.recommend != 2 &&
                                <Button size="small" type="primary" onClick={() => this.recommend(record, 2)}>置顶</Button>
                            }
                            <Popconfirm
                                title="确定要删除该视频吗?"
                                onConfirm={() => this.handleConfirm(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                {checkPermission('gkvideo:tv:del') && <Button size="small" type="primary">删除</Button>}
                            </Popconfirm>
                        </div>

                    )
                }
            }

        ];
        return columns;
    }

    tableTitleRender = () => (
        <div className="commonTableTitle">
            <span>视频列表</span>
            {checkPermission('gkvideo:tv:edit') &&
                <Button type="primary"><Link to="/tvRelease">添加tv视频</Link></Button>
            }
        </div>
    )

    render() {
        return (
            <div>
                <h4>tv视频列表</h4>
                <div className="mb20">
                    {checkPermission('sys:tvTag:edit') && <div>
                        <label>标签</label>
                        <Input style={{width: '60%', margin: '0 10px 10px 0'}} value={this.state.tag} onChange={e => this.setState({ tag: e.target.value })} />
                        <Button type="primary" onClick={this.createTag} disabled={this.props.isSend}>创建标签</Button>
                    </div>}
                    {checkPermission('sys:tvTag:list') &&
                        this.props.tags.map((tag, idx) => (
                            <Tag color='blue' key={idx} closable={checkPermission('sys:tvTag:del')} onClose={() => this.props.dispatch(delTag(tag.id))}>{tag.name}</Tag>
                        ))
                    }
                </div>

                <Table
                    columns={this.generateTableColumns()}
                    dataSource={this.props.tvs}
                    rowKey={record => record.id}
                    title={this.tableTitleRender}
                    loading={this.props.isFetching}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onChange: this.paginationChange,
                        onShowSizeChange: this.onShowSizeChange,
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.tvList,
    };
}

export default connect(mapStatetoProps)(TvList);