import {
  LOADING,
  SUCCESS_GET_SHENLUN_QUESTION,
  SET_KEY_VALUE,
  RESET_SEARCH_STATUS
} from './actions';

const searchFilter = {
  area: '',
  categoryId: '',
  page: 1,
  pageSize: 10,
  query: '',                      // 这个是问题的关键词
  year: '',
};

let initialState = {
  loading: false,
  questions: [],
  totalElements: 10,
  ...searchFilter
};

export const shenlunQuestions = (prevState=initialState, action) => {
  switch(action.type) {
    case LOADING:
      return { ...prevState, loading: true };
    case SUCCESS_GET_SHENLUN_QUESTION:
      return { ...prevState, loading: false, questions: action.data.content, totalElements: action.data.totalElements};
    case SET_KEY_VALUE:
      return { ...prevState, [action.key]: action.value };
    case RESET_SEARCH_STATUS:
      return { ...prevState, ...searchFilter };
    default:
      return prevState;
  }
}