
import {message} from 'antd';
import apiRequest from '../../common/request'

const PREFIX = "DAILY_EXAMINE_LIST";
export const REQUEST_DAILY_EXAMINES = PREFIX + "REQUEST_DAILY_EXAMINES";
export const RECEIVE_DAILY_EXAMINES = PREFIX + "RECEIVE_DAILY_EXAMINES";
export const REQUEST_DELETE_DAILY_EXAMINES = PREFIX + "REQUEST_DELETE_DAILY_EXAMINES";
export const RECEIVE_DELETE_DAILY_EXAMINES_RESULT = PREFIX + "RECEIVE_DELETE_DAILY_EXAMINES_RESULT";
export const SET_PAGE_SIZE = PREFIX + "SET_PAGE_SIZE";
export const SET_ACTIVE_PAGE = PREFIX + "SET_ACTIVE_PAGE";

export const setPageSize = data => ({
    type: SET_PAGE_SIZE,
    data
});

export const setActivePage = data => ({
    type: SET_ACTIVE_PAGE,
    data
});

function request_daily_examines() {
    return {
        type: REQUEST_DAILY_EXAMINES
    }
}

function receive_daily_examines(data) {
    return {
        type: RECEIVE_DAILY_EXAMINES,
        dailyExamineList: data.content,
        totalElements: data.totalElements,
        numberOfElements: data.numberOfElements,
        receiveAt: Date.now()
    }
}

function request_delete_daily_examines() {
    return {
        type: REQUEST_DELETE_DAILY_EXAMINES
    }
}

function receive_request_delete_daily_examines(id) {
    return {
        type: RECEIVE_DELETE_DAILY_EXAMINES_RESULT,
        id
    }
}

export function fetchExamines(page, pageSize) {
    let data = {
        page: page || 1,
        pageSize: pageSize
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_daily_examines());
        apiRequest('/gk/ops/dailyExercise/page', config)
            .then(json => {
                dispatch(receive_daily_examines(json.obj))
            })
            .catch(error => console.log(error));
    }
}

export function deleteExamines(id) {
    return dispatch => {
        dispatch(request_delete_daily_examines());
        return apiRequest(`/gk/ops/dailyExercise/delete/${id}`, {credentials: 'include'})
            .then(response => {
                message.success('操作成功')
                dispatch(receive_request_delete_daily_examines(id))
            })
            .catch(error => console.log(error));
    }
}
