import apiRequest from '../../common/request';
import Cookies from 'js-cookie';
import { message } from 'antd';

const PREFIX = "GOODS_ORDER_LIST_";

export const SET_REDUX_KEY_VALUE = PREFIX + "SET_REDUX_KEY_VALUE";
export const RESET_FILTER_VALUES = PREFIX + "RESET_FILTER_VALUES";
export const UPDATE_ORDER_ITEM_VALUE = PREFIX + "UPDATE_ORDER_ITEM_VALUE";

/** 
 *  更新redux所维护的订单列表数据中指定的那一项
 *  idx: 想要进行修改的目标项的索引位置
 *  key: 想要修改所指定位置对象的哪个键值；value: 其所需要修改成的值
 */
export const updateOrderItemValue = (idx, key, value) => ({
  type: UPDATE_ORDER_ITEM_VALUE,
  idx, key, value
});

export const resetFilterValue = () => ({ type: RESET_FILTER_VALUES });

export const setReduxKeyValue = data => ({
  type: SET_REDUX_KEY_VALUE,
  key: data.key,
  value: data.value
});

/** 加载商品订单列表数据 */
export const fetchGoodsOrderList = data => {
  let url = `/gk/ops/productOrder/page`;
  let config = { 
    method: 'POST', credentials: 'include', headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify(data)
  };
  return async dispatch => {
    dispatch(setReduxKeyValue({ key: 'loadingList', value: true }));
    let json = await apiRequest(url, config);
    if (json.status == 1) {
      json.obj.content.length ? '' : message.info('没有数据，请重新查询');
      dispatch(setReduxKeyValue({ key: 'productOrdersVO', value: json.obj }));
      dispatch(setReduxKeyValue({ key: 'loadingList', value: false }));
    }
  }
}

/** 下载Excel */
export const downExcel = data => {
  let url = '/gk/ops/productOrder/exportOrder';
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Sin-Access-Token': Cookies.get('accessToken'),
      'Sin-UserAgent-Sign': 'SintureGK-WEB',
      'Sin-Client-Type': 3,
      Accept: '*/*',
    },
    body: JSON.stringify(data),
    credentials: 'include'
  };
  return dispatch => {
    fetch(url, config).then(response => {
      response.blob().then(blob => {
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        let fileName = '商品订单数据表.xls';
        a.download = fileName;
        document.body.appendChild(a).click();
        window.URL.revokeObjectURL(url);
      })
    }).catch(err => {
      message.error('下载出错');
    })
  }
}

/** 更新订单的物流信息 */
export const updateOrderHandler = async (data, successCb) => {
  let url = `/gk/ops/productOrder/logistics`;
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Sin-Access-Token': Cookies.get('accessToken'),
      'Sin-UserAgent-Sign': 'SintureGK-WEB',
      'Sin-Client-Type': 3,
      Accept: '*/*',
    },
    body: JSON.stringify(data),
    credentials: 'include'
  };
  let json = await apiRequest(url, config);
  if (json.status === 1) {
    message.success('操作成功');
    successCb && successCb();
  }
}