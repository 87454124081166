import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Select, Input, Button, Modal, message } from "antd";
import {
    fetchClassList, saveOrUpdateClass,
    setReduxKv_InterviewClass, resetFilterStatus
} from "./actions";

import { fetchHeadTeacher } from '../ClassList/actions';
import { fetchQuestionAreas, fetchQuestionYears } from '../QuestionList/actions';
import { checkPermission } from '../../utils/utils';

import './index.scss';

const Option = Select.Option;

class InterviewClass extends React.Component {
    condition2Cn = { year: '年份', area: '地区', className: '班级名称', headTeacher: '班主任', coursePrice: '课程价格', page: '页码', pageSize: '每页多少条' }

    inputConditions = ['className', 'coursePrice']

    selectConditions = ['year', 'area', 'headTeacher']

    initialInterviewClassVO = { year: null, className: null, area: null, headTeacher: null, id: null, coursePrice: 0 }

    state = {
        showModal: false,                                                           // 是否显示模态
        interviewClassVO: this.initialInterviewClassVO,                             // 新增以及修改时候的VO
    };

    /** 获取加载分页数据的参数 */
    generateFetchListData = () => {
        let keys = Object.keys(this.condition2Cn);
        let obj = {};
        keys.map(key => { let val = this.props[key]; val && (obj[key] = val); });
        return obj;
    }

    /** 加载面试班列表数据的handler方法 */
    fetchClassListHandler = () => {
        let obj = this.generateFetchListData();                          // 获取加载分页数据时的参数对象
        this.props.dispatch(fetchClassList(obj));
    }

    componentDidMount() {
        this.fetchClassListHandler();
        this.props.dispatch(fetchHeadTeacher());
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchQuestionYears());
    }

    resetPage = async () => {
        await this.props.dispatch(setReduxKv_InterviewClass({ key: 'page', value: 1 }));
    }

    selectOnChange = async (key, val) => {
        await this.props.dispatch(setReduxKv_InterviewClass({ key: key, value: val }));
        this.fetchClassListHandler();
    }

    inputOnChange = async (val) => {
        await this.props.dispatch(setReduxKv_InterviewClass({ key: 'className', value: val }));
    }


    /** 点击搜索 */
    clickSearch = async () => {
        await this.resetPage();
        this.fetchClassListHandler();
    }

    /** 点击重新搜索 */
    clickResetBtn = async () => {
        await this.props.dispatch(resetFilterStatus());
        this.fetchClassListHandler();
    }


    /** 将在UI层面上值的变化更新到redux上面, needResetPage参数表示是否需要重置分页数据；realTime参数表示是否需要实时加载 */
    value2Redux = async (key, value, needResetPage = false, realTime = false) => {
        await this.props.dispatch(setReduxKv_InterviewClass({ key, value }));
        if (needResetPage) await this.resetPage();
        if (realTime) this.fetchClassListHandler();
    }

    /** 修改班级 */
    modifyClass = classObj => {
        let userfulKeys = Object.keys(this.initialInterviewClassVO);
        let obj = {};
        userfulKeys.map(key => { obj[key] = classObj[key]; });
        this.setState({ interviewClassVO: obj, showModal: true });
    };

    /** 取消只做隐藏模态逻辑，不清空值 */
    modalCancelHandler = () => {
        this.setState({ showModal: false, });
    }

    /** 新增以及修改的时候校验三个输入项 */
    verifyInput = obj => {
        let keys = Object.keys(this.initialInterviewClassVO);
        keys = keys.filter(item => item != 'id');
        let result = keys.every(key => {
            if (!this.state.interviewClassVO[key]) {
                message.warning(`请填写${this.condition2Cn[key]}`);
                return false;
            }
            obj[key] = this.state.interviewClassVO[key];
            return true;
        });
        return result;
    }


    /** 新增以及修改都是走的这个方法 */
    handleOk = async () => {
        let data = {};
        if (!this.verifyInput(data)) return;
        this.state.interviewClassVO.id && (data.id = this.state.interviewClassVO.id);
        let status = await saveOrUpdateClass(data);
        if (status) {
            this.setState({ showModal: false });
            this.fetchClassListHandler();
        }
    }

    /** 点击添加班级 */
    showModalHandler = () => {
        this.setState({ showModal: true, interviewClassVO: this.initialInterviewClassVO });
    }

    /** 生成表头方法 */
    renderHeader = () => (
        <div className="commonTableTitle">
            <span>班级列表</span>
            {checkPermission('interview:clazz:edit') &&
                <Button type="primary" onClick={this.showModalHandler}>添加班级</Button>
            }
        </div>
    );

    /** 确定list数据，key参数表明是哪个状态；containsAll参数表明是否包括所有 */
    generateList = (key, containsAll) => {
        if (containsAll) return this.props[`${key}List`];
        else return this.props[`${key}List`].filter(obj => obj.label !== '全部');
    }

    /** 确定value值, isFilter参数表明是否属于筛选栏；key参数表明键值 */
    generateValue = (isFilter, key) => {
        if (isFilter) return this.props[key];
        else return this.state.interviewClassVO[key];
    }

    /** 确定事件绑定 */
    generateEventBindings = (key, value) => {
        this.setState({ interviewClassVO: { ...this.state.interviewClassVO, [key]: value } });
    }

    /** antd table 的数据格式约定配置 */
    generateColumns() {
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'className',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div>
                            {checkPermission('interview:clazz:gkinfo') &&
                                <Link to={`/interviewStudent?classId=${record.id}`}>
                                    <span>{record.className}</span>
                                </Link> || <span>{record.className}</span>
                            }
                        </div>
                    )
                }
            },
            { title: '课程价格', dataIndex: 'coursePrice', width: '10%', },
            { title: '人数', dataIndex: 'studentNumber', width: '10%', },
            { title: '班主任', dataIndex: 'headTeacher', width: '10%', },
            { title: '年份', dataIndex: 'year', width: '10%', },
            { title: '地区', dataIndex: 'area', width: '10%', },
            {
                title: '操作',
                dataIndex: 'id',
                width: '30%',
                render: (text, record) => {
                    return (
                        <div className="commonTableBtn">
                            {checkPermission('interview:clazz:gklx') &&
                                <Link to={`/interviewClassexercise?classId=${record.id}&className=${record.className}`}>
                                    <Button size="small" type="primary">查看班级练习</Button>
                                </Link>
                            }
                            {checkPermission('interview:clazz:gkxy') && <Link to={`/interviewCandidateList?classId=${record.id}&num=${record.studentNumber}`}>
                                <Button size="small" type="primary">查看班级学员详情</Button>
                            </Link>}
                            {
                                checkPermission('interview:clazz:edit') &&
                                <Button size="small" type="primary" onClick={() => { this.modifyClass(record) }} className="mb5">修改班级</Button>
                            }
                        </div>
                    );
                }
            }
        ];
        return columns;
    }

    /** 改变每页显示大小 */
    showSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setReduxKv_InterviewClass({ key: 'page', value: 1 }));
        await this.props.dispatch(setReduxKv_InterviewClass({ key: 'pageSize', value: pageSize }));
        this.fetchClassListHandler();
    }

    render() {
        let columns = this.generateColumns();
        return (
            <div className="interviewClassPageWrapper">
                {checkPermission('interview:clazz:page') && <div className="commonSearchBox">
                    <div>
                        <label>年份</label>
                        <Select
                            showSearch
                            value={this.props.year}
                            style={{ width: 150 }}
                            onChange={value => this.selectOnChange('year', value)}
                        >
                            <Option value={null} key="">全部</Option>
                            {
                                this.props.yearList.map(obj => (
                                    <Option value={obj} key={obj}>{obj}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>地区</label>
                        <Select
                            showSearch
                            value={this.props.area}
                            style={{ width: 150 }}
                            onChange={value => this.selectOnChange('area', value)}
                        >
                            <Option value={null} key="">全部</Option>
                            {
                                this.props.areaList.map(obj => (
                                    <Option value={obj} key={obj}>{obj}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班主任</label>
                        <Select
                            showSearch
                            value={this.props.headTeacher}
                            style={{ width: 150 }}
                            onChange={value => this.selectOnChange('headTeacher', value)}
                        >
                            <Option value={null} key="">全部</Option>
                            {
                                this.props.headTeacherList && this.props.headTeacherList.slice(1).map(obj => (
                                    <Option value={obj} key={obj}>{obj}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <label>班级名称</label>
                        <Input value={this.props.className} onChange={e => this.inputOnChange(e.target.value)} style={{ width: 400 }} />
                    </div>
                    <div>
                        <Button disabled={this.props.loading} type="primary" onClick={this.clickSearch} className="mr20">搜索</Button>
                        <Button disabled={this.props.loading} onClick={this.clickResetBtn} type="danger">重置</Button>
                    </div>
                </div>}

                {/** 班级列表展示区域 */}
                <div className="tableWrapper">
                    <Table
                        columns={columns}
                        dataSource={this.props.responseObj.content}
                        rowKey={record => record.id}
                        title={this.renderHeader}
                        loading={this.props.loading}
                        pagination={{
                            current: this.props.page,
                            pageSize: this.props.pageSize,
                            total: this.props.responseObj.totalElements,
                            showTotal: total => `共有${total}条数据`,
                            onChange: page => this.value2Redux('page', page, false, true),
                            onShowSizeChange: this.showSizeChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '20', '50', '100']
                        }}
                    />
                </div>

                {/** 添加一节修改班级弹窗 */}
                <Modal
                    title={this.state.interviewClassVO.id ? '修改班级' : '新增班级'}
                    visible={this.state.showModal}
                    onOk={this.handleOk}
                    onCancel={this.modalCancelHandler}
                    wrapClassName='interviewClassPageWrapper'
                >
                    {this.inputConditions.map((key, idx) => (
                        <div key={idx} className="flexRow">
                            <label>{this.condition2Cn[key]}</label>
                            <Input
                                onChange={e => this.generateEventBindings(key, e.target.value)}
                                value={this.generateValue(false, key)} placeholder={`请输入${this.condition2Cn[key]}`} className="w20em"
                            />
                        </div>
                    ))}
                    {
                        this.selectConditions.map((key, idx) => (
                            <div key={idx} className="flexRow">
                                <label>{this.condition2Cn[key]}</label>
                                <Select
                                    showSearch
                                    style={{ width: 120 }}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={value => this.generateEventBindings(key, value)}
                                    value={this.generateValue(false, key)} className="w20em"
                                >
                                    {
                                        this.generateList(key, true).map((obj, idx) => (
                                            <Option value={obj} key={idx}>{obj}</Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        ))
                    }
                </Modal>
            </div>
        )
    }

}

function mapStatetoProps(state) {
    return {
        ...state.interviewClass,
        headTeacherList: (state.classList.headTeacherArr),
        yearList: (state.questions.years),
        areaList: (state.questions.areas)
    };
}

export default connect(mapStatetoProps)(InterviewClass);
