import {
    FETCH_ORDERDETAIL_START,
    FETCH_ORDERDETAIL_SUCCED,
    FETCH_ORDERDETAIL_FAIL,
    POST_WULIU_MESSAGE_SUCCED
} from './actions.js';

const initialState = {
    orderLogistics: {}
};

export const orderDetailReducer = (previouState=initialState, action) => {
    switch(action.type){
        case FETCH_ORDERDETAIL_START:
            return previouState;
        case FETCH_ORDERDETAIL_SUCCED:
            return action.data;
        case FETCH_ORDERDETAIL_FAIL:
            return previouState;
        case POST_WULIU_MESSAGE_SUCCED:
            return previouState;
        default:
            return previouState;
    }
}