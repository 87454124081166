import {
    CHANGE_COMMON,
    FETCH_RANK_DATA_SUCCED,
    FETCH_RANK_DATA_START,
    FETCH_DETAIL_RANK_DATA_SUCCED,
    RESET_ARR_DATA
} from './actions'

const inisitalState = {
    classIdsList: [],
    classArrList: [],       // 所有班级id
    indeterminate: false,   // 全选按钮样式
    checkedList: [],        // 已选班级
    checked: false,         // 全选按钮状态
    total: 0,
    page: 1,
    pageSize: 10,
    data: [],
    detailData: [],         // 详细的测试结果，比如每一道题目的平均正确率以及平均用时
    onFetch: false
};

/** 在每一项排名数据记录中插入一个classId字段 */
function classIdHandler(arr) {
    if (!arr) return [];
    for (let i = 0, len = arr.length; i < len; i++) {
        let classObj = arr[i];
        let classId = classObj.classId;
        let className = classObj.className;
        let datas = classObj.datas || [];
        for (let j = 0, jl = datas.length; j < jl; j++) {
            let record = datas[j];
            record.classId = classId;
            record.className = className;
        }
    }
}


export const exerciseRank = (previousState=inisitalState, action) => {
    switch(action.type) {
        case FETCH_RANK_DATA_SUCCED:
            let arr = action.data.content;            
            classIdHandler(arr);
            return { ...previousState, onFetch: false, data: arr, total: action.data.totalElements };
        case FETCH_RANK_DATA_START:
            return {...previousState, onFetch: true};
        case FETCH_DETAIL_RANK_DATA_SUCCED:
            return {...previousState, detailData: action.data};
        case RESET_ARR_DATA:
            return {...previousState, ...inisitalState};
        case CHANGE_COMMON:
            return {...previousState, [action.key]:action.val};
        default:
            return previousState;
    }
};