import apiRequest from '../../common/request';

const PREFIX = "_QUESTION_SELECT_CARD_COMPONENT_";

export const SET_KEY_VALUE = PREFIX + "SET_KEY_VALUE";
export const RESET_SEARCH_STATUS = PREFIX + "RESET_SEARCH_STATUS";

export const setKeyValue = (key, value) => ({ type: SET_KEY_VALUE, key, value });

export const resetSearchStatus = () => ({ type: RESET_SEARCH_STATUS });

/** 获取历年真题列表 
 * exerciseType==0  行测
 * exerciseType==1  申论
 * exerciseType==gj 公基
 * exerciseType==zc 职测
 * exerciseType==zy 综应
*/
export const fetchHistoryList = (title, type) => {
     let data = {
          query: title,
          page: 1,
          pageSize: 20
     };
     let url = '';
     if(type==0) url = `/gk/ops/historyExamine/page`;
     if(type=='gj') url = `/gk/ops/gj/historyExamine/page`;
     if(type=='zc') url = `/gk/ops/zc/historyExamine/page`;
     let config = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
          },
     };
     return dispatch => {
          apiRequest(url, config).then(json => {
               dispatch(setKeyValue('historyList', json.obj && json.obj.content || []));
          })
     }
}

/** 获取一套历年真题详情 
 * exerciseType==0  行测
 * exerciseType==1  申论
 * exerciseType==gj 公基
 * exerciseType==zc 职测
 * exerciseType==zy 综应
*/
export const fetchHistoryInfo = (id, type, cb) => {
     let url = '';
     if(type==0) url = `/gk/ops/historyExamine/getById?id=${id}`;
     if(type=='gj') url = `/gk/ops/gj/historyExamine/getById/${id}`
     if(type=='zc') url = `/gk/ops/zc/historyExamine/getById/${id}`
     let config = {
          method: 'GET',
          headers: { 'Accept': 'application/json' },
     };
     return dispatch => {
          dispatch(setKeyValue('selLoading', true));
          apiRequest(url, config).then(json => {
               dispatch(setKeyValue('selLoading', false));
               cb && cb(true, '', json.obj.questions)
          })
     }
}