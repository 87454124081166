import {
    FILL_EXAMINE_CLASS_ID,
    FILL_EXAMINE_TITLE,
    SELECT_QUESTION,
    UPDATE_SEL_QUESTION,
    RESET_ALL_DATA,
    RECEIVE_EXAMINE_INFO
} from './action';

const initialState = {
    isFetchingExamine: false,
    isFetchingQuestions: false,
    isUpdating: false,
    isUpdated: false,
    categoryId: '-1',
    info: {
        title: "",
        quesArr: [],
        selQuesList: [],
        classId: [],
        classIdDate: []
    }
};

export function interviewClassTest(state=initialState, action) {
    switch(action.type) {
        case FILL_EXAMINE_CLASS_ID:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case FILL_EXAMINE_TITLE:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case SELECT_QUESTION:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    quesArr: action.value,
                })
            });
        case UPDATE_SEL_QUESTION:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {
                    selQuesList: action.value
                })
            }); 
        case RESET_ALL_DATA:
            return Object.assign({}, state, { info: {
                title: "",
                quesArr: [],
                selQuesList: [],
                classIdDate: []
            }});
        case RECEIVE_EXAMINE_INFO: 
            return Object.assign({}, state, { info: action.data});
        default:
            return state;
    }
}