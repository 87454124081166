import React, { Component } from 'react'
import { connect } from "react-redux";
import { Popover, Spin, Modal, Tag, Input, Select, Button, Tooltip, message, Table, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { resetFilterStatus, changeCommon, fetchQuestions, fetchQuestionAreas, fetchQuestionYears, fetchQuestionCollection } from "./actions";
import { fetchQuestionCategories } from "../ModuleSetting/actions";
import { fetchTag } from "../EssayCategory/actions";
import QRCode from 'qrcode';
import Preview from '../../components/Preview';
import { checkPermission, downWord } from '../../utils/utils';
import apiRequest from '../../common/request';

const { Option } = Select;
class QuestionList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            qrcodeShow: false,
            showModal: false,
            deleteId: '',
            curQuestionIndex: 0,
            previewShow: false,
            showVideoModal: false,
            signedVideoUrl: '',
            p: 1,                   // word下载当前页数
            downLoading: false,     // word下载加载状态
            totalPages: 0,          // word下载总页数
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchQuestionCategories());
        this.props.dispatch(fetchTag());
        this.sendPostPagerequest();
    }

    sendPostPagerequest = async () => {
        let data = {
            page: this.props.page,
            year: this.props.year,
            province: this.props.province,
            id: this.props.categoryId,
            searchName: this.props.searchName,
            options: this.props.options,
            source: this.props.source,
            questionTag: this.props.questionTag,
            pageSize: this.props.pageSize
        };
        await this.props.dispatch(fetchQuestions(data));
    }

    /** 下载试题为word */
    downloadQuestion = (isShow) => {
        let that = this;
        let flag = isShow;
        let { p } = this.state;
        let data = {
            page : p,
            qdesc: 'asc',
            sortField: 'id',
            years: this.props.year.toString(),
            province: this.props.province.toString(),
            categoryId: this.props.categoryId=='-1' ? '' : this.props.categoryId,
            options: this.props.options,
            query: this.props.searchName,
            source: this.props.source,
            questionTag: this.props.questionTag,
            pageSize: 500
        };
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
        };
        that.setState({ downLoading: true});
        apiRequest('/gk/ops/question/downloadQuestion', config)
            .then(async json => {
                let totalPages = json.obj.totalPages;
                let res = json.obj.content || [];
                if(json.obj && !json.obj.content.length) return message.success('试题为空');
                let last = json.obj.last;
                let fileName = this.props.year.toString() + res[0].categoryName + p;
                await downWord(that.getContentInfo(res), p, fileName, flag);
                that.setState({ p: ++p, totalPages});
                if(!last) {        // 不是最后一页，继续下载
                    this.downloadQuestion(flag);
                } else {        // 最后一页，停止下载，恢复第一页
                    message.success('下载成功');
                    that.setState({ p: 1, downLoading: false, totalPages: 0});
                }
            })
            .catch(error => {
                console.log(error);
                message.error('下载失败');
                that.setState({ p: 1, downLoading: false, totalPages: 0});
            });
    }

    /** 筛选掉试题缺失的题目 */
    getContentInfo = (res) => {
        return res.filter((item, idx)=>{
            return (!item.content.includes('试题暂缺'));             
        });;
    }

    /** 清空搜索条件 */
    resetInput = async () => {
        await this.props.dispatch(resetFilterStatus());
        this.sendPostPagerequest();
    }

    /** 把标签数字转换为中文字面意思 */
    questionTag2Cn = number => (
        number === 1 ? <Tag color="red">其他</Tag> :
            number === 2 ? <Tag color="cyan">原创</Tag> :
                <Tag color="gold">无标签</Tag>
    );

    /** 点击播放视频按钮 */
    playVideo = record => {
        this.setState({ showVideoModal: true, signedVideoUrl: record.signedVideoUrl });
    }

    /** 关闭播放视频蒙层 */
    closeVideoModal = () => {
        this.setState({ showVideoModal: false, signedVideoUrl: '' });
    }

    /** 生成二维码，然后画带logo的二维码后面优化 */
    getQRCode = (record) => {
        let version = 'xz1';
        this.imgId = record.id;
        this.name = record.categoryName;
        this.imgContent = record.content;
        QRCode.toDataURL(`sinture://topic.service/scan?bn=${version}&id=${record.id}&type=16`)
            .then(url => {
                this.ewmUrl = url; // 本来这个二维码已经有用，但是还要加logo
                this.setState({ qrcodeShow: true });
            })
            .catch(err => {
                console.error(err)
            });
    }

    /** 下载生成带logo的二维码 */
    handleOkQrcode = () => {
        let url = this.ewmUrl;                        // 获取图片地址
        let a = document.createElement('a');          // 创建一个a节点插入的document
        let event = new MouseEvent('click');          // 模拟鼠标click点击事件
        a.download = this.name + this.imgId;          // 设置a节点的download属性值
        a.href = url;                                 // 将图片的src赋值给a节点的href
        a.dispatchEvent(event);                       // 触发鼠标点击事件
    }

    /** 展示收藏按钮文字及更新页面 */
    sureCollectBtn = async record => {
        let response = await fetchQuestionCollection(record.id);
        if (response.status == 1) {
            message.success('操作成功');
            this.sendPostPagerequest();
        } else {
            message.error(response.errorMes || '操作失败');
        }
    }

    /** 关闭模态框 */
    handleCancelQrcode = () => {
        this.setState({ qrcodeShow: false })
    }

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };
    /** 生成antd table的配置信息 */
    generateTableColumns = () => {
        let columns = [
            {
                title: '内容',
                width: '20%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.content)}>
                        {checkPermission('sys:question:gksee') && <Link to={`/question/${record.id}`} className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.content }}></Link>
                        || <div className="ellipsis2" dangerouslySetInnerHTML={{__html: record.content}}></div>}
                    </Tooltip>
                ),
            },
            {
                title: '分类',
                width: '10%',
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: '类型',
                width: '10%',
                dataIndex: 'quesTypeName',
                key: 'quesTypeName'
            },
            {
                title: '标签',
                width: '5%',
                render: (text, record) => this.questionTag2Cn(record.questionTag)
            },
            {
                title: '来源',
                width: '10%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.source)}>
                        <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.source }}></span>
                    </Tooltip>
                ),
            },
            {
                title: '解析视频',
                width: '10%',
                render: (text, record) => {
                    if (record.signedVideoUrl) {
                        return <Button type="primary" size="small" onClick={() => this.playVideo(record)}>播放视频</Button>
                    } else {
                        return <Button disabled ghost size="small">无视频</Button>
                    }
                }
            },
            {
                title: '疑难解答',
                width: '10%',
                render: (text, record) => (
                    <Tooltip title={this.getTooltip(record.issue)}>
                        <span className="ellipsis2" dangerouslySetInnerHTML={{ __html: record.issue }}></span>
                    </Tooltip>
                ),
            },
            {
                title: '二维码',
                width: '10%',
                render: (text, record) => {
                    return (
                        checkPermission('sys:question:gkewm') && <Button size="small" type="primary" onClick={() => this.getQRCode(record)}>点击生成</Button>
                    )
                }
            },
            {
                title: '收藏',
                width: '10%',
                render: (text, record) => {
                    if (record.collection) {
                        return checkPermission('sys:question:collect') && <Button type='danger' size='small' onClick={() => this.sureCollectBtn(record)}>取消收藏</Button>
                    } else {
                        return checkPermission('sys:question:collect') && <Button type="primary" size='small' onClick={() => this.sureCollectBtn(record)}>点击收藏</Button>
                    }
                }
            },
            {
                title: '预览',
                width: '5%',
                render: (text, record, index) => {
                    return (
                        checkPermission('sys:question:gkdpre') && <Button size="small" type="primary" onClick={() => this.currentPreview(index)}>预览</Button>
                    )
                }
            }
        ];
        return columns;
    }

    commChange = async (val, key) => {
        await this.props.dispatch(changeCommon({ key, val }));
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        await this.sendPostPagerequest();
    }
    onChangePage = async (current, pageSize) => {
        await this.props.dispatch(changeCommon({ key: 'page', val: current }));
        await this.props.dispatch(changeCommon({ key: 'pageSize', val: pageSize }));
        await this.sendPostPagerequest();
    }

    onPressEnter = async () => {
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        this.sendPostPagerequest();
    }

    selectFirstCate = async (val) => {
        await this.props.dispatch(changeCommon({ key: 'fstCategoryId', val: val }));
        await this.props.dispatch(changeCommon({ key: 'categoryId', val: val }));
        await this.props.dispatch(changeCommon({ key: 'secondCategories', val: [] }));
        await this.props.dispatch(changeCommon({ key: 'thirdCategories', val: [] }));
        await this.props.dispatch(changeCommon({ key: 'secCategoryId', val: '-1' }));
        await this.props.dispatch(changeCommon({ key: 'trdCategoryId', val: '-1' }));
        this.props.categories.map(item => {
            item.id === Number(val) && this.props.dispatch(changeCommon({ key: 'secondCategories', val: item.subset }))
        });
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        this.sendPostPagerequest();
    }

    selectSecondCate = async (val) => {
        let categoryId = (val=='-1' ? this.props.fstCategoryId : val);
        await this.props.dispatch(changeCommon({ key: 'secCategoryId', val: val }));
        await this.props.dispatch(changeCommon({ key: 'categoryId', val: categoryId }));
        await this.props.dispatch(changeCommon({ key: 'thirdCategories', val: [] }));
        await this.props.dispatch(changeCommon({ key: 'trdCategoryId', val: '-1' }));
        this.props.secondCategories.map(item => (
            item.id === Number(val) && this.props.dispatch(changeCommon({ key: 'thirdCategories', val: item.subset }))
        ));
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        this.sendPostPagerequest();
    }

    selectThirdCate = async (val) => {
        let categoryId = (val=='-1' ? this.props.secCategoryId : val);
        await this.props.dispatch(changeCommon({ key: 'trdCategoryId', val: val }));
        await this.props.dispatch(changeCommon({ key: 'categoryId', val: categoryId }));
        await this.props.dispatch(changeCommon({ key: 'page', val: 1 }));
        this.sendPostPagerequest();
    }

    showPreview = () => {
        if (this.props.questionList.length == 0) {
            return;
        }
        this.setState({
            previewShow: true
        })
    }

    currentPreview = (index) => {
        if (this.props.questionList.length == 0) {
            return;
        }
        this.setState({
            previewShow: true,
            curQuestionIndex: index
        })
    }

    hidePreview = () => {
        this.setState({
            previewShow: false
        })
    }

    /** 上一题 */
    preQuestion = async () => {
        let { page, pageSize } = this.props;
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex === 0) {
            if (page > 1) { // 如果是第一题，并且不是第一页，请求上一页，索引重置为上一页最后一题
                await this.props.dispatch(changeCommon({ key: 'page', val: page - 1 }));
                await this.sendPostPagerequest();
                setTimeout(() => {
                    this.setState({
                        curQuestionIndex: pageSize - 1
                    });
                }, 0)
            } else if (page == 1) { // 如果是第一题，并且是第一页
                message.info('前面已经没有题目了');
            }
        } else {
            this.setState({ // 索引递减
                curQuestionIndex: curQuestionIndex - 1
            });
        }
    }

    /** 下一题 */
    nextQuestion = async () => {
        let { page, pageSize } = this.props;
        const curQuestionIndex = this.state.curQuestionIndex;
        if (curQuestionIndex > pageSize - 2) {
            if (page < this.props.totalPages) { // 如果是最后一题，并且不是最后一页，请求下一页，索引重置为下一页第一题
                await this.props.dispatch(changeCommon({ key: 'page', val: page + 1 }));
                this.sendPostPagerequest();
                this.setState({
                    curQuestionIndex: 0
                });
            }
        } else {
            if (curQuestionIndex == this.props.questionList.length - 1) { // 最后一题
                message.info('已经是最后一题了');
                return;
            }
            this.setState({
                curQuestionIndex: curQuestionIndex + 1
            });
        }
    }

    tableTitle = () => (
        <div className="commonTableTitle">
            <div>试题列表</div>
            <div>
                {checkPermission('sys:question:download') && 
                <Popover 
                    title="下载说明" 
                    content={<div>
                        <p>1.每套最多生成500题</p>
                        <p>2.上面的筛选条件就是生成word的内容,请至少选择一个分类</p>
                        <p>3.想要默认自动下载,请找到浏览器的下载设置,关闭下载前询问文件保存位置</p>
                        <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(1)} style={{margin: '10px'}}>有解析</Button>
                        <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(2)} style={{margin: '10px'}}>无解析</Button>
                        <Button disabled={this.state.downLoading || this.props.categoryId=='-1'} type="primary" size="small" onClick={()=>this.downloadQuestion(3)} style={{margin: '10px'}}>纯解析</Button>
                    </div>}
                >
                    <Button disabled={this.props.categoryId=='-1'} type="link">下载word-共{Math.ceil(this.props.totalElements/500 || 0)}份</Button>
                </Popover>}
                {checkPermission('sys:question:edit') &&<Button type="primary" disabled={this.props.isFetching} onClick={() => this.props.history.push('/publishQuestion')} style={{marginRight: '20px'}}>添加试题</Button>}
                {checkPermission('sys:question:gkpre') && <Button type="primary" disabled={this.props.isFetching} onClick={this.showPreview}>预览试题</Button>}
            </div>
        </div>
    )

    render() {
        const columns = this.generateTableColumns();
        let { source, year, province, searchName, options, questionTag, fstCategoryId, secCategoryId, trdCategoryId, secondCategories, thirdCategories, questionList, areas, years, categories, isFetching } = { ...this.props };
        let { p, downLoading, totalPages, signedVideoUrl, showVideoModal } = this.state;
        const curQuestion = questionList.length > 0 ? questionList[this.state.curQuestionIndex] : {};

        return (
            
            <Spin tip={totalPages && `已完成${p}套, 共${totalPages}套` || '加载中...'} spinning={downLoading}>
                <Breadcrumb>
                    <Breadcrumb.Item>试题</Breadcrumb.Item>
                    <Breadcrumb.Item>试题列表</Breadcrumb.Item>
                </Breadcrumb>

                <div className="commonSearchBox">
                    <div className="itemSearchBox">
                        <label>年份</label>
                        <Select value={year} mode="multiple" style={{ width: 400 }} onChange={value => { this.commChange(value, 'year') }}>
                            {years && years.map((year, idx) => {
                                return <Option key={idx} value={year + ''}>{year}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>省份</label>
                        <Select style={{ width: 400 }} value={province} mode="multiple" onChange={value => { this.commChange(value, 'province') }}>
                            {areas && areas.map((item, idx) => {
                                return <Option key={idx} value={item + ''}>{item}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>标签</label>
                        <Select style={{ width: 150 }} value={questionTag || ''} onChange={value => { this.commChange(value, 'questionTag') }}>
                            <Option value="">全部</Option>
                            {(this.props.tags || []).map((tag, idx) => {
                                return <Option key={idx} value={tag.value + ""}>{tag.desc}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>一级分类</label>
                        <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={fstCategoryId} onChange={value => { this.selectFirstCate(value) }}>
                            <Option value="-1">全部</Option>
                            {(categories || []).map((category, idx) => (
                                <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>二级分类</label>
                        <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={secCategoryId} disabled={!secondCategories.length} onChange={value => { this.selectSecondCate(value) }}>
                            <Option value="-1">全部</Option>
                            {secondCategories.map((category, idx) => (
                                <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>三级分类</label>
                        <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={trdCategoryId} disabled={!thirdCategories.length} onChange={value => { this.selectThirdCate(value) }}>
                            <Option value="-1">全部</Option>
                            {thirdCategories.map((category, idx) => (
                                <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="itemSearchBox">
                        <label>题目搜索</label>
                        <Input value={searchName} onChange={e => this.props.dispatch(changeCommon({ key: 'searchName', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 400 }} placeholder="回车键查询" />
                    </div>
                    <div className="itemSearchBox">
                        <label>选项搜索</label>
                        <Input value={options} onChange={e => this.props.dispatch(changeCommon({ key: 'options', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 400 }} placeholder="回车键查询" />
                    </div>
                    <div className="itemSearchBox">
                        <label>试题来源</label>
                        <Input value={source} onChange={e => this.props.dispatch(changeCommon({ key: 'source', val: e.target.value }))} onPressEnter={this.onPressEnter} style={{ width: 400 }} placeholder="回车键查询" />
                    </div>
                    <div className="itemSearchBox">
                        <Button type="primary" disabled={isFetching} onClick={() => this.sendPostPagerequest()}>搜索</Button>
                    </div>
                    <div className="itemSearchBox">
                        <Button danger disabled={isFetching} onClick={() => this.resetInput()}>重置</Button>
                    </div>
                </div>

                <Table
                    columns={columns}
                    dataSource={this.props.questionList}
                    rowKey={record => record.id}
                    title={() => this.tableTitle()}
                    loading={this.props.isFetching}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        current: this.props.page,
                        pageSize: this.props.pageSize,
                        total: this.props.totalElements,
                        onChange: this.onChangePage,
                        showQuickJumper: true,
                        showTotal: total => `共有${total}条数据`,
                    }}
                />

                {/** 查看解析视频 */}
                <Modal
                    title="播放解析视频"
                    visible={showVideoModal}
                    footer={null}
                    width="650px"
                    closable={false}
                    onCancel={this.closeVideoModal}
                >
                    <video width="600px" className="videoClass" src={signedVideoUrl} controls="controls" />
                </Modal>

                <Modal title="试题二维码" okText="下载" visible={this.state.qrcodeShow} onOk={this.handleOkQrcode} onCancel={this.handleCancelQrcode}>
                    <div style={{ display: 'flex' }}>
                        <img width="160px" height="160px" src={this.ewmUrl} alt='ewm' />
                        <div>
                            <p style={{ color: 'red' } }>说明：二维码下载名称默认用试题分类+id命名，可自行更改</p>
                            <h6>题目内容:</h6>
                            <p dangerouslySetInnerHTML={{ __html: this.imgContent }}></p>
                        </div>
                    </div>
                </Modal>
                {this.state.previewShow ?
                    <Preview
                        fromType={0}
                        previewShow={this.state.previewShow}
                        hidePreviewCb={this.hidePreview}
                        question={curQuestion}
                        pre={this.preQuestion}
                        next={this.nextQuestion}
                    /> : null}
            </Spin>
        )
    }
}

function mapStatetoProps(state) {
    const {
        source,
        year,//已选年份
        province,      //省份
        searchName,  //试卷关键词
        options,         //选项关键词
        questionTag, //标签
        categoryId,
        fstCategoryId,
        secCategoryId,
        trdCategoryId,
        secondCategories,
        thirdCategories,
        items: questionList,
        totalPages,
        totalElements,
        numberOfElements,
        areas,
        years,
        page,
        pageSize,
        isFetching
    } = state.questions;

    const {
        items: categories
    } = state.questionCategories;

    const { tags } = state.essayCategory;

    return {
        source,
        year,               //已选年份
        province,           //省份
        searchName,         //试卷关键词
        options,            //选项关键词
        questionTag,        //标签
        categoryId,
        fstCategoryId,
        secCategoryId,
        trdCategoryId,
        secondCategories,
        thirdCategories,
        categories,
        tags,
        page,
        isFetching,
        questionList,
        areas,
        years,
        totalPages,
        totalElements,
        numberOfElements,
        pageSize,
    };
}

export default connect(mapStatetoProps)(QuestionList);