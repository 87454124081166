import apiRequest from '../../common/request';
import { message } from 'antd';

const PREFIX = "INTERVIEW_CLASS_EXERCISE";

export const RECEIVE_EXERCISE = PREFIX + 'RECEIVE_EXERCISE';
export const SEARCH_DATA_ARRIVE = PREFIX + 'SEARCH_DATA_ARRIVE';
export const BEGIN_FETCHING_DATA = PREFIX + 'BEGIN_FETCHING_DATA';
export const SET_EXERCISE_LIST = PREFIX + "SET_EXERCISE_LIST";
export const SET_VALUE = PREFIX + "SET_VALUE";

export const setExerciseList = data => {
    return {
        type: SET_EXERCISE_LIST, 
        data
    }
};

export const setValue = (key, value) => ({ type: SET_VALUE, key, value });

export function searchDataArrives(data) {
    return {
        type: SEARCH_DATA_ARRIVE,
        data
    }
};

export function receive_exercise_list(data) {
    return {
        type: RECEIVE_EXERCISE,
        exerciseList: data.content,
        exerciseTotalElements: data.totalElements,
    }
};

/** 更新班级测试 */
export function updateClassTests(obj) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
      apiRequest('/gk/ops/class/exercise/saveOrUpdateExercise', config).then(json => {
          json.status == 1 ? message.success('操作成功') : message.error(json.errorMes)         
      }).catch(e => {});
    }
}

function beginFetch() {
    return {
        type: BEGIN_FETCHING_DATA
    };
}

/** 获取面试班级练习列表数据 */
export function fetchInterviewExercise(params) {
    let data = {
        page: params.page || 1,
        pageSize: params.pageSize || 10,
        classId: +params.id,
        exerciseType: params.type
    }
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    };
    return dispatch => {
        return apiRequest(`/gk/ops/interviewClass/getClassExercise`, config)
            .then(json => {
              dispatch(receive_exercise_list(json.obj))
            })
            .catch(error => console.log(error));
    }
};

/** 搜索班级测试 */
export function searchClassTests(obj) {
    let config = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return dispatch => {
        dispatch(beginFetch());
        apiRequest(`/gk/ops/class/exercise/page`, config)
        .then(json => {
            let result = json.obj || {};
            dispatch(searchDataArrives(result));
        })
    }
}