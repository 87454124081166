import { message } from 'antd';
import apiRequest from '../../common/request'

const PREFIX = "DAILY_EXAMINE_RELEASE_";

export const REQUEST_QUESTIONS = PREFIX + "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = PREFIX + "RECEIVE_QUESTIONS";

export const FILL_EXAMINE_TITLE = PREFIX + "FILL_EXAMINE_TITLE";
export const FILL_EXAMINE_LIMIT_TIME = PREFIX + "FILL_EXAMINE_LIMIT_TIME";
export const FILL_EXAMINE_UP_TIME = PREFIX + "FILL_EXAMINE_UP_TIME";

export const SELECT_QUESTION = PREFIX + "SELECT_QUESTION";
export const ORDER_QUESTION = PREFIX + "ORDER_QUESTION";
export const REQUEST_PUBLISH_EXAMINE = PREFIX + "REQUEST_PUBLISH_EXAMINE";
export const RECEIVE_PUBLISH_EXAMINE_RESULT_SUC = PREFIX + "RECEIVE_PUBLISH_EXAMINE_RESULT_SUC";
export const RECEIVE_PUBLISH_EXAMINE_RESULT_FAIL = PREFIX + "RECEIVE_PUBLISH_EXAMINE_RESULT_FAIL";
export const FILL_EXAMINE_QUESTIONS = PREFIX + "FILL_EXAMINE_QUESTIONS";
export const FILL_EXAMINE_QARR = PREFIX + "FILL_EXAMINE_QARR";

export const RESET_DAILY_EXAMINE = PREFIX + "RESET_DAILY_EXAMINE";

function request_publish_examine() {
    return {
        type: REQUEST_PUBLISH_EXAMINE
    }
}

function receive_publish_examine_result_suc() {
    return {
        type: RECEIVE_PUBLISH_EXAMINE_RESULT_SUC
    }
}
function receive_publish_examine_result_fail() {
    return {
        type: RECEIVE_PUBLISH_EXAMINE_RESULT_FAIL
    }
}

function request_questions() {
    return {
        type: REQUEST_QUESTIONS
    }
}

function receive_questions(data) {
    return {
        type: RECEIVE_QUESTIONS,
        questionList: data.content,
        totalPages: data.totalPages,
        receiveAt: Date.now()
    }
}

function reset_daily_examine() {
    return {
        type: RESET_DAILY_EXAMINE
    }
}

export function fill_examine_title(value) {
    return {
        type: FILL_EXAMINE_TITLE,
        key: 'title',
        value
    }
}

export function fill_examine_limit_time(value) {
    return {
        type: FILL_EXAMINE_LIMIT_TIME,
        key: 'limitTime',
        value
    }
}

export function fill_examine_questions(value) {
    return {
        type: FILL_EXAMINE_QUESTIONS,
        key: 'selQuesList',
        value
    }
}

export function fill_examine_qArr(value) {
    return {
        type: FILL_EXAMINE_QARR,
        key: 'quesArr',
        value
    }
}

export function fill_examine_up_time(value) {
    return {
        type: FILL_EXAMINE_UP_TIME,
        key: 'publishDate',
        value
    }
}

export function publishExamine(examineInfo, cb) {
    let data = {
        limitTime: examineInfo.limitTime,
        quesArr: examineInfo.quesArr,
        title: examineInfo.title,
        publishDate: examineInfo.publishDate
    };
    let config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include'
    };

    return dispatch => {
        dispatch(request_publish_examine());
        return apiRequest("/gk/ops/dailyExercise/saveOrUpdate", config)
            .then(json => {
                dispatch(receive_publish_examine_result_suc());
                message.success('操作成功');
                dispatch(reset_daily_examine());
                cb && cb();
            })
            .catch(error => {
                dispatch(receive_publish_examine_result_fail());
                console.log(error)
            });
    }
}
