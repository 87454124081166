import {
    RECEIVE_DELETE, RECEIVE_MATERIALS, REQUEST_DELETE, REQUEST_MATERIALS,
    SET_ACTIVE_PAGE, SET_PAGE_SIZE, SET_VALUE, RESET_QUERY_VALUE
} from "./action";

export function materialQuestionList(state = {
    isFetching: false,
    isDeleting: false,
    items: [],
    totalElements: 1,
    type: 'xc',
    /** 以下都是query字符串 */
    options: '',                            // 根据选项进行搜索
    page: 1,                                // 当前第几页
    pageSize: 10,                           // 每页显示多少条数据
    query: '',                              // 材料查询内容
}, action) {
    switch (action.type) {
        case REQUEST_MATERIALS:
            return {...state, isFetching: true};
        case RECEIVE_MATERIALS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.items,
                totalElements: action.totalElements,
            });
        case REQUEST_DELETE:
            return {...state, isDeleting: true};
        case RECEIVE_DELETE:
            return Object.assign({}, state, {
                isDeleting: false,
                items: [...state.items.filter(item => item.id !== action.id)]
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, { page: action.data });
        case SET_VALUE:
            return Object.assign({}, state, { [action.key]: action.val });
        case RESET_QUERY_VALUE:
            return Object.assign({}, state, { 
                options: '',
                page: 1,
                pageSize: 10,
                query: '',
            });
        default:
            return state;
    }
}