import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons'
import { Spin, Select, Radio, Button, Table, Upload, message, Modal, Input, Popconfirm, Tooltip, DatePicker } from 'antd';
import {
    setSortList,
    studentCheckExercise,
    set_filter_status,
    get_day_num,
    get_course_metadata,
    get_class_learninfo,
    set_current_course,
    set_current_day,
    show_size_change,
    set_current_page,
    down_class_excel,
    fetch_class_detail,
    save_update_student,
    set_student_list,
    deleteStudentFun
} from './actions';
import { fetchSearchClass } from '../Inquiry/action';
import { checkPermission, getSearchQuery } from '../../utils/utils';
import Cookies from 'js-cookie';
import './index.scss';
import { WORK_FILTER_STATUS_ALL } from '../../common/consts';
import moment from 'moment';

const RadioGroup = Radio.Group;
const Option = Select.Option;

/**
 * 根据班级名称返回班级对象
 * @param {String} className: 选中的班级名称
 */
function className2classObj(className, classList) {
    let classObj = {};
    for (let i = 0, len = classList.length; i < len; i++) {
        classObj = classList[i];
        if (classObj['className'] == className) {
            break;
        }
    }
    return classObj;
}

class ClassDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            studentNameNew: '',             // 要新增的学员姓名
            studentNumberNew: '',           // 要新增的学员手机号码
            studentRemarkNew: '',
            showEdit: false,                // 是否显示编辑学员的模态
            activeStudentObj: {},           // 处于焦点状态下的学员
            originActiveStudentObj: {},     // 被编辑学员的原始数据
            searchStudentName: '',            // 搜索的学员的名字
            filterDropdownVisible: false,       // 输入框是否可见
            className: '',
            sort: 1
        };
    }

    async componentDidMount() {
        let classId = getSearchQuery('classId');
        await this.props.fetchClassDetail(classId);
        await this.courseInit();
        this.getClassLearnInfoFun();                                        // 这个方法无论如何都应该被调用的，因为有可能学员数据正在实时变化
    }

    getTooltip = (content) => {
        return <div>{content}</div>
    };

    getClassLearnInfoFun = async () => {
        let params = {
            classNo: this.props.currentClassObj.classNo,
            courseId: this.courseName2courseId(),
            courseNum: this.props.currentDay || 1,
            page: this.props.currentPage,
            pageSize: this.props.showSize,
        };
        if (this.props.filterStatus == 'DONE') {
            params.status = 1;
        }
        if (this.props.filterStatus == 'NOT') {
            params.status = 0;
        }
        await this.props.getClassLearnInfo(params);
    }

    courseName2courseId = () => {
        let obj = this.props.currentClassObj || {};
        return obj.courseId;
    }

    courseInit = async () => {
        let currentClassObj = this.props.currentClassObj || {};
        let courseName = currentClassObj.courseName || '';
        await this.courseNameChangeChain(courseName, false);
    }

    fetchCourseMetadata = () => {
        let courseId = this.courseName2courseId();
        let courseNum = this.props.currentDay || 1;
        this.props.getCourseMetadata(courseId, courseNum);
    }

    /** 课程名字发生变化 */
    handleCourseNameChange = async value => {
        await this.courseNameChangeChain(value, true);
        await this.props.setCurrentPage(1);                             // 初始化为第1页
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 课程名字联动变化逻辑，参数isFromCourseNameChange表明是否是切换了课程 */
    courseNameChangeChain = async (value, isFromCourseNameChange) => {
        await this.props.setCourseName(value);                    // 课程名发生变化并不会影响当前的currentClassObj
        if (isFromCourseNameChange) {                             // 表明是改变课程名所引起的连锁变化
            await this.props.setCurrentDay(1);
        } else {                                                  // 表明是通过普通页面来的
            await this.props.setCurrentDay(this.props.currentDay || 1);
        }
        let courseId = this.courseName2courseId();
        this.props.getDayNum(courseId);
        this.fetchCourseMetadata();
    }

    /** 天数发生变化，筛选状态初始化为全部 */
    handleDayChange = async value => {
        await this.props.setCurrentDay(value);
        this.fetchCourseMetadata();
        // await this.props.setFilterStatus('ALL');        // 初始化筛选状态位全部
        await this.props.setCurrentPage(1);             // 初始化为第1页

        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 班级名进行筛选 */
    filterClassName = (value, option) => {
        return option.props.children.indexOf(value) >= 0;
    }

    /** 筛选状态发生改变 */
    changeFilterStatus = async event => {
        let value = event.target.value;
        await this.props.setFilterStatus(value);
        await this.props.setCurrentPage(1);             // 初始化为第1页
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 修改每页显示多少条数据 */
    showSizeChangeHandler = async (current, size) => {
        await this.props.showSizeChange(size);
        await this.props.setCurrentPage(1);           // 初始化为第一页
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 页码改变的时候触发 */
    paginationChange = async page => {
        await this.props.setCurrentPage(page);
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 文件上传的配置 */
    uploadSetting = {
        name: 'file',
        accept: '.xls,.xlsx',         // 如果选定的文件格式不再局限于xls了，放开这个限制
    }

    uploadHandler = async obj => {
        let status = obj && obj.file && obj.file.response && obj.file.response.status;
        let errorMes = obj && obj.file && obj.file.response && obj.file.response.errorMes;
        let res = obj && obj.file && obj.file.response && obj.file.response.obj;        
        if (status == 2) {
            message.error(`${errorMes}`);
        } else if (status == 3) {
            message.error('登录失效');
        } else if (status == 1) {
            message.success('上传成功');
            res.length && res.map(item=>{
                item.classExerciseTitle.map(items=>{
                    message.error(`手机号为${item.mobile}的学员已经做过${items}`);
                })
            })
            await this.getClassLearnInfoFun();
            this.setState({ searchStudentName: '' });
        }
    }

    renderFooter = () => {
        let headers = {
            'Sin-Access-Token': Cookies.get('accessToken'),
            'Sin-UserAgent-Sign': 'SintureGK-WEB',
            'Sin-Client-Type': 3,
        };
        return (
            <div className="footer-container">
                {checkPermission('sys:student:edit') && <Button className="marginRight" type="primary" onClick={this.showModal}>添加学员</Button>}
                {checkPermission('sys:student:upload') && <Upload
                    {...this.uploadSetting}
                    onChange={this.uploadHandler}
                    headers={headers}
                    action={`/gk/ops/student/uploadStudentInfo?classId=${this.props.currentClassObj.id}`}
                >
                    <Button type="primary">上传学员Excel表格</Button>
                </Upload>}
                <Button danger style={{marginLeft: '20px'}} onClick={()=>this.upSort()}>更新顺序</Button>
            </div>
        );
    }

    /** 点击按钮显示新增学员模态 */
    showModal = () => {
        let sort = this.props.studentLearnInfo.content && this.props.studentLearnInfo.content.length+1 || 1;
        this.setState({ visible: true, sort });
    }

    /** 点击模态的确认，此时会进行添加学员操作 */
    handleModalOk = async () => {
        if (!this.state.studentNumberNew) {
            message.error('请填写手机号');
            return;
        }
        if (!this.state.studentNameNew) {
            message.error('请填写学员名');
            return;
        }
        let params = {
            sort: this.state.sort,
            classId: this.props.currentClassObj.id,
            mobile: this.state.studentNumberNew,
            studentName: this.state.studentNameNew,
            remark: this.state.studentRemarkNew
        };
        await save_update_student(params, this.successMesHandler, this.errorMesHandler);
        this.setState({ visible: false, sort: 1, studentNumberNew: '', studentNameNew: '', studentRemarkNew: '' });
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    successMesHandler = mes => { message.success(mes); }

    errorMesHandler = mes => { message.error(mes); }

    /** 点击模态的取消 */
    handleModalCancel = () => {
        this.setState({ visible: false, studentNumberNew: '', studentNameNew: '', studentRemarkNew: '' });
    }

    /** 输入新增的学员姓名 */
    inputUserName = event => {
        let studentNameNew = event.target.value;
        this.setState({ studentNameNew });
    }

    /** 输入新增的学员手机号 */
    inputUserNumber = event => {
        let studentNumberNew = event.target.value;
        this.setState({ studentNumberNew });
        if(studentNumberNew.length == 11) {//判断是否已做过班级测试
            this.props.studentCheckExercise(studentNumberNew,getSearchQuery('classId'));
        }
    }

    inputUserRemark = event => {
      if(event.target.value.length>800){
        message.error('备注不可超过800字');
        return ;
      }
      this.setState({ studentRemarkNew: event.target.value });
    }

    /** 导出班级Excel */
    handleDownExcel = () => {
        let params = {};
        let currentClassObj = this.props.currentClassObj;
        let courseId = this.courseName2courseId();
        params.classNo = currentClassObj.classNo;
        params.courseId = courseId;
        params.courseNum = this.props.currentDay || 1;

        this.props.downClassExcel(params);
    }

    /** 把s转化为时分秒 */
    formatSeconds = (value) => {
        let theTime = parseInt(value);          // 秒
        let middle = 0;                         // 分
        let hour = 0;                           // 小时
        if (theTime > 60) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if (middle > 60) {
                hour = parseInt(middle / 60);
                middle = parseInt(middle % 60);
            }
        }
        let result = "" + parseInt(theTime) + "秒";
        if (middle > 0) {
            result = "" + parseInt(middle) + "分" + result;
        }
        if (hour > 0) {
            result = "" + parseInt(hour) + "小时" + result;
        }
        return result;
    }

    /** 修改学员详情 */
    editStudent = async () => {
        if (!this.state.activeStudentObj.mobile) {
            message.error('请填写手机号');
            return;
        }
        if (!this.state.activeStudentObj.studentName) {
            message.error('请填写学员名');
            return;
        }
        if (!this.state.activeStudentObj.classId) {
            message.error('请选择班级');
            return;
        }
        if (this.showResure()) {
            this.makeSureEditStudent();
            return;
        }
        await this.editStudentCb();
    }

    /** 修改学员的方法 */
    editStudentCb = async () => {
        let params = {
            sort: this.state.activeStudentObj.sort,
            classId: this.state.activeStudentObj.classId,
            id: this.state.activeStudentObj.id,
            mobile: this.state.activeStudentObj.mobile,
            studentName: this.state.activeStudentObj.studentName,
            retreatDate: this.state.activeStudentObj.retreatDate,
            remark : this.state.activeStudentObj.remark
        };
        await save_update_student(params, this.successMesHandler, this.errorMesHandler);
        this.setState({ showEdit: false, activeStudentObj: {}, originActiveStudentObj: {} });
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 判断有没有必要出现确认框 */
    showResure = () => {
        return (
            this.state.activeStudentObj.studentName != this.state.originActiveStudentObj.studentName ||
            this.state.activeStudentObj.mobile != this.state.originActiveStudentObj.mobile ||
            this.state.activeStudentObj.classId != this.state.originActiveStudentObj.classId
        );
    }

    /** 编辑学员的二次确认 */
    makeSureEditStudent = () => {
        let that = this;
        Modal.confirm({
            title: '确定要修改学员的信息吗？',
            async onOk() {
                await that.editStudentCb();
            },
            onCancel() {
                that.setState({ showEdit: false });
            },
        });
    }

    /** 点击编辑 展示编辑学员的模态 */
    showEditModal = (record) => {
        this.setState({ showEdit: true, activeStudentObj: record, originActiveStudentObj: record, className: this.props.currentClassObj.className });
        
    }

    /** 隐藏编辑学员的模态 */
    handleEdieModalCancel = () => {
        this.setState({ showEdit: false, activeStudentObj: {}, className: '' });
    }

    /** 编辑学员所在班级 */
    editUserClass = value => {
        let classObj = className2classObj(value, this.props.classes);
        let classId = classObj.id;
        let activeStudentObj = Object.assign({}, this.state.activeStudentObj);
        activeStudentObj.classId = classId;
        this.setState({ activeStudentObj, className: classObj.className });
    }

    /** 编辑学员信息 */
    editUserInfo = (e, key) => {
        let activeStudentObj = Object.assign({}, this.state.activeStudentObj);
        activeStudentObj[key] = e.target.value;
        this.setState({ activeStudentObj });
    }

    editUserRemark = event => {
        let activeStudentObj = Object.assign({}, this.state.activeStudentObj);
        activeStudentObj.remark = event.target.value;
        if(activeStudentObj.remark.length>800){
          message.error('备注不可超过800字');
          return ;
        }
        this.setState({ activeStudentObj });
    }

    /** 输入需要进行搜索的班级名字的时候 */
    searchStudentNameHandler = event => {
        this.setState({ searchStudentName: event.target.value });
    };

    /** 输入完学员名字后点击筛选 */
    onSearch = () => {
        const { searchStudentName } = this.state;
        let newArr = this.props.originStudentLearnInfo.content.filter(record => {
            return record.studentName.indexOf(searchStudentName) !== -1
        });
        this.props.setStudentList(newArr);
        this.setState({ filterDropdownVisible: false });
    };

    /** 重置到初始状态 */
    reInitSearch = () => {
        let originStudentList = this.props.originStudentLearnInfo.content;
        this.props.setStudentList(originStudentList);
        this.setState({ filterDropdownVisible: false });
    };

    /** 判断某个键是否存在 */
    checkExists = (arr, key) => {
        if (Object.prototype.toString.call(arr) !== "[object Array]") return false;
        return arr.some(obj => obj[key] != null);
    }

    /** 删除学员 */
    deleteStudent = async studentObj => {
        await deleteStudentFun(studentObj.id);
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    /** 取消删除学员 */
    cancalDelete = () => {}

    /** 根据字符串进行一个排序 */
    stringSortByKey = (obja, objb, key) => {
        let num1 = (obja[key] || '-1%').replace(/%/, '');
        let num2 = (objb[key] || '-1%').replace(/%/, '');
        return num1 - num2;
    }

    /** 生成columns的动态显示部分 */
    generateDynamicColumns = () => {
        let keysOrder = [ 'mobile', 'watchTime', 'exerciseCountFinished', 'correctionCount', 'accuracy' ];
        let content = (this.props.studentLearnInfo && this.props.studentLearnInfo.content) || [];
        let studentObjVo = content[0] || {};
        let k2label = {
            mobile: '学员手机号',
            watchTime: '视频观看时长',
            exerciseCountFinished: '练习完成次数',
            correctionCount: '申论批改次数',
            accuracy: '平均正确率',
            remark: '备注'
        };
        let that = this;
        let columns = [];
        columns[0] = {
            title: '学员姓名',
            dataIndex: 'studentName',
            width: '10%',
            filterDropdown: (
                    <div className="custom-filter-dropdown flexrc">
                        <Input
                            ref={ele => this.searchInput = ele}
                            placeholder="请输入你想要搜索的学员名字"
                            value={this.state.searchStudentName}
                            onChange={this.searchStudentNameHandler}
                            onPressEnter={this.onSearch}
                            className="mr10"
                        />
                        <Button className="mr10" type="primary" onClick={this.onSearch}>搜索</Button>
                        <Button type="primary" onClick={this.reInitSearch}>重置</Button>
                    </div>
                ),
                /** 筛选图标 */
                filterIcon:  <SearchOutlined />,
                filterDropdownVisible: this.state.filterDropdownVisible,            // 点击筛选才可见；点击确定隐藏
                onFilterDropdownVisibleChange: (visible) => {
                    this.setState({
                        filterDropdownVisible: visible,
                    }, () => this.searchInput.focus());
                },
        };

        keysOrder.map(key => {
            let value = studentObjVo[key];
            if (this.checkExists(content, key)) {
                let personal = {
                    title: k2label[key],
                    dataIndex: key,
                    width: '10%'
                };
                if (typeof value === "number") {
                    personal.sorter = (a, b) => a[key] - b[key];
                }
                if (key === 'watchTime') {
                    personal.render = watchTime => `${this.formatSeconds(watchTime)}`;
                }
                if (key === 'accuracy') {
                    personal.sorter = (a, b) => {
                        if (typeof a[key] === 'number') {                       // 数字类型的话，直接操作
                            return a[key] - b[key];
                        } else {
                            return that.stringSortByKey(a, b, key);
                        }
                    }
                }
                columns.push(personal);
            }
        });

        columns.push({
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: '6%',
            render: record =>
                <Tooltip title={this.getTooltip(record)}>
                    <p className='ellipsis'>{record}</p>
                </Tooltip>
        });

        columns.push({
            title: '退课时间',
            width: '10%',
            dataIndex: 'retreatDate'
        });

        columns.push({
            title: '排序',
            dataIndex: 'sort',
            width: '7%',
            key: 'remark',
            render: (text, record, index) => <Input type="number" size='small' min={1} max={content.length} placeholder={index+1} onPressEnter={ e => this.sortListUp(e.target.value, index)} />
        });
       
        columns.push({
            title: '操作',
            dataIndex: 'id',
            width: '12%',
            render: (id, record) => {
                return (
                    <div className="commonTableBtn">
                        {checkPermission('sys:student:edit') &&  <Button onClick={() => this.showEditModal(record)} type="link" size="small">编辑</Button>}
                        {checkPermission('student:learn:info') && <Button disabled={record.retreatDate} onClick={() => this.seeStudentDetail(id)} type="link" size="small">查看</Button>}
                        {
                            checkPermission('sys:student:del') && 
                            <Popconfirm
                                title="确定要删除该学员？"
                                onConfirm={() => this.deleteStudent(record)}
                                onCancel={this.cancalDelete}
                                okText="确定删除"
                                cancelText="取消"
                            >
                                <Button type="link" size="small" style={{color: 'red'}}>删除</Button>
                            </Popconfirm>
                        }
                    </div>
                );
            }
        });

        return columns;
    }

    /** 修改顺序的学员 */
    sortListUp = (sort, index) => {
        let content = (this.props.studentLearnInfo && this.props.studentLearnInfo.content) || [];
        if (sort > (content.length)) return message.warn('序号超出总数');
        if (sort < 0) return message.warn('序号不能小于0');
        let target = content.splice(index, 1)[0];
        content.splice(sort-1, 0, target);
        this.props.setStudentList(content);
    }

    /** 更新顺序 */
    upSort = ()=> {
        let content = (this.props.studentLearnInfo && this.props.studentLearnInfo.content) || [];
        let arr = [];
        content.map((i, idx)=>{
            arr.push({sort: idx+1, studentId: i.id})
        });
        let cb = this.getClassLearnInfoFun;
        setSortList(arr, cb);
    }

    /** 查看学员详情 */
    seeStudentDetail = id => {
        this.saveLocal();
        let courseId = this.courseName2courseId();
        let url = `/studentDetail/${courseId}/${id}/${this.props.currentDay || 1}/${this.props.currentDay || 1}/${getSearchQuery('classId')}/${WORK_FILTER_STATUS_ALL}`;
        this.props.history.push(url);
    }

    /** 将学员列表数据保存到localStorage */
    saveLocal = () => {
        let arr = this.props.studentLearnInfo.content.map(obj => obj.id);
        arr = JSON.stringify(arr);
        let list = [];
        this.props.studentLearnInfo.content.map(obj => {
            list.push({'id':obj.id,'studentName':obj.studentName})
        });
        try{ window.localStorage.setItem('studentIds', arr);localStorage.setItem('studentList', JSON.stringify(list)); }
        catch(err) {}
    }

    /** 根据classId获取className */
    classId2ClassName = classId => {
        let classArr = this.props.classes;
        let result = classArr.find(obj => obj.id === classId);
        if (result) return result.className;
        else return '';
    }

    /** 渲染已完成作业的学员人数 */
    renderDonePeople = () => {
        if (this.props.filterStatus == 'NOT') {                     // 处于未完成筛选状态的话，那是另外一种处理
            return this.props.currentClassObj.studentNumber - this.props.studentLearnInfo.content.length;
        } else if (this.props.filterStatus == 'DONE') {             // 处于完成状态的筛选提示
            return this.props.studentLearnInfo.content.length;
        } else {
            let doneArr = this.props.studentLearnInfo.content.filter(obj => obj.exerciseCountFinished == this.props.courseMetadata.exerciseCount);
            return doneArr.length;
        }
    }

    /** 搜索班级  动态生成获取select下拉列表 */
    searchClass = value => {
        let data = { title: value }
        this.props.searchCurrentClass(data)
    };

    /** 选择退课时间 */
    dateChange = (dateString) => {
        let activeStudentObj = Object.assign({}, this.state.activeStudentObj);
        activeStudentObj.retreatDate = dateString;
        this.setState({ activeStudentObj });
    }

    /** 日历日期值转换函数 */
    calendarValuePipe = value => value ? moment(value, 'YYYY-MM-DD') : '';

    /** 切换天数 */
    changeDay = async (type) => {
        let day = this.props.currentDay || 1;
        type==1 && ++day || --day;              // 1是下一天，0是上一天
        await this.props.setCurrentDay(day);
        this.fetchCourseMetadata();
        await this.props.setCurrentPage(1);             // 初始化为第1页
        await this.getClassLearnInfoFun();
        this.setState({ searchStudentName: '' });
    }

    getcurrentDate = (day) => {
        let { dayNumberArr } = this.props;
        return dayNumberArr.map(item => {
            if(day == item.number) return (item.date || '--');
        })
    }

    render() {
        let columns = this.generateDynamicColumns();
        let {dayNumberArr} = this.props;
        let currentDay = this.props.currentDay || 1;
        let prevNums = currentDay-1;
        let nextNums = dayNumberArr.length - currentDay;   
        
        return (
            <div className="classDetail-container">
                <div className="filter-container flexrc height60">
                    <span>课程:</span>
                    <span className="marginRight15">{this.props.currentClassObj['courseName']}</span>
                    <div className="flexG"></div>
                    <RadioGroup onChange={this.changeFilterStatus} value={this.props.filterStatus || "ALL"}>
                        <Radio value="ALL">全部</Radio>
                        <Radio value="DONE">已完成</Radio>
                        <Radio value="NOT">未完成</Radio>
                    </RadioGroup>
                    <div className="flexG"></div>
                    {
                        checkPermission('student:learn:export') &&
                        <Button type="primary" onClick={this.handleDownExcel} className="mt5">导出班级数据Excel</Button>
                    }
                </div>

                {checkPermission('clazz:learn:info') && <div className="filter-container borderBottom height60">
                    <span style={{marginRight: '20px'}}>日期: {this.props.dayNumberArr.length && this.getcurrentDate(currentDay)}</span>
                    <span className="filter-label">天数</span>
                    <Select
                        showSearch
                        className="dayNumberSelect"
                        placeholder="选择天数"
                        onChange={this.handleDayChange}
                        value={ this.props.currentDay || 1 }
                        disabled={ this.props.dayNumberArr.length <= 0 }
                    >
                        {
                            this.props.dayNumberArr.map((dayNum, idx) => {
                                return <Option key={idx} value={dayNum.number}>{dayNum.number}</Option>
                            })
                        }
                    </Select>
                    <Tooltip title={`前面还有${prevNums}天`}>
                        <Button style={{marginLeft: '20px'}} onClick={()=>this.changeDay(0)} disabled={currentDay <= 1 || dayNumberArr.length == 1}>上一天</Button>
                    </Tooltip>
                    <Tooltip title={`后面还有${nextNums}天`}>
                        <Button style={{marginLeft: '20px'}} onClick={()=>this.changeDay(1)} disabled={currentDay >= dayNumberArr.length}>下一天</Button>
                    </Tooltip>
                </div>}

                {/** 课程的元数据展示开始 */}
                <div className="filter-container flexrc">
                    {
                        this.props.loadingCourseMetadata ?
                        <Spin />
                        :
                        <div className="flexrc childDiv">
                            <span className="mb5">班级名：</span>
                            <span className="marginRight15 mb5">{this.props.currentClassObj.className}</span>
                            <span className="mb5">班主任：</span>
                            <span className="marginRight15 mb5">{this.props.currentClassObj.teacher}</span>
                            <span className="mb5">班级人数：</span>
                            <span className="marginRight15 mb5">{this.props.currentClassObj.studentNumber}</span>
                            {
                                (this.props.courseMetadata && this.props.courseMetadata.exerciseCount) ?
                                <div className="mb5">
                                    <span>练习已完成人数：</span>
                                    <span className="marginRight15">{this.renderDonePeople()}人</span>
                                </div>
                                :
                                <span className="mb5"></span>
                            }
                            <span className="mb5">视频总时长：</span>
                            <span className="marginRight15 mb5">{this.formatSeconds(this.props.courseMetadata?.totalTime || 0)}</span>
                            <span className="mb5">练习总数量：</span>
                            <span className="marginRight15 mb5">{this.props.courseMetadata?.exerciseCount || 0}次</span>
                        </div>
                    }
                </div>
                <div className="filter-container" style={{color: 'red', fontSize: '12px'}}>排序输入完成点击回车，再点击下方更新顺序按钮，退课与非退课的学员不在一起排序</div>
                {/** 课程的元数据展示结束 */}

                {/** 学员详细信息的表格开始 */}
                <div className="table-container">
                    <Table
                        columns={columns}
                        dataSource={this.props.studentLearnInfo.content}
                        rowKey={record => record.id}
                        loading={this.props.loadingStudentLearnInfo}
                        pagination={false}
                        footer={this.renderFooter}
                    />
                </div>
                {/** 学员详细信息的表格结束 */}

                {/** 新增学员的modal输入框开始 */}
                <Modal
                    title="添加学员"
                    visible={this.state.visible}
                    onOk={this.handleModalOk}
                    onCancel={this.handleModalCancel}
                    maskClosable={true}
                    width="520"
                >
                    <Input className='commonW150 mr' value={this.state.studentNameNew} onChange={this.inputUserName} placeholder="请输入学员姓名" />
                    <Input className='commonW150 mr' value={this.state.studentNumberNew} onChange={this.inputUserNumber} placeholder="请输入学员号码" />
                    <label>排序</label><Input className='commonW150 mr' type="number" min={1} value={this.state.sort} onChange={e=>this.setState({sort: e.target.value})} />
                    <Input.TextArea value={this.state.studentRemarkNew} onChange={this.inputUserRemark} placeholder="请输入备注" rows="6" />
                </Modal>
                {/** 新增学员的modal输入框结束 */}

                {/** 编辑学员的modal开始 */}
                <Modal
                    confirmLoading={this.props.checkLoading}
                    title="编辑学员"
                    visible={this.state.showEdit}
                    onOk={this.editStudent}
                    onCancel={this.handleEdieModalCancel}
                    maskClosable={true}
                    width="520"
                >
                    <label>学员姓名</label><Input className='commonW150 mr' value={this.state.activeStudentObj.studentName} onChange={e=>this.editUserInfo(e, 'studentName')} />
                    <label>联系方式</label><Input className='commonW150 mr' value={this.state.activeStudentObj.mobile} onChange={e=>this.editUserInfo(e, 'mobile')} />
                    <label>退课时间</label><DatePicker className='commonW150 mr' value={this.calendarValuePipe(this.state.activeStudentObj.retreatDate)} onChange={(date, dateString) => {this.dateChange(dateString)}} />
                    <br/>
                    <label>排序</label><Input className='commonW150 mr' type="number"  min={1} value={this.state.activeStudentObj.sort} onChange={e=>this.editUserInfo(e, 'sort')} />
                    <label>学员班级</label>
                    <Select
                        showSearch
                        className="commonW400"
                        placeholder="选择班级名称"
                        optionFilterProp="children"
                        //value={this.classId2ClassName(this.state.activeStudentObj.classId)}
                        value={this.state.className}
                        onSearch={value => {this.searchClass(value)}}
                        onChange={ this.editUserClass }
                        filterOption={ this.filterClassName }
                    >
                        {
                            this.props.classes.map((classObj, idx) => {
                                return <Option key={idx} value={classObj['className']} classObj={classObj}>{classObj['className']}</Option>
                            })
                        }
                    </Select>
                    <br/>
                    备注<Input.TextArea value={this.state.activeStudentObj.remark} autoSize={{ minRows: 6, maxRows: 6}} rows="6" onChange={this.editUserRemark}/>
                </Modal>
                {/** 编辑学员的modal结束 */}

            </div>
        );
    }
}

const mapStateToProps = (storeState, ownProps) => ({
    ...storeState.classDetail,
});

const mapDispatchToProps = dispatch => ({
    studentCheckExercise: (mobile, classId) => {dispatch(studentCheckExercise(mobile, classId))},   //检验是否加入过班级
    setFilterStatus: value => { dispatch(set_filter_status(value)); },      // 设置筛选状态
    getDayNum: courseId => { dispatch(get_day_num(courseId)); },            // 根据课程id获取天数
    getCourseMetadata: (courseId, dayNumber) => { dispatch(get_course_metadata(courseId, dayNumber)); },            // 根据课程id和天数获取班级元数据信息
    getClassLearnInfo: async (params={}) => { await dispatch(get_class_learninfo(params)); },// 获取班级某天的作业完成情况
    setCourseName: name => { dispatch(set_current_course(name)); },         // 设置选中的课程名称
    setCurrentDay: day => { dispatch(set_current_day(day)); },              // 设置选中的天
    showSizeChange: size => { dispatch(show_size_change(size)); },          // 改变每页显示多少条数据
    setCurrentPage: page => { dispatch(set_current_page(page)); },          // 修改当前页
    downClassExcel: params => { dispatch(down_class_excel(params)); },                              // 下载班级
    fetchClassDetail: async classId => { await dispatch(fetch_class_detail(classId)); },
    searchCurrentClass: data => { dispatch(fetchSearchClass(data)) },
    setStudentList: arr => { dispatch(set_student_list(arr)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetail);
