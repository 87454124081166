import {
    CHANGE_ACTIVE_PAGE,
    CHANGE_PAGE_SIZE,
    CHANGE_STATUS,
    FETCH_QUES_CORRECTION_SUCCED,
    UPDATE_STATUS_SUCCED,
    UPDATE_FEEDBACK_SUCCED,
    FETCH_QUES_CORRECTION_START,
    FETCH_QUES_CORRECTION_END
} from './actions';

const initSearch = {
    breed: 0,
    activePage: 1,                  // 当前第几页
    pageSize: 10,                   // 每页多少条
    status: '',                   // 完成状态
};

const initialState = {
    onLoading: false,               // 是否处于加载过程中
    apiError: false,                // 关键API发生错误
    responseObj: {
        content: [],                // 班级列表数据
        totalElements: 10,          // 班级总数
    },                              // 接口返回数据
    ...initSearch
};

export const quesCorrection = (previousState=initialState, action) => {
    switch (action.type) {
        case FETCH_QUES_CORRECTION_START:
          return { ...previousState, onLoading: true, apiError: false };
        case FETCH_QUES_CORRECTION_END:
          return { ...previousState, onLoading: false, apiError: true };
        case FETCH_QUES_CORRECTION_SUCCED:
            return { ...previousState, onLoading: false, apiError: false, responseObj: action.data };
        case CHANGE_PAGE_SIZE:
            return { ...previousState, pageSize: action.data };
        case CHANGE_ACTIVE_PAGE:
            return { ...previousState, activePage: action.data };
        case CHANGE_STATUS:
            return { ...previousState, [action.key]: action.val };
        case UPDATE_STATUS_SUCCED:
        case UPDATE_FEEDBACK_SUCCED:
          let {responseObj} = previousState;
          let {content} = responseObj;
          content = [...content]
          content[action.idx] = {...action.data}
          return { ...previousState, responseObj: {
            content,
            totalElements: responseObj.totalElements
          } };
        default:
            return previousState;
    }
}
