/**
 * Created by pwq on 2016/12/7.
 */

import {
    REQUEST_QUESTIONS,
    RECEIVE_QUESTIONS,
    SET_KEY_VALUE,
    RESET_SEARCH_STATUS,
    RECEIVE_INTERVIEW_AREAS,
    RECEIVE_INTERVIEW_CONDITION,
    CHANGE_COMMON
} from './actions';


const initFilter = {
    page: 1,
    pageSize: 10,
    type: '',    //选择的多元升学类型
    school: '',    //选择的院校
    categoryId: '',   // 选择的面试题型
    questionTag: '',   // 选择的试题标签
    year: '',
    title: '',
    query: '',
    // areas: [],                       //面试地区列表
    schools: [], // 院校
    recruitTypeSchool: [],  // 多元升学类型与学校二级级联
    categories: [], // 面试问题题型
    tags: [],   // 真题、原创
    years: [],  // 年份
};

export function interviewList(state = {
    isFetching: true,
    items: [],
    ...initFilter
}, action) {
    switch (action.type) {
        case CHANGE_COMMON:
            return Object.assign({}, state, { [action.key]: action.val });
        case REQUEST_QUESTIONS:
            return Object.assign({}, state, {isFetching: true});
        case RECEIVE_QUESTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.questionList,
                totalPages: action.totalPages,
                totalElements: action.totalElements,
                lastUpdated: action.receivedAt
            });
        case SET_KEY_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        case RESET_SEARCH_STATUS:
            console.log('RESET_SEARCH_STATUS', state);
            return Object.assign({}, state, {...initFilter, categories: state.categories, recruitTypeSchool: state.recruitTypeSchool, tags: state.tags, years: state.years});
        case RECEIVE_INTERVIEW_AREAS:
            return Object.assign({}, state, {areas: action.areas});
        case RECEIVE_INTERVIEW_CONDITION:
            console.log('RECEIVE_INTERVIEW_CONDITION', action);
            return Object.assign({}, state, {categories: action.condition.categories, recruitTypeSchool: action.condition.recruitTypeSchool, tags: action.condition.tags, years: action.condition.years});
        default:
            return state;
    }
}
