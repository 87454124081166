import React from 'react';
import apiRequest from '../../common/request';
import { Popconfirm, Card, message, Tabs, Input, Radio, Button } from 'antd';
import './style.scss'

const { TabPane } = Tabs;

export default class UserInfo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            nickName: '',
            avatar: '',
            mobile: '',
            email: '',
            sex: 1,
            roleNames: [],
            roleIds: [],
            newPsd: '',
            oldPsd: '',
            newPsd2: ''
        }
    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo = async () => {
        let url = `/gk/ops/system/user/profile/info`;
        let obj = await apiRequest(url);
        let userObj = obj.obj;
        this.setState({
            roleNames: userObj.roleNames,
            nickName: userObj.nickName,
            roleIds: userObj.roleIds,
            avatar: userObj.avatar || 'https://jd-media-resource.oss-cn-hangzhou.aliyuncs.com/background/jiaodian-logo.jpg',
            mobile: userObj.mobile,
            email: userObj.email,
            sex: userObj.sex,
            id: userObj.id,
        })
    }

    updatePwd = async () => {
        let { oldPsd, newPsd, newPsd2 } = this.state;
        let regu = /^[a-zA-Z0-9_-]{6,19}$/;
        let re = new RegExp(regu);
        if (oldPsd == '') return message.warning('请输入旧密码');
        if (newPsd == '') return message.warning('请输入新密码');
        if (newPsd2 == '') return message.warning('请输入确认密码');
        if (oldPsd == newPsd) return message.warning('新密码不能与旧密码相同');
        if (newPsd2 != newPsd) return message.warning('两次密码不一致');
        if (!re.test(newPsd)) return message.warning('新密码不符合修改规则');
        let data = { oldPsd, newPsd };
        let url = `/gk/ops/system/user/profile/updatePwd`;
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        };

        let obj = await apiRequest(url, config);
        if (obj.status == 1) {
            message.success('修改成功');
            this.props.history.push('/login');
        }
    }

    updateUserInfo = async () => {
        let { id, roleIds, sex, nickName, mobile, email } = this.state;

        if (nickName == '') {
            return message.warning('请输入用户名称');
        }

        if (!(/^1[3456789]\d{9}$/.test(mobile))) {
            return message.warning('手机号格式有误');
        }

        if (email == undefined) {
            return message.warning('请输入邮箱');
        }

        if (!(/@/.test(email))) {
            return message.warning('邮箱格式有误');
        }

        let data = {
            roleIds, id, sex, nickName, mobile, email
        }
        let url = `/gk/ops/system/user/profile/update`;
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'accept': 'application/json', 'content-type': 'application/json' },
        };
        let obj = await apiRequest(url, config);
        (obj.status == 1) && message.success('修改成功');
    }

    onChangeCommon = (val, key) => {
        this.setState({ [key]: val })
    }

    render() {
        let { oldPsd, newPsd, newPsd2, sex, avatar, nickName, mobile, roleNames, email } = this.state;
        return (
            <div className="userInfo">
                <Card title="个人信息" style={{ width: '30%' }}>
                    <div className="infoBox">
                        <img className="avatar" alt='avatar' src={avatar}></img>
                        <div className="listUser"><span>用户名称</span><span>{nickName}</span></div>
                        <div className="listUser"><span>手机号码</span><span>{mobile}</span></div>
                        <div className="listUser"><span>用户邮箱</span><span>{email}</span></div>
                        <div className="listUser"><span>所属角色</span><span>{roleNames}</span></div>
                    </div>
                </Card>
                <Card title="基本资料" style={{ width: "68%" }}>
                    <Tabs>
                        <TabPane tab="基本资料" key="1">
                            <div className="upP"><label>用户名称</label><Input onChange={e => this.onChangeCommon(e.target.value, 'nickName')} value={nickName} style={{ width: '80%' }} /></div>
                            <div className="upP"><label>手机号码</label><Input onChange={e => this.onChangeCommon(e.target.value, 'mobile')} value={mobile} style={{ width: '80%' }} disabled/></div>
                            <div className="upP"><label>邮箱</label><Input onChange={e => this.onChangeCommon(e.target.value, 'email')} value={email} style={{ width: '80%' }} /></div>
                            <div className="upP"><label>性别</label>
                                <Radio.Group onChange={e => this.onChangeCommon(e.target.value, 'sex')} value={sex}>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={0}>女</Radio>
                                </Radio.Group>
                            </div>
                            <Popconfirm
                                title="你确定修改基本资料吗?"
                                onConfirm={() => this.updateUserInfo()}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button className="btnP" type="primary">保存</Button>
                            </Popconfirm>

                        </TabPane>
                        <TabPane tab="修改密码" key="2">
                            <div className="upP"><label>旧密码</label><Input onChange={e => this.onChangeCommon(e.target.value, 'oldPsd')} value={oldPsd} type="password" style={{ width: '80%' }} /></div>
                            <div className="upP"><label>新密码</label><Input onChange={e => this.onChangeCommon(e.target.value, 'newPsd')} value={newPsd} type="password" style={{ width: '80%' }} /></div>
                            <div className="upP"><label>确认密码</label><Input onChange={e => this.onChangeCommon(e.target.value, 'newPsd2')} value={newPsd2} type="password" style={{ width: '80%' }} /></div>
                            <div className="btnP upP">注意1：修改密码成功后需要重新登录</div>
                            <div className="btnP upP">注意2：密码必须是6-19位英文或数字的组合</div>
                            <Popconfirm
                                title="你确定修改密码吗?"
                                onConfirm={() => this.updatePwd()}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button className="btnP" type="primary">确定</Button>
                            </Popconfirm>
                        </TabPane>
                    </Tabs>
                </Card>

            </div>
        )
    }

}
