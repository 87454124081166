import {
    RESET_STATE,
    COMMON_SET_STATUS
} from './actions.js';

const init = {
    year: [],
    source: '',
    province: [],
    query: '',
    options: '',
    page: 1,
    pageSize: 10,
    questionTag: '',
    categoryId: [''],
};

const initialState = {
    ...init,
    examType: 'gj',
    loading: true,
    institutionQuestionObj: {
        content: [],
    }
}

export function institutionList (state=initialState, action) {
    switch(action.type){
        case COMMON_SET_STATUS:
            return Object.assign({}, state, {[action.key]: action.value,});
        case RESET_STATE:
            return Object.assign({}, state, {...init});    
        default:
            return state;
    }
}