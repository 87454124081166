import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, message } from "antd";
import InterviewSelectCard from "../../components/InterviewSelectCard";
import { 
    fill_examine_class_id,
    fill_examine_title,
    select_question,
    update_sel_question,
    fetchExamine,
} from './action';
import {updateExamine} from "../ClassTestInfo/actions";
import {fetchClassList} from "../InterviewClass/actions";
import { getSearchQuery, checkPermission } from '../../utils/utils';

import './index.scss';
const Option = Select.Option;


const classTestInfoStyle = {
    selectStyle: {
        margin: 5,
        width: 300,
    },
    mb24: {
        fontSize: 18,
        fontWeight: 500,
    },
    font14: {
        fontSize: 14
    },
    mr6: {
        marginRight: 6,
        marginTop: 6,
        marginBottom: 6
    }
};

class InterviewClassTest extends React.Component {

    componentWillMount() {
        const reqData = { page: 1, pageSize: 10000 };
        let id = getSearchQuery('id');
        let classType = getSearchQuery('type');
        this.props.dispatch(fetchClassList(reqData));
        id && this.props.dispatch(fetchExamine(id, classType));
    };

    /** 添加班级 */
    addClass = () => {
        let {classIdDate} = this.props.info;
        classIdDate.push({})
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    /** 移除班级 */
    removeClass = idx => {
        let {classIdDate} = this.props.info;
        classIdDate.splice(idx, 1)
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    selectClassHandler = (idx, value) => {
        let {classIdDate} = this.props.info;
        classIdDate[idx]['classId']=value
        this.props.dispatch(fill_examine_class_id(classIdDate));
    }

    updateQuesArr = (arr) => {
        this.props.dispatch(select_question(arr));
    }

    updateSelQues = (arr) => {
        this.props.dispatch(update_sel_question(arr));
    }

    updatePoint = (order, point) => {
        // this.props.dispatch(updatePoint(order, point));
    }

    modifyExamine = async () => {
        if (!this.dataVerify()) {
            return;
        }
        await this.props.dispatch(updateExamine(this.props.info, getSearchQuery('type')));
    };

    dataVerify = () => {
        if (!this.props.info.selQuesList || this.props.info.selQuesList.length <= 0) {
            message.warn('请选择试题');
            return false;
        }
        if (!this.props.info.title) {
            message.warn('请输入面试班级标题');
            return false;
        }
        if (!this.props.info.classIdDate || this.props.info.classIdDate.length <= 0) {
            message.warn('请选择班级');
            return false;
        }
        
        return true;
    }

    render() {
        const { info } = this.props;
        return (
            <div className="classTestContainer">
                <div className="mb10 flexRow">
                    <span style={classTestInfoStyle.mb24}>班级练习</span>
                    {checkPermission('gkview:exercise:edit') &&
                        <Button onClick={this.modifyExamine} type="primary" className="position">更新</Button>
                    }
                </div>

                <div style={classTestInfoStyle.mb24}>
                    <label style={classTestInfoStyle.font14}>面试班级标题</label>
                    <Input
                        value={info.title}
                        onChange={e => this.props.dispatch(fill_examine_title(e.target.value))}
                    />
                </div>

                <div style={classTestInfoStyle.mr6}>
                    <label style={classTestInfoStyle.font14}>班级</label>
                    {
                      info.classIdDate && info.classIdDate.map((cls, idx) => {
                        return <div key={idx}>
                            <Select
                                showSearch
                                placeholder="请选择班级"
                                style={classTestInfoStyle.selectStyle}
                                onChange={value=>this.selectClassHandler(idx,value)}
                                value={cls.classId}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {this.props.classListObj.content.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.className}</Option>
                                })}
                            </Select>
                            <Button  onClick={()=>this.removeClass(idx)}>
                                移除
                            </Button>
                        </div>
                      })
                    }
                    <Button onClick={this.addClass} type="primary" className="ml10">
                        添加班级
                    </Button>
                </div>

                {checkPermission('view:question:page') && <InterviewSelectCard
                    quesArr={info.quesArr}
                    selQuesList={info.selQuesList||[]}
                    updateQuesArr={this.updateQuesArr}
                    updateSelQues={this.updateSelQues}
                    updatePoint={this.updatePoint}
                />}
            </div>
        )
    }
}

function mapStatetoProps(state) {
    const {
        info
    } = state.interviewClassTest;

    const {
        responseObj: classListObj
    } = state.interviewClass;

    return {
       info,
       classListObj
    };
}

export default connect(mapStatetoProps)(InterviewClassTest);