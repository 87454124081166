import {
    REQUEST_USER_COURSE,
    RECEIVE_USER_COURSE,
    RECEIVE_USERINFO,
    SET_ACTIVE_PAGE,
    SET_PAGE_SIZE
} from './actions';

export function userConfigPage(state = {
    loading: false,
    items: [],
    courseArr: [],
    userInfo: {},
    activePage: 1,                      // 分页器当前第几页
    pageSize: 10,                       // 分页器每页多少项
    subjects: [
        { label: '全部', value: "" },
        { label: '行测', value: 0 },
        { label: '申论', value: 1 },
        { label: '公基', value: 3 },
        { label: '职测', value: 4 },
        { label: '综应', value: 5 },
    ],
}, action) {
    switch (action.type) {
        case REQUEST_USER_COURSE:
            return Object.assign({}, state, {loading: true});
        case RECEIVE_USER_COURSE:
            return Object.assign({}, state, {
                loading: false,
                items: action.list,
                courseArr: action.list.map(i=>i.id)
            });
        case RECEIVE_USERINFO:
            return Object.assign({}, state, {
                userInfo: action.data,
                loading: false,
            });
        case SET_PAGE_SIZE:
            return Object.assign({}, state, { pageSize: action.data });
        case SET_ACTIVE_PAGE:
            return Object.assign({}, state, { activePage: action.data });
        default:
            return state;
    }
}